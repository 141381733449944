import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import DateRangePicker from 'src/components/DateRangePicker';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { BasicContext } from 'src/context/BasicContext';
import {
  getDateRange,
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import CommonModal from '../../../components/CommonModal';

import { classOptions } from './AddressDetails/Address.interface';

interface IProps {
  handleClose(): any;
  teamListOptions: any[];
  params: any;
  setParams: any;
  userList: any;
  statusArr: any;
  setFilterCount: any;
}

const FilterModal = ({
  handleClose,
  teamListOptions,
  setParams,
  params,
  userList,
  statusArr,
  setFilterCount,
}: IProps) => {
  const uiState = useSelector((state: any) => state.MobileView);
  const { allTimeStartDateActual, appearance } = useContext(BasicContext);
  const [team, setTeam] = useState(params.team ?? 'all');
  const [teamMember, setTeamMember] = useState(userList);
  const { hasRoleV2 } = useRolePermission();
  const [status, setStatus] = useState(params.filter.status ?? 'All');
  const [userSelected, setUserSelected] = useState(params.member ?? 'all');
  const [classification, setClassification] = useState(
    params.filter.classification ?? undefined
  );
  // const [activeFilter, setActiveFilter] = useState('daily');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('custom');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');

  // const [selectedDate, setSelectedDate] = useState<SelectedDate>({
  //   createdDate: params?.createdDate,
  // });
  const [startDate, setStartDate] = useState<any>(
    params.startDate ? moment(params.startDate).toDate() : null
  );
  const [endDate, setEndDate] = useState<any>(
    params.endDate ? moment(params.endDate).toDate() : null
  );
  const initialParams = useRef(params);
  const [isFilterChanged, setIsFilterChanged] = useState(false);

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateActual);

    // for user if start of allTimeStartDateActual is less than last year's first date then not letting user go further than last year's first date
    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateActual);
    }
  }, [allTimeStartDateActual]);

  useEffect(() => {
    const filtersChanged = !_.isEqual(
      {
        team,
        member: userSelected,
        status,
        classification,
        startDate,
        endDate,
      },
      {
        team: initialParams.current.team,
        member: initialParams.current.member,
        status: initialParams.current.filter.status,
        classification: initialParams.current.filter.classification,
        startDate: initialParams.current.startDate,
        endDate: initialParams.current.endDate,
      }
    );

    console.log(
      'first',
      team,
      userSelected,
      status,
      classification,
      startDate,
      endDate
    );

    console.log('initialParams', initialParams);

    setIsFilterChanged(filtersChanged);
  }, [team, userSelected, status, classification, startDate, endDate]);

  const handleDatePickerChange = (date: any, type: any) => {
    const [start, end] = date;
    // console.log('start', start, ' ', end);
    setSelectedTimeRange(type);
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
    const daysDiff = moment(end).diff(moment(start), 'days') + 1;
    const prevMonth = moment(start).subtract(daysDiff, 'day');

    if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
      setIsPrevYearDisable(true);
    } else {
      setIsPrevYearDisable(false);
    }

    setStartDate(start ?? null);

    if (!end) {
      setEndDate(start ?? null);
    } else {
      setEndDate(end ?? null);
    }
  };

  const getFilterRange = (type?: string) => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      // setIsNextYearDisable(false);
      if (selectedTimeRange === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (selectedTimeRange === 'weekly') {
        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (selectedTimeRange === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (selectedTimeRange === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (selectedTimeRange === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();
      } else if (selectedTimeRange === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
      } else if (selectedTimeRange === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();
      } else if (selectedTimeRange === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      // if (moment(endDT, "DD-MM-YYYY").isSameOrAfter(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"))) {
      //     setIsNextYearDisable(true);
      // }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(selectedTimeRange);
        console.log('start', start, '   ', end);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  const handleSave = () => {
    let fCount = 0;
    if (startDate && endDate) fCount += 1;
    if (team !== 'all') fCount += 1;
    if (userSelected !== 'all') fCount += 1;
    if (classification && classification !== 'all') fCount += 1;
    console.log('classification :>> ', classification);
    if (team !== 'all') console.log('team all :>> ');
    if (userSelected !== 'all') console.log('userselected all :>> ');
    if (classification && classification !== 'all')
      console.log('classification all :>> ');

    if (uiState.isMobile || uiState.currentBreakpoint === 'SM') {
      if (status != 'All') fCount += 1;
    }
    // const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setFilterCount(fCount);
    let startDT = startDate
      ? selectedTimeRange === 'custom'
        ? moment(startDate)
            // .tz(appearance?.timezone ?? browserTimezone)
            .local()
            .format('YYYY-MM-DD')
        : moment
            .utc(startDate)
            // .tz(appearance?.timezone ?? browserTimezone)
            .local()
            .format('YYYY-MM-DD')
      : null;
    let endDT = endDate
      ? selectedTimeRange === 'custom'
        ? moment(endDate)
            // .tz(appearance?.timezone ?? browserTimezone)
            .local()
            .format('YYYY-MM-DD')
        : moment
            .utc(endDate)
            // .tz(appearance?.timezone ?? browserTimezone)
            .local()
            .format('YYYY-MM-DD')
      : null;
    setParams((old: any) => ({
      ...old,
      page: 1,
      team: team ?? 'all',
      member: userSelected ?? 'all',
      startDate: startDT,
      endDate: endDT,
      filter: {
        status: status,
        classification: classification,
      },
    }));
    handleClose();
  };

  useEffect(() => {
    if (teamListOptions && teamListOptions.length > 0) {
      let uniqueArr = [];

      if (team === 'all') {
        setUserSelected(userSelected ?? 'all');
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find((tlo) => tlo.value === team);
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setTeamMember(updatedListAllMembers);
    }
  }, [team, teamListOptions]);

  return (
    <CommonModal
      size={'smd:max-w-[490px] max-w-[336px] overflow-unset'}
      handleClose={handleClose}
      title="Filters"
      primaryBtnText="Save"
      primaryBtnDisabled={!isFilterChanged}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={handleClose}
      primaryBtnOnClick={handleSave}
      titleDesc="Please change as per your requirement."
      isOverflow={false}
    >
      <div className="p-5">
        <div className="rounded-lg border border-utilityGray200 p-3 flex flex-col gap-3">
          {window?.MODE !== 'production' && (
            <div className="smd:flex flex-wrap items-center smd:flex-none flex-1">
              <label className={`form_label smd:mb-0 mb-1.5 w-24`}>
                Select Type
              </label>
              <SelectBox
                name="classification"
                required
                className={`form_control`}
                labelClassName="form_label mb-1.5 block"
                options={classOptions}
                placeholder="All Type"
                isClearable={true}
                value={
                  classification
                    ? classOptions?.filter(
                        (val: any) => classification === val?.value
                      )
                    : 'All'
                }
                onChangeFunc={(event: any) => setClassification(event?.value)}
                // errorText={!validForm.customerId ? true : false}
                parentClassName="flex-1"
              />
            </div>
          )}
          <div className="smd:flex flex-wrap items-center smd:flex-none flex-1 mdm:hidden">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>Status</label>
            <SelectBox
              name="status"
              placeholder="All"
              parentClassName=" block w-full flex-1"
              labelClassName="form_label"
              isClearable={status !== 'All' ? true : false}
              value={
                status
                  ? statusArr?.filter((val: any) => status === val?.value)
                  : 'All'
              }
              onChangeFunc={(event: any) => setStatus(event?.value ?? 'All')}
              options={statusArr}
            />
          </div>
          <div className="smd:flex flex-wrap items-center flex-1">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>
              Created Date
            </label>
            {/* <DateSelect
              inputName="createdDate"
              className="form_control"
              placeholder="Select created Date"
              dateFormat="dd/MM/yyyy"
              selected={
                selectedDate.createdDate
                  ? moment(selectedDate.createdDate).toDate()
                  : null
              }
              value={
                selectedDate.createdDate
                  ? getDateWithSuffixFormat(
                      getFormattedPickupDate(selectedDate.createdDate)
                    )
                  : 'Select Created Date'
              }
              isClearable
              clearButtonTitle="Clear"
              onChangeFunc={handleDateChange}
              popperPlacement="bottom"
              icon={<Calendar className="h-5 w-5" />}
              parentClassName="one-month-datepicker filter-modal-datepicker smd:flex-1"
            /> */}
            <div
              className="items-center cursor-pointer justify-between flex flex-1"
              key={`datepicker_${startDate}_${endDate}`}
            >
              <DateRangePicker
                handleDatePickerChange={(dates: any, type?: string) =>
                  handleDatePickerChange(dates, type)
                }
                selectedTimeRange={selectedTimeRange}
                startDate={startDate}
                endDate={endDate}
                allTimeStartDate={allTimeStartDate}
                showYearDropdown={false}
                isShowDropDownIcon={false}
                minDate={
                  hasRoleV2('user')
                    ? moment().subtract(1, 'years').startOf('year').toDate()
                    : ''
                }
                containerClassName="!mt-0"
                containerWrapClassName="h-screen w-screen fixed left-1/2 top-1/2 flex items-center justify-center -translate-y-1/2 -translate-x-1/2 z-[1] after:content-[''] after:bg-black after:bg-opacity-20 after:backdrop-blur-[2px] after:w-full after:h-full"
                parentClassName="sales-datepicker-quote"
                isPrevDisabled={isPrevYearDisable}
                onPrevNextClick={(type: string) => getFilterRange(type)}
                textWrapClass="!justify-start"
                calendarClass="!hidden"
                dateRangeWrapClass="!shadow-none"
              />
            </div>
          </div>
          <div className="smd:flex flex-wrap items-center smd:flex-none flex-1 ">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>Teams</label>
            <SelectBox
              name="teamListGroup"
              id="teamListGroup"
              className="form_control"
              size="sm"
              placeholder="All Teams"
              noOptionMessage="No Teams Found"
              isClearable={true}
              isSearchable={true}
              options={teamListOptions}
              parentClassName="flex-1"
              onChangeFunc={(event: any) => {
                setTeam(event?.value ?? 'all');

                if (!event?.value) {
                  setUserSelected('all');
                }
              }}
              // onChangeFunc={(event: any) =>
              //   setParams((old: any) => ({
              //     ...old,
              //     ...{ team: event?.value ?? 'all' },
              //   }))
              // }
              value={
                team
                  ? teamListOptions.filter((val: any) => team === val.value)
                  : null
              }
            />
          </div>
          <div className="smd:flex flex-wrap items-center smd:flex-none flex-1">
            <label className={`form_label smd:mb-0 mb-1.5 w-24`}>Users</label>
            <SelectBox
              name="userListGroup"
              id="userListGroup"
              size="sm"
              placeholder="All Users"
              className="form_control"
              isClearable={true}
              isSearchable={true}
              options={teamMember}
              parentClassName="flex-1"
              noOptionMessage="No Users Found"
              onChangeFunc={(event: any) =>
                setUserSelected(event?.value ?? 'all')
              }
              value={
                userSelected
                  ? teamMember.filter(
                      (user: any) => userSelected === user.value
                    )
                  : null
              }
            />
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default FilterModal;
