import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getRadarOrderList = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.RADAR.ORDER_LIST, {
    params,
    signal,
  });

  return response;
};

export const getRadarOrderDetail = async (id: number, signal?: any) => {
  const response = await axiosInterceptor.get(
    `${API.RADAR.ORDER_DETAIL}/${id}`,
    { signal }
  );

  return response;
};

export const getRadarOrderChatDetail = async (url: string, signal?: any) => {
  const response = await axiosInterceptor.get(
    `${API.RADAR.ORDER_CHAT_DETAIL}/${url}`,
    { signal }
  );

  return response;
};

export const sendOrderChatMessage = async (payload: any) => {
  const response = await axiosInterceptor.post(
    `${API.RADAR.ORDER_SEND_CHAT_MESSAGE}`,
    payload
  );

  return response;
};

export const getRadarAiAgentDetails = async (queryParams: string) => {
  const response = await axiosInterceptor.get(
    `${API.RADAR.GET_RADAR_AI_AGENT_DETAILS}/${queryParams}`
  );

  return response;
};

export const addOrderAgentContactDetails = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.RADAR.ADD_ORDER_AGENT_CONTACT_DETAILS,
    payload
  );

  return response;
};

export const addPickupAiAgentReminder = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.RADAR.ADD_PICKUP_AI_AGENT_REMINDER,
    payload
  );

  return response;
};

export const addDeliveryAiAgentReminder = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.RADAR.ADD_DELIVERY_AI_AGENT_REMINDER,
    payload
  );

  return response;
};

export const addInTransitAiAgentFollowUp = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.RADAR.ADD_IN_TRANSIT_AI_AGENT_REMINDER,
    payload
  );

  return response;
};

export const addInFollowUpAiAgent = async (payload: any) => {
  const response = await axiosInterceptor.post(
    API.RADAR.ADD_FOLLOW_UPS_AI_AGENT,
    payload
  );

  return response;
};

export const getAiAgentDetails = async (queryParams: any) => {
  const response = await axiosInterceptor.get(
    `${API.RADAR.GET_AI_AGENT_DETAILS}`,
    { params: queryParams }
  );

  return response;
};

export const launchAiAgent = async (payload: any) => {
  const response = await axiosInterceptor.patch(
    API.RADAR.LAUNCH_AI_AGENT,
    payload
  );

  return response;
};
