import { ArrowUpRight, RefreshCw01 } from '@untitled-ui/icons-react/build/cjs';
import { minBy, orderBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { CARRIERS, CURRENCY, ORDER_STATUS, STATUS } from 'src/constants/common';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import { listCarrier } from 'src/services/CarrierService';
import { createRate, getQuote } from 'src/services/QuoteService';
import WalToast from 'src/utils/WalToast';

import { OrderContext } from '..';

import ActiveCarrierRowLoading from './ActiveCarrierRowLoading';
import ActiveCarrierRows from './ActiveCarrierRows';

interface IProps {
  setValue: any;
  watchCustomer: any;
}

const ActiveCarriers = ({ setValue, watchCustomer }: IProps) => {
  const { watch } = useFormContext();

  const {
    customerList,
    order,
    selectedQuote,
    activeCarrier,
    setActiveCarrier,
    setCarrierPrice,
    setSalesPrice,
    setIsValidAddress,
    isValidAddress,
  } = useContext<any>(OrderContext);

  const watchActiveCarrierId = watch('activeCarrierId');
  const watchQuoteId = watch('quoteId');

  const {
    currentUser,
    // setCurrentUser
  } = useContext(AuthContext);
  const { currency } = useContext(BasicContext);

  const [sortData, setSortData] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [isExpiredRefresh, setIsExpiredRefresh] = useState(false);
  const [isGetRate, setIsGetRate] = useState(false);
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [withRateList, setWithRateList] = useState<any[]>([]);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(true);
  const [carrierList, setCarrierList] = useState<any>([]);
  const [isRateLoading, setIsRateLoading] = useState(false);
  const [isRefresh, setIsRefresh] = useState(true);
  const [quote, setQuote] = useState<any>({});
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [isFinish, setIsFinish] = useState(false);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  useEffect(() => {
    if (selectedQuote || watchQuoteId) {
      setIsRefresh(true);
    }
  }, [selectedQuote, watchQuoteId]);

  const carrierRatesRef = useRef(carrierRates);

  const updateCarrierRates = (newCarrierRates: any) => {
    carrierRatesRef.current = newCarrierRates;
  };

  const sortDataByIsQuoted = (data: any, isSortByActive = false) =>
    data.sort((a: any, b: any) => {
      if (isSortByActive) {
        const aIsActive = a.carrierStatus === 'Active';
        const bIsActive = b.carrierStatus === 'Active';

        // Prioritize Active status
        if (aIsActive !== bIsActive) {
          return aIsActive ? -1 : 1; // Active first
        }
      }

      const aIsQuotedFalse = a?.services.some(
        (service: any) => service.isQuoted === false
      );
      const bIsQuotedFalse = b?.services.some(
        (service: any) => service.isQuoted === false
      );

      return aIsQuotedFalse === bIsQuotedFalse ? 0 : aIsQuotedFalse ? 1 : -1;
    });

  const sortCarrierRates = (carrierRatesValue: any, isSortTrue = false) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    let openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : carrierRate.isAmericanGroup
          ? 'American Group'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (
            carrierRate.carrierStatus ===
              (order?.id
                ? order?.orderStatus === ORDER_STATUS.BOOKING_FAILED
                  ? STATUS.OPENED
                  : STATUS.WON
                : STATUS.OPENED) ||
            carrierRate.carrierStatus === STATUS.ACTIVE
          ) {
            openedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortData.sortField,
      sortData.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    updateCarrierRates(allCarrierRates);
    setCarrierRates(allCarrierRates);

    if (!isSortTrue) {
      setWithRateList(
        sortDataByIsQuoted(
          orderBy(openedCarrierRates, sortData.sortField, sortData.sortType),
          true
        )
      );
    } else {
      setTimeout(() => {
        setWithRateList(
          sortDataByIsQuoted(
            orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
      }, 400);
    }
  };

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on && currentUser?.id) {
      socketIO.get(
        `/subscribe/${currentUser.id}`,
        function (data: any, jwr: any) {
          if (jwr.error) {
            return;
          }
        }
      );

      socketIO.on('user', function (data: any) {
        if (data.type === 'NEW_RATE') {
          if (
            data?.data &&
            data?.data?.quoteId === parseInt(selectedQuote?.quote?.id)
          ) {
            let newCarrierRates = [...carrierRatesRef.current, data.data];

            sortCarrierRates(newCarrierRates);
            setIsLoading(false);
          }
        } else if (data?.type === 'FINISH_LOADING') {
          carrierRateFetchStatus[data.carrier] = true;
          setCarrierRateFetchStatus((old: any) => ({
            ...old,
            [data.carrier]: true,
          }));
        }
      });
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('user');
      }
    };
  }, [isSocketConnected, currentUser.id]);

  useEffect(() => {
    if (selectedQuote?.quote?.id) {
      if (isRefresh) {
        setIsLoading(true);
        getQuote(selectedQuote?.quote?.id)
          .then((response: any) => {
            if (
              response &&
              response.data &&
              response.data.addressDetails &&
              response.data.addressDetails.status !== STATUS.PENDING
            ) {
              const responseData = response.data;

              setQuote(responseData);

              if (responseData?.addressDetails) {
                setAddressDetails(responseData?.addressDetails);
              }

              setIsLoading(false);

              if (
                responseData?.carrierRates &&
                responseData?.carrierRates.length
              ) {
                sortCarrierRates(responseData?.carrierRates);

                const filteredCarrier = responseData?.carrierRates.find(
                  (carrier: any) => carrier.id === watchActiveCarrierId
                );

                if (filteredCarrier) {
                  setActiveCarrier(filteredCarrier);

                  setIsValidAddress((old: any) => ({
                    ...old,
                    isActiveCarrierSelected: true,
                  }));
                }
              } else {
                setWithRateList([]);
              }
            } else {
              setIsLoading(false);
            }
          })
          .finally(() => setIsRefresh(false))
          .catch((error) => {
            console.error(error);
            setIsLoading(false);
            setIsRefresh(false);
          });
      }
    } else {
      setIsRefresh(false);
    }
  }, [isRefresh, watchQuoteId]);

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      setIsRateLoading(true);

      const ratePromises = carrierList.map((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));

        let formData = quote;
        formData.carrierId = element.id;
        formData.pickupDate = selectedQuote?.quote.pickupDate;

        return createRate(formData).catch((error: any) => {
          console.error(error);
          WalToast.error(
            error?.response?.data?.message ||
              'Carrier rates have not been created',
            ''
          );
        });
      });

      Promise.all(ratePromises)
        .then(() => {
          setIsRateLoading(false);
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
          setIsRateLoading(false);
        });
    }
  }, [isSocketConnected, carrierList]);

  useEffect(() => {
    if (isGetRate) {
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        type: 'connection',
      })
        .then((response: any) => {
          let carriers = response?.data || [];

          if (carriers?.length) {
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
            updateCarrierRates([]);
            setCarrierRates([]);

            if (isExpiredRefresh) {
              setWithRateList((old) =>
                old?.filter((data) => {
                  if (data.expirationDate) {
                    return new Date(data.expirationDate) > new Date();
                  } else {
                    return new Date(data.pickupDate) > new Date();
                  }
                })
              );

              let expiredCarrierArr: any = [];

              withRateList.map((data) => {
                let slug = data.isBanyan
                  ? CARRIERS.BANYAN.SLUG
                  : data.isFreightcom
                  ? CARRIERS.FREIGHTCOM.SLUG
                  : data.isAmericanGroup
                  ? CARRIERS.AMERICAN_GROUP.SLUG
                  : data.slug;

                console.log('data', data);

                if (data.expirationDate) {
                  if (new Date(data.expirationDate) <= new Date())
                    expiredCarrierArr.push(slug);
                } else {
                  if (new Date(data.pickupDate) <= new Date())
                    expiredCarrierArr.push(slug);
                }
              });

              let expiredCarrierList = carriers.filter((data: any) =>
                expiredCarrierArr.includes(data.slug)
              );

              setCarrierList(expiredCarrierList || []);
            } else {
              if (!isRefresh) {
                setWithRateList([]);
              }
              setCarrierList(carriers || []);
            }
          }
        })
        .catch(console.log);
    } else {
      setIsFinish(true);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setCarrierList([]);
      setIsFinish(true);
      setIsExpiredRefresh(false);
      setIsGetRate(false);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  useEffect(() => {
    let isAnyExpired = false;
    let isAllExpired = true;

    for (const data of withRateList) {
      const expirationDate = data.expirationDate
        ? moment(data.expirationDate).add(1, 'days')
        : moment(data.pickupDate).add(1, 'days');

      const isExpired = expirationDate.isBefore(moment().startOf('day'));

      if (!isExpired) {
        isAllExpired = false;
      } else {
        isAnyExpired = true;
      }

      if (!isAllExpired && isAnyExpired) {
        break;
      }
    }
  }, [withRateList]);

  const handleSelectActiveCarrier = (data: any) => {
    setActiveCarrier(data);
    setValue('activeCarrierId', data?.id);

    setIsValidAddress((old: any) => ({
      ...old,
      isActiveCarrierSelected: true,
    }));

    let providerName = data?.slug;

    if (data?.isBanyan || data?.isBanyan == 1) {
      providerName = 'BANYAN';
    } else if (data?.isFreightcom || data?.isFreightcom == 1) {
      providerName = 'FREIGHTCOM';
    } else if (data?.isAmericanGroup || data?.isAmericanGroup == 1) {
      providerName = 'AMERICAN GROUP';
    }

    const carrierPriceData = {
      carrierId: data?.carrierId,
      budgetCost: undefined,
      baseCarrierRate: data?.totalCharge,
      carrierBookingDate: null,
      fuel: undefined,
      fuelPercentage: undefined,
      totalPrice: data?.totalCostCAD,
      carrier: {
        name: data?.name,
        image: data?.image ? data?.imageUrl + data?.image : null,
        providerName,
      },
      additional_charge: data?.services?.map((service: any) => ({
        id: 0,
        charge: service?.serviceId?.slug ?? null,
        price: service?.value ?? null,
        otherCharge: null,
      })) || [
        {
          id: 0,
          charge: null,
          price: null,
          otherCharge: null,
        },
      ],
    };

    const baseSalesPrice: any =
      data?.finalCharge && (data?.finalChargeUSD || currency === CURRENCY.CAD)
        ? currency === CURRENCY.CAD
          ? data?.finalCharge.toFixed(2)
          : data?.finalChargeUSD.toFixed(2)
        : 0;

    const totalAdditionalCharge = data?.services?.reduce(
      (sum: number, service: any) => sum + (service?.value ?? 0),
      0
    );

    const customerData = customerList?.find(
      (item: any) => item.value === watchCustomer
    );

    const salesPriceData = {
      customerId: watchCustomer,
      customer: { name: customerData?.label, image: customerData?.image },
      baseSalePrice: baseSalesPrice,
      fuel: undefined,
      fuelPercentage: undefined,
      totalSalePrice:
        (parseFloat(baseSalesPrice) || 0) + (totalAdditionalCharge || 0),
      additional_charge: data?.services?.map((service: any) => ({
        id: 0,
        charge: service?.serviceId?.slug ?? null,
        price: service?.value ?? null,
        otherCharge: null,
      })) || [
        {
          id: 0,
          charge: null,
          price: null,
          otherCharge: null,
        },
      ],
    };

    setValue('salesPrice', [salesPriceData]);
    setSalesPrice([salesPriceData]);

    setValue('carrierPrice', [carrierPriceData]);
    setCarrierPrice([carrierPriceData]);
  };

  return (
    <>
      <div>
        <div className="flex justify-between items-center mb-1">
          <h6 className="text-textSecondary text-sm font-medium leading-[1.5]">
            Select Active Quotes
          </h6>
          <div className="flex gap-x-3">
            <ButtonCmp
              className="btn-outline-primary !p-0 !shadow-none !bg-transparent !border-0 gap-1"
              icon={<ArrowUpRight className="w-4 h-4" />}
              iconSide="right"
              onClick={() => {
                window.open(
                  `${ROUTES.QUOTING_DASHBOARD}/${selectedQuote?.quote?.id}`,
                  '_blank'
                );
              }}
            >
              Open Quote
            </ButtonCmp>

            {/* Get Refresh Rates */}
            {!order?.id && (
              <ButtonCmp
                className="btn-outline-primary !p-0 !shadow-none !bg-transparent !border-0 gap-1"
                icon={<RefreshCw01 className="w-4 h-4" />}
                iconSide="right"
                onClick={() => {
                  setIsRefresh(true);
                }}
              >
                Refresh
              </ButtonCmp>
            )}
          </div>
        </div>
        <div
          className={`rounded-xl border ${
            !isValidAddress?.isActiveCarrierSelected
              ? 'border-red-500'
              : 'border-utilityGray200'
          } bg-gray25 shadow-xs p-5 mb-4 last:mb-0`}
        >
          <div
            className={`overflow-x-auto custom-scrollbar scrollbar-hide -my-3`}
          >
            {withRateList.length >= 0 && (
              <TableCmp
                tableWrapClass="table-with-spacing table-box"
                // tableHeaderClass="hidden"
                headCells={[]}
                tableDataArr={withRateList}
                TableLoaderRowCmp={ActiveCarrierRowLoading}
                TableRowCmp={ActiveCarrierRows}
                params={sortData}
                setParams={setSortData}
                tableRowCmpProps={{
                  currency: currency,
                  addressDetails: addressDetails,
                  onRowClick: (data: any) => handleSelectActiveCarrier(data),
                  activeCarrier,
                }}
                isTableDataLoading={!isFinish || isLoading || isRateLoading}
                isTableDataLoadingWithData={!isAllCarrierRateFetched}
                numberOfSkeletonRows={5}
                isTableRowClickable={true}
                isTableRowClickableCondition={(data: any) => {
                  const expDate = new Date(data.expirationDate);
                  const today = new Date();
                  expDate.setDate(expDate.getDate() + 1);

                  const diffTime = expDate.getTime() - today.getTime();
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                  if (diffDays < 0) {
                    return false;
                  }

                  return true;
                }}
                tableBodyRowClass={``}
                getRowClass={(data: any) => {
                  let isNonQuotedServicesExist = false;

                  if (data?.services.some((form: any) => !form.isQuoted)) {
                    isNonQuotedServicesExist = true;
                  }

                  let isExpiredRate = false;
                  const expDate = new Date(data.expirationDate);
                  const today = new Date();
                  expDate.setDate(expDate.getDate() + 1);

                  const diffTime = expDate.getTime() - today.getTime();
                  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                  if (diffDays < 0) {
                    isExpiredRate = true;
                  }

                  return isNonQuotedServicesExist || isExpiredRate
                    ? 'bg-utilityGray100 hover:bg-utilityGray200'
                    : '';
                }}
              />
            )}

            {withRateList.length <= 0 &&
              !isLoading &&
              !isRateLoading &&
              isFinish && (
                <NotFoundUI
                  title="No Quote Found"
                  desc="No active quotes are available at the moment"
                  containerClassName="flex-1 !h-auto"
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActiveCarriers;
