import { ArrowUpRight, Calendar } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AdditionalServiceCmp from 'src/app/Orders/Common/AdditionalServiceCmp';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { BasicContext } from 'src/context/BasicContext';
import { formatDateValue } from 'src/utils/CommonFunctions';
import { getDateWithSuffixFormat } from 'src/utils/CommonFunctions';

interface IProps {
  control: any;
  errors: any;
  data: any;
  onAutocompleteChange: any;
  onAddressInputChange: any;
  title1: any;
  title2: any;
  title3: any;
  status: any;
  handleActionType: any;
  isAddressExits: any;
  isValidAddress: any;
  selectBoxOptionList: any;
  handleSelectChange: any;
  setIsTailgate: any;
  currentLegData: any;
  setCurrentLegData: any;
  setValue: any;
  serviceList: any;
  saveShipperDetails: any;
  shipperBtnDisable: any;
  isShipperContactBtnDisable: any;
  setIsValidAddress: any;
  contactList: any;
  handleContactSelectChange: any;
  isPhoneNumberValue: any;
  setIsPhoneNumberValue: any;
}

const ShipperAndConsigneeAddressForm = ({
  control,
  errors,
  data,
  onAutocompleteChange,
  onAddressInputChange,
  title1,
  title2,
  title3,
  status,
  handleActionType,
  isAddressExits,
  isValidAddress,
  selectBoxOptionList,
  handleSelectChange,
  setIsTailgate,
  currentLegData,
  setCurrentLegData,
  setValue,
  serviceList,
  saveShipperDetails,
  shipperBtnDisable,
  isShipperContactBtnDisable,
  setIsValidAddress,
  contactList,
  handleContactSelectChange,
  isPhoneNumberValue,
  setIsPhoneNumberValue,
}: IProps) => {
  const { mapService } = useContext(BasicContext);
  const { clearErrors, setError, watch } = useFormContext();

  const shipperId = watch(`${data}.id`);
  const contactId = watch(`${data}.contactId`);
  const companyName = watch(`${data}.companyName`);
  const addressData = watch(`${data}.${data}Address.fullAddress`);

  return (
    <>
      <div>
        <h6 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
          {title1}
        </h6>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0">
          <div className="flex flex-wrap sm:-mx-1.5 -my-2">
            <Controller
              name={`${data}.id`}
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <SelectBox
                  label={`${title1}`}
                  labelClassName="form_label block mb-1.5"
                  name={`${data}Id`}
                  id={`${data}Id`}
                  size="sm"
                  className={`form_control`}
                  isError={!!error?.message || !isValidAddress?.[`${data}Id`]}
                  placeholder={`Select a ${title1}`}
                  noOptionMessage={`No ${title1} Found`}
                  isSearchable={true}
                  parentClassName="flex-1 px-1.5 py-2"
                  isClearable={true}
                  options={selectBoxOptionList}
                  onChangeFunc={handleSelectChange(`${data}Id`)}
                  value={
                    value
                      ? selectBoxOptionList?.find((e: any) => e?.value == value)
                      : null
                  }
                  errorText={error ? error.message : null}
                />
              )}
            />

            <Controller
              name={`${
                data === 'shipper'
                  ? 'expectedPickupTime'
                  : 'expectedEstimatedDeliveryTime'
              }`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DateSelect
                  parentClassName="sm:w-1/2 px-1.5 py-2"
                  label={data === 'shipper' ? 'Pickup Date' : 'Delivery Date'}
                  inputName={`${data}PickupDate`}
                  className={`form_control`}
                  placeholder={`Select ${
                    data === 'shipper' ? 'Pickup' : 'Delivery'
                  } Date`}
                  dateFormat="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  selected={
                    value
                      ? moment(value).local().toDate()
                      : moment().local().toDate()
                  }
                  value={
                    value
                      ? getDateWithSuffixFormat(formatDateValue(value))
                      : getDateWithSuffixFormat(
                          formatDateValue(moment().local().toDate())
                        )
                  }
                  errorText={error ? error.message : null}
                  onChangeFunc={(e: any) => {
                    onChange(e?.value);

                    clearErrors(
                      `${
                        data === 'shipper'
                          ? 'expectedPickupTime'
                          : 'expectedEstimatedDeliveryTime'
                      }`
                    );
                  }}
                  icon={<Calendar className="h-5 w-5" />}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between mb-1 ">
          <h6 className="text-textSecondary text-sm font-medium leading-[1.5]">
            {title2}
          </h6>
          {companyName && addressData && (
            <div className="flex gap-1.5 px-1.5">
              {shipperId && (
                <>
                  <ButtonCmp
                    onClick={handleActionType(data, 'delete')}
                    className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 `}
                  >
                    Delete
                    {/* {title1} */}
                  </ButtonCmp>
                  <p className="w-px bg-borderPrimary"></p>
                </>
              )}
              <ButtonCmp
                className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 ${
                  shipperBtnDisable && 'text-opacity-50'
                }`}
                onClick={() => {
                  saveShipperDetails(data, data)();
                }}
                disabled={shipperBtnDisable}
              >
                {shipperId ? 'Update' : `Save ${title1}`}
              </ButtonCmp>
            </div>
          )}
        </div>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0">
          <div className="flex flex-wrap sm:-mx-1.5 -my-2">
            <Controller
              name={`${data}.companyName`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  inputName="companyName"
                  label="Company Name"
                  placeholder="Enter Company Name"
                  value={value}
                  labelClassName="block mb-1.5"
                  parentClassName="sm:px-1.5 py-2 sm:w-1/2"
                  className={`${
                    !isValidAddress?.[`${data}CompanyName`] || error?.message
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  onChangeFunc={(e) => {
                    onChange(e);

                    if (error?.message) {
                      clearErrors(`${data}.companyName`);
                    }
                  }}
                  required
                  // errorText={errors?.[data]?.companyName?.message}
                />
              )}
            />

            {status && (
              <Controller
                name={`${data}.${data}Address.fullAddress`}
                control={control}
                render={({ fieldState: { error } }) =>
                  mapService === 'trimble_map' ? (
                    <AutoCompleteTrimble
                      inputName={`fullAddress`}
                      required
                      placeholder="Enter address"
                      dropdownWrapClass="!top-[unset] bottom-[65%]"
                      className={`form_control truncate ${
                        !isValidAddress?.[`${data}Address`] || error?.message
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      label="Address"
                      labelClassName="mb-1.5 block w-full"
                      mapParentClass="sm:px-1.5 py-2 sm:w-1/2"
                      labelRight={
                        <ButtonCmp
                          className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5"
                          onClick={() => {
                            handleActionType(
                              data,
                              isAddressExits ? 'addressUpdate' : 'addressCreate'
                            )();
                          }}
                          icon={
                            <ArrowUpRight className="w-4 h-4 text-primary700" />
                          }
                          iconSide="right"
                        >
                          View Address
                        </ButtonCmp>
                      }
                      onChangeFunc={onAutocompleteChange}
                      onBlur={onAutocompleteChange}
                      isClearable
                      onAddressInputChange={onAddressInputChange}
                      value={isAddressExits}
                      errorText={error ? error.message : null}
                    />
                  ) : (
                    <AutoCompleteGoogle
                      inputName={`fullAddress`}
                      required
                      placeholder="Enter address"
                      dropdownWrapClass="!top-[unset] bottom-[65%]"
                      className={`form_control truncate ${
                        !isValidAddress?.[`${data}Address`] || error?.message
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      label="Address"
                      labelClassName="mb-1.5 block w-full"
                      mapParentClass="sm:px-1.5 py-2 sm:w-1/2"
                      labelRight={
                        <ButtonCmp
                          className="btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5"
                          onClick={() => {
                            handleActionType(
                              data,
                              isAddressExits ? 'addressUpdate' : 'addressCreate'
                            )();
                          }}
                          icon={
                            <ArrowUpRight className="w-4 h-4 text-primary700" />
                          }
                          iconSide="right"
                        >
                          View Address
                        </ButtonCmp>
                      }
                      onChangeFunc={onAutocompleteChange}
                      onBlur={onAutocompleteChange}
                      isClearable
                      onAddressInputChange={onAddressInputChange}
                      value={isAddressExits}
                      errorText={error ? error.message : null}
                    />
                  )
                }
              />
            )}

            <AdditionalServiceCmp
              setIsTailgate={setIsTailgate}
              currentLegData={currentLegData}
              setCurrentLegData={setCurrentLegData}
              setValue={setValue}
              title={
                data === 'shipper'
                  ? 'Pickup Additional Services'
                  : 'Delivery Additional Services'
              }
              serviceList={serviceList}
              showService={data === 'shipper' ? 'pickup' : 'delivery'}
              isAccordianOpen={true}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center justify-between mb-1 ">
          <h6 className="text-textSecondary text-sm font-medium leading-[1.5]">
            {title3}
          </h6>
          <div className="flex gap-1.5 px-1.5">
            {contactId && (
              <>
                <ButtonCmp
                  onClick={() => handleActionType(data, 'deleteContact')()}
                  className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5`}
                >
                  Delete
                </ButtonCmp>
                <p className="w-px bg-borderPrimary"></p>
              </>
            )}
            <ButtonCmp
              className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 ${
                isShipperContactBtnDisable && 'text-opacity-50'
              }`}
              onClick={() => {
                saveShipperDetails(data, `${data}Contact`)();
              }}
              disabled={isShipperContactBtnDisable}
            >
              {contactId ? 'Update' : 'Save Contact'}
            </ButtonCmp>
          </div>
        </div>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5 mb-4 last:mb-0">
          <div className="flex flex-wrap sm:-mx-1.5 -my-2">
            <Controller
              name={`${data}.contactId`}
              control={control}
              render={({ field: { value }, fieldState: { error } }) => (
                <SelectBox
                  label={`Contact`}
                  labelClassName="form_label block mb-1.5"
                  name={`${data}ContactId`}
                  id={`${data}ContactId`}
                  size="sm"
                  placeholder={`Select a Contact`}
                  noOptionMessage={`No Contact Found`}
                  isSearchable={true}
                  className={`form_control ${
                    error?.message ? 'border border-red-500 border-solid' : ''
                  }`}
                  parentClassName="flex-1 px-1.5 py-2"
                  isClearable={true}
                  options={contactList}
                  onChangeFunc={handleContactSelectChange(`${data}ContactId`)}
                  value={
                    value
                      ? contactList?.find((e: any) => e?.value == value)
                      : null
                  }
                  showCustomerImage={true}
                  menuPlaceOption="top"
                  // errorText={error ? error.message : null}
                />
              )}
            />

            <Controller
              name={`${data}.contactName`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  inputName="contact_name"
                  label="Contact Name"
                  placeholder="Enter Contact Name"
                  labelClassName="block mb-1.5"
                  className={`${
                    !isValidAddress?.[`${data}ContactName`] || error?.message
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  parentClassName="sm:px-1.5 py-2 sm:w-1/4"
                  value={value}
                  onChangeFunc={(e) => {
                    if (error?.message) {
                      clearErrors(`${data}.contactName`);
                    }

                    setIsValidAddress((prev: any) => ({
                      ...prev,
                      [`${data}ContactName`]: true,
                    }));

                    onChange(e);
                  }}
                  required
                  // errorText={errors?.[data]?.contactName?.message}
                />
              )}
            />

            <div className="sm:px-1.5 py-2 sm:w-1/4">
              <label className="form_label block mb-1.5">
                Phone number
                <span className="text-red-600 ms-[2px] leading-4">*</span>
              </label>
              <Controller
                name={`${data}.phoneNumber`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <PhoneInput
                    name="phone"
                    phoneInputParentClassName={`${
                      !isValidAddress?.[`${data}PhoneNumber`] || error?.message
                        ? ''
                        : ''
                    }`}
                    onNumberChange={(
                      country: any,
                      phone: any,
                      code: any
                      // isValid: boolean
                    ) => {
                      onChange(`+${code}${phone}`);

                      clearErrors(`${data}.phoneNumber`);

                      // if (isValid || phone === '' || phone) {
                      //   clearErrors(`${data}.phoneNumber`);
                      // } else {
                      //   setError(`${data}.phoneNumber`, {
                      //     type: 'manual',
                      //     message: 'Contact phone number is invalid.',
                      //   });
                      // }

                      // setIsValidAddress((prev: any) => ({
                      //   ...prev,
                      //   [`${data}PhoneNumber`]: true,
                      // }));
                    }}
                    onBlur={(
                      country: any,
                      phone: any,
                      code: any,
                      isValid: boolean
                    ) => {
                      if (isValid || phone === '') {
                        clearErrors(`${data}.phoneNumber`);
                      } else {
                        setError(`${data}.phoneNumber`, {
                          type: 'manual',
                          message: 'Contact phone number is invalid.',
                        });
                      }

                      setIsValidAddress((prev: any) => ({
                        ...prev,
                        [`${data}PhoneNumber`]: true,
                      }));
                    }}
                    isPhoneNumberValue={isPhoneNumberValue}
                    setIsPhoneNumberValue={setIsPhoneNumberValue}
                    errors={errors?.[data]?.phoneNumber?.message}
                    isShowErrorMsg={false}
                    value={value}
                  />
                )}
              />
            </div>

            <Controller
              name={`${data}.email`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  inputName="email"
                  label="Email"
                  placeholder="Enter Email"
                  value={value}
                  className={`${
                    !isValidAddress?.[`${data}Email`] || error?.message
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  labelClassName="block mb-1.5"
                  parentClassName="sm:px-1.5 py-2 sm:w-1/4"
                  onChangeFunc={(e) => {
                    onChange(e);

                    if (error?.message) {
                      clearErrors(`${data}.email`);
                    }

                    setIsValidAddress((prev: any) => ({
                      ...prev,
                      [`${data}Email`]: true,
                    }));
                  }}
                  required
                  // errorText={errors?.[data]?.email?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShipperAndConsigneeAddressForm;
