export interface IProps {
  addressDetails: any;
  activePostIndex: any;
  setActivePostIndex: any;
  loadLinkPosts: any;
  setLoadLinkPosts: any;
  loadLinkPostingDetail: any;
  validatePostToLoadBoardForm: any;
  setValidatePostToLoadBoardForm: any;
  setIsPostCreateLoading: any;
  setSyncLiveData: any;
  syncLiveData: any;
  setLoadLinkPostingDetail: any;
  isUpdateLoadPost: any;
  setIsUpdateLoadPost: any;
  isCreateLoadPost: any;
  setIsCreateLoadPost: any;
  isRefresh: any;
  setIsRefresh: any;
  rateGatheringWithAi: any;
  setCurrentTab: any;
  setRefreshLoadPosting: any;
  stopRateGathering: any;
  isStopRateLoading: any;
  showTimerLoader: boolean;
  setShowTimerLoader: any;
  servicesList: any[];
}

export interface ITransportLead {
  createdAt: string;
  updatedAt: string;
  id: number;
  leadToken: string;
  dateAvailability: string;
  sourceCity: string;
  sourceState: string;
  sourceCountry: string;
  destinationCity: string;
  destinationState: string;
  destinationCountry: string;
  vehicleSize: string;
  vehicleType: string;
  tooltipVehicleType: string;
  dfo: string;
  dirO: string;
  dfd: string;
  vehicleAttributes: string;
  company: string;
  companyPhone: string;
  companyEmail: string;
  createdOn: string;
  isNew: boolean;
  postId: number;
  comment?: string;
  notes?: string;
  callStatus: string;
  callClassificationTag: string;
  callType: string;
  carrierContactPerson: string;
  offerAmount: string;
  estimatedDeliveryDate: string;
  callStartedAt: string;
  callDuration: string;
  carrierEquipmentType: string;
}

export interface ILeadRowProps {
  data: ITransportLead;
  activeLeadType: string;
  setSelectedCarrierRates: any;
  setIsShowTypeInfoModal: any;
  rates: any;
}

export enum LoadBoardCellId {
  Carrier = 'company',
  CallStatus = 'callStatus',
  OutreachMethod = 'callType',
  CallDuration = 'callDuration',
  CarrierContactPerson = 'carrierContactPerson',
  Rate = 'offerAmount',
  EstDeliveryDate = 'estimatedDeliveryDate',
  Origin = 'sourceCity',
  DFO = 'dfo',
  Destination = 'destinationCity',
  DFD = 'dfd',
  EquipmentType = 'vehicleType',
  Comment = 'comment',
  Notes = 'notes',
  Availability = 'dateAvailability',
  Posted = 'createdOn',
}

export enum QuoteType {
  QUOTED = 'quoted',
  NON_QUOTED = 'non_quoted',
  WITH_RATE = 'with_rate',
  LOAD_BOARD = 'load_board',
  LANE_HISTORY = 'lane_history',
  PREDICTIVE_PRICING = 'predictive_pricing',
}
