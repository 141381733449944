import { Link01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import {
  creditSafeCompanyList,
  linkCompanyToCreditSafe,
} from 'src/services/CustomerService';

import CreditSafeCompanyLoadingRow from './CreditSafeCompanyLoadingRow';
import CreditSafeCompanyRow from './CreditSafeCompanyRow';

interface IProps {
  customerId: any;
  handleClose: any;
  setIsRefresh: any;
  setIsRefreshCreditDetail: any;
}

export const initParams = {
  page: 1,
  pageSize: 10,
};

const FindAndLinkCompanyToCreditSafeModal = ({
  customerId,
  handleClose,
  setIsRefresh,
  setIsRefreshCreditDetail,
}: IProps) => {
  const [params, setParams] = useState(initParams);
  const [isCreditSafeCompanyListLoading, setIsCreditSafeCompanyListLoading] =
    useState(true);
  const [isLinkCompanyToCreditSafe, setIsLinkCompanyToCreditSafe] =
    useState(false);
  const [creditSafeCompanies, setCreditSafeCompanies] = useState<any>([]);
  const [creditSafeCompaniesTotal, setCreditSafeCompaniesTotal] = useState(0);
  const [selectedCreditSafeCompanyId, setSelectedCreditSafeCompanyId] =
    useState(undefined);

  useEffect(() => {
    setIsCreditSafeCompanyListLoading(true);
    setCreditSafeCompanies([]);

    creditSafeCompanyList({
      ...params,
      customerId: customerId,
    })
      .then((response: any) => {
        setCreditSafeCompanies(response?.data);
        setCreditSafeCompaniesTotal(response?.total ?? 0);
        setIsCreditSafeCompanyListLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
    console.log(setIsRefresh);
  }, [customerId, params]);

  const headCells = useMemo(
    () => [
      {
        id: 'id',
        name: 'Id',
      },
      {
        id: 'id2',
        name: 'Id2',
      },
      {
        id: 'country',
        name: 'Country',
      },
      {
        id: 'safeNo',
        name: 'Safe No',
      },
      {
        id: 'name',
        name: 'Name',
      },
      {
        id: 'address',
        name: 'Address',
      },
      {
        id: 'status',
        name: 'Status',
      },
      {
        id: 'officeType',
        name: 'Office Type',
      },
      {
        id: 'type',
        name: 'Type',
      },
      {
        id: 'webSite',
        name: 'Website',
      },
      {
        id: 'regNo',
        name: 'Reg No',
      },
      {
        id: 'Headquarters',
        name: 'Headquarters',
      },
      {
        id: 'phoneNumbers',
        name: 'Phone Numbers',
      },
      {
        id: 'activityCode',
        name: 'Activity Code',
      },
      {
        id: 'significantItems',
        name: 'Significant Items',
      },
      {
        id: 'fileNo',
        name: 'File No',
      },
      {
        id: 'groupId',
        name: 'Group Id',
      },
    ],
    []
  );

  const linkCompany = () => {
    setIsLinkCompanyToCreditSafe(true);
    linkCompanyToCreditSafe(selectedCreditSafeCompanyId, customerId)
      .then(() => {
        setIsLinkCompanyToCreditSafe(false);
        setIsRefreshCreditDetail(true);
        handleClose(false);
      })
      .catch((e: any) => {
        handleClose(false);
        setIsLinkCompanyToCreditSafe(false);
        console.error(e);
      });
  };

  return (
    <CommonModal
      title={`Link Customer To CreditSafe (${creditSafeCompaniesTotal})`}
      titleDesc={'Find and link current customer to credit safe.'}
      handleClose={() => handleClose(false)}
      headerIcon={<Link01 />}
      size={'max-w-[900px]'}
      isOverflow={true}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Link"
      primaryBtnOnClick={linkCompany}
      primaryBtnDisabled={isLinkCompanyToCreditSafe}
      primaryBtnLoading={isLinkCompanyToCreditSafe}
      // secondaryBtnLoading={}
    >
      <div className="p-5">
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col overflow-hidden">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header">
            <TableCmp
              headCells={headCells}
              params={params}
              setParams={setParams}
              tableDataArr={creditSafeCompanies}
              TableRowCmp={CreditSafeCompanyRow}
              TableLoaderRowCmp={CreditSafeCompanyLoadingRow}
              tableHeaderClass=""
              isTableDataLoading={isCreditSafeCompanyListLoading}
              tableRowCmpProps={{
                selectedCreditSafeCompanyId: selectedCreditSafeCompanyId,
                setSelectedCreditSafeCompanyId: setSelectedCreditSafeCompanyId,
              }}
            />
          </div>

          {!creditSafeCompanies?.length && !isCreditSafeCompanyListLoading && (
            <NotFoundUI
              title="No Customer Found"
              desc="There are no data for Customer."
              containerClassName="min-h-[unset]"
            />
          )}

          {creditSafeCompanies?.length ? (
            <CustomPagination
              recordsPerPage={params.pageSize}
              totalRecords={creditSafeCompaniesTotal}
              currentPage={params.page}
              handlePagination={(page: number) => {
                setParams((old) => ({ ...old, page }));
              }}
            />
          ) : null}
        </div>
      </div>
    </CommonModal>
  );
};

export default FindAndLinkCompanyToCreditSafeModal;
