import {
  Calendar,
  SwitchVertical01,
  InfoCircle,
  SearchLg,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import StepBar from 'src/components/StepBar/StepBar';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';

import ShipperAddress from '../..//Common/ShipperAddress';
import ButtonCmp from '../../../../components/ButtonCmp';
import DateSelect from '../../../../components/DateSelect/DateSelect';
import DeleteModal from '../../../../components/DeleteModal';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import { DATE_FORMAT, STATUS } from '../../../../constants/common';
import { ROUTES } from '../../../../constants/routes';
import { RecentCustomersList } from '../../../../services/CustomerService';
import { getQuote } from '../../../../services/QuoteService';
import {
  createShipper,
  deleteShippers,
  updateShipper,
} from '../../../../services/ShipperService';
import {
  formatDateValue,
  getDateWithSuffixFormat,
  getSelectBoxOptions,
  loadGoogleMapsScript,
} from '../../../../utils/CommonFunctions';
import ConsigneeAddress from '../../Common/ConsigneeAddress';
import QuoteEmailDetailView from '../QuoteEmailDetails/QuoteEmailDetailView';

import Address from './Address';
import {
  classOptions,
  IFormData,
  IMapAddress,
  initAction,
  initConsigneeAddress,
  initFormData,
  initSelectQuoteFormData,
  initShipperAddress,
  initValidForm,
  limitedAccessList,
  scriptId,
  STEPS,
} from './Address.interface';

const initServiceValidForm = {
  limitedAccessOther: true,
};
// eslint-disable-next-line
const AddressDetailsForm = ({
  serviceTypes,
  serviceList,
  currentStep,
  setCurrentStep,
  quoteFormData,
  setQuoteFormData,
  isServiceLoading,
}: any) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { appearance } = useContext(BasicContext);
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const initSelectFormData = {
    customerId: quoteFormData?.customerId ?? null,
    shipperId: quoteFormData?.shipperId ?? null,
    consigneeId: quoteFormData?.consigneeId ?? null,
  };
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();

  const {
    googleKey,
    isMapLoading,
    getShipper,
    isShipperLoading,
    shippers,
    consignees,
    shipperOptions,
    consigneeOptions,
    setSearchShipper,
    setSearchConsignee,
  } = useContext(BasicContext);

  const [customers, setCustomers] = useState<any>([]);

  const [formData, setFormData] = useState<IFormData>({
    ...(quoteFormData ?? {
      ...initFormData,
      pickupDate: moment()
        .tz(appearance?.timezone ?? browserTimezone)
        .format('YYYY-MM-DD HH:mm:ss'),
      linearFootage: null,
      totalWeight: null,
    }),
  });
  const [isCustomerLoading, setIsCustomerLoading] = useState(true);
  const [customerSearchLoading, setCustomerSearchLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectFormData, setSelectFormData] = useState<any>(initSelectFormData);
  const [selectQuoteFormData, setSelectQuoteFormData] = useState<any>(
    initSelectQuoteFormData
  );
  const [customerOptions, setCustomerOptions] = useState<any>([]);
  const [shipperAddress, setShipperAddress] = useState('');
  const [shipperSelectedServices, setShipperSelectedServices] = useState<any>(
    []
  );
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const [consigneeSelectedServices, setConsigneeSelectedServices] =
    useState<any>([]);
  const [action, setAction] = useState(initAction);
  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);
  const [isCustomerDeleted, setIsCustomerDeleted] = useState(false);
  const [isSwapAddress, setIsSwapAddress] = useState(true);
  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');
  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    consigneeAddress: true,
  });
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [validForm, setValidForm] = useState(initValidForm);
  const [isShipperActivityLoading, setIsShipperActivityLoading] =
    useState(false);
  const [isConsigneeActivityLoading, setIsConsigneeActivityLoading] =
    useState(false);

  const [isTailgate, setIsTailgate] = useState<any>(
    quoteFormData?.services?.isTailgate ?? []
  );

  const [selectedServices, setSelectedServices] = useState<number[]>(
    quoteFormData?.services?.services ?? []
  );
  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(
    quoteFormData?.services?.limitedAccess ?? false
  );

  const [limitedAccessTypeList, setLimitedAccessTypeList] =
    useState<any>(limitedAccessList);

  const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    quoteFormData?.services?.limitedAccessType ??
      limitedAccessTypeList?.[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>(
    quoteFormData?.services?.limitedAccessOther ?? ''
  );
  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(false);

  const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
    useState<boolean>(false);
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [search, setSearch] = useState<any>(null);
  const isMapScript = document.getElementById(scriptId);

  const checkPickupDate = (value: any) => {
    const pickupDate = moment(value).toDate();
    const currentDateTime = moment().toDate();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = moment().startOf('day').toDate();
    const onePM = moment()
      .startOf('day')
      .hour(13)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  useEffect(() => {
    if (!allowHandleChecked && !isLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isLoading]);

  useEffect(() => {
    if (isLimitedMore && allowHandleChecked) {
      setIsAccessTypeModalOpen(true);
    }
  }, [isLimitedMore]);

  useEffect(() => {
    let filterTypes = limitedAccessList.filter((type: any) => {
      if (type.value === limitedAccessType) {
        return true;
      }

      return type.name
        .toLowerCase()
        .includes(limitedAccessSearch.toLowerCase());
    });
    setLimitedAccessTypeList(filterTypes);
  }, [limitedAccessSearch]);

  useEffect(() => {
    if (shipperAddress || consigneeAddress) {
      setIsSwapAddress(false);
    } else {
      setIsSwapAddress(true);
    }
  }, [shipperAddress, consigneeAddress]);

  useEffect(() => {
    if (!isMapScript && googleKey) {
      loadGoogleMapsScript(googleKey, scriptId);
    }
  }, [isMapScript, googleKey]);

  const handleSelectChange = (name: any) => (event: any) => {
    const newData: any = {};

    newData[name] = event ? event.value : null;

    if (name === 'isInsureLoad') {
      newData[name] = event?.checked;
    } else {
      newData[name] = event ? event.value : null;
    }

    if (name === 'shipperId') {
      if (event && event.value) {
        const shipper = shippers.find(
          (shipperData: any) => shipperData.id === event.value
        );
        newData.shipperCompanyName = shipper.companyName;
        newData.shipperAddress = {
          fullAddress: shipper.fullAddress,
          address1: shipper.address1,
          postal: shipper.postal,
          city: shipper.city,
          state: shipper.state,
          country: shipper.country,
          longitude: shipper.longitude,
          latitude: shipper.latitude,
          stateCode: shipper.stateCode,
          countryCode: shipper.countryCode,
        };
        setSelectedServices((old) => [...old, ...shipper.additionalServiceIds]);
        setShipperSelectedServices(shipper.additionalServiceIds);
        setIsShipperBtnDisable(false);
        setShipperAddress(shipper.fullAddress);

        if (consigneeAddress !== null && consigneeAddress !== '') {
          setIsSwapAddress(false);
        }
      } else {
        newData.shipperCompanyName = '';
        newData.shipperAddress = initShipperAddress;
        setShipperAddress('');
        setIsSwapAddress(true);

        if (shipperSelectedServices && shipperSelectedServices.length) {
          const currentServiceIds = selectedServices || [];

          if (Array.isArray(currentServiceIds) && currentServiceIds.length) {
            const filteredServiceIds = currentServiceIds.filter(
              (shipperServiceId) =>
                !shipperSelectedServices.includes(shipperServiceId)
            );
            setSelectedServices(filteredServiceIds);
          }

          setShipperSelectedServices([]);
        }
      }
    } else if (name === 'consigneeId') {
      if (event && event.value) {
        const consignee = consignees.find(
          (consigneeData: any) => consigneeData.id === event.value
        );
        newData.consigneeCompanyName = consignee.companyName;
        newData.consigneeAddress = {
          fullAddress: consignee.fullAddress,
          address1: consignee.address1,
          postal: consignee.postal,
          city: consignee.city,
          state: consignee.state,
          country: consignee.country,
          longitude: consignee.longitude,
          latitude: consignee.latitude,
          stateCode: consignee.stateCode,
          countryCode: consignee.countryCode,
        };
        setIsConsigneeBtnDisable(false);
        setConsigneeSelectedServices(consignee.additionalServiceIds);
        setSelectedServices((old) => [
          ...old,
          ...consignee.additionalServiceIds,
        ]);
        setConsigneeAddress(consignee.fullAddress);
        setLimitedAccessType(consignee.limitedAccessType);

        if (shipperAddress !== null && shipperAddress !== '') {
          setIsSwapAddress(false);
        }
      } else {
        newData.consigneeCompanyName = '';
        newData.consigneeAddress = initConsigneeAddress;
        setConsigneeAddress('');
        setIsSwapAddress(true);
        setLimitedAccessType(limitedAccessTypeList?.[0].value);

        if (consigneeSelectedServices && consigneeSelectedServices.length) {
          const currentServiceIds = selectedServices || [];

          if (Array.isArray(currentServiceIds) && currentServiceIds.length) {
            const filteredServiceIds = currentServiceIds.filter(
              (consigneeServiceId) =>
                !consigneeSelectedServices.includes(consigneeServiceId)
            );
            console.log('filteredServiceIds', filteredServiceIds);

            setSelectedServices(filteredServiceIds);
          }

          setConsigneeSelectedServices([]);
        }
      }
    }
    setFormData((old) => ({ ...old, ...newData }));
    setSelectFormData((old: any) => ({ ...old, [name]: event }));

    if (event?.value) {
      setValidForm({ ...validForm, [name]: true });

      if (name === 'consigneeId') {
        setValidForm({
          ...validForm,
          consigneeAddress: true,
          consigneeCompanyName: true,
        });
      }

      if (name === 'shipperId') {
        setValidForm({
          ...validForm,
          shipperAddress: true,
          shipperCompanyName: true,
        });
      }
    }
  };

  const handleSelectQuoteClassChange = (name: any) => (event: any) => {
    setSelectQuoteFormData((old: any) => ({ ...old, [name]: event.value }));
    setFormData((old: any) => ({
      ...old,
      classification: event?.value,
    }));
  };

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setFormData((old) => ({ ...old, [name]: value }));
        setIsSwapAddress(true);

        return;
      }
      setIsSwapAddress(false);
      setIsValidAddress((old) => ({ ...old, [name]: true }));
      setValidForm({ ...validForm, shipperAddress: true });
      setIsShipperBtnDisable(false);
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);
        setIsValidAddress((old) => ({ ...old, [name]: false }));
        setFormData((old) => ({ ...old, [name]: value }));
        setIsSwapAddress(true);

        return;
      }
      setIsSwapAddress(false);
      setIsConsigneeBtnDisable(false);
      setIsValidAddress((old) => ({ ...old, [name]: true }));
      setValidForm({ ...validForm, consigneeAddress: true });
    }

    setFormData((old) => {
      const newObj = { ...old, [name]: value };

      if (name === 'shipperAddress') {
        if (!old?.shipperId) {
          newObj.shipperCompanyName =
            value.companyName ?? old.shipperCompanyName;
        }
      } else {
        if (!old?.consigneeId) {
          newObj.consigneeCompanyName =
            value.companyName ?? old.consigneeCompanyName;
        }
      }

      return newObj;
    });
  };

  const getRecentCustomers = () => {
    if (abortControllerRef.current) abortControllerRef.current?.abort();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    setIsCustomerLoading(true);
    RecentCustomersList({ search, customerId: formData.customerId }, signal)
      .then((result: any) => {
        if (result.data && result.data.length) {
          if (formData.customerId) {
            result.data = result.data.filter(
              (data: any) => !data.isDeleted || data.id === formData.customerId
            );
          } else if (!id) {
            result.data = result.data.filter((data: any) => !data.isDeleted);
          }
          result.data = result.data.map((data: any) => {
            if (data.isDeleted) {
              data.name = `${data.name} (Deleted Customer)`;
              data.id = '';
            }

            return data;
          });

          setCustomers(result.data);
          const customerSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name',
            true
          );

          setCustomerOptions(customerSelectBoxOptions);
        } else {
          setCustomerOptions([]);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsCustomerLoading(false);
        setCustomerSearchLoading(false);
      });
  };

  useEffect(() => {
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getRecentCustomers();
      }, 250);

      return () => clearTimeout(delayDebounceFn);
    } else {
      getRecentCustomers();
    }
  }, [search]);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (value !== null) setIsSwapAddress(false);

    if (
      name === 'shipperCompanyName' &&
      formData.shipperCompanyName.trim() !== value?.trim()
    ) {
      setIsShipperBtnDisable(false);
    }

    if (
      name === 'consigneeCompanyName' &&
      formData.consigneeCompanyName?.trim() !== value?.trim()
    ) {
      setIsConsigneeBtnDisable(false);
    }
    setFormData((old: any) => ({ ...old, [name]: value }));

    if (value !== '' && value !== null) {
      setValidForm((old: any) => ({
        ...old,
        [name]: true,
      }));
    }
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      customerId: true,
      shipperAddress: true,
      shipperCompanyName: true,
      consigneeCompanyName: true,
      pickupDate: {
        valid: true,
        nextDay: true,
      },
      consigneeAddress: true,
    };

    if (formData.customerId === '' || formData.customerId === null) {
      validFormData.customerId = false;
      valid = false;
    }

    if (formData.shipperAddress.fullAddress === undefined) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (
      formData.shipperAddress.fullAddress === '' ||
      formData.shipperAddress.fullAddress === null
    ) {
      validFormData.shipperAddress = false;
      valid = false;
    }

    if (
      formData.shipperId &&
      (formData.shipperCompanyName === '' ||
        formData.shipperCompanyName === null)
    ) {
      validFormData.shipperCompanyName = false;
      valid = false;
    }

    if (formData.pickupDate === '' || formData.pickupDate === null) {
      validFormData.pickupDate.valid = false;
      valid = false;
    }

    if (formData.consigneeAddress.fullAddress === undefined) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    if (
      formData.consigneeAddress.fullAddress === '' ||
      formData.consigneeAddress.fullAddress === null
    ) {
      validFormData.consigneeAddress = false;
      valid = false;
    }

    if (
      formData.consigneeId &&
      (formData.consigneeCompanyName === '' ||
        formData.consigneeCompanyName === null)
    ) {
      validFormData.consigneeCompanyName = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const validation = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (isLimitedMore && limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm(validFormData);

    return valid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!isFormValid() || !validation()) {
      return true;
    }

    const formDataCurrent: any = {
      services: selectedServices,
      serviceId: selectedServices.join(','),
      limitedAccess: isLimitedMore,
    };
    formDataCurrent.isLimitedMore = isLimitedMore;

    if (isLimitedMore) {
      formDataCurrent.limitedAccessType = limitedAccessType;
      formDataCurrent.limitedAccessOther = limitedAccessOther;
    }

    if (isTailgate) {
      formDataCurrent.isTailgate = isTailgate;
    }

    if (window.MODE !== 'production' && selectQuoteFormData?.emailContentId) {
      formData.emailContentId = selectQuoteFormData?.emailContentId;
    }

    if (window.MODE !== 'production' && selectQuoteFormData?.urgency) {
      formData.urgency = selectQuoteFormData?.urgency;
    }

    console.log('formData address submit', formData);
    setQuoteFormData((old: any) => ({
      ...old,
      ...formData,
      services: {
        ...old.services,
        ...formDataCurrent,
      },
    }));
    console.log('setCurrentStep', setCurrentStep);
    setCurrentStep(currentStep + 1);
  };

  const saveShipperDetails = (type: string) => () => {
    let idToSave: any;
    let address: any;
    let companyName: any;
    let typeId: any;
    let serviceIds: any;
    let limitedAccess: any;

    if (type === 'shipper') {
      typeId = 1;
      idToSave = formData.shipperId;
      companyName = formData.shipperCompanyName;
      address = formData.shipperAddress;

      serviceIds = selectedServices.filter((serviceId) => {
        const service = serviceTypes?.PICKUP?.find(
          (s: any) => s.id === serviceId
        );

        return (
          service &&
          (service.name.toLowerCase().includes('pickup') ||
            service.name.toLowerCase().includes('origin'))
        );
      });

      serviceIds = Array.from(new Set(serviceIds));
    } else if (type === 'consignee') {
      typeId = 2;
      idToSave = formData.consigneeId;
      companyName = formData.consigneeCompanyName;
      address = formData.consigneeAddress;
      limitedAccess = isLimitedMore ? limitedAccessType : null;

      serviceIds = selectedServices.filter((serviceId) => {
        const service = serviceTypes?.DELIVERY?.find(
          (s: any) => s.id === serviceId
        );

        return (
          service &&
          (service.name.toLowerCase().includes('delivery') ||
            service.name.toLowerCase().includes('destination'))
        );
      });

      serviceIds = Array.from(new Set(serviceIds));
    }
    const data: any = {
      ...address,
      companyName,
      type: typeId,
      additionalServiceIds: serviceIds?.length ? serviceIds : null,
      limitedAccessType: limitedAccess,
    };

    if (
      companyName === '' ||
      address.fullAddress === '' ||
      typeof address !== 'object'
    ) {
      if (companyName === '') {
        if (type === 'shipper') {
          setValidForm((old) => ({ ...old, shipperCompanyName: false }));
        } else if (type === 'consignee') {
          setValidForm((old) => ({ ...old, consigneeCompanyName: false }));
        }
      }

      if (address.fullAddress === '' || typeof address !== 'object') {
        if (type === 'shipper') {
          setValidForm((old) => ({ ...old, shipperAddress: false }));
        } else if (type === 'consignee') {
          setValidForm((old) => ({ ...old, consigneeAddress: false }));
        }
      }

      return false;
    }

    if (type === 'shipper') {
      setIsShipperBtnDisable(true);
    } else {
      setIsConsigneeBtnDisable(true);
    }

    if (type === 'shipper') {
      setIsShipperActivityLoading(true);
    } else {
      setIsConsigneeActivityLoading(true);
    }

    if (idToSave) {
      updateShipper(idToSave, data)
        .then((result: any) => {
          if (result) {
            getShipper(idToSave, typeId, setSelectFormData);

            if (type === 'shipper') {
              setIsShipperBtnDisable(false);
            } else {
              setIsConsigneeBtnDisable(false);
            }
          }
        })
        .catch(console.error)
        .finally(() => {
          if (type === 'shipper') {
            setIsShipperActivityLoading(false);
          } else {
            setIsConsigneeActivityLoading(false);
          }
        });
    } else {
      createShipper(data)
        .then((result: any) => {
          if (result) {
            getShipper(result?.data?.id, typeId, setSelectFormData);

            if (result?.data?.id) {
              setFormData((old: any) => ({
                ...old,
                [`${type}Id`]: result.data.id,
              }));
            }

            if (type === 'shipper') {
              setIsShipperBtnDisable(true);
            } else {
              setIsConsigneeBtnDisable(true);
            }
          }
        })
        .catch(console.error)
        .finally(() => {
          if (type === 'shipper') {
            setIsShipperActivityLoading(false);
          } else {
            setIsConsigneeActivityLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    getRecentCustomers();
  }, [isCustomerDeleted]);

  useEffect(() => {
    if (formData?.shipperAddress?.fullAddress) {
      setShipperAddress(formData.shipperAddress.fullAddress);
    }

    if (formData?.consigneeAddress?.fullAddress) {
      setConsigneeAddress(formData.consigneeAddress.fullAddress);
    }
  }, [formData]);

  useEffect(() => {
    if (!isCustomerLoading && !isShipperLoading && id) {
      setIsLoading(true);
      getQuote(id)
        .then((response: any) => {
          if (response && response.data) {
            const { addressDetails } = response.data;
            const pickupDateInLocal = moment
              .utc(addressDetails.pickupDate)
              .local()
              .format('YYYY-MM-DD');

            if (addressDetails.status !== STATUS.PENDING) {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }

            const currentFormData: any = {
              customerId: addressDetails.isCustomerDeleted
                ? ''
                : addressDetails.customerId,
              pickupDate: pickupDateInLocal,
              shipperId: addressDetails.shipperId,
              shipperCompanyName: addressDetails.shipperCompanyName,
              consigneeId: addressDetails.consigneeId,
              consigneeCompanyName: addressDetails.consigneeCompanyName,
              primaryEquipmentType: addressDetails?.primaryEquipmentType,
              secondaryEquipmentTypes: addressDetails?.secondaryEquipmentTypes,
              primaryEquipmentTypeFullName:
                addressDetails?.primaryEquipmentTypeFullName?.split(','),
              secondaryEquipmentTypeFullNames:
                addressDetails?.secondaryEquipmentTypeFullNames?.split(','),
              shipperAddress: {
                fullAddress: addressDetails.shipperFullAddress,
                address1: addressDetails.shipperAddress1,
                city: addressDetails.shipperCity,
                state: addressDetails.shipperState,
                country: addressDetails.shipperCountry,
                postal: addressDetails.shipperPostal,
                latitude: addressDetails.shipperLatitude,
                longitude: addressDetails.shipperLongitude,
                stateCode: addressDetails.shipperStateCode,
                countryCode: addressDetails.shipperCountryCode,
              },
              consigneeAddress: {
                fullAddress: addressDetails.consigneeFullAddress,
                address1: addressDetails.consigneeAddress1,
                city: addressDetails.consigneeCity,
                state: addressDetails.consigneeState,
                country: addressDetails.consigneeCountry,
                postal: addressDetails.consigneePostal,
                latitude: addressDetails.consigneeLatitude,
                longitude: addressDetails.consigneeLongitude,
                stateCode: addressDetails.consigneeStateCode,
                countryCode: addressDetails.consigneeCountryCode,
              },
              linearFootage: addressDetails.linearFootage,
              totalWeight: addressDetails.totalWeight,
              insuranceCommercialValue: addressDetails.insuranceCommercialValue,
              insuranceCurrencyType: addressDetails.insuranceCurrencyType,
            };
            setFormData(currentFormData);
            setShipperAddress(addressDetails.shipperFullAddress);
            setConsigneeAddress(addressDetails.consigneeFullAddress);
            setIsCustomerDeleted(addressDetails.isCustomerDeleted);

            setSelectQuoteFormData((old: any) => ({
              ...old,
              classification: addressDetails?.classification,
              urgency: addressDetails?.urgency,
              emailContentId: addressDetails?.emailContentId,
            }));

            const newData: any = {};

            if (currentFormData.customerId) {
              const customer = customers.find(
                (customerOption: any) =>
                  customerOption.id === currentFormData.customerId
              );
              const customerOption = getSelectBoxOptions(
                customer,
                'id',
                'name'
              );
              newData.customerId = customerOption;
            }

            if (currentFormData.shipperId) {
              newData.shipperId = shipperOptions.find(
                (shipperOption: any) =>
                  shipperOption.value === currentFormData.shipperId
              );
              setIsShipperBtnDisable(false);
            }

            if (currentFormData.consigneeId) {
              newData.consigneeId = consigneeOptions.find(
                (consigneeOption: any) =>
                  consigneeOption.value === currentFormData.consigneeId
              );
              setIsConsigneeBtnDisable(false);
            }

            if (addressDetails.primaryEquipmentType) {
              newData.primaryEquipmentType =
                addressDetails?.primaryEquipmentType?.split(',');
            }

            if (addressDetails.secondaryEquipmentTypes) {
              newData.secondaryEquipmentTypes =
                addressDetails?.secondaryEquipmentTypes?.split(',');
            }

            setSelectFormData((old: any) => ({ ...old, ...newData }));
            checkPickupDate(addressDetails.pickupDate);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            navigate(ROUTES.QUOTING_DASHBOARD);
          }
        })
        .catch((e: any) => {
          console.error(e);
          setIsLoading(false);
        });
    } else {
      if (!id) {
        setIsLoading(false);
      }
      checkPickupDate(
        quoteFormData?.pickupDate ??
          moment()
            .tz(appearance.timezone ?? browserTimezone)
            .format('YYYY-MM-DD HH:mm:ss')
      );

      setFormData((old) => ({
        ...old,
        pickupDate:
          quoteFormData?.pickupDate ??
          moment()
            .tz(appearance.timezone ?? browserTimezone)
            .format('YYYY-MM-DD HH:mm:ss'),
      }));
    }
  }, [id, isCustomerLoading, isShipperLoading]);

  window.initMap = () => {
    setStatus(true);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({
        ...old,
        shipperAddress: value,
        shipperCompanyName: value?.companyName ?? '',
      }));
      setIsValidAddress((old) => ({ ...old, shipperAddress: true }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({
        ...old,
        consigneeAddress: value,
        consigneeCompanyName: value?.companyName ?? '',
      }));
      setIsValidAddress((old) => ({ ...old, consigneeAddress: true }));
    }
  };

  const handleModalClose = useCallback(
    (closeStatus: boolean) => () => {
      setAction(initAction);

      if (closeStatus) {
        const idToDelete = action.shipper
          ? formData.shipperId
          : formData.consigneeId;

        if (idToDelete) {
          if (action.shipper) {
            setIsShipperActivityLoading(true);
          } else {
            setIsConsigneeActivityLoading(true);
          }

          deleteShippers(idToDelete)
            .then((result: any) => {
              if (result) {
                const newData: any = {};
                let name = '';

                if (action.shipper) {
                  newData.shipperId = null;
                  newData.shipperCompanyName = '';
                  newData.shipperAddress = initShipperAddress;
                  setShipperAddress('');
                  name = 'shipperId';
                  setIsShipperBtnDisable(false);
                  setIsConsigneeBtnDisable(false);
                } else if (action.consignee) {
                  newData.consigneeId = null;
                  newData.consigneeCompanyName = '';
                  newData.consigneeAddress = initConsigneeAddress;
                  setConsigneeAddress('');
                  name = 'consigneeId';
                  setIsShipperBtnDisable(false);
                  setIsConsigneeBtnDisable(false);
                }
                setFormData((old) => ({ ...old, ...newData }));
                setSelectFormData((old: any) => ({ ...old, [name]: null }));
                getShipper();
              }
            })
            .catch(console.error)
            .finally(() => {
              if (action.shipper) {
                setIsShipperActivityLoading(false);
              } else {
                setIsConsigneeActivityLoading(false);
              }
            });
        }
      }
    },
    [action]
  );

  const handleDateChange = (event: any) => {
    const { name, value } = event;
    setFormData((old: any) => ({ ...old, [name]: value }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const swapAddress = () => {
    setConsigneeAddress(shipperAddress);
    setShipperAddress(consigneeAddress);
    setFormData((old) => ({
      ...old,
      consigneeAddress: formData.shipperAddress,
      shipperAddress: formData.consigneeAddress,
      shipperCompanyName: formData.consigneeCompanyName,
      consigneeCompanyName: formData.shipperCompanyName,
      shipperId: null,
      consigneeId: null,
    }));
    setSelectFormData((old: any) => ({
      ...old,
      shipperId: null,
      consigneeId: null,
    }));
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      if (tomorrowDayName === 'Saturday') {
        const tomorrow = moment().add(3, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else if (tomorrowDayName === 'Sunday') {
        const tomorrow = moment().add(2, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else {
        const tomorrow = moment().add(1, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      }

      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((old: any) => [...old, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessTypeList[0].value);
      }
    }
  };

  const getList = (data: any) => (
    <div className="flex flex-wrap [&>div]:px-1 -mx-1 px-2.5">
      {data.map((val: any) => (
        <div
          className="py-4 relative w-1/4 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:-top-px before:-left-[5vw] before:absolute"
          key={`service_${val.id}`}
        >
          <div className="flex cursor-pointer space-x-1.5">
            <CheckBox
              id={`service_${val.id}`}
              onChangeFunc={handleChange}
              name={val.slug}
              value={val.id}
              checked={selectedServices.includes(val.id)}
              parentClassName="mt-px"
              disabled={val.isOnlyForFTL}
            />
            <div className="leading-[0.9] space-x-1.5 flex items-center truncate">
              {val.slug === 'pickup_appointment_required' ||
              val.slug === 'delivery_appointment_required' ||
              val.slug === 'after_hours_pickup' ? (
                <TooltipCmp message={val.name}>
                  <label
                    htmlFor={`service_${val.id}`}
                    className={`leading-4 text-textSecondary text-xs font-medium truncate ${
                      val.isOnlyForFTL ? 'line-through' : 'cursor-pointer'
                    }`}
                  >
                    {val.slug === 'pickup_appointment_required'
                      ? 'Pickup Appointment'
                      : val.slug === 'delivery_appointment_required'
                      ? 'Delivery Appointment'
                      : 'After Hours Pickup'}
                  </label>
                </TooltipCmp>
              ) : (
                <label
                  htmlFor={`service_${val.id}`}
                  className={`leading-4 text-textSecondary text-xs font-medium truncate ${
                    val.isOnlyForFTL ? 'line-through' : 'cursor-pointer'
                  }`}
                >
                  {val.slug === 'after_hours_pickup'
                    ? 'After Hours Pickup'
                    : val.name}
                </label>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const isAccessTypeFormValid = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }
  };

  return (
    <>
      <div className="space-y-3">
        <div className="flex items-center flex-wrap gap-x-2">
          <div className="flex-1">
            <h6 className="text-grayLight900 text-lg font-semibold">
              Add Shipper & Consignee
            </h6>
            <p className="text-grayLight600 text-xs font-medium">
              Track and Review Every Detail of Your Quote
            </p>
          </div>
          <div className="flex flex-wrap gap-2">
            <ButtonCmp
              onClick={() => navigate(-1)}
              className="btn_secondary_black min-w-[75px]"
            >
              Back
            </ButtonCmp>

            <ButtonCmp
              type="submit"
              className="btn-outline-primary min-w-[75px]"
              onClick={(e: any) => {
                console.log('formData Next', formData);
                handleSubmit(e);
              }}
              disabled={
                isLoading ||
                isMapLoading ||
                isCustomerLoading ||
                isShipperLoading
              }
            >
              Next
            </ButtonCmp>
          </div>
        </div>

        <StepBar
          key={selectQuoteFormData?.classification}
          steps={STEPS}
          activeStep={currentStep}
          variant="progressOnly"
          stepsParentClass={selectQuoteFormData?.classification}
        ></StepBar>
      </div>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
        <div className="flex flex-wrap gap-y-3 -mx-1.5">
          {selectQuoteFormData?.classification && (
            <SelectBox
              parentClassName="single-select-x-scroll w-1/3 px-1.5"
              name="classification"
              required
              label="Service Type"
              className={`form_control`}
              labelClassName="form_label mb-1.5 block"
              options={classOptions}
              value={
                formData?.classification
                  ? classOptions.filter(
                      (val: any) => formData?.classification === val.value
                    )
                  : 'ltl'
              }
              onChangeFunc={handleSelectQuoteClassChange('classification')}
            />
          )}

          <SelectBox
            showCustomerImage={true}
            parentClassName="single-select-x-scroll w-1/3 px-1.5"
            isClearable
            name="customerId"
            required
            placeholder="Select customer"
            label="Customer"
            className={`form_control`}
            labelClassName="form_label mb-1.5 block"
            isSearchable={true}
            isLoading={customerSearchLoading}
            selectOptionSubLabelClass="!font-normal !text-[11px]"
            selectoptionWraptext="!justify-between"
            selectInputSubLabelClass="!font-normal !text-grayLight600 !text-[11px]"
            id="selectCarrier"
            size="sm"
            noOptionMessage="No Customer Found"
            options={customerOptions}
            onChangeFunc={(e: any) => {
              handleSelectChange('customerId')(e);
            }}
            onInputChange={(e: any) => {
              setSearch(e);
            }}
            value={
              formData?.customerId
                ? customerOptions?.find(
                    (e: any) => e?.value == formData?.customerId
                  )
                : null
            }
            isOptionLoading={isCustomerLoading}
            errorText={!validForm.customerId ? true : false}
          />

          <div className="w-1/3 min-w-[264px] px-1.5">
            <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
              <div className="flex">
                <label className="form_label mb-0">Pickup Date</label>
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </div>
              <p
                className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
              >
                {pickup1pmError && (
                  <>
                    <span className="text-xs text-primary font-normal flex items-center">
                      {tomorrowDayName === 'Saturday'
                        ? `Set to Monday`
                        : 'Set to Tomorrow'}
                    </span>
                    <a
                      onClick={() => selectNextDay(true)}
                      className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                    >
                      Yes
                    </a>
                    &nbsp;
                    {'/'}
                    <a
                      onClick={() => selectNextDay(false)}
                      className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                    >
                      No
                    </a>
                    <TooltipCmp message={'Pickup is unlikely after 1pm.'}>
                      <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                    </TooltipCmp>
                  </>
                )}
              </p>
            </div>
            <DateSelect
              inputName="pickupDate"
              className={`form_control ${
                !validForm.pickupDate.valid
                  ? 'border border-red-500 mb-1 border-solid'
                  : ''
              }`}
              placeholder={DATE_FORMAT}
              dateFormat="dd/MM/yyyy"
              minDate={moment().toDate()}
              selected={
                formData.pickupDate && moment(formData.pickupDate).isValid()
                  ? moment(formData.pickupDate)
                      .tz(appearance?.timezone ?? browserTimezone)
                      .toDate()
                  : null
              }
              value={getDateWithSuffixFormat(
                formatDateValue(formData.pickupDate)
              )}
              errorText={''}
              onChangeFunc={handleDateChange}
              icon={<Calendar className="h-5 w-5" />}
              parentClassName="w-full one-month-datepicker"
            />
          </div>
        </div>
      </div>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
        <ShipperAddress
          formData={formData}
          shipperOptions={shipperOptions}
          validForm={validForm}
          setValidForm={setValidForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isShipperBtnDisable={isShipperBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          setShipperAddress={setShipperAddress}
          handleInputChange={handleInputChange}
          shipperAddress={shipperAddress}
          isValidAddress={isValidAddress}
          isLoading={isLoading || isServiceLoading}
          isShipperLoading={isServiceLoading}
          status={status}
          serviceList={serviceList}
          serviceTypes={serviceTypes}
          getList={getList}
          isShipperActivityLoading={isShipperActivityLoading}
          setSearchShipper={setSearchShipper}
        />

        <div className="text-center my-4">
          <ButtonCmp
            className="btn_secondary_black !p-2"
            onClick={swapAddress}
            disabled={
              localStorage.getItem('isModifyQuoteFromQuote')
                ? false
                : isSwapAddress
            }
          >
            <SwitchVertical01 className="text-text-grayLight900 w-4 h-4" />
          </ButtonCmp>
        </div>

        <ConsigneeAddress
          formData={formData}
          consigneeOptions={consigneeOptions}
          validForm={validForm}
          setValidForm={setValidForm}
          handleSelectChange={handleSelectChange}
          selectFormData={selectFormData}
          handleActionType={handleActionType}
          saveShipperDetails={saveShipperDetails}
          isConsigneeBtnDisable={isConsigneeBtnDisable}
          handleAutocompleteChange={handleAutocompleteChange}
          handleInputChange={handleInputChange}
          consigneeAddress={consigneeAddress}
          setConsigneeAddress={setConsigneeAddress}
          isLoading={isLoading || isServiceLoading}
          isShipperLoading={isServiceLoading}
          isValidAddress={isValidAddress}
          status={status}
          serviceList={serviceList}
          serviceTypes={serviceTypes}
          getList={getList}
          isConsigneeActivityLoading={isConsigneeActivityLoading}
          setSearchConsignee={setSearchConsignee}
        />
      </div>
      {selectQuoteFormData?.emailContentId && (
        <QuoteEmailDetailView
          emailContentId={selectQuoteFormData?.emailContentId}
        />
      )}

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
      />

      {action.mode === 'delete' && (
        <DeleteModal
          handleClose={handleModalClose}
          moduleTitle={`Remove ${
            action.consignee
              ? formData.consigneeCompanyName
              : formData.shipperCompanyName
          } From Wal HQ `}
          moduleName={action.consignee ? 'consignee' : 'shipper'}
        />
      )}

      {isAccessTypeModalOpen && (
        <CommonModal
          title={'Limited access delivery'}
          titleDesc={'Please select limited access delivery type'}
          modalClassName="flex flex-col"
          handleClose={() => {
            setLimitedAccessSearch('');
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="limited_access_delivery"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsAccessTypeModalOpen(false);
          }}
          headerIcon={<PackagePlus />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (isAccessTypeFormValid()) {
              setIsAccessTypeModalOpen(false);
            }
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="limited_access_delivery"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsAccessTypeModalOpen(false);
          }}
        >
          <div className="p-5 flex-1 flex flex-col overflow-y-auto">
            <InputText
              inputName="searchCarrier"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={limitedAccessSearch}
              isClearable
              onChangeFunc={(e) => setLimitedAccessSearch(e.target.value)}
              inputType="text"
              parentClassName="w-full mb-4"
            />

            <div className="rounded-lg border border-borderPrimary px-3 py-1 flex-1 overflow-y-auto custom-scrollbar-v2">
              {limitedAccessTypeList.map((val: any, i: any) => (
                <div
                  className="flex sms:p-4 px-2 py-3 border-b border-utilityGray200 last:border-b-0"
                  key={`limited_${i}`}
                >
                  <div className="flex items-center">
                    <div
                      className="flex items-start gap-2"
                      key={`service_${val.value}`}
                    >
                      <Radio
                        id={`service_${val.value}`}
                        onChangeFunc={handleChangeRadio}
                        inputName={'limitedAccessType'}
                        value={val.value}
                        checked={limitedAccessType === val.value}
                      />
                      <label
                        htmlFor={`service_${val.value}`}
                        className="text-xs text-textSecondary uppercase cursor-pointer font-medium"
                      >
                        {val.name}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              {limitedAccessType === 'other' && (
                <div className="mt-5 w-full">
                  <InputText
                    inputName="limitedAccessOther"
                    className={`form_control ${
                      !validServicesForm.limitedAccessOther
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label=""
                    value={limitedAccessOther}
                    errorText={
                      !validServicesForm.limitedAccessOther
                        ? 'This field is required'
                        : null
                    }
                    onChangeFunc={(e) => {
                      setLimitedAccessOther(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </CommonModal>
      )}
    </>
  );
};

export default AddressDetailsForm;
