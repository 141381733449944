import { Plus, SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import Radio from 'src/components/Radio/Radio';
import { AuthContext } from 'src/context/AuthContext';
import {
  setCallDuration,
  // setConferenceId,
  // setConferenceName,
  setErrorMessage,
  setHoldStatus,
  setIncomingCall,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setOutgoingCall,
  setOutgoingCallDetails,
  // setParticipants,
} from 'src/redux/CallCenter.slice';
import { RootState } from 'src/redux/store';
import { ContactList, RecentContactList } from 'src/services/CallCenterService';
import { addCallLog } from 'src/services/TwilioService';
import {
  checkAudioDevicesAndPermissions,
  getShortName,
  usePhone,
} from 'src/utils/CommonFunctions';

type CallParams = Record<string, string>;

const initialParams: any = {
  search: '',
  sortType: 'desc',
  page: 1,
  limit: 50,
};

const initNumber: any = {
  phoneNumber: '',
};

const MakeACall = ({
  onOutsideClick,
  setIsMakeCall,
  setAction,
  newContactDial,
}: any) => {
  const dispatch = useDispatch();
  const [callParams, setCallParams] = useState(initialParams);
  const [contacts, setContacts] = useState<any[]>([]);
  const { currentUser } = useContext(AuthContext);
  const [isContactLoading, setIsContactLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [recentContactList, setRecentContactList] = useState<any[]>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] =
    useState<any>(initNumber);
  const [countryCode, setCountryCode] = useState<string>('');
  const [countryShortName, setCountryShortName] = useState<string>('');

  const [focusInput, setFocusInput] = useState<'inputText' | 'phoneInput'>(
    'inputText'
  );
  const [noRecordsFound, setNoRecordsFound] = useState<boolean>(false);
  const [newContactDialAction, setNewContactDialAction] =
    useState<any>(newContactDial);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const { device, outgoingCall } = useSelector(
    (state: RootState) => state.CallCenter
  );

  const { getFormatPhoneWithPlus } = usePhone();

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getContactData = () => {
    setContacts([]);
    setIsContactLoading(true);
    setNoRecordsFound(false);

    cancelApiCall();

    const contactListApiController = new AbortController();
    const contactListApiSignal = contactListApiController.signal;

    abortControllerRef.current = contactListApiController;

    ContactList(callParams, contactListApiSignal)
      .then((response: any) => {
        if (response.data) {
          setContacts(response.data);

          if (response.data.length === 0) {
            setNoRecordsFound(true);
          }
        }
        setIsContactLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsContactLoading(false);
        setNoRecordsFound(true);
      });
  };

  const getRecentContactList = (recentContactParam: any) => {
    setRecentContactList([]);
    setIsLoading(true);

    // cancelApiCall();

    // const recentContactListApiController = new AbortController();
    // const recentContactListApiSignal = recentContactListApiController.signal;

    // abortControllerRef.current = recentContactListApiController;

    RecentContactList(recentContactParam)
      .then((response: any) => {
        if (response.data) {
          setRecentContactList(response.data);
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsLoading(false);
      });
  };

  const handleCall = async (contactData: any) => {
    try {
      await checkAudioDevicesAndPermissions();
    } catch (error: any) {
      alert(error.message);

      return;
    }
    // console.log('⭐️ ~ handleCall ~ scheduleNumber:', scheduleNumber);
    console.log('4. ⭐️ ~ handleCall ~ data:', contactData);

    try {
      // if (!token || (!phoneNumber && !scheduleNumber)) {
      //   throw new Error('Invalid token or phone number');
      // }

      if (!device) {
        throw new Error('Device is not initialized');
      }

      const params: CallParams = {
        // To: scheduleNumber ? scheduleNumber : countryCode + phoneNumber,
        // To: scheduleNumber ? scheduleNumber : '+919427420518',
        To: contactData?.contactNumber,
        From: currentUser?.phone,
        intent: 'call-out',
      };

      const outBound = await device.connect({ params });
      console.log('5. ⭐️ ~ handleCall ~ outBound:', outBound);

      dispatch(
        setOutgoingCallDetails({
          name: contactData?.contactName,
          companyName: contactData?.customerName || contactData?.carrierName,
          role: contactData?.role,
          image: contactData?.contactImageUrl + contactData?.contactImage,
          number: contactData?.contactNumber,
          carrierId: contactData?.carrierName != null && contactData?.contactId,
          customerId:
            contactData?.customerName != null && contactData?.contactId,
        })
      );
      console.log('⭐️ ~ handleCall ~ value dispatched');

      outBound.on('accept', async (connection: any) => {
        console.log('6. ⭐️ Connection accepted by callee');
        const callSid = connection.parameters.CallSid;

        const callSids = {
          caller: outBound?.parameters.CallSid,
        };

        dispatch(setOutgoingCall(outBound));
        console.log('7. ⭐️ ~ outBound.on ~ callSids:', callSids);

        console.log('8. ⭐️ Call SID:', callSid);

        // setTimeout(async () => {
        await addCallLog({
          contactId: contactData?.contactId,
          fromUserId: currentUser?.id,
          fromPhoneNumber: currentUser?.phone,
          toPhoneNumber: contactData?.contactNumber,
          callType: 'outbound',
          status: 'started',
          callerCallSid: outBound?.parameters.CallSid,
        });
        console.log('9. ⭐️ ~ setTimeout ~ callInstance:', outgoingCall);
        // }, 1000);
      });

      // if (scheduleNumber) {
      //   setIsScheduleCall(false);
      //   setScheduleCallTime('');
      //   setIsScheduleCallNumber('');
      // }
      // dispatch(setIsCallInProgress(true));

      outgoingCall?.on('disconnect', (e: any) => {
        console.log('Call disconnected', e, outgoingCall);
        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
        dispatch(setOutgoingCall(null));
      });
    } catch (err: any) {
      setErrorMessage(
        err instanceof Error ? err.message : 'An unknown error occurred'
      );
      console.error('Error making call:', err);
    }
  };

  function isValidPhoneNumber(phoneNumber: any): boolean {
    const phoneRegex = /^[\d\s-()]*$/;

    const isValid = phoneRegex.test(phoneNumber);

    return isValid;
  }

  const handleSubmit = async (data: any) => {
    setIsMakeCall(false);
    console.log('1. ⭐️ ~ handleSubmit ~ data:', data);

    let callDetails;

    if (callParams.search === '') {
      callDetails = recentContactList.find(
        (item: any) => item.otherPhoneNumber === data.phoneNumber
      );
    } else {
      callDetails = contacts.find(
        (item: any) => item.number === data.phoneNumber
      );
    }
    console.log('2. ⭐️ ~ handleSubmit ~ callDetails:', callDetails);

    const dialCallDetails = callDetails
      ? {
          contactName: callDetails?.name,
          companyName: callDetails?.customerName || callDetails?.carrierName,
          role: callDetails?.role,
          contactImageUrl: callDetails?.imageUrl,
          contactImage: callDetails?.image,
          contactNumber: callDetails?.otherPhoneNumber || callDetails?.number,
          carrierId: callDetails?.carrierId,
          customerId: callDetails?.customerId,
          contactId: callDetails?.contactId || callDetails?.id,
        }
      : {
          contactNumber: `+${countryCode}${data.phoneNumber}`,
        };

    console.log('3. ⭐️ ~ handleSubmit ~ dialCallDetails:', dialCallDetails);

    // setIsMakeCall(false);

    handleCall(dialCallDetails);

    return;
  };

  const handleAddContact = () => {
    setIsMakeCall(false);

    const searchValue = callParams.search || selectedPhoneNumber.phoneNumber;

    // Determine if the search value is a phone number
    const isPhoneNumber = /^[\d\s+()-]+$/.test(searchValue);

    setAction({
      mode: 'addNewContactDial',
      data: isPhoneNumber
        ? {
            number: `+${countryCode}${searchValue}`,
          }
        : {
            name: searchValue,
          },
    });
  };

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      // Conference Created For Incoming Call
      socketIO.on('disconnectCall', async () => {
        console.log('disconnect call');
        // await addCallDuration({ callDuration, conferenceId });

        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setIncomingCall(null));
        dispatch(setOutgoingCall(null));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
      });
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('disconnectCall');
      }
    };
  }, [isSocketConnected]);

  useEffect(() => {
    if (currentUser?.phone) {
      getRecentContactList({ number: currentUser?.phone });
    }
  }, []);

  useEffect(() => {
    if (countryShortName) {
      setFocusInput('phoneInput');
    } else {
      setFocusInput('inputText');
    }
  }, [countryShortName]);

  useEffect(() => {
    if (focusInput === 'phoneInput') {
      const phoneInputElement: any =
        document.getElementsByName('contactNumber')[0];

      if (phoneInputElement) {
        phoneInputElement?.focus();
      }
    } else {
      const inputTextElement: any =
        document.getElementsByName('searchInput')[0];

      if (inputTextElement) {
        inputTextElement?.focus();
      }
    }
  }, [focusInput]);

  useEffect(() => {
    if (callParams?.search !== '') {
      if (!noRecordsFound) {
        getContactData();
      }
    } else {
      setNoRecordsFound(false);
    }
  }, [callParams]);

  useEffect(() => {
    if (callParams.search === '') {
      setNoRecordsFound(false);
    }
  }, [callParams.search]);

  useEffect(() => {
    if (newContactDialAction?.mode !== '') {
      const number = newContactDialAction?.data.number;
      setCallParams({
        search: number,
        sortType: 'desc',
        page: 1,
        limit: 50,
      });
      setSelectedPhoneNumber({ phoneNumber: number });
    }
  }, [newContactDialAction]);

  const handlePhoneNumberChange = (country: any, phone: any, code: any) => {
    setCountryCode(code);
    setCountryShortName(country);

    if (isValidPhoneNumber(phone)) {
      setCallParams((old: any) => ({
        ...old,
        ...{ search: phone, page: 1 },
      }));
      setSelectedPhoneNumber({ phoneNumber: phone });
    } else {
      setCallParams((old: any) => ({
        ...old,
        search: '',
      }));
      setSelectedPhoneNumber({ phoneNumber: '' });
    }

    if (!phone) {
      setCountryCode('');
      setCountryShortName('');
      setCallParams((old: any) => ({
        ...old,
        search: '',
      }));
    }
  };

  const handleInputNumberChange = (e: any) => {
    setSelectedPhoneNumber({ phoneNumber: '' });
    setNewContactDialAction({ mode: '', data: {} });
    const value = e.target.value.trim();

    if (value.startsWith('+') && /^\+\d+$/.test(value)) {
      try {
        const phoneNumber = parsePhoneNumberFromString(value);

        if (phoneNumber) {
          const extractedCountryCode = phoneNumber.countryCallingCode;
          const nationalNumber = phoneNumber.nationalNumber;
          const countryShort = phoneNumber.country || 'CA';

          setCountryCode(`+${extractedCountryCode}`);
          setCountryShortName(countryShort);
          setCallParams((old: any) => ({
            ...old,
            ...{ search: '', page: 1 },
          }));
          setSelectedPhoneNumber({ phoneNumber: nationalNumber });

          return;
        }
      } catch (error) {
        console.error('Error parsing phone number:', error);
      }
    }

    // Check if the input is just a number without country code
    if (/^\d+$/.test(value) && value.length >= 8) {
      setCountryCode('+1');
      setCountryShortName('CA');
      setCallParams((old: any) => ({
        ...old,
        ...{ search: '', page: 1 },
      }));
      setSelectedPhoneNumber({ phoneNumber: value });

      return;
    }

    setCallParams((old: any) => ({
      ...old,
      ...{ search: value, page: 1 },
    }));

    if (
      value.length === 2 &&
      !isNaN(value) &&
      countryShortName === '' &&
      countryCode === ''
    ) {
      setCountryCode('+1');
      setCountryShortName('CA');
    } else {
      if (
        value.length === `${countryShortName} ${countryCode}`.length &&
        countryShortName !== '' &&
        countryCode !== ''
      ) {
        setCountryCode('');
        setCountryShortName('');
        setCallParams((old: any) => ({
          ...old,
          search: '',
        }));
      }
    }

    if (value === '') {
      setContacts([]);
      setCountryCode('');
      setCountryShortName('');
    }
  };

  return (
    <>
      <OutsideClickHandler
        onOutsideClick={onOutsideClick}
        containerClassName="relative"
      >
        <div className="rounded-[10px] border border-utilityGray200 shadow-md bg-white my-1.5 z-[1] w-[370px] right-0 absolute max-h-[80vh] flex flex-col">
          <div className="p-4">
            {countryShortName ? (
              <PhoneInput
                onNumberChange={handlePhoneNumberChange}
                value={
                  countryShortName
                    ? `${
                        callParams?.search || selectedPhoneNumber?.phoneNumber
                      }`.startsWith(countryShortName)
                      ? `${
                          callParams?.search || selectedPhoneNumber?.phoneNumber
                        }`
                      : `${countryShortName} ${countryCode} ${
                          callParams?.search || selectedPhoneNumber?.phoneNumber
                        }`
                    : `${
                        callParams?.search || selectedPhoneNumber?.phoneNumber
                      }`
                }
                name="contactNumber"
                errors={null}
              />
            ) : (
              <InputText
                inputName="searchInput"
                placeholder="Search or Dial"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={`${
                  callParams?.search || selectedPhoneNumber?.phoneNumber
                }`}
                inputType="text"
                isClearable={true}
                onChangeFunc={handleInputNumberChange}
                parentClassName=""
              />
            )}
          </div>

          {isLoading && callParams.search === '' ? (
            <>
              <ul className="flex flex-col overflow-auto custom-scrollbar-v2 mb-2">
                {[...Array(3)].map((_, index) => (
                  <li
                    className="px-4 py-3 flex items-center gap-x-3 border-b border-utilityGray200 last:border-b-0 bg-transparent hover:bg-blue25 cursor-pointer"
                    key={index}
                  >
                    <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm custom-loading">
                      D{index + 1}
                    </div>
                    <div className="flex-1">
                      <h6 className="text-grayLight900 text-sm font-medium truncate custom-loading">
                        {'Phoenix Baker'}
                      </h6>
                      <p className="text-grayLight600 font-normal text-xs leading-[1.5] truncate custom-loading">
                        {'Technologies Mindcore'}
                      </p>
                    </div>
                    <p className="text-xs font-normal text-gray500 flex-none custom-loading">
                      +1 (315) 623-3438
                    </p>
                    <Radio
                      onChangeFunc={() => {}}
                      parentClassName="custom-loading"
                    />
                  </li>
                ))}
              </ul>
            </>
          ) : (
            recentContactList &&
            recentContactList?.length > 0 &&
            callParams.search === '' && (
              <>
                <ul className="flex flex-col overflow-auto custom-scrollbar-v2 mb-2">
                  {recentContactList.map((contact: any, index: number) => (
                    <li
                      className={`px-4 py-3 flex items-center gap-x-3 border-b border-utilityGray200 last:border-b-0 bg-transparent hover:bg-blue25 cursor-pointer`}
                      key={index}
                      onClick={() => {
                        setSelectedPhoneNumber({
                          phoneNumber: contact?.otherPhoneNumber,
                        });
                      }}
                    >
                      {contact?.image ? (
                        <img
                          src={contact?.imageUrl + contact?.image}
                          className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                        />
                      ) : (
                        <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                          {contact?.name ? getShortName(contact?.name) : 'N'}
                        </div>
                      )}
                      <div className="flex-1 truncate">
                        <h6 className="text-grayLight900 text-sm font-medium truncate">
                          {contact?.name || 'Unknown'}
                        </h6>
                        <p className="text-grayLight600 font-normal text-xs leading-[1.5] truncate">
                          {contact?.carrierName || contact?.customerName}
                        </p>
                      </div>
                      <p className="text-xs font-normal text-gray500 flex-none">
                        {/* {contact?.otherPhoneNumber
                        ? parsePhoneNumberFromString(
                            String(contact.otherPhoneNumber)
                          )?.formatInternational()
                        : 'N/A'} */}
                        {getFormatPhoneWithPlus(contact.otherPhoneNumber)}
                      </p>
                      <Radio
                        onChangeFunc={() => {}}
                        inputName={'role'}
                        checked={
                          contact?.otherPhoneNumber ===
                          selectedPhoneNumber?.phoneNumber
                        }
                      />
                    </li>
                  ))}
                </ul>
              </>
            )
          )}

          {isContactLoading ? (
            <ul className="flex flex-col overflow-auto custom-scrollbar-v2 mb-2">
              {[...Array(3)].map((_, index) => (
                <li
                  className="px-4 py-3 flex items-center gap-x-3 border-b border-utilityGray200 last:border-b-0 bg-transparent hover:bg-blue25 cursor-pointer"
                  key={index}
                >
                  <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm custom-loading">
                    D{index + 1}
                  </div>
                  <div className="flex-1">
                    <h6 className="text-grayLight900 text-sm font-medium truncate custom-loading">
                      Phoenix Baker
                    </h6>
                    <p className="text-grayLight600 font-normal text-xs leading-[1.5] truncate custom-loading">
                      Technologies Mindcore
                    </p>
                  </div>
                  <p className="text-xs font-normal text-gray500 flex-none custom-loading">
                    +1 (315) 623-3438
                  </p>
                  <Radio
                    onChangeFunc={() => {}}
                    parentClassName="custom-loading"
                    checked
                  />
                </li>
              ))}
            </ul>
          ) : contacts && contacts.length > 0 && callParams.search !== '' ? (
            <ul className="flex flex-col overflow-auto custom-scrollbar-v2 mb-2">
              {contacts.map((contact, index) => (
                <li
                  className={`px-4 py-3 flex items-center gap-x-3 border-b border-utilityGray200 last:border-b-0 bg-transparent hover:bg-blue25 cursor-pointer ${
                    selectedPhoneNumber.phoneNumber === contact?.number
                      ? '!bg-blue25'
                      : ''
                  }`}
                  key={index}
                  onClick={() =>
                    setSelectedPhoneNumber({ phoneNumber: contact?.number })
                  }
                >
                  {contact?.image ? (
                    <img
                      src={`${contact?.imageUrl}${contact?.image}`}
                      className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      alt={contact?.name || 'Contact'}
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                      {contact?.name ? getShortName(contact?.name) : 'NA'}
                    </div>
                  )}
                  <div className="flex-1 truncate">
                    <h6 className="text-grayLight900 text-sm font-medium truncate">
                      {contact?.name || 'N/A'}
                    </h6>
                    <p className="text-grayLight600 font-normal text-xs leading-[1.5] truncate">
                      {contact?.customerName || contact?.carrierName}
                    </p>
                  </div>
                  <p className="text-xs font-normal text-gray500 flex-none">
                    {getFormatPhoneWithPlus(contact.number)}
                  </p>
                  <Radio
                    onChangeFunc={() => {}}
                    inputName="role"
                    checked={
                      contact?.number === selectedPhoneNumber?.phoneNumber
                    }
                  />
                </li>
              ))}
            </ul>
          ) : callParams?.search !== '' ? (
            <div className="flex flex-col items-center justify-center py-[50px]">
              <h3 className="text-center text-grayLight900 text-sm font-semibold leading-6">
                No Contact Found
              </h3>
              <p className="sm:max-w-[342px] p-1 pt-0 text-center text-grayLight600 text-xs font-normal leading-normal">
                Assign the number to a new contact
              </p>
              <ButtonCmp
                className="btn_secondary_black mt-2"
                icon={<Plus className="w-4 h-4" />}
                onClick={handleAddContact}
              >
                Add as Contact
              </ButtonCmp>
            </div>
          ) : null}

          <div className="flex gap-x-3 border-t border-utilityGray200 p-4">
            <ButtonCmp
              className="btn_secondary_black flex-1"
              onClick={onOutsideClick}
            >
              Close
            </ButtonCmp>
            <ButtonCmp
              className="btn-outline-primary flex-1"
              disabled={
                !selectedPhoneNumber.phoneNumber &&
                !isValidPhoneNumber(callParams.phoneNumber)
              }
              onClick={() => handleSubmit(selectedPhoneNumber)}
            >
              Dial
            </ButtonCmp>
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default MakeACall;
