import moment from 'moment';
import React, { useMemo, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import DateRangePicker from 'src/components/DateRangePicker';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { useRolePermission } from 'src/utils/CommonFunctions';

import ActivityOverviewLoadingRow from './ActivityOverviewLoadingRow';
import ActivityOverviewRow from './ActivityOverviewRow';

export const FilterArray: any = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
];

const Activity = () => {
  const [startDate, setStartDate] = useState<any>(() => '');
  const [endDate, setEndDate] = useState<any>(() => '');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => 'custom'
  );
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');
  const [activeFilter, setActiveFilter] = useState(() => 'daily');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');
  const { hasRoleV2 } = useRolePermission();

  console.log(
    '⭐️ ~ Activity ~ setStartDate:',
    setStartDate,
    setEndDate,
    setSelectedTimeRange,
    setAllTimeStartDate,
    setFilterAfterPrevNext
  );

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const tasks = [
    {
      time: '10:00',
      id: '#41836',
      type: 'LTL',
      addressTo: 'Ontario',
      addressFrom: 'Toronto',
      weight: '98',
      length: 72,
      rate: 10,
    },
    {
      time: '20:00',
      id: '#81836',
      type: 'LTL',
      addressTo: 'Ontario',
      addressFrom: 'Toronto',
      weight: '98',
      length: 185,
      rate: 100,
    },
    {
      time: '08:00',
      id: '#51836',
      type: 'LTL',
      addressTo: 'Ontario',
      addressFrom: 'Toronto',
      weight: '100',
      length: 100,
      rate: 1,
    },
  ];

  const headCells = useMemo(
    () => [
      {
        id: 'time',
        name: '',
      },
      {
        id: 'id',
        name: '',
      },
      {
        id: 'type',
        name: '',
      },

      {
        id: 'address',
        name: '',
      },
      {
        id: 'length',
        name: '',
      },
      {
        id: 'rate',
        name: '',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  return (
    <>
      {/* Filter Section */}
      <div className="flex justify-between mb-8">
        <div className="flex space-x-2">
          <TabButton
            activeClassName="!bg-utilityGray100"
            tabArray={FilterArray}
            tabParentClassName=""
            isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
            handleOnClick={(e: any) => {
              // if (activeFilter === e.target.dataset.value) getFilterRange();
              setActiveFilter(e.target.dataset.value);
            }}
          />
        </div>
        <div>
          <DateRangePicker
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={allTimeStartDate}
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
            parentClassName="sales-datepicker"
            isPrevDisabled={isPrevYearDisable}
            // onPrevNextClick={(type: string) => getFilterRange(type)}
            setTodayAsDefaultOnClear={true}
          />
        </div>
      </div>
      <div className="flex gap-x-1.5">
        <h3 className="text-grayLight900 text-sm font-semibold">
          Completed tasks
        </h3>
        <BadgeCmp style="modern" type="primary" isHidePillDot>
          10
        </BadgeCmp>
      </div>

      <div className=" w-full bg-white ">
        <TableCmp
          headCells={headCells}
          tableDataArr={tasks}
          TableLoaderRowCmp={ActivityOverviewLoadingRow}
          TableRowCmp={ActivityOverviewRow}
          isTableRowClickable={true}
          tableHeaderClass="hidden"
        />
      </div>
    </>
  );
};

export default Activity;
