import { InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedDate,
  getDateWithSuffixFormat,
  getShortName,
} from 'src/utils/CommonFunctions';

import { OrderContext } from '..';
import {
  getCommonCarrierAPIInformation,
  getModifiedCategories,
} from '../../order.constant';
import { classOptions } from '../../order.interface';

const Step6 = () => {
  const {
    carrierPrice,
    customerList,
    currentLegData,
    handlingUnitOptions,
    weightMeasurement,
    salesPrice,
    selectedQuote,
  } = useContext<any>(OrderContext);

  const { getValues } = useFormContext();
  const { isAddressChanged, isDimensionChanged, isAdditionalServiceChanged } =
    getModifiedCategories(getValues(), selectedQuote);

  const [carrier, setCarrier] = useState<any>(null);
  const [salePrice, setSalePrice] = useState<any>(null);
  const [carrierAdditionalCharge, setCarrierAdditionalCharge] =
    useState<any>(null);
  const [customer, setCustomer] = useState<any>(null);
  const [orderDimension, setOrderDimension] = useState<any>([]);
  const [salesAdditionalCharge, setSalesAdditionalCharge] = useState<any>(null);
  const [margin, setMargin] = useState<any>(null);
  const [marginPercentage, setMarginPercentage] = useState<any>(null);

  useEffect(() => {
    if (getValues('orderCustomerId')) {
      const customerDetails = customerList.find(
        (val: any) => getValues('orderCustomerId') === val.value
      );

      setCustomer(customerDetails);
    }
  }, [customerList, getValues('orderCustomerId')]);

  useEffect(() => {
    if (getValues('order_dimensions')) {
      setOrderDimension(getValues('order_dimensions'));
    }
  }, [getValues('order_dimensions')]);

  useEffect(() => {
    if (
      carrierPrice &&
      Array.isArray(carrierPrice) &&
      carrierPrice.length > 0
    ) {
      const selectedCarrier: any = carrierPrice[0];
      let providerName = selectedCarrier?.carrier?.providerName;

      const updatedCarrier = {
        ...selectedCarrier,
        carrier: {
          ...selectedCarrier?.carrier,
          providerName: providerName,
        },
      };

      setCarrier(updatedCarrier);

      const additionalChargesTotal = selectedCarrier?.additional_charge
        ? selectedCarrier.additional_charge.reduce(
            (total: number, item: any) => total + (item.price || 0),
            0
          )
        : 0;
      setCarrierAdditionalCharge(additionalChargesTotal);
    } else {
      setCarrier(null);
      setCarrierAdditionalCharge(0);
    }
  }, [carrierPrice]);

  useEffect(() => {
    if (salesPrice && salesPrice?.length > 0) {
      setSalePrice(salesPrice[0]);

      const additionalChargesTotal = Array.isArray(
        salesPrice[0]?.additional_charge
      )
        ? salesPrice[0]?.additional_charge.reduce(
            (total: number, item: any) => total + (item.price || 0),
            0
          )
        : 0;

      const combinedMargin =
        salesPrice[0]?.totalSalePrice -
        (carrierPrice?.length > 0 && carrierPrice[0]?.totalPrice > 0
          ? carrierPrice[0]?.totalPrice
          : salesPrice[0]?.totalSalePrice * 0.9);

      const combinedMarginPercentage =
        salesPrice[0]?.totalSalePrice > 0
          ? (combinedMargin / salesPrice[0]?.totalSalePrice) * 100
          : 0;

      setMargin(combinedMargin?.toFixed(2));
      setMarginPercentage(combinedMarginPercentage?.toFixed(2));
      setSalesAdditionalCharge(additionalChargesTotal);
    } else {
      setSalePrice(null);
      setMargin(null);
      setMarginPercentage(null);
      setSalesAdditionalCharge(null);
    }
  }, [salesPrice, carrierPrice]);

  return (
    <>
      {carrier && getValues('quoteId') && (
        <div className="p-4 rounded-xl border border-borderSecondary bg-utilityindigo25 shadow-xs flex gap-4">
          <div className="w-5 h-5 relative rounded-full text-primary">
            <div className="w-7 h-7 left-[-4px] top-[-4px] absolute opacity-30 rounded-3xl border-2 border-current" />
            <div className="w-[38px] h-[38px] left-[-9px] top-[-9px] absolute opacity-10 rounded-3xl border-2 border-current" />
            <InfoCircle className="w-5 h-5 " />
          </div>
          <div className="space-y-3">
            <div className="flex items-center gap-x-1.5">
              {carrier?.carrier?.image ? (
                <img
                  className="w-7 h-7 rounded border border-utilityBlack border-opacity-[0.08] flex-none"
                  src={`${carrier?.carrier?.image}`}
                />
              ) : (
                <div className="w-7 h-7 rounded border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                  {getShortName(carrier?.carrier?.name ?? 'Unassigned')}
                </div>
              )}

              <h6 className="text-textSecondary text-sm font-semibold">
                {carrier?.carrier?.name ?? 'Unassigned'}
              </h6>
            </div>
            <ul className="list-disc pl-4 space-y-1">
              {getCommonCarrierAPIInformation(
                carrier?.carrier?.providerName,
                carrier?.carrier?.name
              )?.map((messge: any, index: number) => (
                <li
                  className="text-grayLight600 text-sm font-normal"
                  key={index}
                >
                  {messge}
                </li>
              ))}

              {isAddressChanged && (
                <li className="text-grayLight600 text-sm font-normal">
                  A small change in the address will affect the final order
                  price.
                </li>
              )}

              {isDimensionChanged && (
                <li className="text-grayLight600 text-sm font-normal">
                  A small change in the dimensions will affect the final order
                  price.
                </li>
              )}

              {isAdditionalServiceChanged && (
                <li className="text-grayLight600 text-sm font-normal">
                  A small change in the additional service will affect the final
                  order price.
                </li>
              )}
            </ul>
          </div>
        </div>
      )}

      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs mb-4 last:mb-0 overflow-hidden">
        <div className="overflow-auto h-full scrollbar-hide">
          <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0 space-y-3">
            <h6 className="text-grayLight900 text-sm font-medium uppercase">
              Carrier
            </h6>
            <ul className="flex flex-wrap overflow-hidden rounded-lg border border-utilityGray200 bg-white  ">
              <li className="p-4 w-1/4 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Name
                </h6>
                <div className="flex items-center gap-x-1">
                  {carrier?.carrier?.image ? (
                    <img
                      className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      src={`${carrier?.carrier?.image}`}
                    />
                  ) : (
                    <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                      {getShortName(carrier?.carrier?.name ?? 'Unassigned')}
                    </div>
                  )}

                  <h6 className="text-textSecondary text-sm font-medium truncate">
                    <TooltipCmp
                      message={
                        carrier?.carrier?.name &&
                        carrier?.carrier?.name?.length > 18 &&
                        carrier?.carrier?.name
                      }
                    >
                      {carrier?.carrier?.name ?? 'Unassigned'}
                    </TooltipCmp>
                  </h6>
                </div>
              </li>
              <li className="p-4 w-1/4 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Total Carrier Cost
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {carrier?.baseCarrierRate
                    ? `$${carrier?.baseCarrierRate}`
                    : '-'}
                </p>
              </li>
              <li className="p-4 w-1/4 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Base Cost
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {carrierAdditionalCharge
                    ? `$${carrierAdditionalCharge}`
                    : '-'}
                </p>
              </li>
              <li className="p-4 w-1/4 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Total Cost
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {carrier?.totalPrice ? `$${carrier?.totalPrice}` : '-'}
                </p>
              </li>
            </ul>
          </div>
          <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0 space-y-3">
            <h6 className="text-grayLight900 text-sm font-medium uppercase">
              Order Details
            </h6>
            <ul className="flex flex-wrap overflow-hidden [&>li]:relative [&>li]:before:content-[''] [&>li]:before:h-px [&>li]:before:w-[90vw] [&>li]:before:bg-utilityGray200 [&>li]:before:-top-px [&>li]:before:left-0 [&>li]:before:absolute rounded-lg border border-utilityGray200 bg-white  ">
              <li className="px-4 py-3 w-1/4 space-y-1 ">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Customer
                </h6>
                <div className="flex items-center gap-x-1">
                  {customer?.image ? (
                    <img
                      className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      src={`${customer?.image}`}
                    />
                  ) : (
                    <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                      {getShortName(customer?.label ?? 'Unassigned')}
                    </div>
                  )}

                  <h6 className="text-textSecondary text-sm font-medium truncate">
                    <TooltipCmp
                      message={
                        customer?.label &&
                        customer?.label?.length > 18 &&
                        customer?.label
                      }
                    >
                      {customer?.label ?? 'Unassigned'}
                    </TooltipCmp>
                  </h6>
                </div>
              </li>
              <li className="px-4 py-3 w-1/4 space-y-1 ">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Order Type
                </h6>
                <BadgeCmp style="modern" type="success" mainClassName={``}>
                  {classOptions.find(
                    (option) => option.value === getValues('orderType')
                  )?.label || 'Volume LTL'}
                </BadgeCmp>
              </li>
              <li className="px-4 py-3 w-1/4 space-y-1 ">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Equipment Type
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {getValues('equipmentTypeFullName')}
                </p>
              </li>
              <li className="px-4 py-3 w-1/4 space-y-1 ">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Total Weight
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {getValues('totalWeight') ? getValues('totalWeight') : '-'}
                </p>
              </li>

              <li className="px-4 py-3 w-1/2 space-y-1 ">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Pickup Date
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium `}
                  dangerouslySetInnerHTML={{
                    __html: getValues('expectedPickupTime')
                      ? getDateWithSuffixFormat(
                          getFormattedDate(
                            getValues('expectedPickupTime'),
                            `ddd MMM Do, YYYY`
                          )
                        )
                      : '-',
                  }}
                />
              </li>
              <li className="px-4 py-3 w-1/2 space-y-1 ">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Delivery Date
                </h6>
                <p
                  className={`text-textSecondary text-sm font-medium `}
                  dangerouslySetInnerHTML={{
                    __html: getValues('expectedEstimatedDeliveryTime')
                      ? getDateWithSuffixFormat(
                          getFormattedDate(
                            getValues('expectedEstimatedDeliveryTime'),
                            `ddd MMM Do, YYYY`
                          )
                        )
                      : '-',
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0 space-y-3">
            <h6 className="text-grayLight900 text-sm font-medium uppercase">
              Address
            </h6>
            <ul className="flex flex-wrap overflow-hidden rounded-lg border border-utilityGray200 bg-white  ">
              <li className="p-4 w-1/2 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Shipper Name & Address
                </h6>
                <p className={`text-textSecondary text-sm font-medium`}>
                  {currentLegData?.shipper?.companyName}
                </p>
                <p
                  className={`text-textSecondary text-xs font-medium line-clamp-1`}
                >
                  {currentLegData?.shipper?.shipperAddress?.fullAddress}
                </p>
              </li>
              <li className="p-4 w-1/2 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Consignee Name & Address
                </h6>
                <p className={`text-textSecondary text-sm font-medium`}>
                  {currentLegData?.consignee?.companyName}
                </p>
                <p
                  className={`text-textSecondary text-xs font-medium line-clamp-1`}
                >
                  {currentLegData?.consignee?.consigneeAddress?.fullAddress}
                </p>
              </li>
            </ul>
          </div>
          {orderDimension?.length > 0 && orderDimension[0]?.length && (
            <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0 space-y-3">
              <h6 className="text-grayLight900 text-sm font-medium uppercase">
                Dimensions
              </h6>

              {orderDimension?.length > 0 &&
                orderDimension?.map((item: any, index: number) => (
                  <ul
                    className="flex flex-wrap overflow-hidden rounded-lg border border-utilityGray200 bg-white"
                    key={index}
                  >
                    <li className="p-4 w-1/6 space-y-1">
                      <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                        Total Quantity
                      </h6>
                      <p className={`text-textSecondary text-sm font-medium `}>
                        {item?.handlingUnitNo}
                      </p>
                    </li>
                    <div className="p-4 flex-1 space-y-1">
                      <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                        Handling Unit
                      </h6>
                      <p className={`text-textSecondary text-sm font-medium `}>
                        {
                          handlingUnitOptions?.find(
                            (data: any) => item?.handlingUnit === data?.value
                          )?.label
                        }
                        :&nbsp;
                        <span className="font-normal">
                          (
                          {`${item?.length}" x ${item?.width}" x ${item?.height}"`}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="p-4 w-1/6 space-y-1">
                      <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                        Freight Class
                      </h6>
                      <p className={`text-textSecondary text-sm font-medium `}>
                        {item?.freightClass ?? '-'}
                      </p>
                    </div>
                    <div className="p-4 w-1/6 space-y-1">
                      <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                        Total Weight
                      </h6>
                      <p className={`text-textSecondary text-sm font-medium `}>
                        {item?.totalWeight ?? '-'}{' '}
                        {weightMeasurement === 'LBS' ? 'lbs' : 'kgs'}
                      </p>
                    </div>
                    <div className="p-4 w-1/6 space-y-1">
                      <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                        Description
                      </h6>
                      <p className={`text-textSecondary text-sm font-medium `}>
                        {item?.description ?? '-'}
                      </p>
                    </div>
                  </ul>
                ))}
            </div>
          )}
          <div className="p-4 bg-gray25 border-b border-utilityGray200 last:border-b-0 space-y-3">
            <h6 className="text-grayLight900 text-sm font-medium uppercase">
              Sales Price
            </h6>
            <ul className="flex flex-wrap overflow-hidden rounded-lg border border-utilityGray200 bg-white  ">
              <li className="p-4 w-1/6 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Customer
                </h6>
                <div className="flex items-center gap-x-1">
                  {customer?.image ? (
                    <img
                      className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      src={`${customer?.image}`}
                    />
                  ) : (
                    <div className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                      {getShortName(customer?.label ?? 'Unassigned')}
                    </div>
                  )}

                  <h6 className="text-textSecondary text-sm font-medium truncate">
                    <TooltipCmp
                      message={
                        customer?.label &&
                        customer?.label?.length > 18 &&
                        customer?.label
                      }
                    >
                      {customer?.label ?? 'Unassigned'}
                    </TooltipCmp>
                  </h6>
                </div>
              </li>
              <li className="p-4 w-1/6 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Base Sale Price
                </h6>
                <p className={`text-textSecondary text-sm font-medium`}>
                  {salePrice?.baseSalePrice
                    ? `$${salePrice?.baseSalePrice}`
                    : '-'}
                </p>
              </li>
              <li className="p-4 w-1/6 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Additionals
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {salesAdditionalCharge ? `$${salesAdditionalCharge}` : '-'}
                </p>
              </li>
              <li className="p-4 w-1/6 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Total Sale Price
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {salePrice?.totalSalePrice
                    ? `$${salePrice?.totalSalePrice}`
                    : '-'}
                </p>
              </li>
              <li className="p-4 w-1/6 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Margin
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {margin ? `$${margin}` : '-'}
                </p>
              </li>
              <li className="p-4 w-1/6 space-y-1">
                <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
                  Margin %
                </h6>
                <p className={`text-textSecondary text-sm font-medium `}>
                  {marginPercentage ? `${marginPercentage}%` : '-'}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step6;
