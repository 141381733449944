import {
  AlertTriangle,
  ArrowRight,
  Calendar,
  CalendarDate,
  Check,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  ClockRewind,
  Container,
  FilterLines,
  NavigationPointer01,
  PlusCircle,
  SearchLg,
  ShoppingBag03,
  SwitchVertical01,
  XCircle,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DateRangePicker from 'src/components/DateRangePicker';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import SelectBox from 'src/components/SelectBox/SelectBox';
import SelectWithActionTooltip from 'src/components/SelectWithActionTooltip';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import {
  currentDateToDifference,
  formatAddress,
  formatLastUpdate,
  getDateRange,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedDateWithTime,
  getShortName,
  isValidJSON,
  lastLocationUpdateToShowDotColor,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import defaultImage from '../../assets/img/default-image.jpg';
import inboundEmoji from '../../assets/img/inbound-emoji.webp';
import { TimeRanges } from '../SalesDashboard/OverView/OverView.interface';

import { NoDataFound } from './constant';
import LeftSidebarLoading from './LeftSidebarLoading';
import RadarReportInterruptionModal from './RadarReportInterruptionModal';
import SetAppointmentModal from './SetAppointmentModal';

const filterStatusArr = [
  {
    value: 'allStatus',
    label: 'All Status',
    // count: 0,
  },
  {
    value: 'booked',
    label: 'Booked',
    // count: 0,
  },
  {
    value: 'dispatched',
    label: 'Dispatched',
    // count: 0,
  },
];

const filterServiceArr = [
  {
    value: 'allServiceType',
    label: 'All Service Types',
    // count: 0,
  },
  {
    value: 'ftl',
    label: 'FTL',
    // count: 0,
  },
  {
    value: 'volumeLTL',
    label: 'Volume LTL',
    // count: 0,
  },
  {
    value: 'ltl',
    label: 'LTL',
    // count: 0,
  },
];

const filterArr = [
  {
    value: 'booked',
    name: 'Booked',
    // count: 0,
  },
  {
    value: 'dispatched',
    name: 'Dispatched',
    // count: 0,
  },
];

const tabArr = [
  {
    value: 'pickups',
    name: 'Pickups',
    count: 0,
  },
  {
    value: 'in-transit',
    name: 'In Transit',
    count: 0,
  },
  {
    value: 'delivered',
    name: 'Deliveries',
    count: 0,
  },
];

const AdditionalLabelFilter = [
  { label: '🙋‍♂️  Intervention Required', value: 'Intervention Required' },
  { label: '🎢  Roll Over', value: 'Roll Over' },
];

const SortByFilter = [
  { label: 'Last Update Time', value: 'Last Update Time' },
  { label: 'Pickup Date', value: 'Pickup Date' },
  { label: 'Customer', value: 'Customer' },
  { label: 'Estimated Time', value: 'Estimated Time' },
];

interface IProps {
  orders?: any;
  setParams?: any;
  isOrdersLoading?: any;
  params?: any;
  tabCount: any;
  sortToMapReload: any;
}

// Helper functions extracted outside component
const getDotColor = (value: any, filter: string) =>
  filter === 'booked' || filter === 'dispatched'
    ? 'primary'
    : filter === 'delivered'
    ? 'green'
    : (lastLocationUpdateToShowDotColor(
        value?.orderLocationUpdate
          ? value?.orderLocationUpdate?.updatedAt
          : value?.createdAt
      ) as 'primary' | 'red' | 'yellow' | 'green');

const getDotLabel = (value: any, filter: string) => (
  <>
    <span>
      {filter === 'booked' || filter === 'dispatched'
        ? 'Picking up on'
        : filter === 'delivered'
        ? 'Delivered'
        : 'Last Updated:'}
    </span>

    {filter === 'booked' && (
      <span
        className="ml-1"
        dangerouslySetInnerHTML={{
          __html: value?.firstLegExpectedPickupTime
            ? getDateWithSuffixFormat(
                getFormattedDateWithTime(
                  value?.activeLegShipperAppointmentDatetime
                    ? value.activeLegShipperAppointmentDatetime
                    : value?.firstLegExpectedPickupTime,
                  value?.activeLegShipperAppointmentDatetime
                    ? `MMMM Do [at] hh:mm A`
                    : 'MMMM Do',
                  value?.activeLegShipperAppointmentDatetime ? true : false,
                  true
                )
              )
            : '-',
        }}
      />
    )}
    {filter === 'dispatched' && (
      <span
        className="ml-1"
        dangerouslySetInnerHTML={{
          __html: value?.activeLegActualPickupTime
            ? getDateWithSuffixFormat(
                getFormattedDateWithTime(
                  value?.activeLegActualPickupTime,
                  `MMMM Do [at] hh:mm A`,
                  true,
                  true
                )
              )
            : '-',
        }}
      />
    )}

    {filter === 'in-transit' && (
      <span
        className="ml-1"
        dangerouslySetInnerHTML={{
          __html: value?.orderLocationUpdate
            ? currentDateToDifference(
                value?.lastLegExpectedEstimatedDeliveryTime,
                'hours',
                false
              ) < 0
              ? formatLastUpdate(value?.orderLocationUpdate?.updatedAt)
              : currentDateToDifference(
                  value?.lastLegExpectedEstimatedDeliveryTime,
                  'hours',
                  false
                ) < 12
              ? getDateWithSuffixFormat(
                  getFormattedDateWithTime(
                    value?.lastLegExpectedEstimatedDeliveryTime,
                    `MMMM Do [at] hh:mm A`,
                    false,
                    true
                  )
                )
              : formatLastUpdate(value?.orderLocationUpdate?.updatedAt)
            : value?.activeLegActualPickupTime
            ? getDateWithSuffixFormat(
                getFormattedDateWithTime(
                  value?.activeLegActualPickupTime,
                  `MMMM Do [at] hh:mm A`,
                  true,
                  true
                )
              )
            : '-',
        }}
      />
    )}
    {filter === 'delivered' && (
      <span
        className="ml-1"
        dangerouslySetInnerHTML={{
          __html: value?.activeLegActualEstimatedDeliveryTime
            ? getDateWithSuffixFormat(
                getFormattedDateWithTime(
                  value?.activeLegActualEstimatedDeliveryTime,
                  `MMMM Do [at] hh:mm A`,
                  true,
                  true
                )
              )
            : '-',
        }}
      />
    )}
  </>
);

const makeCustomTitleDesc = (params: any, activeFilter: string) => {
  let isCustomTitle;
  let isCustomDesc;
  const isCurrentTimeRange = (
    activeFilter === 'custom' || activeFilter === 'daily'
      ? moment(params?.startDate).format('DD-MM-YYYY') ===
        moment(params?.endDate).format('DD-MM-YYYY')
        ? getFormattedDate(params?.startDate)
        : `${moment(params?.startDate).local().format('Do MMM')} to ${moment(
            params?.endDate || params?.startDate
          )
            .local()
            .format('Do MMM')}`
      : TimeRanges.find((val) => val.value === activeFilter)?.value ?? 'Today'
  )?.trim();

  if (params?.filter === 'in-transit') {
    isCustomTitle = 'No shipments in transit';
    isCustomDesc = 'No shipments are currently in transit.';
  } else {
    if (
      activeFilter === 'custom' &&
      isCurrentTimeRange !== 'Today' &&
      isCurrentTimeRange !== 'Yesterday' &&
      isCurrentTimeRange !== 'Tomorrow'
    ) {
      isCustomTitle = `No ${
        ['in-transit', 'delivered'].includes(params?.filter)
          ? 'shipments'
          : 'orders'
      } ${params?.filter} from ${isCurrentTimeRange}`;
    } else {
      isCustomTitle = NoDataFound(params?.filter, isCurrentTimeRange);
    }
    isCustomDesc = `No ${
      ['in-transit', 'delivered'].includes(params?.filter)
        ? 'shipments'
        : 'orders'
    } were ${params?.filter} during this period.`;
  }

  return { title: isCustomTitle, desc: isCustomDesc };
};

const LeftSidebarNewDesign = ({
  orders,
  setParams,
  isOrdersLoading,
  params,
  tabCount,
  sortToMapReload,
}: IProps) => {
  const navigate = useNavigate();
  const storedParamsString = localStorage.getItem(TABLE_IDS.RADAR_LIST);
  const storedParams = isValidJSON(storedParamsString)
    ? JSON.parse(storedParamsString!)
    : undefined;
  const { hasRoleV2 } = useRolePermission();
  const [activeTab, setActiveTab] = useState(
    storedParams?.filter
      ? storedParams?.filter === 'dispatched' ||
        storedParams?.filter === 'booked'
        ? 'pickups'
        : storedParams?.filter
      : 'pickups'
  );
  const [search, setSearch] = useState(() => storedParams?.search ?? '');
  const { appearance, allTimeStartDateActual } = useContext(BasicContext);

  const [isDisplaySortOption, setIsDisplaySortOption] = useState(false);
  const [activeFilter, setActiveFilter] = useState(() =>
    storedParams?.activeFilter ? storedParams?.activeFilter : 'daily'
  );
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(
    () => storedParams?.startDate ?? ''
  );
  const [endDate, setEndDate] = useState<any>(
    () => storedParams?.endDate ?? ''
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(() =>
    storedParams?.activeFilter ? storedParams?.activeFilter : 'custom'
  );
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>(
    storedParams?.startDate ?? ''
  );
  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      sortToMapReload.current = true;
      setSearch(value);
      searchDebounce(value);
    }
  };

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateActual);

    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateActual);
    }
  }, [allTimeStartDateActual]);

  useEffect(() => {
    if (activeFilter !== 'custom') {
      const { start, end } = getDateRange(activeFilter, allTimeStartDate);

      setStartDate(start);
      setEndDate(end);
      setIsPrevYearDisable(false);
    } else {
      if (startDate && endDate) {
        setStartDate(moment(startDate).local().toDate());
        setEndDate(moment(endDate).local().toDate());
        setIsPrevYearDisable(false);
      }
    }

    if (activeFilter === 'daily' || activeFilter === 'custom') {
      setSelectedTimeRange('custom');
    } else {
      setSelectedTimeRange(activeFilter);
    }
    params.activeFilter = activeFilter;
    localStorage.setItem(TABLE_IDS.RADAR_LIST, JSON.stringify(params));
  }, [activeFilter]);

  useEffect(() => {
    if (tabCount) {
      tabArr[0].count = tabCount?.pickupOrderCount;
      tabArr[1].count = tabCount?.inTransitOrderCount;
      tabArr[2].count = tabCount?.deliveredOrderCount;
      // filterArr[0].count = tabCount?.pickupBookedOrderCount;
      // filterArr[1].count = tabCount?.pickupDispatchedOrderCount;
    }
  }, [tabCount]);

  useEffect(() => {
    if (startDate && endDate) {
      setParams((old: any) => ({
        ...old,
        startDate: `${moment(startDate).local().format('YYYY-MM-DD')}`,
        endDate: `${moment(endDate).local().format('YYYY-MM-DD')}`,
      }));
    }
  }, [startDate, endDate]);

  const getFilterRange = (type?: string) => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      if (activeFilter === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (activeFilter === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(activeFilter);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    sortToMapReload.current = true;
    const [start, end] = date;

    if (timeRange !== 'custom') {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('custom');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  // Add state to track expanded items
  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});

  // Add toggle handler
  const handleToggleComments = (orderId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent order click event from firing
    setExpandedItems((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const [isShowSetAppointmentModal, setIsShowSetAppointmentModal] =
    useState(false);

  const [isShowReportInterruptionModal, setIsShowReportInterruptionModal] =
    useState(false);

  return (
    <>
      <div className="flex gap-x-2.5">
        <InputText
          inputName="searchQuote"
          placeholder="Search"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
          icon={
            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
          }
          value={search}
          inputType="text"
          onChangeFunc={handleFilter}
          parentClassName="flex-1"
          isClearable={true}
        />
        <SelectWithActionTooltip
          isOpenChildTopPosOnTopFalse={true}
          actionTooltipSelectWrap="min-w-[170px]"
          selectTooltipPosition="right"
          selectBoxHeaing={true}
          selectBoxHeaingLabel="Sort by"
          label={
            <ButtonCmp
              className={`btn-outline-primary lg:!px-[9px] !px-2`}
              icon={<SwitchVertical01 className="w-4 h-4" />}
            >
              <></>
            </ButtonCmp>
          }
          options={SortByFilter}
        />
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsDisplaySortOption(false);
          }}
          containerClassName="relative"
        >
          <ButtonCmp
            className={`btn-outline-primary lg:!px-[9px] !px-2`}
            icon={<SwitchVertical01 className="w-4 h-4" />}
            onClick={() => {
              setIsDisplaySortOption(!isDisplaySortOption);
            }}
          >
            <></>
          </ButtonCmp>

          <ul
            className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
              isDisplaySortOption ? '' : 'hidden'
            } `}
          >
            <li className="px-2 text-gray400 my-1.5">Sort by</li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex justify-between gap-x-2 hover:text-primary ${
                params.sortField === 'updatedAt' ? 'bg-gray50' : ''
              } `}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'updatedAt') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'updatedAt',
                  }));
                }
              }}
            >
              <span>Last Update Time</span>
              {params.sortField === 'updatedAt' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary ${
                params.sortField === 'expectedPickupTime' ? 'bg-gray50' : ''
              }`}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'expectedPickupTime') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'expectedPickupTime',
                  }));
                }
              }}
            >
              <span>Pickup Date</span>
              {params.sortField === 'expectedPickupTime' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary ${
                params.sortField === 'customerName' ? 'bg-gray50' : ''
              }`}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'customerName') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'customerName',
                  }));
                }
              }}
            >
              <span>Customer</span>
              {params.sortField === 'customerName' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary ${
                params.sortField === 'expectedEstimatedDeliveryTime'
                  ? 'bg-gray50'
                  : ''
              }`}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'expectedEstimatedDeliveryTime') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'expectedEstimatedDeliveryTime',
                  }));
                }
              }}
            >
              <span>Estimated Time</span>
              {params.sortField === 'expectedEstimatedDeliveryTime' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
          </ul>
        </OutsideClickHandler>
      </div>

      <TabButton
        parentClassName="tab-border-bottom-parent w-full"
        activeClassName="tab-border-bottom-active"
        className="tab-border-bottom flex-1"
        tabBadgeClassName="tab-border-bottom-badge"
        tabArray={tabArr}
        handleOnClick={(e: any) => {
          sortToMapReload.current = true;
          setActiveTab(e?.target?.dataset?.value);

          if (e?.target?.dataset?.value != 'pickups') {
            setParams((old: any) => ({
              ...old,
              ...{ filter: e?.target?.dataset?.value },
            }));
          } else {
            setParams((old: any) => ({
              ...old,
              ...{ filter: 'booked' },
            }));
          }
        }}
        isActive={activeTab}
      />
      {activeTab == 'pickups' ? (
        <TabButton
          tabArray={filterArr}
          handleOnClick={(e: any) => {
            sortToMapReload.current = true;
            setParams((old: any) => ({
              ...old,
              ...{ filter: e?.target?.dataset?.value },
            }));
          }}
          isActive={params.filter}
          isTab={true}
          isTabBadgeStylePill
        />
      ) : (
        <div></div>
      )}
      <div className="flex justify-between gap-x-2">
        <SelectBox
          parentClassName="flex-1 min-w-max"
          name="status"
          id="status"
          className="form_control"
          size="sm"
          placeholder="Filter by status"
          isClearable={true}
          isSearchable={false}
          options={filterStatusArr}
          onChangeFunc={(e: any) => {
            sortToMapReload.current = true;
            setParams((old: any) => ({
              ...old,
              ...{ filter: e?.target?.dataset?.value },
            }));
          }}
          value={params.filter}
        />
        <SelectBox
          parentClassName="flex-1 min-w-max"
          name="serviceType"
          id="serviceType"
          className="form_control"
          size="sm"
          placeholder="Filter by service type"
          isClearable={true}
          isSearchable={false}
          options={filterServiceArr}
          onChangeFunc={(e: any) => {
            sortToMapReload.current = true;
            setParams((old: any) => ({
              ...old,
              ...{ filter: e?.target?.dataset?.value },
            }));
          }}
          value={params.filter}
        />

        {params?.filter !== 'in-transit' && (
          <DateRangePicker
            parentClassName="sales-datepicker max-w-[316px]"
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={allTimeStartDate}
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
            isPrevDisabled={isPrevYearDisable}
            onPrevNextClick={(type: string) => getFilterRange(type)}
            arrowLeftClassname={`!w-[18px] !h-[18px]`}
            arrowRightClassname={`!w-[18px] !h-[18px]`}
          />
        )}
        <SelectWithActionTooltip
          actionTooltipSelectWrap="min-w-[170px]"
          selectTooltipPosition="right"
          label={
            <>
              <ButtonCmp
                className={`btn-outline-primary lg:!px-[9px] !px-2`}
                icon={<FilterLines className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>
              {/* IF ANY FILTER IS SELECTED BELOW CODE WILL BE USED */}
              <ButtonCmp
                className={`relative btn-outline-primary lg:!px-[9px] !px-2 !bg-primary50`}
                icon={<FilterLines className="w-4 h-4" />}
              >
                <p className="border-[3px] border-white bg-primary500 rounded-full w-3 h-3 absolute -top-1.5 -right-1.5"></p>
              </ButtonCmp>
            </>
          }
          options={AdditionalLabelFilter}
        />
      </div>
      <div
        className={`space-y-3.5 flex-1  mb-4 ${
          !isOrdersLoading && !orders?.length
            ? ''
            : 'overflow-y-auto scrollbar-hide'
        }`}
      >
        {!isOrdersLoading &&
          orders?.length > 0 &&
          orders?.map((value: any, index: any) => (
            <div
              key={index}
              className="rounded-lg border border-utilityGray200 bg-white cursor-pointer shadow-none hover:shadow-lgc overflow-hidden"
              onClick={() => {
                if (value?.orderTableId) {
                  navigate(`${PATH.RADAR}/${value?.orderTableId}`);
                }
              }}
            >
              <div className="px-3 py-2 space-y-1.5 border-b border-dashed border-utilityGray200">
                <div className="flex items-center flex-wrap gap-x-2.5">
                  <p
                    className="text-primary text-sm font-medium truncate underline cursor-pointer"
                    onClick={(e: any) => {
                      if (value?.orderTableId) {
                        e.stopPropagation();
                        navigate(`${PATH.ORDER_VIEW}/${value?.orderTableId}`);
                      }
                    }}
                  >
                    {value?.orderId ? `#${value?.orderId}` : ''}
                  </p>
                  <div className="flex-1 flex gap-x-2.5 gap-y-1">
                    {value?.orderType && (
                      <BadgeCmp style="modern" type="success">
                        {value?.orderType?.toUpperCase()}
                      </BadgeCmp>
                    )}
                    {value?.orderStatus && (
                      <BadgeCmp style="modern" type="primary">
                        {value?.orderStatus}
                      </BadgeCmp>
                    )}
                  </div>
                  <div className="flex items-center gap-x-3 flex-none">
                    <TooltipCmp message="Rollover Delivery">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<ClockRewind className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                    {/* USE MODAL FROM ORDER FOR UPDATE LOCATION */}
                    <TooltipCmp message="Update Location">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<NavigationPointer01 className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>

                    <TooltipCmp message="Report Interruption">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<AlertTriangle className="w-4 h-4" />}
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event from bubbling up
                          setIsShowReportInterruptionModal(true);
                        }}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>

                    {/* USE MODAL FROM ORDER FOR Mark as Delivered */}
                    <TooltipCmp message="Mark as Delivered">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<ShoppingBag03 className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                    <TooltipCmp message="Remove From here">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<XCircle className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                    <TooltipCmp message="Mark as Picked Up">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<CheckCircle className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                    <TooltipCmp message="Set Consignee Appointment">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<CalendarDate className="w-4 h-4" />}
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event from bubbling up
                          setIsShowSetAppointmentModal(true);
                        }}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                    <TooltipCmp message="Edit Carrier Appointment">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<Calendar className="w-4 h-4" />}
                        onClick={(e) => {
                          e.stopPropagation(); // Stop event from bubbling up
                          setIsShowSetAppointmentModal(true);
                        }}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                    <TooltipCmp message="Edit Shipper Appointment ">
                      <ButtonCmp
                        className="btn-link-custom"
                        icon={<Container className="w-4 h-4" />}
                      >
                        <></>
                      </ButtonCmp>
                    </TooltipCmp>
                  </div>
                </div>
                <StatusDotProfileCmp
                  dotColor={getDotColor(value, params?.filter)}
                  label={getDotLabel(value, params?.filter)}
                />
              </div>
              <div className="flex items-center px-3 py-2 gap-x-2.5">
                <div className="flex-1 flex gap-x-2.5 truncate">
                  <div className="truncate text-grayLight600 text-xs">
                    <span className="font-semibold">From:&nbsp;</span>
                    <span className="truncate font-normal">
                      {value?.shipperFullAddress
                        ? formatAddress(value?.shipperFullAddress)
                        : ''}
                    </span>
                  </div>
                  <ArrowRight className="w-4 h-4 flex-none" />
                  <div className="truncate text-grayLight600 text-xs">
                    <span className="font-semibold">To:&nbsp;</span>
                    <span className="truncate font-normal">
                      {value?.consigneeFullAddress
                        ? formatAddress(value?.consigneeFullAddress)
                        : ''}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-x-2">
                  <div className="felx-none border-r border-utilityGray200 pr-2.5">
                    <img
                      src={defaultImage}
                      className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  </div>
                  <TooltipCmp
                    message={
                      <>
                        {value?.activeLegCarrierName
                          ? value?.activeLegCarrierName
                          : 'Unassigned Carrier'}
                      </>
                    }
                  >
                    <div
                      className={`w-5 h-5 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]`}
                    >
                      {getShortName(
                        value?.activeLegCarrierName
                          ? value?.activeLegCarrierName
                          : "Unassigned Carrier'"
                      )}
                    </div>
                  </TooltipCmp>
                  {expandedItems[value?.orderTableId] ? (
                    <ChevronUp
                      className="w-4 h-4 text-gray400 flex-none transition-transform"
                      onClick={(e) =>
                        handleToggleComments(value?.orderTableId, e)
                      }
                    />
                  ) : (
                    <ChevronDown
                      className="w-4 h-4 text-gray400 flex-none transition-transform"
                      onClick={(e) =>
                        handleToggleComments(value?.orderTableId, e)
                      }
                    />
                  )}
                </div>
              </div>

              {/* Comments section with conditional rendering */}
              {expandedItems[value?.orderTableId] && (
                <div className="bg-gray25 p-3 border-t border-utilityGray200 space-y-2.5">
                  <div className="flex gap-x-2.5">
                    <div className="w-6 h-6 rounded-full bg-utilityPurple300 flex-none">
                      <img
                        src={inboundEmoji}
                        className={`w-full h-full p-1 object-contain`}
                      />
                    </div>
                    <div className="flex-1 flex items-center gap-x-1">
                      <div className="border border-utilityPurple300 bg-utilityPurple20 shadow-xs rounded-lg rounded-tl-none px-3 py-1.5 text-grayLight900 text-xs font-normal">
                        No reply after 2 follow-ups. Attempting to reach via
                        phone.
                      </div>
                      <p className="text-grayLight600 text-[11px] leading-1.5 font-normal flex-none">
                        8:00 AM
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-x-2.5">
                    <div className="w-6 h-6 rounded-full bg-utilityPurple300 flex-none">
                      <img
                        src={inboundEmoji}
                        className={`w-full h-full p-1 object-contain`}
                      />
                    </div>
                    <div className="flex-1 flex items-center gap-x-1">
                      <div className="border border-utilityPurple300 bg-utilityPurple20 shadow-xs rounded-lg rounded-tl-none px-3 py-1.5 text-grayLight900 text-xs font-normal">
                        Still no carrier response. Please contact carrier
                      </div>
                      <p className="text-grayLight600 text-[11px] leading-1.5 font-normal flex-none">
                        8:15 AM
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="border-t border-utilityGray200 bg-gray50 p-3 flex items-center flex-wrap gap-1.5">
                <div className="inline-flex rounded border border-borderPrimary bg-white shadow-xs px-1.5 py-0.5 text-grayLight600 text-xs font-normal">
                  🙋‍♂️ Intervention Required
                </div>
                <SelectWithActionTooltip
                  actionTooltipSelectWrap="min-w-[170px]"
                  selectTooltipPosition="right"
                  label={
                    <PlusCircle className="w-4 h-4 text-gray400 flex-none" />
                  }
                  options={AdditionalLabelFilter}
                />
              </div>
            </div>
          ))}

        {isOrdersLoading && !orders?.length ? <LeftSidebarLoading /> : ''}
        {!isOrdersLoading && !orders?.length && (
          <NotFoundUI
            title={makeCustomTitleDesc(params, activeFilter)?.title}
            desc={makeCustomTitleDesc(params, activeFilter)?.desc}
            containerClassName="!h-auto flex-1"
            titleClassName={''}
          />
        )}
      </div>
      {isShowSetAppointmentModal && (
        <SetAppointmentModal
          handleClose={() => setIsShowSetAppointmentModal(false)}
        />
      )}
      {isShowReportInterruptionModal && (
        <RadarReportInterruptionModal
          handleClose={() => setIsShowReportInterruptionModal(false)}
        />
      )}
    </>
  );
};

export default LeftSidebarNewDesign;
