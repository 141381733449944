import {
  ArrowRight,
  Edit05,
  Package,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import agentFaceIcon from 'src/assets/img/agent-face-icon.png';
import datIcon from 'src/assets/img/dat-icon.svg';
import loadLinkIcon from 'src/assets/img/load-link-icon.svg';
import truckStopIcon from 'src/assets/img/truck-stop-icon.svg';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
import TooltipCmp from 'src/components/TooltipCmp';
import { STATUS } from 'src/constants/common';
import {
  deleteLoadLinkPosting,
  updateRateGathering,
} from 'src/services/LoadLinkService';
import { getQuote } from 'src/services/QuoteService';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import PostToLoadBoard from '../PostToLoadBoard';

import LoadBoardCountDownTimer from './LoadBoardCountDownTimer';

const LoadPostingCard = ({
  loadLinkPosts,
  setLoadLinkPosts,
  setActivePostIndex,
  activePostIndex,
  loadLinkPostingDetail,
  addressDetails,
  syncLiveData,
  setSyncLiveData,
  validatePostToLoadBoardForm,
  setValidatePostToLoadBoardForm,
  setIsPostCreateLoading,
  setIsCreateLoadPost,
  setLoadLinkPostingDetail,
  setIsUpdateLoadPost,
  isCreateLoadPost,
  isUpdateLoadPost,
  setIsRefresh,
  isRefresh,
  rateGatheringWithAi,
  setCurrentTab,
  isLoading,
  servicesList,
}: any) => {
  const location = useLocation();
  const totalLoadPost = location.state?.totalLoadPost;
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );
  const [deletePostId, setDeletePostId] = useState<number>(0);
  const [
    isShowLoadLinkPostingDeleteConfirmation,
    setIsShowLoadLinkPostingDeleteConfirmation,
  ] = useState(false);
  const [isLoadLinkPostingDeleteLoading, setIsLoadLinkPostingDeleteLoading] =
    useState(false);
  const [refreshLoadPosting, setRefreshLoadPosting] = useState(false);
  const [loadingLoadPosting, setLoadingLoadPosting] = useState(false);
  const [isStopRateLoading, setIsStopRateLoading] = useState([]);
  const [showTimerLoader, setShowTimerLoader] = useState<boolean>(true);
  const [loadingCards, setLoadingCards] = useState<{ [key: number]: boolean }>(
    {}
  );
  const abortControllerRef = useRef<AbortController | null>(null);

  const [isNameTruncated, setIsNameTruncated] = useState(false);
  const [isDestionationNameTruncated, setIsDestionationNameTruncated] =
    useState(false);
  const nameRef = useRef<HTMLHeadingElement>(null);
  const destinationRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (nameRef.current) {
        setIsNameTruncated(
          nameRef.current.scrollWidth > nameRef.current.clientWidth
        );
      }

      if (destinationRef.current) {
        setIsDestionationNameTruncated(
          destinationRef.current.scrollWidth >
            destinationRef.current.clientWidth
        );
      }
    };

    // Check on initial render
    checkTruncation();

    // Add resize listener
    window.addEventListener('resize', checkTruncation);

    // Create observer for content changes
    const resizeObserver = new ResizeObserver(checkTruncation);
    if (nameRef.current) resizeObserver.observe(nameRef.current);
    if (destinationRef.current) resizeObserver.observe(destinationRef.current);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkTruncation);
      resizeObserver.disconnect();
    };
  }, [loadLinkPosts]);

  const calculateMinRate = (loadPost: any) => {
    const offerAmounts = loadPost.leads
      .map((lead: any) => parseFloat(lead.offerAmount))
      .filter((amount: string) => !isNaN(parseFloat(amount)));

    return offerAmounts.length
      ? getFormattedNumber(Math.min(...offerAmounts), true, true, true)
      : '-';
  };

  const calculateAvgRate = (loadPost: any) => {
    const offerAmounts = loadPost.leads
      .map((lead: any) => parseFloat(lead.offerAmount))
      .filter((amount: string) => !isNaN(parseFloat(amount)));

    const avgRate = offerAmounts.length
      ? (
          offerAmounts.reduce((sum: number, val: number) => sum + val, 0) /
          offerAmounts.length
        ).toFixed(2)
      : '';

    return avgRate
      ? getFormattedNumber(parseFloat(avgRate), true, true, true)
      : '-';
  };

  const findInprogressCallCount = (loadPost: any) =>
    loadPost.leads.filter((lead: any) => lead.callStatus === 'In Progress')
      .length;

  const handleLoadPostingDelete = (status: boolean) => {
    if (status && deletePostId > 0) {
      setIsLoadLinkPostingDeleteLoading(true);

      setLoadingCards((prevState) => ({
        ...prevState,
        [deletePostId]: true,
      }));

      deleteLoadLinkPosting(deletePostId)
        .then(() => {
          setDeletePostId(0);
          setIsRefresh(true);
          setRefreshLoadPosting(true);
        })
        .catch((e) => {
          console.log(
            e?.response?.data?.message ??
              'Something went wrong while deleting Load Link Posting.'
          );
        })
        .finally(() => {
          setIsLoadLinkPostingDeleteLoading(false);
          setIsShowLoadLinkPostingDeleteConfirmation(false);
          setLoadingCards((prevState) => ({
            ...prevState,
            [deletePostId]: false,
          }));
        });
    } else {
      setIsShowLoadLinkPostingDeleteConfirmation(false);
      setDeletePostId(0);
    }
  };

  const viewLoadPosting = (loadPost: any, index: number) => {
    setShowTimerLoader(true);
    setLoadLinkPostingDetail(loadPost);
    setActivePostIndex(index);
    localStorage.setItem('loadLinkPostDetail', JSON.stringify(loadPost));
    localStorage.setItem('activePostIndex', String(index));
    localStorage.setItem('loadLinkQuoteId', String(loadPost?.quoteId));
  };

  const setLeadRate = (response: any) => {
    const index = loadLinkPosts.findIndex(
      (posting: any) => posting.id == response.loadLinkPostingDetail.id
    );
    loadLinkPosts[index] = response.loadLinkPostingDetail;
    setLoadLinkPosts([...loadLinkPosts]);
  };

  const stopRateGathering = (truckFindingAi: any) => {
    setIsStopRateLoading((prevStats: any) => ({
      ...prevStats,
      [truckFindingAi.id]: true,
    }));

    const truckFindingAiData = {
      loadLinkPostingId: truckFindingAi.loadLinkQuotePostId,
      acceptInboundCallDuration: 0,
      outgoingCallStartAfter: 0,
      budgetCost: truckFindingAi.budgetCost,
      highestRate: truckFindingAi.highestRate,
      totalRates: truckFindingAi.totalRates,
    };
    updateRateGathering(truckFindingAiData)
      .then(() => {
        setRefreshLoadPosting(true);
      })
      .finally(() => {
        setShowTimerLoader(true);
        setIsStopRateLoading((prevStats: any) => ({
          ...prevStats,
          [truckFindingAi.id]: false,
        }));
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (refreshLoadPosting && addressDetails && addressDetails.id) {
      abortControllerRef.current?.abort();
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      setLoadingLoadPosting(true);
      getQuote(addressDetails.id, signal)
        .then((response: any) => {
          if (
            response &&
            response.data &&
            response.data.addressDetails &&
            response.data.addressDetails.status !== STATUS.PENDING
          ) {
            const responseData = response.data;
            setLoadLinkPosts(responseData.loadLinkPosts);
          }
        })
        .finally(() => {
          setRefreshLoadPosting(false);
          setLoadingLoadPosting(false);
        })
        .catch(console.error);
    }
  }, [refreshLoadPosting]);

  useEffect(() => {
    const storedData = localStorage.getItem('load_post_form_data');
    const loadPostFormData = storedData ? JSON.parse(storedData) : {};

    if (loadPostFormData[addressDetails?.id]) {
      setIsCreateLoadPost(true);
    }
  }, [addressDetails]);

  useEffect(() => {
    console.log('LoadPostingCard isSocketConnected', isSocketConnected);
    console.log('LoadPostingCard socketIO', socketIO);

    if (isSocketConnected && !!socketIO?.on) {
      loadLinkPosts.forEach((post: any) => {
        console.log('🚀 ~ socketIO on loadLinkPosts.forEach ~ post:', post);
        socketIO.on(`lead_rate_${post.id}`, (response: any) => {
          setLeadRate(response);
        });
      });

      return () => {
        if (socketIO?.on) {
          loadLinkPosts.forEach((post: any) => {
            console.log('🚀 ~ socketIO on loadLinkPosts.forEach ~ post:', post);
            socketIO?.off(`lead_rate_${post.id}`);
          });
        }
      };
    }
  }, [isSocketConnected]);

  return (
    <>
      {isCreateLoadPost ||
      (setIsUpdateLoadPost && loadLinkPostingDetail?.id) ? (
        <PostToLoadBoard
          loadLinkPosts={loadLinkPosts}
          setLoadLinkPosts={setLoadLinkPosts}
          activePostIndex={activePostIndex}
          setActivePostIndex={setActivePostIndex}
          loadLinkPostingDetail={loadLinkPostingDetail}
          addressDetails={addressDetails}
          syncLiveData={syncLiveData}
          setSyncLiveData={setSyncLiveData}
          validatePostToLoadBoardForm={validatePostToLoadBoardForm}
          setValidatePostToLoadBoardForm={setValidatePostToLoadBoardForm}
          setIsPostCreateLoading={setIsPostCreateLoading}
          setLoadLinkPostingDetail={setLoadLinkPostingDetail}
          isUpdateLoadPost={isUpdateLoadPost}
          setIsUpdateLoadPost={setIsUpdateLoadPost}
          isCreateLoadPost={isCreateLoadPost}
          setIsCreateLoadPost={setIsCreateLoadPost}
          setIsRefresh={setIsRefresh}
          isRefresh={isRefresh}
          rateGatheringWithAi={rateGatheringWithAi}
          setCurrentTab={setCurrentTab}
          setRefreshLoadPosting={setRefreshLoadPosting}
          stopRateGathering={stopRateGathering}
          isStopRateLoading={isStopRateLoading}
          showTimerLoader={showTimerLoader}
          setShowTimerLoader={setShowTimerLoader}
          servicesList={servicesList}
        />
      ) : (
        <>
          <div className="p-5 flex flex-col flex-1">
            <div
              className={`flex flex-wrap -m-2 ${
                totalLoadPost == 0 && loadLinkPosts.length == 0 ? 'flex-1' : ''
              }`}
            >
              {isLoading || loadingLoadPosting ? (
                <>
                  {Array.from({ length: totalLoadPost }).map((_, index) => (
                    <div key={index} className="w-1/2 p-2 ">
                      <div className="rounded-xl border border-utilityGray100 bg-gray25 shadow-md overflow-hidden cursor-pointer">
                        <div className="flex flex-wrap justify-between items-center px-4 py-3 bg-white border-b border-utilityGray200">
                          <div className="flex items-center gap-1.5">
                            <h3 className="text-grayLight900 text-sm font-medium custom-loading">
                              REF #12345
                            </h3>
                            <div className="w-4 h-4 rounded-full custom-loading">
                              <img
                                src={loadLinkIcon}
                                alt="LoadLink"
                                className="w-4 h-4 rounded-full bg-border300 object-contain"
                              />
                              <img
                                src={datIcon}
                                alt="LoadLink"
                                className="w-4 h-4 rounded-full bg-border300 object-contain"
                              />
                              <img
                                src={truckStopIcon}
                                alt="LoadLink"
                                className="w-4 h-4 rounded-full bg-border300 object-contain"
                              />
                              <BadgeCmp style="modern" type="primary">
                                Published
                              </BadgeCmp>
                            </div>
                          </div>

                          <div className="flex items-center gap-2 custom-loading">
                            <div className="w-6 h-6 rounded-full">
                              <img
                                src={agentFaceIcon}
                                className={`w-6 h-6 rounded-full flex-none bg-success200 p-0.5`}
                              />
                            </div>
                            <ButtonCmp
                              className="btn-link-secondary"
                              icon={<Edit05 className="w-4 h-4" />}
                            >
                              <></>
                            </ButtonCmp>
                            <ButtonCmp
                              className="btn-link-secondary"
                              icon={<Trash01 className="w-4 h-4" />}
                            >
                              <></>
                            </ButtonCmp>
                          </div>
                        </div>

                        <div className="flex flex-wrap p-2">
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Address
                            </h6>
                            <div className="flex gap-2.5 text-grayLight900 text-xs font-medium w-fit custom-loading">
                              <p className="truncate">ON</p>
                              <ArrowRight className="w-3 h-3 flex-none" />
                              <p className="truncate">Toronto </p>
                            </div>
                          </div>

                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Total Rates
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium w-fit custom-loading">
                              2/10 | 5
                            </p>
                          </div>

                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Service Type
                            </h6>
                            <BadgeCmp
                              type={'success'}
                              style="modern"
                              badgeTextColor="!uppercase"
                              mainClassName="custom-loading"
                            >
                              FTL
                            </BadgeCmp>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Total Weight
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium w-fit custom-loading">
                              44000 lbs
                            </p>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Linear Feet
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium w-fit custom-loading">
                              53 ft.
                            </p>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Total Leads
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium w-fit custom-loading">
                              40
                            </p>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Min. Rate
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium w-fit custom-loading">
                              500
                            </p>
                          </div>

                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal w-fit custom-loading">
                              Avg. Rate
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium w-fit custom-loading">
                              600
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {totalLoadPost < 4 &&
                    (totalLoadPost === 0 ? (
                      <NotFoundUI
                        title="No Load Postings Created"
                        desc="No available load postings at the moment. Please add a load posting to continue with your order."
                        containerClassName="flex-1 !h-auto"
                        iconClassName="!hidden"
                        iconSecondaryClassName="!flex"
                        iconSecondary={
                          <Package className="w-5 h-5 text-primary" />
                        }
                        handleActionType={() => {
                          setLoadLinkPostingDetail(undefined);
                          setIsCreateLoadPost(true);
                        }}
                        buttonDisabled={true}
                        buttonText="New Load Posting"
                      />
                    ) : (
                      <div className="w-1/2 p-2">
                        <ButtonCmp
                          className="w-full !min-h-[172px] h-full transition-all delay-100 text-xs font-semibold text-primary inline-flex items-center gap-1 cursor-pointer border border-utilityGray100 hover:border-utilityGray100 !rounded-xl !bg-gray25 shadow-sm hover:shadow-lg custom-loading"
                          icon={<Plus className="w-4 h-4" />}
                          disabled={true}
                        >
                          Add Load Posting
                        </ButtonCmp>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {loadLinkPosts.map((loadPost: any, index: number) => (
                    <div
                      key={index}
                      className="w-1/2 p-2"
                      onClick={() => viewLoadPosting(loadPost, index)}
                    >
                      <div className="rounded-xl border border-utilityGray100 bg-gray25 shadow-md hover:shadow-lg overflow-hidden cursor-pointer">
                        <div className="flex flex-wrap justify-between items-center px-4 py-3 bg-white border-b border-utilityGray200 min-h-[49px]">
                          <div className="flex items-center gap-1.5">
                            <h3 className="text-grayLight900 text-sm font-medium">
                              REF: #{loadPost.refNumber}
                            </h3>

                            {loadPost.loadPostingCompany
                              .split(',')
                              .includes('loadlink') && (
                              <img
                                src={loadLinkIcon}
                                alt="LoadLink"
                                className="w-4 h-4 rounded-full bg-border300 object-contain"
                              />
                            )}
                            {loadPost.loadPostingCompany
                              .split(',')
                              .includes('dat') && (
                              <img
                                src={datIcon}
                                alt="LoadLink"
                                className="w-4 h-4 rounded-full bg-border300 object-contain"
                              />
                            )}
                            {loadPost.loadPostingCompany
                              .split(',')
                              .includes('truckstop') && (
                              <img
                                src={truckStopIcon}
                                alt="LoadLink"
                                className="w-4 h-4 rounded-full bg-border300 object-contain"
                              />
                            )}
                            {loadPost.isDeleted == 0 ? (
                              <BadgeCmp style="modern" type="primary">
                                Published
                              </BadgeCmp>
                            ) : (
                              <BadgeCmp style="modern" type="warning">
                                Unpublished
                              </BadgeCmp>
                            )}
                          </div>

                          <div className="flex items-center gap-2">
                            {loadPost.loadLinkTruckFindingAis[0]
                              ?.acceptInboundCallDuration > 0 &&
                              (() => {
                                const updatedAtUTC = moment.utc(
                                  loadPost.loadLinkTruckFindingAis[0]?.updatedAt
                                );
                                const acceptInboundCallDuration =
                                  loadPost.loadLinkTruckFindingAis[0]
                                    ?.acceptInboundCallDuration || 0;

                                const endDate = updatedAtUTC
                                  .clone()
                                  .add(acceptInboundCallDuration, 'minutes');

                                return (
                                  <LoadBoardCountDownTimer
                                    truckFindingAiDetail={
                                      loadPost.loadLinkTruckFindingAis[0]
                                    }
                                    isStopRateLoading={isStopRateLoading}
                                    stopRateGathering={stopRateGathering}
                                    endDate={endDate}
                                    inboundInprogressCallCount={findInprogressCallCount(
                                      loadPost
                                    )}
                                  />
                                );
                              })()}

                            <ButtonCmp
                              className="btn-link-secondary"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsUpdateLoadPost(true);
                                setLoadLinkPostingDetail(loadPost);
                              }}
                              disabled={isUpdateLoadPost}
                              icon={<Edit05 className="w-3.5 h-3.5" />}
                            >
                              <></>
                            </ButtonCmp>
                            {loadPost.isDeleted == 0 && (
                              <ButtonCmp
                                className="btn-link-secondary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setIsShowLoadLinkPostingDeleteConfirmation(
                                    true
                                  );
                                  setDeletePostId(loadPost.id);
                                }}
                                disabled={
                                  loadingCards[loadPost.id] ? true : false
                                }
                                loading={
                                  loadingCards[loadPost.id] ? true : false
                                }
                                icon={
                                  !loadingCards[loadPost.id] && (
                                    <Trash01 className="w-4 h-4" />
                                  )
                                }
                              >
                                <></>
                              </ButtonCmp>
                            )}
                          </div>
                        </div>

                        <div className="flex flex-wrap p-2">
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Address
                            </h6>
                            <div className="flex items-center gap-2.5 text-grayLight900 text-xs font-medium">
                              <div
                                className="max-w-[45%] truncate"
                                ref={nameRef}
                              >
                                <TooltipCmp
                                  message={
                                    isNameTruncated ? loadPost.sourceCity : ''
                                  }
                                >
                                  {loadPost.sourceCity}
                                </TooltipCmp>
                              </div>
                              <ArrowRight className="w-3 h-3 flex-none" />
                              <div
                                className="max-w-[45%] truncate"
                                ref={destinationRef}
                              >
                                <TooltipCmp
                                  message={
                                    isDestionationNameTruncated
                                      ? loadPost.destinationCity
                                      : ''
                                  }
                                >
                                  {loadPost.destinationCity}
                                </TooltipCmp>
                              </div>
                            </div>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Total Rates
                            </h6>
                            <div className="text-grayLight900 text-xs font-medium flex">
                              <ActionTooltip
                                message={
                                  <p className="text-white text-xs font-medium">
                                    {(() => {
                                      const rates = loadPost.leads.filter(
                                        (lead: any) => lead.offerAmount
                                      ).length;
                                      const target = loadPost
                                        ?.loadLinkTruckFindingAis[0]?.totalRates
                                        ? loadPost?.loadLinkTruckFindingAis[0]
                                            ?.totalRates
                                        : 0;
                                      const processed = loadPost.leads.filter(
                                        (lead: any) =>
                                          lead.callStatus &&
                                          lead.callStatus != 'In Progress'
                                      ).length;

                                      return `${rates} Rate${
                                        rates == 1 ? '' : 's'
                                      }, ${target} Target, ${processed} Processed`;
                                    })()}
                                  </p>
                                }
                                openOnHover={true}
                                isOpenChildTopPosOnTop={true}
                                type="dark"
                                tooltipPosition="center"
                                parentClassName="w-max"
                              >
                                <p className="inline-flex gap-2">
                                  {(() => {
                                    const rates = loadPost.leads.filter(
                                      (lead: any) => lead.offerAmount
                                    ).length;
                                    const target = loadPost
                                      ?.loadLinkTruckFindingAis[0]?.totalRates
                                      ? loadPost?.loadLinkTruckFindingAis[0]
                                          ?.totalRates
                                      : 0;
                                    const processed = loadPost.leads.filter(
                                      (lead: any) =>
                                        lead.callStatus &&
                                        lead.callStatus != 'In Progress'
                                    ).length;

                                    return (
                                      <>
                                        {rates}/{target}
                                        <span className="border-l border-borderPrimary pl-2">
                                          {processed}
                                        </span>
                                      </>
                                    );
                                  })()}
                                </p>
                              </ActionTooltip>
                            </div>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Service Type
                            </h6>
                            <BadgeCmp
                              type={
                                loadPost.serviceType == 'ftl'
                                  ? 'primary'
                                  : 'success'
                              }
                              style="modern"
                              badgeTextColor="!uppercase"
                            >
                              {loadPost.serviceType}
                            </BadgeCmp>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Total Weight
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium">
                              {loadPost.totalWeight
                                ? `${getFormattedNumber(
                                    loadPost.totalWeight,
                                    false,
                                    false,
                                    true
                                  )} lbs`
                                : ''}
                            </p>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Linear Feet
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium">
                              {loadPost.serviceType == 'ftl'
                                ? '53'
                                : `${loadPost.linearFootage}`}{' '}
                              ft.
                            </p>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Total Leads
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium">
                              {loadPost.leads.length}
                            </p>
                          </div>
                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Min. Rate
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium">
                              {calculateMinRate(loadPost)}
                            </p>
                          </div>

                          <div className="w-1/4 p-2 space-y-1">
                            <h6 className="text-gray500 text-xs font-normal">
                              Avg. Rate
                            </h6>
                            <p className="text-grayLight900 text-xs font-medium">
                              {calculateAvgRate(loadPost)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  {loadLinkPosts?.length < 4 ? (
                    loadLinkPosts?.length === 0 ? (
                      <NotFoundUI
                        title="No Load Postings Created"
                        desc="No load postings have been published for this quote, please create a new one to post it on the loadboard of your choice."
                        containerClassName="flex-1 !h-auto"
                        iconClassName="!hidden"
                        iconSecondaryClassName="!flex"
                        iconSecondary={
                          <Package className="w-5 h-5 text-primary" />
                        }
                        buttonText="New Load Posting"
                        handleActionType={() => {
                          setLoadLinkPostingDetail(undefined);
                          setIsCreateLoadPost(true);
                        }}
                      />
                    ) : (
                      <div className="w-1/2 p-2">
                        <ButtonCmp
                          className="w-full !min-h-[172px] h-full border-utilityGray100 hover:border-utilityGray100 !rounded-xl !bg-gray25 shadow-sm hover:shadow-lg !transition-none btn-outline-primary"
                          onClick={() => {
                            setLoadLinkPostingDetail(undefined);
                            setIsCreateLoadPost(true);
                          }}
                          icon={<Plus className="w-4 h-4" />}
                        >
                          Add Load Posting
                        </ButtonCmp>
                      </div>
                    )
                  ) : null}
                </>
              )}
            </div>
          </div>

          {isShowLoadLinkPostingDeleteConfirmation && (
            <CommonModal
              title="Remove Load Link Posting"
              titleDesc="This action will remove load link posting from WAL HQ and Load Link."
              handleClose={() =>
                isLoadLinkPostingDeleteLoading
                  ? false
                  : handleLoadPostingDelete(false)
              }
              secondaryBtnOnClick={() => handleLoadPostingDelete(false)}
              dangerBtnText="Remove"
              secondaryBtnText="Cancel"
              primaryBtnOnClick={() => handleLoadPostingDelete(true)}
              primaryBtnLoading={isLoadLinkPostingDeleteLoading}
              primaryBtnDisabled={isLoadLinkPostingDeleteLoading}
              secondaryBtnDisabled={isLoadLinkPostingDeleteLoading}
              size="max-w-[400px]"
              modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
              modalHeaderTextClass="!flex-col"
              modalFooterClass="border-t-0 pt-0"
            >
              &nbsp;
            </CommonModal>
          )}
        </>
      )}
    </>
  );
};
export default LoadPostingCard;
