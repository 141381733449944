import {
  Container,
  Copy06,
  DownloadCloud02,
  Edit05,
  Share03,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback } from 'react';
import DialOutboundCall from 'src/app/Twilio/CallLogs/DialOutboundCall';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { STATUS } from 'src/constants/common';
import { deleteActiveQuote } from 'src/services/QuoteService';
import {
  downloadPdf,
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
  usePhone,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan-icon-transparent.svg';
import defaultCarrierIcon from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

const QuotedRow = ({
  data,
  handleActionType,
  addressDetails,
  setQuotedList,
  setIsRefresh,
  decreaseActiveCarrierCount,
}: any) => {
  const { getFormatPhoneWithPlus } = usePhone();
  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  const getRemainingDays = (expirationDate: string): string => {
    const expMoment = moment(expirationDate).endOf('day');
    const today = moment().startOf('day');

    const diffDays = expMoment.diff(today, 'days');

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Tomorrow';
    } else if (diffDays > 1) {
      return `${diffDays} Days`;
    } else {
      return 'Expired';
    }
  };

  const handleActiveQuoteDelete = (quoteId: number, quoteCarrierId: any) => {
    deleteActiveQuote(quoteId, {
      quoteCarrierId: [quoteCarrierId],
    })
      .then(() => {
        setIsRefresh(true);
        setQuotedList((prevQuotedList: any) =>
          prevQuotedList.filter((q: any) => quoteCarrierId !== q.id)
        );

        if (decreaseActiveCarrierCount) {
          decreaseActiveCarrierCount();
        }
      })
      .catch(console.error);
  };

  let phoneNumbers = [data.contactPhone];

  if (typeof data.contactPhone === 'string') {
    if (data.contactPhone.startsWith('{')) {
      try {
        const parsed = JSON.parse(data.contactPhone);
        phoneNumbers = Array.isArray(parsed?.phoneNumbers)
          ? parsed.phoneNumbers
          : [data.contactPhone];
      } catch (error) {
        phoneNumbers = [data.contactPhone];
      }
    } else {
      phoneNumbers = [data.contactPhone];
    }
  }

  const phoneNumbersText = phoneNumbers.join(', ');

  const getBusinessDays = (startDate: Date, endDate: Date) => {
    let count = 0;
    const oneDay = 24 * 60 * 60 * 1000;

    for (
      let date = moment(startDate).toDate();
      date <= endDate;
      date.setTime(date.getTime() + oneDay)
    ) {
      const dayOfWeek = date.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return count;
  };

  return (
    <>
      <td className="px-5 py-4 min-w-[230px] w-[230px] max-w-[230px]">
        <div className=" flex items-center">
          <div className="flex-none relative">
            {data.image ? (
              <img
                src={`${data.imageUrl}${data.image}`}
                className="table-profile-icn-circle-xl rounded-md"
                alt={data.name}
                title={data.name}
                onError={onError}
              />
            ) : data?.name ? (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(data.name)}
              </div>
            ) : data?.carrierName ? (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(data.carrierName)}
              </div>
            ) : (
              <>
                <div className="table-profile-customer-circle-xl rounded-md">
                  <Container className="w-4 h-4 text-gray500" />
                </div>
              </>
            )}
            {(data.isFreightcom || data.isFreightcom === 1) && (
              <div className="absolute -bottom-[8px] -right-2.5">
                <TooltipCmp message="Freightcom">
                  <div className="w-5 h-5 rounded-full border border-borderPrimary bg-utilityGray100 p-[1px] hover:border-gray400 cursor-pointer">
                    <img
                      className="object-contain"
                      src={FreightcomIcon}
                      alt="FC"
                    />
                  </div>
                </TooltipCmp>
              </div>
            )}
            {(data.isBanyan || data.isBanyan === 1) && (
              <div className="absolute -bottom-[8px] -right-2.5">
                <TooltipCmp message="Banyan Technologies">
                  <div className="w-5 h-5 rounded-full border border-borderPrimary bg-utilityGray100 p-[2.5px] flex items-center justify-center hover:border-gray400 cursor-pointer">
                    <img
                      className="object-contain"
                      src={BanyanIcon}
                      alt="BANYAN"
                    />
                  </div>
                </TooltipCmp>
              </div>
            )}
            {(data.isAmericanGroup || data.isAmericanGroup === 1) && (
              <div className="absolute -bottom-[8px] -right-[12px]">
                <TooltipCmp message="American Group">
                  <div className="w-[24px] h-[24px] rounded-full border border-borderPrimary bg-utilityGray100 p-1 flex items-center justify-center hover:border-gray400 cursor-pointer">
                    <img
                      className="object-contain"
                      src={defaultCarrierIcon}
                      alt="AMERICAN GROUP"
                    />
                  </div>
                </TooltipCmp>
              </div>
            )}
          </div>
          <div className="pl-5 truncate sapce-y-1">
            {data?.carrierId ? (
              (data.name + (data.banyanPostFix || '')).length > 30 ? (
                <div className="flex items-center">
                  <div className="truncate">
                    <TooltipCmp
                      message={`${data.name} ${data.banyanPostFix || ''}`}
                    >
                      <h6 className="text-grayLight900 font-medium leading-tight truncate">
                        {`${data.name} ${data.banyanPostFix || ''}`}
                      </h6>
                    </TooltipCmp>
                  </div>
                </div>
              ) : (
                <div className="flex items-center">
                  <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none">
                    {`${data.name} ${data.banyanPostFix || ''}`}
                  </h6>
                </div>
              )
            ) : data?.carrierName ? (
              (data?.carrierName + (data.banyanPostFix || '')).length > 30 ? (
                <div className="flex items-center">
                  <div className="truncate">
                    <TooltipCmp
                      message={`${data?.carrierName} ${
                        data.banyanPostFix || ''
                      }`}
                    >
                      <h6 className="text-grayLight900 font-medium leading-tight truncate">
                        {`${data?.carrierName} ${data.banyanPostFix || ''}`}
                      </h6>
                    </TooltipCmp>
                  </div>
                </div>
              ) : (
                <div className="flex items-center">
                  <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none">
                    {`${data?.carrierName} ${data.banyanPostFix || ''}`}
                  </h6>
                </div>
              )
            ) : (
              <div className="flex items-center">
                <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none"></h6>
              </div>
            )}
            {data?.carrierQuoteId || data?.contactPhone ? (
              <div className="text-grayLight600 text-[10px] font-normal flex items-center">
                {data?.carrierQuoteId ? (
                  data?.carrierQuoteId?.length > 30 ? (
                    <TooltipCmp
                      message={`#${data?.carrierQuoteId}`}
                      childrenClassName=""
                    >
                      <span className="max-w-28 truncate inline-block align-middle">
                        #{data?.carrierQuoteId}
                      </span>
                    </TooltipCmp>
                  ) : (
                    <span className="inline align-middle truncate max-w-28">
                      #{data?.carrierQuoteId}
                    </span>
                  )
                ) : (
                  phoneNumbersText &&
                  (phoneNumbersText.split(',').length > 1 ? (
                    <ActionTooltip
                      openOnHover
                      tooltipPosition="center"
                      isOpenChildTopPosOnTop
                      message={phoneNumbersText
                        .split(',')
                        .map((phone: string) => getFormatPhoneWithPlus(phone))
                        .join(', ')}
                      parentClassName="min-w-fit max-w-max w-52"
                    >
                      <p className="truncate max-w-40">
                        {phoneNumbersText
                          .split(',')
                          .map((phone: string) => getFormatPhoneWithPlus(phone))
                          .join(', ')}
                      </p>
                    </ActionTooltip>
                  ) : (
                    <p className="max-w-40">
                      {phoneNumbersText
                        .split(',')
                        .map((phone: string) => getFormatPhoneWithPlus(phone))
                        .join(', ')}
                    </p>
                  ))
                )}
                &nbsp;
                <div className="flex gap-1">
                  {data?.carrierQuoteId ? (
                    <TooltipCmp message="Copy">
                      <Copy06
                        onClick={(event) => {
                          event.stopPropagation();
                          copyDebounce(data?.carrierQuoteId);
                        }}
                        className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                      />
                    </TooltipCmp>
                  ) : (
                    ''
                  )}

                  {data.quoteUrl && (
                    <TooltipCmp message="Open">
                      <a
                        href={data.quoteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(event) => event.stopPropagation()}
                      >
                        <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                      </a>
                    </TooltipCmp>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </td>

      <td className="px-5 py-4 w-[10.8%] min-w-[10.8%] max-w-[10.8%]">
        {!data?.isPrivateCarrier &&
        data.carrierQuoteId &&
        addressDetails?.status !== STATUS.WON ? (
          data.expirationDate ? (
            <BadgeCmp
              style="modern"
              type={
                moment(data.expirationDate)
                  .add(1, 'days')
                  .isBefore(moment().startOf('day'))
                  ? 'red'
                  : 'warning'
              }
            >
              {getRemainingDays(data.expirationDate)}
            </BadgeCmp>
          ) : (
            <BadgeCmp
              style="modern"
              type={
                new Date(addressDetails.pickupDate) < new Date()
                  ? 'red'
                  : 'warning'
              }
            >
              {getRemainingDays(data.expirationDate)}
            </BadgeCmp>
          )
        ) : (
          <BadgeCmp style="modern" type={'red'}>
            No Expiry
          </BadgeCmp>
        )}
      </td>
      <td className="px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%]">
        <span className="hidden">
          Est. {data.transitTime} day{data.transitTime == 1 ? '' : 's'}
        </span>
        {getBusinessDays(
          moment().toDate(),
          moment(data?.projectedDeliveryDate).toDate()
        ) > 0 ? (
          <span>
            Est.{' '}
            {getBusinessDays(
              moment().toDate(),
              moment(data?.projectedDeliveryDate).toDate()
            )}{' '}
            {getBusinessDays(
              moment().toDate(),
              moment(data?.projectedDeliveryDate).toDate()
            ) === 1
              ? 'day'
              : 'days'}
          </span>
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%]">
        {data.transitTime >= 0 ? (
          <span
            dangerouslySetInnerHTML={{
              __html: data.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    moment(data.projectedDeliveryDate).format(`MMM Do - YYYY`)
                  )
                : '-',
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td className="px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%]">
        {addressDetails?.status !== STATUS.WON ||
        addressDetails.finalBookingPrice === data.totalCostCAD
          ? `$${getFormattedNumber(data.totalCostCAD)}`
          : `$${getFormattedNumber(addressDetails?.finalBookingPrice)}`}
      </td>
      <td className="px-5 py-4 w-[172px] min-w-[172px] max-w-[172px]">
        {data.margin === 0 ? '$0.00' : `$${getFormattedNumber(data.margin)}`}
      </td>
      <td className="px-5 py-4 text-sm w-[180px] min-w-[180px] max-w-[180px]">
        <span className="text-textSecondary font-bold">
          {addressDetails?.status !== STATUS.WON ||
          addressDetails.finalBookingPrice === data.totalCostCAD
            ? `$${getFormattedNumber(data.finalCharge)} CAD`
            : `$${getFormattedNumber(
                (addressDetails?.finalBookingPrice || 0) + data.margin
              )}`}
        </span>
      </td>

      {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
        <td className="px-5 py-4 w-[130px] max-w-[130px] min-w-[130px]">
          {addressDetails.status === STATUS.ACTIVE ? (
            <div className="flex justify-end gap-3">
              {data.poq && data.poqUrl ? (
                <TooltipCmp message="Download POQ">
                  <DownloadCloud02
                    className="w-4 h-4 cursor-pointer"
                    onClick={() =>
                      downloadPdf(
                        `${data.poqUrl}${data.poq}`,
                        `POQ-${data.poq}`
                      )
                    }
                  ></DownloadCloud02>
                </TooltipCmp>
              ) : (
                <span className="w-4 h-4">&nbsp;</span>
              )}
              {phoneNumbersText && (
                <TooltipCmp
                  message={`Make a call to ${getFormatPhoneWithPlus(
                    phoneNumbersText.split(',')[0]
                  )}`}
                >
                  <DialOutboundCall
                    data={{
                      contactNumber: getFormatPhoneWithPlus(
                        phoneNumbersText.split(',')[0]
                      ),
                      contactName: ['Not provided', 'None', 'null'].includes(
                        data?.carrierName
                      )
                        ? ''
                        : data?.carrierName,
                      customerName: '',
                      role: 'carrier',
                      contactImageUrl: '',
                      carrierName: data?.carrierName
                        ? data?.carrierName
                        : 'Unknown',
                    }}
                    className="text-primary"
                  />
                </TooltipCmp>
              )}
              <TooltipCmp message="Delete">
                <Trash01
                  className="w-4 h-4 cursor-pointer"
                  onClick={() =>
                    handleActiveQuoteDelete(addressDetails.id, data.id)
                  }
                ></Trash01>
              </TooltipCmp>
              <TooltipCmp message="Modify Pricing">
                <Edit05
                  className="w-4 h-4 cursor-pointer"
                  onClick={handleActionType('model', 'update', data)}
                ></Edit05>
              </TooltipCmp>
            </div>
          ) : (
            <></>
          )}
        </td>
      )}
    </>
  );
};
export default QuotedRow;
