import {
  Calendar,
  CurrencyDollar,
  InfoCircle,
  PackagePlus,
  Percent02,
  Plus,
} from '@untitled-ui/icons-react/build/cjs';
import { CountryCode } from 'libphonenumber-js';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { CURRENCY, MARGIN_TYPE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getCarrierServicePrices } from 'src/services/CarrierService';
import { quickQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  formatDateValue,
  formatDecimalNumber,
} from 'src/utils/CommonFunctions';

import { getBusinessDays, isValidServices } from '../Quote.const';

import AddQuickQuoteAdditionaCharge from './AddQuickQuoteAdditionaCharge';

const initRequiredFields = ['finalCharge'];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const initValidForm = {
  finalCharge: false,
};

const dummyCarriers = [
  {
    label: 'Carrier One',
    value: '1',
    image: 'https://via.placeholder.com/40',
    isImage: true,
    isPrivateCarrier: false,
    contactPhone: '+1-234-567-8901',
  },
  {
    label: 'Carrier Two',
    value: '2',
    image: 'https://via.placeholder.com/40',
    isImage: true,
    isPrivateCarrier: true,
    contactPhone: '+1-987-654-3210',
  },
  {
    label: 'Carrier Three',
    value: '3',
    image: 'https://via.placeholder.com/40',
    isImage: true,
    isPrivateCarrier: false,
    contactPhone: '+44-20-7946-0958',
  },
  {
    label: 'Carrier Four',
    value: '4',
    image: 'https://via.placeholder.com/40',
    isImage: true,
    isPrivateCarrier: true,
    contactPhone: '+61-7-3062-4840',
  },
];

// eslint-disable-next-line max-lines-per-function
const AddQuickQuote = ({
  quoteId,
  currency,
  serviceList,
  handleClose,
  setIsRefresh,
  selectedCarrierId,
  selectedCarrierRates,
  carrierListArr,
  serviceOptions,
  isServiceLoading,
  isCarrierLoading,
  setSearch,
  pickupDate,
  setQuickQuoteData,
  increaseActiveCarrierCount,
  setSelectedCarrierId,
}: any) => {
  const carrierRates = Array.isArray(selectedCarrierRates)
    ? selectedCarrierRates
    : [selectedCarrierRates];

  const { appearance } = useContext(BasicContext);

  const [isPrimaryBtnClicked, setIsPrimaryBtnClicked] =
    useState<boolean>(false);
  const filterRef: any = useRef(null);
  const [formData, setFormData] = useState<any>({});
  const [validForm, setValidForm] = useState<any>(initValidForm);

  const [isPhoneContactValid, setIsPhoneContactValid] = useState<any>(true);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [isShowCarrierList, setIsShowCarrierList] = useState(false);
  const [inputCarrierName, setInputCarrierName] = useState(null);
  const [focusInput, setFocusInput] = useState<'inputText' | 'selectBox'>(
    'selectBox'
  );
  const [notRatedServices, setNotRatedServices] = useState<any>(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isAddItem, setIsAddItem] = useState(true);
  const [lastUpdatedField, setLastUpdatedField] = useState<string | null>(
    'totalCharge'
  );
  const [finalChargeError, setFinalChargeError] = useState<boolean>(false);
  const [carrierValidationError, setCarrierValidationError] = useState(false);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    let initialNotRatedServicesCount = 0;

    if (selectedCarrierRates.length > 0) {
      carrierRates.forEach((selectedCarrierRate: any) => {
        const quotedServices: any = [];
        const nonQuotedServices: any = [];
        const priceData: any = {};
        const services: any = [];
        const isServiceValid: any = [];

        if (
          selectedCarrierRate.services &&
          selectedCarrierRate.services.length
        ) {
          selectedCarrierRate.services.forEach((service: any) => {
            if (service.isQuoted) {
              quotedServices.push({
                [service?.serviceId?.slug]: {
                  id: service?.id,
                  name: service.serviceId?.name,
                  value: service.value
                    ? formatDecimalNumber(service.value, 2)
                    : 0,
                  serviceId: service.serviceId?.id,
                },
              });
            } else {
              initialNotRatedServicesCount++;
              nonQuotedServices.push({
                [service?.serviceId?.slug]: {
                  id: service?.id,
                  name: service.serviceId?.name,
                  value: service.value
                    ? formatDecimalNumber(service.value, 2)
                    : 0,
                  serviceId: service.serviceId?.id,
                },
              });
              existingNonQuoteRequiredFields.push(service?.serviceId?.slug);
            }
            services.push({
              [service?.serviceId ? service?.serviceId?.slug : 'other']: {
                id: service?.id,
                name: service.serviceId?.name,
                value: service.value
                  ? formatDecimalNumber(service.value, 2)
                  : 0,
                serviceId: service.serviceId?.id,
                isOther: service.serviceId?.id ? false : true,
              },
            });
            isServiceValid.push({
              label: true,
              price: true,
            });
            existingRequiredFields.push(service?.serviceId?.slug);
          });
        }
        setValidForm((old: any) => ({
          ...old,
          services: isServiceValid,
        }));

        if (selectedCarrierRate.carrierId) {
          getCarrierServicePrices(selectedCarrierRate.carrierId)
            .then((response: any) => {
              if (response.data && response.data.length) {
                response.data.forEach(
                  (data: any) =>
                    (priceData[data.serviceId] =
                      currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
                );
              }
              selectedCarrierRate.carrierPriceData = priceData;
            })
            .catch(console.log);
        }

        let transitTimeInput = selectedCarrierRate.transitTime;

        if (selectedCarrierRate.projectedDeliveryDate) {
          const currentDate = moment().tz(appearance?.timezone);
          const pickupDateValue = pickupDate
            ? moment(pickupDate).tz(appearance?.timezone)
            : moment().tz(appearance?.timezone);

          const adjustedPickupDate = pickupDateValue.isBefore(currentDate)
            ? currentDate
            : pickupDateValue;

          const deliveryDate = moment
            .utc(selectedCarrierRate.projectedDeliveryDate)
            .tz(appearance?.timezone);

          if (deliveryDate.isValid() && adjustedPickupDate.isValid()) {
            transitTimeInput = getBusinessDays(
              adjustedPickupDate.toDate(),
              deliveryDate.toDate()
            ).toString();
          }
        }

        existingFormData[selectedCarrierRate.id] = {
          transitTime: selectedCarrierRate.transitTime,
          transitTimeInput: transitTimeInput,
          isUnAssignCarrier:
            !selectedCarrierRate.carrierId && !selectedCarrierRate.carrierName,
          carrierId: selectedCarrierRate.carrierId,
          carrierName: selectedCarrierRate.carrierName,
          contactPhone: selectedCarrierRate.contactPhone,
          projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
          margin:
            selectedCarrierRate.margin > 0
              ? formatDecimalNumber(selectedCarrierRate.margin, 2)
              : selectedCarrierRate.margin,
          marginUnit:
            selectedCarrierRate.marginUnit > 0
              ? formatDecimalNumber(selectedCarrierRate.marginUnit, 2)
              : selectedCarrierRate.marginUnit,
          additionalCharges:
            selectedCarrierRate.additionalCharges > 0
              ? formatDecimalNumber(selectedCarrierRate.additionalCharges, 2)
              : 0,
          finalCharge:
            selectedCarrierRate.finalCharge > 0
              ? formatDecimalNumber(selectedCarrierRate.finalCharge, 2)
              : 0,
          totalCharge:
            selectedCarrierRate.totalCharge > 0
              ? formatDecimalNumber(selectedCarrierRate.totalCharge, 2)
              : 0,
          marginType: selectedCarrierRate.marginType,
          carrierPriceData: priceData,
          services,
          quotedServices,
          nonQuotedServices,
        };

        setInputCarrierName(selectedCarrierRate.carrierName);
      });
      setFormData(existingFormData[selectedCarrierId]);

      setNotRatedServices(initialNotRatedServicesCount);
    }
  }, [selectedCarrierRates]);

  useEffect(() => {
    if (!selectedCarrierId) {
      let services: any = [];
      let addServices: any = [];
      let isServiceValid: any = [];
      const serviceListArr = Object.values(serviceList);

      if (serviceListArr && serviceListArr?.length) {
        serviceListArr.forEach((service: any) => {
          services.push({
            [service.slug]: {
              name: service.serviceName,
              value: '',
              serviceId: service.serviceId,
              isOther: service.serviceId ? false : true,
            },
          });
          addServices.push(service.serviceId);
          isServiceValid.push({ label: true, price: true });
        });
      }

      setValidForm((old: any) => ({
        ...old,
        services: isServiceValid,
      }));

      setFormData({ ...formData, services: services, currency });
    }
  }, [serviceList]);

  useEffect(() => {
    if (carrierListArr && carrierListArr.length > 0) {
      setFocusInput('selectBox');
    } else {
      setFocusInput('inputText');
    }
  }, [carrierListArr]);

  useEffect(() => {
    if (focusInput === 'selectBox') {
      const selectBoxInputElement: any =
        document.getElementsByName('selectCarrier')[0];

      if (selectBoxInputElement) {
        selectBoxInputElement?.focus();
      }
    } else {
      const inputTextElement: any =
        document.getElementsByName('carrierName')[0];

      if (inputTextElement) {
        inputTextElement?.focus();
      }
    }
  }, [focusInput]);

  const handleSubmit = async () => {
    setShowPhoneError(true);
    setFinalChargeError(false);
    setCarrierValidationError(false);
    let isAnyFormError = false;

    if (
      !formData?.isUnAssignCarrier &&
      !formData?.carrierId &&
      !formData?.carrierName
    ) {
      isAnyFormError = true;
      setCarrierValidationError(true);
      setIsPrimaryBtnClicked(false);
    }

    if (!formData?.finalCharge || formData?.finalCharge <= 0) {
      isAnyFormError = true;
      setFinalChargeError(true);
      setIsPrimaryBtnClicked(false);
    }

    if (isAnyFormError) {
      return;
    }

    if (isValidServices(formData, setValidForm) && isPhoneContactValid) {
      setIsPrimaryBtnClicked(true);

      try {
        formData.quoteId = quoteId;
        formData.selectedCarrierId = selectedCarrierId ?? '';
        formData.currency = currency;
        formData.margin = formData.margin > 0 ? formData.margin : 0;
        formData.marginUnit = formData.marginUnit > 0 ? formData.marginUnit : 0;
        formData.totalCharge =
          formData.totalCharge > 0 ? formData.totalCharge : 0;
        formData.projectedDeliveryDate = moment(
          formData.projectedDeliveryDate
        ).utc();

        quickQuote(formData)
          .then(() => {
            handleClose(false)();
            setFormData({});
            if (setIsRefresh) setIsRefresh(true);

            if (increaseActiveCarrierCount) {
              increaseActiveCarrierCount();
            }
          })
          .finally(() => {
            setFormData(null);
            setIsPrimaryBtnClicked(false);
            setQuickQuoteData(formData);
          })
          .catch(console.error);
      } catch (error) {
        handleClose(false);
        setIsRefresh(true);
      }
    } else {
      setIsPrimaryBtnClicked(false);
      console.log('not Valid');
    }
  };

  useEffect(() => {
    if (formData) {
      Object.entries(formData).forEach(([key, value]: any) => {
        if (initRequiredFields.includes(key)) {
          if (key === 'finalCharge') {
            setValidForm((prevValidForm: any) => ({
              ...prevValidForm,
              [key]: value > 0 ? true : false,
            }));
          } else {
            setValidForm((prevValidForm: any) => ({
              ...prevValidForm,
              [key]: value ? true : false,
            }));
          }
        }
      });

      const initialSelected = formData?.services?.flatMap((service: any) =>
        Object.keys(service)
      );

      setSelectedOptions(initialSelected);
    }
    let itemCount = serviceOptions?.length + 5;
    setIsAddItem(formData?.services?.length < itemCount);
  }, [formData]);

  const calculateMarginAndTotal = (event: any, isService = false) => {
    const { target } = event;
    const { name } = target;

    const existingFormData: any = { ...formData };

    if (
      ['totalCharge', 'marginUnit', 'margin', 'finalCharge'].includes(name) ||
      isService
    ) {
      let additionalCharges = existingFormData.additionalCharges || 0;

      if (isService) {
        let updatedAdditionCharge = 0;
        existingFormData?.services?.forEach((quoteService: any) => {
          Object.entries(quoteService).forEach(([, service]: any) => {
            service.value = service.value || 0;
            updatedAdditionCharge += parseFloat(service.value);
          });
        });
        existingFormData.additionalCharges = formatDecimalNumber(
          updatedAdditionCharge
        );
      }

      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let finalCharge =
        existingFormData.finalCharge !== undefined
          ? parseFloat(existingFormData.finalCharge)
          : 0;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;

      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      let nonEmptyFields = [total, finalCharge, margin].filter(
        (val) => val !== undefined && val !== 0 && !isNaN(val)
      ).length;

      if (nonEmptyFields < 2) {
        setFormData(existingFormData);

        return;
      }

      if (name === 'totalCharge' || name === 'finalCharge') {
        existingFormData.margin = undefined;
        existingFormData.marginUnit = undefined;
      }

      if (name === 'totalCharge' || isService) {
        if (
          !existingFormData.finalCharge ||
          (!existingFormData.additionalCharges &&
            !existingFormData.totalCharge &&
            !existingFormData.margin)
        ) {
          existingFormData.finalCharge =
            total + margin ? formatDecimalNumber(total + margin, 2) : '';
          existingFormData.marginUnit =
            total > 0 ? formatDecimalNumber((100 * margin) / total, 2) : '';
        } else {
          let marginVal = existingFormData.finalCharge - total;
          existingFormData.margin = marginVal !== 0 ? marginVal : '';
          existingFormData.marginUnit =
            total > 0
              ? formatDecimalNumber((100 * existingFormData.margin) / total, 2)
              : '';
        }
      } else if (name === 'finalCharge') {
        if (total > 0) {
          existingFormData.margin =
            existingFormData.finalCharge - total || undefined;
          existingFormData.marginUnit =
            total > 0
              ? formatDecimalNumber((100 * existingFormData.margin) / total, 2)
              : '';
        }
      } else if (name === 'marginUnit') {
        if (!existingFormData.finalCharge) {
          existingFormData.finalCharge =
            total > 0 ? formatDecimalNumber((total / 100) * marginUnit, 2) : '';
          existingFormData.margin =
            total > 0 ? formatDecimalNumber((total / 100) * marginUnit, 2) : '';
        } else {
          if (lastUpdatedField === 'finalCharge') {
            let totalCharge = formatDecimalNumber(
              existingFormData.finalCharge / (1 + marginUnit / 100) -
                additionalCharges
            );
            let marginVal = formatDecimalNumber(
              existingFormData.finalCharge -
                (existingFormData.totalCharge + additionalCharges)
            );

            existingFormData.totalCharge = totalCharge > 0 ? totalCharge : '';
            existingFormData.margin = marginVal > 0 ? marginVal : '';
          } else {
            let finalChargeVal = formatDecimalNumber(
              total + total * (marginUnit / 100)
            );

            let marginVal = formatDecimalNumber(
              finalChargeVal -
                (existingFormData.totalCharge + additionalCharges)
            );

            existingFormData.finalCharge =
              finalChargeVal > 0 ? finalChargeVal : '';

            existingFormData.margin = marginVal > 0 ? marginVal : '';
          }
        }
      } else if (name === 'margin') {
        if (!existingFormData.finalCharge) {
          let finalChargeVal = formatDecimalNumber(total + (margin || 0), 2);
          existingFormData.finalCharge =
            finalChargeVal > 0 ? finalChargeVal : '';
        } else {
          if (lastUpdatedField === 'finalCharge') {
            let totalChargeVal =
              existingFormData.finalCharge - (margin || 0) - additionalCharges;

            existingFormData.totalCharge =
              totalChargeVal !== 0 ? totalChargeVal : '';
          } else {
            let finalChargeVal = total + margin;

            existingFormData.finalCharge =
              finalChargeVal !== 0 ? finalChargeVal : '';
          }
        }

        existingFormData.marginUnit =
          existingFormData.totalCharge > 0
            ? formatDecimalNumber(
                (100 * margin) /
                  ((+existingFormData.totalCharge || 0) + +additionalCharges),
                2
              )
            : '';
      }
    }

    setFormData(existingFormData);
  };

  const removeOptions = (id: any, val: any) => {
    const form = formData.services?.filter(
      (formDataField: any, index: number) => index !== id
    );

    if (val > 0) {
      calculateMarginAndTotal({ target: { name: 'totalCharge' } }, true);
    }

    setFormData((old: any) => ({
      ...old,
      services: form,
    }));

    setValidForm((old: any) => {
      const updatedServicesValidation = old.services?.filter(
        (_: any, index: number) => index !== id
      );

      return {
        ...old,
        services: updatedServicesValidation,
      };
    });
  };

  const handleInputChange = (event: any, isService = false, index = 0) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;

    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const regex = /^[0-9.]*$/;

    if (
      (['totalCharge', 'marginUnit', 'transitTime', 'margin'].includes(name) ||
        isService) &&
      !regex.test(value)
    ) {
      return;
    }

    if (initRequiredFields.includes(name)) {
      setValidForm((old: any) => ({
        ...old,
        [name]: value ? true : false,
      }));
    }
    setFormData((prevFormData: any) => {
      if (isService) {
        prevFormData?.services?.forEach((quoteService: any, qIndex: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            service.value = service.value || '';
            prevFormData.services[qIndex][slug] = {
              ...service,
              value:
                index === qIndex && name === slug
                  ? value ?? 0
                  : parseFloat(service.value),
            };
          });
        });
      }

      return {
        ...prevFormData,
        [name]: value,
      };
    });

    if (name === 'totalCharge' || name === 'finalCharge' || isService) {
      if (isService) {
        setLastUpdatedField('totalCharge');
      } else {
        setLastUpdatedField(name);
      }
    }

    if (name === 'finalCharge') {
      setFinalChargeError(false);
    }
  };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';

      return newType;
    });
  };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isPhoneValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
    };

    if (phone === '') {
      setIsPhoneContactValid(false);
    } else {
      setIsPhoneContactValid(isPhoneValid);
      validFormData.isPhoneValid = isPhoneValid;
    }

    if (phone !== '')
      setFormData({
        ...formData,
        phone_country_code: code,
        contactPhone: `+${code}${phone}`,
      });
    else
      setFormData({
        ...formData,
        phone_country_code: code,
        contactPhone: ``,
      });
  };

  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      if (
        isShowCarrierList &&
        filterRef.current &&
        !filterRef.current.contains(event.target) &&
        event.target.name !== 'searchOrder'
      ) {
        setIsShowCarrierList(false);
      }
    };

    const handleEscapePress = (event: any) => {
      if (isShowCarrierList && event.key === 'Escape') {
        setIsShowCarrierList(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('keydown', handleEscapePress);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [isShowCarrierList]);

  const pickupDateInTimezone = pickupDate
    ? moment.utc(pickupDate).tz(appearance?.timezone)
    : null;

  return (
    <CommonModal
      title={selectedCarrierId ? 'Edit Quote' : 'Add Quote'}
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={handleClose(false)}
      headerIcon={<PackagePlus />}
      size={`xl:max-w-[606px] md:max-w-[536px] max-w-[496px]`}
      modalClassName=""
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      primaryBtnLoading={isPrimaryBtnClicked}
      primaryBtnDisabled={isPrimaryBtnClicked}
      primaryBtnOnClick={handleSubmit}
      secondaryBtnOnClick={handleClose(false)}
      childrenContainerId={'add_quick_quote'}
    >
      <div className="p-5 flex flex-col gap-y-4">
        {notRatedServices !== 0 && formData?.carrierId && (
          <div className="rounded-md bg-utilityError50 border border-utilityError300 px-3 py-2 flex gap-x-1 font-normal text-xs text-grayLight600 text-wrap">
            <InfoCircle className="text-textSecondary w-4 h-4 flex-none" />
            <div>
              <span className="text-grayLight900 font-semibold">Warning:</span>{' '}
              {notRatedServices} Additional Services not rated. Please contact
              carrier to verify feasibility of the shipment.
            </div>
          </div>
        )}

        <SelectBox
          label="Select Carrier"
          labelClassName="form_label block mb-1.5"
          selectOptionSubLabelClass="!font-normal !text-[11px]"
          selectoptionWraptext="!justify-between"
          selectInputSubLabelClass="!font-normal !text-grayLight600 !text-[11px]"
          labelRight={
            <CheckBox
              classes=""
              labelClassName="!text-primary700"
              label="Unassigned Carrier"
              id="unassignedCarrier"
              labelHtmlFor="unassignedCarrier"
              onChangeFunc={() => {
                setFormData({
                  ...formData,
                  carrierId: undefined,
                  contactPhone: '',
                  isUnAssignCarrier: !formData?.isUnAssignCarrier,
                });
              }}
              checked={formData?.isUnAssignCarrier}
            />
          }
          name="selectCarrier"
          id="selectCarrier"
          size="sm"
          placeholder="Select Carrier"
          noOptionMessage="No Carrier Found"
          isSearchable={true}
          className="form_control"
          options={isCarrierLoading ? dummyCarriers : carrierListArr}
          autoCompleteSelect={true}
          isDisabled={formData?.isUnAssignCarrier}
          onCreateOption={(e: any) => {
            setFormData((prevFormData: any) => ({
              ...prevFormData,
              carrierName: e,
            }));
            setInputCarrierName(e);
          }}
          onChangeFunc={(e: any) => {
            console.log('e?.value', e?.value);
            setSelectedCarrierId(e?.value);

            if (e?.value) {
              setIsPhoneContactValid(true);
            }
            setFormData({
              ...formData,
              carrierId: e?.value,
              carrierName: '',
              contactPhone: e?.contactPhone,
              isUnAssignCarrier: false,
              isPrivateCarrier: e?.isPrivateCarrier,
            });
          }}
          onInputChange={(e: any) => {
            setSearch(e);
            setInputCarrierName(e?.target?.value);
            setFormData((prevFormData: any) => ({
              ...prevFormData,
              carrierName: e,
            }));
          }}
          value={
            formData?.carrierId
              ? carrierListArr?.find(
                  (e: any) => e?.value == formData?.carrierId
                ) || {
                  value: selectedCarrierRates[0]?.carrierId,
                  label: selectedCarrierRates[0]?.name,
                  image:
                    selectedCarrierRates[0]?.imageUrl +
                    selectedCarrierRates[0]?.image,
                  isImage: true,
                  subLabel: selectedCarrierRates[0]?.provider,
                }
              : inputCarrierName
              ? { label: inputCarrierName, value: inputCarrierName }
              : null
          }
          isClearable
          isOptionLoading={isCarrierLoading}
          errorText={carrierValidationError}
        />

        {formData?.carrierName &&
          formData?.carrierName !== '' &&
          !formData?.carrierId && (
            <div className="flex flex-col">
              <label className="form_label block mb-1.5">
                {' '}
                Contact Number
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              </label>
              <PhoneInput
                onNumberChange={handleNumberChange}
                errors={showPhoneError && !isPhoneContactValid}
                name="contactPhone"
                value={formData?.contactPhone ?? ''}
              />
            </div>
          )}

        <div className="flex w-full gap-2">
          <DateSelect
            inputName="projectedDeliveryDate"
            className={`form_control `}
            label="Projected Delivery"
            labelClassName="block"
            placeholder={'Enter Projected Delivery'}
            dateFormat="dd/MM/yyyy"
            minDate={
              pickupDateInTimezone && pickupDateInTimezone.isAfter(moment())
                ? pickupDateInTimezone.toDate()
                : moment().tz(appearance?.timezone).toDate()
            }
            isClearable
            selected={
              formData && formData.projectedDeliveryDate
                ? moment(formData.projectedDeliveryDate).toDate()
                : null
            }
            errorText={''}
            value={
              formData?.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    formatDateValue(formData.projectedDeliveryDate)
                  )
                : ''
            }
            onChangeFunc={(date: any) => {
              if (date?.value) {
                const currentDate = moment().tz(appearance?.timezone);
                const pickupDateValue = pickupDate
                  ? moment(pickupDate).tz(appearance?.timezone)
                  : currentDate;

                const adjustedPickupDate = pickupDateValue.isBefore(currentDate)
                  ? currentDate
                  : pickupDateValue;

                const deliveryDate = moment(date.value).tz(
                  appearance?.timezone
                );

                let businessDays;

                if (deliveryDate.isSame(adjustedPickupDate, 'day')) {
                  businessDays = 0;
                } else {
                  businessDays = getBusinessDays(
                    adjustedPickupDate.toDate(),
                    deliveryDate.toDate()
                  );
                }

                setFormData({
                  ...formData,
                  projectedDeliveryDate: date?.value,
                  transitTimeInput: businessDays.toString(),
                });
              } else {
                setFormData({
                  ...formData,
                  projectedDeliveryDate: null,
                  transitTimeInput: null,
                });
              }
            }}
            icon={<Calendar className="h-4 w-4 " />}
            calendarIconClass="!text-primary"
            parentClassName="fixed-width-datepicker right-side-datepicker one-month-datepicker flex-1"
          />

          <InputText
            label="Est. Transit Time"
            labelClassName="block mb-1.5"
            placeholder="Enter transit time"
            parentClassName="flex-1"
            inputName="transitTimeInput"
            inputType="number"
            value={
              formData?.transitTimeInput !== null
                ? formData?.transitTimeInput
                : ''
            }
            onChangeFunc={(e: any) => {
              const value = e.target.value;

              if (value.length > 1 && value.startsWith('0')) {
                const newValue = value.substring(1);
                setFormData({
                  ...formData,
                  transitTimeInput: newValue,
                });
              } else {
                setFormData({
                  ...formData,
                  transitTimeInput: value,
                });
              }
            }}
            onBlur={(e: any) => {
              const transitDays = parseInt(e.target.value);

              if (transitDays > 365) {
                return;
              }

              if (!isNaN(transitDays)) {
                const currentDate = moment().tz(appearance?.timezone);
                const pickupDateValue = pickupDate
                  ? moment(pickupDate).tz(appearance?.timezone)
                  : moment().tz(appearance?.timezone);

                const adjustedPickupDate = pickupDateValue.isBefore(currentDate)
                  ? currentDate
                  : pickupDateValue;

                if (transitDays === 0) {
                  setFormData({
                    ...formData,
                    transitTimeInput: '0',
                    projectedDeliveryDate: adjustedPickupDate.format(),
                  });

                  return;
                }

                let deliveryDate = moment(adjustedPickupDate);
                let daysAdded = 0;

                while (daysAdded < transitDays) {
                  deliveryDate.add(1, 'days');

                  const dayOfWeek = deliveryDate.day();

                  if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                    daysAdded++;
                  }

                  if (daysAdded > 365) break;
                }

                setFormData({
                  ...formData,
                  transitTimeInput: e.target.value,
                  projectedDeliveryDate: deliveryDate.format(),
                });
              } else if (e.target.value === '') {
                setFormData({
                  ...formData,
                  transitTimeInput: '',
                  projectedDeliveryDate: null,
                });
              }
            }}
            shouldFormatNumber={true}
            inputValueUnitType={
              formData?.transitTimeInput === '1'
                ? 'Business Day'
                : 'Business Days'
            }
          />
        </div>

        <InputText
          label="Carrier Cost"
          placeholder="Enter cost"
          labelWrapClass="mb-1.5"
          labelClassName="block mb-0"
          inputType="number"
          inputName="totalCharge"
          className="pl-8"
          parentClassName="w-full"
          value={formData?.totalCharge || undefined}
          onChangeFunc={handleInputChange}
          onBlur={calculateMarginAndTotal}
          shouldFormatNumber={true}
          labelIconRight={
            formData?.services?.length === 0 && (
              <div
                className={`btn-link-custom`}
                onClick={() => {
                  setFormData((old: any) => ({
                    ...old,
                    services: [
                      ...old.services,
                      {
                        [`${formData?.services?.length}`]: {
                          name: '',
                          value: '',
                          serviceId: '',
                          isOther: false,
                        },
                      },
                    ],
                  }));

                  setValidForm((old: any) => ({
                    ...old,
                    services: [
                      ...old.services,
                      {
                        label: true,
                        price: true,
                      },
                    ],
                  }));
                }}
              >
                <Plus className="w-4 h-4" />
                Add Additional Charges
              </div>
            )
          }
          onFocus={() => {
            if (Number(formData?.totalCharge) === 0) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                totalCharge: '',
              }));
            }
          }}
          icon={<CurrencyDollar className="input-currency-sign" />}
        />

        <AddQuickQuoteAdditionaCharge
          formData={formData}
          isAddItem={isAddItem}
          isServiceLoading={isServiceLoading}
          serviceOptions={serviceOptions}
          selectedOptions={selectedOptions}
          validForm={validForm}
          setSelectedOptions={setSelectedOptions}
          setFormData={setFormData}
          setValidForm={setValidForm}
          handleInputChange={handleInputChange}
          calculateMarginAndTotal={calculateMarginAndTotal}
          removeOptions={removeOptions}
        />

        <div className="w-ful">
          <label className="form_label block mb-1.5">Margin</label>
          <div className="form_control p-0 relative !h-auto">
            {activeMarginType === '$' ? (
              <InputText
                inputName="margin"
                inputType="number"
                className="!border-0 !h-auto !pl-8 !pr-20"
                labelClassName="block mb-1.5"
                value={formData?.margin || undefined}
                placeholder="Enter margin"
                onChangeFunc={handleInputChange}
                onBlur={calculateMarginAndTotal}
                shouldFormatNumber={true}
                onFocus={() => {
                  if (Number(formData?.margin) === 0) {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      margin: '',
                    }));
                  }
                }}
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            ) : (
              <InputText
                inputName="marginUnit"
                inputType="number"
                placeholder="Enter margin"
                className="!border-0 !h-auto !pl-8 !pr-20"
                labelClassName="block mb-1.5"
                value={formData?.marginUnit}
                onChangeFunc={handleInputChange}
                onBlur={calculateMarginAndTotal}
                shouldFormatNumber={true}
                onFocus={() => {}}
                icon={<Percent02 className="input-currency-sign" />}
              />
            )}

            <TabButton
              tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
              parentClassName="!bg-gray50 !p-[3px]"
              className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
              activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
              tabArray={tabArray}
              isActive={activeMarginType}
              isTab={true}
              handleOnClick={handleTabChange}
            />
          </div>
        </div>

        <InputText
          label="Total Sale Price"
          inputName="finalCharge"
          inputType="number"
          placeholder="Enter sale price"
          className={`pl-8 ${finalChargeError ? 'border-red-600' : ''}`}
          labelClassName="block mb-1.5"
          parentClassName="w-full"
          value={formData?.finalCharge || undefined}
          onChangeFunc={handleInputChange}
          onBlur={calculateMarginAndTotal}
          shouldFormatNumber={true}
          onFocus={() => {
            if (formData?.finalCharge === 0) {
              setFormData((prevFormData: any) => ({
                ...prevFormData,
                finalCharge: '',
              }));
            }
          }}
          required
          icon={<CurrencyDollar className="input-currency-sign" />}
        />
      </div>
    </CommonModal>
  );
};
export default AddQuickQuote;
