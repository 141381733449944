import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import { declineCreditIncreaseRequest } from 'src/services/CreditIncreaseRequestService';

interface IProps {
  handleClose: any;
  creditIncreaseRequestData: any;
}

const CreditIncreaseDeclineModal = ({
  handleClose,
  creditIncreaseRequestData,
}: IProps) => {
  const [declineExplanation, setDeclineExplanation] = useState('');
  const [isCreditRequestDeclineLoading, setIsCreditRequestDeclineLoading] =
    useState(false);
  const [error, setError] = useState('');
  console.log('creditIncreaseRequestData ', creditIncreaseRequestData);

  const handleCreditIncreaseRequestDeclineAction = () => {
    if (!declineExplanation.trim()) {
      setError('Decline explanation is required.');

      return;
    }

    setError('');
    setIsCreditRequestDeclineLoading(true);

    declineCreditIncreaseRequest({
      creditIncreaseRequestId: creditIncreaseRequestData?.id,
      declineExplanation: declineExplanation,
    })
      .then(() => {
        handleClose(true);
      })
      .finally(() => {
        setIsCreditRequestDeclineLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <CommonModal
      title={'Decline Credit Request'}
      titleDesc={'Provide a Reason for declining the request.'}
      handleClose={() =>
        isCreditRequestDeclineLoading ? false : handleClose(false)
      }
      headerIcon={<XClose />}
      size={'max-w-[500px]'}
      isOverflow={true}
      modalClassName=""
      primaryBtnText="Decline"
      secondaryBtnText="Cancel"
      primaryBtnLoading={isCreditRequestDeclineLoading}
      primaryBtnDisabled={isCreditRequestDeclineLoading}
      secondaryBtnDisabled={isCreditRequestDeclineLoading}
      primaryBtnOnClick={handleCreditIncreaseRequestDeclineAction}
      secondaryBtnOnClick={() =>
        isCreditRequestDeclineLoading ? false : handleClose(false)
      }
    >
      <div className="w-full p-5 mb-1.5">
        <InputText
          placeholder="Enter Decline Explanation"
          label="Decline Explanation"
          labelClassName="block mb-1.5"
          className="min-h-[100px]"
          asTextarea={'textarea'}
          value={declineExplanation}
          onChangeFunc={(e) => setDeclineExplanation(e?.target?.value)}
        />
        {error && <ErrorMsg errorText={error} />}
      </div>
    </CommonModal>
  );
};

export default CreditIncreaseDeclineModal;
