import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import EmailReplyCmp from 'src/components/EmailReplyCmp';

interface IProps {
  handleClose: any;
  DraftDetails: any;
  title: any;
  isShowInitialMessage?: any;
  onSubmit?: any;
}

const DraftMailModal = ({
  handleClose,
  DraftDetails,
  title,
  onSubmit,
}: IProps) => (
  <CommonModal
    title={title}
    titleDesc=""
    modalHeaderClass="border-b-0"
    handleClose={handleClose}
    headerIcon={''}
    size={'max-w-[600px]'}
    isOverflow={true}
  >
    <EmailReplyCmp
      subjectLine={DraftDetails?.subjectLine}
      toEmailAddress={DraftDetails?.toEmailAddress}
      emailDetails={DraftDetails?.emailDetails}
      handleClose={handleClose}
      onSubmit={onSubmit}
    />
  </CommonModal>
);

export default DraftMailModal;
