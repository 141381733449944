import { yupResolver } from '@hookform/resolvers/yup';
import { Calendar, Plus, XClose } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import DateSelect from 'src/components/DateSelect/DateSelect';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { DATE_FORMAT } from 'src/constants/common';
import { addPickupAiAgentReminder } from 'src/services/RadarService';
import {
  getDateWithSuffixFormat,
  getFormattedDateWithTime,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import { dateArr, formatTo1PM, timeArr } from './constant';

const validationSchema = yup.object().shape({
  pickupDate: yup.string().required(),
  reminders: yup.array().of(
    yup.object().shape({
      pickupId: yup.number().nullable(),
      reminderDate: yup.string().required(),
      reminderTime: yup.string().required(),
    })
  ),
});

const PickUpModal = forwardRef(
  ({
    activeLegData,
    reviewConfigData,
    pickupModalRef,
    loading,
    setLoading,
    getOrderAIAgentDetails,
    setStep,
    aiAgentDetails,
  }: any) => {
    const pickupDate = activeLegData?.carrierPickupAppointmentDatetime
      ? activeLegData?.carrierPickupAppointmentDatetime
      : activeLegData?.expectedPickupTime
      ? formatTo1PM(activeLegData?.expectedPickupTime)
      : undefined;

    const initialReminders =
      reviewConfigData?.isPickup?.length > 0
        ? reviewConfigData?.isPickup?.map((pickup: any) => ({
            pickupId: pickup?.id || null,
            reminderDate: pickup?.reminderDateType || '',
            reminderTime: pickup?.reminderTimeType || '',
          }))
        : [
            {
              pickupId: null,
              reminderDate: '',
              reminderTime: '',
            },
          ];

    const {
      control,
      setValue,
      clearErrors,
      formState: { errors },
      handleSubmit,
    } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        pickupDate: pickupDate,
        reminders: initialReminders,
      },
    });

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'reminders',
    });

    const onSubmit = async (data: any) => {
      if (loading) return;

      const hasReminderCountChanged =
        data.reminders.length !== (reviewConfigData?.isPickup?.length || 0);

      const hasReminderDataChanged = data.reminders.some(
        (newReminder: any, index: number) => {
          const existingReminder = reviewConfigData?.isPickup?.[index];

          if (!existingReminder) return true;

          const hasFieldChanges =
            newReminder.reminderDate !== existingReminder.reminderDateType ||
            newReminder.reminderTime !== existingReminder.reminderTimeType;

          return hasFieldChanges;
        }
      );

      if (!hasReminderCountChanged && !hasReminderDataChanged) {
        setStep(3);

        return;
      }

      const calculateReminderDateTime = (
        isPickupDate: string,
        reminderDateType: string,
        reminderTimeType: string
      ) => {
        if (!isPickupDate) return null;

        const pickupMoment = moment(isPickupDate);
        let reminderMoment = pickupMoment.clone();

        // Handle date adjustment
        if (reminderDateType === 'day_before') {
          reminderMoment.subtract(1, 'days');
        }
        // For "Day Off" we keep the same day

        // Handle time adjustment
        if (reminderTimeType === '1_hour_before_appointment') {
          reminderMoment.subtract(1, 'hours');
        } else if (reminderTimeType === '2_hour_before_appointment') {
          reminderMoment.subtract(2, 'hours');
        }

        return reminderMoment.format('YYYY-MM-DD HH:mm:ss');
      };
      setLoading(true);
      const payload = {
        masterOrderId: activeLegData?.masterOrderId,
        legId: activeLegData?.id,
        agentId: aiAgentDetails?.id,
        reminderDetails: data.reminders.map((reminder: any) => ({
          id: reminder.pickupId ?? null,
          reminderDateType: reminder.reminderDate,
          reminderTimeType: reminder.reminderTime,
          reminderDateTime: calculateReminderDateTime(
            pickupDate,
            reminder.reminderDate,
            reminder.reminderTime
          ),
        })),
      };
      addPickupAiAgentReminder(payload)
        .then((response: any) => {
          if (response?.message === 'Success') {
            getOrderAIAgentDetails();
            setStep(3);
          }
        })
        .catch((error) => {
          console.log('error :>> ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const handleAddReminder = () => {
      append({ reminderDate: '', reminderTime: '' });
    };

    useImperativeHandle(pickupModalRef, () => ({
      pickupSubmit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <>
        <div className="space-y-1">
          <h2 className="text-grayLight900 text-lg font-semibold">Pickups</h2>
          <p className="text-grayLight600 text-sm font-normal">
            Please fill require pickups details
          </p>
        </div>
        <div className="flex-1 overflow-auto custom-scrollbar-v2">
          <div className="">
            <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
              <div className="flex">
                <label className="form_label mb-0">
                  Carrier Pickup Appointment
                </label>
              </div>
            </div>
            <Controller
              name="pickupDate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DateSelect
                  inputName="pickupDate"
                  className={`form_control`}
                  placeholder={DATE_FORMAT}
                  dateFormat="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  selected={value ? moment(value).toDate() : null}
                  value={
                    value
                      ? getDateWithSuffixFormat(
                          getFormattedDateWithTime(
                            value,
                            'dddd, MMMM Do, YYYY [at] h:mmA',
                            true,
                            true
                          )
                        )
                      : ''
                  }
                  onChangeFunc={(date: any) => {
                    onChange(date.value ?? null);
                  }}
                  icon={<Calendar className="h-5 w-5" />}
                  parentClassName="w-full one-month-datepicker"
                  disabled={true}
                />
              )}
            />
          </div>
          <div className="space-y-4 mt-4">
            {fields?.map((field, index) => (
              <div key={field.id} className="flex gap-x-3">
                <Controller
                  name={`reminders.${index}.reminderDate`}
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectBox
                      parentClassName="w-[160px]"
                      isClearable
                      name={`reminders.${index}.reminderDate`}
                      placeholder="Select Date"
                      label={index === 0 ? 'Reminder Date' : ''}
                      className={`form_control`}
                      labelClassName="form_label mb-1.5 block"
                      isSearchable={true}
                      options={dateArr}
                      value={dateArr?.find((val) => val?.value === value)}
                      onChangeFunc={(e: any) => {
                        setValue(
                          `reminders.${index}.reminderDate`,
                          e?.value || null
                        );

                        if (e?.value) {
                          clearErrors(`reminders.${index}.reminderDate`);
                        }
                      }}
                      isError={
                        errors.reminders?.[index]?.reminderDate?.message
                          ? true
                          : false
                      }
                    />
                  )}
                />
                <Controller
                  name={`reminders.${index}.reminderTime`}
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectBox
                      parentClassName="flex-1"
                      isClearable
                      name={`reminders.${index}.reminderTime`}
                      placeholder="Select Time"
                      label={index === 0 ? 'Reminder Time' : ''}
                      className={`form_control`}
                      labelClassName="form_label mb-1.5 block"
                      isSearchable={true}
                      options={timeArr}
                      value={timeArr?.find((val) => val?.value === value)}
                      onChangeFunc={(e: any) => {
                        setValue(
                          `reminders.${index}.reminderTime`,
                          e?.value || null
                        );

                        if (e?.value) {
                          clearErrors(`reminders.${index}.reminderTime`);
                        }
                      }}
                      isError={
                        errors.reminders?.[index]?.reminderTime?.message
                          ? true
                          : false
                      }
                    />
                  )}
                />
                <ButtonCmp
                  className={`${
                    index === 0 ? 'mt-[22px]' : ''
                  } btn-outline-primary lg:!px-[9px] !px-2`}
                  icon={<XClose className="w-4 h-4" />}
                  onClick={() => fields.length > 1 && remove(index)}
                  type="button"
                  disabled={loading}
                >
                  <></>
                </ButtonCmp>
              </div>
            ))}
            <div className="text-right">
              <ButtonCmp
                icon={<Plus className="w-4 h-4" />}
                className="btn-link-custom"
                onClick={handleAddReminder}
                type="button"
                disabled={loading}
              >
                Add Pickup Reminder
              </ButtonCmp>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default PickUpModal;
