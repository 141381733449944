import { CurrencyDollar } from '@untitled-ui/icons-react/build/cjs';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import AdditionalServiceCmp from 'src/app/Orders/Common/AdditionalServiceCmp';
import LoadInsuranceModal from 'src/app/Orders/Common/LoadInsuranceModal';
import { handleInputChange } from 'src/app/Orders/order.constant';
import BadgeCmp from 'src/components/BadgeCmp';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { getFormattedNumber } from 'src/utils/CommonFunctions';

import Dimensions from './Dimensions';

interface IProps {
  setIsTailgate: any;
  currentLegData: any;
  serviceList: any;
  setCurrentLegData: any;
  setWeightMeasurement: any;
  setLengthMeasurement: any;
  lengthMeasurement: any;
  weightMeasurement: any;
  order: any;
  setOrder: any;
  handlingUnitOptions: any;
  isHandlingUnitLoading: any;
}

const initAction = {
  mode: null,
  carrier: false,
};

const AddOrderDetails = ({
  setIsTailgate,
  currentLegData,
  serviceList,
  setCurrentLegData,
  setWeightMeasurement,
  setLengthMeasurement,
  lengthMeasurement,
  weightMeasurement,
  order,
  setOrder,
  handlingUnitOptions,
  isHandlingUnitLoading,
}: IProps) => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
  } = useFormContext();

  const [isInsuranceToggleChecked, setIsInsuranceToggleChecked] =
    useState(false);
  const [action, setAction] = useState<{
    mode: string | null;
    carrier: boolean;
  }>(initAction);

  const currencyOptions = [
    {
      label: 'USD',
      value: 'USD',
    },
    {
      label: 'CAD',
      value: 'CAD',
    },
  ];

  useEffect(() => {
    if (order?.loadInsuranceId) {
      setIsInsuranceToggleChecked(true);
    }
  }, [order]);

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setIsInsuranceToggleChecked(false);
  }, []);

  return (
    <>
      <div className="">
        <h5 className="text-textSecondary text-sm font-medium mb-1 leading-[1.5]">
          Add Order Details
        </h5>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5">
          <div className="flex flex-wrap sm:-mx-1.5 sm:-my-2">
            <Controller
              name={`poNumber`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  inputName="PO_Number"
                  label="PO Number"
                  placeholder="Enter PO number"
                  value={value}
                  labelClassName="block mb-1.5"
                  className=""
                  parentClassName="sm:px-1.5 sm:py-2 sm:w-[19%]"
                  onChangeFunc={(e) => {
                    onChange(e);

                    if (error?.message) {
                      clearErrors('poNumber');
                    }
                  }}
                  errorText={error ? error.message : null}
                />
              )}
            />

            <Controller
              name={`refNumber`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  inputName="refNumber"
                  label="REF Number"
                  placeholder="Enter REF number"
                  value={value}
                  labelClassName="block mb-1.5"
                  className=""
                  parentClassName="sm:px-1.5 sm:py-2 sm:w-[19%]"
                  onChangeFunc={(e) => {
                    onChange(e);

                    if (error?.message) {
                      clearErrors('refNumber');
                    }
                  }}
                  errorText={error ? error.message : null}
                />
              )}
            />

            <div className="sm:px-1.5 sm:py-2 sm:w-[19%]">
              <label className="form_label mb-1.5 block">
                Linear Footage
                {/* <span className="text-red-600 leading-4">*</span> */}
              </label>
              <Controller
                name={`linearFootage`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="linearFootage"
                    inputType="number"
                    placeholder="Enter Linear Footage"
                    value={`${value}`}
                    className=""
                    parentClassName=""
                    onChangeFunc={(e) => {
                      handleInputChange(e, 'change', onChange);

                      if (error?.message) {
                        clearErrors('linearFootage');
                      }
                    }}
                    shouldFormatNumber={true}
                    errorText={error ? error.message : null}
                    required
                  />
                )}
              />
            </div>

            <div className="sm:px-1.5 sm:py-2 sm:w-[19%]">
              <label className="form_label mb-1.5 block">Total Weight</label>
              <Controller
                name={`totalWeight`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    inputName="totalWeight"
                    inputType="number"
                    placeholder="Enter Total Weight"
                    value={`${value}`}
                    className=""
                    parentClassName=""
                    onChangeFunc={(e) => {
                      handleInputChange(e, 'change', onChange);

                      if (error?.message) {
                        clearErrors('totalWeight');
                      }
                    }}
                    shouldFormatNumber={true}
                    errorText={error ? error.message : null}
                  />
                )}
              />
            </div>

            <div className="sm:px-1.5 sm:py-2 sm:w-[22%] flex-1">
              <label className="form_label mb-1.5 block">
                Commercial Value
                {/* <span className="text-red-600 leading-4">*</span> */}
              </label>
              <div
                className={`form_control p-0 relative !h-auto ${
                  errors?.declaredValue?.message ? 'border-[#f04438]' : ''
                }`}
              >
                <Controller
                  name={`declaredValue`}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputText
                      inputName="Declared_Value"
                      inputType="number"
                      placeholder="Enter number"
                      value={`${value}`}
                      className={`!h-auto !pr-20 pl-8 border-0`}
                      icon={<CurrencyDollar className="input-currency-sign" />}
                      shouldFormatNumber={true}
                      onChangeFunc={(e) => {
                        handleInputChange(e, 'change', onChange);

                        if (isInsuranceToggleChecked) {
                          setOrder((prevOrder: any) => ({
                            ...prevOrder,
                            loadInsurance: {
                              ...prevOrder.loadInsurance,
                              commercialValue: e?.target?.value,
                            },
                          }));

                          setValue(
                            'loadInsurance.commercialValue',
                            e?.target?.value
                          );
                        }

                        if (error?.message) {
                          clearErrors('declaredValue');
                        }
                      }}
                    />
                  )}
                />
                <Controller
                  name={`declaredValueUnit`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectBox
                      name="declaredValueUnit"
                      labelClassName="form_label block mb-1.5"
                      parentClassName="absolute right-0 top-0 w-[80px] dropdown-within-input"
                      id="declaredValueUnit"
                      classComp={`rounded-none rounded-r-lg `}
                      size="sm"
                      options={currencyOptions}
                      onChangeFunc={(e: any) => {
                        onChange(e?.value);

                        if (isInsuranceToggleChecked) {
                          setOrder((prevOrder: any) => ({
                            ...prevOrder,
                            loadInsurance: {
                              ...prevOrder.loadInsurance,
                              declaredValueUnit: e?.target?.value,
                            },
                          }));

                          setValue('loadInsurance.declaredValueUnit', e?.value);
                        }
                      }}
                      value={currencyOptions?.find(
                        (e: any) => e?.value == value
                      )}
                    />
                  )}
                />
              </div>
              <div className="[&:empty]:mt-0 mt-1.5">
                {errors?.declaredValue && (
                  <ErrorMsg errorText={errors?.declaredValue?.message} />
                )}
              </div>
            </div>

            <Controller
              name={`specialNotes`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputText
                  inputName="Special_Notes"
                  label="Special Notes"
                  asTextarea="textarea"
                  placeholder="Enter notes"
                  value={value}
                  labelClassName="block mb-1.5"
                  className="h-[76px]"
                  parentClassName="sm:px-1.5 sm:py-2 w-full"
                  onChangeFunc={(e) => {
                    onChange(e);

                    if (error?.message) {
                      clearErrors('specialNotes');
                    }
                  }}
                  errorText={error ? error.message : null}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div>
        <Dimensions
          handlingUnitOptions={handlingUnitOptions}
          isHandlingUnitLoading={isHandlingUnitLoading}
          setWeightMeasurement={setWeightMeasurement}
          setLengthMeasurement={setLengthMeasurement}
          lengthMeasurement={lengthMeasurement}
          weightMeasurement={weightMeasurement}
        />
      </div>
      <div className="sm:-mx-1.5 sm:-my-1">
        <AdditionalServiceCmp
          setIsTailgate={setIsTailgate}
          currentLegData={currentLegData}
          setCurrentLegData={setCurrentLegData}
          setValue={setValue}
          title={'Add Other Additional Services'}
          serviceList={serviceList}
          showService={'other'}
          additionalServiceHeaderClass="!bg-gray25"
          additionalServiceClass="!border-utilityGray200 !shadow-xs"
          isAccordianOpen={true}
        />
      </div>

      <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-5">
        <div className="flex items-end justify-between ">
          <label
            htmlFor="load_insurance_toggle"
            className="text-textSecondary text-sm mr-2 mb-0 text-grayLight900 text-sm font-semibold cursor-pointer "
          >
            Would you like to insure this load?
          </label>
          <div className="flex items-center gap-2">
            <Radio
              id={`Yes`}
              onChangeFunc={(e: any) => {
                if (e?.target?.checked) {
                  setIsInsuranceToggleChecked(true);
                  setAction((old) => ({
                    ...old,
                    mode: 'load-insurance-request',
                  }));
                }
              }}
              inputName={'Yes'}
              radioLabelClassName={'!normal-case'}
              labelName={'Yes'}
              labelHtmlFor={`Yes`}
              value={'Yes'}
              checked={isInsuranceToggleChecked}
            />

            <Radio
              id={`No`}
              onChangeFunc={(e: any) => {
                if (e?.target?.checked) {
                  setOrder((old: any) => ({
                    ...old,
                    loadInsurance: null,
                    loadInsuranceId: null,
                  }));
                  setIsInsuranceToggleChecked(false);
                  setValue('loadInsurance.commodity', undefined);
                  setValue('loadInsurance.commercialValue', undefined);
                }
              }}
              inputName={'No'}
              radioLabelClassName={'!normal-case'}
              labelName={'No'}
              labelHtmlFor={`No`}
              value={'No'}
              checked={!isInsuranceToggleChecked}
            />
          </div>
        </div>

        {order?.loadInsurance && (
          <div className="rounded-[10px] border border-utilityGray200 shadow-sm p-4 bg-white flex gap-x-6 flex-wrap mt-5">
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Load Insurance ID
              </h6>
              <div className="text-textSecondary text-sm font-medium">
                {order?.loadInsurance?.load_insurance_id
                  ? `#${order?.loadInsurance?.load_insurance_id}`
                  : '-'}
              </div>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Status
              </h6>
              <BadgeCmp style="modern" type="success">
                {order?.loadInsurance?.status ?? 'Pending'}
              </BadgeCmp>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Commodity
              </h6>
              <div className="text-textSecondary text-sm font-medium">
                {order?.loadInsurance?.commodity ?? '-'}
              </div>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Commercial Value
              </h6>
              <div className="text-textSecondary text-sm font-medium">
                {order?.loadInsurance?.commercialValue
                  ? getFormattedNumber(
                      order?.loadInsurance?.commercialValue,
                      true,
                      true,
                      true
                    )
                  : '-'}
              </div>
            </div>
            <div className="flex-1">
              <h6 className="text-grayLight600 text-xs font-normal mb-1">
                Insurance Amount
              </h6>
              <div className="text-textSecondary text-sm font-medium">
                {order?.loadInsurance?.insurance_amount
                  ? getFormattedNumber(
                      order?.loadInsurance?.insurance_amount,
                      true,
                      true,
                      true
                    )
                  : '-'}
              </div>
            </div>
          </div>
        )}
      </div>

      {action.mode === 'load-insurance-request' ? (
        <LoadInsuranceModal
          handleClose={handleDrawerClose}
          loadInsurance={order?.loadInsurance}
          commercialValue={getValues('declaredValue')}
          declaredValueUnit={getValues('declaredValueUnit')}
          onSubmitLoadInsurance={(data: any) => {
            if (data) {
              setOrder((old: any) => ({
                ...old,
                loadInsurance: data,
              }));

              setValue('loadInsurance.commodity', data?.commodity);
              setValue('loadInsurance.commercialValue', data?.commercialValue);
              setValue(
                'loadInsurance.insurance_amount',
                data?.insurance_amount
              );
              setValue(
                'loadInsurance.declaredValueUnit',
                data?.declaredValueUnit
              );
              setValue('declaredValue', data?.commercialValue);
              setValue('declaredValueUnit', data?.declaredValueUnit);
              setAction(initAction);
            }
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AddOrderDetails;
