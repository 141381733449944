import moment from 'moment';
import React, { useContext, useState } from 'react';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import { CURRENCY, MEASUREMENT } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';

import {
  IFormData,
  initConsigneeAddress,
  initShipperAddress,
} from './AddressDetails/Address.interface';
import AddressDetailsForm from './AddressDetails/AddressDetailsForm';
import Dimensions from './Dimensions';

const CreateQuote = () => {
  const { appearance, serviceTypes, servicesList, isServiceLoading } =
    useContext(BasicContext);

  const initFormData: IFormData = {
    customerId: '',
    shipperId: null,
    shipperCompanyName: '',
    shipperAddress: initShipperAddress,
    pickupDate: appearance?.timezone
      ? moment().tz(appearance.timezone).format('YYYY-MM-DD HH:mm:ss')
      : moment().format('YYYY-MM-DD HH:mm:ss'),
    consigneeId: null,
    consigneeCompanyName: '',
    consigneeAddress: initConsigneeAddress,
    classification: 'ltl',
    linearFootage: '',
    totalWeight: '',
    primaryEquipmentType: '',
    insuranceCurrencyType: CURRENCY.CAD,
    insuranceCommercialValue: '',
    primaryEquipmentTypeFullName: null,
    secondaryEquipmentTypeFullNames: null,
    secondaryEquipmentTypes: null,
    dimensions: [
      {
        id: null,
        handlingUnit: '',
        handlingUnitNo: 0,
        weight: 0,
        itemLength: 0,
        width: 0,
        height: 0,
        totalWeight: 0,
        freightClass: 0,
        isCommodity: false,
        isStack: true,
        weightMeasurement: MEASUREMENT.WEIGHT1.value,
        commodityId: null,
        commodityName: null,
        sub: null,
        description: null,
      },
    ],
    services: {},
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [quoteFormData, setQuoteFormData] = useState(initFormData);

  return (
    <PageSectionLayout
      contentClassName="pb-[20px] !gap-y-5"
      header={
        <Header
          title="New Quote"
          desc="Create a new quote by filling out the shipment details below."
        />
      }
    >
      {currentStep === 1 && (
        <AddressDetailsForm
          setQuoteFormData={setQuoteFormData}
          quoteFormData={quoteFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          serviceList={servicesList}
          serviceTypes={serviceTypes}
          isServiceLoading={isServiceLoading}
        />
      )}
      {currentStep === 2 && (
        <Dimensions
          setQuoteFormData={setQuoteFormData}
          quoteFormData={quoteFormData}
          serviceList={servicesList}
          serviceTypes={serviceTypes}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )}
    </PageSectionLayout>
  );
};
export default CreateQuote;
