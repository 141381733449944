import { SearchLg, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useMemo } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';

import FreightStatisticsDetailModalLoadingRow from './FreightStatisticsDetailModalLoadingRow';
import FreightStatisticsDetailModalRow from './FreightStatisticsDetailModalRow';

interface IProps {
  handleClose: any;
}

const FreightStatisticsDetailModalData = [
  {
    id: 1,
    from: 'New York',
    to: 'Los Angeles',
    no_of_loads: 15,
    order_weight: -0.25,
    no_of_customers: 23,
    total_gross_profit: '$236.00 ',
    gp_weight: 50,
  },
  {
    id: 2,
    from: 'Chicago',
    to: 'Houston',
    no_of_loads: 10,
    order_weight: 0.25,
    no_of_customers: 3,
    total_gross_profit: '$9,000',
    gp_weight: 45,
  },
  {
    id: 3,
    from: 'San Francisco',
    to: 'Seattle',
    no_of_loads: 8,
    order_weight: 1.5,
    no_of_customers: 4,
    total_gross_profit: '$7,200',
    gp_weight: -1.5,
  },
];

const recordsPerPageArr = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const FreightStatisticsDetailModal = ({ handleClose }: IProps) => {
  const headCells = useMemo(
    () => [
      {
        id: 'from',
        name: 'From',
        sortable: true,
      },
      {
        id: 'to',
        name: 'To',
        sortable: true,
      },
      {
        id: 'no_of_loads',
        name: 'No. of loads',
        sortable: true,
      },
      {
        id: 'order_weight',
        name: 'Order Weight',
        sortable: true,
      },
      {
        id: 'no_of_customers',
        name: 'No. of customers',
        sortable: true,
      },
      {
        id: 'total_gross_profit',
        name: 'Total Gross Profit',
        sortable: true,
      },
      {
        id: 'gp_weight',
        name: 'GP Weight',
        sortable: true,
      },
    ],
    []
  );

  return (
    <CommonModal size="max-w-7xl" handleClose={() => {}}>
      <div className="p-5">
        <div className=" min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title">Regional Freight Statistics</h5>
                <BadgeCmp style="modern" type="success">
                  50 Customer
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Comprehensive Analysis of Regional Freight Sales Data and
                Performance Trends
              </p>
            </div>
            <div className="table-right-wrap ">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={''}
                inputType="text"
                isClearable={true}
                onChangeFunc={() => {}}
                parentClassName="table-searchInput"
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArr}
                  isSearchable={false}
                  onChangeFunc={() => {}}
                  value={''}
                />
              </div>
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                icon={<XClose className="w-4 h-4" />}
                iconSide="left"
                onClick={handleClose}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
          <div className="h-full w-full">
            <div className="overflow-x-auto custom-scrollbar max-h-[calc(100vh_-_265px)] custom-scrollbar-v2 custom-vertical-scrollbar-hide">
              <TableCmp
                headCells={headCells}
                tableDataArr={FreightStatisticsDetailModalData}
                TableRowCmp={FreightStatisticsDetailModalRow}
                TableLoaderRowCmp={FreightStatisticsDetailModalLoadingRow}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
              />
            </div>

            <div className="w-full">
              <CustomPagination
                recordsPerPage={1}
                totalRecords={100}
                currentPage={1}
                handlePagination={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default FreightStatisticsDetailModal;
