import { Edit01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import {
  formattedPickupDateValue,
  GetAppearance,
  getDateWithSuffixFormat,
  getLabelByValue,
  getShortName,
} from 'src/utils/CommonFunctions';

interface QuoteDetailTableRowProps {
  data: any;
  totalWeight: number;
  setAction: any;
  handleRateGathering: any;
  dimensions: { weightMeasurement: string }[];
  totalUnit: number;
  isDisplayDimensionsDetail: boolean;
  setIsDisplayDimensionsDetail: any;
  addressDetails: any;
  handleEditQuoteDetail: any;
  action: any;
  setQuoteDetailData: any;
  handleAction: any;
  setIsGetRate: any;
  sortActiveCarrierRates?: any;
  setIsShowQuoteDetailModal?: any;
  setActiveTab?: any;
}

const initAction = {
  isEdit: false,
  mode: '',
  data: {
    name: '',
    value: '',
  },
  fullAddress: {},
};

const QuoteDetailTableRow: React.FC<QuoteDetailTableRowProps> = ({
  data,
  totalWeight,
  dimensions,
  setAction,
  action,
  setActiveTab,
  setIsShowQuoteDetailModal,
}) => {
  const inputRef = useRef<any>(null);
  let appearance = GetAppearance();
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [secondaryEquipmentTypes, setSecondaryEquipmentTypes] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (data?.secondaryEquipmentTypeFullNames) {
      setSecondaryEquipmentTypes(
        data?.secondaryEquipmentTypeFullNames
          ?.split(',')
          .map((type: any) => type.trim())
      );
    } else {
      setSecondaryEquipmentTypes([]);
    }
  }, [data?.secondaryEquipmentTypeFullNames]);

  const handleAcion = (name: string, value: any) => {
    setAction((old: any) => ({
      ...old,
      ...{
        isEdit: true,
        mode: name,
        data: {
          name: name,
          value: value,
        },
      },
    }));
  };

  const handleClickOutside = (event: any) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      !event?.target?.classList.contains('react-datepicker__day') &&
      action.name !== 'shipperAddress' &&
      action.name !== 'consigneeAddress'
    ) {
      console.log('setAction123');
      setAction(initAction);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [action]);

  return (
    <>
      <td className="px-5 py-4 min-w-[200px] max-w-[1px] w-[200px]">
        <div className="flex items-center gap-3 truncate">
          {data?.customerImage ? (
            <div className="table-profile-wrap">
              <img
                className="table-profile-icn-circle-sm"
                src={data?.customerImage}
                alt=""
                title=""
              />
            </div>
          ) : (
            <div className="table-profile-customer-circle-sm">
              {getShortName(`${data?.customerName}`)}
            </div>
          )}
          <p className="truncate ">
            <TooltipCmp
              message={
                data?.customerName?.length > 20 ? data.customerName : null
              }
              parentClassName="max-w-90"
            >
              {data.customerName}
            </TooltipCmp>
          </p>
        </div>
      </td>
      <td className="px-5 py-4 w-[130px] min-w-[130px] max-w-[130px]">
        {data.classification === QUOTE_CLASSIFICATION.LTL && (
          <BadgeCmp style="modern" type="success">
            LTL
          </BadgeCmp>
        )}
        {data.classification === QUOTE_CLASSIFICATION.FTL && (
          <BadgeCmp style="modern" type="lightblue">
            FTL
          </BadgeCmp>
        )}
      </td>
      {/* {data.status === STATUS.WON && (
        <td className="px-5 py-4 min-w-[150px] max-w-[150px] w-[150px]">
          {data.e2eOrderId ? (
            <Link
              to={`${PATH.ORDER_VIEW}/${data.e2eOrderId}`}
              className="underline text-primary"
              target="_blank"
            >
              #{data?.e2eOrderWalId}
            </Link>
          ) : (
            <Link
              to={`${ROSE_ROCKET.QUOTE_URL}${data.rossRocketId}`}
              className="underline text-primary"
              target="_blank"
            >
              #{data.rossRocketPublicId}
            </Link>
          )}
        </td>
      )} */}

      <td className="px-5 py-3 min-w-[200px] max-w-[200px] w-[200px] group">
        <div className="flex gap-1">
          <span
            id="pickupDate"
            dangerouslySetInnerHTML={{
              __html: data.pickupDate
                ? getDateWithSuffixFormat(
                    formattedPickupDateValue(
                      moment
                        .utc(data.pickupDate)
                        .tz(appearance.timezone ?? browserTimezone)
                        .format('YYYY-MM-DD HH:mm:ss'),
                      false
                    )
                  )
                : '-',
            }}
          />
          <Edit01
            className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
            onClick={(e: any) => {
              e.stopPropagation();
              setActiveTab('address');
              setIsShowQuoteDetailModal(true);
              handleAcion('pickupDate', data?.pickupDate);
            }}
          />
        </div>
      </td>
      <td className="px-5 py-4 min-w-[180px] max-w-[1px] w-[16%] group">
        <div className="flex gap-x-2.5 truncate">
          <div className="truncate">
            <TooltipCmp
              message={data.shipperFullAddress}
              parentClassName="max-w-90"
            >
              <p className="truncate text-grayLight600 text-xs font-normal">
                {`${data?.shipperCity}, ${data?.shipperPostal}, ${data?.shipperCountryCode}`}
              </p>
            </TooltipCmp>
          </div>

          <Edit01
            className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0 flex-none"
            onClick={(e: any) => {
              e.stopPropagation();
              setActiveTab('address');
              setIsShowQuoteDetailModal(true);
            }}
          />
        </div>
      </td>
      <td className="px-5 py-4 min-w-[180px] max-w-[1px] w-[16%] group">
        <div className="flex gap-x-2.5 truncate">
          <div className="truncate">
            <TooltipCmp
              message={data.consigneeFullAddress}
              parentClassName="max-w-90"
            >
              <p className="truncate text-grayLight600 text-xs font-normal">
                {`${data?.consigneeCity}, ${data?.consigneePostal}, ${data?.consigneeCountryCode}`}
              </p>
            </TooltipCmp>
          </div>
          <Edit01
            className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0 flex-none"
            onClick={(e: any) => {
              e.stopPropagation();
              setActiveTab('address');
              setIsShowQuoteDetailModal(true);
            }}
          />
        </div>
      </td>

      <td className="px-5 py-4 min-w-[155px] max-w-[155px] w-[155px] group">
        <div className="flex gap-1">
          {totalWeight > 0 ? totalWeight : 0}{' '}
          {dimensions.length > 0
            ? getLabelByValue(dimensions[0].weightMeasurement)?.toLowerCase()
            : 'lbs'}
          <Edit01
            className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
            onClick={(e: any) => {
              e.stopPropagation();
              setIsShowQuoteDetailModal(true);
              setActiveTab('quote_detail');
            }}
          />
        </div>
      </td>
      <td className="px-5 py-4 min-w-[160px] max-w-[160px] w-[160px] group">
        <div className="flex gap-1">
          {`${
            data?.linearFootage > 0
              ? `${data?.linearFootage}Ft.`
              : data?.classification === QUOTE_CLASSIFICATION.FTL
              ? '53 ft.'
              : '-'
          }`}
          {data?.classification !== QUOTE_CLASSIFICATION.FTL ? (
            <Edit01
              className="w-3.5 h-4 cursor-pointer group-hover:opacity-100 opacity-0"
              onClick={(e: any) => {
                e.stopPropagation();
                handleAcion('linearFootage', data?.linearFootage);
                setIsShowQuoteDetailModal(true);
                setActiveTab('quote_detail');
              }}
            />
          ) : (
            ''
          )}
        </div>
      </td>
      <td className="px-5 py-3 min-w-[230px] max-w-[230px] w-[230px] group">
        <div className="flex items-center gap-1">
          <div className="truncate flex items-center gap-1">
            {data?.primaryEquipmentTypeFullName ? (
              <BadgeCmp style="modern" type="primary">
                {data?.primaryEquipmentTypeFullName}
              </BadgeCmp>
            ) : null}

            {secondaryEquipmentTypes.length > 0 && (
              <BadgeCmp
                style="modern"
                type="gray"
                mainClassName="truncate"
                badgeTextColor="truncate"
                badgeTextClassName="truncate"
                badgeTextEllipse={true}
              >
                {secondaryEquipmentTypes[0]}
              </BadgeCmp>
            )}

            {secondaryEquipmentTypes.length > 1 && (
              <ActionTooltip
                openOnHover
                parentClassName="min-w-fit max-w-max w-52"
                tooltipPosition={
                  secondaryEquipmentTypes.length <= 2 ? 'center' : 'right'
                }
                isOpenChildTopPosOnTop
                message={secondaryEquipmentTypes.slice(1).join(', ')}
              >
                <BadgeCmp
                  style="modern"
                  type="gray"
                  mainClassName=""
                  isHidePillDot
                >
                  +{secondaryEquipmentTypes.length - 1}
                </BadgeCmp>
              </ActionTooltip>
            )}
          </div>

          <Edit01
            className="w-3.5 h-3.5 mt-1 cursor-pointer group-hover:opacity-100 opacity-0 flex-none"
            onClick={(e: any) => {
              e.stopPropagation();
              handleAcion('primaryEquipmentType', data?.primaryEquipmentType);
              setActiveTab('quote_detail');
              setIsShowQuoteDetailModal(true);
            }}
          />
        </div>
      </td>
    </>
  );
};

export default QuoteDetailTableRow;
