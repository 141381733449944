import {
  Check,
  ChevronDown,
  SearchLg,
  SwitchVertical01,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import DateRangePicker from 'src/components/DateRangePicker';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import OutsideClickHandler from 'src/components/OutsideClickHandler';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import {
  currentDateToDifference,
  formatAddress,
  formatLastUpdate,
  getDateRange,
  getDateWithSuffixFormat,
  getFormattedDate,
  getFormattedDateWithTime,
  getShortName,
  isValidJSON,
  lastLocationUpdateToShowDotColor,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import { TimeRanges } from '../SalesDashboard/OverView/OverView.interface';

import { NoDataFound } from './constant';

const filterArr = [
  {
    value: 'booked',
    name: 'Booked',
    // count: 0,
  },
  {
    value: 'dispatched',
    name: 'Dispatched',
    // count: 0,
  },
];

const tabArr = [
  {
    value: 'pickups',
    name: 'Pickups',
    count: 0,
  },
  {
    value: 'in-transit',
    name: 'In Transit',
    count: 0,
  },
  {
    value: 'delivered',
    name: 'Deliveries',
    count: 0,
  },
];

interface IProps {
  orders?: any;
  setParams?: any;
  isOrdersLoading?: any;
  params?: any;
  tabCount: any;
  sortToMapReload: any;
}

const LeftSidebar = ({
  orders,
  setParams,
  isOrdersLoading,
  params,
  tabCount,
  sortToMapReload,
}: IProps) => {
  const navigate = useNavigate();
  const storedParamsString = localStorage.getItem(TABLE_IDS.RADAR_LIST);
  const storedParams = isValidJSON(storedParamsString)
    ? JSON.parse(storedParamsString!)
    : undefined;
  const { hasRoleV2 } = useRolePermission();
  const [activeTab, setActiveTab] = useState(
    storedParams?.filter
      ? storedParams?.filter === 'dispatched' ||
        storedParams?.filter === 'booked'
        ? 'pickups'
        : storedParams?.filter
      : 'pickups'
  );
  const [search, setSearch] = useState(() => storedParams?.search ?? '');
  const { appearance, allTimeStartDateActual } = useContext(BasicContext);

  const [isDisplaySortOption, setIsDisplaySortOption] = useState(false);
  const [activeFilter, setActiveFilter] = useState(() =>
    storedParams?.activeFilter ? storedParams?.activeFilter : 'daily'
  );
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>(
    () => storedParams?.startDate ?? ''
  );
  const [endDate, setEndDate] = useState<any>(
    () => storedParams?.endDate ?? ''
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(() =>
    storedParams?.activeFilter ? storedParams?.activeFilter : 'custom'
  );
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>(
    storedParams?.startDate ?? ''
  );
  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old: any) => ({ ...old, ...{ search: debSearch } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      sortToMapReload.current = true;
      setSearch(value);
      searchDebounce(value);
    }
  };

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateActual);

    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateActual);
    }
  }, [allTimeStartDateActual]);

  useEffect(() => {
    if (activeFilter !== 'custom') {
      const { start, end } = getDateRange(activeFilter, allTimeStartDate);

      setStartDate(start);
      setEndDate(end);
      setIsPrevYearDisable(false);
    } else {
      if (startDate && endDate) {
        setStartDate(moment(startDate).local().toDate());
        setEndDate(moment(endDate).local().toDate());
        setIsPrevYearDisable(false);
      }
    }

    if (activeFilter === 'daily' || activeFilter === 'custom') {
      setSelectedTimeRange('custom');
    } else {
      setSelectedTimeRange(activeFilter);
    }
    params.activeFilter = activeFilter;
    localStorage.setItem(TABLE_IDS.RADAR_LIST, JSON.stringify(params));
  }, [activeFilter]);

  useEffect(() => {
    if (tabCount) {
      tabArr[0].count = tabCount?.pickupOrderCount;
      tabArr[1].count = tabCount?.inTransitOrderCount;
      tabArr[2].count = tabCount?.deliveredOrderCount;
      // filterArr[0].count = tabCount?.pickupBookedOrderCount;
      // filterArr[1].count = tabCount?.pickupDispatchedOrderCount;
    }
  }, [tabCount]);

  useEffect(() => {
    if (startDate && endDate) {
      setParams((old: any) => ({
        ...old,
        startDate: `${moment(startDate).local().format('YYYY-MM-DD')}`,
        endDate: `${moment(endDate).local().format('YYYY-MM-DD')}`,
      }));
    }
  }, [startDate, endDate]);

  // const getAddressWholeString = (
  //   first: string,
  //   second: string,
  //   third: string
  // ) =>
  //   [first, second, third].filter((e) => e).length
  //     ? [first, second, third].filter((e) => e).toString()
  //     : false;

  const getFilterRange = (type?: string) => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      if (activeFilter === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (activeFilter === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(activeFilter);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    sortToMapReload.current = true;
    const [start, end] = date;

    if (timeRange !== 'custom') {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('custom');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const makeCustomTitleDesc = () => {
    let isCustomTitle;
    let isCustomDesc;
    const isCurrentTimeRange = (
      activeFilter === 'custom' || activeFilter === 'daily'
        ? moment(params?.startDate).format('DD-MM-YYYY') ===
          moment(params?.endDate).format('DD-MM-YYYY')
          ? getFormattedDate(params?.startDate)
          : `${moment(params?.startDate).local().format('Do MMM')} to ${moment(
              params?.endDate || params?.startDate
            )
              .local()
              .format('Do MMM')}`
        : TimeRanges.find((val) => val.value === activeFilter)?.value ?? 'Today'
    )?.trim();

    if (params?.filter === 'in-transit') {
      isCustomTitle = 'No shipments in transit';
      isCustomDesc = 'No shipments are currently in transit.';
    } else {
      if (
        activeFilter === 'custom' &&
        isCurrentTimeRange !== 'Today' &&
        isCurrentTimeRange !== 'Yesterday' &&
        isCurrentTimeRange !== 'Tomorrow'
      ) {
        isCustomTitle = `No ${
          ['in-transit', 'delivered'].includes(params?.filter)
            ? 'shipments'
            : 'orders'
        } ${params?.filter} from ${isCurrentTimeRange}`;
      } else {
        isCustomTitle = NoDataFound(params?.filter, isCurrentTimeRange);
      }
      isCustomDesc = `No ${
        ['in-transit', 'delivered'].includes(params?.filter)
          ? 'shipments'
          : 'orders'
      } were ${params?.filter} during this period.`;
    }

    return { title: isCustomTitle, desc: isCustomDesc };
  };

  const getDotColor = (value: any) => {
    const isDotColor =
      params?.filter === 'booked' || params?.filter === 'dispatched'
        ? 'primary'
        : params?.filter === 'delivered'
        ? 'green'
        : (lastLocationUpdateToShowDotColor(
            value?.orderLocationUpdate
              ? value?.orderLocationUpdate?.updatedAt
              : value?.createdAt
          ) as 'primary' | 'red' | 'yellow' | 'green');

    return isDotColor;
  };

  const getDotLabel = (value: any) => {
    const isDotLabel = (
      <>
        <span>
          {params?.filter === 'booked' || params?.filter === 'dispatched'
            ? 'Picking up on'
            : params?.filter === 'delivered'
            ? 'Delivered'
            : 'Last Updated:'}
        </span>

        {params.filter === 'booked' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.firstLegExpectedPickupTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegShipperAppointmentDatetime
                        ? value.activeLegShipperAppointmentDatetime
                        : value?.firstLegExpectedPickupTime,
                      value?.activeLegShipperAppointmentDatetime
                        ? `MMMM Do [at] hh:mm A`
                        : 'MMMM Do',
                      value?.activeLegShipperAppointmentDatetime ? true : false,
                      true
                    )
                  )
                : '-',
            }}
          />
        )}
        {params.filter === 'dispatched' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.activeLegActualPickupTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegActualPickupTime,
                      `MMMM Do [at] hh:mm A`,
                      true,
                      true
                    )
                  )
                : '-',
            }}
          />
        )}

        {params.filter === 'in-transit' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.orderLocationUpdate
                ? currentDateToDifference(
                    value?.lastLegExpectedEstimatedDeliveryTime,
                    'hours',
                    false
                  ) < 0
                  ? formatLastUpdate(value?.orderLocationUpdate?.updatedAt)
                  : currentDateToDifference(
                      value?.lastLegExpectedEstimatedDeliveryTime,
                      'hours',
                      false
                    ) < 12
                  ? getDateWithSuffixFormat(
                      getFormattedDateWithTime(
                        value?.lastLegExpectedEstimatedDeliveryTime,
                        `MMMM Do [at] hh:mm A`,
                        false,
                        true
                      )
                    )
                  : formatLastUpdate(value?.orderLocationUpdate?.updatedAt)
                : value?.activeLegActualPickupTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegActualPickupTime,
                      `MMMM Do [at] hh:mm A`,
                      true,
                      true
                    )
                  )
                : '-',
            }}
          />
        )}
        {params.filter === 'delivered' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.activeLegActualEstimatedDeliveryTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegActualEstimatedDeliveryTime,
                      `MMMM Do [at] hh:mm A`,
                      true,
                      true
                    )
                  )
                : '-',
            }}
          />
        )}
      </>
    );

    return isDotLabel;
  };

  return (
    <>
      <div className="flex gap-x-2.5">
        <InputText
          inputName="searchQuote"
          placeholder="Search"
          className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
          icon={
            <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
          }
          value={search}
          inputType="text"
          onChangeFunc={handleFilter}
          parentClassName="flex-1"
          isClearable={true}
        />
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsDisplaySortOption(false);
          }}
          containerClassName="relative"
        >
          <ButtonCmp
            className={`btn-outline-primary lg:!px-[9px] !px-2`}
            icon={<SwitchVertical01 className="w-4 h-4" />}
            onClick={() => {
              setIsDisplaySortOption(!isDisplaySortOption);
            }}
          >
            <></>
          </ButtonCmp>

          <ul
            className={`rounded-[10px] border border-utilityGray200 shadow-md bg-white py-2 px-1.5 text-xs font-medium my-1.5 z-[1]  w-[200px] right-0 absolute ${
              isDisplaySortOption ? '' : 'hidden'
            } `}
          >
            <li className="px-2 text-gray400 my-1.5">Sort by</li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex justify-between gap-x-2 hover:text-primary ${
                params.sortField === 'updatedAt' ? 'bg-gray50' : ''
              } `}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'updatedAt') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'updatedAt',
                  }));
                }
              }}
            >
              <span>Last Update Time</span>
              {params.sortField === 'updatedAt' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary ${
                params.sortField === 'expectedPickupTime' ? 'bg-gray50' : ''
              }`}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'expectedPickupTime') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'expectedPickupTime',
                  }));
                }
              }}
            >
              <span>Pickup Date</span>
              {params.sortField === 'expectedPickupTime' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary ${
                params.sortField === 'customerName' ? 'bg-gray50' : ''
              }`}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'customerName') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'customerName',
                  }));
                }
              }}
            >
              <span>Customer</span>
              {params.sortField === 'customerName' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
            <li
              className={`text-textSecondary p-2 rounded-md hover:bg-gray50 cursor-pointer flex gap-x-2 hover:text-primary ${
                params.sortField === 'expectedEstimatedDeliveryTime'
                  ? 'bg-gray50'
                  : ''
              }`}
              onClick={() => {
                setIsDisplaySortOption(false);
                sortToMapReload.current = false;

                if (params.sortField === 'expectedEstimatedDeliveryTime') {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'createdAt',
                  }));
                } else {
                  setParams((old: any) => ({
                    ...old,
                    sortField: 'expectedEstimatedDeliveryTime',
                  }));
                }
              }}
            >
              <span>Estimated Time</span>
              {params.sortField === 'expectedEstimatedDeliveryTime' && (
                <Check className="w-5 h-5 text-primary" />
              )}
            </li>
          </ul>
        </OutsideClickHandler>
      </div>

      <TabButton
        parentClassName="tab-border-bottom-parent w-full"
        activeClassName="tab-border-bottom-active"
        className="tab-border-bottom flex-1"
        tabBadgeClassName="tab-border-bottom-badge"
        tabArray={tabArr}
        handleOnClick={(e: any) => {
          sortToMapReload.current = true;
          setActiveTab(e?.target?.dataset?.value);

          if (e?.target?.dataset?.value != 'pickups') {
            setParams((old: any) => ({
              ...old,
              ...{ filter: e?.target?.dataset?.value },
            }));
          } else {
            setParams((old: any) => ({
              ...old,
              ...{ filter: 'booked' },
            }));
          }
        }}
        isActive={activeTab}
      />

      <div className="flex justify-between gap-x-2">
        {activeTab == 'pickups' ? (
          <TabButton
            tabArray={filterArr}
            handleOnClick={(e: any) => {
              sortToMapReload.current = true;
              setParams((old: any) => ({
                ...old,
                ...{ filter: e?.target?.dataset?.value },
              }));
            }}
            isActive={params.filter}
            isTab={true}
            isTabBadgeStylePill
          />
        ) : (
          <div></div>
        )}
        {params?.filter !== 'in-transit' && (
          <DateRangePicker
            parentClassName="sales-datepicker max-w-[316px]"
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={allTimeStartDate}
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
            isPrevDisabled={isPrevYearDisable}
            onPrevNextClick={(type: string) => getFilterRange(type)}
            arrowLeftClassname={`!w-[18px] !h-[18px]`}
            arrowRightClassname={`!w-[18px] !h-[18px]`}
          />
        )}
      </div>
      <div
        className={`space-y-3 flex-1  mb-4 flex flex-col ${
          !isOrdersLoading && !orders?.length
            ? ''
            : 'overflow-y-auto scrollbar-hide'
        }`}
      >
        {!isOrdersLoading &&
          orders?.length > 0 &&
          orders?.map((value: any, index: any) => (
            <div
              key={index}
              className="rounded-lg border border-utilityGray200 bg-white p-3 cursor-pointer shadow-none hover:shadow-lgc"
              onClick={() => {
                if (value?.orderTableId) {
                  navigate(`${PATH.RADAR}/${value?.orderTableId}`);
                }
              }}
            >
              <div className="flex gap-x-2 justify-between items-start">
                {/* <a
                  href={`https://wal.roserocket.com/#/ops/orders/${value?.orderId}`}
                  className={`text-primary text-sm font-medium underline flex-none ${
                    isOrdersLoading ? 'custom-loading' : ''
                  }`}
                  target="_blank"
                  onClick={(e) => e.stopPropagation()}
                >
                  #{value?.orderId}
                </a> */}
                <p
                  className="text-primary700 text-sm font-medium leading-tight truncate underline cursor-pointer"
                  onClick={(e: any) => {
                    if (value?.orderTableId) {
                      e.stopPropagation();
                      navigate(`${PATH.ORDER_VIEW}/${value?.orderTableId}`);
                    }
                  }}
                >
                  {value?.orderId ? `#${value?.orderId}` : ''}
                </p>
                <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                  {/* {value?.orderTags !== '' &&
                    value?.orderTags &&
                    value?.orderTags?.split(',')?.map((tag: any) => (
                      <BadgeCmp
                        key={tag}
                        style="modern"
                        type="success"
                        mainClassName={`${
                          isOrdersLoading ? 'custom-loading' : ''
                        }`}
                      >
                        {tag}
                      </BadgeCmp>
                    ))} */}
                  {value?.orderType && (
                    <BadgeCmp style="modern" type="success">
                      {value?.orderType?.toUpperCase()}
                    </BadgeCmp>
                  )}
                  {value?.orderStatus && (
                    <BadgeCmp style="modern" type="primary">
                      {value?.orderStatus}
                    </BadgeCmp>
                  )}
                </div>
              </div>
              <div className="flex justify-between items-center mt-1.5">
                <StatusDotProfileCmp
                  dotColor={getDotColor(value)}
                  label={getDotLabel(value)}
                />
                <div className="flex items-center gap-x-2">
                  <div
                    className={`w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]`}
                  >
                    {getShortName(
                      value?.activeLegCarrierName
                        ? value?.activeLegCarrierName
                        : "Unassigned Carrier'"
                    )}
                  </div>

                  <p
                    className={`text-xs font-normal text-grayLight900 truncate`}
                  >
                    {value?.activeLegCarrierName
                      ? value?.activeLegCarrierName
                      : 'Unassigned Carrier'}
                  </p>
                </div>
              </div>

              <div className="mt-3.5 pt-3.5 border-t border-utilityGray200">
                <ul className="p-2.5 rounded-lg bg-gray50">
                  <li
                    key="0"
                    className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden "
                  >
                    <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
                      <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal">
                      <div className="flex justify-between items-center">
                        <div className="text-grayLight900 font-medium">
                          {value?.shipperCompanyName ?? ''}
                        </div>
                        {value?.firstLegExpectedPickupTime && (
                          <BadgeCmp style="modern" type="success">
                            <p
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: value?.firstLegExpectedPickupTime
                                  ? `Pickup: ${getDateWithSuffixFormat(
                                      getFormattedDate(
                                        value?.firstLegExpectedPickupTime,
                                        `Do MMM YYYY`,
                                        false,
                                        false
                                      )
                                    )}`
                                  : '-',
                              }}
                            />
                          </BadgeCmp>
                        )}
                      </div>
                      <div>
                        {value?.shipperFullAddress
                          ? formatAddress(value?.shipperFullAddress)
                          : ''}
                      </div>
                    </div>
                  </li>
                  <li
                    key="1"
                    className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden"
                  >
                    <div>
                      <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
                      <div className="flex justify-between items-center">
                        <div className="text-grayLight900 font-medium">
                          {value?.consigneeCompanyName ?? ''}
                        </div>
                        {value?.lastLegExpectedEstimatedDeliveryTime && (
                          <BadgeCmp style="modern" type="success">
                            <p
                              className=""
                              dangerouslySetInnerHTML={{
                                __html:
                                  value?.lastLegExpectedEstimatedDeliveryTime
                                    ? `Delivery: ${getDateWithSuffixFormat(
                                        getFormattedDate(
                                          value?.lastLegExpectedEstimatedDeliveryTime,
                                          `Do MMM YYYY`,
                                          false,
                                          false
                                        )
                                      )}`
                                    : '-',
                              }}
                            />
                          </BadgeCmp>
                        )}
                      </div>
                      <div>
                        {value?.consigneeFullAddress
                          ? formatAddress(value?.consigneeFullAddress)
                          : ''}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          ))}

        {isOrdersLoading && !orders?.length
          ? [...Array(5)].map((_, index) => (
              <div
                key={index}
                className="rounded-lg border border-utilityGray200 bg-white p-3"
              >
                <div className="flex gap-x-2 justify-between items-start">
                  <a
                    className={`text-primary text-sm font-medium underline flex-none ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    #WAL-TCL4-16
                  </a>
                  <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                    <BadgeCmp
                      style="modern"
                      type="success"
                      mainClassName={`${
                        isOrdersLoading ? 'custom-loading' : ''
                      }`}
                    >
                      LTL
                    </BadgeCmp>

                    <BadgeCmp
                      style="modern"
                      type="primary"
                      mainClassName={`${
                        isOrdersLoading ? 'custom-loading' : ''
                      }`}
                    >
                      Booked
                    </BadgeCmp>
                  </div>
                </div>

                <div
                  className={`flex gap-1.5 items-center mt-1.5 ${
                    isOrdersLoading ? 'custom-loading' : ''
                  }`}
                >
                  <div className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100" />
                  <p className="text-xs text-grayLight900">
                    Last Updated: &nbsp;
                    <span>28/11/2024</span>
                  </p>
                </div>

                <ul className="mt-3.5 pt-3.5 border-t border-utilityGray200">
                  <li
                    className={`relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-gray500">
                      <div className="w-2.5 h-2.5 border-2 border-gray500 rounded-full"></div>
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal">
                      <span className="text-grayLight900 font-medium">
                        Grandview Crystal Screen Canada&nbsp;
                      </span>
                      Pointe Claire,QC,H9R 1A3
                    </div>
                  </li>
                  <li
                    key={index}
                    className={`relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden ${
                      isOrdersLoading ? 'custom-loading' : ''
                    }`}
                  >
                    <div>
                      <ChevronDown className="w-[18px] text-gray500 -mt-[13px] -ml-1" />
                    </div>
                    <div className="flex-1 text-xs text-grayLight600 font-normal -mt-2.5 -ml-1">
                      <span className="text-grayLight900 font-medium">
                        Techni-Contact Canada Ltd&nbsp;
                      </span>
                      Pointe Claire,QC,H9R 1A3
                    </div>
                  </li>
                </ul>
              </div>
            ))
          : ''}
        {!isOrdersLoading && !orders?.length && (
          <NotFoundUI
            title={makeCustomTitleDesc()?.title}
            desc={makeCustomTitleDesc()?.desc}
            containerClassName="!h-auto flex-1"
            titleClassName={''}
          />
        )}
      </div>
      {/* <div className="p-3 bg-white rounded-lg border border-[#eaecf0] flex-col justify-center items-start gap-3.5 inline-flex">
          <div className="h-11 flex-col gap-1 flex border-borderPrimary ">
            <div className="w-[427px] justify-start items-start gap-2.5 inline-flex">
              <div className="w-[208.50px] text-[#444ce6] text-sm font-medium font-['Inter'] underline leading-tight">
                #WAL-KP13-13
              </div>
              <div className="grow shrink basis-0 h-[22px] justify-end items-start gap-2.5 flex">
                <div className="px-1.5 py-0.5 bg-white rounded-md shadow border border-[#d0d5dd] justify-start items-center gap-1 flex">
                  <div className="w-2 h-2 relative">
                    <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-[#17b169] rounded-full" />
                  </div>
                  <div className="text-center text-[#344054] text-xs font-medium font-['Inter'] leading-[18px]">
                    LTL
                  </div>
                </div>
                <div className="px-1.5 py-0.5 bg-white rounded shadow border border-[#d0d5dd] justify-start items-center gap-1 flex">
                  <div className="w-2 h-2 relative">
                    <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-[#6071f3] rounded-full" />
                  </div>
                  <div className="text-center text-[#344054] text-xs font-medium font-['Inter'] leading-[18px]">
                    Booked
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-col gap-1.5 flex">
              <div className="rounded-full justify-start items-center gap-1.5 flex">
                <div className="w-3 h-3 relative">
                  <div className="w-3 h-3 left-0 top-0 absolute bg-[#dfeaff] rounded-full" />
                  <div className="w-1.5 h-1.5 left-[3px] top-[3px] absolute bg-[#444ce6] rounded-full" />
                </div>
                <div className="text-center text-[#101828] text-xs font-medium font-['Inter'] leading-[18px]">
                  Pickup Appointment: Today, 07:00 PM
                </div>
              </div>
            </div>
          </div>
          <div className="p-2.5 bg-gray-50 rounded-lg justify-start items-center gap-2.5 flex ">
            <div className="w-3 h-[30px] justify-center mr-2">
              <img
                src={addressline}
                className="table-profile-icn-circle-xl rounded-md"
                alt="address"
                title="address"
                onError={onError}
              />
            </div>
            <div className="flex-col gap-1 flex">
              <div className="gap-1 flex">
                <div className="text-grayLight600 text-xs font-normal">
                  Koray Okumus
                </div>
                <div className="text-grayText text-xs font-normal">
                  Montreal Eaton Centre, West, Montreal, QC, Canada
                </div>
              </div>
              <div className="gap-1 flex">
                <div className="text-grayLight600 text-xs font-normal ">
                  Olivia Rhye
                </div>
                <div className="text-grayText text-xs font-normal">
                  8001 S Orange Blossom Trl, Orlando, FL 32809, USA
                </div>
              </div>
            </div>
          </div>
        </div> */}
    </>
  );
};

export default LeftSidebar;
