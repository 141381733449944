import moment from 'moment';
import React from 'react';
import CheckBox from 'src/components/CheckBox';

import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

const WithQuoteLoading = () => (
  <>
    <td className="px-5 py-4 min-w-[230px] w-[230px] max-w-[230px]">
      <div className=" flex items-center ">
        <div className="table-profile-customer-circle-xl rounded-md custom-loading ">
          <img
            className="w-full h-full"
            src={FreightcomIcon}
            alt="IMAGE"
            title="IMAGE"
          />
        </div>
        <div className="pl-3 max-w-48">
          <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none custom-loading">
            *****************
          </h6>
          <p className="max-w-28 truncate inline-block align-middle custom-loading">
            #11111
          </p>
        </div>
      </div>
    </td>

    <td className="px-5 py-4 w-[10.8%] min-w-[10.8%] max-w-[10.8%]">
      <span className="custom-loading"> {`Est. 5 days`} </span>
    </td>

    <td className={`px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%] `}>
      <span className="custom-loading">Est. 1 days</span>
    </td>

    <td className="px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%]">
      <span className="custom-loading">
        {moment().format('MMM. Do - YYYY')}{' '}
      </span>
    </td>
    <td className="px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%]">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4 w-[172px] min-w-[172px] max-w-[172px]">
      <span className="custom-loading">100000</span>
    </td>
    <td className="px-5 py-4 w-[180px] min-w-[180px] max-w-[180px]">
      {' '}
      <span className="text-textSecondary font-bold custom-loading">
        {' '}
        {'2000000000'}
      </span>
    </td>

    <td className="px-5 py-4 w-[84px] max-w-[84px] min-w-[84px]">
      <div className="flex justify-end gap-3">
        <span className="">
          <CheckBox
            className="w-4 h-4 custom-loading"
            onChangeFunc={() => {}}
          />
        </span>
      </div>
    </td>
  </>
);

export default WithQuoteLoading;
