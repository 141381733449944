import { yupResolver } from '@hookform/resolvers/yup';
import { MessageCircle02, Phone } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import avatarGroup from 'src/assets/img/Avatars.webp';
import defaultImage from 'src/assets/img/default-image.jpg';
import integrationImage from 'src/assets/img/integration.webp';
import profile1 from 'src/assets/img/tick-icon.svg';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import Toggle from 'src/components/Toggle/Toggle';
import { ITabOption } from 'src/interface/common';
import {
  enableIntegration,
  setConfiguration,
} from 'src/services/IntegrationService';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import InputText from '../../components/InputText/InputText';
import TabButton from '../../components/TabButton';

import {
  IConfiguration,
  IConfigurationFormData,
  IConfigurationModalProps,
} from './Integration.interface';

export const tabOption: ITabOption[] = [
  {
    value: 'about',
    name: 'About',
  },
  {
    value: 'instructions',
    name: 'Instructions',
  },
  {
    value: 'configurations',
    name: 'Configuration',
  },
  {
    value: 'IntegrationScope',
    name: 'Integration Scope',
  },
];

const ViewIntegrationModal = ({
  handleClose,
  integration,
}: IConfigurationModalProps) => {
  const [activeTab, setActiveTab] = useState<string>('configurations');
  const [formData, setFormData] = useState<IConfigurationFormData>({
    configurations: [],
  });
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [isLoadingConnect, setIsLoadingConnect] = useState<boolean>(false);

  useEffect(() => {
    const updatedConfigurations = integration?.configurations.map((config) => ({
      id: config.id,
      value: config.value,
    }));

    setFormData({ configurations: updatedConfigurations || [] });
  }, [integration]);

  const schema: Record<string, any> = {};

  integration?.configurations?.forEach((configuration) => {
    if (configuration.slug === 'email') {
      schema[configuration.slug] = yup
        .string()
        .email(`${configuration.name} must be a valid email`)
        .required(`${configuration.name} is required`);
    } else if (configuration.slug === 'password') {
      schema[configuration.slug] = yup
        .string()
        .required(`${configuration.name} is required`);
    } else if (configuration.slug === 'apiUrl') {
      schema[configuration.slug] = yup
        .string()
        .matches(
          /^(https?:\/\/)?([a-zA-Z0-9.-]+)(:[0-9]{1,5})?(\/[^\s]*)?$/,
          `${configuration.name} must be a valid URL`
        )
        .required(`${configuration.name} is required`);
    } else {
      schema[configuration.slug] = yup
        .string()
        .required(`${configuration.name} is required`);
    }
  });
  const validationSchema = yup.object().shape(schema);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Function to handle input change
  const handleInputChange = (id: number, newValue: string) => {
    setFormData((prevFormData) => ({
      configurations: prevFormData.configurations.map((config) =>
        config.id === id ? { ...config, value: newValue } : config
      ),
    }));
  };

  const handleChange = (e: any, id: number) => {
    handleInputChange(id, e?.target?.value || '');
  };

  const getValueById = (id: number) => {
    const configuration = formData.configurations.find(
      (config) => config.id === id
    );

    return configuration ? configuration.value : '';
  };

  const onSubmit = () => {
    if (integration) {
      setIsLoadingSave(true);
      setConfiguration(integration.id, formData)
        .then((response: any) => {
          WalToast.success(response.message);
          setIsLoadingSave(false);
          handleClose(true);
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingSave(false);
        });
    }
  };

  const handleConnect = () => {
    if (integration) {
      setIsLoadingConnect(true);
      enableIntegration(integration.id, { status: !integration.isEnable })
        .then((response: any) => {
          WalToast.success(response.message);
          setIsLoadingConnect(false);
          handleClose(true);
        })
        .catch((e) => {
          console.log(e);
          setIsLoadingConnect(false);
        });
    }
  };

  return (
    <CommonModal
      title={
        <div className="mr-3 modal-hover-text">
          <span className="inline mr-2">{integration?.name}</span>
          {integration?.isEnable && (
            <div className="hover-text text-success700 items-center bg-[#eefcf6] w-6 h-6 inline-flex  rounded-full text-xs font-semibold transition-all duration-[0.3s]">
              <img src={profile1} className="mx-1" />
              <span className="opacity-0 w-0 font-semibold transition-all duration-[0.5s] text-[#0d442e] text-xs leading-[20px] whitespace-nowrap">
                Connected
              </span>
            </div>
          )}
        </div>
      }
      titleDesc={integration?.shortDescription}
      handleClose={() => handleClose(false)}
      headerIcon={
        <>
          <img
            src={
              integration?.logo
                ? integration.logoUrl + integration.logo
                : defaultImage
            }
            className="object-contain h-full w-full"
            alt=""
          />
        </>
      }
      headerIconClass="!bg-[#e7e7e7] !p-0 sm:!h-16 sm:!w-16 !h-11 !w-11 !rounded-full border-0 overflow-hidden"
      // headerIconClass="md:!h-[48px] !h-8 md:!w-[48px] !w-8 !p-0 !inline-flex mr-2 before:content-[''] before:h-[55px] before:w-[35px] before:border-utilityGray200 before:absolute before:bg-[url('../../assets/img/vector-dotframe.svg')]  before:bg-no-repeat before:bg-center before:bg-white before:top-0 before:-left-[69px] before:opacity-50"
      size={'max-w-[976px] md:min-h-[auto] min-h-[90vh] max-h-[668px]'}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderClass="md:!px-8 !p-5 !border-0 hover-text-wrap bg-utilityGray300"
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      closeBtnClass="[&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full"
      modalClassName="flex flex-col "
    >
      <div className=" flex md:flex-row flex-col flex-1 overflow-auto">
        <div className="flex-1 md:px-8 px-5 md:order-[0] order-2 flex flex-col">
          {/* <TabButton
            className=" !border-0 !border-b-0 border-transparent !rounded-none flex gap-1 items-center  !p-0 flex-none"
            parentClassName="mt-6 mb-6 rounded-none whitespace-nowrap overflow-x-auto w-full scrollbar-hide !shadow-none relative md:gap-x-6 gap-x-3"
            tabParentClassName=" "
            activeClassName="z-[1] !border-b-0 !border-primary text-primary700 [&>span]:after:bg-primary [&>span]:after:w-full [&>span]:text-grayLight900 [&>span]:font-medium"
            tabArray={tabOption}
            tabTextClass="after:content-[''] after:z-[0] after:bg-borderPrimary after:h-[1px] after:w-0 after:absolute after:bottom-0 after:left-0 hover:after:w-full after:ease-in-out after:duration-[0.15s] after:transition-[width] relative leading-5 text-gray500 hover:text-grayLight900 transition-all font-medium"
            isActive={activeTab}
            handleOnClick={(e: any) => {
              setActiveTab(e.target.dataset.value);
            }}
          /> */}
          <TabButton
            parentClassName="mt-6 mb-6 !bg-gray50 !w-full "
            className="rounded-md text-gray500 !border-0 min-w-[162px] "
            tabParentClassName={`!w-full ${
              window.MODE === 'production' ? '!hidden' : ''
            }`}
            activeClassName="!bg-primary100 !text-primary700"
            tabArray={tabOption}
            isActive={activeTab}
            isTab={true}
            handleOnClick={(e: any) => {
              setActiveTab(e.target.dataset.value);
            }}
          />
          {activeTab === 'about' && (
            <div className="mb-10 flex flex-col gap-10 flex-1 md:h-[calc(100vh_-_ 420px)] md:max-h-[calc(100vh_-_420px)] md:min-h-[calc(100vh_-_420px)] overflow-auto scrollbar-hide ">
              <p className="text-textSecondary text-sm font-normal tracking-[-0.09px]">
                {integration?.description}
              </p>
              {/* <img src={integrationImage} className="max-w-full" /> */}
              <div className="w-full bg-[#f7f7f8] lg:p-12 p-9 !pb-0 rounded-lg">
                <img
                  src={
                    integration?.aboutImage
                      ? integration.aboutImageUrl + integration.aboutImage
                      : integrationImage
                  }
                  className="object-cover h-full w-full"
                  alt=""
                />
              </div>
            </div>
          )}
          {activeTab === 'instructions' && (
            <ol className="mb-10 list-decimal list-inside flex flex-col gap-2 text-textSecondary text-xs text-normal md:h-[calc(100vh_-_ 420px)] md:max-h-[calc(100vh_-_420px)] md:min-h-[calc(100vh_-_420px)] overflow-auto scrollbar-hide">
              <li>
                Lorem Ipsumis simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industrys
              </li>
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry
              </li>
              <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been.
              </li>
              <li>Lorem Ipsum is simply dummy text of the printing.</li>
            </ol>
          )}
          {activeTab === 'configurations' && (
            <div className="mb-10 md:h-[calc(100vh_-_ 420px)] md:max-h-[calc(100vh_-_420px)] md:min-h-[calc(100vh_-_420px)] overflow-auto scrollbar-hide flex flex-col gap-4">
              {integration &&
                integration.configurations.map(
                  (configuration: IConfiguration) => (
                    <div key={configuration.id}>
                      <Controller
                        name={configuration.slug}
                        control={control}
                        defaultValue={getValueById(configuration.id)}
                        render={({ field }) => (
                          <InputText
                            inputName={configuration.slug}
                            value={field.value}
                            label={configuration.name}
                            labelClassName="mb-1.5 block"
                            onChangeFunc={(e) => {
                              field.onChange(e);
                              handleChange(e, configuration.id);
                            }}
                            errorText={errors[configuration.slug]?.message}
                          />
                        )}
                      />
                    </div>
                  )
                )}
              <div>
                <ButtonCmp
                  type="submit"
                  className="btn-outline-primary min-w-[140px]"
                  onClick={handleSubmit(onSubmit)}
                  loading={isLoadingSave}
                  disabled={isLoadingSave}
                >
                  Save
                </ButtonCmp>
              </div>
            </div>
          )}
          {activeTab === 'IntegrationScope' && (
            <div className="mb-10 md:h-[calc(100vh_-_ 420px)] md:max-h-[calc(100vh_-_420px)] md:min-h-[calc(100vh_-_420px)] overflow-auto scrollbar-hide flex flex-col gap-4">
              <div>
                <div className="flex gap-2 justify-between items-center mb-1.5">
                  <label className="form_label mb-0 block">Get Rate</label>
                  <Toggle
                    isDisabled={false}
                    isChecked={true}
                    onChange={() => {}}
                  />
                </div>
                <InputText
                  inputName="firstName"
                  placeholder="Enter first name"
                  parentClassName="flex-1"
                  value={'asd'}
                  onChangeFunc={() => {}}
                />
              </div>
              <div>
                <div className="flex gap-2 justify-between items-center mb-1.5">
                  <label className="form_label mb-0 block">
                    Address autocomplete
                  </label>
                  <Toggle
                    isDisabled={false}
                    isChecked={false}
                    onChange={() => {}}
                  />
                </div>
                <InputText
                  inputName="firstName"
                  placeholder="Enter first name"
                  parentClassName="flex-1"
                  value={'asd'}
                  onChangeFunc={() => {}}
                  disabled
                />
              </div>
            </div>
          )}
        </div>
        <div className="md:w-[241px] w-full  md:mb-0 mb-4 flex md:flex-col xs:flex-row flex-col md:items-stretch xs:items-center">
          <div className="md:border-l md:border-b-0 border-b border-utilityGray200 md:p-6 p-5 flex-1 flex md:flex-col smd:flex-row flex-col md:items-stretch mdm:items-center">
            <div className="md:w-full">
              {integration?.isEnable ? (
                <ButtonCmp
                  type="submit"
                  className="w-full text-xs leading-5 font-medium border-0 !shadow-none text-gray500 rounded-full bg-[#eefcf6] hover:bg-utilityGray400 hover:text-gray500 [&:hover>span]:block [&:hover>span:nth-child(2)]:hidden "
                  onClick={() => handleConnect()}
                  loading={isLoadingConnect}
                  disabled={isLoadingConnect}
                >
                  <span className="hidden">Disconnect</span>
                  <span>Connected</span>
                </ButtonCmp>
              ) : (
                <ButtonCmp
                  type="submit"
                  className="btn_primary w-full rounded-full"
                  onClick={handleSubmit(handleConnect)}
                  loading={isLoadingConnect}
                  disabled={isLoadingConnect}
                >
                  Connect
                </ButtonCmp>
              )}
              {/* <div className="mt-1 flex justify-center smd:h-10 h-6 items-center smd:mb-0 mb-5">
                <a
                  className="inline-flex gap-1 text-textSecondary text-xs font-medium hover:text-primary"
                  href="#"
                >
                  Open to direct page <LinkExternal02 className="w-4 h-4" />
                </a>
              </div> */}
            </div>
            <div className="md:border-l-0 smd:border-l smd:border-t-0 border-t border-utilityGray200 md:pl-0 smd:pl-4 md:pt-10 smd:pt-0 pt-5 md:ml-0 smd:ml-4 flex-1 md:w-full">
              <img src={avatarGroup} className="w-[58px]" />
              <h6 className="text-grayLight900 text-sm font-medium mt-2">
                Have a question?
              </h6>
              <a className="flex flex-wrap gap-2 px-2 text-gray500 text-sm font-normal mt-2 min-h-8 items-center bg-transparent hover:bg-utilityGray400 rounded-badge cursor-pointer">
                <MessageCircle02 className="w-5 h-5" />
                <p>Chat with us</p>
              </a>
              <a className="flex flex-wrap gap-2 px-2 text-gray500 text-sm font-normal min-h-8 items-center bg-transparent hover:bg-utilityGray400 rounded-badge cursor-pointer">
                <Phone className="w-5 h-5" />
                <p>Call us</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default ViewIntegrationModal;
