import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef } from 'react';

import startMarkerImg from '../../../assets/img/pinWithParcel.svg';
import endMarkerImg from '../../../assets/img/pinWithParcel.svg';

interface MapboxProps {
  startPoint: { lat: number; lng: number; name: string | null };
  endPoint?: { lat: number; lng: number; name: string | null };
  midpoint?: { lat: number; lng: number; name: string };
  height?: any;
  className?: string;
  markerRef?: any;
  isShowRoute?: boolean;
  isZoomControlVisible?: boolean;
  isFullscreen?: boolean;
  isDraggable?: boolean;
  isZoomable?: boolean;
  defaultZoom?: any;
  setDistance?: any;
  setDuration?: any;
  showImageAsMarker?: boolean;
  currentLocation?: any;
}

const createSVGMarker = () => {
  const svgElement = document.createElement('div');
  svgElement.className = 'parcel-arrow';
  svgElement.innerHTML = `
  
   <div class="w-[12px] -mt-px -mr-px after:content-[''] after:border after:border-[#6172F3] after:w-[200%] after:h-[200%] after:rounded-full after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:absolute">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" class="w-full rotate-[60deg]">
            <path d="M12.6657 2.67916C12.2439 2.4686 11.7476 2.4686 11.3258 2.67916C10.9525 2.86552 10.7595 3.19367 10.6676 3.36083C10.5675 3.543 10.4649 3.77692 10.362 4.01162L3.05073 20.6813C2.93566 20.9436 2.82421 21.1976 2.7529 21.4084C2.69129 21.5905 2.56638 21.9837 2.70011 22.4066C2.84758 22.873 3.21319 23.2382 3.67975 23.3851C4.10282 23.5184 4.49584 23.393 4.67791 23.3312C4.88863 23.2596 5.14253 23.1478 5.4047 23.0324L11.9957 20.1324L18.5868 23.0325C18.8489 23.1478 19.1028 23.2596 19.3135 23.3312C19.4956 23.393 19.8886 23.5184 20.3117 23.3851C20.7783 23.2382 21.1439 22.873 21.2913 22.4066C21.4251 21.9837 21.3002 21.5905 21.2385 21.4084C21.1672 21.1976 21.0558 20.9436 20.9407 20.6813L13.6295 4.01167C13.5266 3.77695 13.424 3.54301 13.3238 3.36083C13.2319 3.19367 13.039 2.86552 12.6657 2.67916Z" fill="white"/>
          </svg>
        </div>`;

  return svgElement;
};

// const calculateAngle = (start: any, end: any) => {
//   const dx = end[0] - start[0];
//   const dy = end[1] - start[1];

//   return Math.atan2(dy, dx) * (180 / Math.PI);
// };

const MapboxMap: React.FC<MapboxProps> = ({
  startPoint,
  endPoint,
  midpoint,
  height = 500,
  className,
  markerRef,
  isShowRoute,
  isZoomControlVisible = false,
  isFullscreen = true,
  isDraggable = false,
  isZoomable = true,
  defaultZoom = 2,
  setDistance,
  setDuration,
  showImageAsMarker = false,
  currentLocation,
}) => {
  const mapRef = useRef<any>(null);
  const mapContainerRef = useRef<any>(null);

  const addMarker = useCallback(
    (map: any, position: any, imageUrl: any, name: any, showImage: any) => {
      if (!position || !position.lng || !position.lat) return;

      let markerElement;

      if (showImage) {
        markerElement = document.createElement('div');
        markerElement.style.width = '23px';
        markerElement.style.height = '30px';
        markerElement.style.backgroundImage = `url(${imageUrl})`;
        markerElement.style.backgroundSize = 'contain';
      } else {
        markerElement = document.createElement('div');
        markerElement.style.width = '10px';
        markerElement.style.height = '10px';
        markerElement.style.backgroundColor = '#444CE7';
        markerElement.style.borderRadius = '50%';
        markerElement.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.5)';
      }

      const marker = new mapboxgl.Marker(markerElement)
        .setLngLat([position.lng, position.lat])
        .addTo(map);

      if (name) {
        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setHTML(`<h3>${name}</h3>`);
        marker.setPopup(popup);
        popup.addTo(map);
      }
    },
    []
  );

  const initializeMap = useCallback(() => {
    if (!window?.MAPBOX_ACCESSTOKEN) {
      console.error('Mapbox access token is missing');

      return;
    }

    mapboxgl.accessToken = window.MAPBOX_ACCESSTOKEN;

    // if (mapContainerRef.current && !mapRef.current) {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [-95, 55],
      zoom: defaultZoom,
      pitch: 0,
      bearing: 0,
      boxZoom: isZoomable,
      antialias: true,
      dragPan: isDraggable,
      scrollZoom: isZoomable,
      doubleClickZoom: isZoomable,
      touchZoomRotate: isZoomable,
      projection: 'mercator',
      attributionControl: false,
      dragRotate: false,
    });

    if (isZoomControlVisible) {
      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    // Fullscreen control
    if (isFullscreen) {
      const fullscreenControl = new mapboxgl.FullscreenControl();
      mapRef.current.addControl(fullscreenControl);
    }

    if (startPoint || endPoint) {
      mapRef.current.on('load', async () => {
        if (startPoint && startPoint.lng) {
          addMarker(
            mapRef.current,
            startPoint,
            startMarkerImg,
            startPoint.name,
            showImageAsMarker
          );
        }

        if (endPoint && endPoint.lng) {
          addMarker(
            mapRef.current,
            endPoint,
            endMarkerImg,
            endPoint.name,
            showImageAsMarker
          );
        }

        if (
          isShowRoute &&
          startPoint &&
          startPoint.lng &&
          endPoint &&
          endPoint.lng
        ) {
          const query = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint.lng},${startPoint.lat};${endPoint.lng},${endPoint.lat}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
            { method: 'GET' }
          ).catch((error) => {
            console.error('Error fetching directions:', error);
          });

          if (!query) return;

          const json = await query.json();

          if (json.routes && json.routes.length > 0) {
            const data = json.routes[0];
            const fetchedRoute = data.geometry.coordinates;

            let routeDistance = Math.round((data.distance / 1000) * 0.621371);
            setDistance(routeDistance);

            const durationInSeconds = json.routes[0].duration;
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);
            const formattedDuration = `${hours} hr ${minutes} min`;

            setDuration(formattedDuration);

            if (mapRef.current.getSource('fetched-route')) {
              (
                mapRef.current.getSource(
                  'fetched-route'
                ) as mapboxgl.GeoJSONSource
              ).setData({
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: fetchedRoute,
                },
              });
            } else {
              mapRef.current.addSource('fetched-route', {
                type: 'geojson',
                data: {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'LineString',
                    coordinates: fetchedRoute,
                  },
                },
              });

              mapRef.current.addLayer({
                id: 'fetched-route',
                type: 'line',
                source: 'fetched-route',
                layout: {
                  'line-join': 'round',
                  'line-cap': 'round',
                },
                paint: {
                  'line-color': '#444CE7',
                  'line-width': 3,
                  'line-opacity': 0.75,
                },
              });

              const bounds = new mapboxgl.LngLatBounds(
                fetchedRoute[0],
                fetchedRoute[0]
              );

              if (currentLocation) {
                const svgMarker = createSVGMarker();
                new mapboxgl.Marker(svgMarker)
                  .setLngLat([currentLocation.lng, currentLocation.lat])
                  .addTo(mapRef.current!);
              }

              fetchedRoute.forEach((coord: any) => bounds.extend(coord));

              mapRef.current.fitBounds(bounds, {
                padding: { top: 50, bottom: 50, left: 50, right: 50 },
              });
            }
          }
        }

        const bounds = new mapboxgl.LngLatBounds();

        if (startPoint && startPoint.lng) {
          bounds.extend([startPoint.lng, startPoint.lat]);
        }

        if (endPoint && endPoint.lng) {
          bounds.extend([endPoint.lng, endPoint.lat]);
        }

        if (startPoint && endPoint) {
          mapRef?.current?.fitBounds(bounds, {
            padding: { top: 50, bottom: 50, left: 50, right: 50 },
            maxZoom: 10,
          });
        } else if (startPoint) {
          mapRef?.current?.setCenter([startPoint.lng, startPoint.lat]);
          mapRef?.current?.setZoom(defaultZoom > 16 ? defaultZoom : 16);
        } else if (endPoint) {
          mapRef?.current?.setCenter([endPoint.lng, endPoint.lat]);
          mapRef?.current?.setZoom(defaultZoom > 16 ? defaultZoom : 16);
        }
      });
    }
    // }
  }, [startPoint, endPoint, currentLocation]);

  useEffect(() => {
    initializeMap();

    // return () => {
    //   if (mapRef.current) {
    //     mapRef.current.remove();
    //   }
    // };
  }, [initializeMap]);

  useEffect(() => {
    if (midpoint?.lat && midpoint?.lng) {
      const midpointMarker = document.createElement('div');
      midpointMarker.style.width = '23px';
      midpointMarker.style.height = '30px';
      midpointMarker.style.backgroundImage = `url(${endMarkerImg})`;
      midpointMarker.style.backgroundSize = 'cover';

      if (markerRef.current) {
        markerRef.current.remove();
      }

      markerRef.current = new mapboxgl.Marker(midpointMarker)
        .setLngLat([midpoint.lng, midpoint.lat])
        .addTo(mapRef.current);
    } else {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    }

    return () => {
      if (markerRef.current) {
        markerRef.current.remove();
        markerRef.current = null;
      }
    };
  }, [midpoint]);

  return (
    <div
      id="map-container"
      ref={mapContainerRef}
      style={{ width: '100%', height: `${height}` }}
      className={className}
    />
  );
};

export default MapboxMap;
