import { ChevronUp, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import { getShortName } from 'src/utils/CommonFunctions';

const SalesPriceCostBreakDownRow = ({
  data,
  subDataVisibilityForSalesPrice,
  isSalesPriceDropDownShown,
  totalCarrierPrice,
}: any) => (
  <>
    <td className="px-4 py-3 w-[45%] min-w-[45%] max-w-[1px]">
      <div className="flex items-center gap-3 truncate">
        {data?.image && data?.imageUrl ? (
          <img
            src={`${data?.imageUrl}${data?.image}`}
            className="table-profile-icn-circle-sm "
          />
        ) : data?.image ? (
          <img
            src={`${data?.image}`}
            className="table-profile-icn-circle-sm "
          />
        ) : (
          <div className="table-profile-user-circle-sm ">
            {getShortName(data?.name)}
          </div>
        )}

        <h6 className="text-textSecondary font-medium truncate ">
          {data?.name}{' '}
          <span className="text-grayLight600 text-xs font-normal">
            (#{data?.legId})
          </span>
        </h6>
      </div>
    </td>

    <td className="px-4 py-3 w-[50.66%] min-w-[50.66%] max-w-[1px]">
      <span
        className={`${
          totalCarrierPrice > data.totalSalePrice
            ? 'text-fgErrorPrimary font-semibold'
            : 'text-fgSuccessPrimary font-semibold'
        }`}
      >{`$${data.totalSalePrice} CAD `}</span>
      <span className="font-medium">({`$${data.totalSalePriceUSD} USD`})</span>
    </td>
    <td className="px-4 py-3 w-[50px] max-w-[50px] min-w-[50px]">
      {isSalesPriceDropDownShown &&
        (subDataVisibilityForSalesPrice[data.id] ? (
          <ChevronUp className="w-4 h-4 flex-none" />
        ) : (
          <ChevronDown className="w-4 h-4 flex-none" />
        ))}
    </td>
  </>
);

export default SalesPriceCostBreakDownRow;
