import { Plus } from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CropperModal from 'src/components/CropperModal/CropperModal';
import DeleteModal from 'src/components/DeleteModal';
import NotFoundUI from 'src/components/NotFoundUI';
import PdfPreviewModal from 'src/components/PdfPreviewModal/PdfModal';
import TableCmp from 'src/components/TableCmp';
import { MAX_FILE_SIZE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import {
  addClaimAttachmentFile,
  addDisputeAttachmentFile,
  deleteClaimDisputeAttachmentFile,
  getClaimDisputeFileList,
  getClaimDisputeFileListCount,
} from 'src/services/ClaimService';
import {
  checkFileTypeValidation,
  convertBase64ToFile,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import FileUploadLoaderRow from './FileUploadLoaderRow';
import FileUploadRow from './FileUploadRow';

interface IProps {
  claimId?: number;
  disputeId?: number;
}

const FileUpload = ({ claimId, disputeId }: IProps) => {
  // const [fileAttachmentFilter, setFileAttachmentFilter] = useState('all');
  const [upload, setUpload] = useState(false);
  const [displayImage, setDisplayImage] = useState<any>('');
  const [displayPDF, setDisplayPDF] = useState<any>('');
  const [displayPDFUrl, setDisplayPDFUrl] = useState<any>('');
  const fileNameRef = useRef<any>('');
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [isShowSweetAlert, setIsShowSweetAlert] = useState<boolean>(false);
  const [isFileUploadLoading, setIsFileUploadLoading] = useState(false);

  const [attachmentFileList, setAttachmentFileList] = useState<any>([]);
  const [isFileListLoading, setIsFileListLoading] = useState(false);
  const [filesTotal, setFilesTotal] = useState<any>(0);
  const [isDeleteFile, setIsDeleteFile] = useState(false);
  const [isDeleteID, setIsDeleteID] = useState(null);

  const { currentUser } = useContext(AuthContext);
  const fileInput = useRef<HTMLInputElement>(null);

  const getFileAttachmentListData = () => {
    setIsFileListLoading(true);
    setAttachmentFileList([]);

    Promise.all([
      getClaimDisputeFileList({
        claimId: claimId,
        disputeId: disputeId,
        // filterType: fileAttachmentFilter,
      }),
      getClaimDisputeFileListCount({
        claimId: claimId,
        disputeId: disputeId,
      }),
    ])
      .then(([responseData, responseCount]: [any, any]) => {
        if (responseData?.claimDisputeAttachments) {
          setAttachmentFileList(responseData?.claimDisputeAttachments);
        }
        setFilesTotal(responseCount?.total);
        setIsFileListLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
        setIsFileListLoading(false);
      });
  };

  useEffect(() => {
    getFileAttachmentListData();
  }, []);

  const uploadFile = (file: any) => {
    const appendFormData = new FormData();
    appendFormData.append('name', fileNameRef.current);
    appendFormData.append('file', file.convertedFile);
    appendFormData.append('userId', currentUser?.id);

    if (claimId) {
      addClaimAttachmentFile(claimId, appendFormData)
        .then((response: any) => {
          if (response && response.data) {
            setUpload(false);
            setIsFileUploadLoading(false);
            getFileAttachmentListData();

            if (fileInput?.current) {
              fileInput.current.value = '';
            }
          }
        })
        .catch(console.error);
    } else if (disputeId) {
      addDisputeAttachmentFile(disputeId, appendFormData)
        .then((response: any) => {
          if (response && response.data) {
            setUpload(false);
            setIsFileUploadLoading(false);
            getFileAttachmentListData();

            if (fileInput?.current) {
              fileInput.current.value = '';
            }
          }
        })
        .catch(console.error);
    }
  };

  const imageUpload = (data: any) => {
    const appLogoImage = data;
    const fileInfo: any = convertBase64ToFile(appLogoImage);

    if (fileInfo?.convertedFile && fileInfo?.convertedFile != '') {
      setIsFileUploadLoading(true);
      uploadFile(fileInfo);
    }
  };

  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   const file = acceptedFiles[0];

  //   if (file?.type?.match('image') || file?.type?.match('application/pdf')) {
  //     const { result } = checkFileTypeValidation(acceptedFiles, MAX_FILE_SIZE, [
  //       'image/svg+xml',
  //       'image/png',
  //       'image/jpeg',
  //       'application/pdf',
  //     ]);

  //     if (result) {
  //       const reader = new FileReader();

  //       reader.onloadstart = () => {
  //         setDisplayImage('');
  //         setDisplayPDF('');
  //         setErrorMessage('');
  //         setFileName('');
  //       };

  //       reader.onloadend = () => {
  //         setFileName(file.name);

  //         if (file?.type?.match('application/pdf')) {
  //           setDisplayPDFUrl(URL.createObjectURL(file));
  //           setDisplayPDF(reader.result as any);
  //         } else {
  //           setDisplayImage(reader.result as any);
  //         }

  //         setUpload(true);
  //       };
  //       reader.readAsDataURL(file);
  //     } else {
  //       setErrorMessage('File size is invalid');
  //     }
  //   } else {
  //     setErrorMessage('File type is invalid');
  //   }
  // }, []);

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  //   maxFiles: 1,
  //   multiple: false,
  //   accept: {
  //     'image/png': ['.png'],
  //     'image/jpeg': ['.jpg', '.jpeg'],
  //     'image/svg+xml': ['.svg'],
  //     'application/pdf': ['.pdf'],
  //   },
  // });

  const filesHeadCells = useMemo(
    () => [
      {
        id: 'filename',
        name: 'File Name',
      },
      {
        id: 'dateuploaded',
        name: 'Date Uploaded',
        rowClassName: ' max-w-64 min-w-[25%] w-[25%]',
      },
      {
        id: 'uploadedby',
        name: 'Uploaded By',
        rowClassName: ' max-w-64 min-w-[25%] w-[25%]',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  const onAttachmentRowClick = (url: any) => () => {
    console.log('url', url);

    window.open(url, '_blank');
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      if (status && isDeleteID) {
        deleteClaimDisputeAttachmentFile(isDeleteID)
          .then(() => {
            setIsDeleteFile(false);
            setIsDeleteID(null);
            getFileAttachmentListData();
          })
          .catch((e) => {
            WalToast.error(
              e?.response?.data?.message ??
                'Something went wrong while deleting file.'
            );
          });
      } else {
        setIsDeleteFile(false);
        setIsDeleteID(null);
      }
    },
    [isDeleteID]
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    if (
      file &&
      (file.type.match('image') || file.type.match('application/pdf'))
    ) {
      const { result } = checkFileTypeValidation([file], MAX_FILE_SIZE, [
        'image/svg+xml',
        'image/png',
        'image/jpeg',
        'application/pdf',
      ]);

      if (result) {
        const reader = new FileReader();

        reader.onloadstart = () => {
          setDisplayImage('');
          setDisplayPDF('');
          setErrorMessage('');
          fileNameRef.current = '';
          setDisplayPDFUrl('');
        };

        reader.onloadend = () => {
          fileNameRef.current = file.name;

          if (file.type.match('application/pdf')) {
            setDisplayPDFUrl(URL.createObjectURL(file));
            setDisplayPDF(reader.result as any);
          } else {
            setDisplayImage(reader.result as any);
            imageUpload(reader.result);
          }

          setUpload(true);
        };
        reader.readAsDataURL(file);
      } else {
        setErrorMessage('File size is invalid');
      }
    } else {
      setErrorMessage('File type is invalid');
    }
  };

  useEffect(() => {
    if (!upload) {
      if (fileInput?.current) {
        fileInput.current.value = '';
      }
    }
  }, [upload]);

  useEffect(() => {
    if (errorMessage) {
      setIsShowSweetAlert(true);
    }
  }, [errorMessage]);

  return (
    <>
      {/* <div className=" flex-1 mt-4">
        <div className=" md:gap-4 gap-2 md:flex-row flex-col items-center rounded-xl border border-borderSecondary hover:border-primary">
          <label
            className="cursor-pointer"
            htmlFor="fileSelect"
            {...getRootProps()}
          >
            <div className="relative z-[0] flex-grow md:w-auto w-full cursor-pointer flex-auto rounded-xl py-3.5 px-5 text-center bg-white">
              <div className="mx-auto mb-3 w-9 h-9 flex items-center justify-center rounded-lg border border-utilityGray200 shadow">
                <UploadCloud02 className="h-4 w-4" />
              </div>
              <progress
                className="progress bg-gray50 absolute left-0 top-0 -z-10 rounded-xl h-full w-[70%] hidden"
                value="70"
                max="100"
              ></progress>
              <div className="text-grayLight600 text-xs font-normal">
                <p>
                  <span className="text-[#2422DD] font-semibold">
                    Click to upload&nbsp;
                  </span>
                  or drag and drop
                </p>
                <p className="text-[11px] leading-[18px]">
                  SVG, PNG, JPG or PDF
                </p>
              </div>
            </div>
          </label>
          <input
            type="file"
            name="image"
            id="fileSelect11"
            accept="image/*"
            className="hidden"
            {...getInputProps()}
          />
        </div>
      </div>
      {errorMessage && <ErrorMsg errorText={errorMessage} />} */}

      <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col mb-5">
        <div className="table-top-header ">
          <div className="table-left-wrap ">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">All Files & Attachments</h5>
              <BadgeCmp
                style="modern"
                type="success"
                mainClassName={`${isFileListLoading ? 'custom-loading' : ''}`}
              >
                {filesTotal} {filesTotal > 1 ? 'Files' : 'File'}
              </BadgeCmp>
            </div>
            <p className="table-subtitle">
              {`All Files and assets that have been attached to this 
                ${claimId ? 'claim' : ''}${disputeId ? 'dispute' : ''}.`}
            </p>
          </div>
          {/* <TabButton
            tabArray={FilesTab}
            parentClassName="w-full"
            isActive={fileAttachmentFilter}
            handleOnClick={(e: any) =>
              setFileAttachmentFilter(e.target.dataset.value)
            }
          /> */}
          <input
            type="file"
            id="fileInput"
            accept=".png,.jpg,.jpeg,.svg,.pdf"
            ref={fileInput}
            onChange={(e) => {
              handleFileChange(e);
            }}
            className="hidden"
          />
          <ButtonCmp
            className=" btn-outline-primary"
            icon={<Plus className="w-4 h-4" />}
            onClick={() => {
              setErrorMessage(null);
              document.getElementById('fileInput')?.click();
            }}
            loading={isFileUploadLoading}
            disabled={isFileUploadLoading}
          >
            Upload Files
          </ButtonCmp>
        </div>
        <div className="h-full w-full border-t border-gray100 flex flex-col">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
            <TableCmp
              headCells={filesHeadCells}
              tableDataArr={attachmentFileList}
              TableLoaderRowCmp={FileUploadLoaderRow}
              TableRowCmp={FileUploadRow}
              tableRowCmpProps={{
                getList: getFileAttachmentListData,
                setIsDeleteFile,
                setIsDeleteID,
                onRowClick: onAttachmentRowClick,
              }}
              isTableDataLoading={isFileListLoading}
            />
          </div>
          {!isFileListLoading && attachmentFileList?.length <= 0 && (
            <NotFoundUI
              title={'No file & attachments found'}
              desc={`There are no file & attachments found`}
              containerClassName="!h-auto !my-6 flex-1"
              testId="wal-Claims-dataNotFoundText"
              iconClassName="!hidden"
            />
          )}
        </div>
      </div>

      {upload &&
        (displayPDF && displayPDF != '' ? (
          <PdfPreviewModal
            modalTitle={`${claimId ? 'Claim' : ''}${
              disputeId ? 'Dispute' : ''
            }  Attachment preview`}
            modalDesc={`${claimId ? 'Claim' : ''}${
              disputeId ? 'Dispute' : ''
            }  Attachment preview`}
            setUpload={setUpload}
            displayPDF={displayPDF}
            imageUpload={imageUpload}
            setIsFileUploadLoading={setIsFileUploadLoading}
            isFileUploadLoading={isFileUploadLoading}
            displayPDFUrl={displayPDFUrl}
          />
        ) : claimId || disputeId ? (
          ''
        ) : (
          <CropperModal
            modalTitle={`Edit ${claimId ? 'claim' : ''}${
              disputeId ? 'dispute' : ''
            } task Image`}
            modalDesc={`Edit ${claimId ? 'claim' : ''}${
              disputeId ? 'dispute' : ''
            } task Image as per your requirement`}
            imageUpload={imageUpload}
            imageUrl={displayImage}
            setUpload={setUpload}
            defaultImage={''}
            setCropData={setDisplayImage}
            isCropType={false}
            defaultCropType="Square"
            isSetToModalClose={false}
            primaryBtnLoading={isFileUploadLoading}
          />
        ))}
      {isDeleteFile && (
        <DeleteModal
          moduleName={`file from ${claimId ? 'claim' : ''}${
            disputeId ? 'dispute' : ''
          }`}
          handleClose={handleModalClose}
        />
      )}

      {isShowSweetAlert && (
        <CommonModal
          title={errorMessage}
          primaryBtnText="Close"
          primaryBtnOnClick={() => {
            setIsShowSweetAlert(false);

            if (fileInput?.current) {
              fileInput.current.value = '';
            }
          }}
          handleClose={() => {
            setIsShowSweetAlert(false);

            if (fileInput?.current) {
              fileInput.current.value = '';
            }
          }}
        >
          <div className="p-5 text-sm">
            {errorMessage === 'File type is invalid' ? (
              <p>
                The file type you selected is not supported. Please upload a
                file in one of the following formats:{' '}
                <strong>SVG, PNG, JPG, JPEG, or PDF</strong>.
              </p>
            ) : errorMessage === 'File size is invalid' ? (
              <p>
                The file you selected is too large. The maximum allowed file
                size is <strong>{MAX_FILE_SIZE / (1024 * 1024)} MB</strong>.
                Please choose a smaller file to upload.
              </p>
            ) : (
              <p>{errorMessage}</p>
            )}
          </div>
        </CommonModal>
      )}
    </>
  );
};

export default FileUpload;
