import React from 'react';

const winLossRowCmp = ({ data }: any) => (
  <>
    <td className={`px-5 py-4 w-[50%] min-w-[50%] max-w-[50%]`}>
      <span className="">{data?.percentage_bracket}%</span>
    </td>
    <td className={`px-5 py-4 w-[50%] min-w-[50%] max-w-[50%]`}>
      <span className="">{data?.customer_count}</span>
    </td>
  </>
);

export default winLossRowCmp;
