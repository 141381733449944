import { ApexOptions } from 'apexcharts';

export const predictivePriceOptions: ApexOptions = {
  chart: {
    height: 350,
    type: 'area',
    events: {
      mouseMove: function () {
        document.querySelectorAll('.apexcharts-marker').forEach((el: any) => {
          el.style.opacity = '0'; // Hide markers on hover
        });
      },
      mouseLeave: function () {
        document.querySelectorAll('.apexcharts-marker').forEach((el: any) => {
          el.style.opacity = '1'; // Show markers when not hovering
        });
      },
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 2,
    dashArray: [0, 0, 0],
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5,
    },
  },
  colors: ['#A4BCFD', '#3538CD', '#6172F3'],
  yaxis: {
    labels: {
      offsetX: 0,
      formatter: function (val: any) {
        return val === 0 ? val : `$${val}`;
      },
    },
    max: 2000,
    min: 500,
    tickAmount: 5,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    crosshairs: {
      show: true,
      width: 1,
      position: 'front',
      opacity: 1,
      stroke: {
        color: '#A0A0A0',
        width: 1,
        dashArray: 2,
      },
    },
    tickAmount: 12,
    type: 'datetime',
    labels: {
      formatter: function (val) {
        const date = new Date(val);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });

        return `${day < 10 ? `0${day}` : day} ${month}`;
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
};
