import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import {
  createUpdateQuoteSettings,
  getQuoteSettings,
} from 'src/services/QuoteService';
import WalToast from 'src/utils/WalToast';
import * as Yup from 'yup';

const QuoteSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailLoading, setIsDetailLoading] = useState(true);

  const schema = Yup.object().shape({
    expired_days: Yup.number()
      .transform((value, originalValue) =>
        originalValue === '' ? undefined : value
      )
      .required('Expired days count is required')
      .positive('Expired days count must be positive')
      .integer('Expired days count must be a whole number')
      .min(1, 'Expired days count must be at least 1'),
  });

  const defaultValues = {
    expired_days: 0,
  };

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const getQuoteSettingsDetail = () => {
    setIsDetailLoading(true);
    getQuoteSettings()
      .then((response: any) => {
        if (response.data) {
          setValue(
            'expired_days',
            response?.data?.quoteLostExpiryDaysCount ?? ''
          );
          setIsDetailLoading(false);
        }
      })
      .finally(() => {
        setIsDetailLoading(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    getQuoteSettingsDetail();
  }, []);

  const onSubmit = (data: any) => {
    setIsLoading(true);
    console.log('data', data);
    createUpdateQuoteSettings(data)
      .then((response: any) => {
        console.log('response?.data ', response);
        setValue(
          'expired_days',
          response?.updatedData?.quoteLostExpiryDaysCount
        );
      })
      .finally(() => setIsLoading(false))
      .catch(() => WalToast.error('Quote setting have been not updated'));
  };

  return (
    <form className="h-full w-full px-2 py-1" onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full flex justify-between border-b border-utilityGray200 pb-4">
        <div>
          <h6 className="text-grayLight900 font-semibold text-sm">
            Quote settings
          </h6>
          <p className="text-grayLight600 font-normal text-sm">
            Update your quote settings here.
          </p>
        </div>
      </div>
      <div className="mt-4">
        <div className="border-b border-utilityGray200 pb-4 flex sm:gap-8 gap-2 sm:flex-row flex-col mt-4">
          <label className="xls:w-3/12 sm:w-[30%] w-full text-textSecondary font-semibold text-sm">
            Lost Quote Expiry Days
          </label>
          <div className="xlm:w-[30%] xls:w-2/5 lg:w-2/4 sm:w-[70%] w-full flex gap-4">
            <Controller
              name="expired_days"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  parentClassName="flex-1"
                  inputName="lost_quote_expiry_days"
                  inputType="number"
                  placeholder={'Enter expiry days count'}
                  labelClassName="mb-1.5 block"
                  value={value}
                  shouldFormatNumber={true}
                  onChangeFunc={(event) => {
                    onChange(event);
                  }}
                  isLoading={isDetailLoading}
                  inputValueUnitType="days"
                  errorText={
                    errors.expired_days ? errors.expired_days?.message : null
                  }
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4 py-4">
          <ButtonCmp
            type="button"
            className="btn_secondary_black shadow py-2 px-3"
            onClick={() => {
              reset(defaultValues);
            }}
          >
            Cancel
          </ButtonCmp>
          <ButtonCmp
            type="submit"
            className="btn_primary  shadow py-2 px-3"
            loading={isLoading}
            disabled={isLoading || isDetailLoading}
          >
            Save
          </ButtonCmp>
        </div>
      </div>
    </form>
  );
};

export default QuoteSetting;
