import {
  CurrencyDollar,
  InfoCircle,
  PackageCheck,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { MARGIN_TYPE, CURRENCY } from 'src/constants/common';
import { activeQuote, updatePDF } from 'src/services/QuoteService';
import { formatDecimalNumber, getShortName } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import avatar from '../../../../../assets/img/Avatar1.png';
import { getCarrierServicePrices } from '../../../../../services/CarrierService';

import AddQuickQuotePhoneNumber from './AddQuickQuotePhoneNumber';

interface IProps {
  action: any;
  quoteID: any;
  handleClose: any;
  selectedCarrierRates: any[];
  currency: any | null;
  setIsRefresh: any;
  source?: 'loadBoard' | 'quoteDetail';
  servicesList: any[];
}

const initRequiredFields = ['finalCharge'];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const MoveToActive = ({
  quoteID,
  action,
  handleClose,
  selectedCarrierRates,
  currency,
  setIsRefresh,
  source = 'quoteDetail',
  servicesList,
}: IProps) => {
  const carrierRates = Array.isArray(selectedCarrierRates)
    ? selectedCarrierRates
    : [selectedCarrierRates];

  const [formData, setFormData] = useState<any>({});
  const [requiredFields, setRequiredFields] = useState(initRequiredFields);
  const [isValid, setIsValid] = useState<any>(false);
  const [nonQuoteRequiredFields, setNonQuoteRequiredFields] = useState([]);
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [notRatedServices, setNotRatedServices] = useState<any>(0);
  const [isPrimaryBtnClicked, setIsPrimaryBtnClicked] =
    useState<boolean>(false);
  const [validForm, setValidForm] = useState<any>({
    phoneNumbers: [false],
  });
  const [hasEmptyPhones, setHasEmptyPhones] = useState<boolean>(false);

  useEffect(() => {
    const existingFormData: any = {};
    const existingNonQuoteRequiredFields: any = [];
    const existingRequiredFields = [...initRequiredFields];
    let initialNotRatedServicesCount = 0;

    carrierRates.forEach((selectedCarrierRate: any) => {
      const quotedServices: any = [];
      const nonQuotedServices: any = [];
      const priceData: any = {};
      const services: any = [];

      if (selectedCarrierRate.services && selectedCarrierRate.services.length) {
        selectedCarrierRate.services.forEach((service: any) => {
          if (service.isQuoted) {
            quotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId?.name,
                value: service.value
                  ? formatDecimalNumber(service.value, 2)
                  : 0,
                serviceId: service.serviceId?.id,
              },
            });
          } else {
            initialNotRatedServicesCount++;
            nonQuotedServices.push({
              [service.serviceId.slug]: {
                name: service.serviceId?.name,
                value: service.value
                  ? formatDecimalNumber(service.value, 2)
                  : 0,
                serviceId: service.serviceId?.id,
              },
            });
            existingNonQuoteRequiredFields.push(service.serviceId.slug);
          }
          services.push({
            [service?.serviceId ? service?.serviceId?.slug : 'other']: {
              id: service?.id,
              name: service.serviceId?.name,
              value: service.value ? formatDecimalNumber(service.value, 2) : 0,
              serviceId: service.serviceId?.id,
              isOther: service.serviceId?.id ? false : true,
            },
          });

          existingRequiredFields.push(service.serviceId.slug);
        });
      }

      if (selectedCarrierRate.carrierId) {
        getCarrierServicePrices(selectedCarrierRate.carrierId)
          .then((response: any) => {
            if (response.data && response.data.length) {
              response.data.forEach(
                (data: any) =>
                  (priceData[data.serviceId] =
                    currency === CURRENCY.CAD ? data.priceCAD : data.priceUSD)
              );
            }
            selectedCarrierRate.carrierPriceData = priceData;
          })
          .catch(console.log);
      }

      existingFormData[selectedCarrierRate.id] = {
        transitTime: selectedCarrierRate.transitTime,
        projectedDeliveryDate: selectedCarrierRate.projectedDeliveryDate,
        margin:
          selectedCarrierRate.margin > 0
            ? formatDecimalNumber(selectedCarrierRate.margin, 2)
            : selectedCarrierRate.margin,
        marginUnit:
          selectedCarrierRate.marginUnit > 0
            ? formatDecimalNumber(selectedCarrierRate.marginUnit, 2)
            : selectedCarrierRate.marginUnit,
        additionalCharges:
          selectedCarrierRate.additionalCharges > 0
            ? formatDecimalNumber(selectedCarrierRate.additionalCharges, 2)
            : 0,
        finalCharge:
          selectedCarrierRate.finalCharge > 0
            ? formatDecimalNumber(selectedCarrierRate.finalCharge, 2)
            : '',
        totalCharge:
          selectedCarrierRate.totalCharge > 0
            ? formatDecimalNumber(selectedCarrierRate.totalCharge, 2)
            : '',
        marginType: selectedCarrierRate.marginType,
        carrierPriceData: priceData,
        services,
        quotedServices,
        nonQuotedServices,
        contactPhone: {
          phoneNumbers: selectedCarrierRate.contactPhone
            ? [selectedCarrierRate.contactPhone]
            : [''],
        },
      };

      if (selectedCarrierRate.id == 0) {
        existingFormData[selectedCarrierRate.id].name =
          selectedCarrierRate.name;
      }
    });

    setRequiredFields(existingRequiredFields);
    setNonQuoteRequiredFields(existingNonQuoteRequiredFields);
    setFormData(existingFormData);

    setNotRatedServices(initialNotRatedServicesCount);
  }, [selectedCarrierRates]);

  useEffect(() => {
    const existingValid: any = {};
    const existingValidNonQuote: any = {};

    Object.entries(formData).forEach(([key, value]: any) => {
      const isValid1 = requiredFields.every((requiredField) => {
        if (requiredField === 'finalCharge') {
          return value[requiredField] > 0;
        }

        return value[requiredField] !== '' && value[requiredField] !== null;
      });
      let isValid2 = true;

      if (value.nonQuotedServices && value.nonQuotedServices.length) {
        isValid2 = value.nonQuotedServices.every((nonQuotedService: any) => {
          const isValidService = nonQuoteRequiredFields.every(
            (requiredField) => {
              if (nonQuotedService[requiredField]) {
                return (
                  nonQuotedService[requiredField].value !== '' &&
                  nonQuotedService[requiredField].value !== null
                );
              }

              return true;
            }
          );

          return isValidService;
        });
      }
      existingValidNonQuote[key] = isValid2;
      existingValid[key] = isValid1 && isValid2;
    });

    let allCarrierValid: boolean = true;

    Object.values(existingValid).forEach((value: any) => {
      if (!value) {
        allCarrierValid = false;
      }
    });

    setIsValid(allCarrierValid);
  }, [formData]);

  const handleInputChange =
    (id: number, isService = false) =>
    (event: any) => {
      const { target, type } = event;
      const { name, value } = target;
      const regex = /^[0-9.]*$/;

      if (!regex.test(value)) {
        return;
      }

      const existingFormData: any = {
        ...formData[id],
      };

      if (name === 'transitTime') {
        existingFormData.projectedDeliveryDate = moment()
          .clone()
          .add(value, 'days')
          .toDate();
      }

      const newValue =
        type === 'blur' && !value
          ? 0
          : type === 'focus' && value === '0'
          ? ''
          : value;

      if (!isService) {
        existingFormData[name] = newValue >= 0 ? newValue : 0;
      }

      const parseValue =
        type === 'blur' && !value
          ? 0
          : value && value >= 0
          ? parseFloat(value)
          : 0;

      let additionalCharges = 0;
      existingFormData.quotedServices.forEach(
        (quoteService: any, index: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug ? parseValue : parseFloat(service.value);
            existingFormData.quotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value > 0
                  ? formatDecimalNumber(service.value, 2)
                  : 0,
            };
          });
        }
      );
      existingFormData.nonQuotedServices.forEach(
        (nonQuoteService: any, index: number) => {
          Object.entries(nonQuoteService).forEach(([slug, service]: any) => {
            additionalCharges +=
              name === slug
                ? parseValue
                : service.value
                ? parseFloat(service.value)
                : 0;
            existingFormData.nonQuotedServices[index][slug] = {
              ...service,
              value:
                name === slug
                  ? newValue
                  : service.value !== null
                  ? service.value > 0
                    ? formatDecimalNumber(service.value, 2)
                    : 0
                  : 0,
            };
          });
        }
      );

      if (isService) {
        existingFormData?.services?.forEach(
          (quoteService: any, qIndex: number) => {
            Object.entries(quoteService).forEach(([slug, service]: any) => {
              service.value = service.value || 0;
              existingFormData.services[qIndex][slug] = {
                ...service,
                value: name === slug ? value ?? 0 : parseFloat(service.value),
              };
            });
          }
        );
      }

      existingFormData.additionalCharges = formatDecimalNumber(
        additionalCharges,
        2
      );

      const total: any =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges);

      existingFormData.marginType =
        activeMarginType == '$' ? MARGIN_TYPE.VALUE : MARGIN_TYPE.PERCENTAGE;

      let margin: any =
        existingFormData.margin >= 0 ? existingFormData.margin : 0;
      let marginUnit: any =
        existingFormData.marginUnit >= 0 ? existingFormData.marginUnit : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 && marginUnit > 0
            ? formatDecimalNumber(
                (total / 100) * parseFloat(marginUnit.toString()),
                2
              )
            : 0;
      } else {
        marginUnit =
          total > 0 && margin > 0
            ? formatDecimalNumber(
                (100 * parseFloat(margin.toString())) / total,
                2
              )
            : 0;
      }

      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'totalCharge') {
        if (type === 'blur') {
          existingFormData[name] = parseValue
            ? formatDecimalNumber(parseValue, 2)
            : 0;
        }
      }

      if (name === 'finalCharge') {
        if (type === 'blur') {
          let finalCharge =
            parseValue < total
              ? formatDecimalNumber(total, 2)
              : formatDecimalNumber(parseValue, 2);

          existingFormData[name] = finalCharge > 0 ? finalCharge : '';
        }
        let newMargin = parseValue - total;

        existingFormData.margin =
          parseValue < total
            ? 0
            : newMargin
            ? formatDecimalNumber(newMargin, 2)
            : newMargin;
      } else {
        if (parseFloat(existingFormData.margin) >= 0) {
          existingFormData.finalCharge = formatDecimalNumber(
            parseFloat(total) + parseFloat(existingFormData.margin),
            2
          );
        }
      }

      if (name === 'margin' && parseValue === 0) {
        existingFormData.finalCharge = formatDecimalNumber(total, 2);
      }

      setFormData((old: any) => ({ ...old, [id]: existingFormData }));
    };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => (prevType === '$' ? '%' : '$'));
  };

  const handleSubmit = async () => {
    setIsPrimaryBtnClicked(true);
    let id = carrierRates[0]?.id;
    const existingFormData: any = {
      ...formData[id],
    };

    existingFormData.margin =
      existingFormData.margin > 0 ? existingFormData.margin : 0;
    existingFormData.marginUnit =
      existingFormData.marginUnit > 0 ? existingFormData.marginUnit : 0;
    existingFormData.additionalCharges =
      existingFormData.additionalCharges > 0
        ? existingFormData.additionalCharges
        : 0;
    existingFormData.totalCharge =
      existingFormData.totalCharge > 0 ? existingFormData.totalCharge : 0;
    existingFormData.finalCharge =
      existingFormData.finalCharge > 0 ? existingFormData.finalCharge : 0;

    formData[id] = existingFormData;

    try {
      if (action.mode === 'create') {
        await activeQuote(quoteID, {
          carrierPrices: formData,
          currency,
        });
        setIsRefresh(true);
        handleClose(true);
      } else if (action.mode === 'update') {
        let carrierId = selectedCarrierRates?.[0]?.id;

        updatePDF(carrierId, { ...formData[carrierId], currency })
          .then(() => {
            handleClose();
            setIsRefresh(true);
            WalToast.success('Price updated successfully');
          })
          .finally(() => {
            setIsRefresh(true);
            handleClose(false);
          })
          .catch(console.error);
      }
    } catch (error) {
      handleClose(false);
      setIsRefresh(true);
    }
  };

  const getCarrierProvider = (carrier: any): string =>
    carrier.isFreightcom || carrier.isFreightcom === 1
      ? 'Freightcom'
      : carrier.isBanyan || carrier.isBanyan === 1
      ? 'Banyan'
      : 'Direct';

  const carrierRate = carrierRates[0] || {};
  let provider = null;

  if (carrierRate.carrierId) {
    provider = getCarrierProvider(carrierRate);
  }

  const getBusinessDays = (startDate: Date, endDate: Date) => {
    let count = 0;
    const oneDay = 24 * 60 * 60 * 1000;

    for (
      let date = moment(startDate).toDate();
      date <= endDate;
      date.setTime(date.getTime() + oneDay)
    ) {
      const dayOfWeek = date.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return count;
  };

  return (
    <CommonModal
      title={
        action.mode === 'update' ? 'Modify Pricing' : 'Move to Active Quote'
      }
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose(false)}
      headerIcon={<PackageCheck />}
      size={`xl:max-w-[606px] md:max-w-[536px] max-w-[496px]`}
      isOverflow={true}
      modalClassName=""
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      primaryBtnDisabled={
        source === 'loadBoard'
          ? !isValid ||
            isPrimaryBtnClicked ||
            hasEmptyPhones ||
            !validForm.phoneNumbers.every((phoneValid: boolean) => phoneValid)
          : !isValid || isPrimaryBtnClicked
      }
      primaryBtnOnClick={handleSubmit}
      secondaryBtnOnClick={() => handleClose(false)}
    >
      <div className="w-full p-5 flex flex-col gap-y-4">
        {/* Warning message */}
        {notRatedServices !== 0 && (
          <div className="rounded-md bg-utilityError50 border border-utilityError300 px-3 py-2 flex gap-x-1 font-normal text-xs text-grayLight600 text-wrap">
            <InfoCircle className="text-textSecondary w-4 h-4 flex-none" />
            <div>
              <span className="text-grayLight900 font-semibold">Warning:</span>{' '}
              {notRatedServices} Additional Services not rated. Please contact
              carrier to verify feasibility of the shipment.
            </div>
          </div>
        )}

        {/* Carrier Info */}

        <div>
          <label className="form_label block mb-1.5">Carrier</label>
          <div className="rounded-lg border border-borderPrimary bg-white shadow-xs px-2.5 lg:py-[7px] py-2 flex gap-x-1.5 text-grayLight900 font-medium text-xs pointer-events-none items-center">
            {carrierRate.imageUrl && carrierRate.image ? (
              <div>
                <img
                  src={`${carrierRate.imageUrl}${carrierRate.image}`}
                  alt={carrierRate.name || 'Carrier Avatar'}
                  className="rounded-full w-5 h-5 border border-borderPrimary"
                  onError={(e) => {
                    e.currentTarget.src = avatar;
                  }}
                />
              </div>
            ) : (
              <div className="w-[18px] h-[18px] leading-[18px] bg-primary text-[8px] rounded-full font-bold text-white flex items-center justify-center uppercase flex-none">
                {getShortName(`${carrierRate.name}`)}
              </div>
            )}
            <p className="">{carrierRate.name || 'Unknown Carrier'}</p>
            <span
              className={`flex justify-center items-center !font-normal !text-grayLight600 !text-[11px]`}
            >
              {provider}
            </span>
          </div>
        </div>

        {!carrierRate?.carrierQuoteId && (
          <div className="flex flex-col">
            {source === 'loadBoard' ? (
              <AddQuickQuotePhoneNumber
                formData={formData}
                setFormData={setFormData}
                validForm={validForm}
                setValidForm={setValidForm}
                carrierId={carrierRate.id}
                setHasEmptyPhones={setHasEmptyPhones}
              />
            ) : (
              <>
                <label className="form_label block mb-1.5">
                  {' '}
                  Contact Number
                </label>

                <PhoneInput
                  onNumberChange={() => {}}
                  errors={false}
                  name="contactPhone"
                  value={carrierRate?.contactPhone ?? ''}
                  disabled={true}
                />
              </>
            )}
          </div>
        )}

        <div className="flex w-full gap-2">
          <InputText
            label="Projected Delivery"
            inputName="projectedDeliveryDate"
            placeholder="Projected Delivery"
            labelClassName="block mb-1.5"
            value={
              carrierRate.projectedDeliveryDate
                ? (() => {
                    const date = new Date(carrierRate.projectedDeliveryDate);
                    const day = date.getDate();
                    const month = date.toLocaleString('default', {
                      month: 'long',
                    });
                    const year = date.getFullYear();

                    return `${day} ${month}, ${year}`;
                  })()
                : 'N/A'
            }
            parentClassName="pointer-events-none flex-1"
          />
          <InputText
            label="Est. Transit Time"
            labelClassName="block mb-1.5"
            placeholder="Enter transit time"
            parentClassName="pointer-events-none flex-1"
            inputName="estimatedTransitTime"
            value={
              carrierRate.projectedDeliveryDate
                ? (() => {
                    const deliveryDate = moment(
                      carrierRate.projectedDeliveryDate
                    );
                    const today = moment();
                    const businessDays = deliveryDate
                      ? getBusinessDays(today.toDate(), deliveryDate.toDate())
                      : null;

                    return businessDays !== null
                      ? `${businessDays} Business ${
                          businessDays === 0 || businessDays === 1
                            ? 'Day'
                            : 'Days'
                        }`
                      : 'N/A';
                  })()
                : 'N/A'
            }
          />
        </div>

        {/* Base Quote */}
        {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <div key={selectedCarrierRate.name} className="w-full">
                <InputText
                  inputType="number"
                  inputName="totalCharge"
                  className="pl-8"
                  placeholder="Enter cost"
                  parentClassName=""
                  label="Carrier Cost"
                  labelClassName="block mb-1.5"
                  value={
                    formData[selectedCarrierRate.id]?.totalCharge
                      ? formData[selectedCarrierRate.id]?.totalCharge
                      : ''
                  }
                  onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                  onFocus={() => {
                    if (formData[selectedCarrierRate.id]?.totalCharge === 0) {
                      setFormData((prevFormData: any) => ({
                        ...prevFormData,
                        [selectedCarrierRate.id]: {
                          ...prevFormData[selectedCarrierRate.id],
                          totalCharge: '',
                        },
                      }));
                      setIsValid(false);
                    }
                  }}
                  onBlur={() => {
                    if (formData[selectedCarrierRate.id]?.totalCharge === '') {
                      setFormData((prevFormData: any) => ({
                        ...prevFormData,
                        [selectedCarrierRate.id]: {
                          ...prevFormData[selectedCarrierRate.id],
                          totalCharge: 0,
                        },
                      }));
                    }
                  }}
                  shouldFormatNumber={true}
                  icon={<CurrencyDollar className="input-currency-sign" />}
                />
              </div>
            ))}
          </>
        )}

        {formData?.[carrierRate?.id]?.services?.length > 0 && (
          <div>
            <div className="flex flex-col gap-3">
              {formData?.[carrierRate?.id]?.services?.map(
                (nonQuoteService: any, index: any) =>
                  Object.entries(nonQuoteService).map(
                    ([slug, service]: any) => (
                      <div className="flex w-full gap-x-2" key={slug}>
                        <>
                          {!service.isOther ? (
                            <SelectBox
                              label={`${
                                index === 0 ? 'Additional Charges' : ''
                              }`}
                              name={slug}
                              parentClassName="flex-1"
                              labelClassName="form_label block mb-1.5"
                              id={`additional_charge-${index}-charge`}
                              size="sm"
                              placeholder="Select Additional Charge"
                              noOptionMessage="No Additional Charge Found"
                              isSearchable={true}
                              className="form_control"
                              isClearable={true}
                              options={servicesList}
                              onChangeFunc={(e: any) => {
                                setFormData((old: any) => {
                                  const updatedService = old.services[index];

                                  const updatedServiceObj = {
                                    [e?.value]: {
                                      ...updatedService[slug],
                                      serviceId: e?.id,
                                      isOther:
                                        e?.value === 'other' ? true : false,
                                    },
                                  };
                                  const updatedServices = [
                                    ...old.services.slice(0, index),
                                    updatedServiceObj,
                                    ...old.services.slice(index + 1),
                                  ];

                                  return {
                                    ...old,
                                    services: updatedServices,
                                  };
                                });
                              }}
                              value={servicesList?.find(
                                (e: any) => e?.value === slug
                              )}
                              isDisabled
                            />
                          ) : (
                            <InputText
                              label={`${
                                index === 0 ? 'Additional Charges' : ''
                              }`}
                              inputName={`additional_charge.${index}.otherCharge`}
                              placeholder="Enter Additional Charge"
                              className="w-full"
                              value={service?.name || ''}
                              onChangeFunc={(e) => {
                                setFormData((old: any) => ({
                                  ...old,
                                  services: old.services.map(
                                    (serviceItem: any, idx: any) =>
                                      idx === index
                                        ? {
                                            ...serviceItem,
                                            [slug]: {
                                              ...serviceItem[slug],
                                              name: e?.target?.value,
                                            },
                                          }
                                        : serviceItem
                                  ),
                                }));
                              }}
                              labelClassName="block mb-1.5"
                              parentClassName="flex-1"
                              isClearable
                            />
                          )}
                          <TooltipCmp
                            message={
                              !service.value ||
                              service.value === 0 ||
                              service.value === '0' ||
                              service.value === '0.00'
                                ? `Carrier did not provide a rate for ${service.name}, for this additional service. Please validate feasibility and custom pricing.`
                                : null
                            }
                          >
                            <InputText
                              inputName={slug}
                              label={`${index === 0 ? 'Price' : ''}`}
                              labelClassName="block mb-1.5"
                              className="pl-8"
                              parentClassName="[&:not(:last-child)]:mb-4"
                              value={service.value}
                              icon={
                                <CurrencyDollar className="input-currency-sign" />
                              }
                              onChangeFunc={handleInputChange(
                                carrierRate.id,
                                true
                              )}
                              shouldFormatNumber={true}
                              onFocus={() => {
                                setFormData((old: any) => ({
                                  ...old,
                                  [carrierRate?.id]: {
                                    ...old?.[carrierRate?.id],
                                    services: old?.[
                                      carrierRate?.id
                                    ]?.services.map(
                                      (serviceItem: any, idx: any) =>
                                        idx === index
                                          ? {
                                              ...serviceItem,
                                              [slug]: {
                                                ...serviceItem[slug],
                                                value: '',
                                              },
                                            }
                                          : serviceItem
                                    ),
                                  },
                                }));
                              }}
                            />
                          </TooltipCmp>
                        </>
                      </div>
                    )
                  )
              )}
            </div>
          </div>
        )}

        {/* Margin */}
        {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <div key={selectedCarrierRate.name} className="">
                <label className="form_label block mb-1.5">Margin</label>
                <div className="form_control p-0 relative !h-auto">
                  {activeMarginType === '$' ? (
                    <InputText
                      inputName="margin"
                      inputType="number"
                      placeholder="Enter margin"
                      className="!border-0 !h-auto !pl-8 !pr-20"
                      labelClassName="block mb-1.5"
                      value={
                        formData[selectedCarrierRate.id]?.margin || undefined
                      }
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      shouldFormatNumber={true}
                      onFocus={() => {
                        if (
                          Number(formData[selectedCarrierRate.id]?.margin) === 0
                        ) {
                          setFormData((prevFormData: any) => ({
                            ...prevFormData,
                            [selectedCarrierRate.id]: {
                              ...prevFormData[selectedCarrierRate.id],
                              margin: '',
                            },
                          }));
                          setIsValid(false);
                        }
                      }}
                      icon={<CurrencyDollar className="input-currency-sign" />}
                    />
                  ) : (
                    <InputText
                      inputName="marginUnit"
                      inputType="text"
                      placeholder="Enter margin"
                      className="!border-0 !h-auto !pl-8 !pr-20"
                      labelClassName="block mb-1.5"
                      value={
                        formData[selectedCarrierRate.id]?.marginUnit ||
                        undefined
                      }
                      onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                      shouldFormatNumber={true}
                      onFocus={() => {
                        if (
                          Number(
                            formData[selectedCarrierRate.id]?.marginUnit
                          ) == 0
                        ) {
                          setFormData((prevFormData: any) => ({
                            ...prevFormData,
                            [selectedCarrierRate.id]: {
                              ...prevFormData[selectedCarrierRate.id],
                              marginUnit: '',
                            },
                          }));
                          setIsValid(false);
                        }
                      }}
                      icon={<Percent02 className="input-currency-sign" />}
                    />
                  )}

                  <TabButton
                    tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                    parentClassName="!bg-gray50 !p-[3px]"
                    className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                    activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                    tabArray={tabArray}
                    isActive={activeMarginType}
                    isTab={true}
                    handleOnClick={handleTabChange}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {/* Total Price */}
        {carrierRates.length > 0 && (
          <>
            {carrierRates.map((selectedCarrierRate: any) => (
              <InputText
                inputType="number"
                label="Total Sale Price"
                inputName="finalCharge"
                placeholder="Enter sale price"
                key={selectedCarrierRate.id}
                className="pl-8"
                labelClassName="block mb-1.5"
                parentClassName=""
                required={true}
                value={
                  formData[selectedCarrierRate.id]?.finalCharge
                    ? formData[selectedCarrierRate.id]?.finalCharge
                    : ''
                }
                onChangeFunc={handleInputChange(selectedCarrierRate.id)}
                shouldFormatNumber={true}
                icon={<CurrencyDollar className="input-currency-sign" />}
                onFocus={() => {
                  if (
                    Number(formData[selectedCarrierRate.id]?.finalCharge) === 0
                  ) {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      [selectedCarrierRate.id]: {
                        ...prevFormData[selectedCarrierRate.id],
                        finalCharge: '',
                      },
                    }));
                    setIsValid(false);
                  }
                }}
                onBlur={() => {
                  if (formData[selectedCarrierRate.id]?.finalCharge === '') {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      [selectedCarrierRate.id]: {
                        ...prevFormData[selectedCarrierRate.id],
                        finalCharge: 0,
                      },
                    }));
                  }
                }}
              />
            ))}
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default MoveToActive;
