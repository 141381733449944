import { User01 } from '@untitled-ui/icons-react/build/cjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import TableCmp from 'src/components/TableCmp';
import { AuthContext } from 'src/context/AuthContext';
import {
  fetchLeaderBoardActivities,
  fetchOngoingEventDetail,
  getLeaderBoardData,
} from 'src/services/SalesDashboard';
import {
  confettiAnimation,
  useRolePermission,
} from 'src/utils/CommonFunctions';

import LeaderBoardMetaDetails from './LeaderBoardMetaDetails';
import LeaderboardMyNewCustomers from './LeaderboardMyNewCustomers';
import LeaderboardNewCustomersManagement from './LeaderboardNewCustomersManagement';
import LeaderRankLoaderRow from './LeaderRankLoaderRow';
import LeaderRankRow from './LeaderRankRow';
import WinnerBoard from './WinnerBoard';

interface LeaderBoardProps {
  id: number;
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  totalCustomers: number;
  totalPoints: number;
  dayPoints: number;
  daysInLead: number;
  profit: string;
  ranking: number;
  leaderboardEventId: number;
  leaderboardDate: any;
  createdAt: any;
  updatedAt: any;
  rankingChange: number;
  profileImage: string;
}

const initParams = {
  sortType: 'asc',
  sortField: 'ranking',
};

const LeaderBoard = () => {
  const { hasRoleV2 } = useRolePermission();
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );
  const abortControllerRef = useRef<AbortController | null>(null);
  const [params, setParams] = useState(initParams);
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingLeaderBoard, setIsLoadingLeaderBoard] =
    useState<boolean>(true);
  const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardProps[]>(
    []
  );
  const [loginUserRank, setLoginUserRank] = useState<number>(0);
  const [totalUserCount, setTotalUserCount] = useState<number>(0);
  const [leaderBoardTopThreeData, setLeaderBoardTopThreeData] = useState<
    LeaderBoardProps[]
  >([]);
  const [eventDetail, setEventDetail] = useState<any>({});
  const [leaderBoardActivities, setLeaderBoardActivities] = useState<any>([]);
  const [badgeWinner, setBadgeWinner] = useState<any>('');
  const [isShowNewCustomersLeaderboard, setIsShowNewCustomersLeaderboard] =
    useState(false);
  const [isShowMyNewCustomers, setIsShowMyNewCustomers] = useState(false);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const setLeaderBoardResponse = (response: any) => {
    let loginUserRanking: LeaderBoardProps = response?.topThree.find(
      (obj: any) => obj.userId == currentUser.id
    );

    if (!loginUserRanking) {
      loginUserRanking = response?.restOfData.find(
        (obj: any) => obj.userId == currentUser.id
      );
    }

    if (loginUserRanking && loginUserRanking.ranking) {
      setLoginUserRank(loginUserRanking.ranking);
    }

    setTotalUserCount(response?.restOfData.length);
    setLeaderBoardTopThreeData(response?.topThree);
    setLeaderBoardData(response?.restOfData);
    setBadgeWinner(response?.badgeWinner.userId);
  };

  const fetchLeaderBoardData = (loading = true) => {
    let signal;

    if (loading) {
      setIsLoadingLeaderBoard(true);
    }

    cancelApiCall();
    abortControllerRef.current = new AbortController();
    signal = abortControllerRef.current.signal;

    getLeaderBoardData(params, signal)
      .then((response: any) => {
        const isUserMovedFirst = response.restOfData.find(
          (user: any) => user.stageRanking == 1 && user.rankingChange >= 1
        );

        if (
          !loading &&
          isUserMovedFirst &&
          isUserMovedFirst.id == currentUser.id
        ) {
          confettiAnimation(1000);
        }
        setLeaderBoardResponse(response);
        setIsLoadingLeaderBoard(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') return;
        setIsLoadingLeaderBoard(false);
      });
  };

  useEffect(() => {
    setLeaderBoardData([]);
    fetchLeaderBoardData();
  }, [params]);

  const handleNewActivity = useCallback(
    (newActivity: any) => {
      if (
        !leaderBoardActivities.some(
          (activity: any) => activity.id === newActivity.id
        ) &&
        newActivity?.action !== 'delete'
      ) {
        setLeaderBoardActivities((prevActivities: any) => [
          newActivity,
          ...prevActivities,
        ]);
        fetchLeaderBoardData(false);
      } else {
        const updatedActivity = leaderBoardActivities.filter(
          (act: any) => act.id !== newActivity.id
        );
        setLeaderBoardActivities(updatedActivity);
      }
    },
    [leaderBoardActivities]
  );

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.on('leaderboard-new-activity', (newActivity: any) => {
        console.log('got newActivity through socket', newActivity.id);
        handleNewActivity(newActivity);
      });
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('leaderboard-new-activity');
      }
    };
  }, [eventDetail, socketIO, isSocketConnected]);

  const getAllLeaderBoardData = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const [
      ongoingEventDetailResponse,
      activitiesResponse,
      leaderBoardResponse,
    ]: [any, any, any] = await Promise.all([
      fetchOngoingEventDetail({}, signal),
      fetchLeaderBoardActivities({}, signal),
      getLeaderBoardData(params, signal),
    ]);

    setEventDetail(ongoingEventDetailResponse.data);
    setLeaderBoardActivities(activitiesResponse.data);
    setLeaderBoardResponse(leaderBoardResponse);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllLeaderBoardData();
  }, []);

  const rankHeadCells = useMemo(
    () => [
      {
        id: 'ranking',
        name: 'Rank',
        sortable: true,
      },
      {
        id: 'rankingChange',
        name: 'Change',
        sortable: true,
      },
      {
        id: 'firstName',
        name: 'Sales Representative',
        sortable: true,
      },
      {
        id: 'totalCustomers',
        name: 'New Customers',
        sortable: true,
      },
      {
        id: 'daysInLead',
        name: 'Days In Lead',
        sortable: true,
      },
      {
        id: 'profitBooked',
        name: 'GP Booked',
        sortable: true,
      },
      {
        id: 'profitDelivered',
        name: 'GP Delivered',
        sortable: true,
      },
      {
        id: 'totalPoints',
        name: 'Total Points',
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <PageSectionLayout
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pt-0 !pb-0 relative "
        header={
          <Header
            topContent={<></>}
            title="Incentive"
            desc="View and manage your incentive here."
            rightSideContent={
              <>
                {/* <ButtonCmp
                  className="btn-outline-primary"
                  icon={<Settings03 className="w-4 h-4" />}
                >
                  Customize Incentive
                </ButtonCmp> */}

                {!isLoading &&
                  eventDetail &&
                  (hasRoleV2('admin') || hasRoleV2('manager')) && (
                    <ButtonCmp
                      className="btn-outline-primary"
                      icon={<User01 className="w-4 h-4" />}
                      onClick={() => {
                        setIsShowNewCustomersLeaderboard(true);
                      }}
                    >
                      New Customer Management
                    </ButtonCmp>
                  )}

                {!isLoading && eventDetail && hasRoleV2('sales') && (
                  <ButtonCmp
                    className="btn-outline-primary"
                    icon={<User01 className="w-4 h-4" />}
                    onClick={() => {
                      setIsShowMyNewCustomers(true);
                    }}
                  >
                    New Customer
                  </ButtonCmp>
                )}
              </>
            }
          />
        }
      >
        {!isLoading && eventDetail ? (
          <div className="flex h-full">
            <div className="flex-1 pr-4 overflow-auto custom-scrollbar-v2 py-5 xxl:gap-4 gap-3 flex flex-col">
              <WinnerBoard
                leaderBoardTopThreeData={leaderBoardTopThreeData}
                loginUserRank={loginUserRank}
                totalUserCount={totalUserCount}
                eventDetail={eventDetail}
                isLoading={isLoading}
              />

              <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                <div className="w-full ">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                    <TableCmp
                      headCells={rankHeadCells}
                      tableDataArr={leaderBoardData}
                      isTableDataLoading={isLoadingLeaderBoard}
                      TableRowCmp={LeaderRankRow}
                      TableLoaderRowCmp={LeaderRankLoaderRow}
                      tableRowCmpProps={{
                        badgeWinner: badgeWinner,
                      }}
                      params={params}
                      setParams={setParams}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-4 py-5 xlm:w-[380px] xl:w-[360px] w-[320px] border-l border-utilityGray200 flex flex-col gap-y-4">
              <LeaderBoardMetaDetails
                isLoading={isLoading}
                leaderBoardEventDetail={eventDetail}
                leaderBoardActivities={leaderBoardActivities}
              />
            </div>
          </div>
        ) : isLoading ? (
          <div className="flex h-full">
            <div className="flex-1 pr-4 overflow-auto custom-scrollbar-v2 py-5 xxl:gap-4 gap-3 flex flex-col">
              <WinnerBoard
                leaderBoardTopThreeData={[]}
                loginUserRank={undefined}
                totalUserCount={undefined}
                eventDetail={undefined}
                isLoading={true}
              />

              <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
                <div className="w-full ">
                  <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-title-header table-without-pagination">
                    <TableCmp
                      headCells={rankHeadCells}
                      tableDataArr={[]}
                      isTableDataLoading={true}
                      TableRowCmp={LeaderRankRow}
                      TableLoaderRowCmp={LeaderRankLoaderRow}
                      params={params}
                      numberOfSkeletonRows={10}
                      setParams={setParams}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-4 py-5 xlm:w-[380px] xl:w-[360px] w-[320px] border-l border-utilityGray200 flex flex-col gap-y-4">
              <LeaderBoardMetaDetails isLoading={true} />
            </div>
          </div>
        ) : (
          <div className="block h-full w-full">
            <NotFoundUI
              title="No Leader Board event found"
              desc="There are no Leader Board event created."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          </div>
        )}
      </PageSectionLayout>

      {isShowNewCustomersLeaderboard && (
        <LeaderboardNewCustomersManagement
          leaderBoardEventDetail={eventDetail}
          handleClose={(status: boolean) => {
            if (status) {
              // handleRefreshingLeaderboard();
            }
            setIsShowNewCustomersLeaderboard(false);
          }}
        />
      )}

      {isShowMyNewCustomers && (
        <LeaderboardMyNewCustomers
          leaderBoardEventDetail={eventDetail}
          handleClose={() => {
            setIsShowMyNewCustomers(false);
          }}
        />
      )}
    </>
  );
};

export default LeaderBoard;
