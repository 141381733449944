import { search } from 'node-emoji';
import React, { useState } from 'react';
import InputText from 'src/components/InputText/InputText';

const EmojiPicker = ({ onSelect }: { onSelect: (emoji: string) => void }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredEmojis = search(searchTerm).slice(0, 50);

  return (
    <div className="rounded-[10px] border border-utilityGray200 shadow-md bg-white my-1.5 p-3 z-[1] w-[415px] left-0 absolute max-h-[80vh] flex flex-col space-y-1.5">
      <InputText
        placeholder="Search emojis..."
        onChangeFunc={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
      />
      <div className="flex flex-wrap gap-x-3 gap-y-2">
        {filteredEmojis.map(({ emoji, name }) => (
          <button
            key={name}
            onClick={() => onSelect(emoji)}
            className="text-lg"
          >
            {emoji}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EmojiPicker;
