import React from 'react';

const FreightStatisticsDetailModalLoadingRow = () => (
  <>
    <td className="px-5 py-4 w-[19%] min-w-[19%] max-w-px">
      <div className="flex items-center gap-x-3 ">
        <div className="flex-none relative">
          <div className="w-8 h-8 rounded-md border border-utilityGray200 bg-utilityGray100 custom-loading">
            wa
          </div>
        </div>
        <p className="truncate text-sm font-semibold w-fit custom-loading min-h-8 flex items-center">
          Outbound from Canada
        </p>
      </div>
    </td>

    <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
      <span className="custom-loading">7895</span>
    </td>
    <td className="px-5 py-4 w-[16%] min-w-[16%] max-w-px">
      <span className="custom-loading">256</span>
    </td>
    <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
      <span className="custom-loading">$485.98</span>
    </td>
    <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
      <span className="custom-loading">$485.98</span>
    </td>
    <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
      <span className="custom-loading">$485.98</span>
    </td>
  </>
);

export default FreightStatisticsDetailModalLoadingRow;
