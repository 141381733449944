import { ArrowNarrowLeft } from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
// import ButtonCmp from 'src/components/ButtonCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import { PATH } from 'src/constants/path';
import { getRadarOrderDetail } from 'src/services/RadarService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

import RadarChatBlock from './RadarChatBlock';
import RadarMapAboveComponent from './RadarMapAboveComponent';
import RadarOrderStatus from './RadarOrderStatus';

const RadarOrderDetail = () => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const { id } = useParams();
  const navigate = useNavigate();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [order, setOrder] = useState<any>(null);
  const [activeLegData, setActiveLegData] = useState<any>(null);
  const [isChatBlockOpen, setIsChatBlockOpen] = useState(false);

  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-90, 50],
        zoom: 3,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
        projection: 'mercator',
        dragRotate: false,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    initializeMap();
  }, []);

  useEffect(() => {
    if (order?.orderLegs && order?.orderLegs?.length > 0) {
      const index = order?.orderLegs?.findIndex(
        (item: any) => item.isActive === 1
      );

      if (index === -1) {
        setActiveLegData(null);
      } else {
        setActiveLegData(order?.orderLegs?.[index]);
      }
    }
  }, [order?.orderLegs]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      setOrder(null);

      // Cancel the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the current request
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      getRadarOrderDetail(Number(id), signal)
        .then(async (response: any) => {
          setOrder(response.data);
        })
        .catch((e) => {
          console.log(e);

          if (e.code === 'ERR_CANCELED') {
            return;
          } else {
            navigate(`${PATH.RADAR}`);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const createSVGMarker = () => {
    const svgElement: any = document.createElement('div');
    svgElement.className = 'parcel-arrow';
    svgElement.innerHTML = `
     <div class="w-[12px] -mt-px -mr-px after:content-[''] after:border after:border-[#17B26A] after:w-[200%] after:h-[200%] after:rounded-full after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:absolute">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" class="w-full rotate-[60deg]">
            <path d="M12.6657 2.67916C12.2439 2.4686 11.7476 2.4686 11.3258 2.67916C10.9525 2.86552 10.7595 3.19367 10.6676 3.36083C10.5675 3.543 10.4649 3.77692 10.362 4.01162L3.05073 20.6813C2.93566 20.9436 2.82421 21.1976 2.7529 21.4084C2.69129 21.5905 2.56638 21.9837 2.70011 22.4066C2.84758 22.873 3.21319 23.2382 3.67975 23.3851C4.10282 23.5184 4.49584 23.393 4.67791 23.3312C4.88863 23.2596 5.14253 23.1478 5.4047 23.0324L11.9957 20.1324L18.5868 23.0325C18.8489 23.1478 19.1028 23.2596 19.3135 23.3312C19.4956 23.393 19.8886 23.5184 20.3117 23.3851C20.7783 23.2382 21.1439 22.873 21.2913 22.4066C21.4251 21.9837 21.3002 21.5905 21.2385 21.4084C21.1672 21.1976 21.0558 20.9436 20.9407 20.6813L13.6295 4.01167C13.5266 3.77695 13.424 3.54301 13.3238 3.36083C13.2319 3.19367 13.039 2.86552 12.6657 2.67916Z" fill="white"/>
          </svg>
        </div>`;

    return svgElement;
  };

  const getDrivingRouteCoordinates = async (
    startPoint: { lat: number; lng: number } = { lat: 10, lng: 10 },
    endPoint: { lat: number; lng: number } = { lat: 10, lng: 10 }
  ) => {
    try {
      // Cancel the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      // Create a new abort controller for the current request
      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current.signal;
      const response = await fetch(
        `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint?.lng},${startPoint?.lat};${endPoint?.lng},${endPoint?.lat}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
        { method: 'GET', signal }
      );
      const data = await response.json();

      if (!data.routes || data.routes.length === 0) {
        throw new Error('No route found');
      }

      const routeCoordinates = data.routes[0].geometry.coordinates;

      return routeCoordinates;
    } catch (error) {
      console.error('Error fetching route coordinates:', error);

      return [];
    }
  };

  const getOrderLegCoordinates = async (orderLegs: any) => {
    const coordinates: any = [];

    for (let i = 0; i < orderLegs?.length; i++) {
      const orderLeg: any = orderLegs[i];
      const routeCoordinates = await getDrivingRouteCoordinates(
        {
          lat: orderLeg.shipperLatitude,
          lng: orderLeg.shipperLongitude,
        },
        {
          lat: orderLeg.consigneeLatitude,
          lng: orderLeg.consigneeLongitude,
        }
      );

      if (routeCoordinates.length > 0) {
        coordinates.push(...routeCoordinates); // Merge route coordinates
      }
    }

    return coordinates;
  };

  const removeAllMarkersFromDOM = () => {
    const markerElements = document.querySelectorAll('.mapboxgl-marker');
    markerElements.forEach((marker) => marker.remove());
  };

  const removeAllMarkers = () => {
    if (mapRef.current?.getLayer('route-line')) {
      mapRef.current?.removeLayer('route-line');
    }

    if (mapRef.current?.getSource('route')) {
      mapRef.current?.removeSource('route');
    }
  };

  const setOrderDetailMarker = async () => {
    const coordinates: any = await getOrderLegCoordinates(order?.orderLegs);

    if (coordinates?.length > 0) {
      removeAllMarkers();
      removeAllMarkersFromDOM();
      mapRef.current?.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
          properties: {},
        },
      });

      mapRef.current?.addLayer({
        id: 'route-line',
        type: 'line',
        source: 'route',
        layout: {},
        paint: {
          'line-color': '#4264fb',
          'line-width': 4,
        },
      });
      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

      if (order?.orderLocationUpdate || order?.shipperAddress) {
        const currentLocation = order?.orderLocationUpdate
          ? order?.orderLocationUpdate
          : order?.shipperAddress;
        const svgMarker = createSVGMarker();
        new mapboxgl.Marker(svgMarker)
          .setLngLat([currentLocation.longitude, currentLocation.latitude])
          .addTo(mapRef.current!);
        mapRef?.current?.setCenter([
          currentLocation.longitude,
          currentLocation.latitude,
        ]);
        mapRef?.current?.setZoom(3);
      }

      coordinates.forEach((coord: any) => bounds.extend(coord));
      mapRef?.current?.fitBounds(bounds, {
        padding: { top: 50, bottom: 50, left: 50, right: 180 },
      });
    }
  };

  useEffect(() => {
    if (order) {
      setOrderDetailMarker();
    }
  }, [order?.orderLocationUpdate]);

  return (
    <PageSectionLayout
      header={
        <Header
          title={
            <div className="flex items-center gap-x-2.5">
              <>
                Radar:
                <p
                  className={`text-primary font-semibold ${
                    isLoading || !order ? 'custom-loading' : 'cursor-pointer'
                  }`}
                  onClick={() => {
                    if (order?.id) {
                      navigate(`${PATH.ORDER_VIEW}/${order?.id}`);
                    }
                  }}
                >
                  {order?.orderId ? `#${order?.orderId}` : '#WAL-jdku-392'}
                </p>
                {(order?.orderType || isLoading) && (
                  <BadgeCmp
                    style="modern"
                    type="success"
                    mainClassName={`${
                      isLoading || !order ? 'custom-loading' : ''
                    }`}
                  >
                    {isLoading ? 'LTL' : order?.orderType?.toUpperCase()}
                  </BadgeCmp>
                )}
              </>
            </div>
          }
          desc="Effortlessly monitor your orders from pickup to delivery."
          // rightSideContent={
          //   <>
          //     <ButtonCmp
          //       className="btn_secondary_black lg:!px-[9px] !px-2"
          //       onClick={() => {
          //         navigate(`${PATH.RADAR}`);
          //       }}
          //     >
          //       <XClose className="w-4 h-4" />
          //     </ButtonCmp>
          //   </>
          // }
          mainContainerClassName="!border-0"
        />
      }
      contentClassName="h-[calc(100vh_-_97px)] !p-0 border-t border-borderPrimary"
    >
      <div className="w-full flex h-full">
        <div className="w-[50%] flex flex-col gap-y-4 [&>div]:pr-7 [&>div]:pl-5 px-px pt-4 overflow-auto custom-scrollbar-v2 mb-5">
          <>
            <div className="space-y-4 flex flex-col overflow-auto flex-1">
              <div
                className="flex gap-x-2 items-center cursor-pointer w-fit"
                onClick={() => {
                  navigate(`${PATH.RADAR}`);
                }}
              >
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  icon={
                    <ArrowNarrowLeft className="w-4 h-4 text-grayLight600" />
                  }
                >
                  <></>
                </ButtonCmp>
                <p className="text-grayLight900 text-sm font-medium">Back</p>
              </div>
              <ul
                className={`radar-detail-box flex flex-wrap ${
                  isChatBlockOpen ? 'hidden' : ''
                }`}
              >
                <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
                  <h6
                    className={`text-textSecondary text-xs font-semibold ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    Order ID
                  </h6>
                  <p
                    className={`text-gray500 text-xs leading-[1.5] font-normal ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {order?.orderId ? `#${order?.orderId}` : '-'}
                  </p>
                </li>
                <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
                  <h6
                    className={`text-textSecondary text-xs font-semibold ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    Order Created
                  </h6>
                  <p
                    className={`text-gray500 text-xs leading-[1.5] font-normal ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: order?.createdAt
                        ? getDateWithSuffixFormat(
                            getFormattedDate(
                              order?.createdAt,
                              `Do MMMM YYYY`,
                              true,
                              true
                            )
                          )
                        : '-',
                    }}
                  />
                </li>
                <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
                  <h6
                    className={`text-textSecondary text-xs font-semibold ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    Sales Rep.
                  </h6>
                  <div className="flex items-center gap-x-1">
                    {isLoading ? (
                      <div
                        className={`w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                      >
                        mark zukerberg
                      </div>
                    ) : (
                      order?.salesRepFirstName && (
                        <>
                          {order?.salesImage ? (
                            <img
                              src={order?.salesImageUrl + order?.salesImage}
                              className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                            />
                          ) : (
                            <div className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[7px]">
                              {getShortName(
                                `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                              )}
                            </div>
                          )}
                        </>
                      )
                    )}
                    <p
                      className={`text-gray500 text-xs leading-[1.5] font-normal truncate ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      {order?.salesRepFirstName
                        ? `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                        : '-'}
                    </p>
                  </div>
                </li>
                <li className="px-4 py-3 space-y-1 flex-1 w-1/4">
                  <h6
                    className={`text-textSecondary text-xs font-semibold ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    Customer
                  </h6>
                  <p
                    className={`text-gray500 text-xs leading-[1.5] font-normal truncate ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                  >
                    {order?.customerName ?? '-'}
                  </p>
                </li>
              </ul>
              <RadarOrderStatus
                orderLegList={order?.orderLegs ? order?.orderLegs : []}
                isLoading={isLoading}
                setOrder={setOrder}
                order={order}
                activeLegData={activeLegData}
                isChatBlockOpen={isChatBlockOpen}
                setActiveLegData={setActiveLegData}
              />
              <RadarChatBlock
                order={order}
                setIsChatBlockOpen={setIsChatBlockOpen}
                isChatBlockOpen={isChatBlockOpen}
              />
            </div>
          </>
          {/* <OrderDetail
              setOrderId={setOrderId}
              orderId={orderId}
              order={order}
              setOrder={setOrder}
            /> */}
        </div>

        <div className="h-full flex-1 radar-mapbox relative border-borderPrimary border-l">
          <div
            id="map"
            ref={mapContainerRef}
            style={{ width: '100%', height: '100%' }}
          ></div>

          <RadarMapAboveComponent
            order={order}
            isLoading={isLoading}
            setOrder={setOrder}
            activeLegData={activeLegData}
          />
        </div>
      </div>
    </PageSectionLayout>
  );
};
export default RadarOrderDetail;
