/* eslint-disable max-lines-per-function */
import {
  XClose,
  Copy05,
  Edit05,
  DotsVertical,
  RefreshCcw05,
  HelpCircle,
  RefreshCcw04,
  Plus,
  RefreshCcw01,
  DownloadCloud02,
  Mail01,
  Route,
  ArrowLeft,
  Package,
} from '@untitled-ui/icons-react/build/cjs';
import { orderBy, minBy, debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PredictivePriceChart from 'src/app/QuotingHub/PredictivePricing/PredictivePriceChart';
import ReplyRequestModal from 'src/app/QuotingHub/QuotingRequest/MessageDetailSection/ReplyRequestModal';
import outlook from 'src/assets/img/outlook.svg';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import LiveTimer from 'src/components/LiveTimer';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  CARRIERS,
  CURRENCY,
  getBadgeLabelByQuoteStatus,
  getBadgeTypeByQuoteStatus,
  QUOTE_CLASSIFICATION,
  ROSE_ROCKET,
  STATUS,
} from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import { DirectCarrierRatesContext } from 'src/context/DirectCarrierRatesContext';
import { listCarrier } from 'src/services/CarrierService';
import { getPricingRate } from 'src/services/CommonService';
import { getTopCarriers } from 'src/services/GeographyService';
import { getQuoteEmailDetails } from 'src/services/QuoteEmailService';
import {
  createRate,
  getQuote,
  spotQuoteRequest,
  createPDF,
  checkBol,
  cancelQuote,
  sendPDF,
} from 'src/services/QuoteService';
import {
  getBadgeTypeByMinutes,
  loadGoogleMapsScript,
} from 'src/utils/CommonFunctions';
import { downloadPdf } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import defaultImage from '../../../../../assets/img/default-image.jpg';
import { scriptId } from '../../AddressDetails/Address.interface';
import AddQuickQuote from '../AddQuickQuote';
import AddQuoteDetail from '../AddQuoteDetail';
import AttachQuotePDFModal from '../AttachQuotePDFModal';
import CloseQuoteConfirm from '../CloseQuoteConfirm';
import FinalizeOrderModal from '../FinalizeOrderModal';
import LaneHistory from '../LaneHistory';
import OverviewRateGathering from '../OverviewRateGathering';
import { QuoteType } from '../PostToLoadBoard/LoadBoard.interface';
import LoadPostingCard from '../PostToLoadBoard/LoadPostingCard';
import MoveToActive from '../PostToLoadBoard/MoveToActive';
import PricingDetails from '../PricingDetails';
import QuotedLoading from '../QuotedLoading';
import QuotedRow from '../QuotedRow';
import ReqQuotedRow from '../ReqQuotedRow';
import ReqQuoteLoading from '../ReqQuoteLoading';
import SelectQuotesModal from '../SelectQuotesModal';
import SendQuoteToCustomerModal from '../SendQuoteToCustomerModal';
import SpotQuoteConfirmation from '../SpotQuoteConfirmation';
import WithQuoteLoading from '../WithQuoteLoading';
import WithRateQuote from '../WithRateQuote';

import ConvertOrderModal from './ConvertOrderModal';
import QuoteDetailTable from './QuoteDetailTable';
import QuoteEmailModal from './QuoteEmailModal';
import RefreshRatesModal from './RefreshRatesModal';

const initAction = {
  mode: '',
  pricing: false,
  model: false,
  pdf: false,
  closedReason: true,
  quoteDetail: false,
  addQuoteDetail: false,
  orderSuccessModel: false,
  pricingDetails: false,
  spotQuoteConfirmation: false,
  carrierOrderModel: false,
};

function containsWholeMatchForEquipmentType(str: string) {
  const strParts = str
    ? str.split(',').map((item) => item.trim().toLowerCase())
    : [];

  return ['van', 'dry van', 'reefer', 'straight truck'].some((item) =>
    strParts.includes(item.toLowerCase())
  );
}

const QuoteDetailPage = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { id } = useParams<{ id: any }>();
  const location = useLocation();
  const activeCarrierCount = location.state?.activeCarrierCount;
  const classification = location.state?.classification;
  const { googleKey, vehicleSizeOptions, isServiceLoading, servicesList } =
    useContext(BasicContext);
  const navigate = useNavigate();
  const { isCarrierRateFetched, setIsCarrierRateFetched } = useContext(
    DirectCarrierRatesContext
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isRefresh, setIsRefresh] = useState(true);
  const [predictivePriceData, setPredictivePriceData] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState<QuoteType | boolean>(false);
  const [isRateLoading, setIsRateLoading] = useState(false);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [dimensions, setDimensions] = useState<any[]>([]);
  const [totalUnit, setTotalUnit] = useState<number | string>();
  const [totalWeight, setTotalWeight] = useState<number | string>();
  const [services, setServices] = useState<any[]>([]);
  const [rates, setRates] = useState<any>({});
  let [carrierRates, setCarrierRates] = useState<any[]>([]);
  const [action, setAction] = useState(initAction);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const [selectedCarrierRatesModel, setSelectedCarrierRatesModel] =
    useState<any>([]);
  const [quickQuoteData, setQuickQuoteData] = useState<any>([]);
  const [isOrderBooked, setIsOrderBooked] = useState<boolean>(false);
  const [selectedCarrierId, setSelectedCarrierId] = useState<any>(null);

  let lastSelTabType;
  const localData = localStorage.getItem('last_sel_quote_tab');

  if (localData) {
    const lastSelQuoteTabObject = JSON.parse(localData);

    if (Object.hasOwn(lastSelQuoteTabObject, parseInt(id))) {
      lastSelTabType = lastSelQuoteTabObject[id];
    } else {
      lastSelTabType =
        classification === QUOTE_CLASSIFICATION.LTL
          ? 'with_rate'
          : 'predictive_pricing';
    }
  } else {
    lastSelTabType =
      classification === QUOTE_CLASSIFICATION.LTL
        ? 'with_rate'
        : 'predictive_pricing';
  }

  const carrierRatesRef = useRef(carrierRates);
  const [isQuoteModalOpen, setQuoteModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('english');
  const [isExpiredRefresh, setIsExpiredRefresh] = useState(false);
  const [selectedDisplayOptions, setSelectedDisplayOptions] = useState({
    displayServiceType: false,
    displayTransitTimes: false,
    displayCarrierNames: false,
  });
  const [orderBookingSuccessToast, setOrderBookingSuccessToast] =
    useState(false);
  const [orderBookingErrorToast, setOrderBookingErrorToast] = useState(false);
  const [quoteEmailDetail, setQuoteEmailDetail] = useState<any>(null);

  const handleLanguageSelect = (language: string) => {
    setSelectedLanguage(language);
  };

  const updateCarrierRates = (newCarrierRates: any) => {
    carrierRatesRef.current = newCarrierRates;
  };

  const [sortData, setSortData] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [activeQuoteParams, setActiveQuoteParams] = useState<{
    sortField: string;
    sortType: 'desc' | 'asc';
  }>({ sortField: 'finalCharge', sortType: 'asc' });

  const [colors, setColors] = useState<any>({});
  const [isGetRate, setIsGetRate] = useState(false);
  const [quote, setQuote] = useState<any>({});
  const [isFinish, setIsFinish] = useState(false);
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [quoteType, setQuoteType] = useState<QuoteType | boolean>(
    lastSelTabType ?? false
  );
  const [withRateList, setWithRateList] = useState<any[]>([]);
  const [quotedList, setQuotedList] = useState<any[]>([]);
  const [nonQuotedList, setNonQuotedList] = useState<any[]>([]);
  const [isAllCarrierRateFetched, setIsAllCarrierRateFetched] =
    useState<boolean>(true);
  const [carrierRateFetchStatus, setCarrierRateFetchStatus] = useState<any>({});
  const [carrierList, setCarrierList] = useState<any>([]);
  const { currency, setCurrency } = useContext(BasicContext);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isPdfGenerating, setIsPdfGenerating] = useState<boolean>(false);
  const [isShowSelectQuotesModal, setIsShowSelectQuotesModal] = useState(false);
  const [isShowAttachQuotePDFModal, setIsShowAttachQuotePDFModal] =
    useState(false);
  const [isQuotePdfAttached, setIsQuotePdfAttached] = useState<boolean>(true);
  const [selectedQuoteToGeneratePdfId, setSelectedQuoteToGeneratePdfId] =
    useState<any[]>([]);
  const [loadLinkPosts, setLoadLinkPosts] = useState<any[]>([]);
  const [loadLinkPostingDetail, setLoadLinkPostingDetail] = useState<
    any | undefined
  >(undefined);
  const [rateGatheringWithAi, setRateGatheringWithAi] = useState<
    any | undefined
  >(undefined);
  const [syncLiveData, setSyncLiveData] = useState<boolean>(false);
  const [isUpdateLoadPost, setIsUpdateLoadPost] = useState<boolean>(false);
  const [isCreateLoadPost, setIsCreateLoadPost] = useState<boolean>(false);
  const [activePostIndex, setActivePostIndex] = useState<number | any>(null);

  const [nonExpiredList, setNonExpiredList] = useState<any[]>([]);
  const [isShowRefreshRateModal, setIsShowRefreshRateModal] = useState(false);
  const [isShowConvertOrderModal, setIsShowConvertOrderModal] = useState(false);
  const [intervalId, setIntervalId] = useState<any>();
  const [isShowCloseReasonModal, setIsShowCloseReasonModal] = useState(false);
  const [isQuoteCloseLoading, setIsQuoteCloseLoading] = useState(false);
  const [isExpiredRateExist, setIsExpiredRateExist] = useState(false);
  const [isAllRateExpired, setIsAllRateExpired] = useState(false);
  const [isShowSendPdfModal, setIsShowSendPdfModal] = useState(false);
  const [isShowQuoteEmailsThreads, setIsShowQuoteEmailsThreads] =
    useState(false);
  const [generatedPdfId, setGeneratedPdfId] = useState<number | any>(null);
  const [generatedPdfName, setGeneratedPdfName] = useState<string | any>('');
  const [generatedPdfUrl, setGeneratedPdfUrl] = useState<string | any>('');
  const [activeTab, setActiveTab] = useState('address');
  const [isShowQuoteDetailModal, setIsShowQuoteDetailModal] = useState(false);
  const [carriersList, setCarriersList] = useState<any>([]);
  const [isCarrierLoading, setIsCarrierLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [isFromQuoteDetail, setIsFromQuoteDetail] = useState(false);
  const [openOverviewOfRateGathering, setOpenOverviewOfRateGathering] =
    useState<boolean>(false);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const isMapScript = document.getElementById(scriptId);
  useEffect(() => {
    let storedQuotedId = localStorage.getItem('loadLinkQuoteId');

    if (storedQuotedId === id) {
      setLoadLinkPostingDetail(
        localStorage.getItem('loadLinkPostDetail')
          ? JSON.parse(localStorage.getItem('loadLinkPostDetail') as string)
          : undefined
      );
      setActivePostIndex(localStorage.getItem('activePostIndex') ?? null);
    }
  }, []);

  const sortDataByIsQuoted = (data: any) =>
    data.sort((a: any, b: any) => {
      const aIsQuotedFalse = a?.services.some(
        (service: any) => service.isQuoted === false
      );
      const bIsQuotedFalse = b?.services.some(
        (service: any) => service.isQuoted === false
      );

      return aIsQuotedFalse === bIsQuotedFalse ? 0 : aIsQuotedFalse ? 1 : -1;
    });

  const sortActiveCarrierRates = (
    carrierRatesValue: any,
    isSortTrue = false
  ) => {
    const quotedCarrierRates: any = [];
    const nonExpiredQuotedList: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : carrierRate.isAmericanGroup
          ? 'American Group'
          : 'Direct';

        if (
          [STATUS.ACTIVE, STATUS.REQUESTED, STATUS.WON].includes(
            carrierRate.carrierStatus
          ) &&
          carrierRate.carrierStatus === addressDetails.status
        ) {
          quotedCarrierRates.push(carrierRate);

          if (
            !carrierRate.expirationDate || // No expiration date
            (carrierRate.carrierId &&
              carrierRate.carrierQuoteId &&
              !moment(carrierRate.expirationDate)
                .add(1, 'days')
                .isBefore(moment().startOf('day'))) ||
            !carrierRate.carrierId ||
            !carrierRate.carrierQuoteId
          ) {
            nonExpiredQuotedList.push(carrierRate);
          }
        }
      });
    }

    if (!isSortTrue) {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      setNonExpiredList(
        sortDataByIsQuoted(
          orderBy(
            nonExpiredQuotedList,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
    } else {
      setQuotedList(
        sortDataByIsQuoted(
          orderBy(
            quotedCarrierRates,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
      setNonExpiredList(
        sortDataByIsQuoted(
          orderBy(
            nonExpiredQuotedList,
            activeQuoteParams.sortField,
            activeQuoteParams.sortType
          )
        )
      );
    }
  };

  const sortCarrierRates = (carrierRatesValue: any, isSortTrue = false) => {
    const validCarrierRates: any = [];
    const invalidCarrierRates: any = [];
    let openedCarrierRates: any = [];

    if (carrierRatesValue?.length) {
      carrierRatesValue.forEach((carrierRate: any) => {
        carrierRate.provider = carrierRate.isBanyan
          ? 'Banyan'
          : carrierRate.isFreightcom
          ? 'FrightCom'
          : carrierRate.isAmericanGroup
          ? 'American Group'
          : 'Direct';

        if (carrierRate.carrierQuoteId) {
          validCarrierRates.push(carrierRate);

          if (carrierRate.carrierStatus === STATUS.OPENED) {
            openedCarrierRates.push(carrierRate);
          }
        } else {
          invalidCarrierRates.push(carrierRate);
        }
      });
    }
    const lowestCharge: any = minBy(validCarrierRates, 'finalCharge');
    const fastestDelivery: any = minBy(
      validCarrierRates,
      'projectedDeliveryDate'
    );
    const appendCarrierRates = validCarrierRates.map((carrierRate: any) => {
      const tags = [];

      if (carrierRate.finalCharge === lowestCharge.finalCharge) {
        tags.push('Cheapest');
      }

      if (
        carrierRate.projectedDeliveryDate ===
        fastestDelivery.projectedDeliveryDate
      ) {
        tags.push('Fastest');
      }
      carrierRate.tags = tags;

      return carrierRate;
    });
    const sortedCarrierRates = orderBy(
      appendCarrierRates,
      sortData.sortField,
      sortData.sortType
    );
    const allCarrierRates = sortedCarrierRates.concat(invalidCarrierRates);

    updateCarrierRates(allCarrierRates);
    setCarrierRates(allCarrierRates);
    let nonQuotedCarrierRatesList = invalidCarrierRates.filter(
      (carrierRate: any) => carrierRate.carrierStatus !== STATUS.ACTIVE
    );
    setNonQuotedList(nonQuotedCarrierRatesList);

    if (!isSortTrue) {
      setWithRateList(
        sortDataByIsQuoted(
          orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
        )
      );
    } else {
      setTimeout(() => {
        setWithRateList(
          sortDataByIsQuoted(
            orderBy(openedCarrierRates, sortData.sortField, sortData.sortType)
          )
        );
      }, 400);
    }
  };

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      label: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      label: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const quoteClassificationLTLToolsArr = [
    {
      value: QuoteType.WITH_RATE,
      name: 'Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      name: 'Spot Quote',
    },
  ];

  const quoteClassificationFTLToolsArr = [
    {
      value: QuoteType.LOAD_BOARD,
      name: 'Load Boards',
    },
    {
      value: QuoteType.PREDICTIVE_PRICING,
      name: 'Predictive Index',
    },
  ];

  const handleAllColors = (quoteStatus: any) => {
    const status = quoteStatus;
    const colorsObj = {
      badgeColor: ' bg-gray-100 ',
      badgeText: ' text-grayText ',
      textColor: ' text-black ',
      bgColor: '',
      borderColor: '',
    };

    switch (status) {
      case STATUS.OPENED:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.ACTIVE:
        colorsObj.badgeColor = ' bg-primary50 ';
        colorsObj.badgeText = ' text-primary ';
        colorsObj.borderColor = ' border-utilityBlue200 ';
        break;
      case STATUS.WON:
        colorsObj.badgeColor = ' bg-green100 ';
        colorsObj.badgeText = ' text-green ';
        colorsObj.textColor = ' text-green ';
        colorsObj.bgColor = ' bg-green100 ';
        colorsObj.borderColor = ' border-green ';
        break;
      case STATUS.LOST:
      case STATUS.EXPIRED:
        colorsObj.badgeColor = ' bg-red-100 ';
        colorsObj.badgeText = ' text-danger ';
        colorsObj.borderColor = ' border-danger ';
        break;
      default:
        break;
    }
    setColors(colorsObj);
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch((err: any) => {
        console.error('Pricing rates error', err);
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  const fetchQuoteEmailDetails = async (messageId: string) => {
    try {
      const quoteEmails = (await getQuoteEmailDetails({
        messageId: messageId,
      })) as any;
      setQuoteEmailDetail({
        ...quoteEmails?.data,
        emailBody: JSON.parse(quoteEmails?.data?.emailBody),
      });
    } catch (e: any) {
      // WalToast.error(
      //   e?.message ?? e ?? 'Something went wrong while fetching quote emails.'
      // );
    }
  };

  useEffect(() => {
    if (id) {
      localStorage.removeItem('isModifyQuoteFromQuote');
      localStorage.removeItem('isPickupDateChanged');
      localStorage.removeItem('modifyQuoteCode');

      if (isRefresh) {
        getQuote(id)
          .then((response: any) => {
            if (
              response &&
              response.data &&
              response.data.addressDetails &&
              response.data.addressDetails.status !== STATUS.PENDING
            ) {
              const responseData = response.data;
              setQuote(responseData);

              if (responseData?.addressDetails) {
                setAddressDetails(responseData?.addressDetails);

                if (responseData?.addressDetails?.emailContentId) {
                  fetchQuoteEmailDetails(
                    responseData.addressDetails.emailContentId
                  );
                }
              }

              if (responseData?.rateGatheringWithAi)
                setRateGatheringWithAi(responseData?.rateGatheringWithAi);

              setLoadLinkPosts(responseData.loadLinkPosts);

              if (responseData?.dimensions?.length) {
                setDimensions(responseData.dimensions);
                let unit: number | string = 0;
                let weight = 0;

                responseData.dimensions.forEach((val: any) => {
                  unit += val.handlingUnitNo || 0;
                  weight += val.totalWeight;
                });

                if (
                  responseData.dimensions?.length &&
                  responseData.dimensions[0].handlingUnitName
                ) {
                  const handlingUnitName =
                    responseData.dimensions[0].handlingUnitName;

                  unit = `${unit} ${handlingUnitName}${unit > 1 ? 's' : ''}`;
                }
                setTotalWeight(weight);
                setTotalUnit(unit);
              } else {
                setTotalWeight(responseData?.addressDetails?.totalWeight);
                setTotalUnit(
                  responseData?.addressDetails?.primaryEquipmentTypeFullName ||
                    ''
                );
              }

              if (responseData?.additionalService) {
                setServices(responseData?.additionalService);
              }

              if (
                responseData?.carrierRates &&
                responseData?.carrierRates.length
              ) {
                if (!isFromQuoteDetail)
                  sortCarrierRates(responseData?.carrierRates);

                sortActiveCarrierRates(responseData?.carrierRates);
              } else if (
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.LTL &&
                responseData?.dimensions?.length > 0 &&
                (containsWholeMatchForEquipmentType(
                  responseData?.addressDetails?.primaryEquipmentTypeFullName
                ) ||
                  containsWholeMatchForEquipmentType(
                    responseData?.addressDetails
                      ?.secondaryEquipmentTypeFullNames
                  ))
              ) {
                setIsGetRate(true);
              }

              if (!quoteType) {
                if (currentTab) {
                  setQuoteType(currentTab);
                } else if (
                  responseData?.addressDetails?.classification ===
                    QUOTE_CLASSIFICATION.LTL &&
                  responseData?.dimensions?.length > 0 &&
                  (containsWholeMatchForEquipmentType(
                    responseData?.addressDetails?.primaryEquipmentTypeFullName
                  ) ||
                    containsWholeMatchForEquipmentType(
                      responseData?.addressDetails
                        ?.secondaryEquipmentTypeFullNames
                    ))
                ) {
                  setQuoteType(QuoteType.WITH_RATE);
                } else {
                  if (
                    responseData?.addressDetails?.classification ===
                      QUOTE_CLASSIFICATION.FTL ||
                    responseData?.addressDetails?.classification ===
                      QUOTE_CLASSIFICATION.VOLUME_LTL
                  ) {
                    setQuoteType(QuoteType.PREDICTIVE_PRICING);
                  } else {
                    setQuoteType(QuoteType.LOAD_BOARD);
                  }
                }
              }

              if (
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.FTL ||
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.LTL
              ) {
                handleAllColors(responseData?.addressDetails?.status);
              }
            } else {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }
          })
          .catch(console.error)
          .finally(() => {
            setIsLoading(false);
            setIsRefresh(false);
          });
      }
    } else {
      navigate(ROUTES.QUOTING_DASHBOARD);
    }
  }, [isRefresh]);

  useEffect(() => {
    if (!isQuotePdfAttached) {
      setSelectedLanguage('english');
      setSelectedDisplayOptions({
        displayServiceType: false,
        displayTransitTimes: false,
        displayCarrierNames: false,
      });
    }
  }, [isQuotePdfAttached]);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on && currentUser?.id) {
      socketIO.get(
        `/subscribe/${currentUser.id}`,
        function (data: any, jwr: any) {
          if (jwr.error) {
            return;
          }
        }
      );

      socketIO.on('user', function (data: any) {
        if (data.type === 'NEW_RATE') {
          if (data?.data && data?.data?.quoteId === parseInt(id)) {
            let newCarrierRates = [...carrierRatesRef.current, data.data];

            sortCarrierRates(newCarrierRates);
            setIsLoading(false);
          }
        } else if (data?.type === 'FINISH_LOADING') {
          carrierRateFetchStatus[data.carrier] = true;
          setCarrierRateFetchStatus((old: any) => ({
            ...old,
            [data.carrier]: true,
          }));
        } else if (data.type === 'ORDER_BOOKING_SUCCESS') {
          if (!orderBookingSuccessToast) {
            setOrderBookingSuccessToast(true);
          }

          setAction((old) => ({ ...old, carrierOrderModel: false }));

          if (data?.data?.quoteId == id) {
            setIsRefresh(true);
          }
        } else if (data.type === 'ORDER_BOOKING_ERROR') {
          if (!orderBookingErrorToast) {
            setAction((old) => ({ ...old, carrierOrderModel: false }));
            WalToast.error(
              data.insufficientBalance
                ? "Can't book order due to insufficient balance"
                : "Can't book order, some details missing"
            );
            setOrderBookingErrorToast(true);
          }
        }
      });
    }

    return () => {};
  }, [isSocketConnected, currentUser?.id, isGetRate]);

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
    setSelectedCarrierRates([]);
    setSelectedCarrierRatesModel([]);
  }, []);

  const handleActionType =
    (actionKey: string, mode: string = '', carrierRate: any = null) =>
    () => {
      let newCarrierRate: any = {};

      if (carrierRate) {
        if (currency === CURRENCY.USD) {
          newCarrierRate = { ...carrierRate };
          newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
          newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
          newCarrierRate.margin = carrierRate.marginUSD;
          newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

          if (newCarrierRate?.services?.length) {
            newCarrierRate.services = newCarrierRate.services.map(
              (item: any) => ({
                ...item,
                value: item.valueUSD,
              })
            );
          }
        } else {
          newCarrierRate = { ...carrierRate };
        }
      }

      if (actionKey === 'model' && mode === 'create') {
        setQuoteModalOpen(true);
        setSelectedCarrierRatesModel([newCarrierRate]);
        setAction((old) => ({ ...old, [actionKey]: true, mode }));

        return;
      }

      if (carrierRate) {
        setSelectedCarrierRates([newCarrierRate]);
      }

      if (actionKey === 'model' && mode === 'update') {
        console.log('newCarrierRate', newCarrierRate);
        setSelectedCarrierRatesModel([newCarrierRate]);
        setAction((old) => ({ ...old, [actionKey]: true, mode }));

        return;
      }

      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleSubmit = () => {
    navigate(ROUTES.QUOTING_DASHBOARD);
  };

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [currency]);

  useEffect(() => {
    if (isGetRate) {
      listCarrier({
        search: '',
        sortDirection: 'DESC',
        sortBy: 'quoteCount',
        page: 1,
        limit: 100,
        type: 'connection',
      })
        .then((response: any) => {
          let carriers = response?.data || [];

          if (carriers?.length) {
            setIsFinish(false);
            setIsAllCarrierRateFetched(false);
            updateCarrierRates([]);
            setCarrierRates([]);
            setWithRateList([]);
            setNonQuotedList([]);

            if (isExpiredRefresh) {
              setWithRateList((old) =>
                old.filter((data) => {
                  if (data.expirationDate) {
                    return new Date(data.expirationDate) > new Date();
                  } else {
                    return new Date(data.pickupDate) > new Date();
                  }
                })
              );
              let expiredCarrierArr: any = [];

              withRateList.map((data) => {
                let slug = data.isBanyan
                  ? CARRIERS.BANYAN.SLUG
                  : data.isFreightcom
                  ? CARRIERS.FREIGHTCOM.SLUG
                  : data.isAmericanGroup
                  ? CARRIERS.AMERICAN_GROUP.SLUG
                  : data.slug;

                if (data.expirationDate) {
                  if (new Date(data.expirationDate) <= new Date())
                    expiredCarrierArr.push(slug);
                } else {
                  if (new Date(data.pickupDate) <= new Date())
                    expiredCarrierArr.push(slug);
                }
              });
              let expiredCarrierList = carriers.filter((data: any) =>
                expiredCarrierArr.includes(data.slug)
              );

              setCarrierList(expiredCarrierList || []);
            } else {
              setWithRateList([]);
              setCarrierList(carriers || []);
            }
          }
        })
        .catch(console.log);
    } else {
      setIsFinish(true);
    }
  }, [isGetRate]);

  useEffect(() => {
    if (
      (addressDetails?.classification === QUOTE_CLASSIFICATION.FTL ||
        addressDetails?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL) &&
      !quoteType
    ) {
      setQuoteType(QuoteType.PREDICTIVE_PRICING);
    }

    if (carrierRates?.length && !isExpiredRefresh) {
      if (!isFromQuoteDetail) {
        sortCarrierRates(carrierRates, true);
      }
    }
  }, [sortData, addressDetails]);

  useEffect(() => {
    if (carrierRates?.length) {
      sortActiveCarrierRates(carrierRates, true);
    }
  }, [activeQuoteParams, addressDetails]);

  const handleQuoteClose = (closeReasonPayload: any) => {
    setIsShowCloseReasonModal(false);
    setIsQuoteCloseLoading(true);
    cancelQuote(closeReasonPayload, id)
      .then((response: any) => {
        if (response && response.data) {
          setIsShowCloseReasonModal(false);
          setIsRefresh(true);
        }
      })
      .finally(() => {
        setIsQuoteCloseLoading(false);
      })
      .catch((error: any) => {
        WalToast.error(
          error?.response?.data?.message || 'Something went wrong',
          ''
        );
      });
  };

  const ReqQuoteHeadCells = useMemo(
    () => [
      {
        id: 'Name',
        name: 'Carrier',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'DeliveryTime',
        name: 'Delivery Time',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'ProjectedDelivery',
        name: 'Projected Delivery',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'BaseQuote',
        name: 'Base Quote',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'AdditionalServices',
        name: 'Additional Services',
        sortable: false,
        rowClassName: '',
      },
      {
        id: 'Total',
        name: 'Total',
        sortable: false,
        rowClassName: '',
      },
      ...([STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status)
        ? [
            {
              id: 'action',
              name: '',
            },
          ]
        : []),
    ],
    [allChecked, selectedCarrierRates, addressDetails]
  );

  useEffect(() => {
    setSelectedCarrierRates([]);
  }, [quoteType]);

  useEffect(() => {
    let isAnyExpired = false;
    let isAllExpired = true;

    for (const data of withRateList) {
      const expirationDate = data.expirationDate
        ? moment(data.expirationDate).add(1, 'days')
        : moment(data.pickupDate).add(1, 'days');

      const isExpired = expirationDate.isBefore(moment().startOf('day'));

      if (!isExpired) {
        isAllExpired = false;
      } else {
        isAnyExpired = true;
      }

      if (!isAllExpired && isAnyExpired) {
        break;
      }
    }

    setIsExpiredRateExist(isAnyExpired);
    setIsAllRateExpired(isAllExpired);
  }, [withRateList]);

  const handleExpiredRefreshRates = () => {
    let isAnyExpired = withRateList.some((data) => {
      if (data.expirationDate) {
        return moment(data.expirationDate)
          .add(1, 'days')
          .isBefore(moment().startOf('day'));
      } else {
        return moment(data.pickupDate)
          .add(1, 'days')
          .isBefore(moment().startOf('day'));
      }
    });

    if (isAnyExpired) {
      if (new Date(addressDetails.pickupDate) < new Date()) {
        setIsShowRefreshRateModal(true);
      } else {
        setIsGetRate(true);
      }
      setIsExpiredRefresh(true);
    } else {
      WalToast.error('No Expired rates found');
    }
  };

  const tooltipItems = (
    <div className="">
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <Edit05 className="w-4 h-4 text-gray500" />
        Modify quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <RefreshCcw05 className="w-4 h-4 text-gray500" />
        Rollover
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center mb-[1px]">
        <XClose className="w-4 h-4 text-gray500" />
        No quote
      </div>
      <div className="py-[9px] px-2.5 flex gap-2 text-textSecondary text-sm font-medium items-center">
        <HelpCircle className="w-4 h-4 text-gray500" />
        Request help
      </div>
    </div>
  );

  const WithRatesHeadCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
      },

      {
        id: 'expiry',
        name: 'Expiry',
      },
      {
        id: 'transitTime',
        name: 'Transit Time',
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: 'totalCharge',
        name: 'Base Quote',
        sortable: true,
      },
      {
        id: 'additionalChargesUSD',
        name: 'Additional Services',
        sortable: true,
      },
      {
        id: 'finalCharge',
        name: 'Total Cost',
        sortable: true,
      },
      {
        id: 'action',
        visible:
          isLoading ||
          [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
      },
    ],
    [addressDetails, isLoading]
  );

  const QuotedHeadCells = useMemo(() => {
    const baseHeadCells = [
      {
        id: 'name',
        name: 'Carrier',
        sortable: true,
        rowClassName: '',
        visible: true,
      },

      {
        id: addressDetails.status === STATUS.WON ? 'status' : 'expiry',
        name: addressDetails.status === STATUS.WON ? 'Status' : 'Expiry',
        visible: true,
      },
      {
        id: 'transittime',
        name: 'Transit Time',
        visible: true,
      },
      {
        id: 'projectedDeliveryDate',
        name: 'Est. Delivery Date',
        sortable: true,
        visible: true,
      },
      {
        id: `${currency === CURRENCY.CAD ? 'totalCostCAD' : 'totalCostUSD'}`,
        name: 'Total Cost',
        sortable: true,
        visible: true,
      },
      {
        id: 'margin',
        name: 'Total Margin',
        sortable: true,
        visible: true,
      },
      {
        id: 'finalCharge',
        name: 'Total Sell Price',
        sortable: true,
        visible: true,
      },
      {
        id: 'action',
        visible:
          isLoading ||
          [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status),
      },
    ];

    return baseHeadCells;
  }, [quotedList, isLoading, addressDetails]);

  const tabOptionsArr = [
    {
      value: QuoteType.QUOTED,
      label: 'Quoted',
    },
    {
      value: QuoteType.WITH_RATE,
      label: 'Direct Carrier Rates',
    },
    {
      value: QuoteType.NON_QUOTED,
      label: 'Request a Spot Quote',
    },
  ];

  const requestSpotQuote = (reqData: any) => {
    const quoteIds = selectedCarrierRates.map((item: any) => item.id);
    const data: any = {
      ...reqData,
      ids: quoteIds,
    };
    const formDataToSend = new FormData();
    formDataToSend.append('doNotAskSpotQuote', reqData?.doNotAskSpotQuote);
    formDataToSend.append('sentEmailTo', reqData.sentEmailTo);
    formDataToSend.append('emailContent', reqData.emailContent);

    console.log('quoteIds', quoteIds);
    formDataToSend.append('ids', JSON.stringify(quoteIds));

    if (reqData?.cc && reqData.cc.length > 0) {
      formDataToSend.append('cc', JSON.stringify(reqData.cc));
    }

    if (reqData?.bcc && reqData.bcc.length > 0) {
      formDataToSend.append('bcc', JSON.stringify(reqData.bcc));
    }

    let attachments: any = [];
    data?.attachments?.forEach((file: any) => {
      attachments.push(file);
    });
    formDataToSend.append('attachments', JSON.stringify(attachments));

    console.log('data', data);
    console.log('spotQuoteRequest', spotQuoteRequest);

    setSubmitLoading(true);
    spotQuoteRequest(formDataToSend)
      .then(() => {
        setSubmitLoading(false);
        setSelectedCarrierRates([]);
        setAllChecked(false);
        setAction(initAction);

        if (reqData?.isDoNotAskChecked) {
          setCurrentUser({
            ...currentUser,
            doNotAskSpotQuote: reqData?.isDoNotAskChecked,
          });
        }
        setIsRefresh(true);
      })
      .finally(() => setIsLoading(false))
      .catch(() => {
        setSelectedCarrierRates([]);
        setSubmitLoading(false);
        setAction(initAction);
      });
  };

  const handleModalClose = useCallback(
    (status: boolean, modalName?: any, reqData?: any) => () => {
      if (modalName) {
        setAction({ ...initAction, [modalName]: status });
      }

      if (!status) {
        setAction(initAction);
      }

      if (action.spotQuoteConfirmation) {
        if (status) {
          requestSpotQuote({
            ...reqData,
            cc: Array.isArray(reqData?.cc) ? reqData.cc.join(',') : reqData.cc,
          });
        }
      }

      if (action.orderSuccessModel) {
        setIsRefresh(true);
      } else if (action.addQuoteDetail) {
        setSelectedCarrierRates([]);
      }
    },
    [action]
  );

  const handleQuoteClick = (carrierRate: any) => {
    if (
      (carrierRate?.isSpotRequestSent || !carrierRate?.spotQuoteEmail) &&
      quoteType === QuoteType.NON_QUOTED
    ) {
      return;
    }

    if (![STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status)) {
      return;
    }

    if (quoteType === QuoteType.NON_QUOTED) {
      handleActionType('spotQuoteConfirmation', 'view')();
    }

    if (quoteType === QuoteType.WITH_RATE) {
      const expDate = new Date(carrierRate.expirationDate);
      const today = new Date();
      expDate.setDate(expDate.getDate() + 1);

      const diffTime = expDate.getTime() - today.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays < 0) {
        return;
      }

      handleActionType('model', 'create', carrierRate)();
    }

    let newCarrierRate: any = {};

    if (currency === CURRENCY.USD) {
      newCarrierRate = { ...carrierRate };
      newCarrierRate.additionalCharges = carrierRate.additionalChargesUSD;
      newCarrierRate.finalCharge = carrierRate.finalChargeUSD;
      newCarrierRate.margin = carrierRate.marginUSD;
      newCarrierRate.totalCharge = carrierRate.totalChargeUSD;

      if (newCarrierRate?.services?.length) {
        newCarrierRate.services = newCarrierRate.services.map((item: any) => ({
          ...item,
          value: item.valueUSD,
        }));
      }
    } else {
      newCarrierRate = { ...carrierRate };
    }

    if (
      selectedCarrierRates.filter((rate: any) => rate.id === carrierRate.id)
        .length
    ) {
      newCarrierRate = {};
      setSelectedCarrierRates((old: any) =>
        old.filter((item: any) => item.id !== carrierRate.id)
      );
    } else {
      setSelectedCarrierRates((old: any) => [...old, newCarrierRate]);
    }
  };

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  useEffect(() => {
    if (isSocketConnected && carrierList?.length && isGetRate) {
      setIsRateLoading(true);

      const ratePromises = carrierList.map((element: any) => {
        setCarrierRateFetchStatus((old: any) => ({
          ...old,
          [element.slug]: false,
        }));
        let formData = quote;
        formData.carrierId = element.id;
        formData.pickupDate = addressDetails.pickupDate;

        return createRate(formData).catch((error: any) => {
          console.error(error);
          WalToast.error(
            error?.response?.data?.message ||
              'Carrier rates have not been created',
            ''
          );
        });
      });

      setIsCarrierRateFetched({
        ...isCarrierRateFetched,
        [id]: true,
      });
      Promise.all(ratePromises)
        .then(() => {
          setIsRateLoading(false);
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
          setIsRateLoading(false);
        })
        .finally(() => {
          setIsCarrierRateFetched({
            ...isCarrierRateFetched,
            [id]: false,
          });
        });
    }
  }, [isSocketConnected, carrierList]);

  useEffect(() => {
    let allFetched: boolean = true;

    for (const key in carrierRateFetchStatus) {
      if (!carrierRateFetchStatus[key]) {
        allFetched = false;
      }
    }
    setIsAllCarrierRateFetched(allFetched);
  }, [carrierRateFetchStatus]);

  console.log('addressDetails', addressDetails);

  useEffect(() => {
    if (isAllCarrierRateFetched) {
      setCarrierList([]);
      setIsFinish(true);
      setIsExpiredRefresh(false);
      setIsGetRate(false);
      setCarrierRateFetchStatus({});
    }
  }, [isAllCarrierRateFetched]);

  const fetchBol = () => {
    if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
      checkBol(id)
        .then((response: any) => {
          setAddressDetails({ ...addressDetails, ...response?.data });

          if (response?.data?.bolUrl) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
        })
        .catch((error) => {
          console.error('Error processing rates:', error);
        });
    }
  };

  useEffect(() => {
    let intervalIdd: any = '';

    if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
      intervalIdd = setInterval(() => {
        if (addressDetails.status === STATUS.WON && !addressDetails.bolUrl) {
          fetchBol();
        }
      }, 180000);
      setIntervalId(intervalIdd);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [addressDetails.status, addressDetails.bolUrl]);

  const handleClickRedirectToOrder = () => {
    // navigate(`${PATH.ORDER_VIEW}/${addressDetails.e2eOrderId}`);
    window.open(`${PATH.ORDER_VIEW}/${addressDetails.e2eOrderId}`, '_blank');
  };

  const handleClickRedirectToQuote = () => {
    window.open(ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId, '_blank');
  };

  const rightSideContent = (
    <div className="flex sms:flex-nowrap flex-wrap sm:justify-end justify-start items-center lg:gap-x-4 sm:gap-x-3 gap-x-2 mdm:w-auto md:w-full whitespace-nowrap">
      <div className="!hidden">
        {!isLoading && (
          <>
            <TooltipCmp
              message={tooltipItems}
              type="light"
              openOnClick
              isArrow={false}
            >
              <ButtonCmp
                type="submit"
                className="btn_secondary_black lg:!p-[9px] !p-2"
              >
                <DotsVertical className="w-4 h-4" />
              </ButtonCmp>
            </TooltipCmp>
          </>
        )}
      </div>
      {addressDetails?.emailContentId && quoteEmailDetail ? (
        <ButtonCmp
          className="btn-outline-primary"
          onClick={() => setIsShowQuoteEmailsThreads(true)}
          icon={<Mail01 className="w-4 h-4" />}
        >
          Open Thread
        </ButtonCmp>
      ) : (
        ''
      )}

      {addressDetails.status === STATUS.REQUESTED &&
        addressDetails?.isRequestedByCustomer && (
          <ButtonCmp
            className="btn_secondary_black lg:!p-[9px] !p-2"
            onClick={() => setIsShowConvertOrderModal(true)}
          >
            Move To Booked
          </ButtonCmp>
        )}

      {!!addressDetails.isOrderBookingInProgress && (
        <ButtonCmp
          className="btn-outline-primary text-grayLight600"
          btnSpinnerClass="text-primary"
          loading
        >
          Processing Order Booking
        </ButtonCmp>
      )}
      {addressDetails.isBookOrderEnable &&
        [STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
          <TooltipCmp
            parentClassName="max-w-[200px]"
            message={
              addressDetails.status !== STATUS.ACTIVE
                ? 'Add at least 1 active quote to convert this quote to an order'
                : ''
            }
          >
            <ButtonCmp
              className="btn-outline-primary"
              disabled={
                addressDetails?.status !== STATUS.ACTIVE ||
                addressDetails?.isOrderBookingInProgress ||
                ![STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails?.status)
              }
              onClick={() => {
                if (nonExpiredList?.length == 1) {
                  if (nonExpiredList[0]?.carrierId) {
                    handleActionType(
                      'carrierOrderModel',
                      '',
                      nonExpiredList[0]
                    )();
                  } else {
                    navigate(`${PATH.ORDER_CREATE}`, {
                      state: {
                        customerId: addressDetails?.customerId,
                        quoteId: nonExpiredList[0]?.quoteId,
                      },
                    });
                  }
                } else {
                  setIsShowConvertOrderModal(true);
                }
              }}
            >
              Convert to Order
            </ButtonCmp>
          </TooltipCmp>
        )}

      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() =>
            window.open(ROSE_ROCKET.QUOTE_URL + addressDetails.rossRocketId)
          }
        >
          View Order
        </ButtonCmp>
      )}

      {addressDetails.status === STATUS.WON && (
        <ButtonCmp
          className="btn_secondary_black"
          onClick={() => {
            if (addressDetails.bolUrl) {
              downloadPdf(
                addressDetails.bolUrl,
                `${addressDetails.code}-BOL.${
                  addressDetails.bolUrl.split('.').reverse()[0]
                }`
              );
            }
          }}
          disabled={!addressDetails.bolUrl}
          loading={!addressDetails.bolUrl}
        >
          {addressDetails.bolUrl ? (
            <>
              <DownloadCloud02 className="w-4 h-4" />
              Download BOL
            </>
          ) : (
            'Fetching BOL'
          )}
        </ButtonCmp>
      )}

      {addressDetails?.e2eOrderId ? (
        <ButtonCmp
          className="btn_secondary_black text-primary"
          onClick={handleClickRedirectToOrder}
        >
          #{addressDetails?.e2eOrderWalId}
        </ButtonCmp>
      ) : (
        addressDetails.rossRocketPublicId && (
          <ButtonCmp
            className="btn_secondary_black text-primary"
            onClick={handleClickRedirectToQuote}
          >
            #{addressDetails.rossRocketPublicId}
          </ButtonCmp>
        )
      )}
    </div>
  );

  const ActiveArray: any = [
    {
      value: 'active',
      name: 'Active Quotes',
    },
  ];

  const increaseActiveCarrierCount = () => {
    navigate(`${PATH.MY_QUOTE}/${id}`, {
      state: {
        ...location.state,
        activeCarrierCount: (location.state?.activeCarrierCount || 0) + 1,
      },
    });
  };

  const decreaseActiveCarrierCount = () => {
    navigate(`${PATH.MY_QUOTE}/${id}`, {
      state: {
        ...location.state,
        activeCarrierCount: Math.max(
          (location.state?.activeCarrierCount || 0) - 1,
          0
        ),
      },
    });
  };

  const handleQuoteModalClose = (status: boolean) => {
    setQuoteModalOpen(false);
    setSelectedCarrierRatesModel([]);

    if (status) {
      navigate(`${PATH.MY_QUOTE}/${id}`, {
        state: {
          ...location.state,
          activeCarrierCount: (location.state?.activeCarrierCount || 0) + 1,
        },
      });
    }
  };

  const handleGeneratePdf = () => {
    setIsPdfGenerating(true);
    createPDF(id, {
      selectedOption: selectedDisplayOptions,
      quoteCarrierId: selectedQuoteToGeneratePdfId,
      pdfLanguage: selectedLanguage,
      isPdf: false,
      currency: currency,
    })
      .then((response: any) => {
        const { data } = response;
        setIsPdfGenerating(false);
        setIsShowSendPdfModal(false);
        setIsShowSendPdfModal(true);
        setGeneratedPdfId(data?.id);
        setGeneratedPdfName(data.name);
        setGeneratedPdfUrl(`${data?.url}${data?.file}`);
        setAddressDetails((prevState: any) => ({
          ...prevState,
          quotePdf: (addressDetails.quotePdf || 0) + 1,
        }));
        setSelectedLanguage('english');
        setSelectedDisplayOptions({
          displayServiceType: false,
          displayTransitTimes: false,
          displayCarrierNames: false,
        });
        setSelectedQuoteToGeneratePdfId([]);
        setIsShowAttachQuotePDFModal(false);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsPdfGenerating(false);
        WalToast.error('Failed to generate quote pdf', '');
      });
  };

  const [isPostCreateLoading, setIsPostCreateLoading] = useState(false);
  const [validatePostToLoadBoardForm, setValidatePostToLoadBoardForm] =
    useState(false);

  useEffect(() => {
    if (!isMapScript && googleKey) {
      loadGoogleMapsScript(googleKey, scriptId);
    }
  }, [isMapScript, googleKey]);

  const handleNavigation = () => {
    const newTab = window.open(ROUTES.MAPIT, '_blank');

    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(
          {
            from: predictivePriceData?.from,
            to: predictivePriceData?.to,
            fromAddressObj: predictivePriceData?.fromFullAddress,
            toAddressObj: predictivePriceData?.toFullAddress,
          },
          ''
        );
      };
    }
  };

  const handleSendQuoteToCustomer = (data: any) => {
    if (generatedPdfId) {
      const formDataToSend = new FormData();

      formDataToSend.append('subject', data.subject);
      formDataToSend.append('sentEmailTo', data.to);
      formDataToSend.append('emailContent', data.emailBody);
      formDataToSend.append('emailCc', data.cc);
      formDataToSend.append('emailBcc', data.bcc);
      data?.attachments?.forEach((file: File) => {
        formDataToSend.append(`attachments`, file);
      });
      sendPDF(generatedPdfId, formDataToSend)
        .then(() => {
          setIsShowSendPdfModal(false);
        })
        .catch(() => {
          WalToast.error('Failed to send quote to customer', '');
        });
    }
  };

  const handleRateGathering = () => {
    setIsGetRate(true);
  };

  const getCarrierProvider = (carrier: any): string =>
    carrier.isFreightcom || carrier.isFreightcom === 1
      ? 'Freightcom'
      : carrier.isBanyan || carrier.isBanyan === 1
      ? 'Banyan'
      : carrier.isAmericanGroup || carrier.isAmericanGroup === 1
      ? 'American Group'
      : 'Direct';

  const getCarriers = () => {
    if (abortControllerRef.current) abortControllerRef.current?.abort();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    setIsCarrierLoading(true);
    getTopCarriers(
      { search: search, limit: 5, carrierId: selectedCarrierId },
      signal
    )
      .then((response: any) => {
        if (response?.data) {
          const carrierListOptions = response?.data?.map((carr: any) => ({
            label: `${carr?.name}`,
            subLabel: `${getCarrierProvider(carr)}`,
            value: carr?.id,
            image: carr?.image ? carr?.imageUrl + carr?.image : null,
            isImage: true,
            isPrivateCarrier: carr?.isPrivateCarrier,
            contactPhone: carr?.contactPhone,
          }));

          setCarriersList(carrierListOptions);
          setIsCarrierLoading(false);
        }
      })
      .finally(() => {
        setIsCarrierLoading(false);
      })
      .catch(console.log);
  };

  const cancelLoadBoardRequest = () => {
    const storedData = localStorage.getItem('load_post_form_data');
    const loadPostFormData = storedData ? JSON.parse(storedData) : {};
    if (loadPostFormData[addressDetails?.id])
      delete loadPostFormData[addressDetails?.id];
    localStorage.setItem(
      'load_post_form_data',
      JSON.stringify(loadPostFormData)
    );

    if (isCreateLoadPost) {
      setIsCreateLoadPost(false);
    } else {
      setIsUpdateLoadPost(false);
    }
    setLoadLinkPostingDetail(undefined);
    setActivePostIndex(null);
  };

  useEffect(() => {
    if (search) {
      const delayDebounceFn = setTimeout(() => {
        getCarriers();
      }, 250);

      return () => clearTimeout(delayDebounceFn);
    } else {
      getCarriers();
    }
  }, [search, selectedCarrierId]);

  useEffect(() => {
    getCarriers();
  }, []);

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={
              <div className="flex items-center md:gap-2 gap-1">
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  onClick={handleSubmit}
                >
                  <ArrowLeft className="w-4 h-4" />
                </ButtonCmp>
                #{addressDetails.code ?? ''}
                <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2">
                  {addressDetails?.firstName && (
                    <TooltipCmp
                      message={`${addressDetails?.firstName} ${addressDetails?.lastName}`}
                    >
                      {addressDetails?.image ? (
                        <div className="rounded-full bg-white">
                          <img
                            alt="sales image"
                            src={
                              addressDetails?.imageUrl + addressDetails?.image
                            }
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      ) : (
                        <div className="rounded-full bg-white">
                          <img
                            src={defaultImage}
                            alt="default image"
                            className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                          />
                        </div>
                      )}
                    </TooltipCmp>
                  )}
                </div>
              </div>
            }
            leftContent={
              <Copy05
                className="w-5 h-5 text-primary cursor-pointer font-bold"
                onClick={() => copyDebounce(addressDetails.code ?? '')}
              />
            }
            countBadge={
              <>
                {isLoading ? (
                  <div className="flex h-6 w-20 border justify-center items-center border-gray300 rounded-lg gap-1 custom-loading">
                    <span className="rounded-full p-1 bg-warning500"></span>
                    <span className="text-xs">To Quote </span>
                  </div>
                ) : (
                  <BadgeCmp
                    style="modern"
                    type={getBadgeTypeByQuoteStatus(addressDetails.status)}
                  >
                    {getBadgeLabelByQuoteStatus(addressDetails.status)}
                  </BadgeCmp>
                )}
                {addressDetails.classification === 'volume_ltl' && (
                  <div className="flex gap-2">
                    <BadgeCmp style="modern" type="pink">
                      Volume LTL
                    </BadgeCmp>
                  </div>
                )}
                {quotedList.length <= 0 && !isLoading && (
                  <BadgeCmp
                    style="modern"
                    type={
                      getBadgeTypeByMinutes(addressDetails?.createdAt)?.type
                    }
                    mainClassName="rounded-md"
                  >
                    <LiveTimer startDate={addressDetails?.createdAt} />
                  </BadgeCmp>
                )}
              </>
            }
            rightSideContent={rightSideContent}
            labelClassName={`${colors.badgeColor} ${colors.badgeText} ${colors.borderColor}`}
            labelTextColor={`${colors.badgeText}`}
            mainContainerClassName="xls:!flex-nowrap !flex-wrap mdm:!flex-row md:!flex-col sm:!flex-row flex-col mdm:!items-center !items-start"
            rightClassName="mdm:min-w-fit md:min-w-full sm:min-w-fit min-w-full !w-fit"
          />
        }
      >
        {/* HELP FLOW NOTIFICATION BOX DESIGN COMMENTED BELOW */}
        {/* <div className="px-3 py-2.5 rounded-md border border-borderSecondary bg-utilityindigo25 flex gap-x-2">
            <InfoCircle className="mt-0.5 w-4 h-4 text-textSecondary" />{' '}
            <p className="text-grayLight600 text-sm font-normal">
              <span className="text-grayLight900 font-semibold">
                Quote Shared:
              </span>{' '}
              Kate Morrison shared this quote with you, please add an active quote
              for their reference.
            </p>
          </div> */}

        {/* {isTotalWeightEqual && (
            <>
              <AlertMessage
                style="error"
                label="Warning:"
                labelInfo={`Total Weight should match with dimensions total weight`}
              />
            </>
          )} */}

        <QuoteDetailTable
          dimensions={dimensions}
          sortActiveCarrierRates={sortActiveCarrierRates}
          services={services}
          isLoading={isLoading}
          setIsGetRate={setIsGetRate}
          handleActionType={handleActionType}
          handleRateGathering={handleRateGathering}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setIsShowQuoteDetailModal={setIsShowQuoteDetailModal}
          isShowQuoteDetailModal={isShowQuoteDetailModal}
          totalWeight={totalWeight}
          totalUnit={totalUnit}
          setIsRefresh={setIsRefresh}
          setIsFromQuoteDetail={setIsFromQuoteDetail}
          quoteData={quote}
        />
        <div className="min-h-[169px] w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col">
          <div className="w-full py-3 px-5 flex mdm:flex-row flex-col flex-wrap mdm:justify-between mdm:items-center gap-3">
            <TabButton
              className={'!cursor-text'}
              tabArray={ActiveArray}
              isActive={'active'}
              activeClassName="!bg-utilityGray100"
            />

            {/* Test */}
            <div className="gap-3 flex flex-wrap whitespace-nowrap">
              <TooltipCmp
                message={
                  quotedList?.length <= 0
                    ? 'Add at least 1 active quote to quote customer'
                    : ''
                }
              >
                <ButtonCmp
                  className="btn-outline-primary"
                  onClick={() => {
                    if (quotedList?.length == 1) {
                      setSelectedQuoteToGeneratePdfId([quotedList[0].id]);
                      setIsShowAttachQuotePDFModal(true);
                    } else {
                      setIsShowSelectQuotesModal(true);
                    }
                  }}
                  disabled={
                    quotedList?.length <= 0 ||
                    ![STATUS.ACTIVE, STATUS.OPENED].includes(
                      addressDetails?.status
                    ) ||
                    isOrderBooked
                  }
                >
                  <img
                    alt="outlook icon"
                    src={outlook}
                    className="w-4 h-4 object-contain"
                  />
                  Quote Customer
                </ButtonCmp>
              </TooltipCmp>

              <ButtonCmp
                type="button"
                className="btn-outline-primary"
                onClick={handleActionType(
                  'model',
                  'Quick_quote',
                  addressDetails
                )}
                icon={<Plus className="w-4 h-4" />}
                disabled={
                  ![STATUS.ACTIVE, STATUS.OPENED].includes(
                    addressDetails?.status
                  )
                }
              >
                Add Quote
              </ButtonCmp>
            </div>
          </div>

          <div className="w-full border-t border-gray100 flex-1 flex flex-col">
            {quotedList.length > 0 || (isLoading && activeCarrierCount > 0) ? (
              <div className="overflow-x-auto custom-scrollbar custom-scrollbar-v2 custom-vertical-scrollbar-hide flex flex-col justify-between table-without-pagination">
                <TableCmp
                  headCells={QuotedHeadCells}
                  tableDataArr={
                    quickQuoteData.length > 0 ? quickQuoteData : quotedList
                  }
                  TableLoaderRowCmp={QuotedLoading}
                  TableRowCmp={QuotedRow}
                  params={activeQuoteParams}
                  setParams={setActiveQuoteParams}
                  tableRowCmpProps={{
                    handleActionType: handleActionType,
                    currency: currency,
                    addressDetails: addressDetails,
                    isFinish: isFinish,
                    setIsRefresh: setIsRefresh,
                    setQuotedList: setQuotedList,
                    decreaseActiveCarrierCount,
                  }}
                  getRowClass={(data: any) => {
                    let isExpiredRate = false;

                    if (
                      !data?.isPrivateCarrier &&
                      data.carrierQuoteId &&
                      addressDetails?.status !== STATUS.WON
                    ) {
                      const expDate = new Date(data.expirationDate);
                      const today = new Date();
                      expDate.setDate(expDate.getDate() + 1);

                      const diffTime = expDate.getTime() - today.getTime();
                      const diffDays = Math.ceil(
                        diffTime / (1000 * 60 * 60 * 24)
                      );

                      if (diffDays < 0) {
                        isExpiredRate = true;
                      }
                    }
                    let rowClass =
                      data.carrierStatus === STATUS.WON ||
                      data.carrierStatus === STATUS.REQUESTED
                        ? '!bg-utilityindigo25'
                        : '';

                    return isExpiredRate
                      ? `bg-utilityGray100 hover:bg-utilityGray200 ${rowClass}`
                      : rowClass;
                  }}
                  isTableDataLoading={isLoading}
                  numberOfSkeletonRows={
                    activeCarrierCount ? activeCarrierCount : 3
                  }
                  isTableRowClickable={false}
                />
              </div>
            ) : (
              <NotFoundUI
                iconClassName="hidden"
                contentClassName="before:!bg-none"
                title=""
                desc="Push a rate from the toolbar below or add a quote manually to answer your customer."
                containerClassName=" min-h-[46px] !h-auto !my-[30px]"
              />
            )}
          </div>
        </div>
        {action.model &&
          (action.mode === 'Quick_quote' || action.mode === 'update') && (
            <AddQuickQuote
              quoteId={id}
              action={action}
              currency={currency}
              selectedCarrierId={selectedCarrierRatesModel?.[0]?.id}
              serviceList={services}
              selectedCarrierRates={selectedCarrierRatesModel}
              handleClose={() => handleDrawerClose}
              pickupDate={addressDetails?.pickupDate}
              carrierListArr={carriersList}
              isCarrierLoading={isCarrierLoading}
              setSearch={setSearch}
              serviceOptions={servicesList}
              isServiceLoading={isServiceLoading}
              setIsRefresh={setIsRefresh}
              setQuickQuoteData={setQuickQuoteData}
              increaseActiveCarrierCount={increaseActiveCarrierCount}
              setSelectedCarrierId={setSelectedCarrierId}
            />
          )}
        {isShowQuoteEmailsThreads ? (
          <QuoteEmailModal
            handleClose={() => setIsShowQuoteEmailsThreads(false)}
            setQuoteEmailDetail={setQuoteEmailDetail}
            data={quoteEmailDetail}
            quoteStatus={addressDetails?.status}
          />
        ) : (
          ''
        )}
        <div
          className={`w-full bg-white rounded-xl shadow border border-utilityGray200 flex flex-col ${
            (withRateList.length <= 0 || quoteType === QuoteType.LOAD_BOARD) &&
            'flex-1'
          }`}
        >
          {(!loadLinkPostingDetail?.id ||
            (isUpdateLoadPost && loadLinkPostingDetail?.id)) && (
            <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
              <div className="table-header-bottom-left">
                {isCreateLoadPost || isUpdateLoadPost ? (
                  <ButtonCmp
                    className="btn_secondary_black"
                    disabled={isPostCreateLoading}
                    onClick={() => cancelLoadBoardRequest()}
                  >
                    <ArrowLeft className="w-4 h-4" /> Back
                  </ButtonCmp>
                ) : (
                  <TabButton
                    className={`table-tab max-xxl:!px-3`}
                    activeClassName="!bg-utilityGray100"
                    tabArray={
                      addressDetails?.classification ===
                        QUOTE_CLASSIFICATION.LTL ||
                      classification === QUOTE_CLASSIFICATION.LTL
                        ? (() => {
                            const combinedArray = [
                              ...quoteClassificationLTLToolsArr,
                              ...quoteClassificationFTLToolsArr,
                            ];

                            return [
                              ...combinedArray.filter(
                                (item) => item?.value !== QuoteType.NON_QUOTED
                              ),
                              ...combinedArray.filter(
                                (item) => item?.value === QuoteType.NON_QUOTED
                              ),
                            ];
                          })()
                        : quoteClassificationFTLToolsArr
                    }
                    parentClassName={`table-tabs`}
                    isActive={quoteType}
                    handleOnClick={(e: any) => {
                      const storedData =
                        localStorage.getItem('last_sel_quote_tab');
                      const lastSelQuoteTabData = storedData
                        ? JSON.parse(storedData)
                        : {};
                      lastSelQuoteTabData[id] = e?.target?.dataset?.value;
                      localStorage.setItem(
                        'last_sel_quote_tab',
                        JSON.stringify(lastSelQuoteTabData)
                      );
                      setQuoteType(e?.target?.dataset?.value);
                    }}
                  />
                )}
              </div>

              {quoteType === QuoteType.LOAD_BOARD &&
                ((isCreateLoadPost &&
                  (!loadLinkPostingDetail || !loadLinkPostingDetail?.id)) ||
                  (isUpdateLoadPost && loadLinkPostingDetail)) && (
                  <>
                    <ButtonCmp
                      className={`btn-outline-primary hidden ${
                        vehicleSizeOptions.length == 0 ? 'custom-loading' : ''
                      }`}
                      loading={isPostCreateLoading}
                      disabled={
                        isPostCreateLoading || vehicleSizeOptions.length == 0
                      }
                      onClick={() => setOpenOverviewOfRateGathering(true)}
                    >
                      Overview Modal
                    </ButtonCmp>
                    <ButtonCmp
                      className={`btn-outline-primary ${
                        vehicleSizeOptions.length == 0 ? 'custom-loading' : ''
                      }`}
                      loading={isPostCreateLoading}
                      disabled={
                        isPostCreateLoading || vehicleSizeOptions.length == 0
                      }
                      onClick={() => setValidatePostToLoadBoardForm(true)}
                    >
                      Post to Load Board
                    </ButtonCmp>
                  </>
                )}
              {quoteType === QuoteType.PREDICTIVE_PRICING && (
                <>
                  <ButtonCmp
                    onClick={handleNavigation}
                    className="btn-outline-primary !bg-transparent"
                  >
                    <span className="flex text-xs font-semibold gap-1">
                      <Route className="w-4 h-4" /> Open in Map-It
                    </span>
                  </ButtonCmp>
                </>
              )}
              {quoteType !== QuoteType.LOAD_BOARD &&
                quoteType !== QuoteType.PREDICTIVE_PRICING && (
                  <>
                    <SelectBox
                      name="currency"
                      placeholder="Select Currency"
                      parentClassName="currency-dropdown sms:mb-0 mb-2"
                      selectoptiontext="currency-dropdown-text"
                      isLoading={isLoading}
                      labelClassName="form_label"
                      value={filterArr.find(
                        (val: any) => val.value === currency
                      )}
                      onChangeFunc={(e: any) => setCurrency(e.value)}
                      options={filterArr}
                    />
                  </>
                )}
              <SelectBox
                name="role"
                placeholder="Carrier Rates"
                parentClassName="sm:hidden block w-full"
                labelClassName="form_label"
                value={tabOptionsArr.find(
                  (val: any) => val.value === quoteType
                )}
                onChangeFunc={(event: any) => setQuoteType(event.value)}
                options={tabOptionsArr}
              />
              {quoteType === QuoteType.WITH_RATE &&
                (isAllCarrierRateFetched ? (
                  <>
                    {isExpiredRateExist && (
                      <ButtonCmp
                        className={`btn_secondary_black lg:!px-[9px] !px-2 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                        onClick={handleExpiredRefreshRates}
                        disabled={[
                          STATUS.EXPIRED,
                          STATUS.LOST,
                          STATUS.WON,
                        ].includes(addressDetails?.status)}
                      >
                        <RefreshCcw04 className="w-4 h-4 text-primary" />
                        Refresh Expired Rates
                      </ButtonCmp>
                    )}
                    {!isAllRateExpired && (
                      <ButtonCmp
                        className={`btn_secondary_black lg:!px-[9px] !px-2 ${
                          isLoading ? 'custom-loading' : ''
                        }`}
                        onClick={() => {
                          setIsExpiredRefresh(false);

                          if (
                            new Date(addressDetails.pickupDate) < new Date()
                          ) {
                            setIsShowRefreshRateModal(true);
                          } else {
                            setIsGetRate(true);
                          }
                        }}
                        disabled={[
                          STATUS.EXPIRED,
                          STATUS.LOST,
                          STATUS.WON,
                        ].includes(addressDetails?.status)}
                      >
                        <RefreshCcw01 className="w-4 h-4 text-primary" />
                        Refresh Rates
                      </ButtonCmp>
                    )}
                  </>
                ) : (
                  <>
                    <ButtonCmp
                      className="btn_secondary_black lg:!px-[9px] !px-2"
                      disabled={true}
                      loading={true}
                    >
                      Fetching Rates
                    </ButtonCmp>
                  </>
                ))}
            </div>
          )}

          {quoteType === QuoteType.NON_QUOTED && (
            <div
              className={`w-full ${
                nonQuotedList.length <= 0 ? 'flex flex-col flex-1' : ''
              }`}
            >
              {nonQuotedList.length > 0 || !isFinish || isLoading ? (
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                  <TableCmp
                    headCells={ReqQuoteHeadCells}
                    tableDataArr={nonQuotedList}
                    TableLoaderRowCmp={ReqQuoteLoading}
                    TableRowCmp={ReqQuotedRow}
                    tableRowCmpProps={{
                      handleActionType: handleActionType,
                      currency: currency,
                      currentUser: currentUser,
                      requestSpotQuote: requestSpotQuote,
                      selectedCarrierRates: selectedCarrierRates,
                      allChecked: allChecked,
                      setAllChecked: setAllChecked,
                      addressDetails: addressDetails,
                    }}
                    isTableDataLoading={isLoading || !isFinish}
                    isTableDataLoadingWithData={!isAllCarrierRateFetched}
                    numberOfSkeletonRows={8}
                    isTableRowClickable={true}
                    onRowClick={handleQuoteClick}
                  />
                </div>
              ) : (
                <NotFoundUI
                  title="No Quote Found"
                  desc="There are no rates for current quote"
                  containerClassName="flex-1 !h-auto"
                />
              )}
            </div>
          )}

          {!quoteType && (
            <div className=" w-full flex flex-col flex-1">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  headCells={WithRatesHeadCells}
                  tableDataArr={[]}
                  TableLoaderRowCmp={WithQuoteLoading}
                  TableRowCmp={<></>}
                  isTableDataLoading={true}
                  numberOfSkeletonRows={5}
                  isTableRowClickable={true}
                />
              </div>
            </div>
          )}

          {quoteType === QuoteType.WITH_RATE && (
            <div
              className={`w-full ${
                withRateList.length <= 0 ? 'flex flex-col flex-1 ' : ''
              }`}
            >
              {withRateList.length > 0 ||
              !isFinish ||
              isLoading ||
              isRateLoading ? (
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                  <TableCmp
                    headCells={WithRatesHeadCells}
                    tableDataArr={withRateList}
                    TableLoaderRowCmp={WithQuoteLoading}
                    TableRowCmp={WithRateQuote}
                    params={sortData}
                    setParams={setSortData}
                    tableRowCmpProps={{
                      handleActionType: handleActionType,
                      currency: currency,
                      addressDetails: addressDetails,
                      isFinish: isFinish,
                      selectedCarrierRates: selectedCarrierRates,
                    }}
                    onRowClick={handleQuoteClick}
                    isTableDataLoading={!isFinish || isLoading || isRateLoading}
                    isTableDataLoadingWithData={!isAllCarrierRateFetched}
                    numberOfSkeletonRows={7}
                    isTableRowClickable={true}
                    isTableRowClickableCondition={(data: any) => {
                      const expMoment = moment(data.expirationDate).endOf(
                        'day'
                      );
                      const today = moment().startOf('day');

                      const diffDays = expMoment.diff(today, 'days');

                      if (diffDays < 0) {
                        return false;
                      }

                      return true;
                    }}
                    tableBodyRowClass={``}
                    getRowClass={(data: any) => {
                      let isNonQuotedServicesExist = false;

                      if (data?.services.some((form: any) => !form.isQuoted)) {
                        isNonQuotedServicesExist = true;
                      }

                      let isExpiredRate = false;
                      const expMoment = moment(data.expirationDate).endOf(
                        'day'
                      );
                      const today = moment().startOf('day');

                      const diffDays = expMoment.diff(today, 'days');

                      if (diffDays < 0) {
                        isExpiredRate = true;
                      }

                      return isNonQuotedServicesExist || isExpiredRate
                        ? 'bg-utilityGray100 hover:bg-utilityGray200'
                        : '';
                    }}
                  />
                </div>
              ) : (
                <NotFoundUI
                  title="No Direct Carrier Rates"
                  desc="Please provide the dimensions for this load to get direct carrier rates."
                  containerClassName="flex-1 !h-auto"
                  iconClassName="!hidden"
                  iconSecondaryClassName="!flex"
                  iconSecondary={<Package className="w-5 h-5 text-primary" />}
                  buttonText="Add Dimensions"
                  handleActionType={() => {
                    setIsShowQuoteDetailModal(true);
                    setActiveTab('dimensions');
                  }}
                />
              )}
            </div>
          )}

          {quoteType === QuoteType.LOAD_BOARD && (
            <LoadPostingCard
              loadLinkPosts={loadLinkPosts}
              setLoadLinkPosts={setLoadLinkPosts}
              activePostIndex={activePostIndex}
              setActivePostIndex={setActivePostIndex}
              loadLinkPostingDetail={loadLinkPostingDetail}
              addressDetails={addressDetails}
              syncLiveData={syncLiveData}
              validatePostToLoadBoardForm={validatePostToLoadBoardForm}
              setValidatePostToLoadBoardForm={setValidatePostToLoadBoardForm}
              setIsPostCreateLoading={setIsPostCreateLoading}
              setSyncLiveData={setSyncLiveData}
              setLoadLinkPostingDetail={setLoadLinkPostingDetail}
              isUpdateLoadPost={isUpdateLoadPost}
              setIsUpdateLoadPost={setIsUpdateLoadPost}
              isCreateLoadPost={isCreateLoadPost}
              setIsCreateLoadPost={setIsCreateLoadPost}
              setIsRefresh={setIsRefresh}
              isRefresh={isRefresh}
              rateGatheringWithAi={rateGatheringWithAi}
              vehicleSizeOptions={vehicleSizeOptions}
              setCurrentTab={setCurrentTab}
              isLoading={isLoading}
              servicesList={servicesList}
            />
          )}

          {quoteType === QuoteType.LANE_HISTORY && <LaneHistory />}

          {quoteType === QuoteType.PREDICTIVE_PRICING && (
            <PredictivePriceChart
              id={id}
              predictivePriceData={predictivePriceData}
              setPredictivePriceData={setPredictivePriceData}
              openAddQuickModal={handleActionType(
                'model',
                'Quick_quote',
                addressDetails
              )}
            />
          )}
        </div>
      </PageSectionLayout>

      <PricingDetails
        isOpen={action.pricingDetails}
        handleClose={handleDrawerClose}
        quote={{ addressDetails, dimensions, services }}
        carrierRate={selectedCarrierRates[0]}
      />

      {isQuoteModalOpen && (
        <MoveToActive
          quoteID={id}
          action={action}
          handleClose={handleQuoteModalClose}
          selectedCarrierRates={selectedCarrierRatesModel}
          currency={currency}
          setIsRefresh={setIsRefresh}
          source="quoteDetail"
          servicesList={servicesList}
        />
      )}
      {action.addQuoteDetail && (
        <AddQuoteDetail
          handleClose={handleDrawerClose}
          carrierRate={selectedCarrierRates[0]}
          selectedCarrierId={selectedCarrierRatesModel?.[0]?.id}
          currency={currency}
          pickupDate={addressDetails?.pickupDate}
          setIsRefresh={setIsRefresh}
          servicesList={servicesList}
        />
      )}

      {action.spotQuoteConfirmation && (
        <SpotQuoteConfirmation
          handleClose={handleModalClose}
          services={services}
          selectedCarrierRate={selectedCarrierRates[0]}
          dimensions={dimensions}
          addressDetails={addressDetails}
          loading={submitLoading}
        />
      )}

      {action.carrierOrderModel && (
        <FinalizeOrderModal
          handleClose={handleModalClose}
          carrierRate={selectedCarrierRates[0]}
          addressDetails={addressDetails}
          setAddressDetails={setAddressDetails}
          dimensions={dimensions}
          currency={currency}
          setIsOrderBooked={setIsOrderBooked}
        />
      )}
      {isShowRefreshRateModal && (
        <RefreshRatesModal
          handleClose={() => setIsShowRefreshRateModal(false)}
          isExpiredRefresh={isExpiredRefresh}
          handleSubmit={(date: any) => {
            setIsShowRefreshRateModal(false);
            setAddressDetails((prevState: any) => ({
              ...prevState,
              pickupDate: moment(date).format('YYYY-MM-DD HH:mm:ss'),
            }));
            setIsGetRate(true);
          }}
        />
      )}
      {isShowConvertOrderModal && (
        <ConvertOrderModal
          handleClose={() => setIsShowConvertOrderModal(false)}
          quotedList={nonExpiredList}
          currency={currency}
          customerId={addressDetails?.customerId}
          handleActionType={handleActionType}
        />
      )}
      {isShowSelectQuotesModal && (
        <SelectQuotesModal
          handleClose={() => setIsShowSelectQuotesModal(false)}
          quotedList={quotedList}
          currency={currency}
          selectedQuoteToGeneratePdfId={selectedQuoteToGeneratePdfId}
          setSelectedQuoteToGeneratePdfId={setSelectedQuoteToGeneratePdfId}
          setIsShowAttachQuotePDFModal={() =>
            setIsShowAttachQuotePDFModal(true)
          }
        />
      )}

      {isShowAttachQuotePDFModal && (
        <AttachQuotePDFModal
          handleClose={() => setIsShowAttachQuotePDFModal(false)}
          selectedDisplayOptions={selectedDisplayOptions}
          setSelectedDisplayOptions={setSelectedDisplayOptions}
          selectedLanguage={selectedLanguage}
          handleLanguageSelect={handleLanguageSelect}
          handleGeneratePdf={handleGeneratePdf}
          isPdfGenerating={isPdfGenerating}
          setIsQuotePdfAttached={setIsQuotePdfAttached}
          isQuotePdfAttached={isQuotePdfAttached}
        />
      )}

      {isShowSendPdfModal &&
        (addressDetails?.emailContentId && quoteEmailDetail?.emailBody ? (
          <ReplyRequestModal
            emailDetails={quoteEmailDetail.emailBody}
            draftEmail={
              'Please find the detailed quote with this attached document provides a comprehensive breakdown of our offerings, pricing, and any relevant terms.'
            }
            draftEmailLoading={false}
            handleClose={() => setIsShowSendPdfModal(false)}
            defaultAttachments={[
              { url: generatedPdfUrl, fileName: generatedPdfName },
            ]}
          />
        ) : (
          <SendQuoteToCustomerModal
            handleClose={() => setIsShowSendPdfModal(false)}
            customerName={addressDetails?.customerName || ''}
            customerEmail={addressDetails?.customerEmail || ''}
            onSubmit={handleSendQuoteToCustomer}
            generatedPdfName={generatedPdfName}
            defaultAttachments={
              isQuotePdfAttached
                ? [{ url: generatedPdfUrl, fileName: generatedPdfName }]
                : []
            }
          />
        ))}

      {isShowCloseReasonModal && (
        <CloseQuoteConfirm
          id={id}
          handleSuccess={handleQuoteClose}
          isLoading={isQuoteCloseLoading}
          handleClose={() => {
            setIsShowCloseReasonModal(false);
          }}
        />
      )}

      {openOverviewOfRateGathering && (
        <OverviewRateGathering
          handleClose={() => setOpenOverviewOfRateGathering(false)}
        />
      )}
    </>
  );
};

export default QuoteDetailPage;
