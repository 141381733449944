import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

const CustomerOrdersTableRow = ({ data }: any) => {
  console.log('🚀 ~ CustomerOrdersTableRow ~ data:', data);

  return (
    <>
      <td className="px-5 py-4 w-[19%] min-w-[19%] max-w-px pl-[64px]">
        <div className="flex min-h-8 items-center">
          <a
            href={`https://wal.roserocket.com/#/ops/orders/${data.roserocketOrderId}`}
            className="text-primary underline font-medium "
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            #{data.orderFullId}
          </a>
        </div>
      </td>
      <td className="px-5 py-4 w-[16%] min-w-[16%] max-w-px">
        {data.orderCommissionees ? (
          <div className="flex items-center gap-2 overflow-hidden">
            {data.orderCommissioneesImage ? (
              <img
                src={`${data.orderCommissioneesImageUrl}${data.orderCommissioneesImage}`}
                className="table-profile-icn-circle-sm"
                alt={data.orderCommissionees}
                title={data.orderCommissionees}
                onError={onError}
              />
            ) : (
              <div className="table-profile-user-circle-sm">
                {getShortName(data.orderCommissionees)}
              </div>
            )}
            {data?.orderCommissionees?.length > 35 ? (
              <TooltipCmp
                message={data.orderCommissionees}
                parentClassName="xs:w-80 w-64"
              >
                <p className="text-grayLight900 font-medium leading-tight max-w-52 truncate">
                  {data.orderCommissionees}
                </p>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate">
                {data.orderCommissionees}
              </h6>
            )}
          </div>
        ) : (
          '-'
        )}
      </td>

      <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px ">
        ${getFormattedNumber(data.combinedRevenue)}
      </td>
      {/* <td className="px-5 py-4 w-28 min-w-28 max-w-28">
        ${getFormattedNumber(data.combinedCost)}
      </td> */}
      <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
        {getFormattedNumber(data.combinedMargin, true, true)}
      </td>
      <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
        {data.combinedMarginPercentage > 0
          ? `${parseFloat(data?.combinedMarginPercentage).toFixed(2)}%`
          : `${0}%`}
      </td>
      <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">-</td>
      <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">-</td>
      <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]"></td>
    </>
  );
};

export default CustomerOrdersTableRow;
