import { ArrowRight, ChevronRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const ActivityOverviewLoadingRow = () => (
  // const { hasRoleV2 } = useRolePermission();

  <>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5">
        <p className="text-grayLight900 font-normal leading-[1.5] custom-loading">
          09:26
        </p>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5">
        <p className="text-grayLight900 font-semibold leading-[1.5] custom-loading">
          #142546
        </p>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5">
        <BadgeCmp style="modern" type="success" mainClassName="custom-loading">
          LTL
        </BadgeCmp>
      </div>
    </td>
    <td className="p-0 max-w-px">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5 flex items-center justify-between gap-x-1.5">
        <div className="flex items-center gap-x-1 truncate">
          <p className="text-grayLight900 font-normal leading-[1.5] truncate custom-loading">
            Ontario
          </p>
          <div className="w-3 h-3 custom-loading">
            <ArrowRight className="w-3 h-3 text-grayLight900 " />
          </div>
          <p className="text-grayLight900 font-normal leading-[1.5] truncate custom-loading">
            Toronto
          </p>
        </div>
        <div className="text-gray500 font-normal leading-[1.5] custom-loading">
          <span className="text-grayLight900 font-semibold">98&nbsp;</span>LBS
        </div>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="text-gray500 font-normal leading-[1.5] border-r border-utilityGray200 my-2.5 px-2.5 ">
        <span className="text-grayLight900 font-semibold custom-loading">
          52&nbsp;
        </span>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="flex items-center justify-between my-2.5 px-2.5 gap-x-2">
        <div className="text-grayLight900 font-semibold leading-[1.5] custom-loading">
          <span className="text-gray500 font-normal">Avg. Rate:&nbsp;</span>
          FT
        </div>
        <div className="w-4 h-4 custom-loading">
          <ChevronRight className="w-4 h-4 text-primary500" />
        </div>
      </div>
    </td>
  </>
);

export default ActivityOverviewLoadingRow;
