import React, { useEffect, useState } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import { GetOutlookConnectedAccountList } from 'src/services/IntegrationService';

import OutlookAccountCard from './OutlookAccountCard';
import OutlookAccountLoadingCard from './OutlookAccountLoadingCard';

const OutlookIntegrationConnectedAccounts = () => {
  const [isRefresh, setIsRefresh] = useState(true);
  const [outlookAccountsList, setOutlookAccountsList] = useState([]);
  const [isOutlookAccountsLoading, setIsOutlookAccountsLoading] =
    useState(true);

  useEffect(() => {
    setIsRefresh(true);
  }, []);

  const getOutlookConnectedAccounts = () => {
    setIsOutlookAccountsLoading(true);

    setOutlookAccountsList([]);
    GetOutlookConnectedAccountList()
      .then((response: any) => {
        const accountList = response.data.map((acc: any) => {
          const cacheData = JSON.parse(acc.cacheData);
          const key = Object.keys(cacheData.Account || {})[0];

          return {
            ...acc,
            cacheData,
            name: key ? cacheData.Account[key].name : null,
            userName: key ? cacheData.Account[key].username : null,
          };
        });
        setOutlookAccountsList(accountList);
      })
      .finally(() => {
        setIsOutlookAccountsLoading(false);
        setIsRefresh(false);
      })
      .catch(console.log);
  };

  useEffect(() => {
    if (isRefresh) {
      getOutlookConnectedAccounts();
    }
  }, [isRefresh]);

  return (
    <div>
      {isOutlookAccountsLoading ? (
        <div className="flex flex-wrap [&>div]:px-1 -mx-1 [&>div]:py-1.5 -my-1.5">
          <OutlookAccountLoadingCard />
          <OutlookAccountLoadingCard />
          <OutlookAccountLoadingCard />
          <OutlookAccountLoadingCard />
          <OutlookAccountLoadingCard />
        </div>
      ) : (
        <>
          {outlookAccountsList?.length ? (
            <div className="flex flex-wrap [&>div]:px-1 -mx-1 [&>div]:py-1.5 -my-1.5">
              {outlookAccountsList.map((outlookAccountData: any) => (
                <OutlookAccountCard
                  key={outlookAccountData.userId}
                  outlookAccountData={outlookAccountData}
                  setIsRefresh={setIsRefresh}
                  totalLinkedAccounts={outlookAccountsList?.length}
                />
              ))}
            </div>
          ) : (
            <NotFoundUI
              title="No Linked Outlook Account"
              desc="No Outlook account is currently linked. Connect your Outlook account to start sending and receiving emails."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          )}
        </>
      )}
    </div>
  );
};

export default OutlookIntegrationConnectedAccounts;
