import {
  AlertCircle,
  AlertHexagon,
  InfoCircle,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AdditionalServiceModal from 'src/app/QuotingHub/Common/AdditionalServiceModal';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import ConfirmModal from 'src/components/ConfirmModal';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import {
  CURRENCY,
  FOOT,
  KGS,
  LBS,
  MEASUREMENT,
  QUOTE_CLASSIFICATION,
} from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import { getPricingRate } from 'src/services/CommonService';
import { getRoundUpLinearFootage } from 'src/utils/CommonFunctions';

// import QuoteDetailLoading from './QuoteDetailLoading';
import { isValidQuoteDetail } from './ValidationFunction';
// import { createUpdateService } from 'src/services/QuoteService';

interface IFormData {
  linearFootage: string | number | undefined;
  totalWeight: string | number | undefined;
  classification: string | null;
  primaryEquipmentType: any;
  primaryEquipmentTypeFullName: any[];
  secondaryEquipmentTypeFullNames: any[];
  secondaryEquipmentTypes: any[];
}
const initServiceValidForm = {
  // limitedAccessOther: true,
  inbondName: true,
  inbondAddress: true,
  inbondContactValue: true,
  unitedNationsNumber: true,
  isEmailValid: true,
  isPhoneValid: true,
  goodsClass: true,
  description: true,
  emergencyContactName: true,
  emergencyContactNumber: true,
  isEmergencyPhoneValid: true,
  declaredValue: true,
  isLoadInsuranceValid: true,
};

const initFormData: IFormData = {
  linearFootage: '',
  totalWeight: '',
  classification: '',
  primaryEquipmentType: '',
  primaryEquipmentTypeFullName: [],
  secondaryEquipmentTypeFullNames: [],
  secondaryEquipmentTypes: [],
};

// const tabArray: any = [
//   {
//     value: 'address',
//     name: 'Address',
//     isAllow: true,
//   },
//   {
//     value: 'quote_detail',
//     name: 'Quote Details',
//     isAllow: true,
//   },
//   {
//     value: 'dimensions',
//     name: 'Dimensions',
//     isAllow: true,
//   },
// ];

const inbondTypeList = [
  {
    name: '(IT) Immediate Transportation Bond',
    value: 'immediate-exportation',
  },
  {
    name: '(T&E) Transportation & Export Bond',
    value: 'transportation-and-exportation',
  },
];

const inbondContactMethodList = [
  { name: 'Email', value: 'email-address' },
  { name: 'Phone', value: 'phone-number' },
  { name: 'Fax Number', value: 'fax-number' },
];

const dangerTypeList = [
  { name: 'Limited Quantity', value: 'limited-quantity' },
  { name: '500 kg Exemption', value: 'exemption-500-kg' },
  { name: 'Fully Regulated', value: 'fully-regulated' },
];

const packagingGroupOptions = [
  { label: 'PG I', value: 'PGI' },
  { label: 'PG II', value: 'PGII' },
  { label: 'PG III', value: 'PGIII' },
];

const initAction = {
  tailgate: false,
  unusual: false,
};

const initValidForm = {
  totalWeight: true,
  linearFootage: true,
  totalWeightMax: true,
  totalWeightMin: true,
  linearFootageMax: true,
  linearFootageMin: true,
  primaryEquipmentType: true,
};

/* eslint-disable max-lines-per-function */
const QuoteDetails = ({
  tabArray,
  setSubmitAction,
  updateQuoteDetail,
  setQuoteFormData,
  quoteFormData,
  quoteId,
  isQuoteLoading,
  activeTab,
  setActiveTab,
  quoteData,
  isUpdateLoading,
  setIsUpdateLoading,
  handleClose,
  serviceTypes,
  // serviceList,
  isEquipmentTypeLoading,
  equipmentTypeOptions,
  validFormDetail,
  isViewQuote = false,
  setIsDisplayDimensionsDetail = () => {},
  isServiceLoading,
  setIsLinearFootageManuallyChanged,
}: any) => {
  const [formData, setFormData] = useState<IFormData>({
    ...initFormData,
    ...quoteFormData.addressDetail,
    linearFootage: quoteFormData.addressDetail?.linearFootage || '',
  });
  const [validForm, setValidForm] = useState(initValidForm);
  const [rates, setRates] = useState<any>({});
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [selectedServices, setSelectedServices] = useState<number[]>(
    quoteFormData?.services?.services ?? []
  );

  const [isLoading, setIsLoading] = useState(isQuoteLoading);
  const [isManuallyChanged, setIsManuallyChanged] = useState(false);

  const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
    useState<boolean>(false);
  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');

  const [isDeclaredValueMore, setIsDeclaredValueMore] =
    useState<boolean>(false);
  const [declaredValue, setDeclaredValue] = useState<any>(0);

  const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
    useState<boolean>(false);

  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>('');
  const [isDangerousFormSubmitted, setIsDangerousFormSubmitted] =
    useState<boolean>(false);
  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const { currency } = useContext(BasicContext);
  const [declaredValueUnit, setDeclaredValueUnit] = useState(currency);
  const [action, setAction] = useState(initAction);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [isValid] = useState(true);
  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);
  // const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(false);
  // const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);

  const [isLoadInsurance, setIsLoadInsurance] = useState<boolean>(false);
  const [loadInsuranceValue, setLoadInsuranceValue] = useState<any>(
    quoteFormData?.addressDetail?.insuranceCommercialValue ?? 0
  );
  const [loadInsuranceValueUnit, setLoadInsuranceValueUnit] = useState(
    quoteFormData?.addressDetail?.insuranceCurrencyType ??
      currency ??
      CURRENCY.CAD
  );
  const [loadInsuranceValueWithUnit, setLoadInsuranceValueWithUnit] = useState({
    loadInsuranceValueCAD: 0,
    loadInsuranceValueUSD: 0,
  });
  const [isLoadInsuranceModalOpen, setIsLoadInsuranceModalOpen] =
    useState<boolean>(false);

  const filterSelectArr: any = [
    {
      value: CURRENCY.CAD,
      label: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      label: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  useEffect(() => {
    setSubmitAction((old: any) => ({
      ...old,
      isUpdateQuoteDetail: true,
    }));

    if (validFormDetail) {
      setValidForm({ ...validForm, ...validFormDetail });
    }

    let serviceForm = quoteFormData.services;
    setAllowHandleChecked(false);

    if (serviceForm) {
      if (serviceForm?.inbond) {
        setIsInbondMore(serviceForm?.inbond);
        setInbondType(serviceForm.inbondType ?? false);
        setInbondName(serviceForm.inbondName);
        setInbondAddress(serviceForm.inbondAddress);
        setInbondContactMethod(serviceForm.inbondContactMethod);
        setInbondContactValue(serviceForm.inbondContactValue);
      }

      if (serviceForm?.isDeclaredValue) {
        setIsDeclaredValueMore(serviceForm?.isDeclaredValueMore);
        setDeclaredValueUnit(serviceForm.declaredValueUnit);
        setDeclaredValue(serviceForm.declaredValue);
      }

      if (serviceForm?.dangerousGoods) {
        setIsDangerousMore(serviceForm?.dangerousGoods);
        setDangerType(serviceForm.dangerGoodsType);
        setPackagingGroup(serviceForm.packagingGroup);
        setUnitedNationsNumber(serviceForm.unitedNationsNumber);
        setGoodsClass(serviceForm.goodsClass);
        setEmergencyContactName(serviceForm.emergencyContactName);
        setEmergencyContactNumber(serviceForm.emergencyContactNumber);
        setEmergencyContactExt(serviceForm.emergencyContactExt);
        setDescription(serviceForm.description);
      }

      if (serviceForm?.isLoadInsurance) {
        setLoadInsuranceValueWithUnit({
          ...loadInsuranceValueWithUnit,
          ...{
            loadInsuranceValueCAD:
              quoteFormData?.addressDetail?.insuranceCurrencyType === 0
                ? quoteFormData?.addressDetail?.insuranceCommercialValue
                : '',
            loadInsuranceValueUSD:
              quoteFormData?.addressDetail?.insuranceCurrencyType === 1
                ? quoteFormData?.addressDetail?.insuranceCommercialValue
                : '',
          },
        });

        // setLoadInsuranceValueUnit(
        //   quoteFormData?.addressDetail?.insuranceCurrencyType
        // );
        // setLoadInsuranceValue(
        //   quoteFormData?.addressDetail?.insuranceCommercialValue
        // );
      }
    }
  }, []);

  useEffect(() => {
    if (!allowHandleChecked && !isLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isLoading]);

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        console.error('Pricing rates error');
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  useEffect(() => {
    if (isInbondMore && allowHandleChecked) {
      setIsInbondMoreModalOpen(true);
    }
  }, [isInbondMore]);

  useEffect(() => {
    if (isLoadInsurance && allowHandleChecked) {
      setIsLoadInsuranceModalOpen(true);
    }
  }, [isLoadInsurance]);

  useEffect(() => {
    if (isDangerousMore && allowHandleChecked) {
      setIsDangerousMoreModalOpen(true);
    }
  }, [isDangerousMore]);

  const isDangerousGoodsFormValid = () => {
    let valid = true;
    const validFormData = {
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyPhoneNumber === '' || emergencyPhoneNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      } else {
        if (!validServicesForm.isEmergencyPhoneValid) {
          validFormData.isEmergencyPhoneValid = false;
          valid = false;
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const getQuoteInfo = () => {
    setAllowHandleChecked(false);

    if (!isQuoteLoading && !isLoading) {
      const { dimensions, addressDetails } = quoteData;

      if (addressDetails) {
        setFormData((old: any) => ({
          ...old,
          ...quoteFormData.addressDetail,
        }));
      }

      if (dimensions) {
        setWeightMeasurement(dimensions[0].weightMeasurement);
      }
    }
  };

  useEffect(() => {
    if (quoteId && !isLoading) {
      getQuoteInfo();
    }
  }, [quoteId, isLoading]);

  useEffect(() => {
    if (
      !isManuallyChanged &&
      quoteFormData?.addressDetail?.linearFootage !== formData.linearFootage
    ) {
      setFormData((prev) => ({
        ...prev,
        linearFootage: quoteFormData.addressDetail?.linearFootage || '',
      }));
    }
  }, [
    quoteFormData.addressDetail?.linearFootage,
    isManuallyChanged,
    formData.linearFootage,
  ]);

  useEffect(() => {
    console.log('selectedServiceArr', selectedServices);
    setQuoteFormData((old: any) => ({
      ...old,

      services: {
        ...old.services, // Preserve existing properties (quoteId, serviceId, etc.)
        services: selectedServices, // Update the nested services array
      },
    }));
  }, [selectedServices]);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      // if (
      //   e.target.name === 'tailgate_pickup' ||
      //   e.target.name === 'tailgate_delivery'
      // ) {
      //   setIsTailgate((old: any) => [...old, value]);
      // }

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }

      if (e.target.name === 'load_insurance') {
        setIsLoadInsurance(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      // setIsTailgate((current: any) =>
      //   current.filter((item: any) => item !== value)
      // );

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        // setInbondContactMethod(inbondContactMethodList[0].value);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(false);
      }

      if (e.target.name === 'load_insurance') {
        setIsLoadInsurance(false);
      }
    }
  };

  const getList = (data: any) => (
    <div className="flex flex-wrap -m-1">
      {data.map((val: any) =>
        quoteFormData?.addressDetail?.classification ===
          QUOTE_CLASSIFICATION.FTL ||
        val?.slug === 'load_insurance' ||
        (quoteFormData?.addressDetail?.classification !==
          QUOTE_CLASSIFICATION.FTL &&
          val.loadLinkTerm !== null) ? (
          <div
            className="flex cursor-pointer space-x-1.5 p-1 w-1/3"
            key={`service_${val.id}`}
          >
            <CheckBox
              id={`service_${val.id}`}
              onChangeFunc={handleChange}
              name={val.slug}
              value={val.id}
              checked={selectedServices.includes(val.id)}
              parentClassName="mt-px"
              disabled={isViewQuote}
              // disabled={val.isOnlyForFTL}
            />
            <div className="leading-[0.9] space-x-1.5 flex items-center truncate  ">
              <label
                htmlFor={`service_${val.id}`}
                className={`leading-4 text-textSecondary text-xs font-medium truncate cursor-pointer `}
              >
                {val.name}
              </label>
            </div>
          </div>
        ) : (
          ''
        )
      )}
    </div>
  );

  const getName = (contactMethod: string) => {
    if (contactMethod === 'fax-number') {
      return 'Fax';
    } else if (contactMethod === 'phone-number') {
      return 'Phone';
    } else {
      return 'Email';
    }
  };

  const parsedPhoneNumber = inbondContactValue
    ? parsePhoneNumberFromString(inbondContactValue)
    : null;

  const defaultValues = {
    phone: parsedPhoneNumber?.nationalNumber || '',
    phone_country_code: parsedPhoneNumber?.country || '',
  };

  const emergencyContactNo = emergencyContactNumber
    ? parsePhoneNumberFromString(emergencyContactNumber)
    : null;

  const defaultEmergencyPhoneValues = {
    phone: emergencyContactNo?.nationalNumber || '',
    phone_country_code: emergencyContactNo?.country || '',
  };

  const isLoadInsuranceFormValid = () => {
    let valid = true;
    const validFormData = {
      isLoadInsuranceValid: true,
    };

    if (!loadInsuranceValue) {
      validFormData.isLoadInsuranceValid = false;
      valid = false;
    }
    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isInBondFormValid = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      isEmailValid: false,
      isPhoneValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        validFormData.inbondContactValue = true;

        if (getName(inbondContactMethod) === 'Email') {
          if (emailRegex.test(inbondContactValue)) {
            validFormData.isEmailValid = true;
          } else {
            validFormData.isEmailValid = false;
            valid = false;
          }
        } else {
          validFormData.isEmailValid = true;
        }

        if (getName(inbondContactMethod) === 'Phone') {
          if (!validServicesForm.isPhoneValid) {
            validFormData.isPhoneValid = false;
            valid = false;
          }

          if (!validServicesForm.inbondContactValue) {
            validFormData.inbondContactValue = false;
            valid = false;
          }
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;
    setInbondContactValue('');

    if (name === 'contactMethod') {
      // isInBondFormValid();

      setServicesValidForm((old) => ({
        ...old,
        inbondName: true,
        inbondAddress: true,
        inbondContactValue: true,
        unitedNationsNumber: true,
        isEmailValid: true,
        isPhoneValid: true,
      }));

      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string
  ) => {
    if (phone) {
      defaultValues.phone_country_code = country;
      setInbondContactValue(`+${code}${phone}`);
    }
  };

  const handleNumberOnBlur = (
    country: CountryCode,
    phone: string,
    code: string,
    isPhoneValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
      inbondContactValue: true,
    };

    if (phone === '') {
      validFormData.phone = false;
      validFormData.isPhoneValid = false;
      validFormData.inbondContactValue = false;
    } else {
      validFormData.isPhoneValid = isPhoneValid;
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));
  };

  const handleDangerPhoneChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isPhoneValid: boolean
  ) => {
    var validFormData = {
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };
    defaultEmergencyPhoneValues.phone_country_code = country;
    setEmergencyContactNumber(`+${code}${phone}`);
    setEmergencyPhoneNumber(`${phone}`);

    if (phone === '') {
      validFormData.emergencyContactNumber = false;
      validFormData.isEmergencyPhoneValid = false;
    } else {
      validFormData.isEmergencyPhoneValid = isPhoneValid;
    }

    setServicesValidForm({ ...validServicesForm, ...validFormData });
  };

  const isFormValid = () => {
    let quoteDetailValidObj = isValidQuoteDetail(quoteFormData?.addressDetail);
    setValidForm({ ...validForm, ...quoteDetailValidObj?.validFormData });
    // setIsValid(valid);

    return quoteDetailValidObj?.valid;
  };

  useEffect(() => {
    setQuoteFormData((old: any) => ({
      ...old,
      addressDetail: {
        ...old.addressDetail,
        ...formData,
      },
    }));
    // isFormValid();
  }, [formData]);

  const validation = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      isEmailValid: true,
      isPhoneValid: true,
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
      declaredValue: true,
      isLoadInsuranceValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        validFormData.inbondContactValue = false;

        if (
          getName(inbondContactMethod) === 'Email' &&
          !emailRegex.test(inbondContactValue)
        ) {
          validFormData.isEmailValid = false;
          valid = false;
        }
      }
    }

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyContactNumber === '' || emergencyContactNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      }
    }

    if (isDeclaredValueMore) {
      if (!declaredValue) {
        validFormData.declaredValue = false;
        valid = false;
      }
    }

    if (isLoadInsurance) {
      if (!loadInsuranceValue) {
        validFormData.isLoadInsuranceValid = false;
        valid = false;
      }
    }
    setServicesValidForm(validFormData);

    return valid;
  };

  const handleQuoteFormData = () => {
    let formDataCurrent: any = {
      services: selectedServices,
      quoteId: parseInt(quoteId),
      serviceId: selectedServices.join(','),
      isDangerousMore: isDangerousMore,
      isInbondMore: isInbondMore,
      isDeclaredValueMore: isDeclaredValueMore,
      modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
    };

    if (isInbondMore) {
      formDataCurrent.inbondType = inbondType;
      formDataCurrent.inbondName = inbondName;
      formDataCurrent.inbondAddress = inbondAddress;
      formDataCurrent.inbondContactMethod = inbondContactMethod;
      formDataCurrent.inbondContactValue = inbondContactValue;
    }

    if (isDangerousMore) {
      formDataCurrent.dangerGoodsType = dangerType;
      formDataCurrent.goodsClass = goodsClass;
      formDataCurrent.description = description;
      formDataCurrent.unitedNationsNumber = unitedNationsNumber;
      formDataCurrent.packagingGroup = packagingGroup;
      formDataCurrent.emergencyContactName = emergencyContactName;
      formDataCurrent.emergencyContactNumber = emergencyContactNumber;
      formDataCurrent.emergencyContactExt = emergencyContactExt ?? '';
    }

    if (isDeclaredValueMore) {
      formDataCurrent.declaredValue = declaredValue;
      formDataCurrent.isDeclaredValue = isDeclaredValueMore;
      formDataCurrent.currency = declaredValueUnit;
    }

    const updatedAddressDetail =
      loadInsuranceValue !== 0 && loadInsuranceValue !== null
        ? {
            ...formData,
            insuranceCommercialValue: loadInsuranceValue,
            insuranceCurrencyType: loadInsuranceValueUnit,
          }
        : { ...formData };

    setQuoteFormData((old: any) => ({
      ...old,
      addressDetail: {
        ...old.addressDetail,
        ...updatedAddressDetail,
      },
      services: {
        ...old.services,
        ...formDataCurrent,
      },
    }));
  };

  useEffect(() => {
    handleQuoteFormData();
  }, [formData, activeTab]);

  const handleSubmit = (event: any) => {
    event?.preventDefault();

    if (isViewQuote) {
      setIsDisplayDimensionsDetail(false);

      return;
    }

    if (!isFormValid() || !validation()) {
      return true;
    }
    setIsLoading(false);
    setIsUpdateLoading(true);
    handleQuoteFormData();

    if (activeTab === 'quote_detail') {
      setTimeout(() => {
        updateQuoteDetail();
      }, 100);
    }
    // if (isTailgate.length && (isTotalWeightOver || isItemLengthOver)) {
    //   setIsLoading(false);
    //   handleServiceActionType('tailgate');
    // } else {
    // handleActionType('unusual');
    // submitService();
    // }
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        updateQuoteDetail();
        // if (action.unusual) {
        //     submitService();
        // } else {
        //     // handleActionType('unusual');
        //     submitService();
        // }
      } else {
        setIsUpdateLoading(false);
      }
    },
    [action]
  );

  const [
    isShowAdditionalServiceInfoModal,
    setIsShowAdditionalServiceInfoModal,
  ] = useState(false);

  const handleLinearFootageBlur = (value: any) => {
    if (!isNaN(value) && value > 0) {
      const roundedValue = getRoundUpLinearFootage(value);

      setFormData((old: any) => ({
        ...old,
        linearFootage: roundedValue,
      }));

      setQuoteFormData((prev: any) => ({
        ...prev,
        addressDetail: {
          ...prev.addressDetail,
          linearFootage: roundedValue,
        },
      }));
    }
  };

  return (
    <>
      <CommonModal
        title={isViewQuote ? 'View Quote Details' : 'Edit Quote Details'}
        titleDesc={
          isViewQuote
            ? 'Get a breakdown of your quote and shipment details.'
            : 'Enter total weight for accurate pricing.'
        }
        handleClose={() => handleClose()}
        headerIcon={<PackagePlus className="w-5 h-5" />}
        size={' max-w-[745px] h-[85vh] '}
        primaryBtnText={isViewQuote ? 'Edit' : 'Save Changes'}
        secondaryBtnText="Cancel"
        primaryBtnDisabled={isLoading || isUpdateLoading}
        primaryBtnLoading={isUpdateLoading}
        primaryBtnOnClick={handleSubmit}
        secondaryBtnOnClick={() => handleClose(false)}
      >
        <div className="px-5 pb-5 space-y-4">
          <TabButton
            tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px"
            parentClassName={`w-full ${isLoading ? 'custom-loading' : ''}`}
            className={`flex-1 ${!isValid ? '!cursor-not-allowed' : ''}`}
            tabArray={tabArray.filter((e: any) => e.isAllow)}
            isActive={activeTab}
            isTab={true}
            handleOnClick={(e: any) => {
              if (isValid) setActiveTab(e.target.dataset.value);
            }}
          />

          <div className="space-y-1.5 !mt-0">
            <h6 className="text-grayLight900 text-xs font-medium">
              Load Details
            </h6>
            <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2">
              <>
                <InputText
                  parentClassName="flex-1"
                  inputWrapClass={isLoading ? 'custom-loading' : ''}
                  label="Total Weight"
                  inputName="totalWeight"
                  inputType="number"
                  placeholder={'Enter total weight'}
                  labelClassName="mb-1.5 block"
                  className={`${
                    !validForm?.totalWeight
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  value={formData.totalWeight}
                  shouldFormatNumber={true}
                  inputValueUnitType="lbs"
                  required
                  onChangeFunc={(event) => {
                    setFormData((old: any) => ({
                      ...old,
                      totalWeight: event?.target?.value,
                    }));
                    setValidForm((prev) => ({
                      ...prev,
                      totalWeight: true,
                      totalWeightMin:
                        event?.target?.value > 0 || event?.target?.value === '',
                      totalWeightMax: event?.target?.value <= 100000,
                    }));
                  }}
                  errorText={
                    !validForm.totalWeightMin
                      ? 'Total weight should not be 0'
                      : !validForm.totalWeightMax
                      ? 'Total weight should be less than 100,000 lbs'
                      : ''
                  }
                  disabled={isViewQuote}
                  isShowDisableClass={false}
                />
                {formData?.classification === QUOTE_CLASSIFICATION.LTL ||
                formData?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL ? (
                  <InputText
                    parentClassName="flex-1"
                    inputWrapClass={isLoading ? 'custom-loading' : ''}
                    label="Linear Footage"
                    inputName="linearFootage"
                    inputType="number"
                    className={`${
                      !validForm?.linearFootage
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    placeholder={'Enter linear footage'}
                    labelClassName="mb-1.5 block"
                    value={formData.linearFootage}
                    required={
                      formData?.classification !== QUOTE_CLASSIFICATION.FTL
                    }
                    shouldFormatNumber={true}
                    onChangeFunc={(event) => {
                      setValidForm((prev) => ({
                        ...prev,
                        linearFootage: true,
                        linearFootageMin:
                          event?.target?.value > 0 ||
                          event?.target?.value === '',
                        linearFootageMax: event?.target?.value <= 53,
                      }));
                      setFormData((old: any) => ({
                        ...old,
                        linearFootage: event?.target?.value,
                      }));

                      setQuoteFormData((prev: any) => ({
                        ...prev,
                        addressDetail: {
                          ...prev.addressDetail,
                          linearFootage: event?.target?.value,
                        },
                      }));

                      setIsLinearFootageManuallyChanged(true);
                      setIsManuallyChanged(true);
                    }}
                    onBlur={(event) => {
                      if (event?.target?.value) {
                        const value = parseFloat(event.target.value);
                        handleLinearFootageBlur(value);
                      }
                    }}
                    inputValueUnitType="ft"
                    errorText={
                      !validForm.linearFootageMin
                        ? 'Linear footage should not be 0'
                        : !validForm.linearFootageMax
                        ? 'Linear footage should be less than 53 Ft.'
                        : ''
                    }
                    disabled={isViewQuote}
                    isShowDisableClass={false}
                  />
                ) : (
                  false
                )}
              </>
              {/* )} */}
            </div>
          </div>

          <div className="space-y-1.5">
            <h6 className="text-grayLight900 text-xs font-medium">
              Equipment Types
            </h6>
            <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 space-y-3">
              {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
              formData?.classification === QUOTE_CLASSIFICATION.LTL ||
              formData?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL ? (
                <SelectBox
                  parentClassName="w-full min-w-[166px] flex-1"
                  isClearable
                  required
                  name="primaryEquipmentType"
                  placeholder="Select eqpt. type"
                  label="Primary Equipment Type"
                  labelClassName="form_label mb-1.5 block"
                  options={equipmentTypeOptions}
                  isDisabled={isViewQuote}
                  value={equipmentTypeOptions.find(
                    (item: any) => formData.primaryEquipmentType === item.value
                  )}
                  isLoading={isEquipmentTypeLoading}
                  isMultipleSelection={false}
                  onChangeFunc={(event: any) => {
                    setValidForm((old: any) => ({
                      ...old,
                      primaryEquipmentType: true,
                    }));

                    const secondaryEquipmentTypesWithOutPrimary =
                      formData?.secondaryEquipmentTypes?.length > 0
                        ? formData?.secondaryEquipmentTypes?.filter(
                            (set: any) => set !== event?.value
                          )
                        : [];
                    const secondaryEquipmentTypeFullNamesWithOutPrimary =
                      formData?.secondaryEquipmentTypeFullNames?.length > 0
                        ? formData?.secondaryEquipmentTypeFullNames?.filter(
                            (setf: any) => setf !== event?.label
                          )
                        : [];
                    setFormData((old: any) => ({
                      ...old,
                      primaryEquipmentType: event?.value,
                      primaryEquipmentTypeFullName: event?.label,
                      secondaryEquipmentTypes:
                        secondaryEquipmentTypesWithOutPrimary,
                      secondaryEquipmentTypeFullNames:
                        secondaryEquipmentTypeFullNamesWithOutPrimary,
                    }));
                  }}
                  errorText={!validForm.primaryEquipmentType ? true : false}
                />
              ) : (
                false
              )}

              {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
              formData?.classification === QUOTE_CLASSIFICATION.LTL ||
              formData?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL ? (
                <SelectBox
                  parentClassName="w-full min-w-[166px] flex-1"
                  isClearable
                  name="secondaryEquipmentTypes"
                  placeholder="Select eqpt. types"
                  label="Secondary Equipment Types"
                  labelClassName="form_label mb-1.5 block"
                  isDisabled={isViewQuote}
                  options={equipmentTypeOptions.filter(
                    (eto: any) => eto.value !== formData.primaryEquipmentType
                  )}
                  value={equipmentTypeOptions.filter((item: any) =>
                    formData.secondaryEquipmentTypes?.includes(item.value)
                  )}
                  isLoading={isEquipmentTypeLoading}
                  isMultipleSelection={true}
                  closeMenuOnSelect={false}
                  onChangeFunc={(event: any) => {
                    const newValue = event.map((e: any) => e.value);
                    const newLabel = event.map((e: any) => e.label);
                    setFormData((old) => ({
                      ...old,
                      secondaryEquipmentTypes: newValue,
                      secondaryEquipmentTypeFullNames: newLabel,
                    }));
                  }}
                />
              ) : (
                false
              )}
            </div>
          </div>

          {/* {serviceList.length ? ( */}
          <>
            <div className="space-y-1.5">
              <div className="flex items-center gap-x-1.5 mb-1.5">
                <h6 className="text-textSecondary text-xs font-medium">
                  Other Additional Services
                </h6>
                <div
                  className={`cursor-pointer ${
                    isServiceLoading ? 'custom-loading' : ''
                  }`}
                  onClick={() => {
                    setIsShowAdditionalServiceInfoModal(true);
                  }}
                >
                  <InfoCircle className="h-3.5 w-3.5 cursor-pointer text-primary" />
                </div>
              </div>

              <div className="rounded-lg border border-utilityGray200 bg-gray25 shadow-sm p-3">
                {!isServiceLoading && serviceTypes.OTHER?.length ? (
                  getList(serviceTypes.OTHER)
                ) : isServiceLoading ? (
                  <div className="flex flex-wrap -m-1">
                    {Array.from({ length: 12 }).map((_, index) => (
                      <div className="flex p-1 w-1/3" key={`service_${index}`}>
                        <CheckBox
                          id={`service_abc`}
                          onChangeFunc={handleChange}
                          name="abc"
                          value="xyz"
                          checked={false}
                          parentClassName="mt-0.5 custom-loading"
                        />
                        <div className="flex leading-4">
                          <label
                            htmlFor={`service_abc`}
                            className={`md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary font-medium xxl:me-2.5 me-1.5 custom-loading`}
                          >
                            fdgjdflkjgkldjflgdl
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <NotFoundUI
                    iconClassName="!hidden"
                    title="No services found"
                    desc={`No Other Services found. Please try again.`}
                    containerClassName="!h-auto !my-5"
                  />
                )}
              </div>
            </div>
          </>
          {/* ) : (
            <div className="flex justify-center mt-4">
              {isLoading ? (
                <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
              ) : (
                false
              )}
            </div>
          )} */}

          {action.tailgate && (
            <ConfirmModal
              title="Tailgate Over Dimensions"
              description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
                weightMeasurement === MEASUREMENT.WEIGHT1.value
                  ? `${LBS.MAX_WEIGHT}lbs`
                  : `${KGS.MAX_WEIGHT}kgs`
              }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
              button1="Yes, I am sure"
              button2="Modify Selection"
              handleClose={handleModalClose}
            />
          )}
          {action.unusual && (
            <ConfirmModal
              title="Unusual selection"
              description="Based on the options you selected, a lot of carriers might not give a direct quote for this load. Are you sure you want to proceed?"
              button1="Yes, I am sure"
              button2="Modify Selection"
              handleClose={handleModalClose}
            />
          )}
        </div>
      </CommonModal>
      {isInbondMoreModalOpen && (
        <CommonModal
          title={'In-Bond Details'}
          titleDesc={'Please fill In-Bond Details'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="in-bond"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsInbondMore(false);
            setIsInbondMoreModalOpen(false);
          }}
          headerIcon={<PackagePlus />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (isInBondFormValid()) {
              setIsInbondMoreModalOpen(false);
            }
            handleQuoteFormData();
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="in-bond"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsInbondMore(false);
            setIsInbondMoreModalOpen(false);
          }}
        >
          <div className="p-5 ">
            <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
              {inbondTypeList.map((val: any, i) => (
                <div className="flex gap-2" key={`inbond_${i}`}>
                  <Radio
                    id={`inbond_${i}`}
                    onChangeFunc={handleChangeRadio}
                    inputName={'inbondName'}
                    value={val.value}
                    checked={inbondType === val.value}
                  />

                  <label
                    htmlFor={`inbond_${i}`}
                    className="text-textSecondary text-xs cursor-pointer font-medium"
                  >
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="w-full mb-4">
              <InputText
                inputName="inbondName"
                required={true}
                placeholder=""
                className={`form_control ${
                  !validServicesForm.inbondName
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Warehouse or Carrier who will cancel US Bond"
                labelClassName="block mb-1.5"
                value={inbondName}
                icon={
                  !validServicesForm.inbondName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                  ) : null
                }
                // errorText={!validServicesForm.inbondName ? 'This field is required' : null}
                onChangeFunc={(e) => {
                  setInbondName(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full mb-5">
              <InputText
                inputName="inbondAddress"
                placeholder="123 Address"
                required={true}
                className={`form_control ${
                  !validServicesForm.inbondAddress
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Address"
                labelClassName="block mb-1.5"
                icon={
                  !validServicesForm.inbondName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                  ) : null
                }
                value={inbondAddress}
                // errorText={!validServicesForm.inbondAddress ? 'This field is required' : null}
                onChangeFunc={(e) => {
                  setInbondAddress(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full mb-4">
              {getName(inbondContactMethod) !== 'Phone' ? (
                <InputText
                  inputName="inbondContactValue"
                  // inputType={getName(inbondContactMethod) === "Phone" ? "number" : "text"}
                  placeholder=""
                  required={true}
                  className={`form_control ${
                    !validServicesForm.inbondContactValue
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  icon={
                    !validServicesForm.inbondContactValue ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  label={getName(inbondContactMethod)}
                  labelClassName="block mb-1.5"
                  value={inbondContactValue}
                  errorText={
                    !validServicesForm.inbondContactValue
                      ? 'This field is required'
                      : !validServicesForm.isEmailValid &&
                        getName(inbondContactMethod) === 'Email'
                      ? 'enter valid email'
                      : ''
                  }
                  onChangeFunc={(e) => {
                    setInbondContactValue(e.target.value);
                    setServicesValidForm((old) => ({
                      ...old,
                      [e.target.name]: true,
                    }));
                  }}
                />
              ) : (
                <PhoneInput
                  onNumberChange={handleNumberChange}
                  name="phone"
                  errors={
                    !validServicesForm.inbondContactValue
                      ? 'Phone number is required'
                      : !validServicesForm.isPhoneValid &&
                        getName(inbondContactMethod) === 'Phone'
                      ? 'Invalid Number'
                      : null
                  }
                  onBlur={handleNumberOnBlur}
                  value={inbondContactValue || ''}
                />
              )}
            </div>
            <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px]">
              {inbondContactMethodList.map((val: any, i) => (
                <div className="flex gap-2" key={`inbond_contact_${i}`}>
                  <Radio
                    id={`inbond_contact_${i}`}
                    onChangeFunc={handleChangeRadio}
                    inputName={'contactMethod'}
                    value={val.value}
                    checked={inbondContactMethod === val.value}
                  />
                  <label
                    htmlFor={`inbond_contact_${i}`}
                    className="text-textSecondary text-xs cursor-pointer font-medium uppercase"
                  >
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </CommonModal>
      )}
      {isDangerousMoreModalOpen && (
        <CommonModal
          title={'Dangerous Goods'}
          titleDesc={'Please provide dangerous goods details'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="dangerous_goods"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsDangerousFormSubmitted(false);
            setUnitedNationsNumber('');
            setGoodsClass('');
            setDescription('');
            setEmergencyContactName('');
            setEmergencyContactNumber('');
            setEmergencyContactExt('');
            setIsDangerousMore(false);
            setIsDangerousMoreModalOpen(false);
          }}
          headerIcon={<AlertHexagon />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            setIsDangerousFormSubmitted(true);

            if (isDangerousGoodsFormValid()) {
              setIsDangerousMoreModalOpen(false);
            }
            handleQuoteFormData();
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="dangerous_goods"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsDangerousFormSubmitted(false);
            setUnitedNationsNumber('');
            setGoodsClass('');
            setDescription('');
            setEmergencyContactName('');
            setEmergencyContactNumber('');
            setEmergencyContactExt('');
            setIsDangerousMore(false);
            setIsDangerousMoreModalOpen(false);
          }}
        >
          <div className="p-5 flex flex-col md:gap-5 gap-3">
            <p className="text-grayLight600 font-normal text-xs ">
              Please provide the Dangerous Goods details, as these details will
              show up on the BOL. Failure to enter this data may result in
              delayed pickups.
            </p>
            <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-5 xl:gap-4 gap-3 py-[12.5px] ">
              {dangerTypeList.map((val: any, i) => (
                <div className="flex gap-2" key={`danger_${i}`}>
                  <Radio
                    id={`danger_${i}`}
                    onChangeFunc={handleChangeRadio}
                    inputName={'dangerType'}
                    value={val.value}
                    checked={dangerType === val.value}
                  />
                  <label
                    htmlFor={`danger_${i}`}
                    className="text-textSecondary text-xs font-medium uppercase cursor-pointer"
                  >
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="grid sm:grid-cols-3 xxl:gap-5 xl:gap-4 gap-3 ">
              <InputText
                inputName="unitedNationsNumber"
                placeholder="UN0000"
                className={`form_control ${
                  !validServicesForm.unitedNationsNumber
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                required={true}
                label="UN #"
                labelClassName="block mb-1.5"
                value={unitedNationsNumber}
                icon={
                  !validServicesForm.inbondName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                // errorText={!validServicesForm.unitedNationsNumber ? 'This field is required' : null}
                onChangeFunc={(e) => {
                  setUnitedNationsNumber(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />

              <div className="content">
                <SelectBox
                  name="packagingGroup"
                  label="Packaging Group"
                  labelClassName="form_label block mb-1.5"
                  id="packagingGroup"
                  className="form_control"
                  options={packagingGroupOptions}
                  onChangeFunc={(event: any) => setPackagingGroup(event.value)}
                  value={packagingGroupOptions.find(
                    (val) => val.value === packagingGroup
                  )}
                />
              </div>

              <InputText
                inputType="number"
                inputName="goodsClass"
                placeholder=""
                className={`form_control ${
                  !validServicesForm.goodsClass
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Class"
                required={true}
                labelClassName="block mb-1.5"
                value={goodsClass}
                icon={
                  !validServicesForm.goodsClass ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                shouldFormatNumber={true}
                // errorText={!validServicesForm.goodsClass ? 'This field is required' : null}
                onChangeFunc={(e) => {
                  setGoodsClass(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full">
              <InputText
                inputName="description"
                placeholder=""
                className={`form_control ${
                  !validServicesForm.description
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Technical Name or Description"
                required={true}
                labelClassName="block mb-1.5"
                value={description}
                icon={
                  !validServicesForm.description ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                // errorText={!validServicesForm.description ? 'This field is required' : null}
                onChangeFunc={(e) => {
                  setDescription(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full ">
              <InputText
                inputName="emergencyContactName"
                placeholder=""
                className={`form_control ${
                  !validServicesForm.emergencyContactName
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="24-hr Emergency Contact Name"
                required={true}
                labelClassName="block mb-1.5"
                icon={
                  !validServicesForm.emergencyContactName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                value={emergencyContactName}
                // errorText={!validServicesForm.emergencyContactName ? 'This field is required' : null}
                onChangeFunc={(e) => {
                  setEmergencyContactName(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="grid lg:grid-cols-2 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
              <div className="md:flex flex-col">
                <PhoneInput
                  label
                  required
                  onNumberChange={handleDangerPhoneChange}
                  name="phone"
                  errors={
                    isDangerousFormSubmitted &&
                    !validServicesForm.emergencyContactNumber
                      ? 'Phone number is required'
                      : isDangerousFormSubmitted &&
                        !validServicesForm.isEmergencyPhoneValid
                      ? 'Invalid Number'
                      : null
                  }
                  value={emergencyContactNumber || ''}
                />
              </div>
              <InputText
                inputName="emergencyContactExt"
                placeholder=""
                className="form_control"
                label="Ext"
                labelClassName="block mb-1.5"
                value={emergencyContactExt}
                onChangeFunc={(e) => setEmergencyContactExt(e.target.value)}
              />
            </div>
          </div>
        </CommonModal>
      )}
      {isLoadInsuranceModalOpen && (
        <CommonModal
          title={'Load Insurance'}
          titleDesc={'Please Provide Load Insurance Value'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="load_insurance"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsLoadInsurance(false);
            setIsLoadInsuranceModalOpen(false);
          }}
          headerIcon={<AlertHexagon />}
          size={'max-w-[600px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (validServicesForm && isLoadInsuranceFormValid()) {
              setIsLoadInsuranceModalOpen(false);
            }
            handleQuoteFormData();
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="load_insurance"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsLoadInsuranceModalOpen(false);
          }}
        >
          <div className="p-5">
            <div className="rounded-xl border border-utilityGray200 bg-gray50 p-4 flex gap-2.5">
              <InputText
                parentClassName="flex-1"
                label="Commercial Value"
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="loadInsuranceValue"
                required={true}
                placeholder=""
                className={`form_control ${
                  !validServicesForm.isLoadInsuranceValid
                    ? 'border border-red-500 border-solid pr-8'
                    : ''
                }`}
                icon={
                  !validServicesForm.isLoadInsuranceValid ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                  ) : null
                }
                value={loadInsuranceValue}
                shouldFormatNumber={true}
                onChangeFunc={(e) => {
                  setLoadInsuranceValue(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
                onFocus={() => {
                  if (loadInsuranceValue === 0) {
                    setLoadInsuranceValue('');
                  }
                }}
                onBlur={() => {
                  if (loadInsuranceValue === '') {
                    setLoadInsuranceValue(0);
                  }
                }}
              />
              <SelectBox
                label="Currency"
                name="currency"
                placeholder="Select Currency"
                parentClassName="currency-dropdown"
                selectoptiontext="currency-dropdown-text"
                isLoading={isLoading}
                labelClassName="form_label block mb-1.5"
                value={filterSelectArr.find(
                  (item: any) => item.value === loadInsuranceValueUnit
                )}
                onChangeFunc={(e: any) => {
                  setLoadInsuranceValueUnit(e.value);
                }}
                options={filterSelectArr}
              />
            </div>
          </div>
        </CommonModal>
      )}
      {isShowAdditionalServiceInfoModal && (
        <AdditionalServiceModal
          handleClose={() => setIsShowAdditionalServiceInfoModal(false)}
          services={(serviceTypes?.OTHER || []).filter(
            (val: any) =>
              quoteFormData?.addressDetail?.classification ===
                QUOTE_CLASSIFICATION.FTL ||
              val?.slug === 'load_insurance' ||
              (quoteFormData?.addressDetail?.classification !==
                QUOTE_CLASSIFICATION.FTL &&
                val.loadLinkTerm !== null)
          )}
          title="Other Additional Services"
          titleDesc="Explore key details of additional pickup and delivery services"
        />
      )}
    </>
  );
};

export default QuoteDetails;
