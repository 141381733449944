import { getSalesPrice } from 'src/services/OrderService';

type AppointmentProps = {
  title: string;
  description: string;
  mode: string;
  field: string;
  status?: any;
  dateFieldName?: any;
  timeFieldName?: any;
};

export const pickupAppointments: AppointmentProps[] = [
  {
    title: 'Shipper Pickup Appointment',
    description: 'Add a Shipper Pickup Appointment',
    mode: 'shipper-appointment',
    field: 'shipperAppointmentDatetime',
    status: 'shipperAppointmentStatus',
    dateFieldName: 'shipperAppointmentDate',
    timeFieldName: 'shipperAppointmentTime',
  },
  {
    title: 'Carrier Pickup Appointment',
    description: 'Add a Carrier Pickup Appointment',
    mode: 'carrier-pickup-appointment',
    field: 'carrierPickupAppointmentDatetime',
    status: 'carrierPickupAppointmentStatus',
    dateFieldName: 'carrierPickupAppointmentDate',
    timeFieldName: 'carrierPickuAppointmentTime',
  },
];

export const deliveryAppointments: AppointmentProps[] = [
  {
    title: 'Consignee Delivery Appointment',
    description: 'Add a Consignee Delivery Appointment',
    mode: 'delivery-appointment',
    field: 'deliveryAppointmentDatetime',
    status: 'deliveryAppointmentStatus',
    dateFieldName: 'deliveryAppointmentDate',
    timeFieldName: 'deliveryAppointmentTime',
  },
  {
    title: 'Carrier Delivery Appointment',
    description: 'Add a Carrier Delivery Appointment',
    mode: 'carrier-delivery-appointment',
    field: 'carrierDeliveryAppointmentDatetime',
    status: 'carrierDeliveryAppointmentStatus',
    dateFieldName: 'carrierDeliveryAppointmentDate',
    timeFieldName: 'carrierDeliveryAppointmentTime',
  },
];

export const fieldMapping: any = {
  'shipper-appointment': {
    datetimeField: 'shipperAppointmentDatetime',
    statusField: 'shipperAppointmentStatus',
    dateFieldName: 'shipperAppointmentDate',
    timeFieldName: 'shipperAppointmentTime',
  },
  'carrier-pickup-appointment': {
    datetimeField: 'carrierPickupAppointmentDatetime',
    statusField: 'carrierPickupAppointmentStatus',
    dateFieldName: 'carrierPickupAppointmentDate',
    timeFieldName: 'carrierPickuAppointmentTime',
  },
  'carrier-delivery-appointment': {
    datetimeField: 'carrierDeliveryAppointmentDatetime',
    statusField: 'carrierDeliveryAppointmentStatus',
    dateFieldName: 'carrierDeliveryAppointmentDate',
    timeFieldName: 'carrierDeliveryAppointmentTime',
  },
  'delivery-appointment': {
    datetimeField: 'deliveryAppointmentDatetime',
    statusField: 'deliveryAppointmentStatus',
    dateFieldName: 'deliveryAppointmentDate',
    timeFieldName: 'deliveryAppointmentTime',
  },
  'delivery-date': {
    datetimeField: 'expectedEstimatedDeliveryTime',
  },
  'pickup-date': {
    datetimeField: 'expectedPickupTime',
  },
};

export const appointmentDetails: any = {
  'carrier-pickup-appointment': {
    title: 'Carrier Pickup Appointment',
    description: 'Assign a carrier to complete your shipment',
    draftTitle: 'RE: Request to Set Carrier Pickup Appointment',
    sendMailTo: 'carrier',
    emailDraftModalTitle: 'Confirm Carrier Pickup Appointment',
    messages: {
      Pending:
        'Are you sure you want to mark the pickup appointment with the carrier as pending?',
      Confirmed:
        'Are you sure you want to confirm the pickup appointment with the carrier?',
    },
  },
  'carrier-delivery-appointment': {
    title: 'Carrier Delivery Appointment',
    description: 'Assign a carrier to complete your shipment',
    draftTitle: 'RE: Request to Set Carrier Delivery Appointment',
    sendMailTo: 'carrier',
    emailDraftModalTitle: 'Confirm Carrier Delivery Appointment',
    messages: {
      Pending:
        'Are you sure you want to mark the delivery appointment with the carrier as pending?',
      Confirmed:
        'Are you sure you want to confirm the delivery appointment with the carrier?',
    },
  },
  'shipper-appointment': {
    title: 'Shipper Appointment',
    description: 'Assign a shipper to complete your shipment',
    draftTitle: 'RE: Request to Set Shipper Pickup Appointment',
    sendMailTo: 'shipper',
    emailDraftModalTitle: 'Confirm Shipper Pickup Appointment',
    messages: {
      Pending:
        'Are you sure you want to mark the pickup appointment with the customer as pending?',
      Confirmed:
        'Are you sure you want to confirm the pickup appointment with the customer?',
    },
  },
  'delivery-appointment': {
    title: 'Consignee Appointment',
    description: 'Assign a consignee to complete your shipment',
    draftTitle: 'RE: Request to Set Delivery Appointment',
    sendMailTo: 'consignee',
    emailDraftModalTitle: 'Confirm Consignee Delivery Appointment',
    messages: {
      Pending:
        'Are you sure you want to mark the delivery appointment with the customer as pending?',
      Confirmed:
        'Are you sure you want to confirm the delivery appointment with the customer?',
    },
  },
  'pickup-date': {
    title: 'Pickup Date',
    description: 'Assign a pickup date to schedule your shipment',
    draftTitle: '',
    sendMailTo: '',
    messages: {
      Pending: '',
      Confirm: '',
    },
  },
  'delivery-date': {
    title: 'Delivery Date',
    description: 'Assign a delivery date to schedule your shipment',
    draftTitle: '',
    sendMailTo: '',
    messages: {
      Pending: '',
      Confirm: '',
    },
  },
};

export const OrderStatusAction = {
  MARK_AS_PICKED_UP: {
    title: 'Mark as Picked Up',
    value: 'mark-as-picked-up',
    toolTip: null,
    type: 'pickup',
  },
  MARK_AS_DISPATCHED: {
    title: 'Mark as Dispatched',
    value: 'mark-as-dispatched',
    toolTip: 'Revert Picked Up',
    type: 'dispatch',
  },
  MARK_AS_IN_TRANSIT: {
    title: 'Mark as In-transit',
    value: 'mark-as-in-transit',
    toolTip: 'Revert Delivery',
    type: 'in-transit',
  },
  MARK_AS_DELIVERED: {
    title: 'Mark as Delivered',
    value: 'mark-as-delivered',
    toolTip: 'Revert Picked Up',
    type: 'delivery',
  },
  MARK_AS_COMPLETED: {
    title: 'Mark as Completed',
    value: 'mark-as-completed',
    toolTip: 'Revert Delivery',
    type: 'complete',
  },
};

export const constructOrderParam = (
  masterOrder: any,
  id: number,
  weightMeasurement: string,
  lengthMeasurement: string
) => ({
  shipperCompanyName: masterOrder?.shipper?.companyName,
  shipperContactName: masterOrder?.shipper?.contactName,
  shipperContactNumber: masterOrder?.shipper?.phoneNumber,
  shipperEmail: masterOrder?.shipper?.email,
  shipperFullAddress: masterOrder?.shipper?.shipperAddress?.fullAddress,
  shipperAddress1: masterOrder?.shipper?.shipperAddress?.address1,
  shipperCity: masterOrder?.shipper?.shipperAddress?.city,
  shipperState: masterOrder?.shipper?.shipperAddress?.state,
  shipperCountry: masterOrder?.shipper?.shipperAddress?.country,
  shipperPostal: masterOrder?.shipper?.shipperAddress?.postal,
  shipperLatitude: masterOrder?.shipper?.shipperAddress?.latitude,
  shipperLongitude: masterOrder?.shipper?.shipperAddress?.longitude,
  shipperStateCode: masterOrder?.shipper?.shipperAddress?.stateCode,
  shipperCountryCode: masterOrder?.shipper?.shipperAddress?.countryCode,
  consigneeCompanyName: masterOrder?.consignee?.companyName,
  consigneeContactName: masterOrder?.consignee?.contactName,
  consigneeContactNumber: masterOrder?.consignee?.phoneNumber,
  consigneeEmail: masterOrder?.consignee?.email,
  consigneeFullAddress: masterOrder?.consignee?.consigneeAddress?.fullAddress,
  consigneeAddress1: masterOrder?.consignee?.consigneeAddress?.address1,
  consigneeCity: masterOrder?.consignee?.consigneeAddress?.city,
  consigneeState: masterOrder?.consignee?.consigneeAddress?.state,
  consigneeCountry: masterOrder?.consignee?.consigneeAddress?.country,
  consigneePostal: masterOrder?.consignee?.consigneeAddress?.postal,
  consigneeLatitude: masterOrder?.consignee?.consigneeAddress?.latitude,
  consigneeLongitude: masterOrder?.consignee?.consigneeAddress?.longitude,
  consigneeStateCode: masterOrder?.consignee?.consigneeAddress?.stateCode,
  consigneeCountryCode: masterOrder?.consignee?.consigneeAddress?.countryCode,
  id,
  order_dimensions: masterOrder?.order_dimensions,
  declaredValue: masterOrder?.declaredValue,
  declaredValueUnit: masterOrder?.declaredValueUnit,
  equipmentType: masterOrder?.equipmentType,
  equipmentTypeFullName: masterOrder?.equipmentTypeFullName,
  linearFootage: masterOrder?.linearFootage,
  orderType: masterOrder?.orderType,
  poNumber: masterOrder?.poNumber,
  refNumber: masterOrder?.refNumber,
  specialNotes: masterOrder?.specialNotes,
  weightMeasurement,
  lengthMeasurement,
  additionalServices: masterOrder?.additionalServices,
  carrierPrice: masterOrder?.carrierPrice,
  salesPrice: masterOrder?.salesPrice,
});

export const getSalesPriceAPI = (params: any, handleSubmitFunction: any) => {
  getSalesPrice(params)
    .then((response) => {
      if (response?.data) {
        handleSubmitFunction();
      }
    })
    .catch(console.error);
};

export const ORDER_STATUS_TAB: { value: string; name: string }[] = [
  {
    value: 'all',
    name: 'View All',
  },
  {
    value: 'draft',
    name: 'Drafted',
  },
  {
    value: 'booked',
    name: 'Booked',
  },
  {
    value: 'dispatched',
    name: 'Dispatched',
  },
  {
    value: 'in-transit',
    name: 'In-Transit',
  },
  {
    value: 'delivered',
    name: 'Delivered',
  },
  {
    value: 'cancelled',
    name: 'Canceled',
  },
  {
    value: 'completed',
    name: 'Completed',
  },
];

export const orderStatusOption: { value: string; label: string }[] = [
  {
    value: 'draft',
    label: 'Drafted',
  },
  {
    value: 'booking_in_progress',
    label: 'Booking In Progress',
  },
  {
    value: 'booking_failed',
    label: 'Booking Failed',
  },
  {
    value: 'booked',
    label: 'Booked',
  },
  {
    value: 'dispatched',
    label: 'Dispatched',
  },
  {
    value: 'in-transit',
    label: 'In-Transit',
  },
  {
    value: 'delivered',
    label: 'Delivered',
  },
  {
    value: 'cancelled',
    label: 'Canceled',
  },
  {
    value: 'completed',
    label: 'Completed',
  },
];

export const ORDER_TYPE_TAB: { value: string; name: string }[] = [
  {
    value: 'rose-rocket-order',
    name: 'RoseRocket Order',
  },
  {
    value: 'new-order',
    name: 'End To End Order',
  },
];

export const END_TO_END_ORDER_HEAD_CELLS = [
  {
    id: 'salesRepId',
    name: 'User',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'orderStatus',
    name: 'Status',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'customerName',
    name: 'Customer',
    rowClassName: '',
    sortable: true,
  },
  // {
  //   id: 'carrierName',
  //   name: 'Carrier',
  //   rowClassName: '',
  //   sortable: false,
  // },
  {
    id: 'orderFromOrgName',
    name: 'Address',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'createdAt',
    name: 'Order Created',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'os.totalSalePrice',
    name: 'Total Revenue',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'carrierTotalPrice',
    name: 'Cost',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedMarginPercentage',
    name: 'Margin %',
    rowClassName: '',
    sortable: false,
  },
  {
    id: 'combinedMargin',
    name: 'Margin',
    rowClassName: '',
    sortable: false,
  },
  // {
  //   id: '',
  //   name: '',
  //   rowClassName: '',
  //   sortable: false,
  // },
];

export const ROSE_ROCKET_ORDER_HEAD_CELLS = [
  {
    id: 'orderCommissionees',
    name: 'User',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderStatus',
    name: 'Status',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'customerName',
    name: 'Customer',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderFromOrgName',
    name: 'Address',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'orderCreateTime',
    name: 'Order Created',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedRevenue',
    name: 'Total Revenue',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedCost',
    name: 'Cost',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedMarginPercentage',
    name: 'Margin %',
    rowClassName: '',
    sortable: true,
  },
  {
    id: 'combinedMargin',
    name: 'Margin',
    rowClassName: '',
    sortable: true,
  },
];

export const INIT_ROSE_ROCKET_ORDER_PARAMS: any = {
  search: '',
  sortType: 'desc',
  sortField: 'orderCreateTime',
  page: 1,
  limit: 25,
  team: 'all',
  member: 'all',
};

export const INIT_E2E_ORDER_PARAMS: any = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  team: 'all',
  member: 'all',
  customer: 'all',
  shipper: 'all',
  consignee: 'all',
  statusFilter: 'all',
};

export const getEmailMessageForAppointMent = (
  actionType: any,
  currentLegData: any
) => {
  switch (actionType) {
    case 'carrier-pickup-appointment':
      return `I hope this message finds you well. We would like to arrange a carrier appointment for pick-up of your order: #${currentLegData?.legId}. Please let us know when you are available.`;
    case 'carrier-delivery-appointment':
      return `I hope this message finds you well. We would like to arrange a carrier appointment for delivery of your order: #${currentLegData?.legId}. Please let us know when you are available.`;
    case 'shipper-appointment':
      return `This is to inform you that your shipment with order #${currentLegData?.legId} is scheduled for pick-up. Kindly confirm the available time for pick-up.`;
    case 'delivery-appointment':
      return `We would like to confirm the delivery details for your order #${currentLegData?.legId}. Please provide us with the suitable time for the delivery.`;
    default:
      return `I hope this message finds you well. Please provide us with the required details for order #${currentLegData?.legId}.`;
  }
};

export const STEPS = [
  {
    name: 'Add Order Meta Data',
    step: 1,
  },
  {
    name: 'Add Shipper',
    step: 2,
  },
  {
    name: 'Add Consignee',
    step: 3,
  },
  {
    name: 'Appointment',
    step: 4,
  },
  {
    name: 'Order Details',
    step: 5,
  },
  {
    name: 'Prices',
    step: 6,
  },
  {
    name: 'Review Order Details',
    step: 7,
  },
];

export const STEPS_FOR_LEG_FORM = [
  {
    name: 'Add Shipper',
    step: 1,
  },
  {
    name: 'Add Consignee',
    step: 2,
  },
  {
    name: 'Appointment',
    step: 3,
  },
  {
    name: 'Order Details',
    step: 4,
  },
  {
    name: 'Prices',
    step: 5,
  },
];

export const filterServicesByType = (
  services: any,
  selectedServices: any,
  type: any
) => {
  if (!Array.isArray(services) || services.length === 0) return [];
  if (!Array.isArray(selectedServices) || selectedServices.length === 0)
    return [];

  const filteredService = services.filter(
    (service) => service.type === type && selectedServices.includes(service.id)
  );

  return filteredService;
};

export const handleInputChange = (e: any, type: string, onChange: any) => {
  const { target } = e;
  let { value: currentValue } = target;

  let newValue = currentValue;

  if (type === 'blur' && !currentValue) {
    newValue = undefined;
  } else {
    newValue = currentValue;

    if (newValue && !newValue.includes('.')) {
      newValue = Number(newValue);
    }
  }

  onChange(newValue);
};

export const getModifiedCategories = (
  currentValues: Record<string, any>,
  selectedQuote: Record<string, any>
): {
  isAddressChanged: boolean;
  isDimensionChanged: boolean;
  isAdditionalServiceChanged: boolean;
} => {
  if (!selectedQuote)
    return {
      isAddressChanged: false,
      isDimensionChanged: false,
      isAdditionalServiceChanged: false,
    };

  const addressFields = [
    'fullAddress',
    'address1',
    'city',
    'state',
    'country',
    'postal',
    'latitude',
    'longitude',
    'stateCode',
    'countryCode',
  ];

  const capitalizeFirstLetter = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const isAddressChanged = (() => {
    const currentShipperAddress = currentValues?.shipper?.shipperAddress || {};
    const selectedShipperAddress = selectedQuote?.quote || {};
    const currentConsigneeAddress =
      currentValues?.consignee?.consigneeAddress || {};
    const selectedConsigneeAddress = selectedQuote?.quote || {};

    const isShipperChanged = addressFields.some(
      (field) =>
        currentShipperAddress[field] !==
        selectedShipperAddress[`shipper${capitalizeFirstLetter(field)}`]
    );

    const isConsigneeChanged = addressFields.some(
      (field) =>
        currentConsigneeAddress[field] !==
        selectedConsigneeAddress[`consignee${capitalizeFirstLetter(field)}`]
    );

    return isShipperChanged || isConsigneeChanged;
  })();

  const isDimensionChanged = (() => {
    const currentDimensions = currentValues.order_dimensions || [];
    const selectedDimensions = selectedQuote?.dimensions || [];

    if (currentDimensions.length !== selectedDimensions.length) return true;

    return currentDimensions.some((currDim: any, index: number) => {
      const selDim = selectedDimensions[index] || {};

      return (
        currDim.length !== selDim.length ||
        currDim.width !== selDim.width ||
        currDim.height !== selDim.height ||
        currDim.weight !== selDim.weight
      );
    });
  })();

  const isAdditionalServiceChanged = (() => {
    const currentServices = currentValues?.additionalServices || {};
    const selectedServices = selectedQuote?.additionalServices || {};

    const currentServiceIds = Array.isArray(currentServices.serviceId)
      ? currentServices.serviceId.sort()
      : [];
    const selectedServiceIds = Array.isArray(selectedServices.serviceId)
      ? selectedServices.serviceId.sort()
      : [];

    const hasServiceIdChanged =
      currentServiceIds.length !== selectedServiceIds.length ||
      currentServiceIds.some(
        (id: any, index: number) => id !== selectedServiceIds[index]
      );

    return hasServiceIdChanged;
  })();

  return { isAddressChanged, isDimensionChanged, isAdditionalServiceChanged };
};

export const getCommonCarrierAPIInformation = (
  type: string,
  carrierName: string
) => {
  switch (type) {
    case 'FREIGHTCOM':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'POLARIS':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'DAY_ROSS':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'DAY_ROSS_USA':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'MARITIME_ONTARIO':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'CSA':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'KINDERSLEY':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    case 'BANYAN':
      return [
        `Order will be automatically booked via API Integration with ${carrierName}.`,
        'BOL will be generated after 10 minutes and will attach here.',
        'API Tracking will be linked.',
      ];

    default:
      return [];
  }
};
