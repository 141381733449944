import React from 'react';

const TotalMarginCostBreakDownSubRow = ({ data }: any) => {
  const filteredData = data?.key?.filter(
    (item: any) => item.name !== 'Total Margin'
  );

  return (
    <>
      {filteredData?.map((item: any, index: number) => (
        <tr
          className="text-grayLight600 border-b border-utilityGray200 font-normal text-xs bg-gray25 transition-all duration-[0.5s]"
          key={index}
        >
          <td className="px-4 py-3 w-[45%] min-w-[45%] max-w-[1px]">
            {item?.name}
          </td>

          <td className="px-4 py-3 w-[50.66%] min-w-[50.66%] max-w-[1px]">
            <span className={``}>${item?.cad} CAD</span>
          </td>
          <td className="px-4 py-3 w-[50px] max-w-[50px] min-w-[50px]"></td>
        </tr>
      ))}
    </>
  );
};

export default TotalMarginCostBreakDownSubRow;
