import { Mail01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
import {
  getBadgeLabelByQuoteStatus,
  getBadgeTypeByQuoteStatus,
} from 'src/constants/common';
import { getShortName, onError } from 'src/utils/CommonFunctions';

import MailDetailView from './MailDetailView';

const QuoteEmailModal = ({ data, quoteStatus, handleClose }: any) => {
  const [selectedEmail, setSelectedEmail] = useState<any>();
  const [isEmailClicked, setIsEmailClicked] = useState(false);
  const [emailThread, setEmailThread] = useState<any>();

  const mergeThreadToArray = (dataObj: any) => {
    const filteredThread =
      dataObj?.thread
        ?.filter((email: any) => email.id !== data.id)
        .map((item: any) => ({
          ...item,
          emailBody: item?.emailBody ? JSON.parse(item.emailBody) : {},
        })) ?? [];

    // Return the final array
    return [data, ...filteredThread];
  };

  useEffect(() => {
    setEmailThread(mergeThreadToArray(data));
  }, [data]);

  useEffect(() => {
    if (emailThread?.length > 0) {
      setIsEmailClicked(true);
      setSelectedEmail(emailThread?.[0]);
    }
  }, [emailThread]);

  useEffect(() => {
    if (!isEmailClicked) {
      setSelectedEmail(null);
    }
  }, [isEmailClicked]);

  return (
    <CommonModal
      title={'Customer Thread'}
      titleDesc={'Review rate and margin details to create an active quote.'}
      handleClose={() => handleClose()}
      size={'max-w-[688px] min-h-[500px] h-[90vh]'}
      headerIcon={<Mail01 />}
      modalClassName="m-4 flex flex-col"
    >
      <>
        <ul className="space-y-4 p-1 flex flex-col flex-1">
          {emailThread?.map((item: any, index: number) => {
            let emailBody = item?.emailBody;

            return (
              <li
                key={index}
                className={`rounded-lg bg-white shadow-md flex flex-col ${
                  selectedEmail?.messageId === item?.messageId ? 'flex-1' : ''
                } `}
                onClick={() => {
                  setSelectedEmail(item);
                  setIsEmailClicked(!isEmailClicked);
                }}
              >
                <>
                  <div
                    className={`px-4 py-3 flex items-center gap-2 cursor-pointer ${
                      selectedEmail?.messageId === item?.messageId
                        ? 'border-b border-utilityGray200 '
                        : ''
                    }`}
                  >
                    {item?.image ? (
                      <img
                        className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                        src={item?.imageUrl + item?.image}
                        alt=""
                        title=""
                        onError={onError}
                      />
                    ) : (
                      <div className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                        {getShortName(`${emailBody?.from?.emailAddress?.name}`)}
                      </div>
                    )}
                    <div className="flex-1 space-y-0.5">
                      <h4 className="text-grayLight900 text-sm font-medium truncate">
                        {emailBody?.from?.emailAddress?.name}
                      </h4>
                      <p className="text-grayLight600 text-xs font-normal truncate">
                        {emailBody?.from?.emailAddress?.address}
                      </p>
                    </div>
                    <div className="space-y-1 text-right">
                      <div className="text-grayLight600 text-xs font-normal">
                        {item?.createdAt
                          ? moment(item.createdAt).format(
                              'hh:mm A ddd, MMM D, YYYY'
                            )
                          : ''}
                      </div>
                      <BadgeCmp
                        style="modern"
                        type={getBadgeTypeByQuoteStatus(quoteStatus)}
                      >
                        {getBadgeLabelByQuoteStatus(quoteStatus)}
                      </BadgeCmp>
                    </div>
                  </div>
                  {isEmailClicked &&
                  selectedEmail?.emailBody &&
                  selectedEmail?.messageId === item?.messageId ? (
                    <MailDetailView emailDetails={selectedEmail} />
                  ) : (
                    ''
                  )}
                </>
              </li>
            );
          })}
          {emailThread?.length === 0 ? (
            <NotFoundUI
              title="Not Found customer thread"
              desc="There are no customer thread found for this quote."
              containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
            />
          ) : (
            ''
          )}
        </ul>
      </>
    </CommonModal>
  );
};
export default QuoteEmailModal;
