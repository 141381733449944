import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DialOutboundCall from 'src/app/Twilio/CallLogs/DialOutboundCall';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';
import {
  capitalizeFirstLetterSpace,
  getShortName,
  usePhone,
} from 'src/utils/CommonFunctions';

import { ILeadRowProps } from './LoadBoard.interface';

const LoadBoardRow = ({
  data,
  setIsShowTypeInfoModal,
  setSelectedCarrierRates,
  rates,
}: ILeadRowProps) => {
  const { getFormatPhoneWithPlus } = usePhone();
  const { vehicleTypeOptions } = useContext(BasicContext);
  const [lead, setLead] = useState<any>(data);
  const [equipmentTypes, setEquipmentTypes] = useState<string[]>([]);
  const [equipmentTypeLoading, setEquipmentTypeLoading] =
    useState<boolean>(false);

  useEffect(() => {
    let vehicleType = [];
    setEquipmentTypeLoading(true);

    if (!data.vehicleType && data.carrierEquipmentType) {
      vehicleType = data.carrierEquipmentType?.split(',');
    } else if (
      vehicleTypeOptions.length > 0 &&
      typeof data.vehicleType === 'string'
    ) {
      vehicleType = vehicleTypeOptions
        .filter((vehType: any) =>
          data.vehicleType.split('').includes(vehType.value)
        )
        .map((vehType: any) =>
          vehType.label === 'Van' ? 'Dry Van' : vehType.label
        );
    }
    setLead({ ...data, vehicleType });

    if (Array.isArray(vehicleType)) {
      setEquipmentTypes(vehicleType);
    } else {
      setEquipmentTypes([]);
    }
    setEquipmentTypeLoading(false);
  }, [vehicleTypeOptions]);

  const tooltipItems = (
    <div className="flex flex-wrap gap-2 max-w-[210px] px-0.5 py-1.5 w-max">
      {equipmentTypes.slice(2)?.map((name: any) => (
        <div key={name}>
          <BadgeCmp
            mainClassName="!py-[3px] !pl-1 !pr-1.5"
            badgeTextColor="text-nowrap break-keep"
            type="pink"
            style="modern"
          >
            {name}
          </BadgeCmp>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <td className="px-5 py-4 max-w-[1px] w-[18%] min-w-[200px]">
        <div className="flex items-center">
          <div className="table-profile-customer-circle-xl rounded-md">
            {getShortName(lead.company ? lead.company : 'Unknown')}
          </div>
          <div className="ml-3 truncate flex-1">
            {lead.company?.length > 30 ? (
              <TooltipCmp message={lead.company}>
                <h6 className="text-grayLight900 font-medium leading-tight truncate">
                  {lead.company.slice(0, 30)}...
                </h6>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {lead.company ? lead.company : 'Unknown'}
              </h6>
            )}

            <span className="text-grayLight600 font-normal leading-tight truncate">
              {getFormatPhoneWithPlus(lead.companyPhone)}
            </span>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[13.04%] w-[13.04%] max-w-[1px]">
        <p className="truncate">
          {capitalizeFirstLetterSpace(lead.sourceCity)}
        </p>
      </td>
      <td className="px-5 py-4 min-w-[12.04%] w-[12.04%] max-w-[1px]">
        <span>{lead.dfo ? `${lead.dfo} mi` : ''} </span>
      </td>
      <td className="px-5 py-4 min-w-[13.04%] w-[13.04%] max-w-[1px]">
        <p className="truncate">
          {capitalizeFirstLetterSpace(lead.destinationCity)}
        </p>
      </td>
      <td className="px-5 py-4 min-w-[12.04%] w-[12.04%] max-w-[1px]">
        <span>{lead.dfd ? `${lead.dfd} mi` : ''}</span>
      </td>
      <td
        className={`px-5 py-4 min-w-[14.04%] w-[14.04%] max-w-[14.04%] ${
          equipmentTypeLoading ? 'custom-loading' : ''
        }`}
      >
        <div className="truncate w-fit max-w-full flex space-x-2">
          {equipmentTypes?.length > 0 && equipmentTypes[0] != 'N/A' && (
            <BadgeCmp type="pink" style="modern">
              {equipmentTypes[0]}
            </BadgeCmp>
          )}

          {equipmentTypes?.length > 1 && (
            <BadgeCmp type="pink" style="modern">
              {equipmentTypes[1]}
            </BadgeCmp>
          )}
          {equipmentTypes?.length > 2 && (
            <>
              <ActionTooltip
                message={tooltipItems}
                type="light"
                tooltipPosition="right"
                isOpenChildTopPosOnTop={false}
                openOnHover={true}
              >
                <BadgeCmp
                  mainClassName="cursor-pointer"
                  type="success"
                  style="modern"
                  isHidePillDot
                >
                  +{equipmentTypes?.length - 2}
                </BadgeCmp>
              </ActionTooltip>
            </>
          )}
        </div>
      </td>
      <td className="px-5 py-4 min-w-[14.04%] w-[14.04%] max-w-[1px]">
        <div className="truncate">
          {lead.comment && lead.comment?.length > 25 ? (
            <TooltipCmp message={lead.comment}>
              <p className="truncate">{lead.comment}</p>
            </TooltipCmp>
          ) : (
            <p className="truncate">{lead.comment || '-'}</p>
          )}
        </div>
      </td>

      <td className="px-5 py-4 w-[100px] max-w-[100px] min-w-[100px] pointer">
        <div className="flex">
          <ArrowUp
            className="w-4 h-4 text-primary cursor-pointer mr-2"
            onClick={() => {
              setSelectedCarrierRates([
                {
                  id: 0,
                  contactPhone: `${
                    data.companyPhone.includes('+1')
                      ? data.companyPhone
                      : `+1${data.companyPhone}`
                  }`,
                  projectedDeliveryDate:
                    data.estimatedDeliveryDate &&
                    moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').diff(
                      moment().startOf('day'),
                      'days'
                    ) >= 0
                      ? moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').format(
                          'YYYY-MM-DD 00:00:00'
                        )
                      : moment().format('YYYY-MM-DD 00:00:00'),
                  transitTime:
                    data.estimatedDeliveryDate &&
                    moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').diff(
                      moment().startOf('day'),
                      'days'
                    ) >= 0
                      ? moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').diff(
                          moment().startOf('day'),
                          'days'
                        )
                      : 0,
                  name: data.company ? data.company : 'Unknown Carrier',
                  additionalCharges: 0,
                  additionalChargesUSD: 0,
                  marginType: 'VALUE',
                  margin: 0,
                  marginUSD: 0,
                  marginUnit: 0,
                  marginUnitUSD: 0,
                  totalCharge: Number(data.offerAmount) || 0,
                  totalChargeUSD:
                    (Number(data.offerAmount) || 0) * (rates.usdRate || 0),
                  totalCostCAD: Number(data.offerAmount) || 0,
                  totalCostUSD:
                    (Number(data.offerAmount) || 0) * (rates.usdRate || 0),
                },
              ]);
              setIsShowTypeInfoModal(true);
            }}
          />
          <DialOutboundCall
            data={{
              contactNumber: getFormatPhoneWithPlus(data.companyPhone),
              contactName: ['Not provided', 'None', 'null'].includes(
                data.carrierContactPerson
              )
                ? ''
                : data.carrierContactPerson,
              customerName: '',
              role: 'carrier',
              contactImageUrl: '',
              carrierName: lead.company ? lead.company : 'Unknown',
            }}
            className="text-primary"
          />
        </div>
      </td>
    </>
  );
};

export default LoadBoardRow;
