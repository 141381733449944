import {} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName, setNumberWithCommas } from 'src/utils/CommonFunctions';

import defaultImg from '../../../assets/img/default-image.jpg';
import trophy1 from '../../../assets/img/rank-1.png';
import trophy2 from '../../../assets/img/rank-2.png';
import trophy3 from '../../../assets/img/rank-3.png';
import winnerBoxTop from '../../../assets/img/winner-box-top.svg';
import winnerFlag from '../../../assets/img/winner-flag.png';
// import trophy1 from '../../../assets/img/trophy-icon1.svg';
// import trophy2 from '../../../assets/img/trophy-icon2.svg';
// import trophy3 from '../../../assets/img/trophy-icon3.svg';

import CountDownTimer from './CountDownTimer';

interface IProps {
  leaderBoardTopThreeData: any;
  loginUserRank: any;
  totalUserCount: any;
  eventDetail: any;
  isLoading: any;
}

const WinnerBoard = ({
  leaderBoardTopThreeData,
  loginUserRank,
  totalUserCount,
  eventDetail,
  // badgeWinner,
  isLoading,
}: IProps) => {
  // SHOW TOOLTIP WHEN 2ND NAME GET TRUNCATED
  const nameRefThird = useRef<HTMLHeadingElement>(null);
  const [isNameThirdTruncated, setIsNameThirdTruncated] = useState(false);

  useEffect(() => {
    if (nameRefThird.current) {
      setIsNameThirdTruncated(
        nameRefThird.current.scrollWidth > nameRefThird.current.clientWidth
      );
    }
  }, [eventDetail]);

  // SHOW TOOLTIP WHEN 2ND NAME GET TRUNCATED
  const nameRefSecond = useRef<HTMLHeadingElement>(null);
  const [isNameSecondTruncated, setIsNameSecondTruncated] = useState(false);
  useEffect(() => {
    if (nameRefSecond.current) {
      setIsNameSecondTruncated(
        nameRefSecond.current.scrollWidth > nameRefSecond.current.clientWidth
      );
    }
  }, []);

  // SHOW TOOLTIP WHEN 1ST NAME GET TRUNCATED
  const nameRef = useRef<HTMLHeadingElement>(null);
  const [isNameTruncated, setIsNameTruncated] = useState(false);
  useEffect(() => {
    if (nameRef.current) {
      setIsNameTruncated(
        nameRef.current.scrollWidth > nameRef.current.clientWidth
      );
    }
  }, []);

  const leaderBoardFirst =
    leaderBoardTopThreeData.length > 0 ? leaderBoardTopThreeData[0] : null;
  const leaderBoardSecond =
    leaderBoardTopThreeData.length > 1 ? leaderBoardTopThreeData[1] : null;
  const leaderBoardThird =
    leaderBoardTopThreeData.length > 2 ? leaderBoardTopThreeData[2] : null;

  const getNumberWithSuffix = (num: number) => {
    const lastDigit = num % 10;
    const lastTwoDigits = num % 100;

    if ([11, 12, 13].includes(lastTwoDigits)) {
      return `${num}th`;
    }

    switch (lastDigit) {
      case 1:
        return `${num}st`;
      case 2:
        return `${num}nd`;
      case 3:
        return `${num}rd`;
      default:
        return `${num}th`;
    }
  };

  // const [eventEnd6PM, setEventEnd6PM] = useState<any>();
  const [todayRemainingTimeInPercentage, setTodayRemainingTimeInPercentage] =
    useState<any>(100);
  // const [eventRemainingTimeInPercentage, setEventRemainingTimeInPercentage] =
  //   useState<any>(100);

  const [next6PM, setNext6PM] = useState(
    moment()
      .tz('America/New_York')
      .isAfter(
        moment()
          .tz('America/New_York')
          .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
      )
      ? moment()
          .tz('America/New_York')
          .add(1, 'days')
          .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
      : moment()
          .tz('America/New_York')
          .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
  );

  // useEffect(() => {
  //   if (eventDetail) {
  //     let eventEndAt6PM = moment(eventDetail.end_event)
  //       .tz('America/New_York')
  //       .set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
  //     setEventEnd6PM(eventEndAt6PM);

  //     let now = moment().tz('America/New_York');
  //     const duration = moment.duration(eventEndAt6PM.diff(now));
  //     const eventRemainingTime = (duration.asSeconds() * 100) / 3571200;
  //     setEventRemainingTimeInPercentage(eventRemainingTime);

  //     const interval = setInterval(() => {
  //       // Refresh once the event once it is completed
  //       // let now = moment().local();
  //       // if (now.isSameOrAfter(eventEndAt6PM)) {
  //       // }
  //     }, 1000);

  //     return () => clearInterval(interval);
  //   }
  // }, [eventDetail]);

  useEffect(() => {
    let todayAt6PM = next6PM;

    const interval = setInterval(() => {
      let now = moment().tz('America/New_York');
      const duration = moment.duration(todayAt6PM.diff(now));
      const remainingTime = (duration.asSeconds() * 100) / 86400;
      setTodayRemainingTimeInPercentage(remainingTime);

      if (now.isSameOrAfter(todayAt6PM)) {
        todayAt6PM.add(1, 'day');
        setNext6PM(todayAt6PM);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <ul className="relative rounded-2xl border border-utilityGray200 flex justify-center items-end px-4 gap-7 pt-14 pb-6">
        {leaderBoardSecond ? (
          <li className="text-center min-w-[13%] w-[142px] ">
            <div className="mb-3">
              <div className="mb-2.5">
                {leaderBoardSecond.profileImage ? (
                  <img
                    className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue-sm mx-auto"
                    src={leaderBoardSecond.profileImage}
                  />
                ) : leaderBoardSecond.firstName &&
                  leaderBoardSecond.lastName ? (
                  <div className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm mx-auto shadow-blue-sm">
                    <span>
                      {getShortName(
                        `${leaderBoardSecond.firstName} ${leaderBoardSecond.lastName}`
                      )}
                    </span>
                  </div>
                ) : (
                  <img
                    src={defaultImg}
                    className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue-sm mx-auto"
                  />
                )}
              </div>
              <div className="flex justify-center items-center gap-x-1.5">
                <TooltipCmp
                  message={
                    isNameSecondTruncated
                      ? `${leaderBoardSecond.firstName}`
                      : ''
                  }
                >
                  <h6
                    ref={nameRefSecond}
                    className="text-grayLight900 text-xs font-semibold truncate max-w-28"
                  >
                    {leaderBoardSecond.firstName}
                  </h6>
                </TooltipCmp>
                <div className="flex-none">
                  <img src={trophy2} className="w-4 h-4 object-contain" />
                </div>
              </div>
            </div>
            <div className="min-h-[135px] flex flex-col">
              <img src={winnerBoxTop} className="w-full" />
              <div className="flex-1 pt-2 space-y-1.5 px-1.5 bg-gradient-to-b from-[#F2F4F7] from-0% to-[rgba(242, 244, 247, 0.00)] to-100% -mt-[0.5px]">
                {/* <div
                  className={`rounded-md p-1.5 border border-utilityGray200 w-6 h-6 mx-auto shadow-[0_2px_15px_-2px_rgba(97,114,243,0.18)] bg-white ${
                    leaderBoardSecond.userId != badgeWinner ? 'opacity-0' : ''
                  }`}
                >
                  {leaderBoardSecond.userId == badgeWinner && (
                    <img src={trophy1} />
                  )}
                </div> */}
                <p className="text-primary text-xs font-medium">
                  {leaderBoardSecond.totalPoints} Points
                </p>
                <p className="text-gray500 text-xs font-normal pb-1 border-b border-utilityGray200">
                  {leaderBoardSecond.totalCustomers}{' '}
                  {leaderBoardSecond.totalCustomers <= 1
                    ? 'New Customer'
                    : 'New Customers'}
                </p>
                <p className="text-[#EDEEF0] text-4xl font-bold tracking-[-0.674px]">
                  2nd
                </p>
              </div>
            </div>
          </li>
        ) : (
          <li className="text-center min-w-[13%] w-[142px] ">
            <div className="mb-3">
              <div
                className={`mb-2.5 ${
                  isLoading ? 'w-10 h-10 rounded-lg custom-loading mx-auto' : ''
                }`}
              >
                <img
                  src={defaultImg}
                  className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue-sm mx-auto"
                />
              </div>
              {isLoading && (
                <div className="flex justify-center items-center gap-x-1.5">
                  <TooltipCmp>
                    <h6
                      className={`text-grayLight900 text-xs font-semibold truncate max-w-28 ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      Unassigned
                    </h6>
                  </TooltipCmp>
                </div>
              )}
            </div>

            <div className="min-h-[135px] flex flex-col">
              <img src={winnerBoxTop} className="w-full" />
              <div className="pt-2 space-y-1.5 px-1.5 bg-gradient-to-b from-[#F2F4F7] from-0% to-[rgba(242, 244, 247, 0.00)] to-100% -mt-[0.5px] flex-1">
                {isLoading && (
                  <>
                    <p
                      className={`text-primary text-xs font-medium ${
                        isLoading ? 'custom-loading w-fit mx-auto' : ''
                      }`}
                    >
                      No Points
                    </p>
                    <p
                      className={`text-gray500 text-xs font-normal pb-1 border-b border-utilityGray200 ${
                        isLoading ? 'custom-loading w-fit mx-auto' : ''
                      }`}
                    >
                      No New Customers
                    </p>
                  </>
                )}
                <p
                  className={`text-[#EDEEF0] text-4xl font-bold tracking-[-0.674px]`}
                >
                  2nd
                </p>
              </div>
            </div>
          </li>
        )}

        {leaderBoardFirst ? (
          <li className="text-center min-w-[13%] w-[142px] ">
            <div className="mb-3">
              <div className="mb-2.5">
                {leaderBoardFirst.profileImage ? (
                  <img
                    className="w-14 h-14 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue mx-auto"
                    src={leaderBoardFirst.profileImage}
                  />
                ) : leaderBoardFirst.firstName && leaderBoardFirst.lastName ? (
                  <div className="w-14 h-14 rounded-lg border-2 border-utilityGray200 flex-none flex items-center justify-center uppercase bg-primary text-white text-lg mx-auto shadow-blue">
                    <span>
                      {getShortName(
                        `${leaderBoardFirst.firstName} ${leaderBoardFirst.lastName}`
                      )}
                    </span>
                  </div>
                ) : (
                  <img
                    src={defaultImg}
                    className="w-14 h-14 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue mx-auto"
                  />
                )}
              </div>
              <div className="flex justify-center items-center gap-x-1.5 max-w-full">
                <TooltipCmp
                  message={
                    isNameTruncated ? `${leaderBoardFirst.firstName}` : ''
                  }
                >
                  <h6
                    ref={nameRef}
                    className="text-grayLight900 text-xs font-semibold truncate max-w-28"
                  >
                    {leaderBoardFirst.firstName}
                  </h6>
                </TooltipCmp>
                <div className="flex-none">
                  <img src={trophy1} className="w-4 h-4 object-contain" />
                </div>
                <img
                  src={winnerFlag}
                  className="w-4 h-4 object-contain flex-none"
                />
              </div>
            </div>
            <div className="min-h-40 flex flex-col">
              <img src={winnerBoxTop} className="w-full" />
              <div className="flex-1 pt-2 space-y-1.5 px-1.5 bg-gradient-to-b from-[#F2F4F7] from-0% to-[rgba(242, 244, 247, 0.00)] to-100% -mt-[0.5px]">
                {/* <div
                  className={`rounded-md p-1.5 border border-utilityGray200 w-6 h-6 mx-auto shadow-[0_2px_15px_-2px_rgba(97,114,243,0.18)] bg-white ${
                    leaderBoardFirst.userId != badgeWinner ? 'opacity-0' : ''
                  }`}
                >
                  {leaderBoardFirst.userId == badgeWinner && (
                    <img src={trophy1} />
                  )}
                </div> */}
                <p className="text-primary text-xs font-medium">
                  {leaderBoardFirst.totalPoints} Points
                </p>
                <p className="text-gray500 text-xs font-normal pb-1 border-b border-utilityGray200">
                  {leaderBoardFirst.totalCustomers}{' '}
                  {leaderBoardFirst.totalCustomers <= 1
                    ? 'New Customer'
                    : 'New Customers'}
                </p>
                <p className="text-[#EDEEF0] text-4xl font-bold tracking-[-0.674px]">
                  1st
                </p>
              </div>
            </div>
          </li>
        ) : (
          <li className="text-center min-w-[13%] w-[142px] ">
            <div className="mb-3">
              <div
                className={`mb-2.5 ${
                  isLoading ? 'w-14 h-14 rounded-lg custom-loading mx-auto' : ''
                }`}
              >
                <img
                  src={defaultImg}
                  className="w-14 h-14 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue mx-auto"
                />
              </div>
              {isLoading && (
                <div className="flex justify-center items-center gap-x-1.5">
                  <TooltipCmp>
                    <h6
                      className={`text-grayLight900 text-xs font-semibold truncate max-w-28 ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      Unassigned
                    </h6>
                  </TooltipCmp>
                </div>
              )}
            </div>

            <div className="min-h-40 flex flex-col">
              <img src={winnerBoxTop} className="w-full" />
              <div className="pt-2 space-y-1.5 px-1.5 bg-gradient-to-b from-[#F2F4F7] from-0% to-[rgba(242, 244, 247, 0.00)] to-100% -mt-[0.5px] flex-1">
                {isLoading && (
                  <>
                    <p
                      className={`text-primary text-xs font-medium ${
                        isLoading ? 'custom-loading w-fit mx-auto' : ''
                      }`}
                    >
                      No Points
                    </p>
                    <p
                      className={`text-gray500 text-xs font-normal pb-1 border-b border-utilityGray200 ${
                        isLoading ? 'custom-loading w-fit mx-auto' : ''
                      }`}
                    >
                      No New Customers
                    </p>
                  </>
                )}
                <p
                  className={`text-[#EDEEF0] text-4xl font-bold tracking-[-0.674px]`}
                >
                  1st
                </p>
              </div>
            </div>
          </li>
        )}

        {leaderBoardThird ? (
          <li className="text-center min-w-[13%] w-[142px] ">
            <div className="mb-3">
              <div className={`mb-2.5`}>
                {leaderBoardThird.profileImage ? (
                  <img
                    className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue-sm mx-auto"
                    src={leaderBoardThird.profileImage}
                  />
                ) : leaderBoardThird.firstName && leaderBoardThird.lastName ? (
                  <div className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none flex items-center justify-center uppercase bg-primary text-white text-sm mx-auto shadow-blue-sm">
                    <span>
                      {getShortName(
                        `${leaderBoardThird.firstName} ${leaderBoardThird.lastName}`
                      )}
                    </span>
                  </div>
                ) : (
                  <img
                    src={defaultImg}
                    className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue-sm mx-auto"
                  />
                )}
              </div>
              <div className="flex justify-center items-center gap-x-1.5">
                {/* <h6 className="text-grayLight900 text-xs font-semibold">
                  {leaderBoardThird.firstName} {leaderBoardThird.lastName}
                </h6> */}
                <TooltipCmp
                  message={
                    isNameThirdTruncated ? `${leaderBoardThird.firstName}` : ''
                  }
                >
                  <h6
                    ref={nameRefThird}
                    className="text-grayLight900 text-xs font-semibold truncate max-w-28"
                  >
                    {leaderBoardThird.firstName}
                  </h6>
                </TooltipCmp>

                <div className="flex-none">
                  <img src={trophy3} className="w-4 h-4 object-contain" />
                </div>
              </div>
            </div>
            <div className="min-h-[115px] flex flex-col">
              <img src={winnerBoxTop} className="w-full" />
              <div className="flex-1 pt-2 space-y-1.5 px-1.5 bg-gradient-to-b from-[#F2F4F7] from-0% to-[rgba(242, 244, 247, 0.00)] to-100% -mt-[0.5px]">
                {/* <div
                  className={`rounded-md p-1.5 border border-utilityGray200 w-6 h-6 mx-auto shadow-[0_2px_15px_-2px_rgba(97,114,243,0.18)] bg-white ${
                    leaderBoardThird.userId != badgeWinner ? 'opacity-0' : ''
                  }`}
                >
                  {leaderBoardThird.userId == badgeWinner && (
                    <img src={trophy1} />
                  )}
                </div> */}
                <p className="text-primary text-xs font-medium">
                  {leaderBoardThird.totalPoints} Points
                </p>
                <p className="text-gray500 text-xs font-normal pb-1 border-b border-utilityGray200">
                  {leaderBoardThird.totalCustomers}{' '}
                  {leaderBoardThird.totalCustomers <= 1
                    ? 'New Customer'
                    : 'New Customers'}
                </p>
                <p className="text-[#EDEEF0] text-4xl font-bold tracking-[-0.674px]">
                  3rd
                </p>
              </div>
            </div>
          </li>
        ) : (
          <li className="text-center min-w-[13%] w-[142px] ">
            <div className="mb-3">
              <div
                className={`mb-2.5  ${
                  isLoading ? 'w-10 h-10 rounded-lg custom-loading mx-auto' : ''
                }`}
              >
                <img
                  src={defaultImg}
                  className="w-10 h-10 rounded-lg border-2 border-utilityGray200 flex-none shadow-blue-sm mx-auto"
                />
              </div>
              {isLoading && (
                <div className="flex justify-center items-center gap-x-1.5">
                  <TooltipCmp>
                    <h6
                      className={`text-grayLight900 text-xs font-semibold truncate max-w-28 ${
                        isLoading ? 'custom-loading' : ''
                      }`}
                    >
                      Unassigned
                    </h6>
                  </TooltipCmp>
                </div>
              )}
            </div>
            <div className="min-h-[115px] flex flex-col">
              <img src={winnerBoxTop} className="w-full" />
              <div
                className={`pt-2 space-y-1.5 px-1.5 bg-gradient-to-b from-[#F2F4F7] from-0% to-[rgba(242, 244, 247, 0.00)] to-100% -mt-[0.5px] flex-1 `}
              >
                {isLoading && (
                  <>
                    <p
                      className={`text-primary text-xs font-medium ${
                        isLoading ? 'custom-loading w-fit mx-auto' : ''
                      }`}
                    >
                      No Points
                    </p>
                    <p
                      className={`text-gray500 text-xs font-normal pb-1 border-b border-utilityGray200 ${
                        isLoading ? 'custom-loading w-fit mx-auto' : ''
                      }`}
                    >
                      No New Customers
                    </p>
                  </>
                )}
                <p
                  className={`text-[#EDEEF0] text-4xl font-bold tracking-[-0.674px]`}
                >
                  3rd
                </p>
              </div>
            </div>
          </li>
        )}

        {loginUserRank > 0 && totalUserCount > 0 && (
          <li className="rounded-md border border-utilityBrand200 shadow-xs bg-utilityindigo25 px-2 py-1 text-grayLight600 text-xs font-medium absolute bottom-2.5">
            You are ranked - {getNumberWithSuffix(loginUserRank)} out of{' '}
            {setNumberWithCommas(totalUserCount)} users
          </li>
        )}
        {!moment.utc().isAfter(moment.utc(eventDetail?.end_event)) && (
          <li className="rounded-[10px] border border-utilityGray200 bg-gray25 px-3 py-2 absolute top-2.5 left-2.5 flex items-center gap-x-1.5">
            <StatusDotProfileCmp
              dotColor={`${
                todayRemainingTimeInPercentage >= 25
                  ? 'primary'
                  : todayRemainingTimeInPercentage >= 10
                  ? 'yellow'
                  : 'red'
              }`}
              label={
                <>
                  Time Remaining: Today, <CountDownTimer endDate={next6PM} />
                </>
              }
            ></StatusDotProfileCmp>
          </li>
        )}
        {/* <li className="rounded-md border border-utilityGray200 bg-gray25 px-3 py-2 absolute top-2.5 right-2.5 flex items-center gap-x-1.5">
          <h6 className="text-utilityGray800 text-sm font-medium">Ends on :</h6>
          <p
            className={`text-grayLight600 text-xs font-medium ${
              isLoading ? 'custom-loading' : ''
            }`}
          >
            {eventDetail?.end_event
              ? moment
                  .utc(eventDetail.end_event)
                  .local()
                  .format('MMMM Do, YYYY')
              : ''}
          </p>
          <p
            className={`pl-1.5 border-l-2 border-borderPrimary text-xs font-medium ${
              isLoading ? 'custom-loading' : ''
            } ${
              eventRemainingTimeInPercentage >= 25
                ? 'text-fgSuccessPrimary'
                : eventRemainingTimeInPercentage >= 10
                ? 'text-yellow300'
                : 'text-fgErrorPrimary'
            }`}
          >
            <CountDownTimer endDate={eventEnd6PM} />
          </p>
        </li> */}
      </ul>
    </>
  );
};

export default WinnerBoard;
