import { Check, Star06 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactSelect, { components, NoticeProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { BasicContext } from 'src/context/BasicContext';
import { getShortName, onError } from 'src/utils/CommonFunctions';

import ActionTooltip from '../ActionTooltip';
import CheckBox from '../CheckBox';
import ErrorMsg from '../errorMsg';
// import TooltipCmp from '../TooltipCmp';

import { ISelectBoxProps } from './SelectBox.interface';

const SelectBox = ({
  errorText = '',
  label = '',
  options,
  menuPlaceOption,
  noOptionMessage = 'No Option',
  classNamePrefix,
  tabIndex,
  isSearchable = false,
  isMultipleSelection,
  labelClassName = '',
  imageClassName,
  isDisabled,
  isError,
  isReadOnly,
  value,
  defaultValue,
  onChangeFunc,
  onInputChange,
  isClearable,
  isLoading,
  placeholder,
  classComp = '',
  dropdownImageClassName,
  selectValueWrapClass,
  selectValueClass,
  isCloseOnScroll = true,
  icon,
  parentClassName,
  menuIsOpen,
  selectoptiontext,
  required,
  onMenuOpen,
  placeholderIcon,
  isShowAiStar,
  labelRight,
  labelWrapClassName,
  selectOptionSubLabelClass,
  selectInputSubLabelClass,
  inlineSelect = false,
  showCustomerImage = false,
  autoCompleteSelect = false,
  onCreateOption,
  isOptionLoading = false,
  selectoptionWraptext,
  selectValueMainClass,
  isMultipleEquipmentType,
  closeMenuOnSelect,
}: ISelectBoxProps) => {
  const selectRef = useRef<any>(null);
  const [menuIsOpenList, setMenuIsOpenList] = useState(menuIsOpen);
  const [isFocused, setIsFocused] = useState(false);
  const { setTooltipGlobalOpen } = useContext(BasicContext);

  const CustomOption = ({ ...props }: any) => {
    const { ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };

    return (
      <components.Option {...newProps}>
        {isOptionLoading ? (
          <>
            <div className="flex justify-between items-center align-middle mb-2">
              <div
                className={`flex justify-center items-center w-full flex-1 max-w-[calc(100%_-_15px)] ${selectValueWrapClass}`}
              >
                <div
                  className={`w-[18px] h-[18px] leading-[18px] mr-2 text-[8px] rounded-full font-bold flex items-center justify-center uppercase flex-none custom-loading image-loading ${
                    showCustomerImage
                      ? 'table-profile-customer-circle-xl'
                      : 'bg-primary text-white'
                  }`}
                >
                  <span>{'WA'}</span>
                </div>

                <div className={`flex-1 flex flex-wrap truncate`}>
                  <span className="flex justify-center items-center font-medium text-grayLight900 underline custom-loading">
                    ABF FREIGHT SYSTEM INC ABF FREIGHT SYSTEM INC
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="flex justify-between items-center align-middle">
            <div
              className={`w-full flex gap-x-1.5  ${
                !isMultipleSelection && 'max-w-[calc(100%_-_15px)]'
              }`}
            >
              {isMultipleSelection && (
                <>
                  <CheckBox
                    parentClassName="mt-0.5"
                    classes="z-10"
                    onChangeFunc={() => {}}
                    checked={
                      isMultipleEquipmentType
                        ? Array.isArray(value) &&
                          value.some((item) => item.value === props.data.value)
                        : props.isSelected
                    }
                  />
                </>
              )}

              <div
                className={`flex justify-center items-center w-full flex-1 truncate  ${selectValueWrapClass}`}
              >
                {props.data.icon ? (
                  <span className="mr-2">{props.data.icon}</span>
                ) : props.data.image ? (
                  <img
                    alt=""
                    className={`rounded-full w-5 h-5 mr-2 flex-none ${dropdownImageClassName}`}
                    src={props.data.image}
                    onError={onError}
                  />
                ) : (
                  props.data.isImage && (
                    <div
                      className={`w-[18px] h-[18px] leading-[18px] mr-2 text-[8px] rounded-full font-bold flex items-center justify-center uppercase flex-none ${
                        showCustomerImage
                          ? 'bg-utilityGray100 text-primary'
                          : 'bg-primary text-white'
                      }`}
                    >
                      <span>{getShortName(props.data.label)}</span>
                    </div>
                  )
                )}
                {props.data.value === 'all_user' ? (
                  <div className="w-5 h-5 mr-2"></div>
                ) : (
                  ''
                )}
                <div
                  className={`flex-1 flex flex-wrap  truncate ${selectoptionWraptext}`}
                >
                  <span
                    className={`truncate font-medium mr-2  ${selectoptiontext}`}
                  >
                    {props.data.label}
                  </span>
                  {props.data.subLabel ? (
                    <span
                      className={`flex justify-center items-center font-medium sub-label ${selectOptionSubLabelClass}`}
                    >
                      {props.data.subLabel}
                    </span>
                  ) : (
                    ''
                  )}
                  {props.data.email ? (
                    <span className="flex justify-center items-center font-medium text-grayLight900 underline">
                      {props.data.email}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
            {props.isSelected && !isMultipleSelection ? (
              <Check className="w-3 h-3 text-primary flex-none select-checkmark" />
            ) : (
              ''
            )}{' '}
          </div>
        )}
      </components.Option>
    );
  };

  const CustomNoOptionsMessage = (props: NoticeProps<any>) => (
    <components.NoOptionsMessage {...props}>
      <p className="text-[11px] text-textSecondary font-bold -my-[1px]">
        {noOptionMessage}
      </p>{' '}
      {/* Customize this message as needed */}
    </components.NoOptionsMessage>
  );

  // Custom clear indicator to prevent dropdown from opening when clearing
  const CustomClearIndicator = (props: any) => {
    const { innerProps } = props;

    return (
      <components.ClearIndicator
        {...props}
        innerProps={{
          ...innerProps,
          onMouseDown: (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            props.clearValue();
          },
        }}
      />
    );
  };

  useEffect(() => {
    setMenuIsOpenList(menuIsOpen);
  }, [menuIsOpen]);

  useEffect(() => {
    if (isCloseOnScroll) {
      const handleScroll = () => {
        setMenuIsOpenList(false);
      };

      const scrollableContainer = document.querySelector(
        '.main__layout_section'
      );

      if (scrollableContainer) {
        scrollableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (scrollableContainer) {
          scrollableContainer.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (menuIsOpenList && selectRef.current) {
      selectRef.current.focus();
    }
  }, [menuIsOpenList]);

  useEffect(() => {
    if (
      !isMultipleSelection &&
      selectRef.current &&
      selectRef.current.state.focusedOption &&
      selectRef.current?.state.focusedOption?.value !== value?.value
    ) {
      selectRef.current.state.focusedOption = '';
    }
  }, [menuIsOpen]);

  const handleFocus = () => {
    setIsFocused(true);
    setMenuIsOpenList(true);
  };

  useEffect(() => {
    if (menuIsOpenList && selectRef.current) {
      selectRef?.current?.setState({
        focusedOption: null,
      });

      // Focus on the first option when the menu opens
      if (selectRef.current?.select?.menuListRef) {
        const firstOption =
          selectRef.current.select.menuListRef?.querySelector(
            'div:first-child'
          );

        if (firstOption) {
          firstOption.blur();
        }
      }
    }
  }, [menuIsOpenList]);

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    setIsFocused(false);

    if (newValue === null && actionMeta.action === 'clear') {
      if (onChangeFunc) onChangeFunc(null, actionMeta);
      setMenuIsOpenList(false);
      setTimeout(() => {
        if (selectRef.current) {
          selectRef.current.blur();
        }
      }, 400);
    } else {
      if (onChangeFunc) onChangeFunc(newValue, actionMeta); // Normal change handling
    }
  };

  const CustomMultiValueContainer = (props: any) => {
    const { selectProps, data } = props;
    const selectedValues = selectProps.value;

    if (isMultipleEquipmentType && selectedValues?.length > 2) {
      if (selectedValues.indexOf(data) < 2) {
        return <components.MultiValueContainer {...props} />;
      } else if (selectedValues.indexOf(data) === selectedValues.length - 1) {
        return (
          <div className="inline select__multi-value text-right flex-1">
            <span className="pl-1 pr-2">...</span>
            <div className="select__multi-value__label">
              {selectedValues.length}
            </div>
          </div>
        );
      }

      return null;
    }

    return <components.MultiValueContainer {...props} />;
  };

  return (
    <>
      <div
        className={`${parentClassName} ${
          isShowAiStar ? 'ai-gradient-border-wrap' : ''
        }`}
      >
        <div className={`flex justify-between gap-x-2 ${labelWrapClassName}`}>
          {label && (
            <div className="flex">
              <label className={labelClassName}>{label}</label>
              {required && (
                <span className="text-red-600 ms-[2px] leading-4 text-xs">
                  *
                </span>
              )}
            </div>
          )}
          {labelRight && <>{labelRight}</>}
        </div>
        <div
          className={`wal-hq-select relative ${
            isLoading ? 'custom-loading rounded-lg' : ''
          } ${errorText || isError ? 'is-invalid' : ''}`}
        >
          {autoCompleteSelect ? (
            <CreatableSelect
              ref={selectRef}
              value={value}
              defaultValue={defaultValue}
              onChange={handleChange}
              onInputChange={onInputChange}
              onCreateOption={onCreateOption}
              options={options}
              menuPlacement={menuPlaceOption}
              formatOptionLabel={(option) => (
                <div
                  className={`flex justify-between ${selectValueMainClass} `}
                >
                  <div
                    className={`flex items-center w-full ${selectValueClass} `}
                  >
                    {option.customLabel ? (
                      <span className="font-medium ">
                        {option.customLabel}&nbsp;
                      </span>
                    ) : (
                      ''
                    )}
                    {option?.icon ? (
                      <span className="mr-2">{option.icon}</span>
                    ) : option?.image ? (
                      <img
                        alt=""
                        className={`rounded-full w-5 h-5 mr-2 ${imageClassName}`}
                        src={option.image}
                        onError={onError}
                      />
                    ) : (
                      option.isImage && (
                        <div
                          className={`w-[18px] h-[18px] leading-[18px]  text-[8px] mr-2 rounded-full font-bold flex items-center justify-center uppercase flex-none ${
                            showCustomerImage
                              ? 'table-profile-customer-circle-xl'
                              : 'bg-primary text-white'
                          }`}
                        >
                          <span>{getShortName(option.label)}</span>
                        </div>
                      )
                    )}
                    <span className="truncate font-medium">
                      {option.datetime && (
                        <ActionTooltip
                          message={option.datetime}
                          openOnHover={true}
                          isOpenChildTopPosOnTop={true}
                          type="dark"
                          tooltipPosition="center"
                          parentClassName="w-max"
                        >
                          {option.label}
                        </ActionTooltip>
                      )}
                      {!option.datetime && option.label}
                    </span>

                    {option.email ? (
                      <span className="flex justify-center items-center font-medium underline ml-2">
                        {option.email}
                      </span>
                    ) : (
                      ''
                    )}
                    {option.subLabel ? (
                      <span
                        className={`flex justify-center items-center font-medium ml-2 ${selectInputSubLabelClass}`}
                      >
                        {option.subLabel}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
              hideSelectedOptions={false}
              tabIndex={tabIndex}
              classNamePrefix={classNamePrefix ? classNamePrefix : 'select'}
              isSearchable={
                value?.length || Boolean(value && value.value)
                  ? false
                  : isSearchable
              }
              filterOption={onInputChange ? null : undefined}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isMulti={isMultipleSelection}
              placeholder={placeholder}
              className={`${classComp}`}
              components={{
                ...(isDisabled && isMultipleSelection
                  ? { MultiValueRemove: () => null }
                  : {}),
                Option: CustomOption,
                NoOptionsMessage: CustomNoOptionsMessage,
                MultiValueContainer: CustomMultiValueContainer,
                ClearIndicator: isClearable ? CustomClearIndicator : undefined,
                Placeholder: (props: any) => (
                  <components.Placeholder {...props}>
                    {isFocused && isSearchable ? false : placeholderIcon}
                    {isFocused && isSearchable ? false : props.children}
                  </components.Placeholder>
                ),
              }}
              menuIsOpen={menuIsOpenList}
              menuPortalTarget={inlineSelect ? null : document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 1111 }) }}
              classNames={{
                menuPortal: () => 'wal-hq-select',
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onMenuOpen={() => {
                setTooltipGlobalOpen(false);
                setMenuIsOpenList(true);

                if (onMenuOpen) {
                  onMenuOpen();
                }
              }}
              onMenuClose={() => {
                if (isCloseOnScroll) {
                  setMenuIsOpenList(false);
                }
              }}
            />
          ) : (
            <ReactSelect
              ref={selectRef}
              value={value}
              defaultValue={defaultValue}
              onChange={handleChange}
              onInputChange={onInputChange}
              options={options}
              menuPlacement={menuPlaceOption}
              formatOptionLabel={(option) => (
                <div className={`flex justify-between ${selectValueMainClass}`}>
                  <div
                    className={`flex items-center w-full ${selectValueClass} `}
                  >
                    {option.customLabel ? (
                      <span className="font-medium ">
                        {option.customLabel}&nbsp;
                      </span>
                    ) : (
                      ''
                    )}
                    {option?.icon ? (
                      <span className="mr-2">{option.icon}</span>
                    ) : option?.image ? (
                      <img
                        alt=""
                        className={`rounded-full w-5 h-5 mr-2 ${imageClassName}`}
                        src={option.image}
                        onError={onError}
                      />
                    ) : (
                      option.isImage && (
                        <div
                          className={`w-[18px] h-[18px] leading-[18px]  text-[8px] mr-2 rounded-full font-bold flex items-center justify-center uppercase flex-none ${
                            showCustomerImage
                              ? 'table-profile-customer-circle-xl'
                              : 'bg-primary text-white'
                          }`}
                        >
                          <span>{getShortName(option.label)}</span>
                        </div>
                      )
                    )}
                    <span className="truncate font-medium">
                      {option.datetime && (
                        <ActionTooltip
                          message={option.datetime}
                          openOnHover={true}
                          isOpenChildTopPosOnTop={true}
                          type="dark"
                          tooltipPosition="center"
                          parentClassName="w-max"
                        >
                          {option.label}
                        </ActionTooltip>
                      )}
                      {!option.datetime && option.label}
                    </span>

                    {option.email ? (
                      <span className="flex justify-center items-center font-medium underline ml-2">
                        {option.email}
                      </span>
                    ) : (
                      ''
                    )}
                    {option.subLabel ? (
                      <span
                        className={`flex justify-center items-center font-medium ml-2 ${selectInputSubLabelClass}`}
                      >
                        {option.subLabel}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              )}
              hideSelectedOptions={false}
              tabIndex={tabIndex}
              classNamePrefix={classNamePrefix ? classNamePrefix : 'select'}
              isSearchable={
                value?.length || Boolean(value && value.value)
                  ? false
                  : isSearchable
              }
              onKeyDown={(event) => {
                if (event.key === 'Tab' && !menuIsOpenList) {
                  setMenuIsOpenList(true);

                  if (selectRef.current) {
                    selectRef.current.focus();
                  }
                }

                if (event.key === ' ' && !isSearchable) {
                  event.preventDefault();
                }
              }}
              filterOption={onInputChange ? null : undefined}
              isClearable={isClearable}
              isDisabled={isReadOnly || isDisabled}
              isMulti={isMultipleSelection}
              placeholder={placeholder}
              className={`${classComp}`}
              components={{
                ...(isDisabled && isMultipleSelection
                  ? { MultiValueRemove: () => null }
                  : {}),
                Option: CustomOption,
                NoOptionsMessage: CustomNoOptionsMessage,
                MultiValueContainer: CustomMultiValueContainer,
                MultiValueRemove: () => null,
                ClearIndicator: isClearable ? CustomClearIndicator : undefined,
                Placeholder: (props: any) => (
                  <components.Placeholder {...props}>
                    {isFocused && isSearchable ? false : placeholderIcon}
                    {isFocused && isSearchable ? false : props.children}
                  </components.Placeholder>
                ),
              }}
              closeMenuOnSelect={
                closeMenuOnSelect ?? (isMultipleEquipmentType ? false : true)
              }
              menuIsOpen={menuIsOpenList}
              menuPortalTarget={inlineSelect ? null : document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 1111 }) }}
              classNames={{
                menuPortal: () => 'wal-hq-select',
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onMenuOpen={() => {
                setTooltipGlobalOpen(false);
                setMenuIsOpenList(true);

                if (onMenuOpen) {
                  onMenuOpen();
                }
              }}
              onMenuClose={() => {
                if (isCloseOnScroll) {
                  setMenuIsOpenList(false);
                }
              }}
            />
          )}
          {icon && icon}
          {isShowAiStar && (
            <div className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 mt-px">
              <ActionTooltip
                message={'Detected with AI'}
                isOpenChildTopPosOnTop={true}
                tooltipPosition="center"
                parentClassName="min-w-[120px]"
                openOnHover={true}
              >
                <Star06 className="w-4 h-4 text-primary " />
              </ActionTooltip>
            </div>
          )}
        </div>
        {errorText && typeof errorText !== 'boolean' && (
          <div className="[&:empty]:mt-0 mt-1.5">
            <ErrorMsg errorText={errorText}></ErrorMsg>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectBox;
