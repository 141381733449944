import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ConfirmModal from 'src/components/ConfirmModal';
import {
  DATE_FORMAT,
  FOOT,
  KGS,
  LBS,
  MEASUREMENT,
  QUOTE_CLASSIFICATION,
  TIME_FORMAT,
} from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import {
  createUpdateDimension,
  createUpdateService,
  getFreightClass,
  updateQuote,
} from 'src/services/QuoteService';
import { loadGoogleMapsScript } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import { IMapAddress, scriptId } from '../../AddressDetails/Address.interface';

import AddressTab from './AddressTab';
import DimensionTab from './DimensionTab';
import QuoteDetails from './QuoteDetails';
import {
  isValidAddress,
  isValidDimension,
  isValidQuoteDetail,
} from './ValidationFunction';

interface IFormData {
  [key: string]: any;
  addressDetail: {
    customerId: string;
    shipperId: number | null;
    shipperCompanyName: string;
    shipperAddress: IMapAddress;
    pickupDate: string;
    consigneeId: number | null;
    consigneeCompanyName: string;
    consigneeAddress: IMapAddress;
    totalWeight: string | number | undefined;
    linearFootage: string | number | undefined;
    classification: string | null;
    insuranceCommercialValue: string | number | undefined;
    primaryEquipmentType: any;
    insuranceCurrencyType: string;
    primaryEquipmentTypeFullName: any;
    secondaryEquipmentTypeFullNames: any;
    secondaryEquipmentTypes: any;
  };
  quoteDetail?: any;
  dimensions: any[];
}

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
  maxTotalWeight: 100000,
};

const initAction = {
  mode: '',
  tailgate: false,
  unusual: false,
  totalWeightMismatch: false,
};

const EditQuoteDetail = ({
  activeTab,
  setActiveTab,
  handleRateGathering,
  setQuoteDetailData,
  quoteId,
  handleClose,
  setAddressDetails,
  isViewQuote = false,
  setIsDisplayDimensionsDetail = () => {},
  setIsRefresh = () => {},
  classificationType,
  setIsFromQuoteDetail = () => {},
  initialQuoteData,
}: any) => {
  let { appearance } = useContext(BasicContext);
  const {
    googleKey,
    isServiceLoading,
    isVehicleTypeLoading,
    vehicleTypeOptions,
    serviceTypes,
    handlingUnitOptions,
    isHandlingUnitLoading,
  } = useContext(BasicContext);

  let { addressDetails, dimensions, additionalService } = initialQuoteData;

  const currentFormData: IFormData = {
    addressDetail: {
      customerId: addressDetails?.isCustomerDeleted
        ? ''
        : addressDetails.customerId,
      pickupDate: addressDetails.pickupDate,
      shipperId: addressDetails.shipperId,
      shipperCompanyName: addressDetails.shipperCompanyName,
      consigneeId: addressDetails.consigneeId,
      consigneeCompanyName: addressDetails.consigneeCompanyName,
      primaryEquipmentType: addressDetails?.primaryEquipmentType,
      secondaryEquipmentTypes:
        addressDetails?.secondaryEquipmentTypes?.split(','),
      classification: addressDetails?.classification,
      primaryEquipmentTypeFullName:
        addressDetails?.primaryEquipmentTypeFullName?.split(','),
      secondaryEquipmentTypeFullNames:
        addressDetails?.secondaryEquipmentTypeFullNames?.split(','),
      shipperAddress: {
        fullAddress: addressDetails.shipperFullAddress,
        address1: addressDetails.shipperAddress1,
        city: addressDetails.shipperCity,
        state: addressDetails.shipperState,
        country: addressDetails.shipperCountry,
        postal: addressDetails.shipperPostal,
        latitude: addressDetails.shipperLatitude,
        longitude: addressDetails.shipperLongitude,
        stateCode: addressDetails.shipperStateCode,
        countryCode: addressDetails.shipperCountryCode,
      },
      consigneeAddress: {
        fullAddress: addressDetails.consigneeFullAddress,
        address1: addressDetails.consigneeAddress1,
        city: addressDetails.consigneeCity,
        state: addressDetails.consigneeState,
        country: addressDetails.consigneeCountry,
        postal: addressDetails.consigneePostal,
        latitude: addressDetails.consigneeLatitude,
        longitude: addressDetails.consigneeLongitude,
        stateCode: addressDetails.consigneeStateCode,
        countryCode: addressDetails.consigneeCountryCode,
      },
      linearFootage: addressDetails.linearFootage,
      totalWeight: addressDetails.totalWeight,
      insuranceCommercialValue: addressDetails.insuranceCommercialValue,
      insuranceCurrencyType: addressDetails.insuranceCurrencyType,
    },
    dimensions: [
      {
        id: null,
        handlingUnit: '',
        handlingUnitNo: 0,
        weight: 0,
        itemLength: 0,
        width: 0,
        height: 0,
        totalWeight: 0,
        freightClass: 0,
        isCommodity: false,
        isStack: true,
        weightMeasurement: MEASUREMENT.WEIGHT1.value,
        commodityId: null,
        commodityName: null,
        sub: null,
        description: null,
      },
    ],
  };

  const [status, setStatus] = useState(
    document.getElementById(scriptId) ? true : false
  );
  const [quoteData, setQuoteData] = useState<any>({});
  const [action, setAction] = useState(initAction);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [quoteFormData, setQuoteFormData] = useState<any>(currentFormData);
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );

  const [validateMeasurement, setValidateMeasurement] = useState(
    initValidateMeasurement
  );
  const [validFormData, setValidFormData] = useState<any>({
    address: null,
    dimensions: null,
    quoteDetails: null,
  });
  const [isTotalWeightManuallyChanged, setIsTotalWeightManuallyChanged] =
    useState(false);
  const [isLinearFootageManuallyChanged, setIsLinearFootageManuallyChanged] =
    useState(false);
  const [totalDimensionsWeight, setTotalDimensionsWeight] = useState(null);
  const [isChangeTotalWeight, setIsChangeTotalWeight] =
    useState<boolean>(false);

  const [submitAction, setSubmitAction] = useState({
    isUpdateAddress: false,
    isUpdateDimension: false,
    isUpdateQuoteDetail: false,
  });
  const ltlTabArray = [
    {
      value: 'address',
      name: 'Address',
      isAllow: true,
    },
    {
      value: 'quote_detail',
      name: 'Quote Details',
      isAllow: true,
    },
    {
      value: 'dimensions',
      name: 'Dimensions',
      isAllow: true,
    },
  ];
  const ftlTabArray = [
    {
      value: 'address',
      name: 'Address',
      isAllow: true,
    },
    {
      value: 'quote_detail',
      name: 'Quote Details',
      isAllow: true,
    },
  ];

  let tabArray: any = [];
  const isMapScript = document.getElementById(scriptId);

  if (quoteFormData?.addressDetail?.classification) {
    if (
      quoteFormData?.addressDetail?.classification === QUOTE_CLASSIFICATION.LTL
    )
      tabArray = ltlTabArray;
    else tabArray = ftlTabArray;
  } else {
    if (classificationType == QUOTE_CLASSIFICATION.LTL) tabArray = ltlTabArray;
    else tabArray = ftlTabArray;
  }

  window.initMap = () => {
    setStatus(true);
  };

  useEffect(() => {
    if (initialQuoteData) {
      setQuoteData(initialQuoteData);
    }
  }, [initialQuoteData]);

  useEffect(() => {
    if (!isMapScript && googleKey) {
      loadGoogleMapsScript(googleKey, scriptId);
    }
  }, [isMapScript, googleKey]);

  // Process data and update state only once on component initialization
  useEffect(() => {
    const processData = () => {
      const dimensionData: any = [];
      const isTailgate: any = [];
      const formDataFromServices: any = {};
      const savedServicesArray: any = [];

      if (dimensions) {
        for (const dimension of dimensions) {
          delete dimension.createdAt;
          delete dimension.updatedAt;

          let freightClass = dimension.freightClass;
          let itemClass = dimension.itemClassForDayross;

          let lengthMeasurementValue = dimension.lengthMeasurement;
          setLengthMeasurement(lengthMeasurementValue);

          dimensionData.handlingUnit =
            handlingUnitOptions.length > 0 ? handlingUnitOptions[0].value : '';

          if (!freightClass || !itemClass) {
            const fetchFreightData = async () => {
              const freightData = await getFreightClass({
                itemLength: dimension.itemLength,
                width: dimension.width,
                height: dimension.height,
                weight: dimension.weight,
                lengthUnit: dimension.lengthMeasurement,
                weightUnit: dimension.weightMeasurement,
              })
                .then((dataRes: any) => dataRes?.data || 0)
                .catch(() => 0);

              dimension.freightClass = freightData?.freightClass;
              dimension.itemClassForDayross = freightData?.itemClassForDayross;
            };

            fetchFreightData();
          }

          dimensionData.push(dimension);
        }
      }

      if (additionalService) {
        additionalService.forEach((val: any) => {
          if (
            val.slug === 'tailgate_pickup' ||
            val.slug === 'tailgate_delivery'
          ) {
            isTailgate.push(val.serviceId);
          }

          if (val.slug === 'limited_access_delivery') {
            formDataFromServices.limitedAccess = true;
            formDataFromServices.limitedAccessType = val.limitedAccessType;
            formDataFromServices.limitedAccessOther = val.limitedAccessOther;
          }

          if (val.slug === 'in-bond') {
            formDataFromServices.inbond = true;
            formDataFromServices.inbondType = val.inbondType;
            formDataFromServices.inbondName = val.inbondName;
            formDataFromServices.inbondAddress = val.inbondAddress;
            formDataFromServices.inbondContactMethod = val.inbondContactMethod;
            formDataFromServices.inbondContactValue = val.inbondContactValue;
          }

          if (val.slug === 'dangerous_goods') {
            formDataFromServices.dangerousGoods = true;
            formDataFromServices.dangerGoodsType = val.dangerGoodsType;
            formDataFromServices.goodsClass = val.goodsClass;
            formDataFromServices.description = val.description;
            formDataFromServices.unitedNationsNumber = val.unitedNationsNumber;
            formDataFromServices.packagingGroup = val.packagingGroup;
            formDataFromServices.emergencyContactName =
              val.emergencyContactName;
            formDataFromServices.emergencyContactNumber =
              val.emergencyContactNumber;
            formDataFromServices.emergencyContactExt = val.emergencyContactExt;
          }

          savedServicesArray.push(val.serviceId);
        });
      }

      const serviceData = {
        serviceId: savedServicesArray.join(','),
        services: savedServicesArray,
        modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
        ...formDataFromServices,
      };

      // Set quote data in a single update
      setQuoteFormData((old: any) => ({
        ...old,
        addressDetail: currentFormData.addressDetail,
        dimensions: dimensionData.length ? dimensions : old.dimensions,
        services: {
          ...serviceData,
          isTailgate,
        },
      }));

      // After processing data, consider component loaded
      if (quoteId) {
        setIsLoading(false);
      }
    };

    // Only run this once when the component has all required data
    if (!isHandlingUnitLoading && handlingUnitOptions.length > 0) {
      processData();
    }
  }, [
    dimensions,
    additionalService,
    handlingUnitOptions,
    isHandlingUnitLoading,
    quoteId,
  ]);

  // Make sure quoteData stays in sync with initialQuoteData
  useEffect(() => {
    setQuoteData(initialQuoteData);
  }, [initialQuoteData]);

  useEffect(() => {
    setIsFromQuoteDetail(true);

    return () => {
      setIsFromQuoteDetail(false);
    };
  }, []);

  const handleServiceActionType = (actionKey: string) => {
    setAction((old) => ({ ...old, [actionKey]: true }));
  };

  const submitService = () => {
    let serviceDetail = quoteFormData.services;
    const serviceFormData: any = {
      quoteId: quoteId,
      serviceId: serviceDetail.services.join(','),
      limitedAccess: serviceDetail?.isLimitedMore,
      inbond: serviceDetail?.isInbondMore,
      dangerousGoods: serviceDetail?.isDangerousMore,
      isFromEditQuoteDetail: true,
      modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
    };

    if (serviceDetail?.isLimitedMore) {
      serviceFormData.limitedAccessType = serviceDetail?.limitedAccessType;
      serviceFormData.limitedAccessOther = serviceDetail?.limitedAccessOther;
    }

    if (serviceDetail?.isInbondMore) {
      serviceFormData.inbondType = serviceDetail?.inbondType;
      serviceFormData.inbondName = serviceDetail?.inbondName;
      serviceFormData.inbondAddress = serviceDetail?.inbondAddress;
      serviceFormData.inbondContactMethod = serviceDetail?.inbondContactMethod;
      serviceFormData.inbondContactValue = serviceDetail?.inbondContactValue;
    }

    if (serviceDetail?.isDangerousMore) {
      serviceFormData.dangerGoodsType = serviceDetail?.dangerType;
      serviceFormData.goodsClass = serviceDetail?.goodsClass;
      serviceFormData.description = serviceDetail?.description;
      serviceFormData.unitedNationsNumber = serviceDetail?.unitedNationsNumber;
      serviceFormData.packagingGroup = serviceDetail?.packagingGroup;
      serviceFormData.emergencyContactName =
        serviceDetail?.emergencyContactName;
      serviceFormData.emergencyContactNumber =
        serviceDetail?.emergencyContactNumber;
      serviceFormData.emergencyContactExt = serviceDetail?.emergencyContactExt;
    }

    if (serviceDetail?.isDeclaredValueMore) {
      serviceFormData.declaredValue = serviceDetail?.declaredValue;
      serviceFormData.isDeclaredValue = serviceDetail?.isDeclaredValueMore;
      serviceFormData.currency = serviceDetail?.declaredValueUnit;
    }
    createUpdateService(serviceFormData)
      .then(() => {
        if (setIsRefresh) setIsRefresh(true);
      })
      .finally(() => {
        handleClose();
        setIsUpdateLoading(false);
      })
      .catch(() =>
        WalToast.error('Additional services have not been updated', '')
      );
  };

  function containsWholeMatchForEquipmentType(str: string) {
    const strParts = str
      ? str.split(',').map((item) => item.trim().toLowerCase())
      : [];

    return ['van', 'dry van', 'reefer', 'straight truck'].some((item) =>
      strParts.includes(item.toLowerCase())
    );
  }

  const submitDimension = () => {
    let dimensionsData = quoteFormData.dimensions;

    createUpdateDimension({
      dimensions: dimensionsData,
      quoteId: quoteId,
      modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
    })
      .then((result) => {
        if (result) {
          if (submitAction.isUpdateQuoteDetail || submitAction.isUpdateAddress)
            submitService();
          else {
            setIsUpdateLoading(false);
            handleClose();
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsUpdateLoading(false);
        console.error(err);
        WalToast.error('Dimensions have not been updated', '');
      });
  };

  const submitAddressDetail = () => {
    let formData = quoteFormData.addressDetail;

    if (isChangeTotalWeight) {
      formData.totalWeight = totalDimensionsWeight;
    }

    let timezone =
      appearance?.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('timezone', timezone);

    let addressValidObj = isValidAddress(quoteFormData?.addressDetail);

    let quoteDetailValidObj = isValidQuoteDetail(quoteFormData?.addressDetail);

    if (!addressValidObj?.valid) {
      setActiveTab('address');
      setIsUpdateLoading(false);

      return true;
    }

    if (
      quoteFormData?.addressDetail?.classification !==
        QUOTE_CLASSIFICATION.FTL &&
      quoteFormData?.addressDetail?.classification !==
        QUOTE_CLASSIFICATION.VOLUME_LTL
    ) {
      let dimensionValidObj = isValidDimension(
        quoteFormData?.dimensions,
        validateMeasurement
      );

      setValidFormData({
        address: addressValidObj?.validFormData,
        dimensions:
          quoteFormData?.addressDetail?.classification !==
            QUOTE_CLASSIFICATION.FTL &&
          quoteFormData?.addressDetail?.classification !==
            QUOTE_CLASSIFICATION.VOLUME_LTL
            ? dimensionValidObj?.validFormData
            : null,
        quoteDetails: quoteDetailValidObj?.validFormData,
      });

      if (!dimensionValidObj?.valid) {
        setActiveTab('dimensions');
        setIsUpdateLoading(false);

        return true;
      }
    } else {
      setValidFormData({
        address: addressValidObj?.validFormData,
        dimensions: null,
        quoteDetails: quoteDetailValidObj?.validFormData,
      });
    }

    if (!quoteDetailValidObj?.valid) {
      setActiveTab('quote_detail');
      setIsUpdateLoading(false);

      return true;
    }

    const pickupDateTime = moment(formData.pickupDate)
      .hours(moment().hours())
      .minutes(moment().minutes())
      .seconds(moment().seconds())
      .utc()
      .format(`${DATE_FORMAT} ${TIME_FORMAT}`);

    const addressData: any = {
      customerId: formData.customerId,
      pickupDate: pickupDateTime,
      shipperId: formData.shipperId,
      shipperCompanyName: formData.shipperCompanyName,
      shipperFullAddress: formData.shipperAddress.fullAddress,
      shipperAddress1: formData.shipperAddress.address1,
      shipperCity: formData.shipperAddress.city,
      shipperState: formData.shipperAddress.state,
      shipperCountry: formData.shipperAddress.country,
      shipperPostal: formData.shipperAddress.postal,
      shipperLatitude: formData.shipperAddress.latitude,
      shipperLongitude: formData.shipperAddress.longitude,
      shipperStateCode: formData.shipperAddress.stateCode,
      shipperCountryCode: formData.shipperAddress.countryCode,
      consigneeId: formData.consigneeId,
      consigneeCompanyName: formData.consigneeCompanyName,
      consigneeFullAddress: formData.consigneeAddress.fullAddress,
      consigneeAddress1: formData.consigneeAddress.address1,
      consigneeCity: formData.consigneeAddress.city,
      consigneeState: formData.consigneeAddress.state,
      consigneeCountry: formData.consigneeAddress.country,
      consigneePostal: formData.consigneeAddress.postal,
      consigneeLatitude: formData.consigneeAddress.latitude,
      consigneeLongitude: formData.consigneeAddress.longitude,
      consigneeStateCode: formData.consigneeAddress.stateCode,
      consigneeCountryCode: formData.consigneeAddress.countryCode,
      primaryEquipmentType: formData.primaryEquipmentType?.toString(),
      secondaryEquipmentTypes: formData.secondaryEquipmentTypes?.toString(),
      primaryEquipmentTypeFullName:
        formData.primaryEquipmentTypeFullName?.toString(),
      secondaryEquipmentTypeFullNames:
        formData.secondaryEquipmentTypeFullNames?.toString(),
      linearFootage:
        formData?.classification !== QUOTE_CLASSIFICATION.FTL &&
        formData.linearFootage
          ? formData?.linearFootage ?? ''
          : '',
      totalWeight: formData.totalWeight ?? undefined,
      classification: formData?.classification,
      insuranceCommercialValue: formData.insuranceCommercialValue ?? 0,
      insuranceCurrencyType: formData.insuranceCurrencyType ?? 0,
      isFromQuoteRequest: quoteId ? true : false,
    };

    if (window.MODE !== 'production' && formData?.emailContentId) {
      addressData.emailContentId = formData?.emailContentId;
    }

    if (window.MODE !== 'production' && formData?.urgency) {
      addressData.urgency = formData?.urgency;
    }
    let oldPickUpDate = moment(quoteData.addressDetails.pickupDate).format(
      'YYYY-MM-DD'
    );
    let newPickUpDate = moment(formData.pickupDate).format('YYYY-MM-DD');
    let currentSavedServices = null;
    let updatedServices = null;

    if (quoteData?.additionalService?.length)
      currentSavedServices = quoteData.additionalService
        .map((val: any) => val.serviceId)
        .join(',');
    if (quoteFormData?.services?.services?.length)
      updatedServices = quoteFormData.services.services.join(',');

    if (
      (oldPickUpDate !== newPickUpDate ||
        quoteData.addressDetails.linearFootage !== formData.linearFootage ||
        quoteData.addressDetails.totalWeight !== formData.totalWeight ||
        quoteData?.addressDetails?.consigneeFullAddress !==
          formData.consigneeAddress.fullAddress ||
        quoteData?.addressDetails?.shipperFullAddress !==
          formData.shipperAddress.fullAddress ||
        currentSavedServices !== updatedServices ||
        formData?.primaryEquipmentTypeFullName?.toString() !==
          quoteData?.addressDetails?.primaryEquipmentTypeFullName?.toString() ||
        formData?.secondaryEquipmentTypeFullNames?.toString() !==
          quoteData?.addressDetails?.secondaryEquipmentTypeFullNames?.toString()) &&
      (containsWholeMatchForEquipmentType(
        formData?.primaryEquipmentTypeFullName?.toString()
      ) ||
        containsWholeMatchForEquipmentType(
          formData?.secondaryEquipmentTypeFullNames?.toString()
        )) &&
      formData.classification !== QUOTE_CLASSIFICATION.FTL
    ) {
      addressData.isFromEditQuoteDetail = true;
    }

    if (quoteId) {
      updateQuote(quoteId, addressData)
        .then((result: any) => {
          if (
            submitAction.isUpdateDimension &&
            quoteFormData?.addressDetail?.classification ===
              QUOTE_CLASSIFICATION.LTL
          )
            submitDimension();
          else {
            submitService();
          }

          setIsLoading(false);

          if (setQuoteDetailData) setQuoteDetailData([result?.data]);

          if (addressData.isFromEditQuoteDetail) {
            if (handleRateGathering) handleRateGathering(quoteData);
            if (setAddressDetails) setAddressDetails(result?.data);
          }
        })
        .catch((error: any) => {
          console.log('error updateQupte', error);
          setIsUpdateLoading(false);

          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    }
  };

  const updateQuoteDetail = () => {
    let isTotalWeightOver = false;
    let isItemLengthOver = false;

    if (
      quoteFormData?.addressDetail?.classification === QUOTE_CLASSIFICATION?.LTL
    ) {
      let dimensionsToCheck = quoteFormData.dimensions;
      let totalWeight = 0;

      setWeightMeasurement(dimensionsToCheck[0].weightMeasurement);
      dimensionsToCheck.forEach((dimension: any) => {
        totalWeight += dimension.totalWeight;
        const maxLengthOver =
          dimensionsToCheck[0].weightMeasurement === MEASUREMENT.WEIGHT1
            ? LBS.ITEM_LENGTH_OVER
            : KGS.ITEM_LENGTH_OVER;

        if (dimension.itemLength >= maxLengthOver) {
          isItemLengthOver = true;
        }
      });

      isTotalWeightOver =
        dimensionsToCheck[0].weightMeasurement === MEASUREMENT.WEIGHT1?.value
          ? totalWeight >= LBS.MAX_WEIGHT
          : totalWeight >= KGS.MAX_WEIGHT;
    }

    if (
      quoteFormData?.services?.isTailgate.length &&
      (isTotalWeightOver || isItemLengthOver) &&
      quoteFormData?.addressDetail?.classification === QUOTE_CLASSIFICATION?.LTL
    ) {
      setIsLoading(false);
      handleServiceActionType('tailgate');
    } else if (
      quoteFormData?.addressDetail?.classification ===
        QUOTE_CLASSIFICATION?.LTL &&
      quoteFormData.addressDetail.totalWeight &&
      totalDimensionsWeight &&
      totalDimensionsWeight != quoteFormData.addressDetail.totalWeight
    ) {
      handleServiceActionType('totalWeightMismatch');
    } else {
      submitAddressDetail();
    }
  };

  useEffect(() => {
    if (quoteFormData?.dimensions?.length > 0) {
      const totalDimensionsWeightData = quoteFormData?.dimensions.reduce(
        (sum: any, item: any) => +sum + (+item.totalWeight || 0),
        0
      );

      setTotalDimensionsWeight(totalDimensionsWeightData || undefined);
    }
  }, [quoteFormData]);

  const handleModalClose = useCallback(
    (statuss: boolean) => () => {
      setAction(initAction);
      console.log('status', status);

      if (statuss) {
        submitAddressDetail();
      } else {
        setIsUpdateLoading(false);
      }
    },
    [action]
  );

  const handleWeightModalClose = useCallback(
    (isStatus: boolean) => () => {
      setAction(initAction);

      if (isStatus) {
        const totalDimensionsWeightData = quoteFormData.dimensions?.reduce(
          (sum: number, item: any) => sum + (+item.totalWeight || 0),
          0
        );

        setTotalDimensionsWeight(totalDimensionsWeightData);
        setIsChangeTotalWeight(true);
      } else {
        setIsUpdateLoading(false);
      }
    },
    [action]
  );

  useEffect(() => {
    if (isChangeTotalWeight) {
      submitAddressDetail();
      setIsChangeTotalWeight(false);
    }
  }, [isChangeTotalWeight]);

  return (
    <>
      {activeTab === 'address' ? (
        <AddressTab
          key={quoteData?.addressDetails?.id}
          quoteFormData={quoteFormData}
          setSubmitAction={setSubmitAction}
          setQuoteFormData={setQuoteFormData}
          isUpdateLoading={isUpdateLoading}
          setIsUpdateLoading={setIsUpdateLoading}
          updateQuoteDetail={updateQuoteDetail}
          isQuoteLoading={isLoading || isServiceLoading}
          tabArray={tabArray}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          quoteData={quoteData}
          quoteId={quoteId}
          serviceTypes={serviceTypes}
          handleClose={() => {
            if (handleClose) handleClose();
          }}
          status={status}
          validFormDetail={validFormData?.address}
          weightMeasurement={weightMeasurement}
          setWeightMeasurement={setWeightMeasurement}
          isViewQuote={isViewQuote}
          setIsDisplayDimensionsDetail={setIsDisplayDimensionsDetail}
          isServiceLoading={isServiceLoading}
        />
      ) : (
        ''
      )}
      {activeTab === 'dimensions' ? (
        <DimensionTab
          key={quoteData?.addressDetails?.id}
          quoteFormData={quoteFormData}
          setSubmitAction={setSubmitAction}
          isQuoteLoading={isLoading}
          setQuoteFormData={setQuoteFormData}
          updateQuoteDetail={updateQuoteDetail}
          quoteId={quoteId}
          isUpdateLoading={isUpdateLoading}
          setIsUpdateLoading={setIsUpdateLoading}
          tabArray={tabArray}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          handlingUnitOptions={handlingUnitOptions}
          quoteData={quoteData}
          setLengthMeasurement={setLengthMeasurement}
          lengthMeasurement={lengthMeasurement}
          handleClose={handleClose}
          setValidateMeasurement={setValidateMeasurement}
          validateMeasurement={validateMeasurement}
          validFormDetail={validFormData?.dimensions}
          isViewQuote={isViewQuote}
          setIsDisplayDimensionsDetail={setIsDisplayDimensionsDetail}
          isTotalWeightManuallyChanged={isTotalWeightManuallyChanged}
          setIsTotalWeightManuallyChanged={setIsTotalWeightManuallyChanged}
          isLinearFootageManuallyChanged={isLinearFootageManuallyChanged}
          totalDimensionsWeight={totalDimensionsWeight}
          setTotalDimensionsWeight={setTotalDimensionsWeight}
          isHandlingUnitLoading={isHandlingUnitLoading}
        />
      ) : (
        ''
      )}
      {activeTab === 'quote_detail' ? (
        <QuoteDetails
          key={quoteData?.addressDetails?.id}
          tabArray={tabArray}
          setSubmitAction={setSubmitAction}
          updateQuoteDetail={updateQuoteDetail}
          setQuoteFormData={setQuoteFormData}
          quoteFormData={quoteFormData}
          quoteId={quoteId}
          isQuoteLoading={isLoading}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          quoteData={quoteData}
          isUpdateLoading={isUpdateLoading}
          setIsUpdateLoading={setIsUpdateLoading}
          serviceTypes={serviceTypes}
          handleClose={handleClose}
          equipmentTypeOptions={vehicleTypeOptions}
          isEquipmentTypeLoading={isVehicleTypeLoading}
          validFormDetail={validFormData?.quoteDetails}
          isViewQuote={isViewQuote}
          setIsDisplayDimensionsDetail={setIsDisplayDimensionsDetail}
          isServiceLoading={isServiceLoading}
          setIsLinearFootageManuallyChanged={setIsLinearFootageManuallyChanged}
        />
      ) : (
        ''
      )}

      {action.tailgate && (
        <ConfirmModal
          title="Tailgate Over Dimensions"
          description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
            weightMeasurement === MEASUREMENT.WEIGHT1.value
              ? `${LBS.MAX_WEIGHT}lbs`
              : `${KGS.MAX_WEIGHT}kgs`
          }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
          button1="Yes, I am sure"
          button2="Modify Selection"
          handleClose={handleModalClose}
        />
      )}
      {action.unusual && (
        <ConfirmModal
          title="Unusual selection"
          description="Based on the options you selected, a lot of carriers might not give a direct quote for this load. Are you sure you want to proceed?"
          button1="Yes, I am sure"
          button2="Modify Selection"
          handleClose={handleModalClose}
        />
      )}

      {action.totalWeightMismatch &&
        isValidDimension(quoteFormData?.dimensions, validateMeasurement)
          ?.valid && (
          <ConfirmModal
            title="Total Weight Mismatch"
            description={`The total weight you entered ${
              quoteFormData.totalWeight
                ? `(${quoteFormData.totalWeight} ${
                    weightMeasurement === MEASUREMENT.WEIGHT1.value
                      ? 'lbs'
                      : 'kgs'
                  })`
                : ''
            } does not match the sum of all dimension weights ${
              totalDimensionsWeight
                ? `(${totalDimensionsWeight} ${
                    weightMeasurement === MEASUREMENT.WEIGHT1.value
                      ? 'lbs'
                      : 'kgs'
                  })`
                : ''
            }. 
  
  Would you like to update the total weight to match the dimension weight?`}
            button1="Yes, Update Total Weight"
            button2="Close"
            handleClose={handleWeightModalClose}
          />
        )}
    </>
  );
};
export default EditQuoteDetail;
