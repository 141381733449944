import moment from 'moment';

export const getBusinessDays = (startDate: Date, endDate: Date) => {
  let count = 0;
  const oneDay = 24 * 60 * 60 * 1000;

  for (
    let date = moment(startDate).toDate();
    date <= endDate;
    date.setTime(date.getTime() + oneDay)
  ) {
    const dayOfWeek = date.getDay();

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      count++;
    }
  }

  return count;
};

export const isValidServices = (formData: any, setValidForm: any) => {
  const services: any = [];
  formData?.services.map((item: any) => {
    let itemKey = Object.keys(item);

    services.push({
      label:
        item?.[itemKey?.[0]]?.name && item?.[itemKey?.[0]]?.name !== ''
          ? true
          : false,
      price: true,
    });
  });

  setValidForm((prevValidForm: any) => ({
    ...prevValidForm,
    services: services,
  }));

  const isValidFields = services?.every((item: any) => item.label);

  return isValidFields;
};

export const addItems = (
  setFormData: any,
  setValidForm: any,
  setIsFreightClassLoading: any,
  setOpenItems: any,
  formData: any,
  initFormData: any,
  initValidForm: any
) => {
  setFormData((old: any) => ({
    ...old,
    dimensions: [...old.dimensions, ...initFormData.dimensions],
  }));

  setValidForm((old: any) => ({
    ...old,
    dimensions: [...old.dimensions, ...initValidForm.dimensions],
  }));

  setIsFreightClassLoading((old: any) => [...old, { isLoading: false }]);

  setOpenItems((prev: any) => {
    const newOpenItems = new Set(prev);
    newOpenItems.add(formData?.dimensions?.length);

    return newOpenItems;
  });
};

export const duplicateItems = (
  formData: any,
  setFormData: any,
  validForm: any,
  setValidForm: any,
  setOpenItems: any,
  initFormData: any,
  initValidForm: any
) => {
  if (formData?.dimensions?.length > 0) {
    const lastItem = formData?.dimensions[formData?.dimensions?.length - 1];

    delete lastItem?.id;

    setFormData((old: any) => ({
      ...old,
      dimensions: [...old.dimensions, lastItem],
    }));

    const lastValidItem =
      validForm?.dimensions[formData?.dimensions?.length - 1];

    setValidForm((old: any) => ({
      ...old,
      dimensions: [...old.dimensions, lastValidItem],
    }));
  } else {
    setFormData((old: any) => ({
      ...old,
      dimensions: [...old.dimensions, ...initFormData.dimensions],
    }));

    setValidForm((old: any) => ({
      ...old,
      dimensions: [...old.dimensions, ...initValidForm.dimensions],
    }));
  }

  setOpenItems((prev: any) => {
    const newOpenItems = new Set(prev);
    newOpenItems.add(formData?.dimensions?.length);

    return newOpenItems;
  });
};

export const removeItems = (
  id: any,
  formData: any,
  setFormData: any,
  initFormData: any,
  setValidForm: any,
  initValidForm: any
) => {
  if (formData?.dimensions?.length > 1) {
    const form = formData.dimensions?.filter(
      (formDataField: any, index: number) => index !== id
    );
    setFormData((old: any) => ({
      ...old,
      dimensions: form,
    }));
  } else {
    setFormData((old: any) => ({
      ...old,
      dimensions: [...initFormData.dimensions],
    }));

    setValidForm((old: any) => ({
      ...old,
      dimensions: [...initValidForm.dimensions],
    }));
  }
};
