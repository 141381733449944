import { yupResolver } from '@hookform/resolvers/yup';
import { Save01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormProvider, Resolver, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonCmp from 'src/components/ButtonCmp';
import ConfirmModal from 'src/components/ConfirmModal';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import StepBar from 'src/components/StepBar/StepBar';
import { FOOT, KGS, LBS, MEASUREMENT, TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { ROUTES } from 'src/constants/routes';
import { listHandlingUnit, listService } from 'src/services/CommonService';
import { CustomersAllList } from 'src/services/CustomerService';
import { getLoadLinkVehicleType } from 'src/services/LoadLinkService';
import {
  createOrder,
  getActiveQuote,
  getOrder,
  saveOrderAsDraft,
  updateOrder,
} from 'src/services/OrderService';
import { listShippers } from 'src/services/ShipperService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

import OrderHeader from '../Common/OrderHeader';
import RadarAndInternalChat from '../Common/RadarAndInternalChat';
import { constructOrderParam, STEPS } from '../order.constant';
import {
  defualtOrderValue,
  initAction,
  processOrderData,
  setAppointmentValues,
  step1Schema,
  step2Schema,
  step3Schema,
  step4Schema,
  step5Schema,
  step6Schema,
} from '../order.interface';

import Step1 from './Step1/Step1';
import Step2AndStep3 from './Step2AndStep3';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';

export const OrderContext = createContext({});

const orderDimensionInitAction = {
  tailgate: false,
  unusual: false,
};

const OrderDetails = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const location = useLocation();
  const timeFilter = location.state?.timeFilter;
  const customerId = location.state?.customerId;
  const orderQuoteId = location.state?.quoteId;

  const [action, setAction] = useState(initAction);
  const [orderDimensionAction, setOrderDimensionAction] = useState(
    orderDimensionInitAction
  );
  const [currentStep, setCurrentStep] = useState(1);
  const [stepperTitle, setStepperTitle] = useState('Add Order Metadata');
  const [stepperDescription, setStepperDescription] = useState(
    'Please provide the metadata for your order.'
  );
  const [salesPrice, setSalesPrice] = useState<any>([]);
  const [carrierPrice, setCarrierPrice] = useState<any>([]);
  const [serviceList, setServiceList] = useState([]);
  const [order, setOrder] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSaveAsDraftLoading, setIsSaveAsDraftLoading] = useState(false);
  const [shippers, setShippers] = useState<any>([]);
  const [consignees, setConsignees] = useState<any>([]);
  const [shipperOptions, setShipperOptions] = useState([]);
  const [consigneeOptions, setConsigneeOptions] = useState([]);
  const [isTailgate, setIsTailgate] = useState<any>([]);
  const [isAllowOverWeigth, setIsAllowOverWeigth] = useState(false);
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );

  const [customerList, setCustomerList] = useState<any[]>([]);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);

  const [isValidAddress, setIsValidAddress] = useState({
    shipperAddress: true,
    shipperId: true,
    consigneeAddress: true,
    consigneeId: true,
    shipperCompanyName: true,
    consigneeCompanyName: true,
    shipperContactName: true,
    consigneeContactName: true,
    shipperEmail: true,
    consigneeEmail: true,
    shipperPhoneNumber: true,
    consigneePhoneNumber: true,
    isActiveCarrierSelected: true,
  });
  const [isConsigneeBtnDisable, setIsConsigneeBtnDisable] = useState(false);
  const [isShipperBtnDisable, setIsShipperBtnDisable] = useState(false);
  const [isShipperContactBtnDisable, setIsShipperContactBtnDisable] =
    useState(false);
  const [isConsigneeContactBtnDisable, setIsConsigneeContactBtnDisable] =
    useState(false);

  const [currentLegData, setCurrentLegData] = useState<any>(null);
  const [shipperAddress, setShipperAddress] = useState<any>(null);
  const [shipperContact, setShipperContact] = useState<any>([]);
  const [shipperContactOptions, setShipperContactOptions] = useState<any>([]);
  const [shipperAdditionalServiceIds, setShipperAdditionalServiceIds] =
    useState<any>([]);
  const [consigneeAddress, setConsigneeAddress] = useState<any>(null);
  const [consigneeContact, setConsigneeContact] = useState<any>([]);
  const [consigneeContactOptions, setConsigneeContactOptions] = useState<any>(
    []
  );
  const [consigneeAdditionalServiceIds, setConsigneeAdditionalServiceIds] =
    useState<any>([]);
  const [shipperFullAddress, setShipperFullAddress] = useState<any>(null);
  const [consigneeFullAddress, setConsigneeFullAddress] = useState<any>(null);
  const [isShowSaveAsDraftModal, setIsShowSaveAsDraftModal] = useState(false);
  const [isShowChatSideBar, setIsShowChatSideBar] = useState(true);
  const [equipmentTypeOptions, setEquipmentTypeOptions] = useState<any[]>([]);
  const [isEquipmentTypeLoading, setIsEquipmentTypeLoading] = useState(true);
  const [orderType, setOrderType] = useState('ltl');
  const [isAppointmentInEditMode, setIsAppointmentInEditMode] = useState(false);
  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);

  //Quote states
  const [quoteOptions, setQuoteOptions] = useState<any>([]);
  const [selectedQuote, setSelectedQuote] = useState<any>(null);
  const [activeCarrier, setActiveCarrier] = useState<any>(null);

  const [isShipperPhoneNumberValue, setIsShipperPhoneNumberValue] =
    useState<boolean>(true);
  const [isConsigneePhoneNumberValue, setIsConsigneePhoneNumberValue] =
    useState<boolean>(true);

  let orderSchema = step1Schema;

  if (currentStep === 1) {
    orderSchema = step1Schema;
  } else if (currentStep === 2) {
    orderSchema = step2Schema as unknown as typeof step1Schema;
  } else if (currentStep === 3) {
    orderSchema = step3Schema as unknown as typeof step1Schema;
  } else if (currentStep === 4) {
    orderSchema = isAppointmentInEditMode
      ? (step4Schema as unknown as typeof step1Schema)
      : step1Schema;
  } else if (currentStep === 5) {
    orderSchema = step5Schema as unknown as typeof step1Schema;
  } else if (currentStep === 6) {
    orderSchema = step6Schema as unknown as typeof step1Schema;
  }

  const methods = useForm({
    resolver: yupResolver(orderSchema) as Resolver<any>,
    defaultValues: defualtOrderValue,
    context: {
      orderType: orderType,
    },
  });

  const {
    handleSubmit,
    reset,
    getValues,
    trigger,
    clearErrors,
    watch,
    setValue,
  } = methods;

  const orderTypeWatch = watch('orderType');
  const watchCustomerId = watch('orderCustomerId');
  const watchQuoteId = watch('quoteId');

  useEffect(() => {
    setOrderType(orderTypeWatch);
  }, [orderTypeWatch]);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  useEffect(
    () => () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    },
    []
  );

  const getOrderData = () => {
    setIsLoading(true);

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const param = { id };

    getOrder(param, signal)
      .then((response) => {
        if (response?.data) {
          const masterOrder = response?.data;

          let orderLegsData: any = [];

          if (masterOrder?.orderLegs) {
            orderLegsData = masterOrder?.orderLegs;
          }

          setOrder({ ...masterOrder, orderLegs: orderLegsData });

          orderLegsData.reduce((acc: any, subOrder: any, index: number) => {
            if (index === 0) {
              const resetOrderData = processOrderData({
                ...subOrder,
                orderType: masterOrder?.orderType,
                equipmentType: masterOrder?.equipmentType,
                equipmentTypeFullName: masterOrder?.equipmentTypeFullName,
              });

              setCurrentLegData(resetOrderData);
              setShipperAddress(
                resetOrderData?.shipper?.shipperAddress?.fullAddress
              );
              setConsigneeAddress(
                resetOrderData?.consignee?.consigneeAddress?.fullAddress
              );

              setShipperFullAddress(resetOrderData?.shipper?.shipperAddress);
              setConsigneeFullAddress(
                resetOrderData?.consignee?.consigneeAddress
              );

              const {
                shipperAppointmentDate,
                shipperAppointmentTime,
                carrierPickupAppointmentDate,
                carrierPickuAppointmentTime,
                deliveryAppointmentDate,
                deliveryAppointmentTime,
                carrierDeliveryAppointmentDate,
                carrierDeliveryAppointmentTime,
              } = setAppointmentValues(subOrder);

              reset({
                ...resetOrderData,
                orderCustomerId: masterOrder?.customerId,
                declaredValue: masterOrder?.declaredValue,
                declaredValueUnit: masterOrder?.declaredValueUnit ?? 'CAD',
                totalWeight: masterOrder?.totalWeight,
                expectedPickupTime: resetOrderData?.expectedPickupTime
                  ? resetOrderData?.expectedPickupTime
                  : moment().local().toDate(),
                expectedEstimatedDeliveryTime:
                  resetOrderData?.expectedEstimatedDeliveryTime
                    ? resetOrderData?.expectedEstimatedDeliveryTime
                    : moment().local().toDate(),
                shipperAppointmentDate: shipperAppointmentDate,
                shipperAppointmentTime: shipperAppointmentTime,
                carrierPickupAppointmentDate: carrierPickupAppointmentDate,
                carrierPickuAppointmentTime: carrierPickuAppointmentTime,
                deliveryAppointmentDate: deliveryAppointmentDate,
                deliveryAppointmentTime: deliveryAppointmentTime,
                carrierDeliveryAppointmentDate: carrierDeliveryAppointmentDate,
                carrierDeliveryAppointmentTime: carrierDeliveryAppointmentTime,
                // quoteId: resetOrderData?.quoteId,
              });

              setWeightMeasurement(resetOrderData?.weightMeasurement);
              setLengthMeasurement(resetOrderData?.lengthMeasurement);

              setSalesPrice(subOrder?.salesPrice);
              setCarrierPrice(resetOrderData?.carrierPrice);
            }

            acc[subOrder.legId] = processOrderData(subOrder);

            return acc;
          }, {});

          // console.log('childOrderData', childOrderData);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          navigate(`${ROUTES.ORDERS}`);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getShipper = () => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    listShippers(signal)
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          shipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue,
            'id',
            'companyName'
          );
          consigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue,
            'id',
            'companyName'
          );
          setShipperOptions(shipperOptionsValue);
          setConsigneeOptions(consigneeOptionsValue);
        }
      })
      .finally(() => {
        // setIsShipperLoading(false)
      })
      .catch(console.error);
  };

  const getCustomerList = () => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    setCustomerList([]);
    setIsCustomerLoading(true);
    CustomersAllList(signal)
      .then((response: any) => {
        if (response.data) {
          const customerSelectBoxOptions = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true
          );
          setCustomerList(customerSelectBoxOptions);
        }
        setIsCustomerLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }
        setIsCustomerLoading(false);
      });
  };

  const getListService = () => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    listService(signal)
      .then((response: any) => {
        setServiceList(response.data);
      })
      .finally(() => {
        // setIsLoading(false);
      })
      .catch(console.log);
  };

  const getVehicleTypeOptions = () => {
    setIsEquipmentTypeLoading(true);

    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setEquipmentTypeOptions(updatedListVehicleTypes);
      })
      .catch((e) => {
        console.log('Equipment Type Error ', e);
      })
      .finally(() => {
        setIsEquipmentTypeLoading(false);
      });
  };

  const getHandlingUnits = () => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    listHandlingUnit(signal)
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        }
      })
      .finally(() => setIsHandlingUnitLoading(false))
      .catch(console.error);
  };

  const getActiveQuoteData = (quoteId: any) => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getActiveQuote({ customerId: watchCustomerId, quoteId }, signal)
      .then((response) => {
        if (response?.data) {
          const quoteSelectBoxOptions = response.data.map((quote: any) => ({
            value: quote.quote.id,
            label: `#${quote.quote.code}`,
            code: `${quote.quote.code}`,
            quote: quote,
          }));

          setQuoteOptions(quoteSelectBoxOptions);

          const selectedQuoteData = response.data.find(
            (quote: any) => quote.quote.id === quoteId
          );

          if (selectedQuoteData) {
            setSelectedQuote(selectedQuoteData);
          }
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (watchCustomerId) {
      getActiveQuoteData(watchQuoteId);
    }
  }, [watchCustomerId, watchQuoteId]);

  useEffect(() => {
    if (!id && selectedQuote?.quote) {
      setShipperAddress(selectedQuote?.quote?.shipperFullAddress);
      setShipperFullAddress(selectedQuote?.quote?.shipperFullAddress);

      setValue('shipper', {
        id: selectedQuote?.quote?.shipperId || null,
        companyName: selectedQuote?.quote?.shipperCompanyName || '',
        contactId: selectedQuote?.quote?.shipperContactId || null,
        contactName: selectedQuote?.quote?.shipperContactName || '',
        phoneNumber: selectedQuote?.quote?.shipperContactNumber || '',
        email: selectedQuote?.quote?.shipperEmail || '',
        shipperAddress: {
          fullAddress: selectedQuote?.quote?.shipperFullAddress || '',
          address1: selectedQuote?.quote?.shipperAddress1 || '',
          city: selectedQuote?.quote?.shipperCity || '',
          state: selectedQuote?.quote?.shipperState || '',
          country: selectedQuote?.quote?.shipperCountry || '',
          postal: selectedQuote?.quote?.shipperPostal || '',
          latitude: selectedQuote?.quote?.shipperLatitude || '',
          longitude: selectedQuote?.quote?.shipperLongitude || '',
          stateCode: selectedQuote?.quote?.shipperStateCode || '',
          countryCode: selectedQuote?.quote?.shipperCountryCode || '',
        },
      });

      setValue('expectedPickupTime', selectedQuote?.quote?.pickupDate);
      setValue('orderType', selectedQuote?.quote?.classification);
      setValue('orderCustomerId', selectedQuote?.quote?.customerId);
      setValue('equipmentType', selectedQuote?.quote?.primaryEquipmentType);
      setValue(
        'equipmentTypeFullName',
        selectedQuote?.quote?.primaryEquipmentTypeFullName
      );

      setConsigneeAddress(selectedQuote?.quote?.consigneeFullAddress);
      setConsigneeFullAddress(selectedQuote?.quote?.consigneeFullAddress);

      setValue('consignee', {
        id: selectedQuote?.quote?.consigneeId || null,
        contactId: selectedQuote?.quote?.consigneeContactId || null,
        companyName: selectedQuote?.quote?.consigneeCompanyName || '',
        contactName: selectedQuote?.quote?.consigneeContactName || '',
        phoneNumber: selectedQuote?.quote?.consigneeContactNumber || '',
        email: selectedQuote?.quote?.consigneeEmail || '',
        consigneeAddress: {
          fullAddress: selectedQuote?.quote?.consigneeFullAddress || '',
          address1: selectedQuote?.quote?.consigneeAddress1 || '',
          city: selectedQuote?.quote?.consigneeCity || '',
          state: selectedQuote?.quote?.consigneeState || '',
          country: selectedQuote?.quote?.consigneeCountry || '',
          postal: selectedQuote?.quote?.consigneePostal || '',
          latitude: selectedQuote?.quote?.consigneeLatitude || '',
          longitude: selectedQuote?.quote?.consigneeLongitude || '',
          stateCode: selectedQuote?.quote?.consigneeStateCode || '',
          countryCode: selectedQuote?.quote?.consigneeCountryCode || '',
        },
      });

      setValue('additionalServices', selectedQuote?.additionalServices);
      setValue('order_dimensions', selectedQuote?.dimensions);

      setCurrentLegData((prevData: any) => ({
        ...prevData,
        additionalServices: selectedQuote?.additionalServices,
        shipper: {
          ...prevData?.shipper,
          companyName: selectedQuote?.quote?.shipperCompanyName || '',
          shipperAddress: {
            fullAddress: selectedQuote?.quote?.shipperFullAddress || '',
            address1: selectedQuote?.quote?.shipperAddress1 || '',
            city: selectedQuote?.quote?.shipperCity || '',
            state: selectedQuote?.quote?.shipperState || '',
            country: selectedQuote?.quote?.shipperCountry || '',
            postal: selectedQuote?.quote?.shipperPostal || '',
            latitude: selectedQuote?.quote?.shipperLatitude || '',
            longitude: selectedQuote?.quote?.shipperLongitude || '',
            stateCode: selectedQuote?.quote?.shipperStateCode || '',
            countryCode: selectedQuote?.quote?.shipperCountryCode || '',
          },
        },

        consignee: {
          ...prevData?.consignee,
          companyName: selectedQuote?.quote?.consigneeCompanyName || '',
          consigneeAddress: {
            fullAddress: selectedQuote?.quote?.consigneeFullAddress || '',
            address1: selectedQuote?.quote?.consigneeAddress1 || '',
            city: selectedQuote?.quote?.consigneeCity || '',
            state: selectedQuote?.quote?.consigneeState || '',
            country: selectedQuote?.quote?.consigneeCountry || '',
            postal: selectedQuote?.quote?.consigneePostal || '',
            latitude: selectedQuote?.quote?.consigneeLatitude || '',
            longitude: selectedQuote?.quote?.consigneeLongitude || '',
            stateCode: selectedQuote?.quote?.consigneeStateCode || '',
            countryCode: selectedQuote?.quote?.consigneeCountryCode || '',
          },
        },
      }));
    }
  }, [selectedQuote, id]);

  useEffect(() => {
    if (id) {
      getOrderData();
    }

    getShipper();
    getCustomerList();
    getListService();
    getVehicleTypeOptions();
    getHandlingUnits();

    if (customerId) {
      setValue('orderCustomerId', customerId);
    }

    if (orderQuoteId) {
      setValue('quoteId', orderQuoteId);
    }
  }, []);

  const onSubmit = (data: any) => {
    const currentData = { ...currentLegData, ...data };

    const param: any = {
      activeCarrierId: currentData?.activeCarrierId,
      quoteId: currentData?.quoteId,
      shipperCompanyName: currentData?.shipper?.companyName,
      shipperContactName: currentData?.shipper?.contactName,
      shipperContactNumber: currentData?.shipper?.phoneNumber,
      orderCustomerId: currentData?.orderCustomerId,
      shipperEmail: currentData?.shipper?.email,
      id: currentData?.id,
      shipperId: currentData?.shipper?.id,
      shipperContactId: currentData?.shipper?.contactId,
      shipperFullAddress: currentData?.shipper?.shipperAddress?.fullAddress,
      shipperAddress1: currentData?.shipper?.shipperAddress?.address1,
      shipperCity: currentData?.shipper?.shipperAddress?.city,
      shipperState: currentData?.shipper?.shipperAddress?.state,
      shipperCountry: currentData?.shipper?.shipperAddress?.country,
      shipperPostal: currentData?.shipper?.shipperAddress?.postal,
      shipperLatitude: currentData?.shipper?.shipperAddress?.latitude,
      shipperLongitude: currentData?.shipper?.shipperAddress?.longitude,
      shipperStateCode: currentData?.shipper?.shipperAddress?.stateCode,
      shipperCountryCode: currentData?.shipper?.shipperAddress?.countryCode,
      consigneeId: currentData?.consignee?.id,
      consigneeContactId: currentData?.consignee?.contactId,
      consigneeCompanyName: currentData?.consignee?.companyName,
      consigneeContactName: currentData?.consignee?.contactName,
      consigneeContactNumber: currentData?.consignee?.phoneNumber,
      consigneeEmail: currentData?.consignee?.email,
      consigneeFullAddress:
        currentData?.consignee?.consigneeAddress?.fullAddress,
      consigneeAddress1: currentData?.consignee?.consigneeAddress?.address1,
      consigneeCity: currentData?.consignee?.consigneeAddress?.city,
      consigneeState: currentData?.consignee?.consigneeAddress?.state,
      consigneeCountry: currentData?.consignee?.consigneeAddress?.country,
      consigneePostal: currentData?.consignee?.consigneeAddress?.postal,
      consigneeLatitude: currentData?.consignee?.consigneeAddress?.latitude,
      consigneeLongitude: currentData?.consignee?.consigneeAddress?.longitude,
      consigneeStateCode: currentData?.consignee?.consigneeAddress?.stateCode,
      consigneeCountryCode:
        currentData?.consignee?.consigneeAddress?.countryCode,
      order_dimensions: currentData?.order_dimensions,
      declaredValue: currentData?.declaredValue,
      totalWeight: currentData?.totalWeight,
      declaredValueUnit: currentData?.declaredValueUnit || 'CAD',
      equipmentType: currentData?.equipmentType,
      equipmentTypeFullName: currentData?.equipmentTypeFullName,
      linearFootage: currentData?.linearFootage,
      loadInsurance: currentData?.loadInsurance,
      orderType: currentData?.orderType,
      poNumber: currentData?.poNumber,
      refNumber: currentData?.refNumber,
      specialNotes: currentData?.specialNotes,
      weightMeasurement: weightMeasurement,
      lengthMeasurement: lengthMeasurement,
      additionalServices: currentData?.additionalServices,
      carrierPrice: currentData?.carrierPrice,
      salesPrice: currentData?.salesPrice,
      masterOrderId: +id,
      expectedPickupTime: data?.expectedPickupTime
        ? moment(data?.expectedPickupTime).format('YYYY-MM-DD')
        : null,
      expectedEstimatedDeliveryTime: data?.expectedEstimatedDeliveryTime
        ? moment(data?.expectedEstimatedDeliveryTime).format('YYYY-MM-DD')
        : null,
      carrierPickupAppointmentDatetime: data?.carrierPickupAppointmentDatetime
        ? data?.carrierPickupAppointmentDatetime
        : null,
      carrierDeliveryAppointmentDatetime:
        data?.carrierDeliveryAppointmentDatetime
          ? data?.carrierDeliveryAppointmentDatetime
          : null,
      shipperAppointmentDatetime: data?.shipperAppointmentDatetime
        ? data?.shipperAppointmentDatetime
        : null,
      deliveryAppointmentDatetime: data?.deliveryAppointmentDatetime
        ? data?.deliveryAppointmentDatetime
        : null,
      carrierPickupAppointmentStatus: data?.carrierPickupAppointmentStatus
        ? data?.carrierPickupAppointmentStatus
        : 'Pending',
      carrierDeliveryAppointmentStatus: data?.carrierDeliveryAppointmentStatus
        ? data?.carrierDeliveryAppointmentStatus
        : 'Pending',
      shipperAppointmentStatus: data?.shipperAppointmentStatus
        ? data?.shipperAppointmentStatus
        : 'Pending',
      deliveryAppointmentStatus: data?.deliveryAppointmentStatus
        ? data?.deliveryAppointmentStatus
        : 'Pending',
    };

    // const legData: any = [];
    // let isAnyLegOverweight = false;
    // let isAnyLegOversized = false;

    // Object.keys(updatedTabValues).forEach((key) => {
    //   const leg = tabValues[key];
    //   let totalWeight: any = 0;
    //   let weightMeasurementValue = '';
    //   let isItemLengthOver = false;

    //   leg?.order_dimensions?.forEach((form: any) => {
    //     totalWeight += form.totalWeight;
    //     weightMeasurementValue = form.weightMeasurement;

    //     const maxLengthOver =
    //       lengthMeasurement === MEASUREMENT.LENGTH1.value
    //         ? LBS.ITEM_LENGTH_OVER
    //         : KGS.ITEM_LENGTH_OVER;

    //     if (form.length >= maxLengthOver) {
    //       isItemLengthOver = true;
    //     }
    //   });

    //   const isTotalWeightOverBool =
    //     weightMeasurementValue === MEASUREMENT.WEIGHT1.value
    //       ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
    //       : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;

    //   if (isTotalWeightOverBool && !isAnyLegOverweight) {
    //     isAnyLegOverweight = true;
    //   }

    //   if (isItemLengthOver && !isAnyLegOversized) {
    //     isAnyLegOversized = true;
    //   }

    //   // if (key !== masterOrderKey) {
    //   legData.push({
    //     orderId: key,
    //     ...leg,
    //   });
    //   // }
    // });

    // const finalData = {
    //   legs: updatedTabValues,
    // };

    // let totalWeight: number | string = 0;
    // let weightMeasurementValue = '';
    // let isItemLengthOver = false;

    // data?.order_dimensions.forEach((form: any) => {
    //   totalWeight += form.totalWeight;
    //   weightMeasurementValue = form.weightMeasurement;
    // });

    // data?.order_dimensions.forEach((form: any) => {
    //   const maxLengthOver =
    //     lengthMeasurement === MEASUREMENT.LENGTH1.value
    //       ? LBS.ITEM_LENGTH_OVER
    //       : KGS.ITEM_LENGTH_OVER;

    //   if (form.length >= maxLengthOver) {
    //     isItemLengthOver = true;
    //   }
    // });

    // totalWeight = totalWeight.toFixed();

    // const isTotalWeightOverBool =
    //   weightMeasurementValue === MEASUREMENT.WEIGHT1.value
    //     ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
    //     : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;

    // if (
    //   !isAllowOverWeigth &&
    //   isTailgate.length &&
    //   (isAnyLegOverweight || isAnyLegOversized)
    // ) {
    //   handleServiceActionType('tailgate');

    //   return;
    // }

    setIsSubmitLoading(true);

    if (id) {
      updateOrder(param)
        .then((response) => {
          if (response) {
            if (order?.orderStatus === 'draft') {
              localStorage.setItem(
                TABLE_IDS.ORDER_LIST,
                JSON.stringify({
                  orderType: 'new-order',
                  timeFilter: timeFilter,
                })
              );

              navigate(`${ROUTES.ORDERS}`);
            } else {
              navigate(-1);
            }
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsSubmitLoading(false);
        });
    } else {
      createOrder(param)
        .then((response) => {
          if (response) {
            navigate(`${PATH.ORDER_VIEW}/${response.data.id}`, {
              state: carrierPrice?.length > 0 ? { isNewOrder: true } : {},
            });
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setOrderDimensionAction(orderDimensionInitAction);

      if (status) {
        setOrderDimensionAction(orderDimensionInitAction);
        setIsAllowOverWeigth(true);
      }
    },
    [orderDimensionAction]
  );

  useEffect(() => {
    if (isAllowOverWeigth) {
      handleSubmit(onSubmit)();
    }
  }, [isAllowOverWeigth]);

  const handleSaveAsDraft = async () => {
    const formData = getValues();
    const fieldsToValidate: string[] = [
      'shipper.companyName',
      'consignee.companyName',
    ];

    clearErrors();

    const fieldsToCheck = [
      { path: 'shipper.email', value: formData.shipper?.email },
      { path: 'shipper.phoneNumber', value: formData.shipper?.phoneNumber },
      { path: 'consignee.email', value: formData.consignee?.email },
      { path: 'consignee.phoneNumber', value: formData.consignee?.phoneNumber },
    ];

    fieldsToCheck.forEach((field) => {
      if (field.value?.trim()) {
        fieldsToValidate.push(field.path);
      }
    });

    if (shipperAddress) {
      fieldsToValidate.push('shipper.shipperAddress.fullAddress');
    }

    if (consigneeAddress) {
      fieldsToValidate.push('consignee.consigneeAddress.fullAddress');
    }

    const validationResults = await trigger(fieldsToValidate);

    if (!validationResults) {
      return;
    }

    setIsShowSaveAsDraftModal(true);
  };

  const handleSubmitSaveAsDraft = () => {
    const formData = getValues();

    const draftParam = constructOrderParam(
      formData,
      currentLegData?.id,
      weightMeasurement,
      lengthMeasurement
    );

    setIsSaveAsDraftLoading(true);

    saveOrderAsDraft({ ...draftParam, masterOrderId: id })
      .then((response) => {
        if (response) {
          localStorage.setItem(
            TABLE_IDS.ORDER_LIST,
            JSON.stringify('new-order')
          );

          navigate(`${ROUTES.ORDERS}`);
          setIsShowSaveAsDraftModal(false);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsSaveAsDraftLoading(false);
      });
  };

  const handleCurrentStep = async (stepType: string) => {
    if (stepType !== 'back') {
      const isValid = await trigger();

      if (!isValid) {
        return;
      }

      if (currentStep === 1) {
        if (getValues('quoteId')) {
          if (!getValues('activeCarrierId')) {
            setIsValidAddress((old: any) => ({
              ...old,
              isActiveCarrierSelected: false,
            }));

            return;
          }
        }
      }

      if (currentStep === 2) {
        if (!isValidAddress?.shipperAddress) {
          return;
        }
      } else if (currentStep === 3) {
        if (!isValidAddress?.consigneeAddress) {
          return;
        }
      }
    }

    if (stepType === 'back') {
      if (currentStep === 1) {
        setIsValidAddress((old) => ({
          ...old,
          isActiveCarrierSelected: true,
        }));
      }

      if (currentStep === 2) {
        setIsValidAddress((old) => ({
          ...old,
          shipperAddress: true,
        }));
      }

      if (currentStep === 3) {
        setIsValidAddress((old) => ({
          ...old,
          consigneeAddress: true,
        }));
      }
    }

    const steps = [
      {
        title: 'Add Order Metadata',
        description: 'Please provide the metadata for your order.',
      },
      {
        title: 'Add Shipper',
        description: 'Please provide the shipper details for your order.',
      },
      {
        title: 'Add Consignee',
        description: 'Please provide the consignee details for your order.',
      },
      {
        title: 'Add Pickup and Delivery Dates',
        description:
          'Pick dates for when the pickup and delivery should occur.',
      },
      {
        title: 'Add Order Details',
        description: 'Please add the details of your order before proceeding.',
      },
      {
        title: 'Add Carrier & Sale Price',
        description:
          'Select the carrier and set the sale price for your order.',
      },
      {
        title: 'Review Order',
        description: 'Review every details of your order.',
      },
    ];

    let newCurrentStepCount =
      stepType === 'back' ? currentStep - 1 : currentStep + 1;

    if (newCurrentStepCount === 8) {
      handleSubmit(onSubmit)();
    } else {
      setCurrentStep(newCurrentStepCount);

      if (newCurrentStepCount > 0 && newCurrentStepCount <= steps.length) {
        const { title, description } = steps[newCurrentStepCount - 1];
        setStepperTitle(title);
        setStepperDescription(description);
      }
    }
  };

  return (
    <OrderContext.Provider
      value={{
        isTailgate,
        setIsTailgate,
        orderDimensionAction,
        setOrderDimensionAction,
        order,
        setOrder,
        setWeightMeasurement,
        setLengthMeasurement,
        lengthMeasurement,
        weightMeasurement,
        customerList,
        isCustomerLoading,
        isValidAddress,
        setIsValidAddress,
        shippers,
        consignees,
        shipperOptions,
        consigneeOptions,
        currentLegData,
        setCurrentLegData,
        serviceList,
        setServiceList,
        salesPrice,
        setSalesPrice,
        carrierPrice,
        setCarrierPrice,
        shipperFullAddress,
        setShipperFullAddress,
        consigneeFullAddress,
        setConsigneeFullAddress,
        isConsigneeBtnDisable,
        setIsConsigneeBtnDisable,
        isShipperBtnDisable,
        setIsShipperBtnDisable,
        isShipperContactBtnDisable,
        setIsShipperContactBtnDisable,
        isConsigneeContactBtnDisable,
        setIsConsigneeContactBtnDisable,
        getShipper,
        equipmentTypeOptions,
        isEquipmentTypeLoading,
        shipperContact,
        setShipperContact,
        consigneeContact,
        setConsigneeContact,
        shipperContactOptions,
        setShipperContactOptions,
        consigneeContactOptions,
        setConsigneeContactOptions,
        setIsAppointmentInEditMode,
        quoteOptions,
        selectedQuote,
        setSelectedQuote,
        activeCarrier,
        setActiveCarrier,
        handlingUnitOptions,
        isHandlingUnitLoading,
        isShipperPhoneNumberValue,
        setIsShipperPhoneNumberValue,
        isConsigneePhoneNumberValue,
        setIsConsigneePhoneNumberValue,
        shipperAdditionalServiceIds,
        setShipperAdditionalServiceIds,
        consigneeAdditionalServiceIds,
        setConsigneeAdditionalServiceIds,
        // setTabValues,
        // // tabValues,
        // activeLeg,
      }}
    >
      <PageSectionLayout
        header={
          <OrderHeader
            id={id}
            isLoading={isLoading}
            order={order}
            navigate={navigate}
            isDiscardButton={true}
            isShowActionButton={false}
            setIsShowChatSideBar={setIsShowChatSideBar}
            currentLegData={currentLegData}
          />
        }
        contentClassName="h-[calc(100%_-_88px)] overflow-x-hidden !pb-0 !pt-0 relative"
      >
        <FormProvider {...methods}>
          <div className="flex h-full w-full">
            <div className="flex-1 xl:pr-8 py-5 flex flex-col gap-y-4">
              <div className="flex items-center flex-wrap gap-x-2">
                <div className="flex-1">
                  <h6 className="text-grayLight900 text-base font-semibold">
                    {stepperTitle}
                  </h6>
                  <p className="text-grayLight600 text-sm font-medium">
                    {stepperDescription}
                  </p>
                </div>
                <div className="flex flex-wrap gap-2">
                  {order?.orderStatus === 'draft' && (
                    <ButtonCmp
                      className="btn-primary-light"
                      onClick={handleSaveAsDraft}
                      disabled={isSubmitLoading || isSaveAsDraftLoading}
                    >
                      Save As Draft
                    </ButtonCmp>
                  )}
                  <ButtonCmp
                    className="btn_secondary_black min-w-[80px]"
                    onClick={() => handleCurrentStep('back')}
                    disabled={currentStep === 1 || isSubmitLoading}
                  >
                    Back
                  </ButtonCmp>
                  <ButtonCmp
                    className="btn-outline-primary min-w-[80px]"
                    onClick={() => handleCurrentStep('next')}
                    loading={isSubmitLoading}
                    disabled={isSubmitLoading}
                  >
                    {currentStep === 7
                      ? order?.orderStatus === 'draft'
                        ? 'Create Order'
                        : 'Save Order'
                      : 'Next'}
                  </ButtonCmp>
                </div>
              </div>
              <StepBar
                steps={STEPS}
                activeStep={currentStep}
                variant="progressOnly"
              ></StepBar>

              <div className="gap-y-4 overflow-y-auto scrollbar-hide flex flex-col flex-1">
                {currentStep === 1 && <Step1 />}

                {(currentStep === 2 || currentStep === 3) && (
                  <Step2AndStep3
                    action={action}
                    setAction={setAction}
                    shipperAddress={shipperAddress}
                    setShipperAddress={setShipperAddress}
                    consigneeAddress={consigneeAddress}
                    setConsigneeAddress={setConsigneeAddress}
                    currentStep={currentStep}
                  />
                )}

                {currentStep === 4 && <Step3 />}

                {currentStep === 5 && <Step4 />}

                {currentStep === 6 && <Step5 />}

                {currentStep === 7 && <Step6 />}
              </div>
            </div>

            <RadarAndInternalChat
              order={order}
              shipperFullAddress={shipperFullAddress}
              consigneeFullAddress={consigneeFullAddress}
              isOrderView={false}
              isShowChatSideBar={isShowChatSideBar}
              setIsShowChatSideBar={setIsShowChatSideBar}
            />
          </div>
        </FormProvider>

        {orderDimensionAction.tailgate && (
          <ConfirmModal
            title="Tailgate Over Dimensions"
            description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
              weightMeasurement === MEASUREMENT.WEIGHT1.value
                ? `${LBS.MAX_WEIGHT}lbs`
                : `${KGS.MAX_WEIGHT}kgs`
            }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
            button1="Yes, I am sure"
            button2="Modify Selection"
            handleClose={handleModalClose}
          />
        )}

        {isShowSaveAsDraftModal && (
          <ConfirmationModalCmp
            title="Save As Draft"
            description="Are you sure you want to save this order as draft?"
            Icon={<Save01 className="w-7 h-7" />}
            handleClose={() => setIsShowSaveAsDraftModal(false)}
            isSubmitting={isSaveAsDraftLoading}
            handleSubmit={handleSubmitSaveAsDraft}
          />
        )}
      </PageSectionLayout>
    </OrderContext.Provider>
  );
};

export default OrderDetails;
