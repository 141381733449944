import { ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import BadgeCmp from 'src/components/BadgeCmp';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import { TABLE_IDS } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import { getRadarOrderList } from 'src/services/RadarService';
import {
  currentDateToDifference,
  formatAddress,
  formatLastUpdate,
  GetAppearance,
  getDateWithSuffixFormat,
  getFormattedDateWithTime,
  isValidJSON,
  lastLocationUpdateToShowDotColor,
} from 'src/utils/CommonFunctions';

import LeftSidebar from './LeftSidebar';
import LeftSidebarNewDesign from './LeftSidebarNewDesign';

const initParams = {
  search: '',
  sortType: 'asc',
  sortField: 'createdAt',
  filter: 'booked',
};

const Radar = () => {
  const navigate = useNavigate();
  const storedParamsString = localStorage.getItem(TABLE_IDS.RADAR_LIST);
  const storedParams = isValidJSON(storedParamsString)
    ? JSON.parse(storedParamsString!)
    : undefined;

  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const sortToMapReload = useRef<boolean>(true);
  const [orders, setOrders] = useState([]);
  const [isOrdersLoading, setIsOrdersLoading] = useState(true);
  const [params, setParams] = useState(
    storedParams && Object.keys(storedParams).length > 0
      ? storedParams
      : initParams
  );
  const [tabCount, setTabCount] = useState<any>(null);
  const [orderMarkersPin, SetOrderMarkersPin] = useState<any>([]);
  const abortControllerRef = useRef<AbortController | null>(null);
  const isAppearance = GetAppearance();
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-93.7, 50],
        zoom: 3,
        pitch: 0,
        bearing: 0,
        boxZoom: true,
        antialias: true,
        projection: 'mercator',
        dragRotate: false,
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, []);

  const geocodeAddress = async (
    address: string
  ): Promise<[number, number, string]> => {
    const query = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxgl.accessToken}`
    );
    const json = await query.json();

    if (!json.features || json.features.length === 0) {
      throw new Error(`Unable to geocode address: ${address}`);
    }

    const coordinates = json.features[0].center as [number, number];
    const placeId = json.features[0].id as string;

    return [coordinates[0], coordinates[1], placeId];
  };

  const removeAllMarkersFromDOM = () => {
    const markerElements = document.querySelectorAll('.mapboxgl-marker');
    markerElements.forEach((marker) => marker.remove());
    const popUpElements = document.querySelectorAll('.mapboxgl-popup');
    popUpElements.forEach((popUp) => popUp.remove());
  };

  const removeAllMarkers = () => {
    if (sortToMapReload.current) {
      if (mapRef.current?.getLayer('route-line')) {
        mapRef.current?.removeLayer('route-line');
      }

      if (mapRef.current?.getSource('route')) {
        mapRef.current?.removeSource('route');
      }

      for (let i = 0; i < orderMarkersPin.length; i++) {
        const marker = orderMarkersPin[i];
        marker.remove();
      }
      removeAllMarkersFromDOM();
      SetOrderMarkersPin([]);
    }
  };

  const getDotColor = (value: any) => {
    const isDotColor =
      params?.filter === 'booked' || params?.filter === 'dispatched'
        ? 'primary'
        : params?.filter === 'delivered'
        ? 'green'
        : (lastLocationUpdateToShowDotColor(
            value?.orderLocationUpdate
              ? value?.orderLocationUpdate?.updatedAt
              : value?.createdAt
          ) as 'primary' | 'red' | 'yellow' | 'green');

    return isDotColor;
  };

  const getDotLabel = (value: any) => {
    const isDotLabel = (
      <>
        <span>
          {params?.filter === 'booked' || params?.filter === 'dispatched'
            ? 'Picking up on'
            : params?.filter === 'delivered'
            ? 'Delivered'
            : 'Last Updated:'}
        </span>

        {params.filter === 'booked' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.firstLegExpectedPickupTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegShipperAppointmentDatetime
                        ? value.activeLegShipperAppointmentDatetime
                        : value?.firstLegExpectedPickupTime,
                      value?.activeLegShipperAppointmentDatetime
                        ? `MMMM Do [at] hh:mm A`
                        : 'MMMM Do',
                      value?.activeLegShipperAppointmentDatetime ? true : false,
                      true,
                      isAppearance
                    )
                  )
                : '-',
            }}
          />
        )}
        {params.filter === 'dispatched' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.activeLegActualPickupTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegActualPickupTime,
                      `MMMM Do [at] hh:mm A`,
                      true,
                      true,
                      isAppearance
                    )
                  )
                : '-',
            }}
          />
        )}

        {params.filter === 'in-transit' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.orderLocationUpdate
                ? currentDateToDifference(
                    value?.lastLegExpectedEstimatedDeliveryTime,
                    'hours',
                    false
                  ) < 0
                  ? formatLastUpdate(value?.orderLocationUpdate?.updatedAt)
                  : currentDateToDifference(
                      value?.lastLegExpectedEstimatedDeliveryTime,
                      'hours',
                      false
                    ) < 12
                  ? getDateWithSuffixFormat(
                      getFormattedDateWithTime(
                        value?.lastLegExpectedEstimatedDeliveryTime,
                        `MMMM Do [at] hh:mm A`,
                        false,
                        true,
                        isAppearance
                      )
                    )
                  : formatLastUpdate(value?.orderLocationUpdate?.updatedAt)
                : value?.activeLegActualPickupTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegActualPickupTime,
                      `MMMM Do [at] hh:mm A`,
                      true,
                      true,
                      isAppearance
                    )
                  )
                : '-',
            }}
          />
        )}
        {params.filter === 'delivered' && (
          <span
            className="ml-1"
            dangerouslySetInnerHTML={{
              __html: value?.activeLegActualEstimatedDeliveryTime
                ? getDateWithSuffixFormat(
                    getFormattedDateWithTime(
                      value?.activeLegActualEstimatedDeliveryTime,
                      `MMMM Do [at] hh:mm A`,
                      true,
                      true,
                      isAppearance
                    )
                  )
                : '-',
            }}
          />
        )}
      </>
    );

    return isDotLabel;
  };

  const locationPopUp = (orderObjItems: any, isHovered: boolean = false) => {
    const popupContainer = document.createElement('div');

    // Create the ReactPopupContent component
    const ReactPopupContent = () => {
      if (isHovered) {
        return (
          <div
            className="rounded-lg border border-gray800 bg-primarySolid p-3 cursor-pointer"
            onClick={() => {
              if (orderObjItems?.orderTableId) {
                navigate(`${PATH.RADAR}/${orderObjItems?.orderTableId}`);
              }
            }}
          >
            <div className="flex gap-x-2 justify-between items-start">
              {/* <a
                href={`https://wal.roserocket.com/#/ops/orders/${orderObjItems?.orderId}`}
                className={`text-white text-sm font-medium underline flex-none ${
                  isOrdersLoading ? 'custom-loading' : ''
                }`}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
              >
                #{orderObjItems?.orderId}
              </a> */}
              <p
                className="text-white font-normal leading-tight truncate underline cursor-pointer"
                onClick={(e: any) => {
                  if (orderObjItems?.orderTableId) {
                    e.stopPropagation();
                    navigate(
                      `${PATH.ORDER_VIEW}/${orderObjItems?.orderTableId}`
                    );
                  }
                }}
              >
                {orderObjItems?.orderId ? `#${orderObjItems?.orderId}` : ''}
              </p>
              <div className="flex gap-x-2.5 gap-y-1 justify-end flex-wrap">
                {orderObjItems?.orderType && (
                  <BadgeCmp
                    style="modern"
                    type="success"
                    badgeTextColor="!text-utilityGray600"
                    mainClassName="!border-utilityBlack900"
                  >
                    {orderObjItems?.orderType?.toUpperCase()}
                  </BadgeCmp>
                )}
                {orderObjItems?.orderStatus && (
                  <BadgeCmp
                    style="modern"
                    type="primary"
                    badgeTextColor="!text-utilityGray600"
                    mainClassName="!border-utilityBlack900"
                  >
                    {orderObjItems?.orderStatus}
                  </BadgeCmp>
                )}
              </div>
            </div>

            <StatusDotProfileCmp
              parentClassName="mt-1.5 "
              statusLabelClassName="!text-white"
              dotColor={getDotColor(orderObjItems)}
              label={getDotLabel(orderObjItems)}
            />

            <ul className="mt-3.5 p-2.5 rounded-lg bg-white bg-opacity-10">
              <li
                key="0"
                className="relative flex items-start gap-x-2 pb-5 last:pb-0 overflow-hidden [&:last-child>div:after]:hidden "
              >
                <div className="mt-1 after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-[13px] after:absolute flex items-center justify-center after:bg-[#85888E]">
                  <div className="w-2.5 h-2.5 border-2 border-[#85888E] rounded-full"></div>
                </div>
                <div className="flex-1 text-xs text-utilityGray700 font-normal truncate">
                  <div className="text-white800 font-medium truncate">
                    {orderObjItems?.shipperCompanyName ?? ''}
                  </div>

                  <div className="truncate">
                    {orderObjItems?.shipperCity && orderObjItems?.shipperPostal
                      ? formatAddress(
                          `${orderObjItems?.shipperCity}, ${orderObjItems?.shipperPostal}`
                        )
                      : ''}
                  </div>
                </div>
              </li>
              <li
                key="1"
                className="relative flex items-start gap-x-2 pb-2 last:pb-0 mb-1 last:mb-0 [&:last-child>div:after]:hidden"
              >
                <div>
                  <ChevronDown className="w-[18px] text-[#85888E] -mt-[13px] -ml-1" />
                </div>
                <div className="flex-1 text-xs text-utilityGray700 font-normal -mt-2.5 -ml-1 truncate">
                  <div className="text-white800 font-medium truncate">
                    {orderObjItems?.consigneeCompanyName ?? ''}
                  </div>

                  <div className="truncate">
                    {orderObjItems?.consigneeFullAddress
                      ? formatAddress(orderObjItems?.consigneeFullAddress)
                      : ''}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        );
      } else {
        return (
          <div className="relative mapbox-tooltip-dark">
            {orderObjItems?.orderId ? `#${orderObjItems?.orderId}` : ''}
          </div>
        );
      }
    };

    // Create a root for the React component and render it
    const root = createRoot(popupContainer);
    root.render(<ReactPopupContent />);

    return popupContainer;
  };

  const setOrderMarker = async () => {
    if (sortToMapReload.current) {
      removeAllMarkers();

      let orderMarkers: mapboxgl.Marker[] = [];

      for (let i = 0; i < orders.length; i++) {
        const orderObj: any = orders[i];
        const cityCoords = await geocodeAddress(orderObj.shipperFullAddress);

        if (!cityCoords || cityCoords.length < 2) {
          console.warn(`Could not find coordinates for address`);
          continue;
        }

        // Create marker container
        const parentDiv = document.createElement('div');
        parentDiv.className = 'parent-marker';
        parentDiv.id = orderObj.id || '';

        // Create marker element
        const el = document.createElement('div');
        el.className = 'marker';
        el.id = orderObj.id || '';

        // Create customer image container
        const customerImageDiv = document.createElement('div');
        customerImageDiv.className = 'parcel-arrow hover-parcel-arrow';
        customerImageDiv.id = orderObj.orderId;
        customerImageDiv.innerHTML = `
        <div class="w-[12px] -mt-0.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" class="w-full">
            <path d="M12.6657 2.67916C12.2439 2.4686 11.7476 2.4686 11.3258 2.67916C10.9525 2.86552 10.7595 3.19367 10.6676 3.36083C10.5675 3.543 10.4649 3.77692 10.362 4.01162L3.05073 20.6813C2.93566 20.9436 2.82421 21.1976 2.7529 21.4084C2.69129 21.5905 2.56638 21.9837 2.70011 22.4066C2.84758 22.873 3.21319 23.2382 3.67975 23.3851C4.10282 23.5184 4.49584 23.393 4.67791 23.3312C4.88863 23.2596 5.14253 23.1478 5.4047 23.0324L11.9957 20.1324L18.5868 23.0325C18.8489 23.1478 19.1028 23.2596 19.3135 23.3312C19.4956 23.393 19.8886 23.5184 20.3117 23.3851C20.7783 23.2382 21.1439 22.873 21.2913 22.4066C21.4251 21.9837 21.3002 21.5905 21.2385 21.4084C21.1672 21.1976 21.0558 20.9436 20.9407 20.6813L13.6295 4.01167C13.5266 3.77695 13.424 3.54301 13.3238 3.36083C13.2319 3.19367 13.039 2.86552 12.6657 2.67916Z" fill="white"/>
          </svg>
        </div>`;

        parentDiv.appendChild(customerImageDiv);

        // Create and configure the Mapbox marker
        const marker = new mapboxgl.Marker(parentDiv)
          .setLngLat([cityCoords[0], cityCoords[1]])
          .addTo(mapRef.current!);

        // Create the popup (default content)
        const popup: any = new mapboxgl.Popup({
          offset: 13,
          closeButton: false,
          closeOnClick: false,
          maxWidth: '320px',
        });

        popup.setDOMContent(locationPopUp(orderObj)); // Default popup content
        marker.setPopup(popup);
        popup.addTo(mapRef.current!); // Ensure the popup is always visible

        // Track hover state
        let isHovered = false;
        let closeTimeout: any;

        const popupElement = popup.getElement() as any;

        // Function to show popup with hover content
        const showPopup = () => {
          clearTimeout(closeTimeout); // Prevent accidental closing
          isHovered = true;
          popup.setDOMContent(locationPopUp(orderObj, true));
          popup.getElement().style.zIndex = '1';
        };

        // Function to reset the popup content (not remove it)
        const hidePopup = () => {
          closeTimeout = setTimeout(() => {
            if (!isHovered) {
              popup.setDOMContent(locationPopUp(orderObj, false));
              popup.getElement().style.zIndex = '0';
            }
          }, 200); // Small delay to prevent flickering
        };

        // Mouse enters marker - show popup
        marker.getElement().addEventListener('mouseenter', () => {
          showPopup();
        });

        // Mouse enters the popup itself - keep it open
        popupElement.addEventListener('mouseenter', () => {
          isHovered = true;
          clearTimeout(closeTimeout);
        });

        // Mouse leaves marker - check if it's on the popup
        marker.getElement().addEventListener('mouseleave', () => {
          isHovered = false;
          hidePopup();
        });

        // Mouse leaves popup - close if not on marker
        popupElement.addEventListener('mouseleave', () => {
          isHovered = false;
          hidePopup();
        });

        // Add click event listener to navigate
        marker.getElement().addEventListener('click', () => {
          if (orderObj.orderTableId) {
            navigate(`${PATH.RADAR}/${orderObj.orderTableId}`);
          }
        });

        orderMarkers.push(marker);

        // Center the map and adjust zoom after placing the last marker
        if (i === orders.length - 1) {
          mapRef?.current?.setCenter([-93.7, 50]);
          mapRef?.current?.setZoom(3);
        }
      }

      if (orders?.length === 0) {
        mapRef?.current?.setCenter([-93.7, 50]);
        mapRef?.current?.setZoom(3);
      }

      SetOrderMarkersPin(orderMarkers);
    }
  };

  // Function to calculate the angle between two points
  // const calculateAngle = (
  //   pointALat: number,
  //   pointALng: number,
  //   pointBLat: number,
  //   pointBLng: number
  // ) => {
  //   const angle =
  //     (Math.atan2(pointBLat - pointALat, pointBLng - pointALng) * 180) /
  //     Math.PI;
  //   console.log('angle', angle);

  //   return angle;
  // };

  useEffect(() => {
    setOrderMarker();
  }, [orders]);

  const getOrderList = () => {
    setIsOrdersLoading(true);
    setOrders([]);
    localStorage.setItem(TABLE_IDS.RADAR_LIST, JSON.stringify(params));
    removeAllMarkers();

    // Cancel the previous request if it exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new abort controller for the current request
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;
    let isTempParams;

    if (params?.filter === 'in-transit') {
      isTempParams = {
        activeFilter: params?.activeFilter,
        endDate: params?.endDate,
        filter: params?.filter,
        search: params?.search,
        sortField: params?.sortField,
        sortType: params?.sortType,
        startDate: params?.startDate,
        isAllTime: true,
      };
    } else {
      isTempParams = params;
    }

    getRadarOrderList({ ...isTempParams, ...{ isPagination: true } }, signal)
      .then((response: any) => {
        setOrders(response.data);
        setTabCount(response?.count);
        setIsOrdersLoading(false);
      })
      .catch((e) => {
        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setIsOrdersLoading(false);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getOrderList();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [params]);

  useEffect(() => {
    initializeMap();
  }, []);

  return (
    <PageSectionLayout
      header={
        <Header
          title={<div className="flex items-center gap-x-2.5">Radar</div>}
          desc="Effortlessly monitor your orders from pickup to delivery."
          rightSideContent={''}
          mainContainerClassName="!border-0"
        />
      }
      contentClassName="h-[calc(100vh_-_97px)] !p-0 border-t border-borderPrimary"
    >
      <div className="w-full flex h-full">
        <div className="w-[715px] flex flex-col gap-y-4 [&>div]:pr-7 [&>div]:pl-5 px-px pt-4">
          {/* RADAR NEW DESIGN LeftSidebarNewDesign */}
          <div className="hidden">
            <LeftSidebarNewDesign
              orders={orders}
              isOrdersLoading={isOrdersLoading}
              setParams={setParams}
              params={params}
              tabCount={tabCount}
              sortToMapReload={sortToMapReload}
            />
          </div>

          <LeftSidebar
            orders={orders}
            isOrdersLoading={isOrdersLoading}
            setParams={setParams}
            params={params}
            tabCount={tabCount}
            sortToMapReload={sortToMapReload}
          />
        </div>

        <div className="h-full flex-1 radar-mapbox relative border-borderPrimary border-l">
          <div
            id="map"
            ref={mapContainerRef}
            style={{ width: '100%', height: '100%' }}
          ></div>
        </div>
        {/* <div className="absolute bottom-5 right-5 max-w-64 rounded-[10px] border border-borderPrimary bg-white shadow-lgc p-4 flex flex-col gap-y-1.5">
          <StatusDotProfileCmp
          label="Latest Location Update"
          dotColor="green"
          />
          <p className="text-textSecondary text-xs font-medium">
          8001 S Orange Blossom Trl, Orlando, FL 32809, USA
          </p>
          <p className="text-gray500 text-xs font-normal text-right">
          Today, 2:30 PM
          </p>
          </div> */}
      </div>
    </PageSectionLayout>
  );
};
export default Radar;
