import {
  PieChart03,
  PresentationChart01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import { getQuoteAnalyticsSplitData } from 'src/services/QuoteService';

import QuoteRequestsGraph from './QuoteRequestsGraph';

interface IProps {
  handleClose: () => void;
  analyticsData: {
    requestQuotePieChartData: any;
    totalQuotePieChartData: any;
  };
  activeSplit: string;
  startDate: any;
  endDate: any;
  selectedUser: string | null;
  selectedTeam: string | null;
}

const titleObj = {
  totalQuoteRequests: 'Total Quote Requests',
  totalQuotes: 'Total Quotes',
  totalOrders: 'Total Orders',
  avgTimeToClassify: 'Avg. Time to Classify',
  avgTimeToQuote: 'Avg. Time to Quote',
  winLoss: 'Win/Loss',
};

const QuotingAnalyticsChartModal = ({
  handleClose,
  analyticsData,
  activeSplit,
  startDate,
  endDate,
  selectedUser,
  selectedTeam,
}: IProps) => {
  console.log('⭐️ ~ activeSplit:', activeSplit);
  console.log('⭐️ ~ analyticsData:', analyticsData);
  const [isLoading, setIsLoading] = useState(true);
  const [winLossData, setWinLossData] = useState([]);
  const [chartData, setChartData] = useState({
    pieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
  });
  console.log('⭐️ ~ chartData:', chartData);

  useEffect(() => {
    console.log('from chart modal');

    const param = {
      startDate: moment(startDate).format('DD/MM/YYYY'),
      endDate: moment(endDate).format('DD/MM/YYYY'),
      field: activeSplit,
      userId: selectedUser === 'all' ? null : selectedUser,
      teamId: selectedTeam === 'all' ? null : selectedTeam,
    };

    getQuoteAnalyticsSplitData(param)
      .then((response) => {
        console.log('response: ', response.data);

        if (activeSplit === 'winLoss') {
          console.log('this is winLoss data');
          setWinLossData(response.data);
        } else {
          setChartData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error: ', error);
      });
  }, []);

  const headerIcon =
    activeSplit === 'winLoss' ? <PresentationChart01 /> : <PieChart03 />;

  return (
    <CommonModal
      headerIcon={headerIcon}
      title={titleObj[activeSplit as keyof typeof titleObj] || ''}
      titleDesc={`Overview of ${
        titleObj[activeSplit as keyof typeof titleObj]
      } activity.`}
      handleClose={handleClose}
      size={'max-w-[655px]'}
    >
      <div
        className={`min-h-[356px] flex flex-col ${
          activeSplit === 'winLoss' ? 'p-5' : 'pl-5 pt-[38px] pb-[35px] pr-9 '
        }`}
      >
        {!isLoading || activeSplit === 'winLoss' ? (
          <QuoteRequestsGraph
            activeStatistics={activeSplit}
            data={activeSplit === 'winLoss' ? winLossData : chartData}
            isLoading={isLoading}
          />
        ) : (
          <>
            <div className="flex justify-between items-center">
              <div className="relative w-[358px] h-[282px] flex justify-center items-center">
                <div className="w-[261px] h-[261px] bg-gray-50 rounded-full custom-loading"></div>
                <div className="border-white border-[65px] rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"></div>
              </div>

              <ul className="w-[240px] p-4 rounded-xl border border-utilityGray200 shadow-md flex flex-col gap-2">
                <li className="flex items-center gap-x-[7px]">
                  <p className="w-[7px] h-[7px] rounded-full mr-[7px] flex-none custom-loading"></p>
                  <h6 className="text-sm flex-1 custom-loading">LTL</h6>
                  <p className="text-sm custom-loading">100</p>
                </li>
                <li className="flex items-center gap-x-[7px]">
                  <p className="w-[7px] h-[7px] rounded-full mr-[7px] flex-none custom-loading"></p>
                  <h6 className="text-sm flex-1 custom-loading">LTL</h6>
                  <p className="text-sm custom-loading">100</p>
                </li>
                <li className="flex items-center gap-x-[7px]">
                  <p className="w-[7px] h-[7px] rounded-full mr-[7px] flex-none custom-loading"></p>
                  <h6 className="text-sm flex-1 custom-loading">LTL</h6>
                  <p className="text-sm custom-loading">100</p>
                </li>
                <li className="flex items-center gap-x-[7px]">
                  <p className="w-[7px] h-[7px] rounded-full mr-[7px] flex-none custom-loading"></p>
                  <h6 className="text-sm flex-1 custom-loading">LTL</h6>
                  <p className="text-sm custom-loading">100</p>
                </li>
                <li className="flex items-center gap-x-[7px]">
                  <p className="w-[7px] h-[7px] rounded-full mr-[7px] flex-none custom-loading"></p>
                  <h6 className="text-sm flex-1 custom-loading">LTL</h6>
                  <p className="text-sm custom-loading">100</p>
                </li>
                <li className="flex items-center gap-x-[7px]">
                  <p className="w-[7px] h-[7px] rounded-full mr-[7px] flex-none custom-loading"></p>
                  <h6 className="text-sm flex-1 custom-loading">LTL</h6>
                  <p className="text-sm custom-loading">100</p>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default QuotingAnalyticsChartModal;
