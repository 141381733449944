import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const createRoute = async (data: any) => {
  const response = await axiosInterceptor.post(API.ROUTE.CREATE, data);

  return response;
};

export const getRoute = async (data: any) => {
  const response = await axiosInterceptor.get(API.ROUTE.GET, { params: data });

  return response;
};
