import moment from 'moment';
import { GetAppearance } from 'src/utils/CommonFunctions';

export const NoDataFound = (status: any, activeFilter: any) => {
  const type = ['in-transit', 'delivered'].includes(status)
    ? 'shipments'
    : 'orders';

  const labels: any = {
    last_7_days: `No ${type} have been ${status} in the last 7 days`,
    last_30_days: `No ${type} have been ${status} in the last 30 days`,
    last_90_days: `No ${type} have been ${status} in the last 90 days`,
    last_year: `No ${type} have been ${status} in the last year`,
    weekly: `No ${type} have been ${status} this week`,
    monthly: `No ${type} have been ${status} this month`,
    quarterly: `No ${type} have been ${status} this quarter`,
    yearly: `No ${type} have been ${status} this year`,
    all_time: `No ${type} have been ${status} till today`,
    Today: `No ${type} have been ${status} today`,
    Yesterday: `No ${type} were ${status} yesterday`,
    Tomorrow: `No ${type} will be ${status} tomorrow`,
  };

  return labels[activeFilter] || `No Orders found`;
};

export const formatTo1PM = (dateString: string) => {
  if (!dateString) return undefined;
  const appearance = GetAppearance();
  // Check if dateString already has a time component other than 00:00:00
  const date = moment(dateString);
  const hasTimeComponent =
    date.hours() !== 0 || date.minutes() !== 0 || date.seconds() !== 0;

  // Only set to 1 AM if no time component is present
  if (!hasTimeComponent) {
    const userTimezone = appearance?.timezone || 'UTC';
    const isTimeSet = moment(dateString).tz(userTimezone);
    isTimeSet.set({
      hour: 13,
      minute: 0,
      second: 0,
      millisecond: 0,
    });

    return isTimeSet.utc().format('YYYY-MM-DD HH:mm:ss');
  }

  return date.format('YYYY-MM-DD HH:mm:ss');
};

// Add this utility function to convert time from 24-hour to 12-hour format
export const convertTo12HourFormat = (timeStr: string) => {
  if (!timeStr) return '';

  const [hours, minutes] = timeStr.split(':').map(Number);
  const period = hours >= 12 ? 'PM' : 'AM';
  const hour12 = hours % 12 || 12; // Convert 0 to 12 for midnight

  return `${hour12}:${minutes.toString().padStart(2, '0')} ${period}`;
};

export const dateArr = [
  { label: 'Day Before', value: 'day_before' },
  { label: 'Day Off', value: 'day_off' },
];

export const timeArr = [
  { label: '1 Hour Before Appointment', value: '1_hour_before_appointment' },
  { label: '2 Hour Before Appointment', value: '2_hour_before_appointment' },
];

export const followUpArr = [
  { label: 'Every 4 hours', value: '4' },
  { label: 'Every 6 hours', value: '6' },
  { label: 'Every 8 hours', value: '8' },
  { label: 'Every 10 hours', value: '10' },
];
