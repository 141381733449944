import { ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import SelectWithActionTooltip from 'src/components/SelectWithActionTooltip';

interface IProps {
  loading: boolean;
  isLoadingMore: boolean;
  parentClassName?: string;
}

const QuoteRequestTypeBadgeSelectBox = ({
  loading,
  isLoadingMore,
  parentClassName = '',
}: IProps) => {
  const carrierNoteFilter = [
    { label: 'To-Quote', value: 'To-Quote' },
    { label: 'To-Specify', value: 'To-Specify' },
    { label: 'Quoted', value: 'Quoted' },
    { label: 'To-Counter', value: 'To-Counter' },
    { label: 'Booked', value: 'Booked' },
    { label: 'Lost', value: 'Lost' },
  ];

  return (
    <div className={`${parentClassName}`}>
      <SelectWithActionTooltip
        actionTooltipSelectWrap="min-w-[120px]"
        selectTooltipPosition="center"
        label={
          <div className="flex">
            <BadgeCmp
              type="success"
              style="modern"
              mainClassName={`flex-none ${
                loading && !isLoadingMore ? 'custom-loading' : ''
              } `}
            >
              <div className="flex gap-1 cursor-pointer items-center">
                To-Quote
                <ChevronDown className="w-3.5 h-3.5 text-gray400" />
              </div>
            </BadgeCmp>
          </div>
        }
        options={carrierNoteFilter}
      />
    </div>
  );
};

export default QuoteRequestTypeBadgeSelectBox;
