import React, { useEffect, useRef, useState } from 'react';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  capitalizeFirstLetterSpace,
  getShortName,
} from 'src/utils/CommonFunctions';

const TeamPerformanceRow = ({
  data,
  userList,
  setSelectedUser,
  selectedUser,
}: any) => {
  const textRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const isAllowClick = userList
    .map((item: any) => item.value)
    .includes(data.userId);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef?.current?.scrollWidth > textRef?.current?.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <>
      <td
        className={`px-5 py-4 text-grayLight900 max-w-44 w-44 min-w-44  ${
          isAllowClick && data.userId !== selectedUser ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (isAllowClick && data.userId !== selectedUser)
            setSelectedUser(data.userId);
        }}
      >
        <div className="flex items-center gap-2 ">
          {data.image && data.imageUrl ? (
            <img
              className="table-profile-icn-circle-xl"
              src={`${data.imageUrl}${data.image}`}
              alt=""
            />
          ) : (
            <div className="table-profile-user-circle-xl">
              {getShortName(`${data.firstName}${data.lastName}`)}
            </div>
          )}

          <TooltipCmp
            message={isOverflowing ? capitalizeFirstLetterSpace(data.name) : ''}
          >
            <div className="max-w-24 truncate" ref={textRef}>
              {capitalizeFirstLetterSpace(`${data.firstName} ${data.lastName}`)}
            </div>
          </TooltipCmp>
        </div>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.totalRequest}</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data.wonLoss}</span>
      </td>
    </>
  );
};

export default TeamPerformanceRow;
