import { Check } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import EmailReplyCmp from 'src/components/EmailReplyCmp';
// import { getShortName } from 'src/utils/CommonFunctions';

import CommonModal from '../../../../components/CommonModal';
// import AiInputText from 'src/components/AiInputText';

const SpotquoteConfirmation = ({
  handleClose,
  dimensions,
  services,
  selectedCarrierRate,
  addressDetails,
  loading,
}: any) => {
  const [doNotAskChecked] = useState<boolean>(false);
  const [serviceNames, setServiceNames] = useState<any[]>([]);

  useEffect(() => {
    if (services?.length > 0) {
      setServiceNames(services.map((service: any) => service.serviceName));
    }
  }, [services]);

  const defaultMessage =
    'We are looking to get a spot quote for the following load:';

  const emailBody = (
    <div className="flex flex-col gap-5 mt-5 ">
      <div>
        <h6 className="text-grayLight600 uppercase text-xs font-normal mb-2">
          SHIPPER DETAILS
        </h6>
        <div className="flex text-grayLight900 text-xs font-medium space-x-1.5 [&>*:first-child]:pl-0 [&>*:first-child]:border-l-0 [&>*]:pl-1.5 [&>*]:border-l [&>*]:border-grayLight900 mb-2 last:mb-0">
          <p>{addressDetails?.shipperAddress1} </p>
          <p>
            {addressDetails?.shipperCity}, {addressDetails?.shipperStateCode},{' '}
            {addressDetails?.shipperCountryCode}
          </p>
          <p>Postal Code: {addressDetails?.shipperPostal}</p>
        </div>
      </div>
      <div>
        <h6 className="text-grayLight600 uppercase text-xs font-normal mb-2">
          CONSIGNEE DETAILS
        </h6>
        <div className="flex text-grayLight900 text-xs font-medium space-x-1.5 [&>*:first-child]:pl-0 [&>*:first-child]:border-l-0 [&>*]:pl-1.5 [&>*]:border-l [&>*]:border-grayLight900 mb-2 last:mb-0">
          <p>{addressDetails?.consigneeAddress1} </p>
          <p>
            {addressDetails?.consigneeCity},{' '}
            {addressDetails?.consigneeStateCode},{' '}
            {addressDetails?.consigneeCountryCode}{' '}
          </p>
          <p> Postal Code: {addressDetails?.consigneePostal}</p>
        </div>
      </div>
      <div className="">
        <h6 className="text-grayLight600 uppercase text-xs font-normal mb-2">
          Dimensions
        </h6>
        {dimensions.map((dimension: any, index: number) => (
          <div
            className="flex text-grayLight900 text-xs font-medium space-x-1.5 [&>*:first-child]:pl-0 [&>*:first-child]:border-l-0 [&>*]:pl-1.5 [&>*]:border-l [&>*]:border-grayLight900 mb-2 last:mb-0"
            key={`spot_dim_${index}`}
          >
            <p>
              {dimension.handlingUnitNo < 10
                ? `0${dimension.handlingUnitNo}`
                : dimension.handlingUnitNo}
              &nbsp;
              {dimension.handlingUnitName}
            </p>
            <p>
              Length: {dimension.itemLength}(in), Width: {dimension.width}(in),
              Height:
              {dimension.height}(in)
            </p>
            <p> Class: {dimension.freightClass ?? '-'} </p>
            <p>
              Total weight: {dimension.totalWeight}(
              {dimension.weightMeasurement.toLowerCase()})
            </p>
          </div>
        ))}
      </div>
      <div className=" ">
        <h6 className="text-grayLight600 uppercase text-xs font-normal mb-2">
          Pickup Date:
        </h6>
        <div className="flex text-grayLight900 text-xs font-medium space-x-1.5 [&>*:first-child]:pl-0 [&>*:first-child]:border-l-0 [&>*]:pl-1.5 [&>*]:border-l [&>*]:border-grayLight900 mb-2 last:mb-0">
          <p>
            {moment(addressDetails.pickupDate).format('dddd, MMMM Do, YYYY')}
          </p>
          <p>{moment(addressDetails.pickupDate).format('MM-DD-YYYY')}</p>
        </div>
      </div>

      {serviceNames.length > 0 && (
        <div>
          <h6 className="text-grayLight600 uppercase text-xs font-normal mb-2">
            Additional Services
          </h6>
          <div className="flex gap-y-1.5 flex-wrap -mx-2.5 [&>*]:px-2.5">
            {serviceNames.map((service: any) => (
              <div key={service} className=" items-start flex gap-1 w-[33.33%]">
                <Check className="w-4 h-4 text-primary mt-px" />
                <div className="text-grayLight900 text-xs font-medium">
                  {service}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {/* <AiInputText /> */}
    </div>
  );

  return (
    <CommonModal
      handleClose={handleClose(false)}
      // titleDesc="Create new carrier for free in less than 5 minutes."
      modalHeaderClass="border-b-0"
      title="Request a Spot Quote"
      primaryBtnText=""
      size={'max-w-3xl'}
      primaryBtnLoading={loading}
    >
      <EmailReplyCmp
        subjectLine="Request For Price"
        toEmailAddress={selectedCarrierRate?.spotQuoteEmail || ''}
        emailDetails={defaultMessage}
        emailStaticBody={emailBody}
        handleClose={handleClose(false)}
        onSubmit={(data: any) => {
          handleClose(true, 'spotQuoteConfirmation', {
            doNotAskSpotQuote: doNotAskChecked,
            sentEmailTo: data.to,
            cc: data.cc ?? [],
            emailContent: data.emailBody,
            attachments: data.attachments,
          })();
        }}
      />
    </CommonModal>
  );
};

export default memo(SpotquoteConfirmation);
