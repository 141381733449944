import {} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

const QuoteDetailTableLoadingRow = () => (
  <>
    {/* <td className="px-5 py-4 min-w-[18%] max-w-[1px] w-[18%]">
      <div className="flex items-center gap-3">
        <div className="table-profile-user-circle-xl custom-loading ">WA</div>
        <div className="truncate custom-loading">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            Dev User
          </h6>
          <p className="text-grayLight600 font-normal leading-tight truncate">
            #SQ-L-205584
          </p>
        </div>
      </div>
    </td> */}
    {/* <td className="px-5 py-4 min-w-[16%] max-w-[1px] w-[16%]">
      <p className="custom-loading">FTL</p>
    </td> */}
    <td className="px-5 py-4 min-w-[200px] max-w-[1px] w-[200px]">
      <div className="flex items-center gap-3 truncate">
        <div className="table-profile-wrap">
          <div className="table-profile-customer-circle-sm custom-loading">
            WA
          </div>
        </div>
        <p className="truncate custom-loading">
          <TooltipCmp message={''}>customer name</TooltipCmp>
        </p>
      </div>
    </td>

    <td className="px-5 py-3 min-w-[130px] max-w-[130px] w-[130px] group">
      <div className="flex gap-1 custom-loading">
        <span>P ickup date</span>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[200px] max-w-[200px] w-[200px] group">
      <div className="flex gap-1 custom-loading">
        <span>from</span>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[180px] max-w-[1px] w-[16%] group">
      <div className="flex gap-1 custom-loading">
        <span>to</span>
      </div>
    </td>
    <td className="px-5 py-4 min-w-[180px] max-w-[155px] w-[16%] group">
      <div className="custom-loading">20 lbs</div>
    </td>
    <td className="px-5 py-4 min-w-[155px] max-w-[155px] w-[155px] group">
      <span className="custom-loading">10 ft</span>
    </td>
    <td className="px-5 py-3 min-w-[160px] max-w-[160px] w-[160px] group">
      <span className="custom-loading"> EquipmentType</span>
    </td>
    <td className="px-5 py-3 min-w-[230px] max-w-[230px] w-[230px] group">
      <span className="custom-loading"> EquipmentType</span>
    </td>
    {/* <td className="px-5 py-4 min-w-[14%] max-w-[14%] w-[14%]">
      {totalUnit ?? 0}
    </td> */}
    {/* <td className="px-5 py-4 min-w-[65px] w-[65px] max-w-[65px]">
      <div className="flex items-center gap-3">
        <div className="flex w-fit rounded border border-borderPrimary shadow-xs p-[3px] custom-loading">
          <div className="w-3.5 h-3.5 ">12</div>
        </div>
      </div>
    </td> */}
  </>
);

export default QuoteDetailTableLoadingRow;
