import { ReverseLeft } from '@untitled-ui/icons-react/build/cjs';
import React, { useRef, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import { convertToGBMBKB } from 'src/utils/CommonFunctions';

import attachmentDefault from '../../../../assets/img/attachment-default.png';
import csvImage from '../../../../assets/img/csv-icon.png';
import docxImage from '../../../../assets/img/docx-icon.png';
import pdfImage from '../../../../assets/img/pdf-icon.png';
import pngImage from '../../../../assets/img/png-icon.png';

import MessageBodyLoading from './MessageBodyLoading';
import ReplyRequestModal from './ReplyRequestModal';

interface IProps {
  emailDetails: any;
}

const EmailDetailSection = ({ emailDetails }: IProps) => {
  const selectedEmail = emailDetails?.emailBody;
  const dotsMenuRef = useRef<HTMLDivElement>(null);

  const emailContent = selectedEmail?.body?.content
    ?.replace(/\\|\r\n/g, '')
    .replace(
      /<table(.*?)>([\s\S]*?)<\/table>/g,
      (match: any, attributes: any, innerContent: any) =>
        // Wrap the <table> in a <div> with the class 'responsive-table'
        `<div class="responsive-table"><table${attributes}>${innerContent}</table></div>`
    );

  const customStyles = `
    <style>
      /* Basic Styles */
      html, body {
        font-family: "Inter", sans-serif !important;
        color: #475467 !important;
        font-weight: 500 !important;
        max-width: 98% !important;
      }
      img{
        max-width: 100%;
      }
      html *{
        font-family: "Inter", sans-serif !important;
        line-height: 1.5 !important;
        white-space: normal;
        max-width: 100% !important;
      }
      html::-webkit-scrollbar,.responsive-table::-webkit-scrollbar{
        width: 4px;
        height: 4px;
      }
      html::-webkit-scrollbar-thumb,.responsive-table::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #eaecf0;
      }
      body {
        font-size: 12px !important;
        margin: 0;
      }
      
      /* Paragraph (p) styling */
      p {
        font-size: 14px !important;
        color: #475467 !important;
        font-weight: 400 !important;
      }
      font {
        font-size: 14px !important;
        color: #475467 !important;
        font-weight: 400 !important;
      }
      /* Heading styles */
      h1 {
        font-size: 30px !important;
        font-weight: 700 !important;
        color: #475467 !important;
        margin: 10px 0;
      }

      h2 {
        font-size: 24px !important;
        font-weight: 600 !important;
        color: #475467 !important;
        margin: 10px 0;
      }

      h3 {
        font-size: 20px !important;
        font-weight: 600 !important;
        color: #475467 !important;
        margin: 10px 0;
      }

      h4 {
        font-size: 18px !important;
        font-weight: 600 !important;
        color: #475467 !important;
        margin: 10px 0;
      }

      h5 {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #475467 !important;
        margin: 10px 0;
      }

      h6 {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: #475467 !important;
        margin: 10px 0;
      }

      /* List item (li) styling */
      li {
        font-size: 14px !important;
        font-weight: 500 !important;
        color: #475467 !important;
      }

      /* Div and Span styling */
      div, span {
        font-size: 14px !important;
        color: #475467 !important;
        font-weight: 400 !important;
      }
      div{
        margin-top: 5px !important;
        margin-bottom: 5px !important;    
      }
      /* Gmail Signature - Hide it */
      .gmail_signature {
        display: none !important;
      }

      /* Default styling for other text elements */
      .MsoNormal {
        color: #475467 !important;
      }
        a, a *{
        color: #3538cd !important;
      }

      .responsive-table{
        overflow-x: auto;
        padding-bottom: 5px;
      }
      table{
        color: #475467 !important;
        border-radius: 12px !important;
        overflow: hidden !important;
        border-color: #eaecf0 !important;
        border-collapse: unset !important;
        font-size: 14px !important;
      }
      table th{
        font-weight: 500 !important;
        background-color: #f9fafb !important;
        border-bottom: 1px solid #eaecf0 !important;
        color: #101828 !important;
      }
      table tr{
        background-color: transparent !important;
        border-bottom: 1px solid #eaecf0 !important;  
      }
      table td{
        font-weight: 400 !important;
      }
    </style>
  `;

  const customScript = `
    <script>
      document.addEventListener('DOMContentLoaded', function() {
        // Custom JavaScript manipulation after the content is loaded
        const signature = document.querySelector('.gmail_signature');
        if (signature) {
          signature.style.display = 'none';  // Example: hide Gmail signature
        }

        // Further manipulation (example: change the color of MsoNormal paragraphs)
        const paragraphs = document.querySelectorAll('.MsoNormal');
        paragraphs.forEach(p => {
          p.style.color = 'gray';  // Example: change text color to gray
        });

        // Find all anchor tags and set them to open in new tab
        const links = document.getElementsByTagName('a');
        for (let link of links) {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer'); // Security best practice
        }

      });
    </script>
  `;

  const srcDocContent = `
    <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
        ${customStyles}
      </head>
      <body lang="EN-US" style="word-wrap:break-word;">
        ${emailContent}
        ${customScript}
      </body>
    </html>
  `;

  const [isShowReplyRequestModal, setIsShowReplyRequestModal] = useState(false);

  const handleReply = () => {
    setIsShowReplyRequestModal(true);
  };

  return (
    <>
      {selectedEmail && (
        <>
          <div className="flex-1 w-full flex-col justify-start items-start gap-4 flex p-5 overflow-y-auto custom-scrollbar-v2">
            <div className="text-grayLight600 text-sm leading-[1.5] font-normal mt-0.5">
              <span className="font-bold mr-1">Subject: </span>
              <span className="font-normal">{selectedEmail?.subject}</span>
            </div>
            <iframe
              srcDoc={srcDocContent}
              style={{ border: 'none', width: '100%', height: '100%' }}
              className="min-h-[auto]"
            />
            {emailDetails?.externalAttachment &&
              emailDetails.externalAttachment.length > 0 && (
                <div className="w-full">
                  <div className="text-black font-medium text-base mb-1.5">
                    Attachments
                  </div>
                  <ul className=" flex flex-wrap -m-1.5">
                    {JSON.parse(emailDetails?.externalAttachment)?.map(
                      (attachment: any, index: number) => {
                        const fileExtension = attachment?.fileUrl
                          ?.split('.')
                          ?.pop()
                          ?.toLowerCase();
                        let fileIcon = null;
                        let fileName = attachment?.name;
                        const fileUrl = attachment?.fileUrl;
                        const fileSize = attachment?.size;

                        const formattedFileSize = convertToGBMBKB(fileSize);

                        if (fileExtension === 'pdf') {
                          fileIcon = (
                            <img src={pdfImage} alt="PDF" className="h-8" />
                          );
                        } else if (
                          fileExtension === 'jpg' ||
                          fileExtension === 'jpeg'
                        ) {
                          fileIcon = (
                            <img
                              src={pngImage}
                              alt="JpgImage"
                              className="h-8"
                            />
                          );
                        } else if (fileExtension === 'png') {
                          fileIcon = (
                            <img
                              src={pngImage}
                              alt="PngImage"
                              className="h-8"
                            />
                          );
                        } else if (fileExtension === 'csv') {
                          fileIcon = (
                            <img src={csvImage} alt="CSV" className="h-8" />
                          );
                        } else if (
                          fileExtension === 'docx' ||
                          fileExtension === 'doc'
                        ) {
                          fileIcon = (
                            <img src={docxImage} alt="DOCX" className="h-8" />
                          );
                        } else if (fileExtension === 'zip') {
                          fileIcon = (
                            <img src={csvImage} alt="ZIP" className="h-8" />
                          );
                        } else if (fileExtension === 'html') {
                          fileIcon = (
                            <img src={csvImage} alt="HTML" className="h-8" />
                          );
                        } else {
                          fileIcon = (
                            <img
                              src={attachmentDefault}
                              alt="DEFAULT"
                              className="h-8"
                            />
                          );
                        }

                        return (
                          <li
                            key={index}
                            className={`p-1.5 w-1/2 ${
                              index % 2 === 0 ? 'col-start-1' : 'col-start-2'
                            }`}
                          >
                            <a
                              href={fileUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3 no-underline"
                            >
                              {fileIcon}
                              <div className="flex-1 w-[calc(100%_-_85px)]">
                                <div className="text-textSecondary text-xs font-medium block truncate">
                                  {fileName}
                                </div>
                                <div className="text-grayLight600 text-xs !text-[11px] font-normal">
                                  {formattedFileSize}
                                </div>
                              </div>
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              )}
          </div>

          <div
            ref={dotsMenuRef}
            className={`flex sm:flex-wrap gap-2 bg-gray50 border-t border-utilityGray200 px-5 py-3  max-sm:top-465px] max-sm:right-[55px] max-sm:shadow max-sm:bg-white max-sm:rounded-lg max-sm:border max-sm:border-borderPrimary max-sm:h-64 max-sm:overflow-y-auto max-sm:flex-col max-sm:p-3 justify-end`}
          >
            <ButtonCmp
              className="btn_secondary_black"
              icon={<ReverseLeft className="w-4 h-4" />}
              onClick={(e: any) => {
                e.stopPropagation();
                handleReply();
              }}
            >
              Reply
            </ButtonCmp>
          </div>
        </>
      )}

      {!selectedEmail && <MessageBodyLoading />}

      {isShowReplyRequestModal && (
        <div className="" onClick={(e) => e.stopPropagation()}>
          <ReplyRequestModal
            emailDetails={selectedEmail}
            handleClose={() => {
              setIsShowReplyRequestModal(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default EmailDetailSection;
