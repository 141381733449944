import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import Radio from 'src/components/Radio/Radio';

interface IProps {
  handleClose: any;
}

const RadarReportInterruptionModal = ({ handleClose }: IProps) => {
  console.log('');

  return (
    <CommonModal
      title="Report Interruption"
      titleDesc="Next steps after reporting interruption"
      handleClose={() => handleClose(false)}
      headerIcon={<PackagePlus />}
      size={'max-w-[600px]'}
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
    >
      <form className="p-5 flex flex-col gap-y-4">
        <InputText
          inputName="chatbot"
          label="Please add a detailed explanation of the interruption"
          placeholder="Enter Explanation"
          labelClassName="block mb-1.5"
          className="h-auto"
          asTextarea="textarea"
          parentClassName={'w-full'}
          value={'test'}
          onChangeFunc={() => {}}
          // errorText={error ? error?.message : ''}
        />

        <ul className="flex flex-wrap gap-2">
          <li className="flex-1">
            <h6 className="text-textSecondary text-sm font-normal mb-2">
              Would you like to open a claim?
            </h6>
            <div className="flex gap-x-2">
              <>
                <Radio
                  id="claim-yes"
                  value="Yes"
                  labelName="Yes"
                  parentClassName="min-w-[75px]"
                  checked={true}
                  onChangeFunc={() => {}}
                  labelHtmlFor="claim-yes"
                />
                <Radio
                  id="claim-no"
                  value="No"
                  labelName="No"
                  parentClassName="min-w-[75px]"
                  checked={true}
                  onChangeFunc={() => {}}
                  labelHtmlFor="claim-no"
                />
              </>
            </div>
          </li>

          <li className="flex-1">
            <h6 className="text-textSecondary text-sm font-normal mb-2">
              Would you like to open a dispute?
            </h6>
            <div className="flex gap-x-2">
              <>
                <Radio
                  id="dispute-yes"
                  value="Yes"
                  labelName="Yes"
                  parentClassName="min-w-[75px]"
                  checked={true}
                  onChangeFunc={() => {}}
                  labelHtmlFor="dispute-yes"
                />
                <Radio
                  id="dispute-no"
                  value="No"
                  labelName="No"
                  parentClassName="min-w-[75px]"
                  checked={true}
                  onChangeFunc={() => {}}
                  labelHtmlFor="dispute-no"
                />
              </>
            </div>
          </li>
        </ul>
      </form>
    </CommonModal>
  );
};

export default RadarReportInterruptionModal;
