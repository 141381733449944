import React from 'react';

import Appointment from './Appointment';

interface IProps {
  setIsAppointmentInEditMode: any;
}

const Step2 = ({ setIsAppointmentInEditMode }: IProps) => (
  <Appointment setIsAppointmentInEditMode={setIsAppointmentInEditMode} />
);

export default Step2;
