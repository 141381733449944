import {
  ArrowUp,
  Copy06,
  InfoCircle,
  Share03,
} from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY, STATUS } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import BanyanIcon from '../../../../assets/img/banyan-icon-transparent.svg';
import defaultCarrierIcon from '../../../../assets/img/default-image.jpg';
import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

import ServiceDetail from './ServiceDetail';

const WithRateQuote = ({ data, currency, addressDetails }: any) => {
  const [isNonQuotedServicesExist, setIsNonQuotedServicesExist] =
    useState(false);
  useEffect(() => {
    setIsNonQuotedServicesExist(
      data.services.some((form: any) => !form.isQuoted)
    );
  }, [data]);

  const copyDebounce = useCallback(
    debounce((copyQuote: string) => {
      navigator.clipboard.writeText(copyQuote);
      WalToast.copy('Copied to clipboard', '', 'copy-msg');
    }, 0),
    []
  );

  const getRemainingDays = (expirationDate: string): string => {
    const expMoment = moment(expirationDate).endOf('day');
    const today = moment().startOf('day');

    const diffDays = expMoment.diff(today, 'days');

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Tomorrow';
    } else if (diffDays > 1) {
      return `${diffDays} Days`;
    } else {
      return 'Expired';
    }
  };

  const getBusinessDays = (startDate: Date, endDate: Date) => {
    let count = 0;
    const oneDay = 24 * 60 * 60 * 1000;

    for (
      let date = moment(startDate).toDate();
      date <= endDate;
      date.setTime(date.getTime() + oneDay)
    ) {
      const dayOfWeek = date.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return count;
  };

  return (
    <>
      <td className={`px-5 py-4 min-w-[230px] w-[230px] max-w-[230px]`}>
        <div className=" flex items-center">
          <div className="flex-none relative">
            {data.image ? (
              <img
                src={`${data.imageUrl}${data.image}`}
                className="table-profile-icn-circle-xl rounded-md"
                alt={data.name}
                title={data.name}
                onError={onError}
              />
            ) : (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(`${data.name}`)}
              </div>
            )}

            {(data.isFreightcom || data.isFreightcom === 1) && (
              <div className="absolute -bottom-[8px] -right-2.5 ">
                <TooltipCmp message="Freightcom">
                  <div className="w-5 h-5 rounded-full border border-borderPrimary bg-utilityGray100 p-[1px] hover:border-gray400 cursor-pointer">
                    <img
                      className="object-contain"
                      src={FreightcomIcon}
                      alt="FC"
                    />
                  </div>
                </TooltipCmp>
              </div>
            )}
            {(data.isBanyan || data.isBanyan === 1) && (
              <div className="absolute -bottom-[8px] -right-2.5 ">
                <TooltipCmp message="Banyan Technologies">
                  <div className="w-5 h-5 rounded-full border border-borderPrimary bg-utilityGray100 p-[2.5px] flex items-center justify-center hover:border-gray400 cursor-pointer">
                    <img
                      className="object-contain"
                      src={BanyanIcon}
                      alt="BANYAN"
                    />
                  </div>
                </TooltipCmp>
              </div>
            )}
            {(data.isAmericanGroup || data.isAmericanGroup === 1) && (
              <div className="absolute -bottom-[8px] -right-[12px] ">
                <TooltipCmp message="American Group">
                  <div className="w-[24px] h-[24px] rounded-full border border-borderPrimary bg-utilityGray100 p-1 flex items-center justify-center hover:border-gray400 cursor-pointer">
                    <img
                      className="object-contain"
                      src={defaultCarrierIcon}
                      alt="AMERICAN GROUP"
                    />
                  </div>
                </TooltipCmp>
              </div>
            )}
          </div>
          <div className="pl-5 truncate">
            {(data.name + (data.banyanPostFix || '')).length > 30 ? (
              <div className="flex items-center">
                <div className="truncate">
                  <TooltipCmp
                    message={`${data.name} ${data.banyanPostFix || ''}`}
                  >
                    <h6 className="text-grayLight900 font-medium leading-tight truncate">{`${
                      data.name
                    } ${data.banyanPostFix || ''}`}</h6>
                  </TooltipCmp>
                </div>
              </div>
            ) : (
              <div className="flex items-center">
                <h6 className="text-grayLight900 font-medium leading-tight truncate">
                  {`${data.name} ${data.banyanPostFix || ''}`}{' '}
                </h6>
              </div>
            )}

            <p className="text-grayLight600 text-[10px] font-normal leading-tight flex items-center">
              {data.carrierQuoteId.length > 30 ? (
                <>
                  <TooltipCmp message={`#${data.carrierQuoteId}`}>
                    <span className="max-w-28 truncate inline-block align-middle ">
                      #{data.carrierQuoteId}
                    </span>
                  </TooltipCmp>
                </>
              ) : (
                <>
                  <span className="inline align-middle truncate max-w-28">
                    #{data.carrierQuoteId}
                  </span>
                </>
              )}
              &nbsp;
              <div className="flex gap-1">
                <TooltipCmp message="Copy">
                  <Copy06
                    onClick={(event) => {
                      event.stopPropagation();
                      copyDebounce(data.carrierQuoteId);
                    }}
                    className="text-gray500 w-3 h-3 inline cursor-pointer flex-none"
                  />
                </TooltipCmp>
                {data.quoteUrl && (
                  <TooltipCmp message="Open">
                    <a
                      href={data.quoteUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <Share03 className="text-gray500 w-3 h-3 inline cursor-pointer flex-none" />
                    </a>
                  </TooltipCmp>
                )}
              </div>
            </p>
          </div>
        </div>
      </td>

      <td className={`px-5 py-4 w-[10.8%] min-w-[10.8%] max-w-[10.8%]`}>
        {data.expirationDate ? (
          <BadgeCmp
            style="modern"
            type={
              moment(data.expirationDate)
                .add(1, 'days')
                .isBefore(moment().startOf('day'))
                ? 'red'
                : 'warning'
            }
          >
            {getRemainingDays(data.expirationDate)}
          </BadgeCmp>
        ) : (
          <BadgeCmp
            style="modern"
            type={
              moment(addressDetails.pickupDate).isBefore(
                moment().startOf('day')
              )
                ? 'red'
                : 'warning'
            }
          >
            {getRemainingDays(data.expirationDate)}
          </BadgeCmp>
        )}
      </td>
      <td className={`px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%]`}>
        <span
          className={`${isNonQuotedServicesExist ? 'line-through' : ''} hidden`}
        >
          Est. {data.transitTime} days
        </span>

        {getBusinessDays(
          moment().toDate(),
          moment(data.projectedDeliveryDate).toDate()
        ) > 0 ? (
          <span className={`${isNonQuotedServicesExist ? 'line-through' : ''}`}>
            Est.{' '}
            {getBusinessDays(
              moment().toDate(),
              moment(data.projectedDeliveryDate).toDate()
            )}{' '}
            {getBusinessDays(
              moment().toDate(),
              moment(data.projectedDeliveryDate).toDate()
            ) === 1
              ? 'day'
              : 'days'}
          </span>
        ) : (
          '-'
        )}
      </td>
      <td className={`px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%] `}>
        {data.transitTime >= 0 ? (
          <span
            className={`${isNonQuotedServicesExist ? 'line-through' : ''}`}
            dangerouslySetInnerHTML={{
              __html: data.projectedDeliveryDate
                ? getDateWithSuffixFormat(
                    moment
                      .utc(data.projectedDeliveryDate)
                      .format(`MMM Do - YYYY`)
                  )
                : '-',
            }}
          />
        ) : (
          '-'
        )}
      </td>
      <td
        className={`px-5 py-4 w-[12.8%] min-w-[12.8%] max-w-[12.8%] ${
          isNonQuotedServicesExist ? 'line-through' : ''
        }`}
      >
        {data.totalCharge === 0
          ? '-'
          : currency === CURRENCY.CAD
          ? `$${getFormattedNumber(data.totalCharge)}`
          : `$${getFormattedNumber(data.totalChargeUSD.toFixed(2))}`}
      </td>

      <td className={`px-5 py-4 w-[172px] min-w-[172px] max-w-[172px]`}>
        <div
          className={`flex items-center gap-1 ${
            isNonQuotedServicesExist ? 'line-through' : ''
          }`}
        >
          {data?.additionalCharges > 0 || data?.services?.length > 0 ? (
            <ActionTooltip
              openOnHover={true}
              tooltipPosition="center"
              parentClassName="w-max"
              isOpenChildTopPosOnTop={true}
              type="dark"
              message={<ServiceDetail carrierServices={data} />}
            >
              {data.additionalCharges === 0
                ? '$0.00'
                : currency === CURRENCY.CAD
                ? `$${getFormattedNumber(data.additionalCharges)}`
                : `$${getFormattedNumber(data.additionalChargesUSD)}`}
            </ActionTooltip>
          ) : data.additionalCharges === 0 ? (
            '$0.00'
          ) : currency === CURRENCY.CAD ? (
            `$${getFormattedNumber(data.additionalCharges)}`
          ) : (
            `$${getFormattedNumber(data.additionalChargesUSD)}`
          )}
          {isNonQuotedServicesExist ? (
            <TooltipCmp message="The carrier did not provide a rate for all additional services specified. Please validate the feasibility of the shipment and/or any additional costs.">
              <InfoCircle className="text-primary w-3 h-3" />
            </TooltipCmp>
          ) : (
            ''
          )}
        </div>
      </td>

      <td
        className={`px-5 py-4 text-sm w-[180px] min-w-[180px] max-w-[180px] ${
          isNonQuotedServicesExist ? 'line-through' : ''
        } `}
      >
        <span className="text-textSecondary font-bold">
          {data.carrierQuoteId
            ? currency === CURRENCY.CAD
              ? `$${getFormattedNumber(data.finalCharge)} CAD`
              : `$${getFormattedNumber(data.finalChargeUSD)} USD`
            : '-'}
        </span>
      </td>
      {[STATUS.ACTIVE, STATUS.OPENED].includes(addressDetails.status) && (
        <td className={`px-5 py-4 w-[130px] max-w-[130px] min-w-[130px]`}>
          <div className="flex justify-end gap-3 ">
            <ArrowUp className="w-4 h-4 text-primary" />
          </div>
        </td>
      )}
    </>
  );
};

export default WithRateQuote;
