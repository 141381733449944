import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ROUTES } from 'src/constants/routes';
import { listNotification } from 'src/services/NotificationService';
import { removeHtmlTags } from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import LogoDark from '../assets/img/logo-small.png';

import { AuthContext } from './AuthContext';
import { EntityCountsContext } from './EntityCountsContext';

export const NotificationsContext = createContext<any>(null);

export const initParams = {
  currentTab: 'viewall',
  page: 1,
  limit: 5,
  type: ['claim', 'dispute'],
};

export const NotificationsProvider = (props: any) => {
  const { children } = props;
  const [notificationParam, setNotificationParams] = useState(initParams);
  const [notifications, setNotifications] = useState([]);
  const [notificationTotals, setNotificationTotals] = useState([]);
  const [isNotificationLoading, setIsNotificationLoading] = useState(false);
  const [entityType, setEntityType] = useState('');
  const [entityId, setEntityId] = useState(0);

  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const EntityCounts = useContext(EntityCountsContext);
  const { setNotificationsCount } = useContext(EntityCountsContext);
  const notificaitonReloadCounts = EntityCounts.reloadCounts;
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const fetchNotfications = async () => {
    if (!isAuthenticated) {
      return;
    }

    setIsNotificationLoading(true);
    setNotifications([]);
    const params = { ...notificationParam, userId: currentUser.id };

    listNotification(params)
      .then((response: any) => {
        if (response.data) {
          const responseData = response.data;
          setNotifications(responseData);
          setNotificationTotals(response.total);
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsNotificationLoading(false);
      });
  };

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }
  }, []);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on && currentUser && currentUser?.id) {
      socketIO.get(
        `/subscribe/notification-user-${currentUser.id}`,
        (details: any, jwr: any) => {
          if (jwr.error) {
            console.log('jwr.error', jwr.error);

            return;
          }
        }
      );

      socketIO.on('new-notification', (data: any) => {
        console.log('notification received');

        if (data) {
          console.log('data ', data);

          if (
            'Notification' in window &&
            Notification.permission === 'granted' &&
            (document.hidden || !document.hasFocus())
          ) {
            const notification = new Notification(data.subject, {
              body: removeHtmlTags(data.message),
              icon: LogoDark,
              badge: LogoDark,
              tag: data.type,
            });

            notification.onclick = function () {
              window.focus();

              const routeMap: any = {
                team_member: ROUTES.TEAMS,
                team_management: ROUTES.TEAM_MANAGEMENT,
                customer: ROUTES.CUSTOMER_DETAILS,
                carrier: ROUTES.CARRIERS,
                order: ROUTES.ORDERS,
                load_insurance_created: ROUTES.LOAD_INSURANCE,
                feedback: ROUTES.FEEDBACK_LIST,
                quoting_hub_request: ROUTES.QUOTING_DASHBOARD,
                claim: ROUTES.CLAIMS_AND_DISPUTES_DETAILS,
                dispute: ROUTES.CLAIMS_AND_DISPUTES_DETAILS,
                customer_onboarding: ROUTES.CREDIT_DASHBOARD,
                credit_increase_request: ROUTES.CREDIT_DASHBOARD,
                call_center: ROUTES.CALL_CENTER_CONTACTS,
              };

              const targetRoute = routeMap[data?.notificationType] || '/';
              window.location.href = targetRoute;

              notification.close();
            };
          } else {
            const message = removeHtmlTags(data.message);
            WalToast.success(data.subject ?? 'Subject', message);
          }

          if (
            [
              'carrier_created',
              'team_created',
              'team_member_created',
              'customer_sync_created',
              'order_created',
              'load_insurance_created',
              'feedback_create',
              'credit_increase_request_created',
              'quote_request_arrived',
            ].includes(data?.type)
          ) {
            notificaitonReloadCounts();
          }

          setNotificationsCount(
            (notificationCount: number) => notificationCount + 1
          );

          setEntityType(data.type);
          setEntityId(data.entityId);

          if (data.type === 'claim' || data.type === 'dispute') {
            fetchNotfications();
          }
        }
      });
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('new-notification');
      }
    };
  }, [isSocketConnected, isAuthenticated, currentUser]);

  const reloadCounts = () => {
    fetchNotfications();
  };

  return (
    <NotificationsContext.Provider
      value={{
        notificationParam,
        notifications,
        setNotifications,
        isNotificationLoading,
        notificationTotals,
        setNotificationParams,
        reloadCounts,
        fetchNotfications,
        setEntityType,
        entityType,
        setEntityId,
        entityId,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
