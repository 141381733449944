import { Container, FilePlus03 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import {
  getDateWithSuffixFormat,
  getFormattedNumber,
  getShortName,
  onError,
} from 'src/utils/CommonFunctions';

interface IProps {
  handleClose: any;
  quotedList: any;
  currency: any;
  selectedQuoteToGeneratePdfId: any;
  setSelectedQuoteToGeneratePdfId: any;
  setIsShowAttachQuotePDFModal: any;
}

const SelectQuotesModal = ({
  handleClose,
  quotedList = [],
  currency,
  selectedQuoteToGeneratePdfId,
  setSelectedQuoteToGeneratePdfId,
  setIsShowAttachQuotePDFModal,
}: IProps) => {
  const [quotedListArr, setquotedListArr] = useState([]);

  useEffect(() => {
    setquotedListArr(quotedList);
    if (quotedList?.length === 1)
      setSelectedQuoteToGeneratePdfId(quotedList.map((data: any) => data.id));
  }, [quotedList]);

  const headCells = useMemo(() => {
    const baseHeadCells = [
      {
        id: 'quote',
        name: 'Quote',
      },
      {
        id: 'est_delivery_date',
        name: 'Est. Delivery Date',
      },
      {
        id: 'total_price',
        name: 'Total Price',
      },
    ];

    const actionCell = {
      id: 'action',
      rowClassName: 'w-[5%]',
      name: (
        <CheckBox
          checked={
            quotedListArr.length > 0 &&
            quotedListArr.every((data: any) =>
              selectedQuoteToGeneratePdfId.includes(data.id)
            )
          }
          onChangeFunc={(e: any) => {
            if (e.target.checked) {
              setSelectedQuoteToGeneratePdfId(
                quotedListArr.map((data: any) => data.id)
              );
            } else {
              setSelectedQuoteToGeneratePdfId([]);
            }
          }}
          parentClassName=""
        />
      ),
      sortable: false,
    };

    return [...baseHeadCells, actionCell];
  }, [quotedListArr, selectedQuoteToGeneratePdfId]);

  const SelectQuotePdfRow = ({ data }: { data: (typeof quotedList)[0] }) => (
    <>
      <td className="px-5 py-4 w-[38%] max-w-px min-w-[38%]">
        <div className="flex items-center gap-x-3">
          {data.image ? (
            <img
              src={`${data.imageUrl}${data.image}`}
              className="table-profile-icn-circle-xl rounded-md"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : data?.name || data.carrierName ? (
            <div className="table-profile-customer-circle-xl rounded-md">
              {getShortName(data?.name || data.carrierName)}
            </div>
          ) : (
            <div className="table-profile-customer-circle-xl rounded-md">
              <Container className="w-4 h-4 text-gray500" />
            </div>
          )}
          <div className="truncate">
            {data?.carrierId ? (
              (data.name + (data.banyanPostFix || '')).length > 15 ? (
                <TooltipCmp
                  message={`${data.name} ${data.banyanPostFix || ''}`}
                >
                  <h6 className="text-grayLight900 text-sm font-medium truncate">
                    {`${data.name} ${data.banyanPostFix || ''}`}
                  </h6>
                </TooltipCmp>
              ) : (
                <h6 className="text-grayLight900 text-sm font-medium truncate">
                  {`${data.name} ${data.banyanPostFix || ''}`}
                </h6>
              )
            ) : data?.carrierName ? (
              (data.carrierName + (data.banyanPostFix || '')).length > 15 ? (
                <TooltipCmp
                  message={`${data.carrierName} ${data.banyanPostFix || ''}`}
                >
                  <h6 className="text-grayLight900 text-sm font-medium truncate">
                    {`${data.carrierName} ${data.banyanPostFix || ''}`}
                  </h6>
                </TooltipCmp>
              ) : (
                <h6 className="text-grayLight900 text-sm font-medium truncate">
                  {`${data.carrierName} ${data.banyanPostFix || ''}`}
                </h6>
              )
            ) : (
              <h6 className="text-grayLight900 text-sm font-medium truncate">
                {`Unassigned Carrier`}
              </h6>
            )}
            {/* <p className="text-grayLight600 text-xs font-normal truncate">
              {data.carrierId ? `#${data.carrierId}` : '-'}
            </p> */}
          </div>
        </div>
      </td>
      <td className="px-5 py-4 w-[25.39%] min-w-[150px] max-w-[25.39%]">
        <span className="text-grayLight900">
          {data.transitTime >= 0 ? (
            <span
              dangerouslySetInnerHTML={{
                __html: data.projectedDeliveryDate
                  ? getDateWithSuffixFormat(
                      moment
                        .utc(data.projectedDeliveryDate)
                        .format(`MMM Do - YYYY`)
                    )
                  : '-',
              }}
            />
          ) : (
            '-'
          )}
        </span>
      </td>
      <td className="px-5 py-4 w-[25.39%] min-w-[25.39%] max-w-[25.39%]">
        <span className="text-grayLight900">
          {currency === CURRENCY.CAD
            ? `$${getFormattedNumber(data.finalCharge)} CAD`
            : `$${getFormattedNumber(data.finalChargeUSD)} USD`}
        </span>
      </td>
      <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
        <CheckBox
          checked={selectedQuoteToGeneratePdfId.includes(data.id)}
          onChangeFunc={() => {}}
          classes="self-center"
        ></CheckBox>
      </td>
    </>
  );

  const SelectQuotePdfLoaderRow = () => (
    <>
      <td className="px-5 py-4 w-[38%] max-w-px min-w-[38%]">
        <span className="custom-loading">noreply@xyz.com</span>
      </td>
      <td className="px-5 py-4 w-[25.39%] min-w-[150px] max-w-[25.39%]">
        <div className="custom-loading">44</div>
      </td>
      <td className="px-5 py-4 w-[25.39%] min-w-[25.39%] max-w-[25.39%]">
        <div className="custom-loading">test</div>
      </td>
      <td className="px-5 py-4 w-[60px] min-w-[60px] max-w-[60px]">
        <div className="w-4 h-4 custom-loading">test</div>
      </td>
    </>
  );

  return (
    <>
      <CommonModal
        title={'Select Quotes'}
        titleDesc={'Select the quotes to include in your response.'}
        handleClose={() => handleClose(false)}
        headerIcon={<FilePlus03 />}
        size={'max-w-[780px]'}
        isOverflow={true}
        modalClassName=""
        secondaryBtnText="Cancel"
        secondaryBtnOnClick={handleClose}
        primaryBtnText="Next"
        primaryBtnOnClick={() => {
          handleClose(false);
          setIsShowAttachQuotePDFModal(true);
        }}
        primaryBtnDisabled={selectedQuoteToGeneratePdfId.length === 0}
      >
        <div className="w-full p-5 flex flex-col gap-y-4">
          <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between overflow-hidden">
            <div className="h-full w-full ">
              {quotedListArr.length > 0 ? (
                <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                  <TableCmp
                    headCells={headCells}
                    tableDataArr={quotedListArr}
                    TableRowCmp={SelectQuotePdfRow}
                    TableLoaderRowCmp={SelectQuotePdfLoaderRow}
                    numberOfSkeletonRows={5}
                    isTableRowClickable={true}
                    onRowClick={(data: any) =>
                      setSelectedQuoteToGeneratePdfId((prevState: any) => {
                        if (prevState.includes(data.id)) {
                          return prevState.filter((id: any) => id !== data.id);
                        } else {
                          return [...prevState, data.id];
                        }
                      })
                    }
                  />
                </div>
              ) : (
                <div>
                  <NotFoundUI
                    title="No Active Quotes"
                    desc="To generate pdf, move your quotes to active quotes"
                    descClass="!max-w-[300px]"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default SelectQuotesModal;
