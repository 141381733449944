import {
  Trash01,
  Plus,
  Edit05,
  Container,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import CarrierModal from 'src/app/Orders/Common/Carrier/CarrierModal';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

import { OrderContext } from '..';

interface IProps {
  setValue: any;
  clearErrors: any;
  getValues: any;
}

const RenderCarrierPriceSection = ({
  setValue,
  clearErrors,
  getValues,
}: IProps) => {
  const [carrierEditData, setCarrierEditData] = useState(null);
  const [isCarrierFormOpen, setIsCarrierFormOpen] = useState<boolean>(false);
  const [carrier, setCarrier] = useState<any>(null);
  const [isCarrierAssigned, setIsCarrierAssigned] = useState(false);
  const [additionalCharge, setAdditionalCharge] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const { carrierPrice, setCarrierPrice } = useContext<any>(OrderContext);

  useEffect(() => {
    if (
      carrierPrice &&
      Array.isArray(carrierPrice) &&
      carrierPrice.length > 0
    ) {
      const selectedCarrier = carrierPrice[0];
      setCarrier(selectedCarrier);

      const additionalChargesTotal = selectedCarrier?.additional_charge
        ? selectedCarrier.additional_charge.reduce(
            (total: number, item: any) => total + (item.price || 0),
            0
          )
        : 0;
      setAdditionalCharge(additionalChargesTotal);
    } else {
      setCarrier(null);
      setAdditionalCharge(0);
    }
  }, [carrierPrice]);

  useEffect(() => {
    if (carrier) {
      setIsCarrierAssigned(true);
      setIsLoading(false);
    } else {
      setIsCarrierAssigned(false);
      setIsLoading(false);
    }
  }, [carrier]);

  const handleCarrierEdit = (data: any) => {
    if (data.carrierDate) {
      data.carrierDate = moment(data.carrierDate).format('YYYY-MM-DD');
    }

    if (!data.carrierId) {
      data.carrierId = 'unassigned';
    }

    setCarrierEditData(data);
    setIsCarrierFormOpen(true);
  };

  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    setCarrierPrice(() => [newCarrierPrice]);
    setValue('carrierPrice', [newCarrierPrice]);
    clearErrors('carrierPrice');

    setIsCarrierFormOpen(false);
  };

  const handleCarrierDelete = () => {
    setCarrierPrice([]);
    setValue('carrierPrice', []);
    setCarrierEditData(null);
  };

  return (
    <>
      <ul className="rounded-lg border border-utilityGray200 bg-white">
        <li className="last:border-b-0 border-b border-utilityGray200 p-3 space-y-1.5">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Carrier
          </h6>
          <div className="flex items-center gap-x-1.5">
            {isCarrierAssigned ? (
              <>
                {carrier?.carrier?.image ? (
                  <img
                    className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none"
                    src={`${carrier?.carrier?.image}`}
                  />
                ) : (
                  <div className="w-10 h-10 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                    {getShortName(carrier?.carrier?.name ?? 'Unassigned')}
                  </div>
                )}

                <div className="truncate flex-1">
                  <h6 className="text-grayLight900 text-sm font-medium truncate">
                    <TooltipCmp
                      message={
                        carrier?.carrier?.name &&
                        carrier?.carrier?.name?.length > 18 &&
                        carrier?.carrier?.name
                      }
                    >
                      {carrier?.carrier?.name ?? 'Unassigned'}
                    </TooltipCmp>
                  </h6>
                  <p className="flex items-center gap-1 text-grayLight600 text-xs font-normal">
                    #WQ085020145
                  </p>
                </div>

                {!getValues('quoteId') && (
                  <div className="items-center gap-2 flex ">
                    <Edit05
                      className="w-4 h-4 cursor-pointer text-textSecondary"
                      onClick={() => {
                        handleCarrierEdit(carrier);
                      }}
                    />
                    <Trash01
                      className="w-4 h-4 cursor-pointer text-textSecondary"
                      onClick={() => {
                        handleCarrierDelete();
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  className={`w-10 h-10 rounded-md border border-black/[0.08] flex-none flex items-center justify-center bg-utilityGray100 text-gray500 `}
                >
                  <Container className="w-6 h-6" />
                </div>
                <div
                  className={`truncate flex-1 ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  <h6 className="text-grayLight900 text-sm font-medium truncate">
                    Unassigned Carrier
                  </h6>
                  <p className="flex items-center gap-1 text-grayLight600 text-xs font-normal">
                    -
                  </p>
                </div>

                <ButtonCmp
                  className={`btn-outline-primary !shadow-none !bg-transparent !border-0 !p-0 ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                  onClick={() => {
                    setCarrierEditData(null);
                    setIsCarrierFormOpen(true);
                  }}
                  icon={<Plus className="w-4 h-4 text-primary700" />}
                >
                  Assign Carrier
                </ButtonCmp>
              </>
            )}
          </div>
        </li>

        <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
          <div className="w-1/3 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Base Cost
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? carrier?.baseCarrierRate
                  ? `$${carrier?.baseCarrierRate}`
                  : '-'
                : '2000'}
            </p>
          </div>
          <div className="w-1/3 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Total Cost
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? carrier?.totalPrice
                  ? `$${carrier?.totalPrice}`
                  : '-'
                : 20000}
            </p>
          </div>
          <div className="w-1/3 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Additionals
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? additionalCharge
                  ? `$${additionalCharge}`
                  : '-'
                : 2000}
            </p>
          </div>
        </li>
        <li className="last:border-b-0 border-b border-utilityGray200 p-3 flex">
          <div className="w-1/3 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Budget Cost
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
            >
              {!isLoading
                ? carrier?.budgetCost
                  ? `$${carrier?.budgetCost}`
                  : '-'
                : 200}
            </p>
          </div>
          <div className="w-1/3 space-y-1">
            <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
              Carrier Booking Date
            </h6>
            <p
              className={`text-textSecondary text-sm font-medium ${
                isLoading ? 'custom-loading' : ''
              }`}
              dangerouslySetInnerHTML={{
                __html: !isLoading
                  ? carrier?.carrierBookingDate
                    ? getDateWithSuffixFormat(
                        getFormattedDate(
                          carrier?.carrierBookingDate,
                          `Do MMMM YYYY`,
                          false,
                          false
                        )
                      )
                    : '-'
                  : 'Today, Friday at 12:00',
              }}
            ></p>
          </div>
        </li>
      </ul>

      {isCarrierFormOpen && (
        <CarrierModal
          handleClose={() => {
            setIsCarrierFormOpen(false);
          }}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
          additionalServices={getValues('additionalServices')?.serviceId}
        />
      )}
    </>
  );
};

export default RenderCarrierPriceSection;
