import { CheckSquareBroken, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';

const OutlookAccountLoadingCard = () => (
  <div className="w-1/2">
    <div className="rounded-lg border border-utilityGray200 px-3 py-5 flex items-center gap-x-3">
      <div className="flex-none">
        <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-sm custom-loading">
          SU
        </div>
      </div>
      <div className="flex-1 overflow-hidden space-y-1">
        <div className="leading-[1]">
          <h6 className="text-grayLight900 text-sm font-medium inline align-middle mr-1 custom-loading">
            skeleton user
          </h6>
          <BadgeCmp
            style="modern"
            type="success"
            mainClassName="custom-loading"
          >
            primary
          </BadgeCmp>
        </div>
        <p className="text-grayLight600 text-xs font-normal truncate custom-loading">
          skeletonuser@skeletonuseremail.com
        </p>
      </div>
      <TooltipCmp message={false}>
        <ButtonCmp className="btn-link-secondary custom-loading">
          <CheckSquareBroken className="w-4 h-4 text-grayLight900" />
        </ButtonCmp>
      </TooltipCmp>
      <TooltipCmp message={false}>
        <ButtonCmp className="btn-link-secondary custom-loading">
          <Trash01 className="w-4 h-4 text-fgErrorPrimary" />
        </ButtonCmp>
      </TooltipCmp>
    </div>
  </div>
);

export default OutlookAccountLoadingCard;
