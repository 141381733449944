import { SearchLg, PhoneCall01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
// import // setBargeWhisperCallDetails,
// setErrorMessage,
// setIsBargeWhisper,
//'src/redux/CallCenter.slice';
import { USER_ROLE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import { EntityCountsContext } from 'src/context/EntityCountsContext';
import {
  setAdminJoiningACall,
  setAgentCallSid,
  setBargeWhisperCallDetails,
  setBargeWhisperConferenceId,
  setBargeWhisperNumber,
  setCallDuration,
  setCallLogId,
  setErrorMessage,
  setFromNumberContact,
  setHoldStatus,
  setIsBargeWhisper,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setOutgoingCall,
  setOutgoingCallDetails,
  setSupervisorSid,
} from 'src/redux/CallCenter.slice';
import { RootState } from 'src/redux/store';
import {
  addBargeWhisper,
  addParticipantInConf,
  CallLogList,
} from 'src/services/CallCenterService';
import { addCallLog } from 'src/services/TwilioService';
import { checkAudioDevicesAndPermissions } from 'src/utils/CommonFunctions';

import ActiveCallModal from '../ActiveCallModal';
import ContactModal from '../Contacts/ContactModal';

import CallLogLoaderRow from './CallLogLoaderRow';
import CallLogRow from './CallLogRow';
import MakeACall from './MakeACall';

const initParams: any = {
  search: '',
  sortType: 'desc',
  page: 1,
  limit: 50,
  status: 'all',
  type: null,
};

type CallParams = Record<string, string>;

type ActionType = {
  mode: string | null;
  data: any;
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const CallLogs = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const { isMakeCall, setIsMakeCall } = useContext(BasicContext);
  const [isMultipleCalls, setIsMultipleCalls] = useState<boolean>(false);
  const [callLogs, setCallLogs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [callLogParams, setCallLogParams] = useState<any>(initParams);
  // const [isClickable, setIsClickable] = useState<boolean>(false);
  const [action, setAction] = useState<ActionType>({ mode: '', data: {} });
  const [newContactDialAction, setNewContactDialAction] = useState<ActionType>({
    mode: '',
    data: {},
  });

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const { entityCounts, handleNotificationRead, isNotificationLoading } =
    useContext(EntityCountsContext);

  const {
    isCallInProgress,
    device,
    // token,
    outgoingCall,
    adminJoiningACall,
    incomingCall,
  } = useSelector((state: RootState) => state.CallCenter);

  const FilterArray: any = [
    {
      value: 'all',
      name: 'View All',
    },
    {
      value: 'ongoing',
      name: 'Ongoing',
    },
    {
      value: 'missed',
      name: 'Missed',
    },
    {
      value: 'inbound',
      name: 'Inbound',
    },
    {
      value: 'outbound',
      name: 'Outbound',
    },
    {
      value: 'voicemail',
      name: 'Voice Mail',
    },
  ];

  const filteredArray =
    currentUser?.role?.name === 'sales'
      ? FilterArray.filter((filter: any) => filter.value !== 'ongoing')
      : FilterArray;

  const filterType = [
    { label: 'Carrier', value: 'carrier' },
    { label: 'Customer', value: 'customer' },
  ];

  useEffect(() => {
    if (callLogs && callLogs.length > 0 && !isNotificationLoading) {
      setTimeout(() => {
        if (
          entityCounts?.missedCallCount &&
          entityCounts?.missedCallCount > 0
        ) {
          const param = { type: 'missed_call' };
          handleNotificationRead(param);

          const newOrders = callLogs.map((callLog) => ({
            ...callLog,
            hasUnreadNotification: false,
          }));

          setCallLogs(newOrders);
        }
      }, 5000);
    }
  }, [callLogs, entityCounts, isNotificationLoading]);

  const getCallLogsData = (signal?: any, showLoading = true) => {
    setCallLogs([]);
    setIsLoading(showLoading);

    CallLogList(callLogParams, signal)
      .then((response: any) => {
        if (response?.data) {
          setCallLogs(response?.data);
        }
        setTotal(response?.total);
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setCallLogs([]);
        setIsLoading(false);
      });
  };

  const handleOutsideClick = () => {
    setIsMakeCall(false);
  };

  const handleModalClose = (resetAction?: any) => {
    if (resetAction?.mode === 'addNewContactDial') {
      setNewContactDialAction({
        mode: 'newContactDial',
        data: resetAction?.data,
      });
      setIsMakeCall(true);
    }

    if (resetAction) {
      getCallLogsData();
      setAction({ mode: null, data: null });
    } else {
      setAction({ mode: '', data: {} });
    }
  };
  // handle outgoing call
  // const handleCall = async (contactData: any) => {
  //   // console.log('⭐️ ~ handleCall ~ scheduleNumber:', scheduleNumber);
  //   console.log('⭐️ ~ handleCall ~ data:', contactData);

  //   try {
  //     console.log('⭐️ ~ handleCall ~ token:', token);
  //     console.log('⭐️ ~ handleCall ~ device:', device);

  //     // if (!token || (!phoneNumber && !scheduleNumber)) {
  //     //   throw new Error('Invalid token or phone number');
  //     // }

  //     if (!device) {
  //       throw new Error('Device is not initialized');
  //     }

  //     console.log('⭐️ ~ handleCall ~ currentUser?.phone:', currentUser?.phone);

  //     const params: CallParams = {
  //       // To: scheduleNumber ? scheduleNumber : countryCode + phoneNumber,
  //       // To: scheduleNumber ? scheduleNumber : '+919427420518',
  //       To: contactData?.contactNumber,
  //       From: currentUser?.phone,
  //       intent: 'call-out',
  //     };
  //     console.log('⭐️ ~ handleCall ~ params:', params);

  //     const outBound = await device.connect({ params });

  //     // const callSids = {
  //     //   caller: outBound?.parameters.CallSid,
  //     // };
  //     // console.log('⭐️ ~ handleCall ~ callSids:', callSids);

  //     dispatch(setOutgoingCall(outBound));
  //     dispatch(
  //       setOutgoingCallDetails({
  //         name: contactData?.contactName,
  //         companyName: contactData?.customerName || contactData?.carrierName,
  //         role: contactData?.role,
  //         image: contactData?.contactImageUrl + contactData?.contactImage,
  //         number: contactData?.contactNumber,
  //         carrierId: contactData?.carrierName != null && contactData?.contactId,
  //         customerId:
  //           contactData?.customerName != null && contactData?.contactId,
  //       })
  //     );

  //     setTimeout(async () => {
  //       await addCallLog({
  //         contactId: contactData?.contactId,
  //         fromUserId: currentUser?.userId,
  //         fromPhoneNumber: currentUser?.phone,
  //         toPhoneNumber: contactData?.contactNumber,
  //         callType: 'outbound',
  //         status: 'started',
  //         // callSids: JSON.stringify(callSids),
  //       });
  //     }, 1000);

  //     // if (scheduleNumber) {
  //     //   setIsScheduleCall(false);
  //     //   setScheduleCallTime('');
  //     //   setIsScheduleCallNumber('');
  //     // }
  //     // dispatch(setIsCallInProgress(true));

  //     outgoingCall?.on('disconnect', (e: any) => {
  //       console.log('Call disconnected', e, outgoingCall);
  //       dispatch(setIsCallInProgress(false));
  //       dispatch(setCallDuration(0));
  //       dispatch(setOnHold(false));
  //       dispatch(setIsMuted(false));
  //       dispatch(setHoldStatus(false));
  //       dispatch(setIsRecording(true));
  //       dispatch(setOutgoingCall(null));
  //     });
  //   } catch (err: any) {
  //     setErrorMessage(
  //       err instanceof Error ? err.message : 'An unknown error occurred'
  //     );
  //     console.error('Error making call:', err);
  //   }
  // };

  const handleCall = async (contactData: any) => {
    try {
      await checkAudioDevicesAndPermissions();
    } catch (error: any) {
      alert(error.message);

      return;
    }
    // console.log('⭐️ ~ handleCall ~ scheduleNumber:', scheduleNumber);
    console.log('⭐️ ~ handleCall ~ data:', contactData);

    try {
      if (!device) {
        throw new Error('Device is not initialized');
      }

      const params: CallParams = {
        // To: scheduleNumber ? scheduleNumber : countryCode + phoneNumber,
        // To: scheduleNumber ? scheduleNumber : '+919427420518',
        To: contactData?.contactNumber,
        From: currentUser?.phone,
        intent: 'call-out',
      };
      console.log('⭐️ ~ handleCall ~ params:', params);

      const outBound = await device.connect({ params });
      console.log('⭐️ ~ handleCall ~ outBound:', outBound);

      dispatch(
        setOutgoingCallDetails({
          name: contactData?.contactName,
          companyName: contactData?.customerName || contactData?.carrierName,
          role: contactData?.role,
          image: contactData?.contactImageUrl + contactData?.contactImage,
          number: contactData?.contactNumber,
          carrierId: contactData?.carrierName != null && contactData?.contactId,
          customerId:
            contactData?.customerName != null && contactData?.contactId,
        })
      );

      outBound.on('accept', (connection: any) => {
        console.log('⭐️ ~ handleCall ~ Connection accepted by callee');
        const callSid = connection.parameters.CallSid;

        const callSids = {
          caller: outBound?.parameters.CallSid,
        };
        console.log('⭐️ ~ handleCall ~ callSids:', callSids);

        console.log('⭐️ ~ handleCall ~ Call SID:', callSid);

        setTimeout(async () => {
          await addCallLog({
            contactId: contactData?.contactId,
            fromUserId: currentUser?.id,
            fromPhoneNumber: currentUser?.phone,
            toPhoneNumber: contactData?.contactNumber,
            callType: 'outbound',
            status: 'started',
            callerCallSid: outBound?.parameters.CallSid,
          });
          console.log('⭐️ ~ handleCall ~ callInstance:', outgoingCall);
        }, 1000);

        dispatch(setOutgoingCall(outBound));
      });

      // if (scheduleNumber) {
      //   setIsScheduleCall(false);
      //   setScheduleCallTime('');
      //   setIsScheduleCallNumber('');
      // }
      // dispatch(setIsCallInProgress(true));

      outgoingCall?.on('disconnect', (e: any) => {
        console.log('Call disconnected', e, outgoingCall);
        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
        dispatch(setOutgoingCall(null));
      });
    } catch (err: any) {
      setErrorMessage(
        err instanceof Error ? err.message : 'An unknown error occurred'
      );
      console.error('Error making call:', err);
    }
  };

  useEffect(() => {
    setNewContactDialAction({ mode: '', data: {} });
  }, [isMakeCall]);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.on('classifyCaller', function (contact: any) {
        console.log('3. ~ classifyCaller:', contact);
        dispatch(setFromNumberContact(contact));
      });

      socketIO.on('updateCallLogs', function () {
        console.log('updateCallLogs event listen');
        console.log(currentUser?.role?.name === USER_ROLE.ADMIN);

        if (currentUser?.role?.name === USER_ROLE.ADMIN) {
          getCallLogsData(false, false);
        }
      });
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('classifyCaller');
        socketIO?.off('updateCallLogs');
      }
    };
  }, [isSocketConnected]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getCallLogsData(signal);

    return () => {
      controller.abort();
    };
  }, [isCallInProgress, callLogParams, incomingCall, outgoingCall]);

  const rawHeadCells = [
    { id: 'contacts', name: 'Contacts', sortable: true },
    // { id: 'user', name: 'User', sortable: true },
    { id: 'account', name: 'Account', sortable: true },
    { id: 'call_type', name: 'Call Type', sortable: true },
    { id: 'duration', name: 'Duration', sortable: true },
    { id: 'date', name: 'Date', sortable: true },
    { id: 'tags', name: 'Tags', sortable: true },
    { id: 'action', name: '', sortable: true },
  ];

  const headCells =
    currentUser?.role?.name === 'sales'
      ? rawHeadCells.filter((filter: any) => filter.id !== 'user')
      : rawHeadCells;

  const addParticipantInConference = async (
    conferenceId: string,
    fromNumber: string
    // callLogId: string
  ) => {
    try {
      console.log(
        '1. ⭐️ ~ addParticipantInConference ~ conferenceId:',
        conferenceId
      );
      // Add Admin/Manager into call
      await addParticipantInConf({
        newParticipantNumber: currentUser?.phone,
        conferenceSid: conferenceId,
        fromNumber: fromNumber,
        // callLogId,
      }).then((res: any) => {
        console.log(
          '⭐️⭐️ ~ addParticipantInConference ~ res?.data:',
          res?.data
        );
        console.log(
          '⭐️⭐️ ~ addParticipantInConference ~ res?.data?.supervisorSid:',
          res?.data?.supervisorSid
        );
        dispatch(setSupervisorSid(res?.data?.supervisorSid));
        dispatch(setAgentCallSid(res?.data?.agentCallSid));
      });

      console.log(`2. Admin / Manager successfully added into the conference`);
    } catch (error) {
      dispatch(
        setErrorMessage(
          error instanceof Error
            ? error.message
            : 'An error occurred during the call transfer.'
        )
      );
      console.error('Error transferring call:', error);
    }
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            title={'Call Activity'}
            desc={`Review detailed records of incoming and outgoing calls for
                performance insights and follow-ups.`}
            rightClassName="sm:w-auto"
            rightSideContent={
              <div className="relative">
                <ButtonCmp
                  className="btn-outline-primary ml-3 hidden"
                  icon={<PhoneCall01 className="w-4 h-4" />}
                  onClick={() => {
                    setIsMultipleCalls(true);
                  }}
                >
                  Make a Multiple Calls
                </ButtonCmp>
                <ButtonCmp
                  className="btn-outline-primary"
                  icon={<PhoneCall01 className="w-4 h-4" />}
                  onClick={() => {
                    setIsMakeCall(true);
                  }}
                >
                  Make a Call
                </ButtonCmp>
                {isMakeCall && (
                  <MakeACall
                    onOutsideClick={handleOutsideClick}
                    setIsMakeCall={setIsMakeCall}
                    setAction={setAction}
                    action={action}
                    newContactDial={newContactDialAction}
                  />
                )}
              </div>
            }
          />
        }
      >
        <div className="w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col  flex-1">
          <div className="table-top-header">
            <div className="table-left-wrap sm:w-auto w-full">
              <div className="table-title-wrap">
                <h5 className="table-title">Call Activity</h5>
                <BadgeCmp style="modern" type="success">
                  {total} {total <= 1 ? 'Call Log' : 'Call Logs'}
                </BadgeCmp>
              </div>
              <p className="table-subtitle">
                Detailed records of Call Activity
              </p>
            </div>
            {/* <ButtonCmp
              className="btn-outline-primary"
              onClick={() => {
                setIsMakeCall(true);
              }}
            >
              <Plus className="text-primary w-4 h-4" />
              Make a Call
            </ButtonCmp> */}
          </div>
          <div className="table-bottom-header">
            <div className="table-header-bottom-left">
              <TabButton
                className="table-tab max-xxl:!px-3"
                activeClassName="!bg-utilityGray100"
                tabArray={filteredArray}
                parentClassName="table-tabs"
                isActive={callLogParams.status}
                handleOnClick={(e: any) => {
                  setCallLogParams((old: any) => ({
                    ...old,
                    status: e?.target?.dataset?.value,
                  }));
                }}
              />
            </div>
            <InputText
              inputName="searchQuote"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={callLogParams.search}
              inputType="text"
              onChangeFunc={(e) => {
                setCallLogParams((old: any) => ({
                  ...old,
                  ...{ search: e.target.value, page: 1 },
                }));
              }}
              parentClassName="table-searchInput"
              isClearable={true}
            />
            <div className="table-statustbox">
              <SelectBox
                name="filters"
                id="filters"
                className="form_control"
                size="sm"
                placeholder="Account Type"
                isClearable={true}
                isSearchable={true}
                options={filterType}
                onChangeFunc={(event: any) =>
                  setCallLogParams((old: any) => ({
                    ...old,
                    ...{ type: event?.value, page: 1 },
                  }))
                }
                value={filterType.filter(
                  (val: any) => callLogParams.type === val.value
                )}
              />
            </div>
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setCallLogParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === callLogParams.limit
                )}
              />
            </div>
          </div>
          <div className="h-full lg:min-h-[122px] w-full border-t border-gray100 flex flex-col ">
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
              <TableCmp
                headCells={headCells}
                tableDataArr={callLogs}
                TableRowCmp={CallLogRow}
                tableRowCmpProps={{
                  handleCall: handleCall,
                  setAction: setAction,
                  action: action,
                }}
                TableLoaderRowCmp={CallLogLoaderRow}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={15}
                tableHeaderClass=""
                isTableRowClickable={true}
                onRowClick={async (data: any) => {
                  console.log('1. ⭐️ ~ onRowClick={ ~ data:', data);
                  dispatch(setAdminJoiningACall(!adminJoiningACall));

                  // setIsClickable(
                  //   data?.status != 'ongoing' &&
                  //     currentUser?.role?.name === 'admin'
                  // );

                  if (
                    data.status === 'ongoing' &&
                    currentUser?.role?.name === 'admin'
                  ) {
                    const bargeWhisperData = {
                      callLogId: data.id,
                      type: 'barge',
                      userId: currentUser?.userId,
                      phoneNumber: currentUser?.phone,
                      status: 'started',
                    };

                    await addBargeWhisper(bargeWhisperData);
                    dispatch(setBargeWhisperNumber(currentUser?.phone));

                    console.log('2. ⭐️ ~ onRowClick if con is true');
                    console.log('⭐️ ~ onRowClick={ ~ data?.id:', data?.id);
                    dispatch(setCallLogId(data?.id));
                    dispatch(setBargeWhisperConferenceId(data?.conferenceId));

                    await addParticipantInConference(
                      data?.conferenceId,
                      data?.userPhoneNumber
                      // data?.id
                    );
                    const bargeWhisperCallDetails = {
                      agentName: data?.contactName,
                      agentImage: data.contactImageUrl + data.contactImage,
                      customerName: `${data?.userFirstName} ${data?.userLastName}`,
                      customerImage: data.userImageUrl + data.userImage,
                      conferenceId: data?.conferenceId,
                      agentCallSid: data?.agentCallSid,
                    };
                    dispatch(setIsBargeWhisper(true));
                    dispatch(
                      setBargeWhisperCallDetails(bargeWhisperCallDetails)
                    );
                  }
                }}
              />
            </div>
            {!isLoading && !callLogs.length && (
              <NotFoundUI
                title="No Call Logs Found"
                desc="There are no call logs."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
          </div>{' '}
          <div className="full bg-white rounded-b-xl">
            <CustomPagination
              recordsPerPage={callLogParams.limit}
              totalRecords={total}
              currentPage={callLogParams.page}
              handlePagination={(page: number) => {
                setCallLogParams((old: any) => ({ ...old, page }));
              }}
            />
          </div>
        </div>
      </PageSectionLayout>
      {isMultipleCalls && <ActiveCallModal />}
      {(action.mode === 'addNewContact' ||
        action.mode === 'editContact' ||
        action.mode === 'addNewContactDial') && (
        <ContactModal
          action={action}
          setAction={setAction}
          contactData={action?.data}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

export default CallLogs;
