import {
  ArrowDown,
  ArrowUp,
  InfoCircle,
  ArrowUpRight,
} from '@untitled-ui/icons-react/build/cjs';
import React, { FC } from 'react';
import TooltipCmp from 'src/components/TooltipCmp';

export interface IKpiCard {
  headerTitle: string;
  contentLable: string;
  contentDescription?: string;
  growth: any;
  fromDate: any;
  secondaryContentLabel?: string;
  secondaryContentDescription?: string;
  signIcon?: any;
  growthDescription?: string;
  isActive?: boolean;
  isAnalytics?: boolean;
  isLoading?: boolean;
  tooltipText?: string;
  handleChart?: () => void;
}

const KpiCard: FC<IKpiCard> = ({
  signIcon,
  headerTitle,
  contentLable,
  growth,
  fromDate,
  isLoading = false,
  tooltipText,
  handleChart,
}) => (
  // !isLoading ? (
  <>
    <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)]  lg:min-w-[calc(50%_-_12px)] min-w-full bg-white text-grayLight900 border border-utilityGray200 rounded-xl shadow-sm transition-all hover:shadow-lg flex-1 xxl:p-5 xl:p-3 p-4">
      <div className="">
        <div className="w-full justify-between flex items-start">
          <h2 className="text-grayLight900 xxl:text-sm xl:text-[11px] text-xs font-medium xxl:pr-4 pr-2">
            {headerTitle}
            {tooltipText && (
              <div className="inline-block align-middle -mt-0.5">
                <TooltipCmp message={tooltipText}>
                  <InfoCircle className="text-grayText h-4 w-4 ml-1" />
                </TooltipCmp>
              </div>
            )}
          </h2>

          <div
            className="flex text-[11px] items-center text-primary font-semibold flex-none cursor-pointer"
            onClick={handleChart}
          >
            View Split <ArrowUpRight className="h-4 w-4" />
          </div>
        </div>
        <div
          className={`mt-4 ${isLoading ? 'custom-loading w-32 truncate' : ''}`}
        >
          <span className={`font-semibold xxl:text-3xl text-2xl `}>
            {contentLable}
          </span>
          <sup
            className={`text-sm font-semibold leading-tight xxl:-top-[0.7em]  `}
          >
            {signIcon}
          </sup>
        </div>
        <div
          className={`w-fit flex justify-center items-center mt-2 ${
            isLoading ? 'custom-loading' : ''
          }`}
        >
          {growth >= 0 ? (
            <ArrowUp className="w-4 h-4 text-green500 flex-none " />
          ) : (
            <ArrowDown className="w-4 h-4 flex-none  text-danger" />
          )}
          <p className="text-grayLight600 font-medium xxl:text-xs xl:text-[11px] text-xs">
            <span className="xxl:text-sm text-xs">
              {`${Math.abs(growth)}%`}
            </span>
            &nbsp;{`vs. ${fromDate}`}
          </p>
        </div>
      </div>
    </div>
  </>
);
// ) : (
//   <h1>loading...</h1>
// );
export default KpiCard;
