import {
  Copy04,
  Eye,
  Plus,
  Trash01,
  ChevronDown,
  ChevronUp,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState, useRef } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { getFreightClass } from 'src/services/QuoteService';
import {
  getLabelByValue,
  formatNumberWithCommas,
  calculateLinearFootage,
  getRoundUpLinearFootage,
} from 'src/utils/CommonFunctions';

import { addItems, duplicateItems, removeItems } from '../../Quote.const';

const Items = (props: any) => {
  const [isFreightClassLoading, setIsFreightClassLoading] = useState([
    { isLoading: false },
  ]);
  const [openItems, setOpenItems] = useState(new Set());
  const isInitialMount = useRef(true);

  const [isCalculateFreightClass, setIsCalculateFreightClass] = useState(false);

  const {
    formData,
    validForm,
    initFormData,
    initValidForm,
    handlingUnitOptions,
    isHandlingUnitLoading,
    setFormData,
    setValidForm,
    lengthMeasurement,
    weightMeasurement,
    currentItem,
    setCurrentItem,
    setIsShowVisualUnit,
    setIsValid,
    isViewQuote = false,
    isTotalWeightManuallyChanged,
    setTotalDimensionsWeight,
    invalidIndexes,
    validateMeasurement,
    setIsPrimaryBtnDisabled,
    isLinearFootageManuallyChanged,
    setQuoteFormData,
  } = props;
  const [prevLengthMeasurement, setPrevLengthMeasurement] =
    useState(lengthMeasurement);

  const addItemFunction = () => {
    addItems(
      setFormData,
      setValidForm,
      setIsFreightClassLoading,
      setOpenItems,
      formData,
      initFormData,
      initValidForm
    );
  };

  const duplicateItemsFunction = () => {
    duplicateItems(
      formData,
      setFormData,
      validForm,
      setValidForm,
      setOpenItems,
      initFormData,
      initValidForm
    );
  };

  const removeItemsFunction = (id: any) => () => {
    removeItems(
      id,
      formData,
      setFormData,
      initFormData,
      setValidForm,
      initValidForm
    );
  };

  useEffect(() => {
    if (
      !isInitialMount.current &&
      formData?.dimensions?.length > 0 &&
      !isLinearFootageManuallyChanged
    ) {
      const newLinearFootage =
        getRoundUpLinearFootage(calculateLinearFootage(formData.dimensions)) ||
        undefined;

      setQuoteFormData((old: any) => ({
        ...old,
        linearFootage: newLinearFootage,
        addressDetail: {
          ...old.addressDetail,
          linearFootage: newLinearFootage,
        },
      }));

      setFormData((old: any) => ({
        ...old,
        linearFootage: newLinearFootage,
      }));

      if (newLinearFootage) {
        setValidForm((old: any) => ({
          ...old,
          linearFootage: true,
        }));
      }
    }
  }, [
    JSON.stringify(
      formData?.dimensions?.map((item: any) => ({
        handlingUnitNo: item.handlingUnitNo,
        width: item.width,
        height: item.height,
        itemLength: item.itemLength,
        isStack: item.isStack,
      }))
    ),
  ]);

  useEffect(() => {
    isInitialMount.current = false;
  }, []);

  useEffect(() => {
    const totalDimensionsWeight = formData?.dimensions.reduce(
      (sum: any, item: any) => +sum + (+item.totalWeight || 0),
      0
    );

    setTotalDimensionsWeight(totalDimensionsWeight || undefined);

    if (!isTotalWeightManuallyChanged) {
      if (formData?.dimensions?.length > 0) {
        if (totalDimensionsWeight > 0) {
          setFormData((old: any) => ({
            ...old,
            totalWeight: totalDimensionsWeight,
          }));
        } else if (formData.totalWeight && formData.totalWeight != '') {
          setFormData((old: any) => ({
            ...old,
            totalWeight: undefined,
          }));
        }
      } else {
        if (formData.totalWeight && formData.totalWeight != '') {
          setFormData((old: any) => ({
            ...old,
            totalWeight: undefined,
          }));
        }
      }
    }
  }, [formData?.dimensions, isTotalWeightManuallyChanged]);

  const handleToggle = (index: number) => (event: any) => {
    const { name, checked } = event.target;
    setFormData((old: any) => {
      const oldData = [...old.dimensions];
      const commodity = {
        commodityId: oldData[index].commodityId,
        sub: oldData[index].sub,
        description: oldData[index].description,
      };

      if (name === 'isCommodity' && !checked) {
        commodity.commodityId = null;
        commodity.sub = null;
        commodity.description = null;
      }
      oldData[index] = {
        ...oldData[index],
        ...commodity,
        [name]: checked,
      };

      return {
        ...old,
        dimensions: oldData,
      };
    });
  };

  const handleSelectChange = (index: number, name: string) => (event: any) => {
    const value = event?.value;
    setFormData((old: any) => {
      const oldData = [...old.dimensions];

      oldData[index] = {
        ...oldData[index],
        [name]: value,
      };

      if (value) {
        setValidForm((oldValidFormVal: any) => {
          const oldValidData = [...oldValidFormVal.dimensions];

          oldValidData[index] = {
            ...oldValidData[index],
            [name]: {
              ...oldValidData[index][name],
              required: Boolean(oldData[index][name]),
            },
          };

          return {
            ...old,
            dimensions: oldValidData,
          };
        });
      }

      return {
        ...old,
        dimensions: oldData,
      };
    });
  };

  const handleInputChange = (index: number) => (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;

    if (type === 'number') {
      value = value ? parseFloat(value) : eventType === 'blur' ? 0 : value;
    }

    setFormData((old: any) => {
      const oldData = [...old.dimensions];
      let oldValue = oldData[index]?.[name];

      let totalWeight = oldData[index].totalWeight;
      let weight = oldData[index].weight;
      let lineItemPerUnit = oldData[index].handlingUnitNo;

      if (
        name !== 'description' &&
        (isNaN(value) || value === null || value === undefined)
      ) {
        value = 0;
      }

      if (name === 'weight') {
        if (lineItemPerUnit > 0) {
          totalWeight = value * lineItemPerUnit;
        } else {
          totalWeight = value;
        }
        weight = value;
      } else if (name === 'handlingUnitNo') {
        if (value > 0) {
          if (totalWeight > 0) {
            weight = totalWeight / value;
          }
        } else {
          totalWeight = 0;
          weight = 0;
        }
      } else if (name === 'totalWeight') {
        if (lineItemPerUnit > 0) {
          weight = value / lineItemPerUnit;
        } else {
          weight = value;
        }
        totalWeight = value;
      }

      if (isNaN(totalWeight) || !isFinite(totalWeight)) {
        totalWeight = 0;
      }

      if (isNaN(weight) || !isFinite(weight)) {
        weight = 0;
      }

      oldData[index] = {
        ...oldData[index],
        [name]: value,
        totalWeight,
        weight,
      };

      if (value) {
        setValidForm((oldValidFormVal: any) => {
          const oldValidData = [...(oldValidFormVal?.dimensions ?? [])];

          oldValidData[index] = {
            ...oldValidData[index],
            [name]: {
              ...oldValidData[index][name],
              ...{
                required: Boolean(oldData[index][name]),
                valid: Boolean(oldData[index][name]),
              },
            },
          };

          if (name === 'weight' && value) {
            oldValidData[index] = {
              ...oldValidData[index],
              totalWeight: {
                ...oldValidData[index].totalWeight,
                required: true,
                valid: true,
              },
            };
          } else if (name === 'totalWeight' && value) {
            oldValidData[index] = {
              ...oldValidData[index],
              weight: {
                ...oldValidData[index].weight,
                required: true,
                valid: true,
              },
            };
          }

          return {
            ...oldValidFormVal,
            dimensions: oldValidData,
          };
        });
      }

      if (
        (name === 'itemLength' ||
          name === 'height' ||
          name === 'weight' ||
          name === 'totalWeight' ||
          name === 'width') &&
        value !== oldValue &&
        value <= 100000
      ) {
        setIsCalculateFreightClass(true);
      } else {
        setIsCalculateFreightClass(false);
      }

      return {
        ...old,
        dimensions: oldData,
      };
    });
  };

  const checkFreightClass = (index: number) => async (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;
    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const oldData = [...formData.dimensions];
    let freightClass = oldData[index].freightClass;
    let itemClassForDayross = oldData[index].itemClassForDayross;
    let weight = oldData[index].weight;
    let itemLength = oldData[index].itemLength;
    let width = oldData[index].width;
    let height = oldData[index].height;

    if (weight && itemLength && width && height) {
      const updatedArray = [...isFreightClassLoading];
      updatedArray[index] = { isLoading: true };
      setIsFreightClassLoading(updatedArray);

      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...(oldValidFormVal?.dimensions ?? [])];
        oldValidData[index] = {
          ...oldValidData[index],
          freightClass: {
            ...oldValidData[index].freightClass,
            required: true,
            valid: true,
          },
        };

        return {
          ...oldValidFormVal,
          dimensions: oldValidData,
        };
      });

      let freightData = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: lengthMeasurement,
        weightUnit: weightMeasurement,
      })
        .then((data: any) => data?.data || 0)
        .catch(() => false)
        .finally(() => {
          const updatedArr = [...isFreightClassLoading];
          updatedArr[index] = { isLoading: false };
          setIsFreightClassLoading(updatedArr);
          setIsCalculateFreightClass(false);
        });

      freightClass = freightData?.freightClass;
      itemClassForDayross = freightData?.itemClassForDayross;
    } else {
      freightClass = 0;
    }

    oldData[index] = {
      ...oldData[index],
      [name]: value,
      freightClass,
      itemClassForDayross,
    };
    setFormData((old: any) => ({
      ...old,
      dimensions: oldData,
    }));
  };

  useEffect(() => {
    if (currentItem.data) {
      setIsShowVisualUnit(true);
    }
  }, [currentItem]);

  useEffect(() => {
    let valid = isFreightClassLoading.some((item) => item?.isLoading);
    setIsValid(!valid);
  }, [isFreightClassLoading]);

  const handleUnitChange = async () => {
    const newFormData = await Promise.all(
      formData?.dimensions.map(async (form: any, index: number) => {
        const oldData = [...formData.dimensions];
        let freightClass = form.freightClass;
        let itemClassForDayross = form.itemClassForDayross;
        let weight = form.weight;
        let itemLength = form.itemLength;
        let width = form.width;
        let height = form.height;

        if (weight && itemLength && width && height) {
          const updatedArray = [...isFreightClassLoading];
          updatedArray[index] = { isLoading: true };
          setIsFreightClassLoading(updatedArray);

          let freightData = await getFreightClass({
            itemLength: itemLength || 0,
            width: width || 0,
            height: height || 0,
            weight: weight || 0,
            lengthUnit: lengthMeasurement,
            weightUnit: weightMeasurement,
          })
            .then((data: any) => data?.data || 0)
            .catch(() => false)
            .finally(() => {
              const updatedArr = [...isFreightClassLoading];
              updatedArr[index] = { isLoading: false };
              setIsFreightClassLoading(updatedArr);
            });
          freightClass = freightData?.freightClass;
          itemClassForDayross = freightData?.itemClassForDayross;
        } else {
          freightClass = 0;
        }

        oldData[index] = {
          ...oldData[index],
          freightClass,
          itemClassForDayross,
        };

        return oldData[index];
      })
    );

    setFormData((old: any) => ({
      ...old,
      dimensions: newFormData,
    }));
  };

  useEffect(() => {
    if (prevLengthMeasurement !== lengthMeasurement) handleUnitChange();
    setPrevLengthMeasurement(lengthMeasurement);
  }, [lengthMeasurement]);

  const toggleDetails = (index: any) => {
    const newOpenItems = new Set(openItems);
    setOpenItems((prev) => {
      if (!prev.has(index)) {
        newOpenItems.add(index);
      } else {
        newOpenItems.delete(index);
      }

      return newOpenItems;
    });
  };

  return (
    <>
      <div className="space-y-3">
        {formData?.dimensions?.length > 0 &&
          formData?.dimensions?.map((form: any, index: number) => (
            <>
              <div
                key={index}
                className="rounded-lg border border-gray100 bg-gray25 p-3 space-y-1"
              >
                <>
                  <div className="space-y-4">
                    <div
                      className="flex justify-between items-center gap-x-2.5 cursor-pointer pb-2 border-b border-gray100"
                      onClick={() => toggleDetails(index)}
                    >
                      <h6 className="text-xs">Line Item {index + 1}</h6>
                      <div className="flex gap-x-2.5">
                        {invalidIndexes.includes(index) ||
                        openItems.has(index) ? (
                          <>
                            <h6 className="text-primary text-xs font-medium">
                              View Less
                            </h6>
                            <ChevronUp className="h-4 w-4" />
                          </>
                        ) : (
                          <>
                            <h6 className="text-primary text-xs font-medium">
                              View More
                            </h6>
                            <ChevronDown className="h-4 w-4" />
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-wrap -m-1">
                        <SelectBox
                          parentClassName="relative w-1/3 p-1"
                          label="Handling unit"
                          labelClassName="form_label block mb-1.5"
                          isSearchable
                          name="handlingUnit"
                          isLoading={isHandlingUnitLoading}
                          id="handlingUnit"
                          isDisabled={isViewQuote}
                          className="form_control"
                          classComp="mt-0"
                          options={handlingUnitOptions}
                          onChangeFunc={handleSelectChange(
                            index,
                            'handlingUnit'
                          )}
                          value={handlingUnitOptions.find(
                            (handlingUnitOption: any) =>
                              handlingUnitOption.value ===
                                parseInt(form.handlingUnit) || ''
                          )}
                          errorText={
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.handlingUnit
                                  ?.valid
                                ? true
                                : false
                              : false
                          }
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label="№ of handling units"
                          labelClassName="block mb-1.5"
                          inputType="number"
                          inputName="handlingUnitNo"
                          min="0"
                          isLoading={false}
                          disabled={isViewQuote}
                          className={` ${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.handlingUnitNo
                                  .required ||
                                !validForm?.dimensions?.[index]?.handlingUnitNo
                                  .valid
                                ? 'border border-red-500 border-solid'
                                : ''
                              : ''
                          }`}
                          placeholder="0"
                          shouldFormatNumber={true}
                          value={form.handlingUnitNo || ''}
                          onChangeFunc={handleInputChange(index)}
                          onBlur={handleInputChange(index)}
                          isShowDisableClass={false}
                          required
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label={`Total weight (${getLabelByValue(
                            weightMeasurement
                          )?.toLowerCase()})`}
                          labelClassName="form_label block mb-1.5"
                          inputName="totalWeight"
                          value={form?.totalWeight || ''}
                          isLoading={false}
                          inputType="number"
                          className={`${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.totalWeight
                                  .required ||
                                !validForm?.dimensions?.[index]?.totalWeight
                                  .valid
                                ? 'border border-red-500 border-solid'
                                : ''
                              : ''
                          }`}
                          placeholder="0"
                          onChangeFunc={handleInputChange(index)}
                          onBlur={(e: any) => {
                            setIsPrimaryBtnDisabled(false);

                            if (isCalculateFreightClass)
                              checkFreightClass(index)(e);
                          }}
                          onFocus={() => {
                            setIsPrimaryBtnDisabled(true);
                          }}
                          disabled={isViewQuote}
                          shouldFormatNumber={true}
                          inputValueUnitType={getLabelByValue(
                            weightMeasurement
                          )?.toLowerCase()}
                          errorText={
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.totalWeight
                                  ?.valid
                                ? `Max allow weight: ${formatNumberWithCommas(
                                    validateMeasurement.maxTotalWeight
                                  )}`
                                : null
                              : null
                          }
                          required
                          isShowDisableClass={false}
                        />
                      </div>
                    </div>
                  </div>
                  {invalidIndexes.includes(index) || openItems.has(index) ? (
                    <div>
                      <div className="flex flex-wrap -m-1">
                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label={
                            <>
                              Length (
                              {getLabelByValue(
                                lengthMeasurement
                              )?.toLowerCase()}
                              )
                            </>
                          }
                          labelClassName="form_label block mb-1.5"
                          inputType="number"
                          inputName="itemLength"
                          disabled={isViewQuote}
                          min="0"
                          className={`${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.itemLength
                                  ?.required
                                ? 'border border-red-500 border-solid'
                                : !validForm?.dimensions?.[index]?.itemLength
                                    ?.valid &&
                                  'border border-red-500 border-solid'
                              : ''
                          }`}
                          isLoading={false}
                          placeholder="0"
                          shouldFormatNumber={true}
                          inputValueUnitType={getLabelByValue(
                            lengthMeasurement
                          )?.toLowerCase()}
                          value={form.itemLength || ''}
                          onChangeFunc={handleInputChange(index)}
                          onBlur={(e: any) => {
                            if (isCalculateFreightClass)
                              checkFreightClass(index)(e);
                          }}
                          required
                          isShowDisableClass={false}
                          errorText={
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.itemLength
                                  ?.valid
                                ? `Max allow length: ${validateMeasurement.length}`
                                : null
                              : null
                          }
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label={
                            <>
                              Width (
                              {getLabelByValue(
                                lengthMeasurement
                              )?.toLowerCase()}
                              )
                            </>
                          }
                          labelClassName="form_label block mb-1.5"
                          inputType="number"
                          inputName="width"
                          min="0"
                          disabled={isViewQuote}
                          isLoading={false}
                          className={`${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.width?.required
                                ? 'border border-red-500 border-solid'
                                : !validForm?.dimensions?.[index]?.width
                                    ?.valid &&
                                  'border border-red-500 border-solid'
                              : ''
                          }`}
                          placeholder="0"
                          shouldFormatNumber={true}
                          inputValueUnitType={getLabelByValue(
                            lengthMeasurement
                          )?.toLowerCase()}
                          value={form.width || ''}
                          onChangeFunc={handleInputChange(index)}
                          onBlur={(e: any) => {
                            if (isCalculateFreightClass)
                              checkFreightClass(index)(e);
                          }}
                          errorText={
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.width?.valid
                                ? `Max allow width: ${validateMeasurement.width}`
                                : null
                              : null
                          }
                          required
                          isShowDisableClass={false}
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label={
                            <>
                              Height (
                              {getLabelByValue(
                                lengthMeasurement
                              )?.toLowerCase()}
                              )
                            </>
                          }
                          isLoading={false}
                          labelClassName="form_label block mb-1.5"
                          inputType="number"
                          inputName="height"
                          disabled={isViewQuote}
                          min="0"
                          className={`${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.height
                                  ?.required
                                ? 'border border-red-500 border-solid'
                                : !validForm?.dimensions?.[index]?.height
                                    ?.valid &&
                                  'border border-red-500 border-solid'
                              : ''
                          }`}
                          placeholder="0"
                          shouldFormatNumber={true}
                          inputValueUnitType={getLabelByValue(
                            lengthMeasurement
                          )?.toLowerCase()}
                          value={form.height || ''}
                          onChangeFunc={handleInputChange(index)}
                          onBlur={(e: any) => {
                            if (isCalculateFreightClass)
                              checkFreightClass(index)(e);
                          }}
                          required
                          isShowDisableClass={false}
                          errorText={
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.height?.valid
                                ? `Max allow height: ${validateMeasurement.height}`
                                : null
                              : null
                          }
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label={
                            <>
                              Unit Weight (
                              {getLabelByValue(
                                weightMeasurement
                              )?.toLowerCase()}
                              )
                            </>
                          }
                          labelClassName="form_label block mb-1.5"
                          inputType="number"
                          inputName="weight"
                          min="0"
                          disabled={isViewQuote}
                          isLoading={false}
                          className={`${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.weight
                                  ?.required
                                ? 'border border-red-500 border-solid'
                                : !validForm?.dimensions?.[index]?.weight
                                    ?.valid &&
                                  'border border-red-500 border-solid'
                              : ''
                          }`}
                          placeholder="0"
                          value={form.weight || ''}
                          shouldFormatNumber={true}
                          inputValueUnitType={getLabelByValue(
                            weightMeasurement
                          )?.toLowerCase()}
                          onChangeFunc={handleInputChange(index)}
                          onBlur={(e: any) => {
                            if (isCalculateFreightClass) {
                              checkFreightClass(index)(e);
                            }
                          }}
                          required
                          isShowDisableClass={false}
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label={
                            <div className="flex">
                              Freight Class
                              <span
                                className={`text-red-600 ms-[2px] leading-4 text-xs `}
                              >
                                *
                              </span>
                              {isFreightClassLoading[index]?.isLoading && (
                                <p
                                  className={`loading loading-spinner text-gray-600 h-4 w-4 ml-2 }`}
                                ></p>
                              )}
                            </div>
                          }
                          isLoading={false}
                          labelClassName="form_label block mb-1.5"
                          inputName="freightClass"
                          inputType="number"
                          disabled={isViewQuote}
                          min={0}
                          value={form.freightClass || ''}
                          placeholder="0"
                          onChangeFunc={handleInputChange(index)}
                          className={`${
                            validForm?.dimensions?.[index]
                              ? !validForm?.dimensions?.[index]?.freightClass
                                  .required
                                ? 'border border-red-500 border-solid'
                                : !validForm?.dimensions?.[index]?.freightClass
                                    .valid &&
                                  'border border-red-500 border-solid'
                              : ''
                          }`}
                          shouldFormatNumber={true}
                          isShowDisableClass={false}
                        />

                        <InputText
                          parentClassName="relative w-1/3 p-1"
                          label="Description"
                          labelClassName="form_label block mb-1.5"
                          inputName="description"
                          inputType="text"
                          isLoading={false}
                          placeholder="Enter description"
                          disabled={isViewQuote}
                          value={form.description || ''}
                          onChangeFunc={handleInputChange(index)}
                          onBlur={handleInputChange(index)}
                          isShowDisableClass={false}
                          shouldFormatNumber={false}
                        />
                      </div>
                      <div className="flex justify-between items-center mt-4 w-full">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name="isStack"
                            className="sr-only peer"
                            checked={form.isStack}
                            onChange={handleToggle(index)}
                            disabled={isViewQuote}
                          />
                          <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-primary"></div>
                          <span className="ml-2 text-xs font-medium text-black ">
                            Do not stack
                          </span>
                        </label>
                        <div className="flex gap-1">
                          <TooltipCmp message="Visualize Unit">
                            <div className="delete cursor-pointer p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary">
                              <Eye
                                className="w-4 h-4"
                                onClick={() => {
                                  setCurrentItem({
                                    ...{ index: index, data: form },
                                  });
                                }}
                              />
                            </div>
                          </TooltipCmp>
                          {!isViewQuote && (
                            <div
                              className={`delete p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer`}
                            >
                              <Trash01
                                className="w-4 h-4"
                                onClick={removeItemsFunction(index)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              </div>
            </>
          ))}
      </div>
      {!isViewQuote && (
        <div className="flex flex-wrap gap-2 mt-4">
          <ButtonCmp
            type="submit"
            className="btn-outline-primary flex-1"
            onClick={addItemFunction}
            icon={<Plus className="w-4 h-4" />}
          >
            Add Line Item
          </ButtonCmp>
          <ButtonCmp
            type="submit"
            className="btn_secondary_black flex-1"
            onClick={duplicateItemsFunction}
            icon={<Copy04 className="w-4 h-4" />}
          >
            Duplicate
          </ButtonCmp>
        </div>
      )}
    </>
  );
};

export default Items;
