import { ArrowUpRight, InfoCircle } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useState } from 'react';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import InputText from 'src/components/InputText/InputText';
import Map from 'src/components/Map/Map';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TrimbleMap from 'src/components/TrimbleMap/Map';
import { BasicContext } from 'src/context/BasicContext';

import AdditionalServiceModal from './AdditionalServiceModal';

const ShipperAddress = ({
  formData,
  shipperOptions,
  validForm,
  setValidForm,
  handleSelectChange,
  selectFormData,
  handleActionType,
  saveShipperDetails,
  isShipperBtnDisable,
  handleAutocompleteChange,
  handleInputChange,
  shipperAddress,
  setShipperAddress,
  isLoading,
  isShipperLoading,
  status,
  serviceTypes,
  getList,
  isShipperActivityLoading = false,
  setSearchShipper,
}: any) => {
  const { mapService } = useContext(BasicContext);
  const [
    isShowAdditionalServiceInfoModal,
    setIsShowAdditionalServiceInfoModal,
  ] = useState(false);

  return (
    <>
      <div className="rounded-xl border border-utilityGray200 bg-white shadow-md p-4 flex flex-wrap gap-4 ">
        <div className="lg:w-[65%] w-full gap-y-4 flex flex-wrap [&>div]:px-1.5 -mx-1.5">
          <SelectBox
            parentClassName={`w-1/2 ${
              isShipperActivityLoading ? 'transparent-disabled-select' : ''
            }`}
            labelWrapClassName="mb-1.5"
            labelClassName="form_label mb-0 block"
            labelRight={
              <>
                <div className="flex items-center gap-x-2.5">
                  {selectFormData.shipperId && (
                    <>
                      <ButtonCmp
                        onClick={handleActionType('shipper', 'delete')}
                        className="btn-link-secondary"
                        disabled={isShipperActivityLoading}
                      >
                        Delete
                      </ButtonCmp>
                      <p className="border border-borderPrimary h-full"></p>
                    </>
                  )}
                  <ButtonCmp
                    onClick={saveShipperDetails('shipper')}
                    disabled={isShipperBtnDisable || isShipperActivityLoading}
                    className={`btn-link-custom `}
                  >
                    {selectFormData.shipperId ? 'Update' : 'Create'}
                  </ButtonCmp>
                </div>
              </>
            }
            isClearable
            isSearchable
            onInputChange={(e: any, { action }: any) => {
              if (action === 'input-change' || action === 'input-blur') {
                setSearchShipper(e);
              }
            }}
            isLoading={isShipperLoading || isLoading}
            name="shipperId"
            label="Add a Shipper"
            className={`form_control ${
              !validForm.shipperId
                ? 'border border-red-500 rounded-[10px] mb-1 border-solid '
                : ''
            }`}
            classComp=""
            options={shipperOptions}
            onChangeFunc={handleSelectChange('shipperId')}
            value={
              formData?.shipperId
                ? shipperOptions.filter(
                    (val: any) => formData?.shipperId === val.value
                  )
                : ''
            }
            placeholder="Select a shipper address"
            errorText={!validForm.shipperId ? 'Shipper is required' : null}
            isDisabled={isShipperActivityLoading}
          />

          <InputText
            parentClassName="w-1/2"
            inputName="shipperCompanyName"
            placeholder="Enter shipper name"
            className={`form_control ${
              !validForm.shipperCompanyName
                ? 'border border-red-500 border-solid'
                : ''
            }`}
            label="Shipper Name"
            labelClassName="mb-1.5 block"
            value={formData.shipperCompanyName}
            onChangeFunc={handleInputChange}
            onKeyPress={() =>
              setValidForm((old: any) => ({
                ...old,
                shipperCompanyName: true,
              }))
            }
            disabled={isShipperActivityLoading}
            isShowDisableClass={false}
          />

          {status &&
            (mapService === 'trimble_map' ? (
              <AutoCompleteTrimble
                mapParentClass="w-full"
                labelRight={
                  <ButtonCmp
                    onClick={handleActionType(
                      'shipper',
                      formData.shipperAddress.fullAddress
                        ? 'addressUpdate'
                        : 'addressCreate'
                    )}
                    type="button"
                    className={`btn-link-custom`}
                    disabled={isShipperActivityLoading || false}
                    iconSide="right"
                    icon={<ArrowUpRight className="w-4 h-4" />}
                  >
                    View Address
                  </ButtonCmp>
                }
                inputName="shipperAddress"
                required
                placeholder="Enter shipper address"
                className={`form_control truncate ${
                  !validForm.shipperAddress
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Shipper Address"
                labelClassName="mb-1.5 block"
                isClearable
                onChangeFunc={handleAutocompleteChange('shipperAddress')}
                onBlur={handleAutocompleteChange('shipperAddress')}
                onAddressInputChange={setShipperAddress}
                value={shipperAddress}
                disabled={isShipperActivityLoading}
              />
            ) : (
              <AutoCompleteGoogle
                mapParentClass="w-full"
                labelRight={
                  <ButtonCmp
                    onClick={handleActionType(
                      'shipper',
                      formData.shipperAddress.fullAddress
                        ? 'addressUpdate'
                        : 'addressCreate'
                    )}
                    type="button"
                    className={`btn-link-custom`}
                    disabled={isShipperActivityLoading || false}
                    iconSide="right"
                    icon={<ArrowUpRight className="w-4 h-4" />}
                  >
                    View Address
                  </ButtonCmp>
                }
                inputName="shipperAddress"
                required
                placeholder="Enter shipper address"
                className={`form_control truncate ${
                  !validForm.shipperAddress
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Shipper Address"
                labelClassName="mb-1.5 block"
                isClearable
                onChangeFunc={handleAutocompleteChange('shipperAddress')}
                onBlur={handleAutocompleteChange('shipperAddress')}
                onAddressInputChange={setShipperAddress}
                value={shipperAddress}
                disabled={isShipperActivityLoading}
              />
            ))}

          <div className="space-y-1.5 w-full">
            <div className="flex items-center gap-x-1.5 mb-1.5 ">
              <h6 className="form_label block mb-0">
                Pickup Additional Services
              </h6>
              <div
                className={`cursor-pointer ${
                  isShipperLoading ? 'custom-loading' : ''
                }`}
                onClick={() => setIsShowAdditionalServiceInfoModal(true)}
              >
                <InfoCircle className="h-3.5 w-3.5 cursor-pointer text-primary" />
              </div>
            </div>
            <div className="rounded-xl border border-utilityGray200 bg-white shadow-md overflow-hidden">
              {!isShipperLoading && serviceTypes?.PICKUP?.length > 0 ? (
                getList(serviceTypes?.PICKUP)
              ) : isShipperLoading ? (
                <div className="flex flex-wrap [&>div]:px-1 -mx-1 px-2.5">
                  {Array.from({ length: 8 }).map((_, index) => (
                    <div
                      className="py-4 relative w-1/4 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:-top-px before:-left-[5vw] before:absolute"
                      key={`service_${index}`}
                    >
                      <div className="flex cursor-pointer space-x-1.5 custom-loading">
                        <CheckBox
                          id={`service_abc`}
                          onChangeFunc={() => {}}
                          name="abc"
                          value="xyz"
                          checked={false}
                          parentClassName=""
                        />
                        <div className="leading-[0.9] space-x-1.5 flex items-center truncate">
                          <label
                            htmlFor={`service_abc`}
                            className={`leading-4 text-textSecondary text-xs font-medium truncate cursor-pointer `}
                          >
                            fdgjdflkj gkldjflgdl
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NotFoundUI
                  iconClassName="!hidden"
                  title="No services found"
                  desc={`No Pickup Services found. Please try again.`}
                  containerClassName="!h-auto !my-5"
                />
              )}
            </div>
          </div>
        </div>
        <div className="lg:flex-1 w-full">
          {status &&
            (mapService === 'trimble_map' ? (
              <TrimbleMap
                className="rounded-xl bg-white !h-full"
                address={
                  formData.shipperAddress &&
                  formData.shipperAddress.fullAddress !== ''
                    ? formData.shipperAddress
                    : null
                }
                id={'shipperMap'}
                onReverseAddressFunc={handleAutocompleteChange(
                  'shipperAddress'
                )}
              />
            ) : (
              <Map
                className="rounded-xl bg-white !h-full"
                address={
                  formData.shipperAddress &&
                  formData.shipperAddress.fullAddress !== ''
                    ? formData.shipperAddress
                    : null
                }
                id={'shipperMap'}
                onReverseAddressFunc={handleAutocompleteChange(
                  'shipperAddress'
                )}
              />
            ))}
        </div>
      </div>
      {isShowAdditionalServiceInfoModal && (
        <AdditionalServiceModal
          handleClose={() => setIsShowAdditionalServiceInfoModal(false)}
          services={serviceTypes?.PICKUP}
          title="Pickup Additional Services"
          titleDesc="Explore key details of additional pickup and delivery services"
        />
      )}
    </>
  );
};

export default ShipperAddress;
