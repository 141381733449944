import { ArrowUp } from '@untitled-ui/icons-react/build/cjs';
import { ArrowDownLeft } from '@untitled-ui/icons-react/build/cjs';
import { ArrowUpRight } from 'lucide-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DialOutboundCall from 'src/app/Twilio/CallLogs/DialOutboundCall';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import LiveTimer from 'src/components/LiveTimer';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  formatDuration,
  getShortName,
  setNumberWithCommas,
  usePhone,
} from 'src/utils/CommonFunctions';

import inboundEmoji from '../../../../../assets/img/inbound-emoji.webp';

import { ILeadRowProps } from './LoadBoard.interface';

const callRateGatheredTag = ['success', 'successful', 'rate_gathered'];
const callRateNotGatheredTag = ['declined', 'error', 'rate_not_gathered'];
const callAssistantConfusedTag = ['assistant_confused'];
const loadCoveredTag = ['load_is_covered'];

const LoadBoardRateRow = ({
  data,
  setIsShowTypeInfoModal,
  setSelectedCarrierRates,
  rates,
}: ILeadRowProps) => {
  const { getFormatPhoneWithPlus } = usePhone();

  const [equipmentTypes, setEquipmentTypes] = useState<string[]>([]);

  useEffect(() => {
    if (Array.isArray(data.vehicleType)) {
      setEquipmentTypes(data.vehicleType);
    } else if (typeof data.vehicleType === 'string') {
      setEquipmentTypes(
        data.vehicleType.split(',').map((type: string) => type.trim())
      );
    } else {
      setEquipmentTypes([]);
    }
  }, [data.vehicleType]);

  const tooltipItems = (
    <div className="flex flex-wrap gap-2 max-w-[210px] px-0.5 py-1.5 w-max">
      {equipmentTypes?.slice(2)?.map((name: any) => (
        <div key={name}>
          <BadgeCmp
            mainClassName="!py-[3px] !pl-1 !pr-1.5"
            badgeTextColor="text-nowrap break-keep"
            type="pink"
            style="modern"
          >
            {name}
          </BadgeCmp>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <td className="px-5 py-4 max-w-[1px] w-[18%] min-w-[200px]">
        <div className="flex items-center">
          <div className="table-profile-customer-circle-xl rounded-md">
            {getShortName(data.company ? data.company : 'Unknown')}
          </div>
          <div className="ml-3 truncate flex-1">
            {data.company?.length > 30 ? (
              <TooltipCmp message={data.company}>
                <h6 className="text-grayLight900 font-medium leading-tight truncate">
                  {data.company.slice(0, 30)}...
                </h6>
              </TooltipCmp>
            ) : (
              <h6 className="text-grayLight900 font-medium leading-tight truncate">
                {data.company ? data.company : 'Unknown'}
              </h6>
            )}

            <div className="flex items-center truncate">
              <p className="max-w-[calc(100%_-_110px)]">
                {data.carrierContactPerson?.length > 10 ? (
                  <TooltipCmp message={data.carrierContactPerson}>
                    <p className="text-grayLight600 font-normal leading-tight truncate mr-0.5">
                      {['Not provided', 'None', 'null'].includes(
                        data.carrierContactPerson
                      )
                        ? ''
                        : `${data.carrierContactPerson?.slice(0, 10)}...`}
                    </p>
                  </TooltipCmp>
                ) : (
                  <p className="text-grayLight600 font-normal leading-tight truncate mr-0.5">
                    {['Not provided', 'None', 'null'].includes(
                      data.carrierContactPerson
                    )
                      ? ''
                      : data.carrierContactPerson}
                  </p>
                )}
              </p>
              <span>{getFormatPhoneWithPlus(data.companyPhone)}</span>
            </div>
          </div>
        </div>
      </td>
      <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
        <BadgeCmp
          style="modern"
          type={
            data.callStatus == 'Completed'
              ? callRateGatheredTag.includes(data?.callClassificationTag)
                ? 'success'
                : callRateNotGatheredTag.includes(data?.callClassificationTag)
                ? 'info'
                : callAssistantConfusedTag.includes(data?.callClassificationTag)
                ? 'yellow'
                : loadCoveredTag.includes(data?.callClassificationTag)
                ? 'success'
                : 'error'
              : data.callStatus == 'In Progress'
              ? 'primary'
              : 'error'
          }
        >
          {data.callStatus == 'Completed'
            ? callRateGatheredTag.includes(data?.callClassificationTag)
              ? 'Rate Gathered'
              : callRateNotGatheredTag.includes(data?.callClassificationTag)
              ? 'Rate Not Gathered'
              : callAssistantConfusedTag.includes(data?.callClassificationTag)
              ? 'Assistant Confused'
              : loadCoveredTag.includes(data?.callClassificationTag)
              ? 'Load Covered'
              : data.callClassificationTag
            : data.callStatus}
        </BadgeCmp>
      </td>
      <td className="px-5 py-4 max-w-[110px] w-[110px] min-w-[110px]">
        {data.callStatus == 'In Progress' ? (
          <LiveTimer startDate={data.callStartedAt} />
        ) : data.callDuration ? (
          formatDuration(parseInt(data.callDuration))
        ) : (
          '00:00'
        )}
      </td>
      <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
        <div className="flex">
          {data.callType == 'inbound' && (
            <ArrowDownLeft color="#475467" className="w-4 h-4 mr-1" />
          )}
          {data.callType == 'outbound' && (
            <ArrowUpRight color="#475467" className="w-4 h-4 mr-1" />
          )}
          <span>
            {data.callType
              ? data.callType.charAt(0).toUpperCase() + data.callType.slice(1)
              : ''}
          </span>
        </div>
      </td>

      <td className="px-5 py-4 min-w-[12.10%] w-[12.10%] max-w-[1px]">
        {data.callStatus == 'In Progress' ? (
          <div className="text-grayLight600 truncate ">-</div>
        ) : (
          <div className="truncate max-w-full w-fit space-x-2">
            {equipmentTypes?.length > 0 && equipmentTypes[0] != 'N/A' && (
              <BadgeCmp type="pink" style="modern">
                {equipmentTypes?.[0]}
              </BadgeCmp>
            )}

            {equipmentTypes?.length > 1 && (
              <BadgeCmp type="pink" style="modern">
                {equipmentTypes[1]}
              </BadgeCmp>
            )}

            {equipmentTypes?.length > 2 && (
              <>
                <ActionTooltip
                  message={tooltipItems}
                  type="light"
                  tooltipPosition="right"
                  isOpenChildTopPosOnTop={false}
                  openOnHover={true}
                >
                  <BadgeCmp
                    mainClassName="cursor-pointer"
                    type="success"
                    style="modern"
                    isHidePillDot
                  >
                    +{equipmentTypes?.length - 2}
                  </BadgeCmp>
                </ActionTooltip>
              </>
            )}
          </div>
        )}
      </td>
      <td className="px-5 py-4 min-w-[12.10%] w-[12.10%] max-w-[1px]">
        {data.callStatus == 'In Progress' ? (
          <div className="text-grayLight600 truncate">-</div>
        ) : (
          <span>
            {data.estimatedDeliveryDate != 'Unknown' &&
            data.estimatedDeliveryDate != 'N/A'
              ? moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').isValid()
                ? moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').format(
                    'MMM Do - YYYY'
                  )
                : moment(data.estimatedDeliveryDate, 'YYYY-MM-DD').isValid()
                ? moment(data.estimatedDeliveryDate, 'YYYY-MM-DD').format(
                    'MMM Do - YYYY'
                  )
                : data.estimatedDeliveryDate
              : ''}
          </span>
        )}
      </td>
      <td className="px-5 py-4 min-w-[10.10%] w-[10.10%] max-w-[1px]">
        {data.callStatus == 'In Progress' ? (
          <div className="text-grayLight600 truncate">-</div>
        ) : (
          <span className="text-textSecondary font-bold">
            {data.offerAmount && data.offerAmount != 'N/A'
              ? `$${setNumberWithCommas(data.offerAmount)}`
              : ''}
          </span>
        )}
      </td>

      <td className="px-5 py-4 min-w-[20.04%] w-[20.04%] max-w-[1px]">
        {data.callStatus == 'In Progress' ? (
          <div className="text-grayLight600 truncate ">-</div>
        ) : (data.notes?.length ?? 0) > 30 ? (
          <ActionTooltip
            openOnHover={true}
            tooltipPosition="center"
            parentClassName="max-w-[320px] w-max"
            isOpenChildTopPosOnTop={true}
            type="dark"
            message={data.notes}
          >
            <p className="truncate">{data.notes}</p>
          </ActionTooltip>
        ) : (
          <p className="truncate">{data.notes}</p>
        )}
      </td>
      <td className="px-5 py-4 w-[100px] max-w-[100px] min-w-[100px] pointer">
        <div className="flex items-center gap-x-4">
          {data.callStatus == 'In Progress' ? (
            <>
              <div className="w-5 h-5 rounded-full bg-success200 flex-none cursor-pointer">
                <img
                  src={inboundEmoji}
                  className={`w-full h-full p-1 object-contain`}
                />
              </div>
              <div className="loading-spinner-custom"></div>
            </>
          ) : (
            <>
              <ArrowUp
                className="w-4 h-4 text-primary cursor-pointer"
                onClick={() => {
                  setSelectedCarrierRates([
                    {
                      id: 0,
                      contactPhone: `${
                        data.companyPhone.includes('+1')
                          ? data.companyPhone
                          : `+1${data.companyPhone}`
                      }`,
                      projectedDeliveryDate:
                        data.estimatedDeliveryDate &&
                        moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').diff(
                          moment().startOf('day'),
                          'days'
                        ) >= 0
                          ? moment(
                              data.estimatedDeliveryDate,
                              'MM/DD/YYYY'
                            ).format('YYYY-MM-DD 00:00:00')
                          : moment().format('YYYY-MM-DD 00:00:00'),
                      transitTime:
                        data.estimatedDeliveryDate &&
                        moment(data.estimatedDeliveryDate, 'MM/DD/YYYY').diff(
                          moment().startOf('day'),
                          'days'
                        ) >= 0
                          ? moment(
                              data.estimatedDeliveryDate,
                              'MM/DD/YYYY'
                            ).diff(moment().startOf('day'), 'days')
                          : 0,
                      name: data.company ? data.company : 'Unknown Carrier',
                      additionalCharges: 0,
                      additionalChargesUSD: 0,
                      marginType: 'VALUE',
                      margin: 0,
                      marginUSD: 0,
                      marginUnit: 0,
                      marginUnitUSD: 0,
                      totalCharge: Number(data.offerAmount) || 0,
                      totalChargeUSD:
                        (Number(data.offerAmount) || 0) * (rates.usdRate || 0),
                      totalCostCAD: Number(data.offerAmount) || 0,
                      totalCostUSD:
                        (Number(data.offerAmount) || 0) * (rates.usdRate || 0),
                    },
                  ]);
                  setIsShowTypeInfoModal(true);
                }}
              />
              <DialOutboundCall
                data={{
                  contactNumber: getFormatPhoneWithPlus(data.companyPhone),
                  contactName: ['Not provided', 'None', 'null'].includes(
                    data.carrierContactPerson
                  )
                    ? ''
                    : data.carrierContactPerson,
                  customerName: '',
                  role: 'carrier',
                  contactImageUrl: '',
                  carrierName: data.company ? data.company : 'Unknown',
                }}
                className="text-primary"
              />
            </>
          )}
        </div>
      </td>
    </>
  );
};

export default LoadBoardRateRow;
