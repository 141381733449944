import { yupResolver } from '@hookform/resolvers/yup';
import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { addInTransitAiAgentFollowUp } from 'src/services/RadarService';
import * as yup from 'yup';

import { convertTo12HourFormat, followUpArr } from './constant';

const generateTimeOptions = () => {
  const times = [];

  // Add 12:00 AM (midnight)
  times.push({ label: '12:00 AM', value: '12:00 AM' });

  // Add 1:00 AM through 11:00 AM
  for (let i = 1; i < 12; i++) {
    times.push({ label: `${i}:00 AM`, value: `${i}:00 AM` });
  }

  // Add 12:00 PM (noon)
  times.push({ label: '12:00 PM', value: '12:00 PM' });

  // Add 1:00 PM through 11:00 PM
  for (let i = 1; i < 12; i++) {
    times.push({ label: `${i}:00 PM`, value: `${i}:00 PM` });
  }

  return times;
};

const startTimeArr = generateTimeOptions();
const endTimeArr = generateTimeOptions();

// Add this utility function to convert time from 12-hour to 24-hour format
const convertTo24HourFormat = (timeStr: string) => {
  if (!timeStr) return '';

  const [timePart, modifier] = timeStr.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (hours === 12) {
    hours = modifier === 'AM' ? 0 : 12;
  } else if (modifier === 'PM') {
    hours += 12;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:00`;
};

const validationSchema = yup.object().shape({
  followupHours: yup.string().required(),
  contactWindowStartTime: yup.string().required(),
  contactWindowEndTime: yup
    .string()
    .required()
    .test(
      'is-after-start',
      'End time must be after start time',
      function (endTime) {
        const startTime = this.parent.contactWindowStartTime;
        if (!startTime || !endTime) return true; // Skip validation if either field is empty

        // Convert both times to 24-hour format for comparison
        const start24 = convertTo24HourFormat(startTime);
        const end24 = convertTo24HourFormat(endTime);

        // Compare the times - end time should be greater than start time
        return end24 > start24;
      }
    ),
});

const InTransitModal = forwardRef(
  ({
    activeLegData,
    reviewConfigData,
    inTransitModalRef,
    loading,
    setLoading,
    getOrderAIAgentDetails,
    setStep,
    aiAgentDetails,
  }: any) => {
    const {
      control,
      setValue,
      formState: { errors },
      clearErrors,
      handleSubmit,
    } = useForm({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        followupHours: reviewConfigData?.isTransit?.followUpHoursInterval ?? '',
        contactWindowStartTime:
          convertTo12HourFormat(
            reviewConfigData?.isTransit?.followUpStartTime
          ) ?? '',
        contactWindowEndTime:
          convertTo12HourFormat(reviewConfigData?.isTransit?.followUpEndTime) ??
          '',
      },
    });

    const onSubmit = async (data: any) => {
      if (loading) return;

      // Convert time strings to 24-hour format
      const startTime24h = convertTo24HourFormat(data.contactWindowStartTime);
      const endTime24h = convertTo24HourFormat(data.contactWindowEndTime);

      if (
        data.followupHours ===
          reviewConfigData?.isTransit?.followUpHoursInterval &&
        startTime24h === reviewConfigData?.isTransit?.followUpStartTime &&
        endTime24h === reviewConfigData?.isTransit?.followUpEndTime
      ) {
        setStep(3);

        return;
      }

      setLoading(true);

      const payload = {
        id: reviewConfigData?.isTransit?.id ?? null,
        masterOrderId: activeLegData?.masterOrderId,
        legId: activeLegData?.id,
        agentId: aiAgentDetails?.id,
        followUpHoursInterval: data.followupHours,
        followUpStartTime: startTime24h,
        followUpEndTime: endTime24h,
      };

      addInTransitAiAgentFollowUp(payload)
        .then((response: any) => {
          if (response?.message === 'Success') {
            getOrderAIAgentDetails();
            setStep(3);
          }
        })
        .catch((error) => {
          console.log('error :>> ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useImperativeHandle(inTransitModalRef, () => ({
      inTransitSubmit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <>
        <>
          <div className="space-y-1">
            <h2 className="text-grayLight900 text-lg font-semibold">
              In Transit
            </h2>
            <p className="text-grayLight600 text-sm font-normal">
              Let me know when and how often I should check in with our contacts
            </p>
          </div>
          <div className="flex-1 overflow-auto custom-scrollbar-v2 space-y-5">
            <Controller
              name="followupHours"
              control={control}
              render={({ field: { value } }) => (
                <SelectBox
                  isClearable
                  name="followupHours"
                  required
                  placeholder="Select Follow Up Hours"
                  label="Set Follow up Hours"
                  className={`form_control`}
                  labelClassName="form_label mb-1.5 block"
                  isSearchable={true}
                  options={followUpArr}
                  value={
                    followUpArr.filter((val) => val?.value == value) || value
                  }
                  onChangeFunc={(e: any) => {
                    setValue('followupHours', e?.value || null);

                    if (e?.value) {
                      clearErrors('followupHours');
                    }
                  }}
                  errorText={errors.followupHours ? true : false}
                />
              )}
            />
            <div className="space-y-2.5">
              <h6 className="text-textSecondary text-xs font-medium">
                Contact Window
              </h6>
              <div className="flex gap-x-3">
                <Controller
                  name="contactWindowStartTime"
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectBox
                      parentClassName="flex-1"
                      isClearable
                      name="contactWindowStartTime"
                      placeholder="Select Start Time"
                      label="Start Time"
                      className={`form_control`}
                      labelClassName="form_label mb-1.5 block"
                      isSearchable={true}
                      options={startTimeArr}
                      value={
                        startTimeArr.filter((val) => val?.value == value) ||
                        null
                      }
                      onChangeFunc={(e: any) => {
                        setValue('contactWindowStartTime', e?.value || null);

                        if (e?.value) {
                          clearErrors('contactWindowStartTime');
                        }
                      }}
                      errorText={errors.contactWindowStartTime ? true : false}
                    />
                  )}
                />
                <Controller
                  name="contactWindowEndTime"
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectBox
                      parentClassName="flex-1"
                      isClearable
                      name="contactWindowEndTime"
                      placeholder="Select End Time"
                      label="End Time"
                      className={`form_control`}
                      labelClassName="form_label mb-1.5 block"
                      isSearchable={true}
                      options={endTimeArr}
                      value={
                        endTimeArr.filter((val) => val?.value == value) || null
                      }
                      onChangeFunc={(e: any) => {
                        setValue('contactWindowEndTime', e?.value || null);

                        if (e?.value) {
                          clearErrors('contactWindowEndTime');
                        }
                      }}
                      errorText={errors.contactWindowEndTime ? true : false}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
);

export default InTransitModal;
