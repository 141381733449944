import {
  Attachment01,
  ChevronDown,
  ChevronUp,
  // ChevronDown,
  XClose,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { sendOrderEmail } from 'src/services/OrderService';
import { convertToGBMBKB } from 'src/utils/CommonFunctions';
// import AiStarIcon from '../../assets/img/AiStarIcon.svg';
import WalToast from 'src/utils/WalToast';

import pdf from '../../assets/img/pdf-icon.png';
import TailwindAdvancedEditor from '../AdvancedEditor';
// import AiInputText from '../AiInputText';
import ButtonCmp from '../ButtonCmp';
// import CheckBox from '../CheckBox';
import DynamicCreatableSelect from '../DynamicCreatableSelect';
import ErrorMsg from '../errorMsg';
import InputText from '../InputText/InputText';
import TooltipCmp from '../TooltipCmp';

interface EmailReplyCmpProps {
  subjectLine?: string;
  toEmailAddress?: string;
  emailDetails?: any;
  emailStaticBody?: any;
  handleClose?: any;
  onSubmit?: any;
  defaultAttachments?: {
    url: string;
    fileName: string;
  }[];
  isShowInitialMessage?: boolean;
}

const fetchPdfAsFile = async (url: string, name: string) => {
  try {
    const response = await fetch(url);

    if (!response.ok) {
      console.error('PDF fetch failed with status:', response.status);
      WalToast.error('Failed to fetch PDF file');
    }

    const blob = await response.blob();

    return new File([blob], `${name}.pdf`, { type: blob.type });
  } catch (error) {
    console.error('Error fetching PDF file:', error);
    WalToast.error('Failed to fetch PDF file');
  }
};

const EmailReplyCmp = ({
  subjectLine,
  toEmailAddress,
  emailDetails,
  emailStaticBody,
  handleClose,
  onSubmit,
  defaultAttachments,
  isShowInitialMessage = true,
}: EmailReplyCmpProps) => {
  const initFormData = {
    messageId: emailDetails?.id,
    to: [toEmailAddress],
    emailType: '',
    bcc: [],
    cc: [],
    emailBody: `<p>Hello,</p><p>${emailDetails}</p>`,
    attachments: [],
  };

  const MAX_FILE_SIZE = 1 * 1024 * 1024;
  const initValidForm = {
    to: true,
    emailBody: true,
    emailType: true,
    cc: true,
    bcc: true,
    subject: true,
  };

  const [formData, setFormData] = useState<any>(initFormData);
  // const [ccInputEmails, setCCInputEmails] = useState<any>([]);
  const [validForm, setValidForm] = useState(initValidForm);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const [ccSelectedOptions, setCcSelectedOptions] = useState([]);
  const [ccInputValue, setCcInputValue] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDisplayCCAndBCC, setIsDisplayCCAndBCC] = useState(false);

  useEffect(() => {
    if (!formData?.attachments?.length) {
      defaultAttachments?.forEach((attachment: any) => {
        fetchPdfAsFile(attachment.url, attachment.fileName).then((pdfFile) => {
          setFormData((prev: any) => ({
            ...prev,
            attachments: [pdfFile],
          }));
        });
      });
    }
  }, [defaultAttachments]);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
  };

  const validateEmails = (emails: any) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emails.map((email: string) => {
      const ccEmail = email.trim();

      return emailPattern.test(ccEmail);
    });
  };

  const handleOnSubmit = async () => {
    const errors: any = {};

    if (!formData.to || formData.to.trim().length === 0) {
      errors.to = 'Email address is required.';
      setValidForm((prev) => ({ ...prev, to: false }));
    } else if (!isValidEmail(formData.to)) {
      errors.to = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, to: false }));
    } else {
      setValidForm((prev) => ({ ...prev, to: true }));
    }

    // if (formData.bcc.length === 0 && formData.cc.length === 0) {
    //   errors.bcc =
    //     'Please enter at least one recipient in the BCC or CC field to proceed.';
    //   setValidForm((prev) => ({ ...prev, bcc: false }));
    // } else
    if (formData.bcc && !validateEmails(formData.bcc).every(Boolean)) {
      errors.bcc = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, bcc: false }));
    } else if (formData.cc && !validateEmails(formData.cc).every(Boolean)) {
      errors.bcc = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, bcc: false }));
    } else {
      setValidForm((prev) => ({ ...prev, bcc: true }));
    }

    if (!formData.subject || formData.subject.trim().length === 0) {
      errors.subject = 'Subject is required.';
      setValidForm((prev) => ({ ...prev, subject: false }));
    } else {
      setValidForm((prev) => ({ ...prev, subject: true }));
    }

    if (
      formData.cc.length !== 0 &&
      !formData.cc.some((email: string) => email.trim() === '') &&
      !validateEmails(formData.cc).every(Boolean)
    ) {
      errors.cc = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, cc: false }));
    } else {
      // setValidForm((prev) => ({ ...prev, cc: true }));
    }

    if (
      formData.bcc.length !== 0 &&
      !formData.bcc.some((email: string) => email.trim() === '') &&
      !validateEmails(formData.bcc).every(Boolean)
    ) {
      errors.cc = 'Please enter a valid email address.';
      setValidForm((prev) => ({ ...prev, bcc: false }));
    } else {
      // setValidForm((prev) => ({ ...prev, bcc: true }));
    }

    if (!formData.emailBody || formData.emailBody.trim() === '') {
      errors.emailBody = 'Please enter the email body.';
      setValidForm((prev) => ({ ...prev, emailBody: false }));
    } else {
      setValidForm((prev) => ({ ...prev, emailBody: true }));
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      setIsDisplayCCAndBCC(true);

      return;
    }

    setIsLoading(true);

    // const attachmentsArray = [];

    // if (formData?.attachments?.length) {
    //   for (const file of formData.attachments) {
    //     try {
    //       const base64String = await fileToBase64(file);
    //       attachmentsArray.push({
    //         filename: file.name,
    //         content: base64String,
    //         contentType: file.type,
    //       });
    //     } catch (error) {
    //       console.error('Error converting file to base64:', error);
    //     }
    //   }
    // }
    // const attachmentsInString = JSON.stringify(attachmentsArray);

    if (onSubmit) {
      onSubmit({ ...formData, attachments: formData?.attachments });
    } else {
      const formDataToSend = new FormData();

      formDataToSend.append('subject', formData.subject);
      formDataToSend.append('to', formData.to);
      formDataToSend.append('emailBody', formData.emailBody);
      formDataToSend.append('cc', formData.cc);
      formDataToSend.append('bcc', formData.bcc);

      let attachments: any = [];
      formData?.attachments?.forEach((file: any) => {
        attachments.push(file);
      });
      console.log('attachments', attachments);
      formDataToSend.append('attachments', JSON.stringify(attachments));
      sendOrderEmail(formDataToSend)
        .then((response) => {
          if (response) {
            handleClose();
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setFormData((old: any) => ({ ...old, to: toEmailAddress }));
  }, [toEmailAddress]);

  useEffect(() => {
    setFormData((old: any) => ({ ...old, subject: subjectLine }));
  }, [subjectLine]);

  useEffect(() => {
    setFormData((old: any) => ({
      ...old,
      bcc: selectedOptions.map((option: any) => option.value),
    }));
  }, [selectedOptions]);

  useEffect(() => {
    setFormData((old: any) => ({
      ...old,
      cc: ccSelectedOptions.map((option: any) => option.value),
    }));
  }, [ccSelectedOptions]);

  const defaultEditorContent = useMemo(() => {
    const content = [];

    if (isShowInitialMessage) {
      content.push({
        type: 'paragraph',
        content: [{ type: 'text', text: 'Hi, ' }],
      });
    }

    content.push({
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: emailDetails,
        },
      ],
    });

    return {
      type: 'doc',
      content,
    };
  }, [emailDetails, isShowInitialMessage]);

  const handleSelectChange = (newValue: any) => {
    setSelectedOptions(newValue);
  };

  const handleInputChange = (newValue: any) => {
    setInputValue(newValue);
  };

  const isEmailUnique = (email: string, inputName: string) => {
    const existingEmails =
      inputName === 'cc'
        ? ccSelectedOptions.map((option: any) => option.value)
        : selectedOptions.map((option: any) => option.value);

    return !existingEmails.includes(email);
  };

  const handleKeyDown = (event: any, inputName: string) => {
    if (
      ccInputValue &&
      (event.key === 'Enter' || event.key === 'Tab') &&
      inputName === 'cc'
    ) {
      if (isValidEmail(ccInputValue)) {
        if (isEmailUnique(ccInputValue, 'cc')) {
          const newOption = { label: ccInputValue, value: ccInputValue };
          setCcSelectedOptions((prev: any) => [...prev, newOption] as any);
        } else {
          setErrorMessages((prev: any) => ({
            ...prev,
            cc: 'This email address is already added',
          }));
        }
        setCcInputValue('');
      } else {
        setCcInputValue('');
      }
      event.preventDefault();
    }

    if (
      inputName === 'bcc' &&
      inputValue &&
      (event.key === 'Enter' || event.key === 'Tab')
    ) {
      if (isValidEmail(inputValue)) {
        if (isEmailUnique(inputValue, 'bcc')) {
          const newOption = { label: inputValue, value: inputValue };
          setSelectedOptions((prev: any) => [...prev, newOption] as any);
        } else {
          setErrorMessages((prev: any) => ({
            ...prev,
            bcc: 'This email address is already added',
          }));
        }
        setInputValue('');
      } else {
        setInputValue('');
      }
      event.preventDefault();
    }
  };

  const openFileManager = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);

      if (filesArray?.[0]?.size <= MAX_FILE_SIZE) {
        setFormData((prev: any) => ({
          ...prev,
          attachments: [...prev.attachments, ...filesArray],
        }));
      } else {
        setFileError('File size must be less than 1 MB');
      }
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      attachments: prev.attachments.filter((_: any, i: number) => i !== index),
    }));
  };

  const handleBlur = (event: any, inputName: string) => {
    if (ccInputValue && inputName === 'cc') {
      if (isValidEmail(ccInputValue)) {
        if (isEmailUnique(ccInputValue, 'cc')) {
          const newOption = { label: ccInputValue, value: ccInputValue };
          setCcSelectedOptions((prev: any) => [...prev, newOption] as any);
        } else {
          setErrorMessages((prev: any) => ({
            ...prev,
            cc: 'This email address is already added',
          }));
        }
        setCcInputValue('');
      }
    } else if (inputValue && inputName === 'bcc') {
      if (isValidEmail(inputValue)) {
        if (isEmailUnique(inputValue, 'bcc')) {
          const newOption = { label: inputValue, value: inputValue };
          setSelectedOptions((prev: any) => [...prev, newOption] as any);
        } else {
          setErrorMessages((prev: any) => ({
            ...prev,
            bcc: 'This email address is already added',
          }));
        }
        setInputValue('');
      }
    }
  };

  return (
    <>
      <form
        className="flex flex-col space-y-4 flex-1 py-5 [&>div]:px-5 overflow-auto custom-scrollbar-v2 h-[60vh]"
        onSubmit={handleOnSubmit}
      >
        <div className="">
          <div className="border-b border-utilityGray200 pb-4 space-y-4">
            <div className="">
              <div className="w-full">
                <div
                  className={`form_control p-0 relative !h-auto flex-1 ${
                    validForm.to === false ? 'border-red-600' : ''
                  }`}
                >
                  <InputText
                    value={formData?.to}
                    parentClassName="flex-1"
                    placeholder="Enter To address"
                    className="!border-0 !h-auto !pr-24"
                    onChangeFunc={(e) => {
                      setErrorMessages((old: any) => ({ ...old, to: null }));
                      setValidForm((old: any) => ({ ...old, to: true }));

                      setFormData((prevData: any) => ({
                        ...prevData,
                        to: e.target.value,
                      }));
                    }}
                    required={true}
                    // isError={validForm.to === false}
                    // isClearable
                    // onClear={() => {
                    //   setFormData((prevData: any) => ({
                    //     ...prevData,
                    //     emailType: '',
                    //   }));
                    // }}
                  />
                  <div
                    className="flex gap-1 text-[11px] leading-[1] font-medium absolute top-1/2 -translate-y-1/2 right-2 text-gray500 h-full items-center cursor-pointer"
                    onClick={() => {
                      setIsDisplayCCAndBCC(!isDisplayCCAndBCC);
                    }}
                  >
                    <p>CC | BCC</p>
                    {isDisplayCCAndBCC ? (
                      <ChevronUp
                        className="w-3.5 h-3.5 text-gray400"
                        onClick={() => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            emailType: '',
                          }));
                        }}
                      />
                    ) : (
                      <ChevronDown
                        className="w-3.5 h-3.5 text-gray400"
                        onClick={() => {
                          setFormData((prevData: any) => ({
                            ...prevData,
                            emailType: 'cc',
                          }));
                        }}
                      />
                    )}
                  </div>
                </div>
                {/* {validForm.to === false && (
              <ErrorMsg errorText={errorMessages?.to} />
            )} */}
              </div>
            </div>
            {isDisplayCCAndBCC ? (
              <>
                <div>
                  <div className="relative">
                    <DynamicCreatableSelect
                      placeholder="Enter email address"
                      inputName="cc"
                      isClearable={true}
                      isMulti={true}
                      menuIsOpen={false}
                      onChange={(e: any) => setCcSelectedOptions(e)}
                      onInputChange={(e: any) => setCcInputValue(e)}
                      onKeyDown={(e: any) => handleKeyDown(e, 'cc')}
                      CreatableSelectWrapClass="EmailReplyMultiSelect"
                      value={ccSelectedOptions}
                      inputValue={ccInputValue}
                      // errorText={!validForm.cc ? errorMessages?.cc : null}
                      onBlur={(e: any) => handleBlur(e, 'cc')}
                    />
                    <p className="flex gap-1 text-[11px] leading-[1] font-medium absolute top-1/2 -translate-y-1/2 right-2.5 text-gray500 h-full items-center cursor-pointer">
                      CC
                    </p>
                  </div>
                </div>

                <div>
                  <div className="relative">
                    <DynamicCreatableSelect
                      placeholder="Enter email address"
                      inputName="bcc"
                      isClearable={true}
                      isMulti={true}
                      menuIsOpen={false}
                      onChange={handleSelectChange}
                      onInputChange={handleInputChange}
                      onKeyDown={(e: any) => handleKeyDown(e, 'bcc')}
                      CreatableSelectWrapClass="EmailReplyMultiSelect"
                      value={selectedOptions}
                      inputValue={inputValue}
                      onBlur={(e: any) => handleBlur(e, 'bcc')}
                      // errorText={!validForm.bcc ? errorMessages?.bcc : null}
                    />

                    <p className="flex gap-1 text-[11px] leading-[1] font-medium absolute top-1/2 -translate-y-1/2 right-2.5 text-gray500 h-full items-center cursor-pointer">
                      BCC
                    </p>
                  </div>
                  {/* {validForm.bcc === false && (
                <ErrorMsg errorText={errorMessages?.bcc} />
              )} */}
                </div>
              </>
            ) : (
              validForm.bcc === false && (
                <ErrorMsg errorText={errorMessages?.bcc} />
              )
            )}

            {/* <div className="flex gap-x-2 w-full">
          <div className="form_control p-0 relative !h-auto flex-1">
            <InputText
              value={ccInputEmails}
              parentClassName="flex-1"
              placeholder="Enter cc address"
              className="!border-0 !h-auto !pr-24"
              onChangeFunc={(e) => {
                const newValue = e.target.value;
                const ccEmails = newValue.split(',');

                setErrorMessages((old: any) => ({ ...old, cc: null }));
                setValidForm((old: any) => ({ ...old, cc: true }));
                setCCInputEmails(ccEmails);

                const trimCCEmails = newValue
                  .split(',')
                  .map((email: any) => email.trim())
                  .filter((email: any) => email !== '');
                setFormData((prevData: any) => ({
                  ...prevData,
                  cc: trimCCEmails,
                }));
              }}
              errorText={validForm.cc === false ? errorMessages?.cc : ''}
            />

            <DynamicCreatableSelect
              placeholder="Enter email address"
              isClearable={true}
              isMulti={true}
              menuIsOpen={false}
              onChange={handleSelectChange}
              onInputChange={handleInputChange}
              onKeyDown={handleKeyDown}
              value={selectedOptions}
              inputValue={inputValue}
              errorText={!validForm.cc ? errorMessages?.cc : null}
            />
          </div>
        </div> */}
          </div>
        </div>
        {subjectLine && (
          <div className="">
            <InputText
              inputName="Subject"
              placeholder="Enter subject"
              parentClassName="flex-1  border-b  border-utilityGray200 pb-4"
              value={formData?.subject}
              onChangeFunc={(e) => {
                setErrorMessages((old: any) => ({ ...old, subject: null }));
                setValidForm((old: any) => ({ ...old, subject: true }));

                setFormData((prevData: any) => ({
                  ...prevData,
                  subject: e.target.value,
                }));
              }}
              errorText={
                validForm.subject === false ? errorMessages?.subject : ''
              }
            />
          </div>
        )}
        <div className="overflow-auto custom-scrollbar-v2 min-h-28 ">
          <div className="novel-editor-wrap">
            <TailwindAdvancedEditor
              initialValue={defaultEditorContent}
              handleChange={(newContent: any) => {
                setFormData((prevData: any) => ({
                  ...prevData,
                  emailBody: newContent,
                }));
              }}
              editorWrapClassName="!border-0 !p-0 !min-h-[unset]"
              editorContentClassName="!px-0"
            />
            {emailStaticBody}
          </div>
        </div>
        {validForm.emailBody === false && (
          <ErrorMsg errorText={errorMessages?.emailBody} />
        )}

        <div>
          <ul className="!mt-6 !mb-3 flex flex-wrap -m-1.5 empty:hidden">
            {Array.isArray(formData?.attachments) &&
              formData?.attachments?.length > 0 &&
              formData?.attachments?.map((file: File, index: number) => (
                <li className="p-1.5 w-1/2" key={index}>
                  <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3 relative">
                    {file?.name?.endsWith('.pdf') ? (
                      <img src={pdf} className="h-8" alt="PDF Icon" />
                    ) : (
                      <img
                        src={URL.createObjectURL(file)}
                        className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none object-cover"
                        alt="File Preview"
                      />
                    )}
                    <div className="flex-1 w-[calc(100%_-_85px)] ">
                      <h6 className="text-textSecondary text-xs font-medium truncate">
                        <TooltipCmp
                          message={file?.name?.length > 20 ? file?.name : null}
                        >
                          {file.name}
                        </TooltipCmp>
                      </h6>
                      <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                        {convertToGBMBKB(file?.size)}
                      </p>
                    </div>
                    {/* <div
                    className="radial-progress text-primary"
                    style={
                      {
                        '--value': 40,
                        '--size': '26px',
                        '--thickness': '4px',
                      } as React.CSSProperties & { '--value': number }
                    }
                    role="progressbar"
                  ></div> */}
                    <div
                      className="cursor-pointer absolute -top-1.5 -right-1.5 bg-white w-4 h-4 border border-utilityGray200 rounded-full flex items-center justify-center"
                      onClick={() => handleRemoveAttachment(index)}
                    >
                      <XClose className="w-3 h-3 text-grayLight900" />
                    </div>
                  </div>
                </li>
              ))}
            {/* <li className="p-1.5 w-1/2 ">
            <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3">
              <img src={pdf} className="h-8 " />
              <div className="flex-1 w-[calc(100%_-_85px)] ">
                <h6 className="text-textSecondary text-xs font-medium truncate">
                  Shipment_POD_[05/06/24].pdf
                </h6>
                <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                  200 KB
                </p>
              </div>
              <CheckBox classes="" onChangeFunc={() => {}} />
            </div>
          </li> */}
          </ul>
        </div>
        <input
          type="file"
          multiple
          ref={fileInputRef}
          accept="image/*, application/pdf"
          onChange={handleFileChange}
          className="hidden"
        />

        <ErrorMsg errorText={fileError} />
        {/* <AiInputText /> */}
      </form>
      <footer className="px-5 py-[14px] border-t flex flex-wrap gap-3 md:flex-row flex-col-reverse sticky bottom-0 bg-white">
        <ButtonCmp
          className="btn_secondary_black min-w-24"
          onClick={() => {
            handleClose();
          }}
        >
          Dismiss
        </ButtonCmp>
        <div className="flex gap-2 flex-1 justify-end">
          {subjectLine !== 'Request For Price' ? (
            <ButtonCmp
              className="btn_secondary_black lg:!px-[9px] !px-2"
              icon={<Attachment01 className="w-4 h-4" />}
              onClick={openFileManager}
            >
              <></>
            </ButtonCmp>
          ) : (
            ''
          )}

          {/* <ButtonCmp
            className="btn_secondary_black "
            icon={<img src={AiStarIcon} className="w-4 h-4 object-contain" />}
          >
            Ask HQ AI
          </ButtonCmp> */}
          <ButtonCmp
            className="btn_primary min-w-20"
            onClick={handleOnSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Send
          </ButtonCmp>
        </div>
      </footer>
    </>
  );
};

export default EmailReplyCmp;
