import {
  BezierCurve03,
  Building01,
  Building07,
  Calendar,
  CalendarDate,
  ClockFastForward,
  File02,
  FileCheck03,
  Home05,
  Package,
  PackageX,
  Snowflake02,
  ThermometerWarm,
  Truck01,
  Truck02,
  User01,
  User03,
} from '@untitled-ui/icons-react/build/cjs';
import { BadgeType } from 'src/interface/common';

export const APP_FULL_NAME: string = window.FULL_NAME as string;
export const REGION_CODE: any = window.REGION_CODE;
export const TIMEZONE: any = window.TIMEZONE;
// export const DATE_FORMAT: string = 'DD/MM/YYYY';
export const DATE_FORMAT: string = 'MMM DD, YYYY';
export const TIME_FORMAT: string = 'hh:mm:ss A';

export const FOOT = 5;
export const HANDLING_UNIT_NUMBER = 999;

export const ROSE_ROCKET = {
  QUOTE_URL: window.ROSE_ROCKET_QUOTE_URL,
};

export const CARRIERS = {
  ARC_BEST: {
    ID: 1,
    QUOTE_URL: window.ARCBEST_QUOTE_URL,
    BOL_URL: window.ARCBEST_BOL_URL,
    SLUG: 'ARC_BEST',
  },
  POLARIS: {
    ID: 2,
    QUOTE_URL: window.POLARIS_QUOTE_URL,
    BOL_URL: window.POLARIS_BOL_URL,
    SLUG: 'POLARIS',
  },
  DAY_ROSS: {
    ID: 3,
    QUOTE_URL: window.DAY_ROSS_QUOTE_URL,
    BOL_URL: window.DAY_ROSS_BOL_URL,
    SLUG: 'DAY_ROSS',
  },
  FREIGHTCOM: {
    ID: 5,
    QUOTE_URL: window.FREIGHTCOM_QUOTE_URL,
    BOL_URL: window.FREIGHTCOM_BOL_URL,
    SLUG: 'FREIGHTCOM',
  },
  MARITIME_ONTARIO: {
    ID: 4,
    QUOTE_URL: window.MARITIME_ONTARIO_QUOTE_URL,
    BOL_URL: window.MARITIME_ONTARIO_BOL_URL,
    SLUG: 'MARITIME_ONTARIO',
  },
  CSA: {
    ID: 6,
    QUOTE_URL: window.CSA_QUOTE_URL,
    BOL_URL: window.CSA_BOL_URL,
    SLUG: 'CSA',
  },
  KINDERSLEY: {
    // QUOTE_URL: window.CSA_QUOTE_URL,
    // BOL_URL: window.CSA_BOL_URL,
    SLUG: 'KINDERSLEY',
  },
  DAY_ROSS_USA: {
    SLUG: 'DAY_ROSS_USA',
  },
  BANYAN: {
    SLUG: 'BANYAN',
  },
  AMERICAN_GROUP: {
    SLUG: 'AMERICAN_GROUP',
  },
};

export const MARGIN_TYPE = {
  VALUE: 'VALUE',
  PERCENTAGE: 'PERCENTAGE',
};

// export const MEASUREMENT = {
//     WEIGHT1: "LBS",
//     WEIGHT2: "KGS",

//     LENGTH1: "INCHES",
//     LENGTH2: "CM",
// };

export const MEASUREMENT = {
  WEIGHT1: {
    value: 'LBS',
    label: 'LBS',
  },
  WEIGHT2: {
    value: 'KGS',
    label: 'KGS',
  },
  LENGTH1: {
    value: 'INCHES',
    label: 'IN',
  },
  LENGTH2: {
    value: 'CM',
    label: 'CM',
  },
};

export const LBS = {
  WEIGHT: 6000,
  LENGTH: 144, // Inches
  WIDTH: 96, // Inches
  HEIGHT: 96, // Inches
  ITEM_LENGTH_OVER: FOOT * 12, // Inches
  MAX_WEIGHT: 2500,
};

export const KGS = {
  WEIGHT: Math.ceil(LBS.WEIGHT / 2.2046),
  LENGTH: Math.ceil(LBS.LENGTH / 0.3937), // CM
  WIDTH: Math.ceil(LBS.WIDTH / 0.3937), // CM
  HEIGHT: Math.ceil(LBS.HEIGHT / 0.3937), // CM
  ITEM_LENGTH_OVER: Math.ceil(FOOT * 30.48), // CM
  MAX_WEIGHT: Math.ceil(LBS.MAX_WEIGHT / 2.2046),
};

export const STATUS = {
  PENDING: 'Pending',
  OPENED: 'Opened',
  REQUESTED: 'BookingRequested',
  ACTIVE: 'Active',
  WON: 'Won',
  LOST: 'Lost',
  EXPIRED: 'Expired',
};

export const CURRENCY = {
  USD: 'USD',
  CAD: 'CAD',
};

export const VALIDATION_MESSAGE = {
  INVALID_ADDRESS:
    'City was not detected by Google Maps. Please specify manually or drag the icon on the map',
  INVALID_PICKUP_QUOTE:
    'Pickup is not likely to happen on this date. Would you like to quote for next available business day?',
};

export const PDF_LANGUAGE_OPTIONS = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
];

export const PDF_LANGUAGE = {
  ENG: 'English',
  FRE: 'French',
};

export const USER_ROLE = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  USER: 'user',
  SALES: 'sales',
  OPERATIONS: 'operations',
  CLAIMS: 'claims',
  FINANCE: 'finance',
};

export const PERMISSION = {
  USER_LIST: 'team_member_list',
  USER_CREATE: 'team_member_create',
  USER_UPDATE: 'team_member_update',
  USER_DELETE: 'team_member_delete',
  TEAM_LIST: 'team_list',
  TEAM_CREATE: 'team_create',
  TEAM_UPDATE: 'team_update',
  TEAM_DELETE: 'team_delete',
  ORGANIZATIONAL_CHART: 'ORGANIZATIONAL_chart',
  QUOTING_HUB_VIEW: 'quoting_hub_view',
  QUOTING_HUB_CREATE: 'quoting_hub_create',
  QUOTING_HUB_UPDATE: 'quoting_hub_update',
  QUOTING_HUB_DELETE: 'quoting_hub_delete',
  OPERATIONS_VIEW: 'operations_view',
  OPERATIONS_CREATE: 'operations_create',
  OPERATIONS_UPDATE: 'operations_update',
  OPERATIONS_DELETE: 'operations_delete',
  CARRIER_VIEW: 'carrier_view',
  CARRIER_CREATE: 'carrier_create',
  CARRIER_UPDATE: 'carrier_update',
  CARRIER_DELETE: 'carrier_delete',
  OVERVIEW: 'dashboard_overview',
  ADVANCE_VIEW: 'advance_view',
  FILE_UPLOAD: 'file_upload',
  FILE_DOWNLOAD: 'file_download',
  FILLE_DELETE: 'file_delete',
  TARGET_VIEW: 'target_view',
  TARGET_UPDATE: 'target_update',
  CUSTOMER_LIST: 'customer_list',
  CUSTOMER_DETAIL: 'customer_detail',
  CUSTOMER_GROUP_DETAIL: 'customer_group_detail',
  QUOTING_REQUEST: 'quoting_request',
  QUOTING_ANYLYTICS: 'quoting_analytics',
  VIEW_SMART_QUOTE: 'view_smart_quote',
  CREATE_SMART_QUOTE: 'create_smart_quote',
  DELETE_SMART_QUOTE: 'delete_smart_quote',
  LANE_HISTORY: 'lane_history',
  TRACK_AND_TRACE: 'track_and_trace',
  ORDERS: 'orders',
  RADAR: 'radar',
  COMMISSION_REPORT: 'commission_reports',
  CLAIMS_AND_DISPUTES: 'claims_dispute',
  CALL_LOGS: 'call_logs',
  CONTACT: 'contacts',
  THREADS: 'threads',
  CUSTOMER_ONBOARDING: 'customer_onboarding',
  CREDIT_INCREASE_REQUEST: 'credit_increase_requests',
  LOAD_INSURANCE: 'load_insurance',
  PREDICTIVE_PRICING: 'predictive_pricing',
  SETTING: 'setting',
};

export const CUSTOMER_SEGMENT_TYPES = {
  NEW: 'new',
  TOP: 'top',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  AT_RISK: 'at-risk',
  DORMANT: 'dormant',
  PROSPECT: 'prospect',
  TEST: 'test',
};

export const ONBOARDING_CUSTOMER_STATUS = {
  FORM_PENDING: 'Form Pending',
  IN_REVIEW: 'In Review',
  FORM_COMPLETED: 'Form Completed',
  APPROVED: 'Approved',
  DENIED: 'Denied',
  NEW: 'New',
  EXPIRED: 'Expired',
};

export const LOAD_INSURANCE_STATUS = {
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  OPEN: 'Open',
};

export const TABLE_IDS = {
  QUOTE_LIST: 'quoteList',
  CUSTOMER_LIST: 'customerList',
  TARGET_LIST: 'targetList',
  SALES_DASHBOARD: 'sales_dashboard',
  TARGET_LIST_BY_CUSTOMER: 'targetListByCustomer',
  CLAIMS_AND_DISPUTES_LIST: 'claimsAndDisputes',
  CREDIT_DASHBOARD: 'creditDashboard',
  CUSTOMER_DETAILS: 'customerDetail',
  ORDER_LIST: 'orderList',
  RADAR_LIST: 'radarList',
  LEADERBOARD_EVENT_TAB: 'leaderboardEventTab',
};

export const FILE_ORDER_STATUS = {
  NEW: 'New',
  NO_QUICK_QUOTE: 'No Quick Quote',
  NO_QUOTE: 'No Quote',
  QUICK_QUOTED: 'Quick Quoted',
  QUOTED: 'Quoted',
  SAVED: 'Saved',
  SPOT_QQ_REQUESTED: 'Spot Qq Requested',
  CANCELLED: 'cancelled',
  DRAFT_QUICK_QUOTE: 'draft quick quote',
  CLAIM: 'claim',
  ARCHIVED: 'archived',
  INVOICE_SENT: 'invoice sent',
  INVOICE_PAID: 'invoice paid',
  DISPATCHED: 'dispatched',
  INVOICE_CREATED: 'invoice created',
  DELIVERED: 'delivered',
  IN_TRANSIT: 'in transit',
};

export const QUOTE_CLASSIFICATION = {
  LTL: 'ltl',
  FTL: 'ftl',
  VOLUME_LTL: 'volume_ltl',
};

export const MAX_FILE_SIZE = 2 * 1024 * 1024;
export const MAP_API: string = 'google_map';
// export const TRIMBLE_KEY = 'D2D11E9BF803B7499B3051535FC9AC14';
// export const MAP_API: string = 'trimble_map';
export const TRIMBLE_KEY = '707CC148BE16B64A8102C686C8B8DBF3';

export const orgTypeArr = [
  { label: 'Public Company', value: 'public_company' },
  { label: 'LLC', value: 'llc' },
  { label: 'Private Corporation', value: 'private_corporation' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Sole Proprietorship', value: 'sole_proprietorship' },
  { label: 'Government', value: 'government' },
  { label: 'Other', value: 'other' },
];

export const CREDIT_INCREASE_REQUEST_STATUS_TYPES = {
  NEW: 'new',
  APPROVED: 'approved',
  IN_REVIEW: 'in_review',
  DENIED: 'denied',
};
export const TIME_FILTER: { value: string; name: string }[] = [
  {
    value: 'daily',
    name: 'Today',
  },
  {
    value: 'weekly',
    name: 'This Week',
  },
  {
    value: 'monthly',
    name: 'This Month',
  },
  {
    value: 'yearly',
    name: 'This Year',
  },
];
export const RECORDS_PER_PAGE = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

export const AI_CHATBOT_APP_API_URL =
  'https://wal-py-ai-gbabdjc2dgh4htgs.canadacentral-01.azurewebsites.net';

export const STATUS_TO_BADGE_TYPE: Record<string, BadgeType> = {
  [STATUS.WON]: 'success',
  [STATUS.ACTIVE]: 'lightblue',
  [STATUS.OPENED]: 'primary',
  [STATUS.EXPIRED]: 'error',
  [STATUS.LOST]: 'error',
  DEFAULT: 'success',
};
export const STATUS_TO_BADGE_LABEL: Record<string, string> = {
  [STATUS.WON]: 'Won',
  [STATUS.ACTIVE]: 'Quoted',
  // [STATUS.OPENED]: 'Open',
  [STATUS.OPENED]: 'To-Quote',
  [STATUS.EXPIRED]: 'Expired',
  [STATUS.LOST]: 'Lost',
  [STATUS.REQUESTED]: 'Requested',
};

export const getBadgeTypeByQuoteStatus = (status: string): BadgeType =>
  STATUS_TO_BADGE_TYPE[status] || STATUS_TO_BADGE_TYPE.DEFAULT;

export const getBadgeLabelByQuoteStatus = (status: string): string =>
  STATUS_TO_BADGE_LABEL[status] || status;

export const ORDER_STATUS = {
  NEW: 'new',
  QUOTED: 'quoted',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  BOOKED: 'booked',
  BOOKING_IN_PROGRESS: 'booking_in_progress',
  BOOKING_FAILED: 'booking_failed',
  INVOICE_PAID: 'invoice-paid',
  DELIVERED: 'delivered',
  INVOICE_CREATED: 'invoice-created',
  INVOICE_SENT: 'invoice-sent',
  DISPATCHED: 'dispatched',
  IN_TRANSIT: 'in-transit',
  COMPLETED: 'completed',
  DRAFT: 'draft',
};

const ORDER_STATUS_LABELS = {
  [ORDER_STATUS.NEW]: 'New',
  [ORDER_STATUS.QUOTED]: 'Quoted',
  [ORDER_STATUS.EXPIRED]: 'Expired',
  [ORDER_STATUS.CANCELLED]: 'Cancelled',
  [ORDER_STATUS.BOOKED]: 'Booked',
  [ORDER_STATUS.BOOKING_IN_PROGRESS]: 'Booking In Progress',
  [ORDER_STATUS.BOOKING_FAILED]: 'Booking Failed',
  [ORDER_STATUS.INVOICE_PAID]: 'Invoice Paid',
  [ORDER_STATUS.DELIVERED]: 'Delivered',
  [ORDER_STATUS.INVOICE_CREATED]: 'Invoice Created',
  [ORDER_STATUS.INVOICE_SENT]: 'Invoice Sent',
  [ORDER_STATUS.DISPATCHED]: 'Dispatched',
  [ORDER_STATUS.IN_TRANSIT]: 'In Transit',
  [ORDER_STATUS.COMPLETED]: 'Completed',
  [ORDER_STATUS.DRAFT]: 'Draft',
};

export const getBadgeLabelByOrderStatus = (status: string): string =>
  ORDER_STATUS_LABELS[status] || status;

export const iconMap: any = {
  'Tailgate Pickup': {
    icon: Truck01,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Pickup Appointment': {
    icon: Calendar,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Residential Pickup': {
    icon: Home05,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Trade Show Pickup': {
    icon: Building07,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Limited Access Pickup': {
    icon: BezierCurve03,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Threshold Pickup': {
    icon: Building01,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'After Hours / Weekend / Holiday Pickup': {
    icon: CalendarDate,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Hold For Pickup': {
    icon: ClockFastForward,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },

  'Tailgate Delivery': {
    icon: Truck01,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Residential Delivery': {
    icon: Home05,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Trade Show Delivery': {
    icon: Building07,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Limited Access Delivery': {
    icon: BezierCurve03,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Delivery Appointment': {
    icon: Calendar,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Threshold Delivery': {
    icon: Building01,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Handbomb Delivery': {
    icon: ClockFastForward,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },

  'Protect From Freeze': {
    icon: ThermometerWarm,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'In-bond': {
    icon: FileCheck03,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Dangerous Goods': {
    icon: PackageX,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  Tarps: {
    icon: BezierCurve03,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  Vented: {
    icon: Calendar,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Tri-axle': {
    icon: Truck02,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  Team: {
    icon: User01,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  Frozen: {
    icon: Snowflake02,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  Expedite: {
    icon: ClockFastForward,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Load Insurance': {
    icon: File02,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Flatbed Pickup': {
    icon: Package,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Flatbed Delivery': {
    icon: Package,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Lumper Service': {
    icon: Truck01,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'Driver Assist': {
    icon: User03,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
  'After Hours Delivery': {
    icon: CalendarDate,
    className: 'w-4 h-4 flex-none text-utilityGray800',
  },
};
