import { yupResolver } from '@hookform/resolvers/yup';
import { CurrencyDollar, FilePlus01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { BasicContext } from 'src/context/BasicContext';
import { getFormattedNumber } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import { handleInputChange } from '../../order.constant';

interface LoadInsuranceModalProps {
  handleClose: (value: boolean) => void;
  loadInsurance: any;
  onSubmitLoadInsurance: (data: any) => void;
  commercialValue?: any;
  declaredValueUnit?: any;
}

const currencyOptions = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'CAD',
    value: 'CAD',
  },
];

const LoadInsuranceModal = ({
  handleClose,
  loadInsurance,
  onSubmitLoadInsurance,
  commercialValue,
  declaredValueUnit,
}: LoadInsuranceModalProps) => {
  const { appearance } = useContext(BasicContext);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    commodity: yup.string().required('Commodity is required.'),
    commercialValue: yup.string().required('Commercial value is required.'),
    declaredValueUnit: yup.string().required(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      commodity: loadInsurance?.commodity || '',
      commercialValue: loadInsurance?.commercialValue
        ? getFormattedNumber(loadInsurance?.commercialValue)
        : commercialValue ?? '',
      declaredValueUnit: declaredValueUnit ? declaredValueUnit : 'CAD',
    },
  });

  const onSubmit = async (formData: any) => {
    setIsLoading(true);

    if (appearance?.load_insurance_sell_per > 0) {
      let insuranceVal =
        (parseFloat(formData.commercialValue) *
          appearance?.load_insurance_sell_per) /
        100;
      formData.insurance_amount = insuranceVal;
    } else {
      formData.insurance_amount = 0;
    }

    onSubmitLoadInsurance(formData);
  };

  return (
    <CommonModal
      title={'New Load Insurance Request'}
      titleDesc="Create a new request for load insurance."
      handleClose={() => handleClose(false)}
      headerIcon={<FilePlus01 className="w-5 h-5" />}
      size={'max-w-[500px] overflow-unset'}
      isOverflow={false}
      secondaryBtnOnClick={() => handleClose(false)}
      modalHeaderClass=""
      headerInfoClass="!gap-0 [&>.text-xs]:text-sm"
      modalClassName="flex flex-col "
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      primaryBtnText={loadInsurance?.id ? 'Update' : 'Create'}
      secondaryBtnText="Cancel"
    >
      <div className="p-5">
        <div className="flex-1 md:order-[0] order-2 flex flex-col gap-4">
          <Controller
            name="commodity"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                required
                label="Commodity"
                inputName="commodity"
                placeholder="Commodity"
                parentClassName="!mb-0"
                className=""
                value={value}
                onChangeFunc={onChange}
                errorText={errors.commodity ? errors.commodity.message : null}
                labelClassName="block mb-1.5"
              />
            )}
          />

          <div className="">
            <div className="flex gap-2.5">
              <Controller
                name={`commercialValue`}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputText
                    parentClassName="flex-1"
                    label="Commercial Value"
                    labelClassName="block mb-1.5"
                    required
                    inputName="commercialValue"
                    inputType="number"
                    placeholder="Enter number"
                    value={`${value}`}
                    className={`pl-8 ${
                      errors?.commercialValue?.message ? 'border-[#f04438]' : ''
                    }`}
                    shouldFormatNumber={true}
                    icon={<CurrencyDollar className="input-currency-sign" />}
                    onChangeFunc={(e) => {
                      handleInputChange(e, 'change', onChange);

                      if (error?.message) {
                        clearErrors('commercialValue');
                      }
                    }}
                  />
                )}
              />
              <Controller
                name={`declaredValueUnit`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectBox
                    name="declaredValueUnit"
                    label="Currency"
                    labelClassName="form_label block mb-1.5"
                    parentClassName="currency-dropdown"
                    selectoptiontext="currency-dropdown-text"
                    id="declaredValueUnit"
                    classComp={`rounded-none rounded-r-lg `}
                    size="sm"
                    options={currencyOptions}
                    onChangeFunc={(e: any) => {
                      onChange(e?.value);
                    }}
                    value={currencyOptions?.find((e: any) => e?.value == value)}
                  />
                )}
              />
            </div>
            <div className="[&:empty]:mt-0 mt-1.5">
              {errors?.commercialValue && (
                <ErrorMsg errorText={errors?.commercialValue?.message} />
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default LoadInsuranceModal;
