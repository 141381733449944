import { ArrowRight, Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { PATH } from 'src/constants/path';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
} from 'src/utils/CommonFunctions';

import TagsModal from './TagsModal';

interface IProps {
  order: any;
  isLoading: boolean;
  currentLegData: any;
  tagList: any;
}

const OrderInfoSection = ({
  order,
  isLoading,
  currentLegData,
  tagList,
}: IProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [orderTags, setOrderTags] = useState([]);

  const linkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (linkRef.current) {
      // Check if the text is truncated
      setShowTooltip(linkRef.current.scrollWidth > linkRef.current.clientWidth);
    }
  }, [order?.customerName, isLoading]);

  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      // Check if the text is truncated
      setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [order?.consigneeAddress?.address1, isLoading]);

  useEffect(() => {
    if (textRef.current) {
      // Check if the text is truncated
      setShowTooltip(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [order?.consigneeAddress?.address1, isLoading]);

  useEffect(() => {
    if (order?.tags?.length > 0) {
      setOrderTags(order?.tags);
    }
  }, [order?.tags]);

  const tooltipItems = (
    <div className="flex flex-wrap gap-2 max-w-[210px] px-0.5 py-1.5 w-max">
      {orderTags?.slice(0, 12).map((tag: any) => (
        <div key={tag.id}>
          <BadgeCmp
            mainClassName="!py-[3px] !pl-1 !pr-1.5"
            badgeTextColor="text-nowrap break-keep"
            type="success"
            style="modern"
            isHidePillDot
          >
            <p className="mr-1 text-[10px] h-[12px]">{tag.emoji}</p>
            {tag.name}
          </BadgeCmp>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div className="flex gap-2.5">
        <div className="rounded-xl border border-utilityGray200 shadow-xs overflow-hidden flex-1">
          <div className="flex flex-wrap border-b border-utilityGray200">
            <div className="md:w-1/4 px-4 py-3 space-y-1">
              <h6 className="text-grayLight600 text-xs font-semibold ">
                Customer
              </h6>
              <div className="flex items-center gap-x-2">
                {order?.customerImage ? (
                  <img
                    src={`${order?.customerImageUrl}${order?.customerImage}`}
                    className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                  />
                ) : (
                  <div
                    className={`w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px] ${
                      isLoading ? 'custom-loading image-loading' : ''
                    }`}
                  >
                    {getShortName(order?.customerName)}
                  </div>
                )}

                <TooltipCmp message={showTooltip ? order?.customerName : ''}>
                  <Link
                    to={`${PATH.CUSTOMER_DETAILS}/${order?.customerId}`}
                    className={`block text-grayLight600 text-xs leading-[1.8] font-normal truncate ${
                      isLoading ? 'custom-loading' : ''
                    } `}
                    ref={linkRef}
                  >
                    {!isLoading && order?.customerName
                      ? order?.customerName
                      : 'DSS Marine Incorporated (USD)'}
                  </Link>
                </TooltipCmp>
              </div>
            </div>

            <div className="md:w-1/4 px-4 py-3 space-y-1">
              <h6 className="text-grayLight600 text-xs font-semibold ">
                Address
              </h6>
              <div className="flex items-center gap-x-2">
                <div className="truncate">
                  <TooltipCmp
                    message={showTooltip ? order?.shipperAddress?.address1 : ''}
                  >
                    <p
                      ref={textRef}
                      className={` text-grayLight600 text-xs leading-[1.8] font-medium truncate ${
                        isLoading ? 'custom-loading w-12' : ''
                      }`}
                    >
                      {order?.shipperAddress?.address1
                        ? order?.shipperAddress?.address1
                        : '-'}
                    </p>
                  </TooltipCmp>
                </div>
                <ArrowRight className="w-3.5 h-3.5 text-grayLight900 flex-none" />
                <div className="truncate">
                  <TooltipCmp
                    message={
                      showTooltip ? order?.consigneeAddress?.address1 : ''
                    }
                    parentClassName="max-w-90"
                  >
                    <p
                      ref={textRef}
                      className={`text-grayLight600 text-xs leading-[1.8] font-medium truncate ${
                        isLoading ? 'custom-loading w-12' : ''
                      }`}
                    >
                      {order?.consigneeAddress?.address1
                        ? order?.consigneeAddress?.address1
                        : '-'}
                    </p>
                  </TooltipCmp>
                </div>
              </div>
            </div>
            <div className="md:w-1/4 px-4 py-3 space-y-1">
              <h6 className="text-grayLight600 text-xs font-semibold ">Tags</h6>

              {isLoading ? (
                <div className="flex items-center gap-x-1 text-primary ">
                  <BadgeCmp
                    type="success"
                    style="modern"
                    isHidePillDot
                    mainClassName="custom-loading"
                  >
                    <span className="mr-1">🏷️</span>
                    <span className="custom-loading w-12"></span>
                  </BadgeCmp>
                </div>
              ) : orderTags?.length > 0 ? (
                <div className="flex items-center gap-x-1.5">
                  {orderTags?.slice(0, 1).map((tag: any) => (
                    <BadgeCmp
                      mainClassName="!py-[3px] !pl-1 !pr-1.5"
                      type="success"
                      style="modern"
                      isHidePillDot
                      key={tag.id}
                    >
                      <p className="mr-1 text-[10px] h-[12px]">{tag.emoji}</p>
                      {tag.name}
                    </BadgeCmp>
                  ))}

                  {orderTags?.length > 1 && (
                    <>
                      <ActionTooltip
                        message={tooltipItems}
                        type="light"
                        tooltipPosition="right"
                        isOpenChildTopPosOnTop={false}
                        openOnHover={true}
                      >
                        <BadgeCmp
                          mainClassName="!py-[3px] !pl-1 !pr-1.5 cursor-pointer !flex"
                          type="success"
                          style="modern"
                          isHidePillDot
                        >
                          +{orderTags?.length - 1}
                        </BadgeCmp>
                      </ActionTooltip>
                    </>
                  )}

                  <Plus
                    className="w-4 h-4 flex-none cursor-pointer text-primary"
                    onClick={() => setShowTagModal(true)}
                  />
                </div>
              ) : (
                <ButtonCmp
                  className="btn-outline-primary !bg-transparent !p-0 !border-none !gap-1 !text-sm !font-medium"
                  onClick={() => setShowTagModal(true)}
                >
                  <Plus className="text-primary w-4 h-4" />
                  Add Tag
                </ButtonCmp>
              )}
            </div>

            <div className="md:w-1/4 px-4 py-3 space-y-1">
              <h6 className="text-grayLight600 text-xs font-semibold ">
                Sales Rep.
              </h6>
              <div className="flex items-center gap-x-1">
                {order?.salesRepFirstName &&
                  (order?.salesImage && order?.salesImageUrl ? (
                    <img
                      src={order?.salesImageUrl + order?.salesImage}
                      className="w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                    />
                  ) : (
                    <div
                      className={`w-5 h-5 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-[8px]  ${
                        isLoading ? 'custom-loading image-loading' : ''
                      }`}
                    >
                      {getShortName(
                        `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                      )}
                    </div>
                  ))}

                <p
                  className={`text-textSecondary text-xs leading-[1.8] font-medium ${
                    isLoading ? 'custom-loading' : ''
                  }`}
                >
                  {!isLoading
                    ? order?.salesRepId
                      ? `${order?.salesRepFirstName} ${order?.salesRepLastName}`
                      : '-'
                    : 'Polaris Transportation'}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap bg-gray50">
            <div className="md:w-1/4 px-4 py-3 flex items-center gap-x-1">
              <h6 className="text-grayLight600 text-xs font-normal ">
                Created At:
              </h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: order
                    ? getDateWithSuffixFormat(
                        getFormattedDate(
                          order.createdAt,
                          `Do MMMM YYYY`,
                          true,
                          true
                        )
                      )
                    : '-',
                }}
                className={`xlm:max-w-56 max-w-40 text-primary text-xs font-medium truncate ${
                  isLoading ? 'custom-loading' : ''
                }`}
              ></p>
            </div>
            <div className="md:w-1/4 px-4 py-3 flex items-center gap-x-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Claim ID:
              </h6>
              <p
                className={`text-xs font-medium  ${
                  currentLegData?.claim?.claimID
                    ? 'text-primary'
                    : 'text-textSecondary'
                } ${isLoading ? 'custom-loading' : ''}`}
              >
                {!isLoading
                  ? currentLegData?.claim?.claimID
                    ? `#${currentLegData?.claim?.claimID}`
                    : '-'
                  : '#CLM-4562'}
              </p>
            </div>
            <div className="md:w-1/4 px-4 py-3 flex items-center gap-x-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Dispute ID:
              </h6>
              <p
                className={`text-xs font-medium ${
                  currentLegData?.dispute?.disputeID
                    ? 'text-primary'
                    : 'text-textSecondary'
                } ${isLoading ? 'custom-loading' : ''}`}
              >
                {!isLoading
                  ? currentLegData?.dispute?.disputeID
                    ? `#${currentLegData?.dispute?.disputeID}`
                    : '-'
                  : '#CLM-4562'}
              </p>
            </div>
            <div className="md:w-1/4 px-4 py-3 flex items-center gap-x-1">
              <h6 className="text-grayLight600 text-xs font-normal">
                Load Insurance ID:
              </h6>
              <Link
                to={`${PATH.LOAD_INSURANCE}/${order?.loadInsurance?.id}`}
                className={`block text-xs font-medium ${
                  order?.loadInsurance?.load_insurance_id
                    ? 'text-primary hover:underline'
                    : ' text-textSecondary'
                } ${isLoading ? 'custom-loading' : ''} `}
              >
                {!isLoading
                  ? order?.loadInsurance?.load_insurance_id
                    ? `#${order?.loadInsurance?.load_insurance_id}`
                    : '-'
                  : '#LIN-45612'}
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showTagModal && (
        <TagsModal
          tagList={tagList}
          handleClose={() => setShowTagModal(false)}
          masterOrderId={order?.id}
          setOrderTags={setOrderTags}
        />
      )}
    </>
  );
};

export default OrderInfoSection;
