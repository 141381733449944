import React from 'react';
import { updateOrCreateCarrierPrice } from 'src/services/OrderService';

import CarrierModal from '../../Common/Carrier/CarrierModal';

interface IProps {
  activeLegData: any;
  action: any;
  setAction: any;
  setActiveLegCarrier: any;
  activeLegCarrier: any;
  masterOrderId: any;
  order: any;
  setActiveLegData: any;
  setTabValues: any;
  setCarrierPrice: any;
  currentLegData: any;
}

const AssignCarrier = ({
  activeLegData,
  action,
  setAction,
  setActiveLegCarrier,
  activeLegCarrier,
  masterOrderId,
  setActiveLegData,
  setTabValues,
  setCarrierPrice,
  currentLegData,
}: IProps) => {
  const handleAddCarrierPrice = (newCarrierPrice: any) => {
    updateOrCreateCarrierPrice({
      ...newCarrierPrice,
      orderLegId: activeLegData?.id,
      masterOrderId: masterOrderId,
    })
      .then((response) => {
        if (response?.data) {
          let carrierPrice = {};

          if (response?.data && response?.data.length > 0) {
            carrierPrice = response.data.map((data: any) => ({
              ...data,
              additional_charge: data.additional_charge
                ? JSON.parse(data.additional_charge)
                : [],
              carrier: data.carrier ? JSON.parse(data.carrier) : {},
            }));
          }

          if (activeLegCarrier?.length > 0) {
            setAction((old: any) => ({ ...old, mode: null }));
          } else {
            setAction((old: any) => ({
              ...old,
              mode: 'create-carrier-thread',
            }));
          }

          setActiveLegCarrier(carrierPrice);

          setActiveLegData((old: any) => ({
            ...old,
            carrierPrice: carrierPrice,
          }));

          if (setTabValues) {
            setTabValues((prevTabValues: any) => ({
              ...prevTabValues,
              [activeLegData?.legId]: {
                ...prevTabValues[activeLegData?.legId],
                carrierPrice: carrierPrice,
              },
            }));
          }

          if (currentLegData && currentLegData?.id === activeLegData?.id) {
            setCarrierPrice(carrierPrice);
          }
        }
      })
      .catch(console.error);
  };

  const carrierEditData =
    activeLegCarrier?.length > 0
      ? {
          ...activeLegCarrier?.[0],
          carrierId: activeLegCarrier?.[0]?.carrierId || 'unassigned',
        }
      : null;

  return (
    <>
      {action.mode === 'assign-carrier' && (
        <CarrierModal
          handleClose={() => {
            setAction((old: any) => ({ ...old, mode: null }));
          }}
          handleAddCarrierPrice={handleAddCarrierPrice}
          carrierEditData={carrierEditData}
        />
      )}
    </>
  );
};

export default AssignCarrier;
