import React, { useEffect, useRef, useState } from 'react';
import {} from '@untitled-ui/icons-react/build/cjs';
import { convertSecondsToTime, getShortName } from 'src/utils/CommonFunctions';

const TeamPerformanceDetailsRow = ({ data }: any) => {
  const textRef = useRef<any>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  console.log(
    '⭐️ ~ TeamPerformanceDetailsRow ~ isOverflowing:',
    isOverflowing
  );

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowing(
          textRef?.current?.scrollWidth > textRef?.current?.clientWidth
        );
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, []);

  return (
    <>
      <td className={`px-5 py-4  min-w-64 w-64 max-w-[1px]`}>
        <div className="flex items-center truncate">
          <div className="table-profile-wrap notification-mark-wrap">
            {data?.image ? (
              <img
                src={`${data?.imageUrl}${data?.image}`}
                className="table-profile-icn-circle-xl rounded-md"
                alt={data?.name}
                title={data?.name}
                // onError={onError}
              />
            ) : (
              <div className="table-profile-customer-circle-xl rounded-md">
                {getShortName(`${data?.firstName} ${data?.lastName}`)}
              </div>
            )}
            {data?.hasUnreadNotification ? (
              <span className={`notification-dot`}></span>
            ) : (
              ''
            )}
          </div>

          <div className="pl-3 truncate">
            <h6 className="text-grayLight900 font-medium leading-tight truncate">
              {`${data?.firstName}  ${data?.lastName}`}
            </h6>
          </div>
        </div>
      </td>

      <td className={`px-5 py-4`}>
        <span className="">{data?.totalRequest}</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">
          {data?.avgTimeToClassify
            ? convertSecondsToTime(data?.avgTimeToClassify)
            : '0'}
        </span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data?.totalQuote}</span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">
          {data?.avgTimeToQuote
            ? convertSecondsToTime(data?.avgTimeToQuote)
            : '0'}
        </span>
      </td>
      <td className={`px-5 py-4`}>
        <span className="">{data?.totalOrders}</span>
      </td>

      <td className={`px-5 py-4`}>
        <span className="">{data?.wonLoss}</span>
      </td>
    </>
  );
};

export default TeamPerformanceDetailsRow;
