import { XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';

import inboundEmoji from '../../../../../assets/img/inbound-emoji.webp';

import Activity from './Activity';
import Analytics from './Analytics';
import Overview from './Overview';

interface IProps {
  handleClose: () => any;
}

const OverviewRateGathering = ({ handleClose }: IProps) => {
  const [section, setSection] = useState('overview');
  console.log('Overview Rate Gathering');

  return (
    <CommonModal
      handleClose={handleClose}
      size={'max-w-[750px]'}
      secondaryBtnOnClick={() => handleClose()}
    >
      <div className="m-1.5 px-4 py-3 flex justify-end h-[120px] bg-gradient-to-r from-[#E8FDF0] from-[22.24%] via-[#F5FFF9] via-[34.36%] to-[#DBFAE6] to-[54.03%] rounded-xl">
        <div
          onClick={handleClose}
          className={`cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full flex-none `}
        >
          <XClose className="text-gray400 hover:text-textSecondary" />
        </div>
      </div>
      <div className="p-5 space-y-5">
        <div className="flex justify-between -mt-[67px]">
          <div className="w-[106px] h-[106px] rounded-full border-4 border-white flex-none bg-utilitySuccess200 p-3">
            <img src={inboundEmoji} className="w-full h-full object-contain" />
          </div>
          <div className="flex items-end gap-x-2 mb-2.5">
            <ButtonCmp
              className={`btn-outline-primary ${
                section === 'overview' &&
                'bg-primary50 border-borderSecondary text-primary700'
              }`}
              onClick={() => setSection('overview')}
            >
              Overview
            </ButtonCmp>
            <ButtonCmp
              className={`btn-outline-primary ${
                section === 'activity' && 'bg-primary text-white'
              }`}
              onClick={() => setSection('activity')}
            >
              Activity
            </ButtonCmp>
            <ButtonCmp
              className={`btn-outline-primary ${
                section === 'analytics' && 'bg-primary text-white'
              }`}
              onClick={() => setSection('analytics')}
            >
              Analytics
            </ButtonCmp>
          </div>
        </div>

        {/* Profile Section */}
        <div className="space-y-1.5">
          <div className="flex items-center gap-1.5">
            <h2 className="text-grayLight900 text-xl font-semibold">Richard</h2>

            <BadgeCmp style="modern" type="success">
              LTL
            </BadgeCmp>
          </div>
          <p className="text-gray500 text-sm font-normal">
            I can help you gather and classify rates for your loads
          </p>
        </div>

        {/* Stats Section */}
        {section === 'overview' && <Overview />}
        {section === 'activity' && <Activity />}
        {section === 'analytics' && <Analytics />}
      </div>
    </CommonModal>
  );
};

export default OverviewRateGathering;
