import 'moment-timezone';

// eslint-disable-next-line import/order
import React from 'react';

import './assets/css/app.css';
import './assets/css/prosemirror.css';
import './assets/scss/app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Loader from './components/Loader';
import { AuthContext, AuthProvider } from './context/AuthContext';
import { BasicProvider } from './context/BasicContext';
import { DirectCarrierRatesProvider } from './context/DirectCarrierRatesContext';
import { EntityCountsProvider } from './context/EntityCountsContext';
import { NotificationsProvider } from './context/NotificationsContext';
import useKeyboardAdjustments from './hooks/useKeyboardAdjustments';
import { store, persistor } from './redux/store';
import Routes from './routes';

// moment.tz.setDefault(TIMEZONE);

function App() {
  useKeyboardAdjustments();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <BasicProvider>
            <EntityCountsProvider>
              <NotificationsProvider>
                <DirectCarrierRatesProvider>
                  <AuthContext.Consumer>
                    {({ loader }) => (loader ? <Loader /> : <Routes />)}
                  </AuthContext.Consumer>
                </DirectCarrierRatesProvider>
              </NotificationsProvider>
            </EntityCountsProvider>
          </BasicProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
