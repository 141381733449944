import { ArrowNarrowRight } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
// import ServiceDetail from 'src/app/QuotingHub/QuotingDashboard/Quote/ServiceDetail';
// import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import CheckBox from 'src/components/CheckBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { getFormattedNumber, getShortName } from 'src/utils/CommonFunctions';

// import BanyanIcon from '../../../../assets/img/banyan.svg';
// import defaultImage from '../../../../assets/img/default-image.jpg';
// import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

interface IProps {
  data: any;
  currency: any;
  activeCarrier: any;
  onRowClick: any;
}

const ActiveCarrierRows = ({
  data,
  currency,
  activeCarrier,
  onRowClick,
}: IProps) => {
  const carrierData = data;

  const handleCheckboxChange = () => {
    onRowClick(data ? data : null);
  };

  const getRemainingDays = (expirationDate: string): string => {
    const expDate = new Date(expirationDate);
    const today = new Date();
    expDate.setDate(expDate.getDate() + 1);

    const diffTime = expDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return 'Today';
    } else if (diffDays === 1) {
      return 'Tomorrow';
    } else if (diffDays > 1) {
      return `${diffDays} Days`;
    } else {
      return 'Expired';
    }
  };

  const isExpired = getRemainingDays(data.expirationDate) === 'Expired';

  return (
    <>
      <td className="px-5 py-4 w-[55px] min-w-[55px] max-w-[55px]">
        <span onClick={(e) => e.stopPropagation()}>
          <CheckBox
            classes="z-10"
            onChangeFunc={() => {
              if (!isExpired) handleCheckboxChange();
            }}
            checked={activeCarrier?.id === data?.id}
            disabled={isExpired}
          />
        </span>
      </td>
      <td
        className="px-5 py-4 w-[13.92%] min-w-[13.92%] max-w-px"
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Carrier
          </h6>

          {carrierData ? (
            <div className="flex items-center gap-1.5">
              <div className="table-profile-wrap">
                {carrierData?.image ? (
                  <img
                    className="table-profile-icn-circle-sm"
                    src={carrierData?.imageUrl + carrierData?.image}
                  />
                ) : (
                  <div className="table-profile-customer-circle-sm">
                    {getShortName(carrierData?.name)}
                  </div>
                )}
              </div>

              <h6 className="text-textSecondary font-medium leading-[1.5] truncate">
                <TooltipCmp
                  message={
                    carrierData?.name?.length > 8 ? carrierData?.name : ''
                  }
                >
                  {carrierData?.name}
                </TooltipCmp>
              </h6>
            </div>
          ) : (
            '-'
          )}
        </div>
      </td>

      <td
        className={`px-5 py-4 min-w-[12.92%] w-[12.92%] max-w-[12.92%]`}
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Provider
          </h6>
          {(data.isFreightcom || data.isFreightcom === 1) && (
            <div className="text-textSecondary font-medium leading-[1.5] truncate">
              Freightcom
            </div>
          )}
          {(data.isAmericanGroup || data.isAmericanGroup === 1) && (
            <div className="text-textSecondary font-medium leading-[1.5] truncate">
              American Group
            </div>
          )}
          {(data.isBanyan || data.isBanyan === 1) && (
            <div className="text-textSecondary font-medium leading-[1.5] truncate">
              Banyan
            </div>
          )}
          {!data.isBanyan && !data.isFreightcom && !data.isAmericanGroup && (
            <div className="text-textSecondary font-medium leading-[1.5] truncate">
              Direct
            </div>
          )}
        </div>
      </td>
      <td
        className={`px-5 py-4 w-[11.92%] min-w-[11.92%] max-w-[11.92%]`}
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Expiry
          </h6>
          {data.expirationDate && (
            <BadgeCmp
              style="modern"
              type={
                moment(data.expirationDate)
                  .add(1, 'days')
                  .isBefore(moment().startOf('day'))
                  ? 'red'
                  : 'warning'
              }
            >
              {getRemainingDays(data.expirationDate)}
            </BadgeCmp>
          )}
        </div>
      </td>
      <td
        className="px-5 py-4 w-[12.92%] max-w-[12.92%] min-w-[12.92%]"
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Base Cost
          </h6>
          <p className="text-textSecondary font-medium leading-[1.5] truncate">
            $907.94
          </p>
        </div>
      </td>
      <td
        className="px-5 py-4 w-[12.92%] max-w-[12.92%] min-w-[12.92%]"
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Total Cost
          </h6>
          <p className="text-textSecondary font-medium leading-[1.5] truncate">
            {data?.totalCharge ? `$${data?.totalCharge}` : '-'}
          </p>
        </div>
      </td>
      <td
        className="px-5 py-4 w-[12.92%] max-w-[12.92%] min-w-[12.92%]"
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Total Margin
          </h6>
          <p className="text-textSecondary font-medium leading-[1.5] truncate">
            {/* {data?.additionalCharges > 0 || data?.services?.length > 0 ? (
              <ActionTooltip
                openOnHover={true}
                tooltipPosition="center"
                parentClassName="w-max"
                isOpenChildTopPosOnTop={true}
                type="dark"
                message={<ServiceDetail carrierServices={data} />}
              >
                {data.additionalCharges === 0
                  ? '$0.00'
                  : currency === CURRENCY.CAD
                  ? `$${getFormattedNumber(data.additionalCharges)}`
                  : `$${getFormattedNumber(data.additionalChargesUSD)}`}
              </ActionTooltip>
            ) : data.additionalCharges === 0 ? (
              '$0.00'
            ) : currency === CURRENCY.CAD ? (
              `$${getFormattedNumber(data.additionalCharges)}`
            ) : (
              `$${getFormattedNumber(data.additionalChargesUSD)}`
            )} */}
            {currency === CURRENCY.CAD
              ? `$${getFormattedNumber(data?.margin)}`
              : `$${getFormattedNumber(data.marginUSD)}`}
          </p>
        </div>
      </td>
      <td
        className="px-5 py-4 w-[12.92%] max-w-[12.92%] min-w-[12.92%]"
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <div className="space-y-1">
          <h6 className="text-gray500 text-[11px] leading-[1.2] font-normal uppercase">
            Total Sell Price
          </h6>
          <p className="text-textSecondary font-medium leading-[1.5] truncate">
            {data?.finalCharge ? `$${data?.finalCharge}` : '-'}
          </p>
        </div>
      </td>
      <td
        className="px-5 py-4 w-[55px] min-w-[55px] max-w-[55px]"
        onClick={() => {
          if (!isExpired) handleCheckboxChange();
        }}
      >
        <ArrowNarrowRight className="w-4 h-4 text-primary" />
      </td>
    </>
  );
};

export default ActiveCarrierRows;
