import { SearchLg, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';

import CommonModal from '../../../../../components/CommonModal';

import TeamPerformanceDetailsRow from './TeamPerformanceDetailsRow';
import TeamPerformanceDetailsRowLoader from './TeamPerformanceDetailsRowLoader';

interface IProps {
  filteredTeamPerformanceList: any[];
  isLoading: boolean;
  handleClose: () => void;
}

const TeamPerformanceDetailTable = ({
  filteredTeamPerformanceList,
  handleClose,
  isLoading,
}: IProps) => {
  const [sortData, setSortData] = useState({
    sortField: 'user',
    sortType: 'asc',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [searchInput, setSearchInput] = useState('');
  const [pageItem, setPageItem] = useState([...filteredTeamPerformanceList]);

  const [filteredTeamList, setFilteredTeamList] = useState([
    ...filteredTeamPerformanceList,
  ]);
  const headCells = useMemo(
    () => [
      {
        id: 'user',
        name: 'User',
        sortable: true,
      },
      {
        id: 'totalRequest',
        name: 'Total Quote Requests',
        sortable: true,
      },
      {
        id: 'avgTimeToClassify',
        name: 'Avg. Time to Classify',
        sortable: true,
      },
      {
        id: 'totalQuote',
        name: 'Total Quotes',
        sortable: true,
      },
      {
        id: 'avgTimeToQuote',
        name: 'Avg. Time to Quote',
        sortable: true,
      },

      {
        id: 'totalOrders',
        name: 'Total Orders',
        sortable: true,
      },
      {
        id: 'wonLoss',
        name: 'Win/Loss',
        sortable: true,
      },
    ],
    []
  );

  const recordsPerPageArr = [
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedCustomerList = filteredTeamPerformanceList.sort(
      (a: any, b: any) => {
        console.log('⭐️ ~ handleSorting ~ sortField:', sortField);

        if (sortField === 'user') {
          console.log('⭐️ ~ handleSorting ~ a.firstName:', a.firstName);
          const nameA = a.firstName.toUpperCase(); // to ensure case-insensitive comparison
          const nameB = b.firstName.toUpperCase(); // to ensure case-insensitive comparison

          if (sortType === 'asc') {
            return nameA.localeCompare(nameB);
          }

          return nameB.localeCompare(nameA);
        } else {
          if (a[sortField] < b[sortField]) {
            return sortType === 'asc' ? -1 : 1;
          }

          if (a[sortField] > b[sortField]) {
            return sortType === 'asc' ? 1 : -1;
          }
        }

        return 0;
      }
    );

    setFilteredTeamList([...sortedCustomerList]);
  };

  useEffect(() => {
    if (searchInput) {
      const searchTerm = searchInput.toLowerCase().trim();

      const filterList = filteredTeamPerformanceList.filter((item: any) => {
        const firstName = item.firstName?.toLowerCase().trim() || '';
        const lastName = item.lastName?.toLowerCase().trim() || '';
        const fullName = `${firstName} ${lastName}`.trim();

        return fullName.includes(searchTerm);
      });

      setFilteredTeamList([...filterList]);
    } else {
      setFilteredTeamList([...filteredTeamPerformanceList]);
    }
  }, [searchInput, filteredTeamPerformanceList]);

  useEffect(() => {
    if (filteredTeamList.length > recordsPerPage) {
      const pageItems = filteredTeamList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredTeamList]);
    }
  }, [filteredTeamList, currentPage, recordsPerPage]);

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  return (
    <CommonModal size="max-w-7xl" handleClose={() => {}}>
      <div className="p-5">
        <div className=" min-h-full w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          <div className="table-top-header flex-wrap ">
            <div className="table-left-wrap">
              <div className="table-title-wrap flex-wrap">
                <h5 className="table-title">Team Performance</h5>
              </div>
              <p className="table-subtitle">
                Manage your team members and their account permissions here.
              </p>
            </div>
            <div className="table-right-wrap ">
              <InputText
                inputName="searchOrder"
                placeholder="Search"
                className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
                icon={
                  <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
                }
                value={searchInput}
                inputType="text"
                isClearable={true}
                onChangeFunc={(e: any) => setSearchInput(e.target.value)}
                parentClassName="table-searchInput"
              />
              <div className="table-recordsPerPage">
                <SelectBox
                  name="recordsPerPageGroup"
                  id="recordsPerPageGroup"
                  className="form_control shadow"
                  size="sm"
                  options={recordsPerPageArr}
                  isSearchable={false}
                  onChangeFunc={(event: any) => {
                    setRecordsPerPage(event.value);
                    setCurrentPage(1);
                  }}
                  value={recordsPerPageArr.find(
                    (val: any) => val.value === recordsPerPage
                  )}
                />
              </div>
              <ButtonCmp
                className="btn_secondary_black lg:!px-[9px] !px-2"
                icon={<XClose className="w-4 h-4" />}
                iconSide="left"
                onClick={handleClose}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
          <div className="h-full w-full">
            <div className="overflow-x-auto custom-scrollbar max-h-[calc(100vh_-_265px)] custom-scrollbar-v2 custom-vertical-scrollbar-hide">
              <TableCmp
                params={sortData}
                setParams={setSortData}
                headCells={headCells}
                tableDataArr={pageItem}
                TableRowCmp={TeamPerformanceDetailsRow}
                TableLoaderRowCmp={TeamPerformanceDetailsRowLoader}
                isTableDataLoading={isLoading}
                numberOfSkeletonRows={recordsPerPage}
                tableHeaderClass="sticky top-0 z-[1] shadow-[inset_0_-1px_0_rgba(234,236,240,1)] [&>tr]:border-0"
              />
            </div>
            {!isLoading && !filteredTeamList.length && (
              <NotFoundUI
                title="No Team Performance Found"
                desc="There are no data for team member performance related data."
                containerClassName="min-h-[calc(100%_-_172.5px)] !h-auto"
              />
            )}
            <div className="w-full">
              {!isLoading && (
                <CustomPagination
                  recordsPerPage={recordsPerPage}
                  totalRecords={filteredTeamList.length}
                  currentPage={currentPage}
                  handlePagination={setCurrentPage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default TeamPerformanceDetailTable;
