import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { getCustomerHeatMapAnalytics } from 'src/services/CustomerService';

function generateDailyHeatmapData(
  dailyOrderData: any,
  setSeries: any,
  year: number
) {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dailyData: any = {};

  function getWeekOfMonth(date: any) {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

    return Math.ceil((date.getDate() + startOfMonth.getDay()) / 7);
  }

  // Aggregate daily data, skipping weekends
  dailyOrderData.forEach(({ order_date, daily_order_count }: any) => {
    const date = new Date(order_date);
    const dayOfWeek = date.getDay();
    const weekOfMonth = getWeekOfMonth(date);

    // Only include Monday (1) to Friday (5)
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      const monthName = months[date.getMonth()];
      const dayLabel = `Week ${weekOfMonth} ${
        days[dayOfWeek - 1]
      } of ${monthName}`;

      if (!dailyData[dayLabel]) {
        dailyData[dayLabel] = 0;
      }
      dailyData[dayLabel] += daily_order_count;
    }
  });

  const startDate = new Date(year, 0, 1);
  const startDayIndex = startDate.getDay();
  const dayIndexMap = [6, 0, 1, 2, 3, 4, 5];
  const firstWeekdayIndex = dayIndexMap[startDayIndex];

  const series = days.map((day, dayIndex) => ({
    name: day,
    data: months.flatMap((month, monthIndex) => {
      const firstDayOfMonth = new Date(year, monthIndex, 1);

      const weekdayOffset = (dayIndex - firstDayOfMonth.getDay() + 7 + 1) % 7;
      const blankBoxes: {
        x: string;
        y: number | null;
        formattedDate?: string;
      }[] = [];

      if (monthIndex === 0 && dayIndex < firstWeekdayIndex) {
        blankBoxes.push({
          x: `Week 1 ${day} of ${months[monthIndex]}`,
          y: null,
          formattedDate: '',
        });
      }

      return blankBoxes.concat(
        [1, 2, 3, 4, 5]
          .map(
            (
              week
            ): {
              x: string;
              y: number | null;
              formattedDate?: string;
            } | null => {
              const label = `Week ${week} ${day} of ${month}`;
              const dayOffset = weekdayOffset + (week - 1) * 7;
              const date = new Date(year, monthIndex, 1 + dayOffset);

              if (date.getMonth() !== monthIndex || date.getDate() < 1)
                return null;

              return {
                x: label,
                y: dailyData[label] || null,
                formattedDate: date.toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                }),
              };
            }
          )
          .filter(Boolean) as {
          x: string;
          y: number | null;
          formattedDate?: string;
        }[]
      );
    }),
  }));

  setSeries(series);

  return series;
}

function generateDummySeries() {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dummyData = days.map((day) => ({
    name: day,
    data: months.flatMap((month) =>
      [1, 2, 3, 4, 5].map((week) => ({
        x: `Week ${week} ${day} of ${month}`,
        y: Math.floor(Math.random() * 10),
      }))
    ),
  }));

  return dummyData;
}

const mapFilterArr: any = [
  {
    value: 'OrderHeatMap',
    name: 'Order Heat Map',
    isAllow: true,
  },
  {
    value: 'QuoteHeatMap',
    name: 'Quote Heat Map',
    isAllow: true,
  },
];

const HeatMapCmp = ({ customerId }: any) => {
  const dummySeries = generateDummySeries();

  const handleChartLoad = (config: any) => {
    const rects = document.querySelectorAll('.apexcharts-heatmap-rect');

    rects.forEach((rect: any) => {
      const seriesIndex: any = rect.getAttribute('index');
      const dataPointIndex: any = rect.getAttribute('j');
      const data = config.series[seriesIndex]?.data[dataPointIndex];

      if (!data?.formattedDate) {
        rect.classList.add('hidden');
      }
    });
  };

  const options: ApexOptions = {
    chart: {
      type: 'heatmap',
      toolbar: {
        show: false,
      },
      events: {
        mounted: function (chartContext: any, config: any) {
          handleChartLoad(config.config);
        },
        updated: function (chartContext: any, config: any) {
          handleChartLoad(config.config);
        },
      },
    },
    tooltip: {
      custom: function ({ seriesIndex, dataPointIndex, w }: any) {
        const data = w.config.series[seriesIndex].data[dataPointIndex];

        return `<div class="p-2">
          <span>${data.formattedDate} : ${data.y ? data.y : 0}</span>
        </div>`;
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.5,
        colorScale: {
          ranges: [
            { from: 0, to: 0, color: '#C7D7FE' },
            { from: 1, to: 10, color: '#8098F9' },
            { from: 11, to: 20, color: '#6172F3' },
            { from: 21, to: 30, color: '#444CE7' },
            { from: 31, to: 60, color: '#2D3282' },
          ],
        },
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'category',
      tooltip: { enabled: false },
      labels: {
        rotate: -45,
        formatter: (val: any) => {
          if (val.includes('Week 3')) {
            return val.split(' of ')[1];
          }

          return '';
        },
        style: {
          fontWeight: 'bold',
        },
      },
    },
  };

  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [mapActiveTab, setMapActiveTab] = useState('OrderHeatMap');
  const [selectedDate, setSelectedDate] = useState(moment().year());

  const yearArr = [];

  for (let i = moment().year(); i >= 2020; i--) {
    yearArr.push({
      value: i,
      label: `${i}`,
    });
  }

  const getHeatMapData = (param: any) => {
    setIsLoading(true);
    getCustomerHeatMapAnalytics(param)
      .then((response) => {
        if (response.data) {
          generateDailyHeatmapData(response.data, setSeries, selectedDate);
        }
      })
      .catch((e: any) => {
        console.log('e', e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const param = {
      customerId,
      type: mapActiveTab,
      timeFrame: moment(selectedDate).year(),
    };
    getHeatMapData(param);
  }, [mapActiveTab, selectedDate]);

  return (
    <div className="rounded-xl border border-utilityGray200 mt-4">
      <div className="px-4 py-3 flex justify-between gap-x-2 border-b border-utilityGray200">
        <TabButton
          activeClassName="text-grayLight900 bg-utilityGray100"
          tabArray={mapFilterArr}
          handleOnClick={(e: any) => {
            setMapActiveTab(e.target.dataset.value);
          }}
          isActive={mapActiveTab}
        />

        <SelectBox
          name="provider"
          id="provider"
          className="form_control"
          parentClassName="min-w-[90px]"
          labelClassName="block"
          size="sm"
          placeholder="Select Year"
          isClearable={false}
          isSearchable={false}
          options={yearArr}
          onChangeFunc={(event: any) => setSelectedDate(event.value)}
          value={yearArr.filter((val: any) => selectedDate === val.value)}
        />
      </div>
      <div className="px-4 py-6">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={isLoading ? dummySeries : series}
            type="heatmap"
            height={200}
            className={isLoading ? `custom-loading-heat-map` : ''}
          />
        </div>
      </div>
    </div>
  );
};

export default HeatMapCmp;
