import { Attachment01, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useRef, useState } from 'react';
import AiInputText from 'src/components/AiInputText';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import DynamicCKEditor from 'src/components/DynamicCKEditor';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TooltipCmp from 'src/components/TooltipCmp';
import { replyEmail } from 'src/services/QuoteEmailService';
import {
  convertToGBMBKB,
  fileToBase64,
  getShortName,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import pdf from '../../../../assets/img/pdf-icon.png';

interface IProps {
  handleClose: any;
  emailDetails: any;
  draftEmail?: any;
  draftEmailLoading?: any;
  defaultAttachments?: {
    url: string;
    fileName: string;
  }[];
}

const fetchPdfAsFile = async (url: string, name: string) => {
  const response = await fetch(url);
  const blob = await response.blob();

  return new File([blob], `${name}.pdf`, { type: blob.type });
};

const ReplyRequestModal = ({
  handleClose,
  emailDetails,
  draftEmail,
  draftEmailLoading,
  defaultAttachments,
}: IProps) => {
  const subjectArray = [
    { label: emailDetails?.subject, value: emailDetails?.subject },
  ];

  const getInitialContent = () => {
    const emptyLines = '<p>&nbsp;</p>'.repeat(2);
    const dividerLine = '<p>--------------</p>';
    const draftContent = draftEmail ? draftEmail.replace(/\n/g, '<br>') : '';
    const originalContent = emailDetails?.body?.content
      ?.replace(/\\|\r\n/g, '')
      ?.replace(/<!--[\s\S]*?-->/g, ''); // Remove HTML comments

    return `${draftContent}${emptyLines}${dividerLine}${originalContent}`;
  };

  const initFormData = {
    messageId: emailDetails?.id,
    emailType: '',
    emailBody: getInitialContent(),
    attachments: [],
  };
  const initValidForm = {
    to: true,
    emailBody: true,
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [emailSubject] = useState(emailDetails?.subject || '');
  const [senderEmail] = useState(
    emailDetails?.sender?.emailAddress?.address || ''
  );
  const [senderName] = useState(emailDetails?.sender?.emailAddress?.name || '');
  const [formData, setFormData] = useState<any>(initFormData);
  const [validForm, setValidForm] = useState(initValidForm);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailBodyInitialized, setIsEmailBodyInitialized] = useState(false);

  const handleEditorChange = (data: any) => {
    setFormData({ ...formData, emailBody: data });
  };

  useEffect(() => {
    if (!draftEmailLoading && !isEmailBodyInitialized && draftEmail) {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        emailBody: getInitialContent(),
      }));
      setIsEmailBodyInitialized(true);
    }
  }, [draftEmailLoading, draftEmail]);

  useEffect(() => {
    if (!formData?.attachments?.length) {
      defaultAttachments?.forEach((attachment: any) => {
        fetchPdfAsFile(attachment.url, attachment.fileName).then((pdfFile) => {
          setFormData((prev: any) => ({
            ...prev,
            attachments: [pdfFile],
          }));
        });
      });
    }
  }, [defaultAttachments]);

  const isFormValid = () => {
    let valid = true;
    const validFormData = {
      to: true,
      emailBody: true,
    };

    if (formData.emailBody?.trim() === '') {
      validFormData.emailBody = false;
      valid = false;
    }

    setValidForm({ ...validForm, ...validFormData });

    return valid;
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();

    if (!isFormValid()) {
      return true;
    }
    setIsLoading(true);
    const payload = formData;

    const attachmentsArray = [];

    if (formData?.attachments?.length) {
      for (const file of formData.attachments) {
        try {
          const base64String = await fileToBase64(file);
          attachmentsArray.push({
            filename: file.name,
            content: base64String,
            contentType: file.type,
          });
        } catch (error) {
          console.error('Error converting file to base64:', error);
        }
      }
    }
    const attachmentsInString = JSON.stringify(attachmentsArray);

    replyEmail({ ...payload, attachments: attachmentsInString })
      .then(() => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
        handleClose(false);
      })
      .catch(() => {
        setIsLoading(false);
        WalToast.error('Reply has been not sent, Something went wrong');
      });
  };

  const openFileManager = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setFormData((prev: any) => ({
        ...prev,
        attachments: [...prev.attachments, ...filesArray],
      }));
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setFormData((prev: any) => ({
      ...prev,
      attachments: prev.attachments.filter((_: any, i: number) => i !== index),
    }));
  };

  return (
    <CommonModal
      title={senderName}
      titleDesc={senderEmail}
      handleClose={() => handleClose(false)}
      headerIconClass="!border-0 !shadow-none !p-0"
      modalHeaderTextClass="flex-1"
      closeBtnClass="self-center"
      headerIconSecondClass="!border-0 !shadow-none order-[3]"
      headerInfoClass="flex-1"
      headerIcon={
        <>
          {/* <img
            src={defaultImage}
            className="w-full h-full rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
          /> */}
          {/* IF NO IMAGE FOUND BELOW COMMENTED CODE WILL BE USED */}
          <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
            {getShortName(senderName)}
          </div>
        </>
      }
      size={'max-w-[688px] h-[70vh]'}
      isOverflow={true}
      modalClassName="flex flex-col"
    >
      <div className="w-full p-5 flex flex-col gap-4 flex-1">
        <SelectBox
          name="recordsPerPageGroup"
          id="recordsPerPageGroup"
          className="form_control shadow"
          size="sm"
          options={subjectArray}
          onChangeFunc={() => {}}
          isSearchable={false}
          value={subjectArray.find((val: any) => val.value === emailSubject)}
        />

        <div
          className={`flex-1 reply-modal-ck-editor ${
            draftEmailLoading ? 'custom-loading' : ''
          }`}
        >
          <div className="h-full">
            {!draftEmailLoading && (
              <DynamicCKEditor
                data={formData?.emailBody}
                onChange={handleEditorChange}
                errorText={
                  !validForm.emailBody && formData?.emailBody?.trim() === ''
                    ? 'email body is required'
                    : null
                }
              />
            )}
          </div>
        </div>
        <ul className="flex flex-wrap -m-1.5 empty:hidden">
          {Array.isArray(formData?.attachments) &&
            formData?.attachments?.length > 0 &&
            formData?.attachments?.map((file: File, index: number) => (
              <li className="p-1.5 w-1/2" key={index}>
                <div className="rounded-lg border border-utilityGray200 bg-white flex items-center gap-2 p-3">
                  {file?.name?.endsWith('.pdf') ? (
                    <img src={pdf} className="h-8" alt="PDF Icon" />
                  ) : (
                    <img
                      src={URL.createObjectURL(file)}
                      className="h-8 w-8 object-cover rounded"
                      alt="File Preview"
                    />
                  )}
                  <div className="flex-1 w-[calc(100%_-_85px)] ">
                    <h6 className="text-textSecondary text-xs font-medium truncate">
                      <TooltipCmp
                        message={file?.name?.length > 20 ? file?.name : null}
                      >
                        {file.name}
                      </TooltipCmp>
                    </h6>
                    <p className="text-grayLight600 text-xs !text-[11px] font-normal">
                      {convertToGBMBKB(file?.size)}
                    </p>
                  </div>
                  <Trash01
                    className="w-5 h-5 cursor-pointer"
                    onClick={() => handleRemoveAttachment(index)}
                  />
                </div>
              </li>
            ))}
        </ul>

        <input
          type="file"
          multiple
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <AiInputText />
      </div>
      <footer className="px-5 py-[14px] border-t flex flex-wrap gap-3 md:flex-row flex-col-reverse sticky bottom-0 bg-white z-[1]">
        <ButtonCmp
          className="btn_secondary_black min-w-24"
          onClick={() => {
            handleClose();
          }}
        >
          Dismiss
        </ButtonCmp>
        <div className="flex gap-3 flex-1 justify-end">
          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2  !border-0 !shadow-none !bg-transparent"
            icon={<Attachment01 className="w-4 h-4" />}
            onClick={openFileManager}
          >
            <></>
          </ButtonCmp>
          <ButtonCmp
            className="btn_primary min-w-20"
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Send
          </ButtonCmp>
        </div>
      </footer>
    </CommonModal>
  );
};

export default ReplyRequestModal;
