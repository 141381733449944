import React, { useMemo, useState, useEffect } from 'react';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';

import FreightStatisticsLoadingRow from './FreighStatisticsLoadingRow';
import FreightStatisticsDetailModal from './FreightStatisticsDetailModal';
import FreightStatisticsRow from './FreightStatisticsRow';

interface RegionalAnalyticsData {
  category: string;
  num_of_loads: number;
  unique_customers: number;
  gross_profit: number;
}

interface IProps {
  regionalAnalyticsData: RegionalAnalyticsData[];
  isDataLoading: boolean;
}

const FreightStatisticsTable = ({
  regionalAnalyticsData,
  isDataLoading,
}: IProps) => {
  const [filteredData, setFilteredData] = useState<RegionalAnalyticsData[]>([
    ...regionalAnalyticsData,
  ]);
  const [sortData, setSortData] = useState({
    sortField: 'category',
    sortType: 'asc',
  });

  const FreightHeadCells = useMemo(
    () => [
      {
        id: 'category',
        name: 'Name',
        sortable: true,
      },

      {
        id: 'num_of_loads',
        name: 'No. of Loads',
        sortable: true,
      },

      {
        id: 'order_weight',
        name: 'Order Weight',
        sortable: true,
      },
      {
        id: 'unique_customers',
        name: 'No. of Customers',
        sortable: true,
      },
      {
        id: 'gross_profit',
        name: 'Total Gross Profit',
        sortable: true,
      },

      {
        id: 'gp_weight',
        name: 'GP Weight',
        sortable: true,
      },
    ],
    []
  );

  // Helper function to get from/to based on category
  const getRouteInfo = (category: string) => {
    let from = '';
    let to = '';
    let isDomestic = false;

    switch (category) {
      case 'Inbound to Canada':
        from = 'USA';
        to = 'Canada';
        break;
      case 'Outbound from Canada':
        from = 'Canada';
        to = 'USA';
        break;
      case 'Canada Domestic':
        from = 'Canada';
        to = 'Canada';
        isDomestic = true;
        break;
      case 'USA Domestic':
        from = 'USA';
        to = 'USA';
        isDomestic = true;
        break;
      default:
        from = '';
        to = '';
    }

    return { from, to, isDomestic };
  };

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    const sortedData = [...regionalAnalyticsData].sort((a: any, b: any) => {
      // Special handling for category field - sort only by from/to locations
      if (sortField === 'category') {
        // Get route info for both items
        const routeA = getRouteInfo(a?.category);
        const routeB = getRouteInfo(b?.category);

        // Create composite strings for sorting: "FROM-TO"
        const valueA = routeA?.isDomestic
          ? a?.category
          : `${routeA?.from}-${routeA?.to}`;
        const valueB = routeB?.isDomestic
          ? b?.category
          : `${routeB?.from}-${routeB?.to}`;

        // Sort by from-to combination
        return sortType === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        // Original numeric sorting logic for other fields
        if (a[sortField] < b[sortField]) {
          return sortType === 'asc' ? -1 : 1;
        }

        if (a[sortField] > b[sortField]) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });
    setFilteredData(sortedData);
  };

  useEffect(() => {
    setFilteredData([...regionalAnalyticsData]);
    handleSorting();
  }, [regionalAnalyticsData]);

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  const [
    isShowFreightStatisticsDetailModal,
    setIsShowFreightStatisticsDetailModal,
  ] = useState(false);

  return (
    <>
      <div className="w-full bg-white rounded-xl shadow border border-utilityGray200">
        <div className="table-top-header">
          <div className="table-left-wrap">
            <div className={`table-title-wrap`}>
              <h5 className="table-title">Regional Freight Statistics</h5>
            </div>
            <p className="table-subtitle">
              Comprehensive Analysis of Regional Freight Sales Data and
              Performance Trends
            </p>
          </div>
        </div>
        <div className=" w-full flex flex-col">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
            <TableCmp
              headCells={FreightHeadCells}
              tableDataArr={isDataLoading ? [] : filteredData}
              TableLoaderRowCmp={FreightStatisticsLoadingRow}
              TableRowCmp={FreightStatisticsRow}
              tableRowCmpProps={{ getRouteInfo }}
              numberOfSkeletonRows={4}
              isTableDataLoading={isDataLoading}
              params={sortData}
              setParams={setSortData}
              // isTableRowClickable={true}
              onRowClick={() => setIsShowFreightStatisticsDetailModal(true)}
            />
          </div>

          {regionalAnalyticsData.length <= 0 && !isDataLoading && (
            <NotFoundUI
              title={'No Freight Statistics Data found'}
              desc={`No freight statistics data available at the moment.`}
              containerClassName="!h-auto"
              iconClassName="!hidden"
            />
          )}
        </div>
      </div>
      {isShowFreightStatisticsDetailModal && (
        <FreightStatisticsDetailModal
          handleClose={() => setIsShowFreightStatisticsDetailModal(false)}
        />
      )}
    </>
  );
};

export default FreightStatisticsTable;
