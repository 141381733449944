import React from 'react';
import InputText from 'src/components/InputText/InputText';
// import TabButton from 'src/components/TabButton';

const DimensionLoading = () => (
  // let tabArray = [
  //   {
  //     value: 'general',
  //     name: 'General',
  //   },
  //   {
  //     value: 'contacts',
  //     name: 'Contacts',
  //   },
  //   {
  //     value: 'spot_quote_email',
  //     name: 'Spot Quote Email',
  //   },
  // ];

  <>
    {/* <div className="px-5 pb-5 space-y-4"> */}
    {/* <TabButton
        parentClassName={`w-full custom-loading`}
        tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px"
        className={`flex-1 `}
        tabArray={tabArray}
        isTab={true}
        isActive="general"
      /> */}

    <div>
      <div className="flex justify-between items-center mb-3">
        <h6 className="text-grayLight900 text-xs font-medium">Dimensions</h6>
        <div className="flex items-center justify-center gap-1.5 custom-loading">
          radio button
        </div>
      </div>

      <div>
        <div className="custom-loading bg-gray50 w-full rounded-[10px]">
          <InputText
            label="Service Type"
            labelClassName="form_label mb-1.5 block"
            parentClassName="flex-1"
          />
          <InputText
            label="Service Type"
            labelClassName="form_label mb-1.5 block"
            parentClassName="flex-1"
          />
        </div>
      </div>
    </div>
    {/* </div> */}
  </>
);
export default DimensionLoading;
