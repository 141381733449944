import { groupBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setIsSocketConnected,
  setSocketIo,
} from 'src/redux/SocketConnection.slice';
import { listHandlingUnit, listService } from 'src/services/CommonService';
import { getMapService } from 'src/services/IntegrationService';
import {
  getLoadLinkVehicleSize,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import { getAppearance } from 'src/services/SettingService';
import { listShippers } from 'src/services/ShipperService';
import { setUserStatus } from 'src/services/UserService';
import { fetchJsFromCDN, getSelectBoxOptions } from 'src/utils/CommonFunctions';

import { CURRENCY, MAP_API, TRIMBLE_KEY } from '../constants/common';

import { AuthContext } from './AuthContext';

export const BasicContext = createContext<any>(null);

export const BasicProvider = (props: any) => {
  const { children } = props;
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [currency, setCurrency] = useState<string>(CURRENCY.CAD);
  const [appearance, setAppearance] = useState<any>({});
  const [mapService, setMapService] = useState<string>(MAP_API);
  const [googleKey, setGoogleKey] = useState<string>(window.GOOGLE_API_KEY);
  const [trimbleKey, setTrimbleKey] = useState<string>(TRIMBLE_KEY);
  const [tooltipGlobalOpen, setTooltipGlobalOpen] = useState(false);
  const [lastSyncDateTimeActual, setLastSyncDateTimeActual] =
    useState(undefined);
  const [allTimeStartDateActual, setAllTimeStartDateActual] =
    useState(undefined);
  const [allTimeStartDateQuote, setAllTimeStartDateQuote] = useState(undefined);

  const [actionTooltipGlobalOpen, setActionTooltipGlobalOpen] = useState(false);
  const [actionDelayTimeout, setActionDelayTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [
    ordersUpdateSocketTriggeredCount,
    setOrdersUpdateSocketTriggeredCount,
  ] = useState<boolean | undefined>(false);
  const [reportMonth, setReportMonth] = useState<string>('');
  const [reportYear, setReportYear] = useState<string>('');
  const [isMakeCall, setIsMakeCall] = useState(false);

  const [isServiceLoading, setIsServiceLoading] = useState(true);
  const [servicesList, setServicesList] = useState<any[]>([]);
  const [serviceTypes, setServiceTypes] = useState<any>({});

  const [handlingUnitOptions, setHandlingUnitOptions] = useState<any>([]);
  const [isHandlingUnitLoading, setIsHandlingUnitLoading] = useState(true);

  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);
  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(true);

  const [vehicleAttrOptions, setVehicleAttrOptions] = useState<any[]>([]);
  const [isVehicleAttrLoading, setIsVehicleAttrLoading] = useState(true);

  const [vehicleSizeOptions, setVehicleSizeOptions] = useState<any[]>([]);

  const [isShipperLoading, setIsShipperLoading] = useState(true);
  const [shippers, setShippers] = useState<any[]>([]);
  const [consignees, setConsignees] = useState<any[]>([]);
  const [shipperOptions, setShipperOptions] = useState<any[]>([]);
  const [consigneeOptions, setConsigneeOptions] = useState<any[]>([]);
  const [isMapLoading, setIsMapLoading] = useState(true);

  const [searchShipper, setSearchShipper] = useState<any>(null);
  const [searchConsignee, setSearchConsignee] = useState<any>(null);

  useEffect(
    () => () => {
      if (actionDelayTimeout) {
        clearTimeout(actionDelayTimeout);
      }
    },
    [actionDelayTimeout]
  );

  const getVehicleTypeOptions = () => {
    setIsVehicleTypeLoading(true);

    getLoadLinkVehicleType()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
      })
      .catch(console.log)
      .finally(() => {
        setIsVehicleTypeLoading(false);
      });
  };

  const getVehicleAttributeOptions = () => {
    setIsVehicleAttrLoading(true);

    listService({})
      .then((response: any) => {
        const updatedListVehicleAttribute = response?.data
          .filter((vt: any) => vt.isOnlyForFTL)
          .map((vt: any) => ({
            label: vt.loadLinkName,
            value: vt.loadLinkTerm,
          }));
        setVehicleAttrOptions(updatedListVehicleAttribute);
      })
      .catch(console.log)
      .finally(() => {
        setIsVehicleAttrLoading(false);
      });
  };

  const getVehicleSizeOptions = () => {
    getLoadLinkVehicleSize()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleSizeOptions(updatedListVehicleTypes);
      })
      .catch(console.log);
  };

  const getListService = () => {
    setIsServiceLoading(true);
    listService({})
      .then((response: any) => {
        if (response.data) {
          const formattedList = response.data.map((item: any) => ({
            id: item.id,
            label: item.name,
            value: item.slug,
          }));

          formattedList.push({
            id: '',
            label: 'Other',
            value: 'other',
          });

          setServicesList(formattedList);

          let serviceByGroup: any = groupBy(response.data, (val) => val.type);
          setServiceTypes(serviceByGroup);
        }
      })
      .finally(() => {
        setIsServiceLoading(false);
      })
      .catch(console.log);
  };

  const getListHandlingUnit = () => {
    listHandlingUnit()
      .then((result: any) => {
        if (result.data && result.data.length) {
          const handlingUnitSelectBoxOptions = getSelectBoxOptions(
            result?.data,
            'id',
            'name'
          );
          setHandlingUnitOptions(handlingUnitSelectBoxOptions);
        } else {
          setHandlingUnitOptions([]);
        }
      })
      .finally(() => {
        setIsHandlingUnitLoading(false);
      })
      .catch((error) => {
        setHandlingUnitOptions([]);
        console.error('Error loading handling units:', error);
      });
  };

  const getShipper = (
    defaultId = null,
    defaultType = 1,
    setSelectFormData: any = null
  ) => {
    setIsShipperLoading(true);
    listShippers()
      .then((response: any) => {
        if (response) {
          let shipperOptionsValue: any = [];
          let consigneeOptionsValue: any = [];
          response.data.forEach((result: any) => {
            if (result.type === 1) {
              shipperOptionsValue.push(result);
            } else {
              consigneeOptionsValue.push(result);
            }
          });
          setShippers(shipperOptionsValue);
          setConsignees(consigneeOptionsValue);
          const filteredShipperOptionsValue = getSelectBoxOptions(
            shipperOptionsValue.slice(0, 5),
            'id',
            'companyName'
          );
          const filteredConsigneeOptionsValue = getSelectBoxOptions(
            consigneeOptionsValue.slice(0, 5),
            'id',
            'companyName'
          );
          setShipperOptions(filteredShipperOptionsValue);
          setConsigneeOptions(filteredConsigneeOptionsValue);

          if (defaultId) {
            const newData: any = {};

            if (defaultType === 1) {
              newData.shipperId = shipperOptionsValue.find(
                (shipperOption: any) => shipperOption.id === defaultId
              );
            } else {
              newData.consigneeId = consigneeOptionsValue.find(
                (consigneeOption: any) => consigneeOption.id === defaultId
              );
            }

            if (setSelectFormData) {
              setSelectFormData((old: any) => ({ ...old, ...newData }));
            }
          }
        }
      })
      .catch(console.error)
      .finally(() => setIsShipperLoading(false));
  };

  useEffect(() => {
    if (searchShipper) {
      const delayDebounceFn = setTimeout(() => {
        setShipperOptions(
          getSelectBoxOptions(
            shippers
              .filter((shipper) =>
                shipper.companyName
                  .toLowerCase()
                  .includes(searchShipper.toLowerCase())
              )
              .slice(0, 5),
            'id',
            'companyName'
          )
        );
      }, 250);

      return () => clearTimeout(delayDebounceFn);
    } else {
      const filteredShipperOptionsValue = getSelectBoxOptions(
        shippers.slice(0, 5),
        'id',
        'companyName'
      );
      setShipperOptions(filteredShipperOptionsValue);
    }
  }, [searchShipper]);

  useEffect(() => {
    if (searchConsignee) {
      const delayDebounceFn = setTimeout(() => {
        setConsigneeOptions(
          getSelectBoxOptions(
            consignees
              .filter((consignee) =>
                consignee.companyName
                  .toLowerCase()
                  .includes(searchConsignee.toLowerCase())
              )
              .slice(0, 5),
            'id',
            'companyName'
          )
        );
      }, 250);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setConsigneeOptions(
        getSelectBoxOptions(consignees.slice(0, 5), 'id', 'companyName')
      );
    }
  }, [searchConsignee]);

  const setMapServiceKey = () => {
    setIsMapLoading(true);
    getMapService()
      .then((response: any) => {
        if (response?.data) {
          if (response.data?.slug) {
            setMapService(response.data?.slug);

            if (response.data.configurations?.length) {
              let configuration = response.data.configurations[0];

              if (configuration.value) {
                if (response.data.slug === 'trimble_map') {
                  setTrimbleKey(configuration.value);
                } else {
                  setGoogleKey(configuration.value);
                }
              }
            }
          }
        }
      })
      .catch(console.error)
      .finally(() => {
        setIsMapLoading(false);
      });
  };

  const getAppearanceDetail = () => {
    getAppearance()
      .then((response) => {
        if (response.data) {
          setAppearance(response.data);
          moment.tz.setDefault(response.data?.timezone);
          setCurrency(response?.data?.currency ?? CURRENCY.CAD);
        }
      })
      .finally(() => {})
      .catch(console.log);
  };

  const connectSocket = () => {
    fetchJsFromCDN(
      'https://cdnjs.cloudflare.com/ajax/libs/sails.io.js/1.0.1/sails.io.min.js',
      ['io']
    )
      .then(([io]: any) => {
        io.sails.url = window.SERVER_URL;

        io.socket.on('connect', function socketConnected() {
          dispatch(setIsSocketConnected(true));
          dispatch(setSocketIo(io.socket));

          if (currentUser && currentUser?.id) {
            setUserStatus({
              status: 'online',
              userId: currentUser.id,
            })
              .then(() => {})
              .catch(console.log);
          }

          io.socket.get(
            `/subscribe/globalRoom`,
            function (_data: any, jwr: any) {
              if (jwr.error) {
                return;
              }
            }
          );
        });

        io.socket.on('disconnect', function socketDisconnected() {
          dispatch(setIsSocketConnected(false));
          dispatch(setSocketIo(null));

          if (currentUser && currentUser?.id) {
            setUserStatus({
              status: 'offline',
              userId: currentUser.id,
            });
          }
        });

        // Clean up on component unmount
        return () => {
          io.socket?.disconnect();

          if (currentUser && currentUser?.id) {
            setUserStatus({
              status: 'offline',
              userId: currentUser.id,
            })
              .then(() => {})
              .catch(console.log);
          }
        };
      })
      .catch((error: any) => {
        console.error('Failed to load Sails socket library:', error);
      });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    getAppearanceDetail();
    getShipper();
    getVehicleTypeOptions();
    getVehicleAttributeOptions();
    getVehicleSizeOptions();
    getListService();
    getListHandlingUnit();
    setMapServiceKey();
    connectSocket();
  }, [isAuthenticated]);

  const value = {
    currency,
    setCurrency,
    tooltipGlobalOpen,
    setTooltipGlobalOpen,
    actionTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
    actionDelayTimeout,
    setActionDelayTimeout,
    mapService,
    setMapService,
    googleKey,
    setGoogleKey,
    trimbleKey,
    setTrimbleKey,
    lastSyncDateTimeActual,
    setLastSyncDateTimeActual,
    allTimeStartDateActual,
    setAllTimeStartDateActual,
    allTimeStartDateQuote,
    setAllTimeStartDateQuote,
    appearance,
    setAppearance,
    ordersUpdateSocketTriggeredCount,
    setOrdersUpdateSocketTriggeredCount,
    reportMonth,
    setReportMonth,
    reportYear,
    setReportYear,
    isMakeCall,
    setIsMakeCall,
    vehicleTypeOptions,
    setVehicleTypeOptions,
    isVehicleTypeLoading,
    setIsVehicleTypeLoading,
    vehicleAttrOptions,
    setVehicleAttrOptions,
    isVehicleAttrLoading,
    setIsVehicleAttrLoading,
    vehicleSizeOptions,
    setVehicleSizeOptions,
    isServiceLoading,
    setIsServiceLoading,
    servicesList,
    setServicesList,
    serviceTypes,
    setServiceTypes,
    handlingUnitOptions,
    setHandlingUnitOptions,
    isHandlingUnitLoading,
    setIsHandlingUnitLoading,
    getShipper,
    isShipperLoading,
    shippers,
    consignees,
    shipperOptions,
    setSearchShipper,
    setSearchConsignee,
    consigneeOptions,
    isMapLoading,
  };

  return (
    <BasicContext.Provider value={value}>{children}</BasicContext.Provider>
  );
};

BasicProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
