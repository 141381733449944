/* eslint-disable max-lines-per-function */
import {
  ArrowLeft,
  Edit05,
  StopCircle,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import CustomPagination from 'src/components/CustomPagination';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TableCmp from 'src/components/TableCmp';
import { BasicContext } from 'src/context/BasicContext';
import { getPricingRate } from 'src/services/CommonService';
import {
  getLoadLinkPostLeads,
  deleteLoadLinkPosting,
  updateRateGathering,
} from 'src/services/LoadLinkService';

import aiEmoji from '../../../../../assets/img/inbound-emoji.webp';

import CreateLoadPostingFrom from './CreateLoadPostingFrom';
import {
  IProps,
  ITransportLead,
  LoadBoardCellId,
  QuoteType,
} from './LoadBoard.interface';
import LoadBoardRateRow from './LoadBoardRateRow';
import LoadBoardRatesLoader from './LoadBoardRatesLoader';
import LoadBoardRow from './LoadBoardRow';
import LoadBoardRowLoader from './LoadBoardRowLoader';
import MoveToActive from './MoveToActive';
import TruckFindingAiModals from './TruckFindingAiModals';

const initParams = {
  sortType: 'desc',
  sortField: LoadBoardCellId.Availability,
  page: 1,
  limit: 50,
};

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

const PostToLoadBoard = ({
  activePostIndex,
  setActivePostIndex,
  loadLinkPosts,
  setLoadLinkPosts,
  loadLinkPostingDetail,
  setLoadLinkPostingDetail,
  addressDetails,
  validatePostToLoadBoardForm,
  setValidatePostToLoadBoardForm,
  setIsPostCreateLoading,
  syncLiveData,
  setSyncLiveData,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
  isCreateLoadPost,
  setIsCreateLoadPost,
  setIsRefresh,
  isRefresh,
  rateGatheringWithAi,
  setCurrentTab,
  setRefreshLoadPosting,
  stopRateGathering,
  isStopRateLoading,
  showTimerLoader,
  setShowTimerLoader,
  servicesList,
}: IProps) => {
  const { id } = useParams<{ id: any }>();
  const { currency } = useContext(BasicContext);
  const [isShowTypeInfoModal, setIsShowTypeInfoModal] = useState(false);
  const [selectedCarrierRates, setSelectedCarrierRates] = useState<any>([]);
  const [leadsData, setLeadsData] = useState<ITransportLead[]>([]);
  const [leadCount, setLeadCount] = useState<number>(0);
  const [rateCount, setRateCount] = useState<number>(0);
  const [rateCollectedCount, setRateCollectedCount] = useState<number>(0);
  const [rates, setRates] = useState<any>({});
  const [inboundInprogressCount, setInboundInprogressCount] =
    useState<number>(0);
  const [outboundInprogressCount, setOutboundInprogressCount] =
    useState<number>(0);
  const [timerActiveIndex, setTimerActiveIndex] = useState<string>(
    loadLinkPostingDetail?.isCovered
      ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.enableOutboundTruckFinding
        ? 'inprogressOutboundCalls'
        : 'rateCollected'
      : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.enableInboundTruckFinding
      ? 'inprogressInboundCalls'
      : 'rateCollected'
  );

  const [truckFindingAiDetail, setTruckFindingAiDetail] = useState<any>({});
  const [callStats, setCallStats] = useState<any>({});
  const [isSyncingLiveData, setIsSyncingLiveData] = useState<boolean>(false);
  const [params, setParams] = useState<any>(initParams);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRateUpdateLoading, setIsRateUpdateLoading] = useState(false);

  const localData = localStorage.getItem('last_sel_load_board_tab');
  let lastSelTabType;

  if (localData) {
    const lastSelQuoteTabObject = JSON.parse(localData);
    if (Object.hasOwn(lastSelQuoteTabObject, parseInt(id)))
      lastSelTabType = lastSelQuoteTabObject[id];
  }
  const [activeLeadType, setActiveLeadType] = useState<string>(
    lastSelTabType ? lastSelTabType : 'leads'
  );

  const [
    isShowLoadLinkPostingDeleteConfirmation,
    setIsShowLoadLinkPostingDeleteConfirmation,
  ] = useState(false);
  const [isLoadLinkPostingDeleteLoading, setIsLoadLinkPostingDeleteLoading] =
    useState(false);
  const [isShowTruckFindingAiModal, setIsShowTruckFindingAiModal] =
    useState(false);
  const [newTimerActiveIndex, setNewTimerActiveIndex] = useState('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const backgroundCallAbortControllerRef = useRef<AbortController | null>(null);
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const setCurrentIndex = (loadLinkPosting: any) => {
    let remainingSeconds = 0;
    let minutes = 0;
    let seconds = 0;

    if (
      !loadLinkPosting?.isCovered &&
      loadLinkPosting?.loadLinkTruckFindingAis?.[0]?.acceptInboundCallDuration >
        0
    ) {
      const updatedAt = moment.utc(
        loadLinkPosting?.loadLinkTruckFindingAis?.[0]?.updatedAt,
        'YYYY-MM-DD HH:mm:ss'
      );
      const currentGMT = moment().tz('GMT');
      const elapsedSeconds = currentGMT.diff(updatedAt, 'seconds');
      const totalSeconds =
        loadLinkPosting?.loadLinkTruckFindingAis?.[0]
          ?.acceptInboundCallDuration * 60;
      remainingSeconds = totalSeconds - elapsedSeconds;

      if (remainingSeconds > 0) {
        minutes = Math.floor(remainingSeconds / 60);
        seconds = remainingSeconds % 60;
        setNewTimerActiveIndex(`inprogressInboundCalls_${minutes}_${seconds}`);

        return setTimerActiveIndex(
          `inprogressInboundCalls_${minutes}_${seconds}`
        );
      }
    }

    setTimeout(() => {
      let inboundRemainingSeconds = 0;
      let outboundRemainingSeconds = 0;

      if (loadLinkPosting?.loadLinkTruckFindingAis?.length > 0) {
        const updatedAt = moment.utc(
          loadLinkPosting?.loadLinkTruckFindingAis?.[0]?.updatedAt,
          'YYYY-MM-DD HH:mm:ss'
        );
        const currentGMT = moment().tz('GMT');
        const elapsedSeconds = currentGMT.diff(updatedAt, 'seconds');
        const inboundTotalSeconds =
          loadLinkPosting?.loadLinkTruckFindingAis?.[0]
            ?.acceptInboundCallDuration * 60;
        inboundRemainingSeconds = inboundTotalSeconds - elapsedSeconds;

        const outboundTotalSeconds =
          loadLinkPosting?.loadLinkTruckFindingAis?.[0]
            ?.outgoingCallStartAfter * 60;
        outboundRemainingSeconds = outboundTotalSeconds - elapsedSeconds;
      }

      let allowedKeys = ['rateCollected'];

      if (!loadLinkPosting?.isCovered) {
        if (
          loadLinkPosting?.loadLinkTruckFindingAis?.[0]
            ?.enableOutboundTruckFinding &&
          outboundRemainingSeconds > 0
        ) {
          allowedKeys = ['inprogressOutboundCalls'];
        } else if (
          loadLinkPosting?.loadLinkTruckFindingAis?.[0]
            ?.enableInboundTruckFinding &&
          inboundRemainingSeconds > 0
        ) {
          allowedKeys = [`inprogressInboundCalls_${minutes}_${seconds}`];
        }
      }

      const updateActiveIndex = (prevIndex: string): string => {
        const mainIndex = prevIndex.split('_')[0];

        if (!allowedKeys.includes(mainIndex)) return allowedKeys[0];

        if (allowedKeys.length > 1) {
          return mainIndex === allowedKeys[0] ? allowedKeys[1] : allowedKeys[0];
        } else {
          return allowedKeys[0];
        }
      };

      setNewTimerActiveIndex(updateActiveIndex);
      setTimerActiveIndex(updateActiveIndex);
    }, 1);
  };

  const setCallStatsResponse = (res: any) => {
    let inboundRemainingSeconds = 0;
    let outboundRemainingSeconds = 0;

    if (loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]) {
      const updatedAt = moment.utc(
        loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]?.updatedAt,
        'YYYY-MM-DD HH:mm:ss'
      );
      const currentGMT = moment().tz('GMT');
      const elapsedSeconds = currentGMT.diff(updatedAt, 'seconds');
      const inboundTotalSeconds =
        loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.acceptInboundCallDuration * 60;
      inboundRemainingSeconds = inboundTotalSeconds - elapsedSeconds;

      const outboundTotalSeconds =
        loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.outgoingCallStartAfter * 60;
      outboundRemainingSeconds = outboundTotalSeconds - elapsedSeconds;
    }

    const defaultStats = {
      rateCollected: {
        count: 0,
        text: 'Rates Gathered',
      },
      ...(loadLinkPostingDetail?.isCovered
        ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableOutboundTruckFinding && outboundRemainingSeconds > 0
          ? {
              inprogressOutboundCalls: {
                count: 0,
                text: 'Calls In Progress',
              },
            }
          : {}
        : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableInboundTruckFinding && inboundRemainingSeconds > 0
        ? {
            inprogressInboundCalls: {
              count: 0,
              text: 'Calls In Progress',
            },
          }
        : {}),
    };

    setCallStats((prevStats: any) => {
      const keys = Object.keys(defaultStats);

      return keys.reduce((acc: any, stat: string) => {
        let text = '';

        if (stat === 'inprogressInboundCalls') {
          text =
            res[stat] && res[stat] === 1
              ? 'Call In Progress'
              : 'Calls In Progress';
        } else if (stat === 'inprogressOutboundCalls') {
          text =
            res[stat] && res[stat] === 1
              ? 'Call In Progress'
              : 'Calls In Progress';
        } else if (stat === 'rateCollected') {
          text =
            res[stat] && res[stat] === 1 ? ' Rate Gathered' : ' Rates Gathered';
        }

        acc[stat] = {
          count: res[stat] ?? prevStats[stat] ?? 0,
          text,
        };

        return acc;
      }, {});
    });
  };

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const getLead = (loading = true) => {
    if (loadLinkPostingDetail?.id) {
      let signal;

      if (loading) {
        setIsLoading(loading);
        cancelApiCall();

        if (activeLeadType === 'rates') {
          if (backgroundCallAbortControllerRef.current)
            backgroundCallAbortControllerRef.current?.abort();
        }

        abortControllerRef.current = new AbortController();
        signal = abortControllerRef.current.signal;
      } else {
        if (backgroundCallAbortControllerRef.current)
          backgroundCallAbortControllerRef.current?.abort();
        backgroundCallAbortControllerRef.current = new AbortController();
        signal = backgroundCallAbortControllerRef.current.signal;
      }

      getLoadLinkPostLeads(
        { ...params, isRefresh: isSyncingLiveData, activeLeadType },
        loadLinkPostingDetail.id,
        signal
      )
        .then((res: any) => {
          if (activeLeadType == 'rates') {
            res.data = res.data.sort((a: any, b: any) => {
              if (
                a.callStatus === 'In Progress' &&
                b.callStatus !== 'In Progress'
              ) {
                return 1;
              }

              if (
                a.callStatus !== 'In Progress' &&
                b.callStatus === 'In Progress'
              ) {
                return -1;
              }

              return 0;
            });
          }
          setLeadsData(res.data);

          if (loading) {
            setLoadLinkPostingDetail(res.loadLinkPostingDetail);
            setTruckFindingAiDetail(
              res.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            );
          }
          setLeadCount(res.leadCount);
          setRateCount(res.rateCount);
          setRateCollectedCount(res.rateCollected);
          setInboundInprogressCount(res.inprogressInboundCalls);
          setOutboundInprogressCount(res.inprogressOutboundCalls);
          setCallStatsResponse(res);
          setTimeout(() => {
            setCurrentIndex(res.loadLinkPostingDetail);
            setTimeout(() => {
              setShowTimerLoader(false);
            }, 1000);
          }, 100);
        })
        .finally(() => {
          setIsSyncingLiveData(false);
          setIsLoading(false);
          setSyncLiveData(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
        });
    } else {
      setIsSyncingLiveData(false);
      setIsLoading(false);
      setSyncLiveData(false);
    }
  };

  useEffect(() => {
    setCallStats({
      rateCollected: {
        count: rateCollectedCount,
        text: `Rate${rateCollectedCount == 1 ? '' : 's'} Gathered`,
      },
      ...(loadLinkPostingDetail?.isCovered
        ? loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableOutboundTruckFinding
          ? {
              inprogressOutboundCalls: {
                count: outboundInprogressCount,
                text: `Call${
                  outboundInprogressCount == 1 ? '' : 's'
                } In Progress`,
              },
            }
          : {}
        : loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
            ?.enableInboundTruckFinding
        ? {
            inprogressInboundCalls: {
              count: inboundInprogressCount,
              text: `Call${inboundInprogressCount == 1 ? '' : 's'} In Progress`,
            },
          }
        : {}),
    });
  }, [
    loadLinkPostingDetail,
    loadLinkPostingDetail?.isCovered,
    outboundInprogressCount,
    rateCount,
    inboundInprogressCount,
    rateCollectedCount,
  ]);

  useEffect(() => {
    if (syncLiveData) {
      setLeadsData([]);
      setLeadCount(0);
      setRateCount(0);
      setRateCollectedCount(0);
      setIsLoading(true);
      setIsSyncingLiveData(syncLiveData);
      setIsUpdateLoadPost(false);
      setIsCreateLoadPost(false);
    }
  }, [syncLiveData]);

  const handlePagination = (page: number) => {
    setParams((old: any) => ({ ...old, page }));
  };

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch(() => {
        console.log('Pricing rates error');
      });
  };

  useEffect(() => {
    setTruckFindingAiDetail(
      loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
    );

    if (!isUpdateLoadPost) {
      getRate();
      setIsSyncingLiveData(true);

      const intervalId = setInterval(() => {
        setIsSyncingLiveData(true);
      }, 180000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);

  const setLeadRate = (response: any) => {
    console.log('🚀 ~ setLeadRate ~ response:', response);
    console.log('🚀 ~ setLeadRate ~ activeLeadType:', activeLeadType);

    if (activeLeadType == 'rates') {
      response.rates = response.rates.sort((a: any, b: any) => {
        if (a.callStatus === 'In Progress' && b.callStatus !== 'In Progress')
          return 1;
        if (a.callStatus !== 'In Progress' && b.callStatus === 'In Progress')
          return -1;

        return 0;
      });
    }
    setLeadsData(response.rates);

    loadLinkPosts[activePostIndex] = response.loadLinkPostingDetail;
    setLoadLinkPosts([...loadLinkPosts]);
    setLoadLinkPostingDetail(response.loadLinkPostingDetail);
    const truckFindingAi =
      response.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0];
    setTruckFindingAiDetail(truckFindingAi);

    setLeadCount(response.loadLinkPostingDetail.leads.length);
    setRateCount(response.rates.length);
    setRateCollectedCount(response.rateCollected);
    setInboundInprogressCount(response.inprogressInboundCalls);
    setOutboundInprogressCount(response.inprogressOutboundCalls);
    setCallStatsResponse(response);
    setTimeout(() => {
      setCurrentIndex(response.loadLinkPostingDetail);
      setTimeout(() => {
        setShowTimerLoader(false);
      }, 1000);
    }, 100);
  };

  useEffect(() => {
    if (!loadLinkPostingDetail?.id) return;

    const postId = loadLinkPostingDetail.id;

    console.log('PostToLoadBoard isSocketConnected', isSocketConnected);
    console.log('PostToLoadBoard socketIO', socketIO);

    if (isSocketConnected && !!socketIO?.on) {
      socketIO.on(`lead_rate_${postId}`, (response: any) => {
        console.log(
          '[Socket] testest Received data for',
          `lead_rate_${postId}`,
          response
        );
        setLeadRate(response);
      });

      return () => {
        console.log('unmount', loadLinkPostingDetail?.id);

        if (socketIO?.on) {
          socketIO?.off(`lead_rate_${postId}`);
        }
      };
    }
  }, [isSocketConnected, loadLinkPostingDetail?.id]);

  useEffect(() => {
    let inboundInterval: NodeJS.Timeout | null = null;
    let currentTimerIndex = timerActiveIndex;

    if (newTimerActiveIndex) currentTimerIndex = newTimerActiveIndex;

    const manageInboundInterval = () => {
      if (
        !loadLinkPostingDetail?.isCovered &&
        truckFindingAiDetail?.acceptInboundCallDuration > 0
      ) {
        setTimeout(() => {
          const updatedAt = moment.utc(
            truckFindingAiDetail?.updatedAt,
            'YYYY-MM-DD HH:mm:ss'
          );
          const currentGMT = moment().tz('GMT');
          const elapsedSeconds = currentGMT.diff(updatedAt, 'seconds');
          const totalSeconds =
            truckFindingAiDetail?.acceptInboundCallDuration * 60;
          const remainingSeconds = totalSeconds - elapsedSeconds;

          if (remainingSeconds > 0) {
            const minutes = Math.floor(remainingSeconds / 60);
            const seconds = remainingSeconds % 60;

            if (currentTimerIndex != 'rateCollected') {
              setTimerActiveIndex(
                `inprogressInboundCalls_${minutes}_${seconds}`
              );
            }
          } else {
            if (inboundInterval) {
              clearInterval(inboundInterval);
              inboundInterval = null;

              if (loadLinkPostingDetail) {
                loadLinkPostingDetail.isCovered = 1;
                setLoadLinkPostingDetail(loadLinkPostingDetail);
              }
              setTimerActiveIndex('rateCollected');
              setNewTimerActiveIndex('rateCollected');
              setCallStatsResponse({
                inprogressInboundCalls: inboundInprogressCount,
                inprogressOutboundCalls: outboundInprogressCount,
                rateCollected: rateCollectedCount,
              });
            }
          }
        }, 30);
      }
    };

    if (
      !loadLinkPostingDetail?.isCovered &&
      truckFindingAiDetail?.acceptInboundCallDuration > 0
    ) {
      if (!inboundInterval) {
        inboundInterval = setInterval(() => {
          manageInboundInterval();
        }, 1000);
      }
    } else {
      setTimeout(() => {
        setShowTimerLoader(false);
      }, 1800);
    }

    return () => {
      if (inboundInterval) clearInterval(inboundInterval);
    };
  }, [
    loadLinkPostingDetail,
    truckFindingAiDetail?.acceptInboundCallDuration,
    truckFindingAiDetail?.updatedAt,
    newTimerActiveIndex,
  ]);

  useEffect(() => {
    if (!isSyncingLiveData) {
      setLeadsData([]);
      setLeadCount(0);
      setRateCount(0);
      setRateCollectedCount(0);
      if (!isUpdateLoadPost) getLead();
    }
  }, [params, isRefresh, activePostIndex]);

  useEffect(() => {
    if (isSyncingLiveData) {
      getLead(false);
    }
  }, [isSyncingLiveData]);

  const LoadBoardLeadsCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Carrier,
        name: 'Carrier',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Origin,
        name: 'Origin',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DFO,
        name: 'DFO',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Destination,
        name: 'Destination',
        sortable: true,
      },
      {
        id: LoadBoardCellId.DFD,
        name: 'DFD',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EquipmentType,
        name: 'Equipment Type',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Comment,
        name: 'Comments',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
        sortable: false,
      },
    ],
    []
  );

  const LoadBoardRatesCells = useMemo(
    () => [
      {
        id: LoadBoardCellId.Carrier,
        name: 'Carrier',
        sortable: true,
      },
      {
        id: LoadBoardCellId.CallStatus,
        name: 'Call Status',
        sortable: true,
      },
      {
        id: LoadBoardCellId.CallDuration,
        name: 'Call Duration',
        sortable: true,
      },
      {
        id: LoadBoardCellId.OutreachMethod,
        name: 'Outreach Method',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EquipmentType,
        name: 'Equipment Type',
        sortable: true,
      },
      {
        id: LoadBoardCellId.EstDeliveryDate,
        name: 'Est. Delivery Date',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Rate,
        name: 'Rate',
        sortable: true,
      },
      {
        id: LoadBoardCellId.Notes,
        name: 'Additional Notes',
        sortable: true,
      },
      {
        id: 'action',
        name: '',
        sortable: false,
      },
    ],
    []
  );

  const onTruckFindingAiModalSubmit = (formDataPayload: any) => {
    loadLinkPostingDetail.truckFindingAiData = formDataPayload;
    const truckFindingAiData = formDataPayload.truckFindingAiData;
    truckFindingAiData.loadLinkPostingId = loadLinkPostingDetail.id;
    truckFindingAiData.acceptInboundCallDuration =
      truckFindingAiData.acceptInboundCallDuration
        ? truckFindingAiData.acceptInboundCallDuration
            .replace(/,|minutes?/g, '')
            .trim()
        : null;
    truckFindingAiData.outgoingCallStartAfter =
      truckFindingAiData.outgoingCallStartAfter
        ? truckFindingAiData.outgoingCallStartAfter
            .replace(/,|minutes?/g, '')
            .trim()
        : null;
    truckFindingAiData.totalRates = truckFindingAiData.totalRates
      ? truckFindingAiData.totalRates.replace(/,|rates?/g, '').trim()
      : null;
    truckFindingAiData.budgetCost = truckFindingAiData.budgetCost
      ? truckFindingAiData.budgetCost.replace(/[$,\s]/g, '')
      : null;

    setIsShowTruckFindingAiModal(false);
    setIsRateUpdateLoading(true);

    updateRateGathering(truckFindingAiData)
      .then()
      .finally(() => {
        setShowTimerLoader(true);
        setIsRateUpdateLoading(false);
      })
      .catch((e: any) => {
        console.log('Something went wrong while creating load link post.', e);
      });
  };

  const handleLoadPostingDelete = (status: boolean) => {
    if (status) {
      setIsLoadLinkPostingDeleteLoading(true);
      deleteLoadLinkPosting(loadLinkPostingDetail?.id)
        .then(() => {
          setShowTimerLoader(true);
          setLoadLinkPostingDetail(undefined);
          setCurrentTab(QuoteType.LOAD_BOARD);
          setIsRefresh(true);
        })
        .catch((e) => {
          console.log(
            e?.response?.data?.message ??
              'Something went wrong while deleting Load Link Posting.'
          );
        })
        .finally(() => {
          setIsLoadLinkPostingDeleteLoading(false);
          setIsShowLoadLinkPostingDeleteConfirmation(false);
        });
    } else {
      setIsShowLoadLinkPostingDeleteConfirmation(false);
    }
  };

  const leadClassificationArray = [
    {
      value: 'leads',
      name: (
        <div data-value="leads">
          Leads
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="leads"
            isHidePillDot
            mainClassName={`bg-white ml-2 ${isLoading ? 'custom-loading' : ''}`}
          >
            {leadCount}
          </BadgeCmp>
        </div>
      ),
    },
    {
      value: 'rates',
      name: (
        <div data-value="rates">
          Rates
          <BadgeCmp
            type="primary"
            style="modern"
            dataValue="rates"
            isHidePillDot
            mainClassName={`bg-white ml-2 ${isLoading ? 'custom-loading' : ''}`}
          >
            {rateCount}
          </BadgeCmp>
        </div>
      ),
    },
  ];

  const onClickBackButton = () => {
    backgroundCallAbortControllerRef?.current?.abort();
    abortControllerRef?.current?.abort();
    localStorage.removeItem('loadLinkPostDetail');
    localStorage.removeItem('activePostIndex');
    localStorage.removeItem('loadLinkQuoteId');
    setLoadLinkPostingDetail(undefined);
    setActivePostIndex(null);
    setRefreshLoadPosting(true);
  };

  return (
    <>
      {(isCreateLoadPost &&
        (!loadLinkPostingDetail || !loadLinkPostingDetail?.id)) ||
      (isUpdateLoadPost && loadLinkPostingDetail?.id) ? (
        <CreateLoadPostingFrom
          addressDetails={addressDetails}
          validatePostToLoadBoardForm={validatePostToLoadBoardForm}
          setValidatePostToLoadBoardForm={setValidatePostToLoadBoardForm}
          setIsPostCreateLoading={setIsPostCreateLoading}
          setIsRefresh={setIsRefresh}
          loadLinkPostingDetail={loadLinkPostingDetail}
          setLoadLinkPostingDetail={setLoadLinkPostingDetail}
          isUpdateLoadPost={isUpdateLoadPost}
          setIsUpdateLoadPost={setIsUpdateLoadPost}
          setIsCreateLoadPost={setIsCreateLoadPost}
          rateGatheringWithAi={rateGatheringWithAi}
          setShowTimerLoader={setShowTimerLoader}
          setCurrentTab={setCurrentTab}
        />
      ) : (
        <>
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left">
              <ButtonCmp
                type="button"
                className="btn_secondary_black lg:!px-[9px] !px-2"
                onClick={() => onClickBackButton()}
              >
                <ArrowLeft className="w-4 h-4" /> Back
              </ButtonCmp>
            </div>
          </div>
          <div className="table-bottom-header table-tab-wrap border-b border-utilityGray200 items-center">
            <div className="table-header-bottom-left">
              <TabButton
                className="min-w-32"
                tabArray={leadClassificationArray}
                isTab={true}
                isActive={activeLeadType}
                handleOnClick={(e: any) => {
                  const storedData = localStorage.getItem(
                    'last_sel_load_board_tab'
                  );
                  const lastSelQuoteTabData = storedData
                    ? JSON.parse(storedData)
                    : {};
                  lastSelQuoteTabData[addressDetails?.id] =
                    e?.target?.dataset?.value;
                  localStorage.setItem(
                    'last_sel_load_board_tab',
                    JSON.stringify(lastSelQuoteTabData)
                  );
                  setActiveLeadType(e?.target?.dataset?.value);
                  handlePagination(1);
                }}
              />
              <div
                className={`w-fit bg-white text-textSecondary items-center text-center rounded-md border border-borderPrimary text-xs whitespace-nowrap font-semibold sm:flex hidden ${
                  isLoading ? 'custom-loading' : ''
                }`}
              ></div>
            </div>

            {loadLinkPostingDetail.isDeleted == 0 &&
              truckFindingAiDetail?.enableTruckFinding && (
                <div
                  className={`p-[7px] rounded-lg border border-borderPrimary bg-white shadow-xs flex items-center gap-2 text-primary text-xs font-normal min-w-[275px] ${
                    showTimerLoader ? 'custom-loading' : ''
                  }`}
                >
                  <>
                    <div className="rounded-full bg-white">
                      <img
                        src={aiEmoji}
                        className={`w-5 h-5 rounded-full flex-none bg-success200 p-1 object-contain`}
                      />
                    </div>

                    {callStats && callStats[timerActiveIndex.split('_')[0]]
                      ? (() => {
                          const callStat =
                            callStats[timerActiveIndex.split('_')[0]];
                          const hasTotalRates =
                            truckFindingAiDetail &&
                            'totalRates' in truckFindingAiDetail;
                          const rateMismatch =
                            hasTotalRates &&
                            callStat.count > 0 &&
                            timerActiveIndex.split('_')[0] ===
                              'rateCollected' &&
                            truckFindingAiDetail.totalRates > callStat.count;
                          const rateText = rateMismatch
                            ? `${callStat.count}/${truckFindingAiDetail.totalRates}`
                            : `${callStat.count}`;

                          const minutes = timerActiveIndex.split('_')[1];
                          const seconds = timerActiveIndex.split('_')[2];
                          let timeDisplay = '';

                          if (minutes && seconds) {
                            const totalMinutes = parseInt(minutes, 10);
                            const displaySeconds = seconds.padStart(2, '0');

                            if (totalMinutes >= 60) {
                              const hours = Math.floor(totalMinutes / 60);
                              const remainingMinutes = totalMinutes % 60;
                              timeDisplay = ` ${hours
                                .toString()
                                .padStart(2, '0')}:${remainingMinutes
                                .toString()
                                .padStart(2, '0')}:${displaySeconds}`;
                            } else {
                              timeDisplay = ` ${totalMinutes
                                .toString()
                                .padStart(2, '0')}:${displaySeconds}`;
                            }
                          }

                          return (
                            <div className="flex flex-1">
                              {timeDisplay && (
                                <p className="pr-1 min-w-10">{timeDisplay}</p>
                              )}
                              <p>
                                {timeDisplay && '|'} {rateText} {callStat.text}
                              </p>
                            </div>
                          );
                        })()
                      : ''}
                  </>

                  <div className="flex items-center gap-x-1.5 justify-end">
                    {((loadLinkPostingDetail?.isCovered &&
                      callStats.inprogressOutboundCalls?.count > 0) ||
                      (!loadLinkPostingDetail?.isCovered &&
                        callStats.inprogressInboundCalls?.count > 0)) &&
                      timerActiveIndex != 'rateCollected' && (
                        <div className="relative pr-1">
                          <div className="w-4 h-4 rounded-full border-2 border-utilityGray100"></div>
                          <div className="w-4 h-4 rounded-full animate-spin absolute top-0 border-2 border-primary border-t-transparent"></div>
                        </div>
                      )}
                    {callStats.inprogressInboundCalls && (
                      <ButtonCmp
                        className="btn-link-secondary"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          stopRateGathering(truckFindingAiDetail);
                        }}
                        disabled={
                          isStopRateLoading[truckFindingAiDetail?.id] ||
                          isRateUpdateLoading
                        }
                        loading={isStopRateLoading[truckFindingAiDetail?.id]}
                        icon={
                          !isStopRateLoading[truckFindingAiDetail?.id] && (
                            <StopCircle className="mt-0.5 w-4 h-4 cursor-pointer" />
                          )
                        }
                      >
                        <></>
                      </ButtonCmp>
                    )}

                    {loadLinkPostingDetail?.isExpired ? (
                      <ActionTooltip
                        message={'Post is expired'}
                        openOnHover={true}
                        isOpenChildTopPosOnTop={true}
                        type="dark"
                        tooltipPosition="center"
                        parentClassName="w-max"
                      >
                        <ButtonCmp
                          className="btn-link-secondary"
                          icon={
                            <Edit05 className="w-3.5 h-3.5 cursor-pointer" />
                          }
                        >
                          <></>
                        </ButtonCmp>
                      </ActionTooltip>
                    ) : (
                      <ButtonCmp
                        className="btn-link-secondary"
                        loading={isRateUpdateLoading}
                        disabled={
                          isRateUpdateLoading ||
                          isStopRateLoading[truckFindingAiDetail?.id]
                        }
                        onClick={() => {
                          setIsShowTruckFindingAiModal(true);
                        }}
                        icon={
                          !isRateUpdateLoading && (
                            <Edit05 className="w-3.5 h-3.5 cursor-pointer" />
                          )
                        }
                      >
                        <></>
                      </ButtonCmp>
                    )}
                  </div>
                </div>
              )}

            <div
              className={`rounded-lg border border-borderPrimary flex gap-2 items-center px-2 lg:py-[9px] py-2 min-w-[100px] text-xs font-medium`}
            >
              REF ID:{' '}
              <span className="font-normal">
                #{loadLinkPostingDetail?.refNumber}
              </span>
            </div>

            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div>

          <div
            className={`w-full ${
              !leadsData?.length ? 'flex flex-col flex-1' : ''
            }`}
          >
            <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
              <TableCmp
                headCells={
                  activeLeadType == 'leads'
                    ? LoadBoardLeadsCells
                    : LoadBoardRatesCells
                }
                tableDataArr={leadsData}
                TableLoaderRowCmp={
                  activeLeadType == 'leads'
                    ? LoadBoardRowLoader
                    : LoadBoardRatesLoader
                }
                tableRowCmpProps={{
                  setIsShowTypeInfoModal: setIsShowTypeInfoModal,
                  setSelectedCarrierRates: setSelectedCarrierRates,
                  rates: rates,
                }}
                TableRowCmp={
                  activeLeadType == 'leads' ? LoadBoardRow : LoadBoardRateRow
                }
                params={params}
                setParams={setParams}
                isTableDataLoading={isLoading}
              />
            </div>

            {!leadsData?.length && !isLoading && (
              <NotFoundUI
                title={`No ${
                  activeLeadType.charAt(0).toUpperCase() +
                  activeLeadType.slice(1)
                } Found`}
                desc={`There are no ${activeLeadType} available for this load.`}
                containerClassName="flex-1 !h-auto"
              />
            )}
          </div>

          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={activeLeadType == 'leads' ? leadCount : rateCount}
            currentPage={params.page}
            handlePagination={handlePagination}
          />
        </>
      )}

      {isShowLoadLinkPostingDeleteConfirmation && (
        <CommonModal
          title="Remove Load Link Posting"
          titleDesc="This action will remove load link posting from WAL HQ and Load Link."
          handleClose={() =>
            isLoadLinkPostingDeleteLoading
              ? false
              : handleLoadPostingDelete(false)
          }
          secondaryBtnOnClick={() => handleLoadPostingDelete(false)}
          dangerBtnText="Remove"
          secondaryBtnText="Cancel"
          primaryBtnOnClick={() => handleLoadPostingDelete(true)}
          primaryBtnLoading={isLoadLinkPostingDeleteLoading}
          primaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          secondaryBtnDisabled={isLoadLinkPostingDeleteLoading}
          size="max-w-[400px]"
          modalHeaderClass="border-b-0 !px-6 !pt-6 !pb-2"
          modalHeaderTextClass="!flex-col"
          modalFooterClass="border-t-0 pt-0"
        >
          &nbsp;
        </CommonModal>
      )}

      {isShowTruckFindingAiModal && (
        <TruckFindingAiModals
          postCreatePayload={{}}
          truckFindingAiDetail={truckFindingAiDetail}
          rateGatheringWithAi={rateGatheringWithAi}
          handleClose={() => {
            setIsShowTruckFindingAiModal(false);
          }}
          handleModalSubmit={onTruckFindingAiModalSubmit}
        />
      )}

      {isShowTypeInfoModal && (
        <MoveToActive
          action={{ model: true, mode: 'create' }}
          quoteID={addressDetails.id}
          handleClose={() => setIsShowTypeInfoModal(false)}
          setIsRefresh={setIsRefresh}
          selectedCarrierRates={selectedCarrierRates}
          currency={currency}
          source="loadBoard"
          servicesList={servicesList}
        />
      )}
    </>
  );
};

export default PostToLoadBoard;
