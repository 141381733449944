import React from 'react';
import InputText from 'src/components/InputText/InputText';
// import TabButton from 'src/components/TabButton';

const AddressLoading = () => (
  // let tabArray = [
  //   {
  //     value: 'general',
  //     name: 'General',
  //   },
  //   {
  //     value: 'contacts',
  //     name: 'Contacts',
  //   },
  //   {
  //     value: 'spot_quote_email',
  //     name: 'Spot Quote Email',
  //   },
  // ];

  <>
    {/* <TabButton
          parentClassName={`w-full custom-loading`}
          tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px"
          className={`flex-1 `}
          tabArray={tabArray}
          isTab={true}
          isActive="general"
        /> */}
    <div className="space-y-1.5 !mt-0">
      <h6 className="text-grayLight900 text-xs font-medium">Quote</h6>
      <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2 custom-loading">
        <InputText
          label="Service Type"
          labelClassName="form_label mb-1.5 block"
          parentClassName="flex-1"
        />
        <InputText
          label="Service Type"
          labelClassName="form_label mb-1.5 block"
          parentClassName="flex-1"
        />
      </div>
    </div>
    <div className="space-y-1.5">
      <h6 className="text-grayLight900 text-xs font-medium">Shipper Details</h6>
      <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2 space-y-3 custom-loading">
        <InputText
          label="Service Type"
          labelClassName="form_label mb-1.5 block"
          parentClassName="flex-1"
        />
        <div className="space-y-1.5 w-full">
          <h6 className="text-textSecondary text-xs font-medium">
            Pickup Additional Services
          </h6>
          <div className="rounded-lg border border-utilityGray200 bg-white shadow-sm p-3">
            <InputText
              label="Service Type"
              labelClassName="form_label mb-1.5 block"
              parentClassName="flex-1"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="space-y-1.5">
      <h6 className="text-grayLight900 text-xs font-medium">
        Consignee Details
      </h6>
      <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2 space-y-3 custom-loading">
        <InputText
          label="Service Type"
          labelClassName="form_label mb-1.5 block"
          parentClassName="flex-1"
        />
        <div className="space-y-1.5 w-full">
          <h6 className="text-textSecondary text-xs font-medium">
            Delivery Additional Services
          </h6>
          <div className="rounded-lg border border-utilityGray200 bg-white shadow-sm p-3">
            <InputText
              label="Service Type"
              labelClassName="form_label mb-1.5 block"
              parentClassName="flex-1"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);
export default AddressLoading;
