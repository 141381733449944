import { ArrowRight, ChevronRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';

const ActivityOverviewRow = ({ data }: any) => (
  // const { hasRoleV2 } = useRolePermission();

  <>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5">
        <p className="text-grayLight900 font-normal leading-[1.5]">
          {data.time}
        </p>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5">
        <p className="text-grayLight900 font-semibold leading-[1.5]">
          {data.id}
        </p>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5">
        <BadgeCmp style="modern" type="success">
          {data.type}
        </BadgeCmp>
      </div>
    </td>
    <td className="p-0 max-w-px">
      <div className="border-r border-utilityGray200 my-2.5 px-2.5 flex items-center justify-between gap-x-1.5">
        <div className="flex items-center gap-x-1 truncate">
          <p className="text-grayLight900 font-normal leading-[1.5] truncate">
            {data.addressTo}
          </p>
          <ArrowRight className="w-3 h-3 text-grayLight900" />
          <p className="text-grayLight900 font-normal leading-[1.5] truncate">
            {data.addressFrom}
          </p>
        </div>
        <div className="text-gray500 font-normal leading-[1.5] ">
          <span className="text-grayLight900 font-semibold">
            {data.weight}&nbsp;
          </span>
          LBS
        </div>
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="text-gray500 font-normal leading-[1.5] border-r border-utilityGray200 my-2.5 px-2.5">
        <span className="text-grayLight900 font-semibold">
          {data.length}&nbsp;
        </span>
        FT
      </div>
    </td>
    <td className="p-0 w-0 whitespace-nowrap">
      <div className="flex items-center justify-between my-2.5 px-2.5 gap-x-2">
        <div className="text-grayLight900 font-semibold leading-[1.5] ">
          <span className="text-gray500 font-normal">Avg. Rate:&nbsp;</span>
          {data.rate}
        </div>
        <ChevronRight className="w-4 h-4 text-primary500" />
      </div>
    </td>
  </>
);

export default ActivityOverviewRow;
