import React, { useContext } from 'react';

import { OrderContext } from '..';

import AddOrderDetails from './AddOrderDetails';

const Step4 = () => {
  const {
    setIsTailgate,
    currentLegData,
    serviceList,
    setCurrentLegData,
    setWeightMeasurement,
    setLengthMeasurement,
    lengthMeasurement,
    weightMeasurement,
    order,
    setOrder,
    handlingUnitOptions,
    isHandlingUnitLoading,
  } = useContext<any>(OrderContext);

  return (
    <AddOrderDetails
      setIsTailgate={setIsTailgate}
      currentLegData={currentLegData}
      serviceList={serviceList}
      setCurrentLegData={setCurrentLegData}
      setWeightMeasurement={setWeightMeasurement}
      setLengthMeasurement={setLengthMeasurement}
      lengthMeasurement={lengthMeasurement}
      weightMeasurement={weightMeasurement}
      order={order}
      setOrder={setOrder}
      handlingUnitOptions={handlingUnitOptions}
      isHandlingUnitLoading={isHandlingUnitLoading}
    />
  );
};

export default Step4;
