import { Check } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CommonModal from 'src/components/CommonModal';

import defaultImage from '../../assets/img/default-image.jpg';
// import aiEmoji from '../../assets/img/inbound-emoji.webp';

interface IProps {
  handleClose: any;
}

const LocationHistoryModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Order ID'}
    titleDesc={'#WAL-NSBB-34'}
    handleClose={() => handleClose(false)}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
  >
    <ul className="p-5">
      <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
        <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-0 after:absolute flex items-center justify-center after:bg-successSecondary">
          <div className="w-[18px] h-[18px] rounded-full bg-fgSuccessPrimary flex justify-center items-center z-[1]">
            <Check className="w-3 h-3 text-white" />
          </div>
        </div>

        <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm p-2 flex flex-wrap gap-x-2">
          <div className="space-y-2 flex-1">
            <h6 className="text-grayLight900 text-sm font-medium">Bowden</h6>
            <div>
              <p className="text-utilityGray800 text-xs font-normal">
                53751 Blick Spring, Worcester, Nova Scotia T0T 5O5, Canada
              </p>
              <p className="text-gray500 text-[10px] leading-[1.5] font-normal">
                4 Nov 2023 10:30 AM
              </p>
            </div>
          </div>

          <div className="flex flex-col items-end gap-x-1 gap-y-2">
            <div className="flex items-center gap-x-1">
              <img
                src={defaultImage}
                className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              {/* IF NO IMAGE FOUND BELOW CODE WILL BE USED */}
              {/* <div className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[6px]">
                  WA
                </div> */}
              {/* IF AI EMOJI BELOW CODE */}
              {/* <img
                src={aiEmoji}
                className={`w-4 h-4 rounded-full flex-none bg-utilityPurple300 p-0.5 `}
              /> */}

              <h6 className="text-utilityGray800 text-xs font-medium max-w-40 truncate">
                Phoenix Baker
              </h6>
            </div>
            <BadgeCmp style="modern" type="success">
              On Track
            </BadgeCmp>
          </div>
        </div>
      </li>
      <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
        <div className="group-last:relative w-[18px] after:content-[''] after:h-full after:w-[2px] after:top-0 after:absolute flex items-center justify-center after:bg-successSecondary">
          <div className="w-2 h-2 rounded-full bg-fgSuccessPrimary flex justify-center items-center z-[1] mt-3.5"></div>
        </div>

        <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm p-2 flex flex-wrap gap-x-2">
          <div className="space-y-2 flex-1">
            <h6 className="text-grayLight900 text-sm font-medium">Bowden</h6>
            <div>
              <p className="text-utilityGray800 text-xs font-normal">
                53751 Blick Spring, Worcester, Nova Scotia T0T 5O5, Canada
              </p>
              <p className="text-gray500 text-[10px] leading-[1.5] font-normal">
                4 Nov 2023 10:30 AM
              </p>
            </div>
          </div>

          <div className="flex flex-col items-end gap-x-1 gap-y-2">
            <div className="flex items-center gap-x-1">
              <img
                src={defaultImage}
                className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              {/* IF NO IMAGE FOUND BELOW CODE WILL BE USED */}
              {/* <div className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[6px]">
                  WA
                </div> */}
              {/* IF AI EMOJI BELOW CODE */}
              {/* <img
                src={aiEmoji}
                className={`w-4 h-4 rounded-full flex-none bg-utilityPurple300 p-0.5 `}
              /> */}

              <h6 className="text-utilityGray800 text-xs font-medium max-w-40 truncate">
                Phoenix Baker
              </h6>
            </div>
            <BadgeCmp style="modern" type="success">
              On Track
            </BadgeCmp>
          </div>
        </div>
      </li>
      <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden">
        <div className="group-last:relative w-[18px] after:content-[''] after:h-full after:w-[2px] after:top-0 after:absolute flex items-center justify-center after:bg-successSecondary">
          <div className="w-2 h-2 rounded-full bg-fgSuccessPrimary flex justify-center items-center z-[1] mt-3.5"></div>
        </div>

        <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm p-2 flex flex-wrap gap-x-2">
          <div className="space-y-2 flex-1">
            <h6 className="text-grayLight900 text-sm font-medium">Bowden</h6>
            <div>
              <p className="text-utilityGray800 text-xs font-normal">
                53751 Blick Spring, Worcester, Nova Scotia T0T 5O5, Canada
              </p>
              <p className="text-gray500 text-[10px] leading-[1.5] font-normal">
                4 Nov 2023 10:30 AM
              </p>
            </div>
          </div>

          <div className="flex flex-col items-end gap-x-1 gap-y-2">
            <div className="flex items-center gap-x-1">
              <img
                src={defaultImage}
                className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              {/* IF NO IMAGE FOUND BELOW CODE WILL BE USED */}
              {/* <div className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[6px]">
                  WA
                </div> */}
              {/* IF AI EMOJI BELOW CODE */}
              {/* <img
                src={aiEmoji}
                className={`w-4 h-4 rounded-full flex-none bg-utilityPurple300 p-0.5 `}
              /> */}

              <h6 className="text-utilityGray800 text-xs font-medium max-w-40 truncate">
                Phoenix Baker
              </h6>
            </div>
            <BadgeCmp style="modern" type="success">
              On Track
            </BadgeCmp>
          </div>
        </div>
      </li>
      <li className="relative flex items-start gap-x-3 pb-4 last:pb-0 overflow-hidden group">
        <div className="group-last:relative w-[18px] after:content-[''] after:h-full after:w-[2px] after:top-0 after:absolute flex items-center justify-center after:bg-successSecondary">
          <div className="w-2 h-2 rounded-full bg-fgSuccessPrimary flex justify-center items-center z-[1] mt-3.5"></div>
        </div>

        <div className="flex-1 rounded-lg border border-utilityGray200 bg-white shadow-sm p-2 flex flex-wrap gap-x-2">
          <div className="space-y-2 flex-1">
            <h6 className="text-grayLight900 text-sm font-medium">Bowden</h6>
            <div>
              <p className="text-utilityGray800 text-xs font-normal">
                53751 Blick Spring, Worcester, Nova Scotia T0T 5O5, Canada
              </p>
              <p className="text-gray500 text-[10px] leading-[1.5] font-normal">
                4 Nov 2023 10:30 AM
              </p>
            </div>
          </div>

          <div className="flex flex-col items-end gap-x-1 gap-y-2">
            <div className="flex items-center gap-x-1">
              <img
                src={defaultImage}
                className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
              />
              {/* IF NO IMAGE FOUND BELOW CODE WILL BE USED */}
              {/* <div className="w-4 h-4 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[6px]">
                  WA
                </div> */}
              {/* IF AI EMOJI BELOW CODE */}
              {/* <img
                src={aiEmoji}
                className={`w-4 h-4 rounded-full flex-none bg-utilityPurple300 p-0.5 `}
              /> */}

              <h6 className="text-utilityGray800 text-xs font-medium max-w-40 truncate">
                Phoenix Baker
              </h6>
            </div>
            <BadgeCmp style="modern" type="success">
              On Track
            </BadgeCmp>
          </div>
        </div>
      </li>
    </ul>
  </CommonModal>
);

export default LocationHistoryModal;
