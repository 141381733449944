import { ArrowRight } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

import CanadaFlagIcon from '../../../../assets/img/canada-square-icon.svg';
import USFlagIcon from '../../../../assets/img/us-square-icon.svg';

interface FreightStatisticsRowProps {
  data: {
    category: string;
    num_of_loads: number;
    unique_customers: number;
    gross_profit: number;
    order_weight: number;
    gp_weight: number;
  };
  getRouteInfo: (category: string) => {
    from: string;
    to: string;
    isDomestic: boolean;
  };
}

const FreightStatisticsRow = ({
  data,
  getRouteInfo,
}: FreightStatisticsRowProps) => {
  const { from, to, isDomestic } = getRouteInfo(data?.category);

  return (
    <>
      <td className="px-5 py-4 w-[19%] min-w-[19%] max-w-px">
        <div className="flex items-center gap-x-5">
          <div className="flex-none relative">
            <div className="w-8 h-8 rounded-md border border-utilityGray200 bg-utilityGray100">
              {from === 'Canada' ? (
                <img
                  src={CanadaFlagIcon}
                  alt="Canada flag"
                  className="object-contain"
                />
              ) : (
                <img
                  src={USFlagIcon}
                  alt="USA flag"
                  className="object-contain"
                />
              )}
            </div>
            {!isDomestic && (
              <div className="absolute -bottom-[8px] -right-[12px] w-[24px] h-[24px] rounded-full border border-utilityGray200 bg-white">
                {to === 'Canada' ? (
                  <img
                    src={CanadaFlagIcon}
                    alt="Canada flag"
                    className="object-contain"
                  />
                ) : (
                  <img
                    src={USFlagIcon}
                    alt="USA flag"
                    className="object-contain"
                  />
                )}
              </div>
            )}
          </div>

          <div className="flex items-center gap-x-1.5 text-grayLight600 text-sm font-medium">
            {isDomestic ? (
              <span>{data?.category}</span>
            ) : (
              <>
                <span>{from}</span>
                <ArrowRight className="w-4 h-4 text-grayLight900" />
                <span>{to}</span>
              </>
            )}
          </div>
        </div>
      </td>

      <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
        <span>{data.num_of_loads.toLocaleString()}</span>
      </td>
      <td className="px-5 py-4 w-[16%] min-w-[16%] max-w-px">
        <span
          className={`${
            data?.order_weight < 0
              ? 'text-fgErrorPrimary'
              : 'text-fgSuccessPrimary'
          }`}
        >
          {data?.order_weight < 0 ? '' : '+'}
          {data?.order_weight}%
        </span>
      </td>
      <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
        <span>{data.unique_customers.toLocaleString()}</span>
      </td>
      <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
        <span>
          $
          {data.gross_profit.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </span>
      </td>
      <td className="px-5 py-4 w-[12%] min-w-[12%] max-w-px">
        <span
          className={`${
            data?.gp_weight < 0
              ? 'text-fgErrorPrimary'
              : 'text-fgSuccessPrimary'
          }`}
        >
          {data?.gp_weight < 0 ? '' : '+'}
          {data?.gp_weight}%
        </span>
      </td>
    </>
  );
};

export default FreightStatisticsRow;
