import {
  CurrencyDollar,
  Plus,
  Trash01,
} from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';

interface IProps {
  formData: any;
  isServiceLoading: any;
  serviceOptions: any;
  selectedOptions: any;
  validForm: any;
  setSelectedOptions: any;
  setFormData: any;
  setValidForm: any;
  handleInputChange: any;
  calculateMarginAndTotal: any;
  removeOptions: any;
  isAddItem: boolean;
}

const AddQuickQuoteAdditionaCharge = ({
  formData,
  isServiceLoading,
  serviceOptions,
  selectedOptions,
  validForm,
  setSelectedOptions,
  setFormData,
  setValidForm,
  handleInputChange,
  calculateMarginAndTotal,
  removeOptions,
  isAddItem,
}: IProps) => (
  <>
    {formData?.services?.length > 0 && (
      <div>
        <div className="flex flex-col gap-3">
          {formData?.services?.map((nonQuoteService: any, index: any) =>
            Object.entries(nonQuoteService).map(([slug, service]: any) => (
              <div className="flex w-full gap-x-2" key={slug}>
                <>
                  {!service.isOther ? (
                    <SelectBox
                      label={`${index === 0 ? 'Additional Charges' : ''}`}
                      name={slug}
                      parentClassName="flex-1"
                      labelClassName="form_label block mb-1.5"
                      id={`additional_charge-${index}-charge`}
                      size="sm"
                      placeholder="Select Additional Charge"
                      noOptionMessage="No Additional Charge Found"
                      isSearchable={true}
                      isLoading={isServiceLoading}
                      className={`form_control ${
                        validForm.services?.[index]
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      isError={!validForm.services?.[index]?.label}
                      isClearable={false}
                      options={serviceOptions?.filter(
                        (item: any) =>
                          item?.value === slug ||
                          item?.value === 'other' || // Keep the currently selected option
                          !selectedOptions?.includes(item?.value) // Remove options already selected elsewhere
                      )}
                      onChangeFunc={(e: any) => {
                        const selectedValue = e?.value;

                        setSelectedOptions([...selectedOptions, selectedValue]);
                        setFormData((old: any) => {
                          // Get the service to update
                          const updatedService = old.services[index];

                          // Create a new object with the updated slug and the same values

                          const updatedServiceObj = {
                            [e?.value]: {
                              ...updatedService[slug],
                              name: e?.value !== 'other' ? e?.value : '',
                              serviceId: e?.id,
                              isOther: e?.value === 'other' ? true : false,
                            },
                          };

                          // Return the updated form data
                          const updatedServices = [
                            ...old.services.slice(0, index), // Services before the updated one
                            updatedServiceObj, // New updated service
                            ...old.services.slice(index + 1), // Services after the updated one
                          ];

                          return {
                            ...old,
                            services: updatedServices,
                          };
                        });

                        if (e?.value) {
                          setValidForm((prevValidForm: any) => ({
                            ...prevValidForm,
                            services: prevValidForm.services?.map(
                              (item: any, i: number) =>
                                i === index
                                  ? {
                                      ...item,
                                      label: e?.value ? true : false,
                                    }
                                  : item
                            ),
                          }));
                        }
                      }}
                      required
                      value={serviceOptions?.find(
                        (e: any) => e?.value === slug
                      )}
                      // errorText={
                      //   !validForm.services?.[index] ? 'a' : null
                      // }
                    />
                  ) : (
                    <InputText
                      label={`${index === 0 ? 'Additional Charges' : ''}`}
                      labelClassName="form_label block mb-1.5"
                      inputName={`additional_charge.${index}.otherCharge`}
                      placeholder="Enter charge name"
                      required
                      className={`w-full ${
                        !validForm.services?.[index]?.label
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      value={service?.name || ''} // Ensure value is tied to the state
                      onChangeFunc={(e) => {
                        setFormData((old: any) => ({
                          ...old,
                          services: old.services.map(
                            (serviceItem: any, idx: any) =>
                              idx === index
                                ? {
                                    ...serviceItem,
                                    [slug]: {
                                      ...serviceItem[slug],
                                      name: e?.target?.value, // Update the value correctly
                                    },
                                  }
                                : serviceItem
                          ),
                        }));

                        if (e?.value) {
                          setValidForm((prevValidForm: any) => ({
                            ...prevValidForm,
                            services: prevValidForm.services?.map(
                              (item: any, i: number) =>
                                i === index
                                  ? {
                                      ...item,
                                      label: e?.value ? true : false,
                                    }
                                  : item
                            ),
                          }));
                        }
                      }}
                      // labelClassName="block mb-1.5"
                      parentClassName="flex-1"
                      isClearable
                    />
                  )}

                  <InputText
                    label={`${index === 0 ? 'Price' : ''}`}
                    placeholder="Enter price"
                    inputType="number"
                    className={`pl-8 w-full`}
                    inputName={slug}
                    value={service?.value || ''}
                    onChangeFunc={(e) => {
                      handleInputChange(e, true, index);

                      if (e?.target?.value) {
                        setValidForm((prevValidForm: any) => ({
                          ...prevValidForm,
                          services: prevValidForm.services?.map(
                            (item: any, i: number) =>
                              i === index
                                ? {
                                    ...item,
                                    price: e?.target?.value ? true : false,
                                  }
                                : item
                          ),
                        }));
                      }
                    }}
                    onBlur={(e) => calculateMarginAndTotal(e, true)}
                    labelClassName="block mb-1.5"
                    parentClassName="flex-1"
                    icon={<CurrencyDollar className="input-currency-sign" />}
                    shouldFormatNumber={true}
                  />

                  <div
                    className={`lg:h-9 h-[34px] flex items-center ${
                      index === 0 ? 'mt-[21px]' : ''
                    }`}
                  >
                    <ButtonCmp
                      className="btn_secondary_black lg:!px-[9px] !px-2"
                      icon={<Trash01 className="w-4 h-4" />}
                      onClick={() => removeOptions(index, service?.value)}
                    >
                      <></>
                    </ButtonCmp>
                  </div>
                </>
              </div>
            ))
          )}
        </div>
        {isAddItem ? (
          <div
            className={`btn-link-custom mt-2 `}
            onClick={() => {
              setFormData((old: any) => ({
                ...old,
                services: [
                  ...old.services,
                  {
                    [`${formData?.services?.length}`]: {
                      name: '',
                      value: '',
                      serviceId: '',
                      isOther: false,
                    },
                  },
                ],
              }));

              setValidForm((old: any) => ({
                ...old,
                services: [
                  ...old.services,
                  {
                    label: true,
                    price: true,
                  },
                ],
              }));
            }}
          >
            <Plus className="w-4 h-4" />
            Add line Item
          </div>
        ) : (
          ''
        )}
      </div>
    )}
  </>
);

export default AddQuickQuoteAdditionaCharge;
