import { StopCircle } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import agentFaceIcon from 'src/assets/img/agent-face-icon.png';
import ActionTooltip from 'src/components/ActionTooltip';
import ButtonCmp from 'src/components/ButtonCmp';

const LoadBoardCountDownTimer = ({
  truckFindingAiDetail,
  endDate,
  inboundInprogressCallCount,
  stopRateGathering,
  isStopRateLoading,
}: any) => {
  const calculateTimeDifference = () => {
    const now = moment.utc();

    if (endDate.isBefore(now)) {
      return '';
    }

    const duration = moment.duration(endDate.diff(now));
    const days = duration.days();
    const hours = duration.hours() + days * 24;
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    if (hours > 0) {
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
      )}:${String(seconds).padStart(2, '0')}`;
    } else {
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
        2,
        '0'
      )}`;
    }
  };

  const [timeDifference, setTimeDifference] = useState(
    calculateTimeDifference()
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeDifference(calculateTimeDifference());
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  return (
    <>
      {timeDifference && (
        <>
          <ActionTooltip
            isOpenChildTopPosOnTop={true}
            openOnHover={true}
            tooltipPosition="center"
            type="dark"
            message={
              <>
                <div className="flex items-center gap-2 whitespace-nowrap">
                  <p className="text-white text-xs font-medium min-w-10">
                    {timeDifference}
                  </p>
                  <p className="text-white text-xs font-medium border-l border-white pl-2">
                    {inboundInprogressCallCount} In Progress
                  </p>
                </div>
              </>
            }
          >
            <img
              src={agentFaceIcon}
              className={`w-6 h-6 rounded-full flex-none bg-success200 p-0.5`}
            />
          </ActionTooltip>

          <ActionTooltip
            message={'Stop Rate Gathering'}
            openOnHover={true}
            isOpenChildTopPosOnTop={true}
            type="dark"
            tooltipPosition="center"
            parentClassName="w-max"
          >
            <ButtonCmp
              className="btn-link-secondary flex"
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                stopRateGathering(truckFindingAiDetail);
              }}
              disabled={isStopRateLoading?.[truckFindingAiDetail.id]}
              loading={isStopRateLoading?.[truckFindingAiDetail.id]}
              icon={
                !isStopRateLoading?.[truckFindingAiDetail.id] && (
                  <StopCircle className="w-4 h-4 cursor-pointer" />
                )
              }
            >
              <></>
            </ButtonCmp>
          </ActionTooltip>

          <p className="border-l border-borderPrimary">
            <span className="invisible pr-px"></span>
          </p>
        </>
      )}
    </>
  );
};

export default LoadBoardCountDownTimer;
