import { XClose } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import { getShortName } from 'src/utils/CommonFunctions';
import { onError } from 'src/utils/CommonFunctions';

import QuoteRequestTypeBadgeSelectBox from '../QuoteRequestTypeBadgeSelectBox';

import EmailDetailSection from './EmailDetailSection';

interface IProps {
  isLoadingMore: boolean;
  loading: boolean;
  emailDetails: any;
  setIsEmailClicked: any;
  selectedThreadId: number | undefined;
  setSelectedThreadId: any;
}

const AccordionMailDetailView = ({
  isLoadingMore,
  loading,
  emailDetails,
  setIsEmailClicked,
  selectedThreadId,
  setSelectedThreadId,
}: IProps) => {
  const [allEmailParticipants, setAllEmailParticipants] = useState([]);

  const handleEmailClick = (email: any) => {
    if (selectedThreadId === email?.id) {
      setSelectedThreadId(null);
    } else {
      setSelectedThreadId(email?.id);
    }
  };

  function getEmailParticipants(emailThread: any) {
    const participants = new Set();

    function extractEmails(emailObj: any) {
      if (!emailObj || !emailObj.emailBody) return;

      const { sender, from, toRecipients } = emailObj.emailBody;

      if (sender?.emailAddress?.address)
        participants.add(sender.emailAddress.address);
      if (from?.emailAddress?.address)
        participants.add(from.emailAddress.address);

      if (Array.isArray(toRecipients)) {
        toRecipients.forEach((recipient) => {
          if (recipient?.emailAddress?.address) {
            participants.add(recipient.emailAddress.address);
          }
        });
      }
    }

    // Extract from main email
    extractEmails(emailThread);

    // Extract from the thread
    if (Array.isArray(emailThread.thread)) {
      emailThread.thread.forEach(extractEmails);
    }

    return Array.from(participants);
  }

  useEffect(() => {
    setSelectedThreadId(selectedThreadId);
  }, [selectedThreadId]);

  useEffect(() => {
    const allEmailParticipantList: any = getEmailParticipants(emailDetails);
    setAllEmailParticipants(allEmailParticipantList);

    setSelectedThreadId(emailDetails?.id);

    return () => {
      setAllEmailParticipants([]);
      setSelectedThreadId(null);
    };
  }, [emailDetails]);

  return (
    <>
      <div className="flex flex-wrap xxl:gap-4 lg:gap-3 gap-2 w-full justify-between border-b border-utilityGray200 px-5 py-3.5">
        <div className="flex-1 flex flex-wrap xxl:gap-x-4 gap-x-2 lg:flex-row flex-col ">
          <div className="flex-1">
            <h6 className="text-grayLight900 text-base font-semibold flex xxl:gap-4 gap-3 items-center">
              {emailDetails?.emailBody?.subject ?? '-'}
            </h6>

            <a
              href="mailto:katherine.moss@wal.com"
              className="text-grayLight600 text-xs leading-[1.5] font-normal block w-fit"
            >
              {allEmailParticipants.slice(0, 2).join(', ')}
              {allEmailParticipants.length > 2 && (
                <span className="text-grayLight600">
                  {'... '}
                  <TooltipCmp
                    message={allEmailParticipants
                      ?.filter((p: any, i: number) => i > 1)
                      .join(', ')}
                  >
                    <BadgeCmp
                      style="modern"
                      type="gray"
                      mainClassName=""
                      isHidePillDot
                    >
                      +{allEmailParticipants?.length - 2}
                    </BadgeCmp>
                  </TooltipCmp>
                </span>
              )}
            </a>
          </div>
          <div className="flex flex-wrap items-end lg:flex-col">
            {emailDetails?.isQuoteEmail ? (
              <BadgeCmp
                style="modern"
                mainClassName="rounded-md min-w-[65px] justify-center !px-1 !border-borderPrimary shadow !bg-white"
                type={'primary'}
              >
                Quote
              </BadgeCmp>
            ) : (
              <BadgeCmp
                style="modern"
                mainClassName="rounded-md min-w-[65px] justify-center !px-1 !border-borderPrimary shadow !bg-white"
                type={'gray'}
              >
                Other
              </BadgeCmp>
            )}
          </div>
        </div>
        <ButtonCmp
          className="btn_secondary_black !px-1.5 !py-1.5 self-start sm:hidden"
          onClick={() => setIsEmailClicked(false)}
        >
          <XClose className="w-3.5 h-3.5" />
        </ButtonCmp>
      </div>

      <ul className="space-y-4 p-5 flex-1 overflow-auto custom-scrollbar-v2 flex flex-col">
        {emailDetails?.thread?.length > 0 ? (
          <>
            {emailDetails?.thread?.map((item: any, index: number) => (
              <li
                key={index}
                onClick={() => handleEmailClick(item)}
                className={`rounded-lg bg-white shadow-md flex flex-col ${
                  selectedThreadId === item?.id ? 'flex-1' : ''
                }`}
              >
                <>
                  <div
                    className={`px-4 py-3 flex items-center gap-2 cursor-pointer ${
                      selectedThreadId === item?.id
                        ? 'border-b border-utilityGray200'
                        : ''
                    }`}
                  >
                    {item?.image ? (
                      <img
                        className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                        src={item?.imageUrl + item?.image}
                        alt=""
                        title=""
                        onError={onError}
                      />
                    ) : (
                      <div className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                        {getShortName(
                          `${item?.emailBody?.from?.emailAddress?.name}`
                        )}
                      </div>
                    )}
                    <div className="flex-1 space-y-0.5">
                      <h4 className="text-grayLight900 text-sm font-medium truncate">
                        {item?.emailBody?.from?.emailAddress?.name}
                      </h4>
                      <p className="text-grayLight600 text-xs font-normal truncate">
                        {item?.emailBody?.from?.emailAddress?.address}
                      </p>
                    </div>
                    <div className="space-y-1 text-right">
                      <div className="text-grayLight600 text-xs font-normal">
                        {item?.createdAt
                          ? moment(item.createdAt).format(
                              'hh:mm A ddd, MMM D, YYYY'
                            )
                          : ''}
                      </div>
                      <QuoteRequestTypeBadgeSelectBox
                        loading={loading}
                        isLoadingMore={isLoadingMore}
                      />
                    </div>
                  </div>
                  {selectedThreadId === item?.id ? (
                    <EmailDetailSection emailDetails={item} />
                  ) : (
                    ''
                  )}
                </>
              </li>
            ))}
          </>
        ) : (
          <>
            <li
              key={1}
              onClick={() => handleEmailClick(emailDetails)}
              className={`rounded-lg bg-white shadow-md flex flex-col ${
                selectedThreadId === emailDetails?.id ? 'flex-1' : ''
              }`}
            >
              <>
                <div
                  className={`px-4 py-3 flex items-center gap-2 cursor-pointer ${
                    selectedThreadId === emailDetails?.id
                      ? 'border-b border-utilityGray200 '
                      : ''
                  }`}
                >
                  {emailDetails?.image ? (
                    <img
                      className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                      src={emailDetails?.imageUrl + emailDetails?.image}
                      alt=""
                      title=""
                      onError={onError}
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                      {getShortName(
                        `${emailDetails?.emailBody?.from?.emailAddress?.name}`
                      )}
                    </div>
                  )}
                  <div className="flex-1 space-y-0.5">
                    <h4 className="text-grayLight900 text-sm font-medium truncate">
                      {emailDetails?.emailBody?.from?.emailAddress?.name}
                    </h4>
                    <p className="text-grayLight600 text-xs font-normal truncate">
                      {emailDetails?.emailBody?.from?.emailAddress?.address}
                    </p>
                  </div>
                  <div className="space-y-1 text-left">
                    <div className="text-grayLight600 text-xs font-normal">
                      {emailDetails?.createdAt
                        ? moment(emailDetails.createdAt).format(
                            'hh:mm A ddd, MMM D, YYYY'
                          )
                        : ''}
                    </div>
                    <QuoteRequestTypeBadgeSelectBox
                      loading={loading}
                      isLoadingMore={isLoadingMore}
                      parentClassName="w-fit ml-auto"
                    />
                  </div>
                </div>
                {selectedThreadId === emailDetails?.id ? (
                  <EmailDetailSection emailDetails={emailDetails} />
                ) : (
                  ''
                )}
              </>
            </li>
          </>
        )}
      </ul>
    </>
  );
};

export default AccordionMailDetailView;
