import { ApexOptions } from 'apexcharts';
import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import NotFoundUI from 'src/components/NotFoundUI';
import TableCmp from 'src/components/TableCmp';
import { convertSecondsToTime } from 'src/utils/CommonFunctions';

import winLossRowCmp from './winLossRowCmp';
import winLossRowCmpLoader from './winLossRowCmpLoader';
// import TabButton from 'src/components/TabButton';

const transformedArray = (data: any, dataType = 'values') => {
  console.log('⭐️ ~ transformedArray ~ data:', data);
  const transformedArr = data[dataType].map((value: any) => {
    console.log('⭐️ ~ transformedArr ~ value:', value);

    if (dataType === 'values') {
      const percentage =
        data.totalValue > 0 ? (value / data.totalValue) * 100 : 0;

      return percentage;
    } else if (dataType === 'labels') {
      return value;
    }
  });

  return transformedArr?.length === 5 ? [...transformedArr] : transformedArr;
};

interface IProps {
  activeStatistics: string;
  isLoading: boolean;
  data: any;
}

const titleObj = {
  totalQuoteRequests: 'Total Quote Requests',
  totalQuotes: 'Total Quotes',
  totalOrders: 'Total Orders',
  avgTimeToClassify: 'Avg. Time to Classify',
  avgTimeToQuote: 'Avg. Time to Quote',
  winLoss: 'Win/Loss',
};

const QuoteRequestsGraph = ({ activeStatistics, data, isLoading }: IProps) => {
  const allValuesAreZero = (arr: number[]) => arr.every((value) => value === 0);
  const headCells = useMemo(
    () => [
      {
        id: 'percentage_bracket',
        name: 'Win %',

        sortable: false,
      },
      {
        id: 'customer_count',
        name: 'Customers',

        sortable: false,
      },
    ],
    []
  );

  // Determine which data set to use based on the active tab
  const currentData =
    activeStatistics != 'winLoss' ? transformedArray(data.pieChartData) : data;

  // Check if all values in the current dataset are 0
  const isAllZero =
    activeStatistics != 'winLoss' ? allValuesAreZero(currentData) : false;

  const options: ApexOptions = {
    chart: {
      width: '100%',
      height: '100%',
      type: 'donut',
    },
    labels:
      activeStatistics != 'winLoss'
        ? transformedArray(data.pieChartData, 'labels')
        : data,
    theme: {
      monochrome: { enabled: false },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 100,
        },
        donut: {
          size: '50%',
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    stroke: {
      show: false, // Removes the white border
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#444CE6', '#6273F2', '#8098F9', '#A4BCFD', '#EBECF0'],

    legend: {
      show: true,
      labels: {
        colors: ['#3538CD'],
      },
      formatter: function (val: string, opts: any): string {
        return `<p>${val}</p><b>${
          activeStatistics === 'avgTimeToClassify'
            ? convertSecondsToTime(data.pieChartData.values[opts.seriesIndex])
            : data.pieChartData.values[opts.seriesIndex]
        }</b>`;
      },
      markers: {
        width: 12,
        height: 12,
        radius: 12,
      },
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: 'Light',
      y: {
        formatter: (value) => `${value.toFixed(2)}%`, // Format the value in the tooltip
      },
    },
  };

  const initialSeries = [20, 20, 20, 20, 20];

  return (
    <>
      {(isLoading || isAllZero) && activeStatistics != 'winLoss' ? (
        <NotFoundUI
          iconClassName="hidden"
          title={`No  ${
            titleObj[activeStatistics as keyof typeof titleObj]
          } Data Split Found`}
          desc={`There are no ${
            titleObj[activeStatistics as keyof typeof titleObj]
          } data split found.`}
          containerClassName="!h-auto flex-1"
        />
      ) : (
        <>
          {activeStatistics === 'winLoss' ? (
            <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 overflow-hidden">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide table-without-pagination">
                <TableCmp
                  tableDataArr={data?.winLossBracket ?? []}
                  headCells={headCells}
                  isTableDataLoading={isLoading}
                  TableRowCmp={winLossRowCmp}
                  TableLoaderRowCmp={winLossRowCmpLoader}
                />
              </div>
            </div>
          ) : (
            <div className="analytics_tooltip flex flex-col flex-1 analytics-chart relative">
              <ReactApexChart
                options={options}
                series={
                  isLoading
                    ? initialSeries
                    : transformedArray(data.pieChartData)
                }
                type="donut"
                height="280px"
                className="!min-h-[unset] flex-1"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default QuoteRequestsGraph;
