import { Edit01, Plus } from '@untitled-ui/icons-react/build/cjs';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { launchAiAgent } from 'src/services/RadarService';
import {
  getDateWithSuffixFormat,
  getFormattedDateWithTime,
} from 'src/utils/CommonFunctions';

import {
  convertTo12HourFormat,
  dateArr,
  followUpArr,
  formatTo1PM,
  timeArr,
} from './constant';

const ReviewConfig = forwardRef(
  ({
    activeLegData,
    reviewConfigData,
    setStep,
    aiAgentDetails,
    reviewConfigModalRef,
    launchAgentLoading,
    setLaunchAgentLoading,
    setActiveLegData,
    handleSuccess,
    setIsConfirmationModalOpen,
  }: any) => {
    const [isReminderError, setIsReminderError] = useState(false);
    const [isFollowUpError, setIsFollowUpError] = useState(false);
    const pickupDate = activeLegData?.carrierPickupAppointmentDatetime
      ? activeLegData?.carrierPickupAppointmentDatetime
      : activeLegData?.expectedPickupTime
      ? formatTo1PM(activeLegData?.expectedPickupTime)
      : undefined;

    const deliveryDate = activeLegData?.carrierDeliveryAppointmentDatetime
      ? activeLegData?.carrierDeliveryAppointmentDatetime
      : activeLegData?.expectedEstimatedDeliveryTime
      ? formatTo1PM(activeLegData?.expectedEstimatedDeliveryTime)
      : undefined;

    const checkReminderExist = () => {
      if (
        reviewConfigData?.isPickup?.length > 0 ||
        reviewConfigData?.isTransit ||
        reviewConfigData?.isDelivery?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    };

    const checkFollowUpExist = () => {
      if (reviewConfigData?.isFollowUp?.length > 0) {
        return true;
      } else {
        return false;
      }
    };

    const launchAgent = async () => {
      if (launchAgentLoading) return;

      setLaunchAgentLoading(true);
      const payload = {
        masterOrderId: activeLegData?.masterOrderId,
        legId: activeLegData?.id,
        agentId: activeLegData?.agentId ? null : aiAgentDetails?.id,
      };
      launchAiAgent(payload)
        .then((response: any) => {
          if (response?.message === 'Success') {
            setActiveLegData({
              ...activeLegData,
              agentId: response?.data?.agentId,
            });
            handleSuccess();
          }
        })
        .catch((error) => {
          console.log('error :>> ', error);
        })
        .finally(() => {
          setLaunchAgentLoading(false);
        });
    };

    useImperativeHandle(reviewConfigModalRef, () => ({
      reviewConfigSubmit: () => {
        launchAgent();
      },
      reviewConfigValidate: () => {
        if (!checkReminderExist() || !checkFollowUpExist()) {
          if (!checkReminderExist()) {
            setIsReminderError(true);
          }

          if (!checkFollowUpExist()) {
            setIsFollowUpError(true);
          }

          return;
        } else {
          setIsConfirmationModalOpen(
            activeLegData?.agentId ? 'abort' : 'launch'
          );
        }
      },
    }));

    return (
      <>
        <div className="space-y-1">
          <h2 className="text-grayLight900 text-lg font-semibold">
            Review Configuration
          </h2>
          <p className="text-grayLight600 text-sm font-normal">
            I can help you to track and trace your order
          </p>
        </div>
        <ul className="space-y-5 flex-1 overflow-auto custom-scrollbar-v2">
          <li className="border-t border-utilityGray200 flex gap-5 pt-5">
            <h6 className="text-grayLight900 text-sm font-semibold min-w-24">
              Pickup
            </h6>
            <div
              className={`rounded-lg bg-gray50 p-3 flex gap-x-3.5 flex-1 ${
                isReminderError ? 'border border-red-600' : ''
              }`}
            >
              <ol
                className={`${
                  reviewConfigData?.isPickup?.length > 0
                    ? 'list-decimal pl-[18px]'
                    : 'list-none'
                }  space-y-1.5 flex-1`}
              >
                {reviewConfigData?.isPickup?.length > 0 ? (
                  <>
                    <li
                      className="text-gray500 text-sm font-normal"
                      dangerouslySetInnerHTML={{
                        __html: pickupDate
                          ? getDateWithSuffixFormat(
                              getFormattedDateWithTime(
                                pickupDate,
                                'dddd, MMMM Do, YYYY [at] h:mmA',
                                true,
                                true
                              )
                            )
                          : '',
                      }}
                    />
                    {reviewConfigData?.isPickup?.map(
                      (item: any, index: number) => (
                        <li
                          className="text-gray500 text-sm font-normal"
                          key={index}
                        >
                          <div className="">
                            <span className="pr-2.5">
                              {
                                dateArr?.find(
                                  (val) => val?.value === item?.reminderDateType
                                )?.label
                              }
                            </span>
                            <span className="border-l border-borderPrimary pl-2.5">
                              {
                                timeArr?.find(
                                  (val) => val?.value === item?.reminderTimeType
                                )?.label
                              }
                            </span>
                          </div>
                        </li>
                      )
                    )}
                  </>
                ) : (
                  <li className="text-gray500 text-sm font-normal">
                    Add a pickup reminder
                  </li>
                )}
              </ol>
              {reviewConfigData?.isPickup?.length > 0 ? (
                <Edit01
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(4)}
                />
              ) : (
                <Plus
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(4)}
                />
              )}
            </div>
          </li>
          <li className="border-t border-utilityGray200 flex gap-5 pt-5">
            <h6 className="text-grayLight900 text-sm font-semibold min-w-24">
              In Transit
            </h6>
            <div
              className={`rounded-lg bg-gray50 p-3 flex gap-x-3.5 flex-1 ${
                isReminderError ? 'border border-red-600' : ''
              }`}
            >
              <ol
                className={`${
                  reviewConfigData?.isTransit
                    ? 'list-decimal pl-[18px]'
                    : 'list-none'
                }  space-y-1.5 flex-1`}
              >
                {reviewConfigData?.isTransit ? (
                  <>
                    <li className="text-gray500 text-sm font-normal">
                      {` Follow up Hours ${
                        followUpArr?.find(
                          (val) =>
                            val?.value ===
                            reviewConfigData?.isTransit?.followUpHoursInterval
                        )?.label
                      }`}
                    </li>
                    <li className="text-gray500 text-sm font-normal">
                      {`Between ${convertTo12HourFormat(
                        reviewConfigData?.isTransit?.followUpStartTime
                      )} and ${convertTo12HourFormat(
                        reviewConfigData?.isTransit?.followUpEndTime
                      )}`}
                    </li>
                  </>
                ) : (
                  <li className="text-gray500 text-sm font-normal">
                    Add a transit reminder
                  </li>
                )}
              </ol>
              {reviewConfigData?.isTransit ? (
                <Edit01
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(5)}
                />
              ) : (
                <Plus
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(5)}
                />
              )}
            </div>
          </li>
          <li className="border-t border-utilityGray200 flex gap-5 pt-5">
            <h6 className="text-grayLight900 text-sm font-semibold min-w-24">
              Delivery
            </h6>
            <div
              className={`rounded-lg bg-gray50 p-3 flex gap-x-3.5 flex-1 ${
                isReminderError ? 'border border-red-600' : ''
              }`}
            >
              <ol
                className={`${
                  reviewConfigData?.isDelivery?.length > 0
                    ? 'list-decimal pl-[18px]'
                    : 'list-none'
                }  space-y-1.5 flex-1`}
              >
                {reviewConfigData?.isDelivery?.length > 0 ? (
                  <>
                    <li
                      className="text-gray500 text-sm font-normal"
                      dangerouslySetInnerHTML={{
                        __html: deliveryDate
                          ? getDateWithSuffixFormat(
                              getFormattedDateWithTime(
                                deliveryDate,
                                'dddd, MMMM Do, YYYY [at] h:mmA',
                                true,
                                true
                              )
                            )
                          : '',
                      }}
                    />
                    {reviewConfigData?.isDelivery?.map(
                      (item: any, index: number) => (
                        <li
                          className="text-gray500 text-sm font-normal"
                          key={index}
                        >
                          <div className="">
                            <span className="pr-2.5">
                              {
                                dateArr?.find(
                                  (val) => val?.value === item?.reminderDateType
                                )?.label
                              }
                            </span>
                            <span className="border-l border-borderPrimary pl-2.5">
                              {
                                timeArr?.find(
                                  (val) => val?.value === item?.reminderTimeType
                                )?.label
                              }
                            </span>
                          </div>
                        </li>
                      )
                    )}
                  </>
                ) : (
                  <li className="text-gray500 text-sm font-normal">
                    Add a delivery reminder
                  </li>
                )}
              </ol>

              {reviewConfigData?.isDelivery?.length > 0 ? (
                <Edit01
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(6)}
                />
              ) : (
                <Plus
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(6)}
                />
              )}
            </div>
          </li>
          <li className="border-t border-utilityGray200 flex gap-5 pt-5">
            <h6 className="text-grayLight900 text-sm font-semibold min-w-24">
              Follow Ups
            </h6>
            <div
              className={`rounded-lg bg-gray50 p-3 flex gap-x-3.5 flex-1 ${
                isFollowUpError ? 'border border-red-600' : ''
              }`}
            >
              <ol
                className={`${
                  reviewConfigData?.isFollowUp?.length > 0
                    ? 'list-decimal pl-[18px]'
                    : 'list-none'
                }  space-y-1.5 flex-1`}
              >
                {reviewConfigData?.isFollowUp?.length > 0 ? (
                  <>
                    {reviewConfigData?.isFollowUp?.map(
                      (item: any, index: number) => (
                        <li
                          className="text-gray500 text-sm font-normal"
                          key={index}
                        >
                          {`Follow Up ${item?.followUpMethod} - ${item?.followUpInterval} mins`}
                        </li>
                      )
                    )}
                  </>
                ) : (
                  <li className="text-gray500 text-sm font-normal">
                    Add a follow up
                  </li>
                )}
              </ol>

              {reviewConfigData?.isFollowUp?.length > 0 ? (
                <Edit01
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(7)}
                />
              ) : (
                <Plus
                  className="w-4 h-4 text-primary700 flex-none cursor-pointer"
                  onClick={() => setStep(7)}
                />
              )}
            </div>
          </li>
        </ul>
      </>
    );
  }
);

export default ReviewConfig;
