import {
  CurrencyDollar,
  MarkerPin02,
  Percent01,
  Plus,
  SwitchVertical01,
  SwitchVertical02,
} from '@untitled-ui/icons-react/build/cjs';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import AlertMessage from 'src/components/AlertMessage';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import { getPredictivePrice } from 'src/services/QuoteService';
import { fixedDecimal, getFormattedNumber } from 'src/utils/CommonFunctions';
import { fetchOrCalculateRoute } from 'src/utils/RouteUtils';

import mapBox from '../../../assets/img/mapBox.png';

import { predictivePriceOptions } from './PredictivePrice.interface';

const chartFilterTab: any = [
  {
    value: 'weekly',
    name: 'Weekly',
    isAllow: true,
  },
  {
    value: 'monthly',
    name: 'Monthly',
    isAllow: true,
  },
];

const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const initialSeries = [
  {
    name: 'Max',
    data: [
      [1739532022006, 1725],
      [1739445622006, 1600],
      [1739359222006, 1750],
      [1739272822006, 1700],
      [1739186422006, 1650],
      [1739100022006, 1500],
      [1739013622006, 1400],
      [1738927222006, 1300],
      [1738840822006, 1350],
      [1738754422006, 1250],
      [1738668022006, 1200],
      [1738581622006, 1300],
      [1738495222006, 1250],
      [1738408822006, 1350],
      [1738322422006, 1450],
      [1738236022006, 1400],
      [1738149622006, 1350],
      [1738063222006, 1400],
      [1737976822006, 1382],
      [1737890422006, 1389],
      [1737804022006, 1404],
      [1737717622006, 1436],
      [1737631222006, 1470],
      [1737544822006, 1483],
      [1737458422006, 1458],
      [1737372022006, 1406],
      [1737285622006, 1352],
      [1737199222006, 1278],
      [1737112822006, 1208],
      [1737026422006, 1167],
    ],
  },
];

const PredictivePriceChart = ({
  id,
  setPredictivePriceData,
  predictivePriceData,
  openAddQuickModal,
}: any) => {
  mapboxgl.accessToken = window?.MAPBOX_ACCESSTOKEN!;
  const [isActiveTab, setIsActiveTab] = useState<any>('weekly');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isTabChangeLoading, setIsTabChangeLoading] = useState<boolean>(false);

  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [margin, setMargin] = useState<any>(0);
  const [series, setSeries] = useState<any>([]);
  const [isReverseAddress, setIsReverseAddress] = useState(false);
  const [totalCost, setTotalCost] = useState<any>('');
  const [marginPercentage, setMarginPercentage] = useState<any>(0);
  const [totalSalePrice, setTotalSalePrice] = useState<any>(0);
  const [routeDistance, setRouteDistance] = useState<any>(0);
  const [isRouteDistanceLoading, setIsRouteDistanceLoading] =
    useState<boolean>(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const generateQuarterSelections = () => {
    const startYear = 2000;
    const currentYear = new Date().getFullYear();
    const currentQuarter = Math.floor((new Date().getMonth() + 3) / 3);
    const selections = [];

    for (let year = startYear; year <= currentYear; year++) {
      const maxQuarter = year === currentYear ? currentQuarter : 4;

      for (let quarter = 1; quarter <= maxQuarter; quarter++) {
        selections.push(`Q${quarter}-${year}`);
      }
    }

    return selections;
  };

  const quarterArr = generateQuarterSelections();
  const [dateRange, setDateRange] = useState<any>(
    moment().format(quarterArr[0])
  );
  const [chartOptions, setChartOptions] = useState<any>(predictivePriceOptions);

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';

      return newType;
    });
  };

  const getChartData = () => {
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    const filter = {
      activeTab: isActiveTab,
      range: dateRange,
      isReverseAddress: isReverseAddress,
    };

    setIsLoading(true);
    setChartOptions((prevOptions: any) => ({
      ...prevOptions,
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.5,
          stops: [0, 50, 90, 100],
        },
      },
    }));

    getPredictivePrice(id, filter, signal, isActiveTab)
      .then((response: any) => {
        if (response && response.data) {
          const { predictivePricingData } = response.data;
          setPredictivePriceData(predictivePricingData ?? []);
          setSeries(predictivePricingData?.graphData?.series ?? []);
          setTotalCost(predictivePricingData.total);

          setTimeout(() => {
            const validDates = response.data.xValues
              .map((x: string) => {
                const formattedDate =
                  isActiveTab === 'weekly'
                    ? moment(x.split(' - ')[0], 'MMM D').format('YYYY-MM-DD')
                    : moment(x, 'MMM').format('YYYY-MM');

                return moment(formattedDate, 'YYYY-MM-DD', true).isValid()
                  ? formattedDate
                  : null;
              })
              .filter((date: any) => date !== null);

            setChartOptions((prevOptions: any) => ({
              ...prevOptions,
              fill: {
                type: 'solid',
                colors: ['transparent'],
              },
              xaxis: {
                ...prevOptions.xaxis,
                categories: validDates,
              },
            }));

            setIsLoading(false);
            setIsTabChangeLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setIsLoading(false);
          }, 100);
        }
      })
      .catch((e: any) => {
        if (e.code !== 'ERR_CANCELED') setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isActiveTab === 'weekly') {
      setDateRange(quarterArr[quarterArr.length - 1]);
    }

    if (isActiveTab === 'monthly') {
      setDateRange(moment().format('YYYY'));
    }
  }, [isActiveTab]);

  const calculateMarginPercentageAndTotalSalePrice = () => {
    if (totalCost !== 0 && margin !== 0) {
      const marginPercentageValue = (margin / totalCost) * 100;
      setMarginPercentage(fixedDecimal(marginPercentageValue, 2));
      setTotalSalePrice(fixedDecimal(Number(totalCost) + Number(margin), 2));
    }
  };

  const calculateMarginAndMarginPercentage = () => {
    if (totalCost === 0 && margin !== 0) {
      setMargin(0);
    } else if (totalCost === 0 && marginPercentage !== 0) {
      setMarginPercentage(0);
    } else if (totalCost !== 0 && totalSalePrice !== 0) {
      const marginValue = totalSalePrice - totalCost;
      const marginPercentageValue = (marginValue / totalCost) * 100;
      setMargin(fixedDecimal(marginValue, 2));
      setMarginPercentage(fixedDecimal(marginPercentageValue, 2));
    }
  };

  useEffect(() => {
    getChartData();
  }, [dateRange, isReverseAddress, isActiveTab]);

  useEffect(() => {
    if (predictivePriceData?.fromFullAddress?.latitude) {
      const locationData = {
        originLatitude: predictivePriceData?.fromFullAddress?.latitude,
        originLongitude: predictivePriceData?.fromFullAddress?.longitude,
        destinationLatitude: predictivePriceData?.toFullAddress?.latitude,
        destinationLongitude: predictivePriceData?.toFullAddress?.longitude,
      };

      fetchOrCalculateRoute(
        locationData,
        predictivePriceData,
        setRouteDistance,
        setIsRouteDistanceLoading
      ).catch((error) => {
        console.error('Failed to fetch or calculate route:', error);
        setIsRouteDistanceLoading(false);
      });
    }
  }, [predictivePriceData]);

  return (
    <>
      <div id="chart" className="flex p-5">
        <div className="rounded-xl border border-utilityGray200 p-5 w-full flex-1 mr-5">
          <div className="flex sm:flex-row flex-col justify-between gap-2 items-center">
            <h6 className="text-grayLight900 text-md font-semibold">
              Predictive Index
            </h6>
            <div className="flex gap-x-5">
              <div className="flex gap-x-5">
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#A4BCFD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Maximum
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#3538CD]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Target
                  </div>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="w-2 h-2 rounded-full bg-[#6172F3]"></p>
                  <div className="text-textSecondary text-xs font-normal leading-none">
                    Minimum
                  </div>
                </div>
              </div>

              <TabButton
                parentClassName="!bg-gray50 "
                className={`!rounded-md !border-0 [&>span]:text-gray400`}
                activeClassName="!bg-white [&>span]:!text-textSecondary [&>span]:!font-semibold !shadow-md "
                isActive={isActiveTab}
                tabArray={chartFilterTab}
                isTab={true}
                handleOnClick={(e: any) => {
                  setIsActiveTab(e.target.dataset.value);
                  setIsTabChangeLoading(true);
                }}
              />
            </div>
          </div>
          <Chart
            options={chartOptions}
            series={isLoading ? initialSeries : series}
            type="area"
            height={360}
            className={`analytics_totalquotes_tooltip -ml-4 ${
              isLoading ? 'apex-line-chart custom-loading graph-loading' : ''
            }`}
          />
        </div>
        <div className="w-[496px] rounded-xl border border-utilityGray200 px-4 py-4 space-y-4">
          <div
            className={`rounded-lg border border-borderPrimary bg-white shadow-[inset_0_2px_4px_-2px_rgba(16,24,40,0.06)] p-4 ${
              isLoading && !isTabChangeLoading ? 'custom-loading' : ''
            }`}
          >
            <ul className="space-y-0.5">
              <li className="relative flex items-start gap-x-3 overflow-hidden">
                <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-0 after:absolute flex items-center justify-center after:border-gray400 after:border-dashed after:border-l">
                  <div className="w-[18px] h-[18px] bg-white flex justify-center items-center z-[2]">
                    <StatusDotProfileCmp dotColor="green" />
                  </div>
                </div>

                <div className="flex-1 text-grayLight900 text-xs font-medium overflow-hidden">
                  <div
                    className={`text-grayLight600 text-sm font-normal truncate cursor-default `}
                  >
                    {[
                      predictivePriceData?.fromFullAddress?.city,
                      predictivePriceData?.fromFullAddress?.postal,
                      predictivePriceData?.fromFullAddress?.country,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                </div>
              </li>
              <li className="relative flex items-start gap-x-3 pb-0 overflow-hidden">
                <div className="after:content-[''] after:h-full after:w-[2px] after:rounded-sm after:top-0 after:absolute flex items-center justify-center after:border-gray400 after:border-dashed after:border-l">
                  <div className="w-[18px] h-[18px] flex justify-center items-center"></div>
                </div>

                <div className="flex-1 flex items-center gap-x-4">
                  <p className="flex-1 border-t border-utilityGray100"></p>
                  <ButtonCmp
                    className="btn_secondary_black lg:!px-[9px] !px-2"
                    onClick={() => setIsReverseAddress(!isReverseAddress)}
                  >
                    {isReverseAddress ? (
                      <SwitchVertical01 className="w-3.5 h-3.5 text-grayLight900 " />
                    ) : (
                      <SwitchVertical02 className="w-3.5 h-3.5 text-grayLight900 " />
                    )}
                  </ButtonCmp>
                </div>
              </li>
              <li className="relative flex items-start gap-x-3 pb-6 last:pb-0 overflow-hidden">
                <div className="after:content-[''] after:h-0 after:w-[2px] after:rounded-sm after:top-0 after:absolute flex items-center justify-center after:bg-successSecondary">
                  <MarkerPin02 className="w-[18px] h-[18px] text-textSecondary" />
                </div>

                <div className="flex-1 text-grayLight900 text-xs font-medium overflow-hidden">
                  <div
                    className={`text-grayLight600 text-sm font-normal truncate cursor-default `}
                  >
                    {[
                      predictivePriceData?.toFullAddress?.city,
                      predictivePriceData?.toFullAddress?.postal,
                      predictivePriceData?.toFullAddress?.country,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <ul className="flex flex-wrap gap-3">
            <li
              className={`rounded-lg p-3 border border-utilityGray200 flex-1 space-y-1 whitespace-nowrap min-w-fit ${
                isLoading && !isTabChangeLoading ? 'custom-loading' : ''
              }`}
            >
              <h6 className={`text-gray500 text-xs font-normal `}>
                Predictive cost
              </h6>

              <p
                className={`text-grayLight900 text-xl font-semibold leading-[1.1] `}
              >
                {predictivePriceData?.total
                  ? getFormattedNumber(predictivePriceData?.total, true, true)
                  : '$0.00'}
              </p>
            </li>

            <li
              className={`rounded-lg p-3 border border-utilityGray200 flex-1 space-y-1 whitespace-nowrap min-w-fit ${
                (isLoading || isRouteDistanceLoading) && !isTabChangeLoading
                  ? 'custom-loading'
                  : ''
              }`}
            >
              <h6 className="text-gray500 text-xs font-normal">
                Cost per mile
              </h6>

              <p
                className={`text-grayLight900 text-xl font-semibold leading-[1.1] `}
              >
                {routeDistance && predictivePriceData?.total
                  ? fixedDecimal(routeDistance / predictivePriceData?.total, 2)
                  : '$0.00'}
              </p>
            </li>
            <li
              className={`rounded-lg p-3 border border-utilityGray200 flex-1 space-y-1 whitespace-nowrap min-w-fit ${
                (isLoading || isRouteDistanceLoading) && !isTabChangeLoading
                  ? 'custom-loading'
                  : ''
              }`}
            >
              <h6 className={`text-gray500 text-xs font-normal `}>
                Distance in miles
              </h6>
              <div className="flex items-center gap-x-1">
                <img src={mapBox} alt="mapBox" className="w-[18px] h-[18px]" />
                <p
                  className={`text-grayLight900 text-xl font-semibold leading-[1.1]`}
                >
                  {routeDistance ? fixedDecimal(routeDistance, 2) : 0} mi
                </p>
              </div>
            </li>
          </ul>

          <div className="flex gap-x-5 items-center pb-2">
            <div className="flex-1">
              <div className="flex justify-between">
                <p className="text-grayLight600 text-sm font-normal mb-1">
                  Confidence
                </p>
                <span className="text-grayLight600 text-sm font-semibold mb-1">
                  {predictivePriceData?.confidenceLevel}%
                </span>
              </div>
              <div className="progress-range-container">
                <div
                  className="progress-range-bar !bg-[#17B26A]"
                  style={{
                    width: `${predictivePriceData?.confidenceLevel}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="flex gap-x-3">
            <div className="flex-1">
              <InputText
                label={'Total Cost'}
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="totalCost"
                value={totalCost}
                className="pl-8"
                parentClassName="w-full"
                icon={<CurrencyDollar className="input-currency-sign" />}
                onChangeFunc={(e: any) => {
                  setTotalCost(e.target.value);
                }}
                onFocus={() => {
                  if (totalCost === 0) {
                    setTotalCost('');
                  }
                }}
                onBlur={() => {
                  if (totalCost === '') {
                    setTotalCost(0);
                  } else {
                    calculateMarginAndMarginPercentage();
                  }
                }}
              />
            </div>
            <div className="flex-1">
              <label className="form_label block mb-1.5">Margin</label>
              <div className="form_control p-0 relative !h-auto">
                {activeMarginType === '$' ? (
                  <InputText
                    inputName="margin"
                    inputType="number"
                    className="!border-0 !h-auto !pl-8 !pr-16"
                    labelClassName="block mb-1.5"
                    value={margin}
                    placeholder="margin"
                    onChangeFunc={(e: any) => {
                      setMargin(e.target.value);
                    }}
                    onFocus={() => {
                      if (margin === 0) {
                        setMargin('');
                      }
                    }}
                    onBlur={() => {
                      if (margin === '') {
                        setMargin(0);
                      } else {
                        calculateMarginPercentageAndTotalSalePrice();
                      }
                    }}
                    icon={<CurrencyDollar className="input-currency-sign" />}
                  />
                ) : (
                  <InputText
                    inputName="marginPercentage"
                    inputType="number"
                    placeholder="margin"
                    className="!border-0 !h-auto !pl-8 !pr-16"
                    labelClassName="block mb-1.5"
                    value={marginPercentage}
                    onChangeFunc={(e: any) => {
                      const value = e.target.value;
                      setMarginPercentage(value);
                    }}
                    onFocus={() => {
                      if (marginPercentage === 0) {
                        setMarginPercentage('');
                      }
                    }}
                    onBlur={() => {
                      if (marginPercentage === '') {
                        setMarginPercentage(0);
                      }
                    }}
                    icon={<Percent01 className="input-currency-sign" />}
                  />
                )}

                <TabButton
                  tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                  parentClassName="!bg-gray50 !p-[3px]"
                  className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                  activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                  tabArray={tabArray}
                  isActive={activeMarginType}
                  isTab={true}
                  handleOnClick={handleTabChange}
                />
              </div>
            </div>
            <div className="flex-1">
              <InputText
                label={'Total Sale Price'}
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="totalSalePrice"
                value={totalSalePrice}
                className="pl-8"
                icon={<CurrencyDollar className="input-currency-sign" />}
                onChangeFunc={(e: any) => {
                  setTotalSalePrice(e.target.value);
                }}
                onFocus={() => {
                  if (totalSalePrice === 0) {
                    setTotalSalePrice('');
                  }
                }}
                onBlur={() => {
                  if (totalSalePrice === '') {
                    setTotalSalePrice(0);
                  } else {
                    calculateMarginAndMarginPercentage();
                  }
                }}
              />
            </div>
          </div>
          <AlertMessage
            style="info"
            labelInfo={`Your average margin for this customer is $${
              predictivePriceData?.marginInPrice ?? 0
            }`}
            className={isLoading ? 'custom-loading' : ''}
          />
          <ButtonCmp
            className="btn-outline-primary w-full"
            icon={<Plus className="w-4 h-4" />}
            onClick={openAddQuickModal}
          >
            Add Quote
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default PredictivePriceChart;
