import parsePhoneNumberFromString from 'libphonenumber-js';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  capitalizeFirstLetterSpace,
  getFormattedNumber,
  getShortName,
  usePhone,
} from 'src/utils/CommonFunctions';

import defaultImage from '../../assets/img/default-image.jpg';
import DialOutboundCall from '../Twilio/CallLogs/DialOutboundCall';

interface IProps {
  data: any;
}

const PredictivePricingRow = ({ data }: IProps) => {
  const { getFormatPhoneWithPlus } = usePhone();
  const nameRef = useRef<HTMLHeadingElement>(null);
  const sourceAddressRef = useRef<HTMLParagraphElement>(null);
  const destinationAddressRef = useRef<HTMLParagraphElement>(null);
  const [isNameTruncated, setIsNameTruncated] = useState(false);
  const [isSourceAddressTruncated, setIsSourceAddressTruncated] =
    useState(false);
  const [isDestinationAddressTruncated, setIsDestinationAddressTruncated] =
    useState(false);
  const transferPhoneNumber = parsePhoneNumberFromString(
    `${!data?.transferNumber?.includes('+') ? '+1' : ''}${String(
      data?.transferNumber
    )}`
  );
  const companyPhoneNumber = parsePhoneNumberFromString(
    `${!data?.companyPhone?.includes('+') ? '+1' : ''}${String(
      data?.companyPhone
    )}`
  );

  useEffect(() => {
    if (nameRef.current) {
      setIsNameTruncated(
        nameRef.current.scrollWidth > nameRef.current.clientWidth
      );
    }
  }, []);

  useEffect(() => {
    if (sourceAddressRef.current) {
      setIsSourceAddressTruncated(
        sourceAddressRef.current.scrollWidth >
          sourceAddressRef.current.clientWidth
      );
    }
  }, []);

  useEffect(() => {
    if (destinationAddressRef.current) {
      setIsDestinationAddressTruncated(
        destinationAddressRef.current.scrollWidth >
          destinationAddressRef.current.clientWidth
      );
    }
  }, []);

  return (
    <>
      <td className="px-5 py-4 min-w-[200px] max-w-[1px] w-[15%]">
        <div className="flex items-center gap-3">
          {data.isCarrierFound ? (
            <>
              <div className="table-profile-wrap">
                {data?.carrierImage ? (
                  <img
                    src={`${data?.carrierImageUrl}${data?.carrierImage}`}
                    className="table-profile-icn-circle-xl rounded-md"
                    alt="image"
                  />
                ) : (
                  <div className="table-profile-customer-circle-xl rounded-md">
                    {getShortName(`${data.carrierName}`)}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="table-profile-wrap">
              <img
                src={defaultImage}
                className="table-profile-icn-circle-xl rounded-md"
                alt="image"
              />
            </div>
          )}
          <div className="truncate">
            <TooltipCmp message={isNameTruncated ? data?.carrierName : ''}>
              <h6
                ref={nameRef}
                className="text-grayLight900 font-medium leading-tight truncate"
              >
                {data.carrierName ? data.carrierName : 'Unknown'}
              </h6>
            </TooltipCmp>
            <p className="text-grayLight600 font-normal leading-tight truncate">
              {transferPhoneNumber
                ? transferPhoneNumber.formatInternational()
                : companyPhoneNumber
                ? companyPhoneNumber.formatInternational()
                : ''}
            </p>
          </div>
        </div>
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
        {data?.classification === 'ltl' && (
          <BadgeCmp style="modern" type="success">
            {data?.classification?.toUpperCase()}
          </BadgeCmp>
        )}
        {data?.classification === 'ftl' && (
          <BadgeCmp style="modern" type="lightblue">
            {data?.classification?.toUpperCase()}
          </BadgeCmp>
        )}
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[1px] min-w-[17.03%]">
        <TooltipCmp
          message={
            isSourceAddressTruncated
              ? `${capitalizeFirstLetterSpace(
                  data?.sourceCity
                )}, ${data?.sourceState?.toUpperCase()}`
              : ''
          }
        >
          <p ref={sourceAddressRef} className="truncate">
            {`${capitalizeFirstLetterSpace(
              data?.sourceCity
            )}, ${data?.sourceState?.toUpperCase()}`}
          </p>
        </TooltipCmp>
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[1px] min-w-[17.03%]">
        <TooltipCmp
          message={
            isDestinationAddressTruncated
              ? `${capitalizeFirstLetterSpace(
                  data?.destinationCity
                )}, ${data?.destinationState?.toUpperCase()}`
              : ''
          }
        >
          <p ref={destinationAddressRef} className="truncate">
            {`${capitalizeFirstLetterSpace(
              data?.destinationCity
            )}, ${data?.destinationState?.toUpperCase()}`}
          </p>
        </TooltipCmp>
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[200px] min-w-[200px]">
        <span>
          {moment.utc(data?.createdOn).local().format('MMM Do, YYYY, HH:mm')}
        </span>
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[1px] min-w-[17.03%]">
        <div className="truncate w-fit max-w-full">
          <TooltipCmp
            message={data?.carrierEquipmentType}
            parentClassName="min-w-[50px]"
          >
            <p className="truncate">{data?.carrierEquipmentType}</p>
          </TooltipCmp>
        </div>
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
        {data?.classification === 'ftl'
          ? '53 Ft.'
          : data?.linearFootage
          ? `${data?.linearFootage} Ft.`
          : '-'}
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
        {data?.totalWeight
          ? `${getFormattedNumber(data?.totalWeight, false, false, true)} lbs`
          : '-'}
      </td>

      <td className="px-5 py-4 w-[17.03%] max-w-[17.03%] min-w-[17.03%]">
        <span className="font-semibold">
          {getFormattedNumber(data?.offerAmount, true, true)}
        </span>
      </td>

      <td className="px-5 py-4 w-[70px] max-w-[70px] min-w-[70px] pointer">
        <DialOutboundCall
          data={{
            contactNumber: getFormatPhoneWithPlus(data.companyPhone),
            contactName: ['Not provided', 'None', 'null'].includes(
              data.carrierContactPerson
            )
              ? ''
              : data.carrierContactPerson,
            customerName: '',
            role: 'carrier',
            contactImageUrl: data?.carrierImageUrl + data?.carrierImage,
            carrierName: data.carrierName,
            contactId: null,
          }}
          className="text-primary"
        />
      </td>
    </>
  );
};

export default PredictivePricingRow;
