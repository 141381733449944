import { Copy02, Share06 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import DynamicCreatableSelect from 'src/components/DynamicCreatableSelect';
import InputText from 'src/components/InputText/InputText';
import Toggle from 'src/components/Toggle/Toggle';

interface IProps {
  handleClose: any;
}

const ShareQuoteModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Share Quote'}
    titleDesc={'Send a quote to one or multiple users seamlessly.'}
    handleClose={() => handleClose(false)}
    headerIcon={<Share06 />}
    size={'max-w-[688px]'}
    isOverflow={true}
    modalClassName=""
  >
    <div className="w-full p-5 space-y-4">
      <div className="rounded-lg border border-gray100 bg-gray25 shadow-xs p-3 space-y-3">
        <Toggle
          labelClassName="!items-start !justify-between"
          label={
            <div>
              <h6 className="text-textSecondary text-sm font-medium">
                Share Link
              </h6>
              <p className="text-grayLight600 text-xs font-normal">
                Generate and share an invitation link for help recommendations.
              </p>
            </div>
          }
          labelPosition="left"
          isChecked={true}
          onChange={() => {}}
        />
        <div className="flex gap-x-1.5">
          <InputText
            parentClassName="flex-1"
            inputType={'text'}
            inputName="link"
            label="Link"
            labelClassName="mb-1.5 block"
            className=""
            placeholder="Enter Link"
            value={'link'}
            onChangeFunc={() => {}}
            disabled
          />

          <ButtonCmp
            icon={<Copy02 className="w-4 h-4" />}
            onClick={() => {}}
            className="btn_secondary_black mt-[22px]"
          >
            Copy
          </ButtonCmp>
        </div>
      </div>
      <div className="flex items-center">
        <span className="h-[1px] bg-gray100 w-full"></span>
        <p className="text-grayLight600 text-xs text-center px-2 text-nowrap">
          OR
        </p>
        <span className="h-[1px] bg-gray100 w-full"></span>
      </div>
      <div className="flex gap-x-1.5">
        <div className="flex-1">
          <label className="form_label mb-1.5 block">
            Select a team member
          </label>
          <DynamicCreatableSelect
            placeholder="Enter To address"
            isClearable={true}
            isMulti={true}
            menuIsOpen={false}
            onChange={() => {}}
            onInputChange={() => {}}
            onKeyDown={() => {}}
            value={[]}
            inputValue={'inputValue'}
          />
        </div>
        <ButtonCmp
          onClick={() => {}}
          className="btn_primary mt-[22px] min-w-[78px]"
        >
          Share
        </ButtonCmp>
      </div>
    </div>
  </CommonModal>
);

export default ShareQuoteModal;
