import { PhoneCall01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from 'src/context/AuthContext';
import {
  setCallDuration,
  setErrorMessage,
  setHoldStatus,
  setIsCallInProgress,
  setIsMuted,
  setIsRecording,
  setOnHold,
  setOutgoingCall,
  setOutgoingCallDetails,
} from 'src/redux/CallCenter.slice';
import { RootState } from 'src/redux/store';
import { addCallLog } from 'src/services/TwilioService';
import { checkAudioDevicesAndPermissions } from 'src/utils/CommonFunctions';

type CallParams = Record<string, string>;

const DialOutboundCall = ({ data, className }: any) => {
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();

  const { device, outgoingCall } = useSelector(
    (state: RootState) => state.CallCenter
  );

  // Calling Functionality
  const handleCall = async (contactData: any) => {
    console.log('⭐️ ~ handleCall ~ data:', contactData);

    try {
      await checkAudioDevicesAndPermissions();
    } catch (error: any) {
      alert(error.message);

      return;
    }

    try {
      if (!device) {
        throw new Error('Device is not initialized');
      }

      contactData.contactNumber = '+919427420518';
      const params: CallParams = {
        To: contactData?.contactNumber,
        From: currentUser?.phone,
        intent: 'call-out',
      };
      console.log('⭐️ ~ handleCall ~ params:', params);

      const outBound = await device.connect({ params });
      console.log('⭐️ ~ handleCall ~ outBound:', outBound);

      dispatch(
        setOutgoingCallDetails({
          name: contactData?.contactName,
          companyName: contactData?.customerName
            ? contactData?.customerName
            : contactData?.carrierName
            ? contactData?.carrierName
            : 'Unknown',
          role: contactData?.role,
          image:
            contactData?.contactImageUrl && contactData?.contactImage
              ? contactData?.contactImageUrl + contactData?.contactImage
              : '',
          number: contactData?.contactNumber,
          carrierId: contactData?.carrierName != null && contactData?.contactId,
          customerId:
            contactData?.customerName != null && contactData?.contactId,
        })
      );

      outBound.on('accept', (connection: any) => {
        console.log('⭐️ ~ handleCall ~ Connection accepted by caller');
        const callSid = connection.parameters.CallSid;

        const callSids = {
          caller: outBound?.parameters.CallSid,
        };

        console.log('⭐️ ~ handleCall ~ callSids:', callSids);
        console.log('⭐️ ~ handleCall ~ Call SID:', callSid);

        setTimeout(async () => {
          await addCallLog({
            contactId: contactData?.contactId,
            fromUserId: currentUser?.id,
            fromPhoneNumber: currentUser?.phone,
            toPhoneNumber: contactData?.contactNumber,
            callType: 'outbound',
            status: 'started',
            callerCallSid: outBound?.parameters.CallSid,
          });
          console.log('⭐️ ~ handleCall ~ callInstance:', outgoingCall);
        }, 1000);

        dispatch(setOutgoingCall(outBound));
      });

      outgoingCall?.on('disconnect', (e: any) => {
        console.log('Call disconnected', e, outgoingCall);
        dispatch(setIsCallInProgress(false));
        dispatch(setCallDuration(0));
        dispatch(setOnHold(false));
        dispatch(setIsMuted(false));
        dispatch(setHoldStatus(false));
        dispatch(setIsRecording(true));
        dispatch(setOutgoingCall(null));
      });
    } catch (err: any) {
      setErrorMessage(
        err instanceof Error ? err.message : 'An unknown error occurred'
      );
      console.error('Error making call:', err);
    }
  };

  return (
    <PhoneCall01
      className={`w-4 h-4 text-gray400 cursor-pointer flex-none ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        handleCall(data);
      }}
    />
  );
};

export default DialOutboundCall;
