import axios from 'axios';

import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listQuote = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.QUOTE.LIST, {
    params,
    signal,
  });

  return response;
};

export const listQuoteCount = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.QUOTE.QUOTELIST_COUNT, {
    params,
    signal,
  });

  return response;
};

export const getQuote = async (id: number, signal?: any) => {
  const response = await axiosInterceptor.get(`${API.QUOTE.GET}/${id}`, {
    signal,
  });

  return response;
};

export const getUserQuotes = async () => {
  const response = await axiosInterceptor.get(API.QUOTE.GET_USER_QUOTES);

  return response;
};

export const getAnalytics = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.QUOTE.GET_ANALYTICS, {
    params,
    signal,
  });

  return response;
};

export const getTeamPerformance = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(API.QUOTE.GET_ANALYTICS_TEAM, {
    params,
    signal,
  });

  return response;
};

export const getCustomerSplit = async (params: any, signal?: any) => {
  const response = await axiosInterceptor.get(
    API.QUOTE.GET_ANALYTICS_CUSTOMER,
    {
      params,
      signal,
    }
  );

  return response;
};

export const createQuote = async (data: any) => {
  const response = await axiosInterceptor.post(API.QUOTE.CREATE, data);

  return response;
};

export const createUpdateQuoteSettings = async (data: any) => {
  const response = await axiosInterceptor.post(API.QUOTE.CREATE_SETTINGS, data);

  return response;
};

export const getQuoteSettings = async () => {
  const response = await axiosInterceptor.get(API.QUOTE.GET_SETTING);

  return response;
};

export const updateQuote = async (id: string, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.UPDATE}/${id}`,
    data
  );

  return response;
};

export const updateLoadInsurance = async (id: string, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.UPDATE_LOAD_INSURANCE}/${id}`,
    data
  );

  return response;
};

export const createUpdateService = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE.CREATE_UPDATE_SERVICE,
    data
  );

  return response;
};

export const createUpdateDimension = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE.CREATE_UPDATE_DIMENSION,
    data
  );

  return response;
};

export const createUpdateSummary = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE.CREATE_UPDATE_SUMMARY,
    data
  );

  return response;
};

export const createRate = async (data: any) => {
  const response = await axiosInterceptor.post(API.QUOTE.CREATE_RATE, data);

  return response;
};

export const quickQuote = async (data: any) => {
  const response = await axiosInterceptor.post(API.QUOTE.QUICK_QUOTE, data);

  return response;
};

export const updateQuickQuote = async (id: string, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.UPDATE_QUICK_QUOTE}/${id}`,
    data
  );

  return response;
};

export const updateRate = async (id: string, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.UPDATE_RATE}/${id}`,
    data
  );

  return response;
};

export const cancelQuote = async (data: any, id: number) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.CANCEL}/${id}`,
    data
  );

  return response;
};

export const pushQuoteToRoseRocket = async (id: number) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.PUSH_TO_ROSE_ROCKET}/${id}`
  );

  return response;
};

export const bookOrder = async (data: any, id: number) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.BOOK_ORDER}/${id}`,
    data
  );

  return response;
};

export const listPDF = async (id: number, params: any) => {
  const response = await axiosInterceptor.get(`${API.QUOTE.LIST_PDF}/${id}`, {
    params,
  });

  return response;
};

export const sendPDF = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.QUOTE.SEND_PDF}/${id}`,
    data
  );

  return response;
};

export const activeQuote = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.QUOTE.ACTIVE_QUOTE}/${id}`,
    data
    // { responseType: 'arraybuffer' }
  );

  return response;
};

export const deleteActiveQuote = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.QUOTE.DELETE_ACTIVE_QUOTE}/${id}`,
    data
    // { responseType: 'arraybuffer' }
  );

  return response;
};

export const createPDF = async (id: number, data: any) => {
  const response = await axiosInterceptor.post(
    `${API.QUOTE.CREATE_PDF}/${id}`,
    data
    // { responseType: 'arraybuffer' }
  );

  return response;
};

export const updatePDF = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.UPDATE_PDF}/${id}`,
    data
  );

  return response;
};

export const updateStatus = async (id: number) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.UPDATE_STATUS}/${id}`
  );

  return response;
};

export const downloadGCSPdf = async (url: string) => {
  const response = await axios.get(url, {
    responseType: 'blob',
  });

  return response;
};

export const deleteQuote = async (id: number) => {
  const response = await axiosInterceptor.delete(`${API.QUOTE.DELETE}/${id}`);

  return response;
};

export const modifyQuote = async (data: any) => {
  const response = await axiosInterceptor.post(API.QUOTE.MODIFY_QUOTE, data);

  return response;
};

export const spotQuoteRequest = async (data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.SPOT_QUOTE}`,
    data
  );

  return response;
};

export const spotQuoteUpdate = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.QUOTE.SPOT_QUOTE_UPDATE}/${id}`,
    data
  );

  return response;
};

export const getFreightClass = async (data: any, signal?: any) => {
  const response = await axiosInterceptor.post(
    API.QUOTE.GET_FREIGHT_CLASS,
    data,
    { signal }
  );

  return response;
};

export const getPredictivePrice = async (
  id: number,
  data?: any,
  signal?: any,
  isActiveTab?: string
) => {
  const response = await axiosInterceptor.post(
    `${API.QUOTE.GET_PREDICTIVE_PRICING}/${id}?days=${isActiveTab}`,
    data,
    { signal }
  );

  return response;
};

export const makeNoQuote = async (data: any) => {
  const response = await axiosInterceptor.post(API.QUOTE.MAKE_NO_QUOTE, data);

  return response;
};

export const checkBol = async (id: number) => {
  const response = await axiosInterceptor.patch(`${API.QUOTE.CHECK_BOL}/${id}`);

  return response;
};

export const getQuoteAnalyticsSplitData = async (params?: any) => {
  const response = await axiosInterceptor.get(API.QUOTE.SPLIT_CHART_DATA, {
    params,
  });

  return response;
};

export const getTotalQuoteChartData = async (params: any, signal: any) => {
  const response = await axiosInterceptor.get(
    API.QUOTE.GET_TOTAL_QUOTE_CHART_DATA,
    {
      params,
      signal,
    }
  );

  return response;
};
