import { Copy04, Eye, Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import Toggle from 'src/components/Toggle/Toggle';
import {
  calculateLinearFootage,
  formatNumberWithCommas,
  getLabelByValue,
  getRoundUpLinearFootage,
} from 'src/utils/CommonFunctions';

import ButtonCmp from '../../../../components/ButtonCmp';
import InputText from '../../../../components/InputText/InputText';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import TooltipCmp from '../../../../components/TooltipCmp';
import { getFreightClass } from '../../../../services/QuoteService';
import VisualizeUnit from '../VisualizeUnit';

const Items = (props: any) => {
  const [currentItem, setCurrentItem] = useState<any>({
    index: null,
    data: null,
  });
  const [isShowVisualUnit, setIsShowVisualUnit] = useState(false);
  const [isFreightClassLoading, setIsFreightClassLoading] = useState([
    { isLoading: false },
  ]);
  const {
    formData,
    validForm,
    initFormData,
    initValidForm,
    handlingUnitOptions,
    isDimensionLoading,
    setFormData,
    setValidForm,
    lengthMeasurement,
    weightMeasurement,
    setIsValid,
    isValid,
    validateMeasurement,
    isTotalWeightManuallyChanged,
    setTotalDimensionsWeight,
    setTotalWeightValue,
    isLinearFootageManuallyChanged,
  } = props;
  const [prevLengthMeasurement, setPrevLengthMeasurement] =
    useState(lengthMeasurement);
  const [isCalculateFreightClass, setIsCalculateFreightClass] = useState(false);

  const addItems = () => {
    setFormData((old: any) => ({
      ...old,
      dimensions: [...old.dimensions, ...initFormData.dimensions],
    }));

    setValidForm((old: any) => ({
      ...old,
      dimensions: [...old.dimensions, ...initValidForm.dimensions],
    }));

    console.log('validateMeasurement', validateMeasurement);
    setIsFreightClassLoading((old: any) => [...old, { isLoading: false }]);
  };

  const duplicateItems = () => {
    if (formData?.dimensions?.length > 0) {
      const lastItem = formData?.dimensions[formData?.dimensions?.length - 1];

      delete lastItem?.id;

      setFormData((old: any) => ({
        ...old,
        dimensions: [...old.dimensions, lastItem],
      }));

      const lastValidItem =
        validForm?.dimensions[formData?.dimensions?.length - 1];

      setValidForm((old: any) => ({
        ...old,
        dimensions: [...old.dimensions, lastValidItem],
      }));
    } else {
      setFormData((old: any) => ({
        ...old,
        dimensions: [...old.dimensions, ...initFormData.dimensions],
      }));

      setValidForm((old: any) => ({
        ...old,
        dimensions: [...old.dimensions, ...initValidForm.dimensions],
      }));
    }
  };

  const removeItems = (id: any) => () => {
    if (formData?.dimensions?.length > 1) {
      const form = formData.dimensions?.filter(
        (formDataField: any, index: number) => index !== id
      );
      setFormData((old: any) => ({
        ...old,
        dimensions: form,
      }));
    } else {
      setFormData((old: any) => ({
        ...old,
        dimensions: [...initFormData.dimensions],
      }));

      setValidForm((old: any) => ({
        ...old,
        dimensions: [...initValidForm.dimensions],
      }));
    }
  };

  useEffect(() => {
    if (formData?.dimensions?.length > 0 && !isLinearFootageManuallyChanged) {
      const newLinearFootage =
        getRoundUpLinearFootage(calculateLinearFootage(formData.dimensions)) ||
        undefined;

      setFormData((old: any) => ({
        ...old,
        linearFootage: newLinearFootage,
      }));

      if (newLinearFootage) {
        setValidForm((old: any) => ({
          ...old,
          linearFootage: true,
        }));
      }

      if (setFormData) {
        setFormData((old: any) => ({
          ...old,
          linearFootage: newLinearFootage,
        }));
      }
    }
  }, [
    JSON.stringify(
      formData?.dimensions?.map((item: any) => ({
        handlingUnitNo: item.handlingUnitNo,
        width: item.width,
        height: item.height,
        itemLength: item.itemLength,
        isStack: item.isStack,
      }))
    ),
  ]);

  useEffect(() => {
    const totalDimensionsWeight = formData?.dimensions.reduce(
      (sum: any, item: any) => +sum + (+item.totalWeight || 0),
      0
    );

    setTotalDimensionsWeight(totalDimensionsWeight || undefined);

    if (!isTotalWeightManuallyChanged) {
      if (formData?.dimensions?.length > 0) {
        if (totalDimensionsWeight > 0) {
          setFormData((old: any) => ({
            ...old,
            totalWeight: totalDimensionsWeight,
          }));

          setTotalWeightValue(totalDimensionsWeight);

          setValidForm((old: any) => ({
            ...old,
            totalWeight: true,
          }));
        } else if (formData.totalWeight && formData.totalWeight != '') {
          setFormData((old: any) => ({
            ...old,
            totalWeight: undefined,
          }));

          setTotalWeightValue(undefined);
        }
      } else {
        if (formData.totalWeight && formData.totalWeight != '') {
          setFormData((old: any) => ({
            ...old,
            totalWeight: undefined,
          }));

          setTotalWeightValue(undefined);
        }
      }
    }
  }, [
    JSON.stringify(
      formData?.dimensions?.map((item: any) => ({
        weight: item.weight,
        handlingUnitNo: item.handlingUnitNo,
        totalWeight: item.totalWeight,
      }))
    ),
    isTotalWeightManuallyChanged,
  ]);

  const handleToggle = (index: number) => (event: any) => {
    const { name, checked } = event.target;
    setFormData((old: any) => {
      const oldData = [...old.dimensions];
      const commodity = {
        commodityId: oldData[index].commodityId,
        sub: oldData[index].sub,
        description: oldData[index].description,
      };

      if (name === 'isCommodity' && !checked) {
        commodity.commodityId = null;
        commodity.sub = null;
        commodity.description = null;
      }
      oldData[index] = {
        ...oldData[index],
        ...commodity,
        [name]: checked,
      };

      return {
        ...old,
        dimensions: oldData,
      };
    });
  };

  const handleSelectChange = (index: number, name: string) => (event: any) => {
    const value = event?.value;
    setFormData((old: any) => {
      const oldData = [...old.dimensions];

      oldData[index] = {
        ...oldData[index],
        [name]: value,
      };

      if (value) {
        setValidForm((oldValidFormVal: any) => {
          const oldValidData = [...(oldValidFormVal.dimensions ?? [])];

          oldValidData[index] = {
            ...oldValidData[index],
            [name]: {
              ...oldValidData[index][name],
              required: Boolean(oldData[index][name]),
            },
          };

          return {
            ...oldValidFormVal,
            dimensions: oldValidData,
          };
        });
      }

      return {
        ...old,
        dimensions: oldData,
      };
    });
  };

  const handleInputChange = (index: number) => (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;

    if (type === 'number') {
      value = value ? parseFloat(value) : eventType === 'blur' ? 0 : value;
    }

    setFormData((old: any) => {
      const oldData = [...old.dimensions];
      let oldValue = oldData[index]?.[name];

      let totalWeight = oldData[index].totalWeight;
      let weight = oldData[index].weight;
      let lineItemPerUnit = oldData[index].handlingUnitNo;

      if (
        name !== 'description' &&
        (isNaN(value) || value === null || value === undefined)
      ) {
        value = 0;
      }

      if (name === 'weight') {
        if (lineItemPerUnit > 0) {
          totalWeight = value * lineItemPerUnit;
        } else {
          totalWeight = value;
        }
        weight = value;
      } else if (name === 'handlingUnitNo') {
        if (value > 0) {
          if (totalWeight > 0) {
            weight = totalWeight / value;
          }
        } else {
          totalWeight = 0;
          weight = 0;
        }
      } else if (name === 'totalWeight') {
        if (lineItemPerUnit > 0) {
          weight = value / lineItemPerUnit;
        } else {
          weight = value;
        }
        totalWeight = value;
      }

      if (isNaN(totalWeight) || !isFinite(totalWeight)) {
        totalWeight = 0;
      }

      if (isNaN(weight) || !isFinite(weight)) {
        weight = 0;
      }

      oldData[index] = {
        ...oldData[index],
        [name]: value,
        totalWeight,
        weight,
      };

      if (value) {
        setValidForm((oldValidFormVal: any) => {
          const oldValidData = [...(oldValidFormVal?.dimensions ?? [])];

          oldValidData[index] = {
            ...oldValidData[index],
            [name]: {
              ...oldValidData[index][name],
              ...{
                required: Boolean(oldData[index][name]),
                valid: Boolean(oldData[index][name]),
              },
            },
          };

          if (oldData[index].weight && oldData[index].handlingUnitNo) {
            oldValidData[index] = {
              ...oldValidData[index],
              totalWeight: {
                ...oldValidData[index].totalWeight,
                required: true,
                valid: true,
              },
            };
          }

          return {
            ...oldValidFormVal,
            dimensions: oldValidData,
          };
        });
      }

      if (
        (name === 'itemLength' ||
          name === 'height' ||
          name === 'weight' ||
          name === 'totalWeight' ||
          name === 'width') &&
        value !== oldValue &&
        value <= 100000
      ) {
        setIsCalculateFreightClass(true);
      } else {
        setIsCalculateFreightClass(false);
      }

      return {
        ...old,
        dimensions: oldData,
      };
    });
  };

  const checkFreightClass = (index: number) => async (event: any) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;
    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const oldData = [...formData.dimensions];
    let freightClass = oldData[index].freightClass;
    let itemClassForDayross = oldData[index].itemClassForDayross;
    let weight = oldData[index].weight;
    let itemLength = oldData[index].itemLength;
    let width = oldData[index].width;
    let height = oldData[index].height;

    if (weight && itemLength && width && height) {
      const updatedArray = [...isFreightClassLoading];
      updatedArray[index] = { isLoading: true };
      setIsFreightClassLoading(updatedArray);
      console.log('checkFreightClass call');

      setValidForm((oldValidFormVal: any) => {
        const oldValidData = [...(oldValidFormVal?.dimensions ?? [])];
        oldValidData[index] = {
          ...oldValidData[index],
          freightClass: {
            ...oldValidData[index].freightClass,
            required: true,
            valid: true,
          },
        };

        return {
          ...oldValidFormVal,
          dimensions: oldValidData,
        };
      });

      let freightData = await getFreightClass({
        itemLength: itemLength || 0,
        width: width || 0,
        height: height || 0,
        weight: weight || 0,
        lengthUnit: lengthMeasurement,
        weightUnit: weightMeasurement,
      })
        .then((data: any) => data?.data || 0)
        .catch(() => false)
        .finally(() => {
          const updatedArr = [...isFreightClassLoading];
          updatedArr[index] = { isLoading: false };
          setIsFreightClassLoading(updatedArr);
          setIsCalculateFreightClass(false);
        });

      freightClass = freightData?.freightClass;
      itemClassForDayross = freightData?.itemClassForDayross;
    } else {
      freightClass = 0;
    }

    oldData[index] = {
      ...oldData[index],
      [name]: value,
      freightClass,
      itemClassForDayross,
    };
    setFormData((old: any) => ({
      ...old,
      dimensions: oldData,
    }));
  };

  useEffect(() => {
    if (currentItem.data) {
      setIsShowVisualUnit(true);
    }
  }, [currentItem]);

  const handleUnitChange = async () => {
    const newFormData = await Promise.all(
      formData?.dimensions.map(async (form: any, index: number) => {
        const oldData = [...formData.dimensions];
        let freightClass = form.freightClass;
        let itemClassForDayross = form.itemClassForDayross;
        let weight = form.weight;
        let itemLength = form.itemLength;
        let width = form.width;
        let height = form.height;

        if (weight && itemLength && width && height) {
          const updatedArray = [...isFreightClassLoading];
          updatedArray[index] = { isLoading: true };
          setIsFreightClassLoading(updatedArray);
          console.log('handleUnitChange');
          let freightData = await getFreightClass({
            itemLength: itemLength || 0,
            width: width || 0,
            height: height || 0,
            weight: weight || 0,
            lengthUnit: lengthMeasurement,
            weightUnit: weightMeasurement,
          })
            .then((data: any) => data?.data || 0)
            .catch(() => false)
            .finally(() => {
              const updatedArr = [...isFreightClassLoading];
              updatedArr[index] = { isLoading: false };
              setIsFreightClassLoading(updatedArr);
            });
          freightClass = freightData?.freightClass;
          itemClassForDayross = freightData?.itemClassForDayross;
        } else {
          freightClass = 0;
        }

        oldData[index] = {
          ...oldData[index],
          freightClass,
          itemClassForDayross,
        };

        return oldData[index];
      })
    );

    setFormData((old: any) => ({
      ...old,
      dimensions: newFormData,
    }));
  };

  useEffect(() => {
    if (prevLengthMeasurement !== lengthMeasurement) handleUnitChange();
    setPrevLengthMeasurement(lengthMeasurement);
  }, [lengthMeasurement]);

  useEffect(() => {
    let valid = isFreightClassLoading.some((item) => item?.isLoading);
    console.log('valid', valid);
    setIsValid(valid);
  }, [isFreightClassLoading]);

  return (
    <>
      <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5 w-full text-nowrap">
        {formData.dimensions?.map((form: any, index: number) => (
          <>
            <div
              key={index}
              className="flex flex-wrap sm:-m-1.5 [&>div]:sm:p-1.5 !mb-5 last:mb-0 text-nowrap"
            >
              <SelectBox
                parentClassName="w-[145px] min-w-min"
                label="Handling unit"
                labelClassName="form_label mb-1.5 block"
                isDisabled={isValid}
                isSearchable
                name="handlingUnit"
                id="handlingUnit"
                className="form_control"
                classComp={`mt-0 ${
                  !validForm?.dimensions?.[index]?.handlingUnit.required ||
                  !validForm?.dimensions?.[index]?.handlingUnit.valid
                    ? ''
                    : ''
                }`}
                options={handlingUnitOptions}
                onChangeFunc={handleSelectChange(index, 'handlingUnit')}
                value={handlingUnitOptions.find(
                  (handlingUnitOption: any) =>
                    handlingUnitOption.value === parseInt(form.handlingUnit) ||
                    ''
                )}
                isLoading={isDimensionLoading}
                errorText=""
              />

              <InputText
                label="№ of HU"
                labelClassName="block mb-1.5"
                required
                parentClassName="w-[9.5%] min-w-min"
                inputType="number"
                inputName="handlingUnitNo"
                min="0"
                className={`${
                  !validForm?.dimensions?.[index]?.handlingUnitNo.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.handlingUnitNo.valid &&
                      'border border-red-500 border-solid'
                }`}
                placeholder="0"
                shouldFormatNumber={true}
                disabled={isValid}
                value={form.handlingUnitNo || ''}
                onChangeFunc={handleInputChange(index)}
                onBlur={handleInputChange(index)}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-min"
                label={`Length (${getLabelByValue(
                  lengthMeasurement
                )?.toLowerCase()})`}
                labelClassName="block mb-1.5"
                required
                inputType="number"
                disabled={isValid}
                inputName="itemLength"
                min="0"
                className={`${
                  !validForm?.dimensions?.[index]?.itemLength.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.itemLength.valid &&
                      'border border-red-500 border-solid'
                }`}
                placeholder="0"
                shouldFormatNumber={true}
                inputValueUnitType={getLabelByValue(
                  lengthMeasurement
                )?.toLowerCase()}
                value={form.itemLength || ''}
                onBlur={(e: any) => {
                  if (isCalculateFreightClass) checkFreightClass(index)(e);
                }}
                onChangeFunc={handleInputChange(index)}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-min !mb-0"
                label={`Width (${getLabelByValue(
                  lengthMeasurement
                )?.toLowerCase()})`}
                labelClassName="block mb-1.5"
                required
                disabled={isValid}
                inputType="number"
                inputName="width"
                min="0"
                className={`${
                  !validForm?.dimensions?.[index]?.width.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.width.valid &&
                      'border border-red-500 border-solid'
                }`}
                placeholder="0"
                shouldFormatNumber={true}
                inputValueUnitType={getLabelByValue(
                  lengthMeasurement
                )?.toLowerCase()}
                value={form.width || ''}
                errorText={
                  !validForm?.dimensions?.[index]?.width.valid
                    ? `Max width: ${validateMeasurement.width}`
                    : null
                }
                onChangeFunc={handleInputChange(index)}
                onBlur={(e: any) => {
                  if (isCalculateFreightClass) checkFreightClass(index)(e);
                }}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-min !mb-0"
                label={`Height (${getLabelByValue(
                  lengthMeasurement
                )?.toLowerCase()})`}
                labelClassName="block mb-1.5"
                required
                disabled={isValid}
                inputType="number"
                inputName="height"
                min="0"
                className={`${
                  !validForm?.dimensions?.[index]?.height.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.height.valid &&
                      'border border-red-500 border-solid'
                }`}
                placeholder="0"
                shouldFormatNumber={true}
                inputValueUnitType={getLabelByValue(
                  lengthMeasurement
                )?.toLowerCase()}
                value={form.height || ''}
                errorText={
                  !validForm?.dimensions?.[index]?.height.valid
                    ? `Max height: ${validateMeasurement.height}`
                    : null
                }
                onChangeFunc={handleInputChange(index)}
                onBlur={(e: any) => {
                  if (isCalculateFreightClass) checkFreightClass(index)(e);
                }}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-[120px]"
                label={`Unit Weight (${getLabelByValue(
                  weightMeasurement
                )?.toLowerCase()})`}
                labelClassName="block mb-1.5"
                required
                disabled={isValid}
                inputType="number"
                inputName="weight"
                min="0"
                className={`${
                  !validForm?.dimensions?.[index]?.weight.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.weight.valid &&
                      'border border-red-500 border-solid'
                }`}
                placeholder="0"
                value={form.weight || ''}
                shouldFormatNumber={true}
                inputValueUnitType={getLabelByValue(
                  weightMeasurement
                )?.toLowerCase()}
                // errorText={!validForm?.dimensions?.[index]?.weight.required ? 'Item weight is required' : null}
                onChangeFunc={handleInputChange(index)}
                onBlur={(e: any) => {
                  if (isCalculateFreightClass) checkFreightClass(index)(e);
                }}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-[115px]"
                label={`Total weight (${getLabelByValue(
                  weightMeasurement
                )?.toLowerCase()})`}
                labelClassName="block mb-1.5"
                inputName="totalWeight"
                placeholder="0"
                disabled={isValid}
                value={form?.totalWeight || ''}
                className={`${
                  !validForm?.dimensions?.[index]?.totalWeight.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.totalWeight.valid &&
                      'border border-red-500 border-solid'
                }`}
                onChangeFunc={handleInputChange(index)}
                onBlur={(e: any) => {
                  if (isCalculateFreightClass) checkFreightClass(index)(e);
                }}
                inputType="number"
                shouldFormatNumber={true}
                inputValueUnitType={getLabelByValue(
                  weightMeasurement
                )?.toLowerCase()}
                errorText={
                  !validForm?.dimensions?.[index]?.totalWeight.valid
                    ? `Max allow weight: ${formatNumberWithCommas(
                        validateMeasurement.maxTotalWeight
                      )}`
                    : ''
                }
                required={true}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-min"
                label={
                  <div className="flex">
                    <TooltipCmp message={'Freight Class'}>
                      <span className="cursor-pointer">FC</span>
                      <span className="text-red-600 ms-[2px] leading-4 text-xs">
                        *
                      </span>
                    </TooltipCmp>
                    {isFreightClassLoading[index]?.isLoading && (
                      <p
                        className={`loading loading-spinner text-gray-600 h-4 w-4 ml-2 }`}
                      ></p>
                    )}
                  </div>
                }
                labelClassName="block mb-1.5"
                inputName="freightClass"
                inputType="number"
                min={0}
                placeholder="0"
                value={form.freightClass || ''}
                onChangeFunc={handleInputChange(index)}
                className={`${
                  !validForm?.dimensions?.[index]?.freightClass.required
                    ? 'border border-red-500 border-solid'
                    : !validForm?.dimensions?.[index]?.freightClass.valid &&
                      'border border-red-500 border-solid'
                }`}
                shouldFormatNumber={true}
              />

              <InputText
                parentClassName="w-[9.5%] min-w-min flex-1"
                label="Description"
                labelClassName="block mb-1.5"
                inputName="description"
                inputType="text"
                placeholder="Enter description"
                value={form.description || ''}
                onChangeFunc={handleInputChange(index)}
                onBlur={handleInputChange(index)}
                shouldFormatNumber={false}
              />

              <div className="flex w-[122px] !p-0">
                <div className="sm:p-1.5 w-[46px]">
                  <TooltipCmp message={'Do Not Stack'}>
                    <label className="form_label block mb-1.5 cursor-pointer">
                      D.N.S
                    </label>
                  </TooltipCmp>
                  <Toggle
                    isChecked={form.isStack}
                    onChange={handleToggle(index)}
                    labelClassName="lg:h-[36px] h-[34px] flex items-center"
                    name="isStack"
                  />
                </div>
                <div className="m-1.5 lg:h-9 h-[34px] flex items-center mt-[26px]">
                  <TooltipCmp message="Visualize Unit">
                    <div className="delete cursor-pointer p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary">
                      <Eye
                        className="w-4 h-4"
                        onClick={() => {
                          setCurrentItem({ ...{ index: index, data: form } });
                        }}
                      />
                    </div>
                  </TooltipCmp>
                </div>
                <div className="m-1.5 lg:h-9 h-[34px] flex items-center mt-[26px]">
                  <div
                    className={`delete p-[5px] bg-primary100 rounded-[4px] flex justify-center items-center text-primary cursor-pointer`}
                  >
                    <Trash01 className="w-4 h-4" onClick={removeItems(index)} />
                  </div>
                </div>
              </div>
            </div>
            {Boolean(form.isCommodity) && (
              <div className="flex gap-4">
                <div className="w-1/3 relative">
                  <div
                    className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                  ${
                    !validForm?.dimensions?.[index]?.commodityId.required &&
                    'border border-red-500 border-solid'
                  }`}
                  >
                    <label className="text-[12px] block">NMFC Class *</label>
                    <input
                      readOnly
                      type="text"
                      name="commodityId"
                      placeholder="Select"
                      value={form.commodityName || ''}
                      className="w-full text-sm focus:ring-0 focus:shadow-none text-black"
                    />
                  </div>
                </div>
                <div className="w-1/3 relative">
                  <div
                    className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                                    ${
                                      !validForm?.dimensions?.[index]?.sub
                                        .required &&
                                      'border border-red-500 border-solid'
                                    }`}
                  >
                    <label className="text-[12px] block">Sub *</label>
                    <input
                      type="text"
                      name="sub"
                      placeholder="Enter here"
                      value={form.sub || ''}
                      onChange={handleInputChange(index)}
                      className="w-full text-sm focus:ring-0 focus:shadow-none text-black"
                    />
                  </div>
                </div>
                <div className="w-1/3 relative">
                  <div
                    className={`bg-white py-2 pl-5 px-3 leading-none border rounded-full border-borderDark
                                    ${
                                      !validForm?.dimensions?.[index]
                                        ?.description.required &&
                                      'border border-red-500 border-solid'
                                    }`}
                  >
                    <label className="text-[12px] block">Description</label>
                    <input
                      type="text"
                      name="description"
                      placeholder="Enter here"
                      value={form.description || ''}
                      onChange={handleInputChange(index)}
                      className="w-full text-sm focus:ring-0 focus:shadow-none text-black"
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
        <div className="flex gap-x-4">
          <ButtonCmp
            type="submit"
            className="btn-link-custom"
            onClick={duplicateItems}
            icon={<Copy04 className="w-4 h-4" />}
          >
            Duplicate
          </ButtonCmp>
          <ButtonCmp
            type="submit"
            className="btn-link-custom"
            onClick={addItems}
            icon={<Plus className="w-4 h-4" />}
          >
            Add Items
          </ButtonCmp>
        </div>
      </div>
      {isShowVisualUnit && currentItem && (
        <VisualizeUnit
          setDimensionsFormData={setFormData}
          currentItem={currentItem}
          unitArr={handlingUnitOptions}
          handleClose={() => {
            setIsShowVisualUnit(false);
            setCurrentItem({ index: null, data: null });
          }}
          weightMeasurement={weightMeasurement}
          lengthMeasurement={lengthMeasurement}
          isFormDataHaveKey={true}
        />
      )}
    </>
  );
};

export default Items;
