import {
  Edit01,
  CurrencyDollar,
  Share06,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ActionTooltip from 'src/components/ActionTooltip';
import BadgeCmp from 'src/components/BadgeCmp';
import TimerBadge from 'src/components/TimerBadge';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  getBadgeLabelByQuoteStatus,
  getBadgeTypeByQuoteStatus,
  STATUS,
} from 'src/constants/common';
import { DirectCarrierRatesContext } from 'src/context/DirectCarrierRatesContext';
import { getQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  getFormattedDate,
  getShortName,
  onError,
  useRolePermission,
  formattedPickupDateValue,
  GetAppearance,
} from 'src/utils/CommonFunctions';

import inboundEmoji from '../../../assets/img/inbound-emoji.webp';

import ShareQuoteModal from './ShareQuoteModal';

const QuotingDashboardRow = ({
  filter,
  setAction,
  setSelectedQuote,
  onRowClick,
  data,
  setIsShowQuoteDetailModal,
}: any) => {
  const { hasRoleV2 } = useRolePermission();
  let appearance = GetAppearance();
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { isCarrierRateFetched } = useContext(DirectCarrierRatesContext);
  const [isLoadPostRunning, setIsLoadPostRunning] = useState<boolean>(
    data.isLoadPostRunning
  );
  const [reloadLoadPostRunning, setReloadLoadPostRunning] =
    useState<boolean>(false);
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );
  const [loadPosts, setLoadPosts] = useState(data.loadPosts);
  const [showLoader, setShowLoader] = useState(false);
  const [isShowShareQuoteModal, setIsShowShareQuoteModal] = useState(false);

  useEffect(() => {
    if (data.isLoadPostRunning) {
      const updateLoadPostsStatus = () => {
        const currentTime = moment.utc();
        const updatedLoadPosts = loadPosts.map((post: any) => {
          const updatedAtUTC = moment.utc(post.updatedAt);
          const acceptInboundCallDuration = post.acceptInboundCallDuration || 0;
          const expiryTime = updatedAtUTC
            .clone()
            .add(acceptInboundCallDuration, 'minutes');
          const duration = moment.duration(expiryTime.diff(currentTime));
          const durationMilliseconds: number = duration.asMilliseconds();

          return {
            ...post,
            isRunning: durationMilliseconds > 0,
          };
        });

        setLoadPosts(updatedLoadPosts);
        const isPostRunning = updatedLoadPosts.some(
          (post: any) => post.isRunning
        );
        setIsLoadPostRunning(isPostRunning);
      };

      updateLoadPostsStatus();

      const interval = setInterval(updateLoadPostsStatus, 30000);

      return () => clearInterval(interval);
    }
  }, [data.isLoadPostRunning, data.loadPosts, reloadLoadPostRunning]);

  const setLeadRate = (response: any) => {
    const index = loadPosts.findIndex(
      (posting: any) => posting.id == response.loadLinkPostingDetail.id
    );

    loadPosts[index] = {
      acceptInboundCallDuration:
        response.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.acceptInboundCallDuration,
      enableInboundTruckFinding:
        response.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.enableInboundTruckFinding,
      enableTruckFinding:
        response.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]
          ?.enableTruckFinding,
      id: response.loadLinkPostingDetail.id,
      inprogressInboundCalls: response.inprogressInboundCalls,
      processedCalls: response.processedInboundCalls,
      rateCollected: response.rateCollected,
      refNumber: response.loadLinkPostingDetail.refNumber,
      updatedAt:
        response.loadLinkPostingDetail?.loadLinkTruckFindingAis?.[0]?.updatedAt,
    };

    setLoadPosts([...loadPosts]);
    setReloadLoadPostRunning(!reloadLoadPostRunning);
  };

  const editQuote = (quote: any) => {
    setShowLoader(true);
    getQuote(quote.id).then((response: any) => {
      if (response && response.data) {
        setSelectedQuote(response.data);
        setShowLoader(false);
        setIsShowQuoteDetailModal(true);
      }
    });
  };

  useEffect(() => {
    console.log('QuotingDashboardRow isSocketConnected', isSocketConnected);
    console.log('QuotingDashboardRow socketIO', socketIO);

    if (isSocketConnected && !!socketIO?.on) {
      loadPosts.forEach((post: any) => {
        console.log('🚀 ~ socketIO on loadLinkPosts.forEach ~ post:', post);
        socketIO.on(`lead_rate_${post.id}`, (response: any) => {
          setLeadRate(response);
        });
      });

      return () => {
        if (socketIO?.on) {
          loadPosts.forEach((post: any) => {
            console.log('🚀 ~ socketIO on loadLinkPosts.forEach ~ post:', post);
            socketIO?.off(`lead_rate_${post.id}`);
          });
        }
      };
    }
  }, [isSocketConnected, loadPosts]);

  return (
    <>
      <td
        className="px-5 py-4 min-w-[13rem] w-[13rem] max-w-[13rem]"
        onClick={onRowClick(data)}
      >
        <div className="flex items-center">
          {data?.urgency === 'live' ? (
            <TooltipCmp message={data?.urgency} parentClassName="capitalize">
              <p className="w-3 h-3 bg-successSecondary rounded-full border-[3px] border-utilitySuccess100"></p>
            </TooltipCmp>
          ) : (
            <TooltipCmp message="QFR" parentClassName="uppercase">
              <p className="w-3 h-3 bg-primary rounded-full border-[3px] border-primary100"></p>
            </TooltipCmp>
          )}

          <div className="table-profile-wrap notification-mark-wrap ml-3">
            {data.image ? (
              <>
                <img
                  className="table-profile-icn-circle-xl"
                  src={data.imageUrl + data.image}
                  alt={data.firstName}
                  title={data.firstName}
                  onError={onError}
                />
              </>
            ) : (
              <div className="table-profile-user-circle-xl">
                {getShortName(`${data.firstName} ${data.lastName}`)}
              </div>
            )}
            {/* HELP FLOW NOTIFICATION DESIGN COMMENTED BELOW */}
            <span className={`notification-dot hidden`}></span>
          </div>
          <div className="ml-3 text-xs font-medium truncate">
            <div
              data-test-id="wal-QuotingDashboard-fullName"
              className="text-grayLight900 font-medium leading-tight truncate"
            >{`${data.fullName}`}</div>
            <div
              data-test-id="wal-QuotingDashboard-code"
              className="text-grayLight600 font-normal leading-tight truncate"
            >
              #{data.code}
            </div>
          </div>
        </div>
      </td>
      <td
        className="px-5 py-4 min-w-[130px] max-w-[130px] w-[130px]"
        onClick={onRowClick(data)}
      >
        <BadgeCmp style="modern" type={getBadgeTypeByQuoteStatus(data.status)}>
          {getBadgeLabelByQuoteStatus(data.status)}
        </BadgeCmp>
      </td>
      <td
        className="px-5 py-4 min-w-[130px] max-w-[130px] w-[130px]"
        onClick={onRowClick(data)}
      >
        {data.classification === 'ltl' && (
          <BadgeCmp style="modern" type="success">
            LTL
          </BadgeCmp>
        )}
        {data.classification === 'ftl' && (
          <BadgeCmp style="modern" type="lightblue">
            FTL
          </BadgeCmp>
        )}
        {data.classification === 'volume_ltl' && (
          <BadgeCmp style="modern" type="pink">
            Volume LTL
          </BadgeCmp>
        )}
      </td>
      <td
        data-test-id="wal-QuotingDashboard-customerName"
        className="px-5 py-4 max-w-40 min-w-40 w-40"
        onClick={onRowClick(data)}
      >
        <TooltipCmp
          message={data.customerName.length > 24 ? data.customerName : null}
          parentClassName=""
        >
          <p className="truncate">{data.customerName}</p>
        </TooltipCmp>
      </td>
      <td
        className="px-5 py-4 xxl:max-w-48 xxl:w-48 xxl:min-w-48 w-40 max-w-40 min-w-40 "
        onClick={onRowClick(data)}
      >
        <TooltipCmp
          message={data.shipperFullAddress}
          type="dark"
          parentClassName=""
        >
          <p className="flex">
            <span className="text-gray500 font-normal">From:&nbsp;</span>
            <span className="font-medium truncate max-w-[150px]">
              {data.shipperFullAddress}
            </span>
          </p>
        </TooltipCmp>

        <TooltipCmp
          message={data.consigneeFullAddress}
          type="dark"
          parentClassName="max-w-90"
        >
          <p className="flex">
            <span className="text-gray500 font-normal">To:&nbsp;</span>
            <span className="font-medium truncate max-w-[150px]">
              {data.consigneeFullAddress}
            </span>
          </p>
        </TooltipCmp>
      </td>
      <td
        data-test-id="wal-QuotingDashboard-createdAt"
        className="px-5 py-4 min-w-44 w-44 max-w-44"
        onClick={onRowClick(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.createdAt
              ? getDateWithSuffixFormat(
                  getFormattedDate(
                    data.createdAt,
                    `MMM Do , hh:mm A`,
                    true,
                    true
                  )
                )
              : '-',
          }}
        />
      </td>

      <td
        data-test-id="wal-QuotingDashboard-createdAt"
        className="px-3 py-4 min-w-[165px] w-[165px] max-w-[165px]"
        onClick={onRowClick(data)}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: data.pickupDate
              ? getDateWithSuffixFormat(
                  formattedPickupDateValue(
                    moment
                      .utc(data.pickupDate)
                      .tz(appearance?.timezone ?? browserTimezone)
                      .format('YYYY-MM-DD HH:mm:ss'),
                    false
                  )
                )
              : '-',
          }}
        />
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalUnits"
        className="pl-3 pr-1 py-4 min-w-28 w-28 max-w-28"
        onClick={onRowClick(data)}
      >
        {data?.classification === 'ltl' && data.totalUnits > 0 ? (
          <span className="">
            {data.totalUnits}{' '}
            {data.handlingUnitTypes?.length
              ? `${data.handlingUnitTypes[0]}${data.totalUnits > 1 ? 's' : ''}`
              : data.totalUnits > 1
              ? 'Units'
              : 'Unit'}
          </span>
        ) : (
          <span className="block_ellipsis ">
            {data.primaryEquipmentTypeFullName}
          </span>
        )}
      </td>
      <td
        data-test-id="wal-QuotingDashboard-totalWeight"
        className="px-3 py-4 min-w-28 w-28 max-w-28"
        onClick={onRowClick(data)}
      >
        {data.totalWeight && (
          <span className="">{`${data.totalWeight}  ${
            data.weightMeasurement
              ? data.weightMeasurement?.toLowerCase()
              : 'lbs'
          }`}</span>
        )}
      </td>

      <td
        className={`px-5 py-4 min-w-28 w-28 max-w-28`}
        onClick={onRowClick(data)}
      >
        <TimerBadge badgeDate={data.createdAt} />
      </td>

      {hasRoleV2('admin') && (
        <td className="px-5 py-4 pl-0 w-[64px] max-w-[64px] min-w-[64px]">
          <div className="flex items-center justify-end gap-1.5">
            {isCarrierRateFetched && isCarrierRateFetched[data.id] && (
              <TooltipCmp message="Fetching Direct Carrier Rates" type="dark">
                <span
                  className={`loading loading-spinner lg:w-4 lg:h-4 h-3 w-3 text-xs pr-2`}
                ></span>
              </TooltipCmp>
            )}

            {isLoadPostRunning && (
              <>
                <ActionTooltip
                  isOpenChildTopPosOnTop={true}
                  openOnHover={true}
                  tooltipPosition="right"
                  type="dark"
                  message={
                    <>
                      <div className="p-1.5 space-y-3 min-w-[320px] text-left">
                        <ul className="rounded-lg bg-white bg-opacity-10 p-2.5 space-y-4 overflow-auto max-h-[232px] custom-scrollbar-v2">
                          {loadPosts?.map(
                            (post: any, index: number) =>
                              post.isRunning && (
                                <li key={index} className="space-y-0.5">
                                  <h6 className="text-utilityGray900 text-sm font-medium underline">
                                    REF: #{post.refNumber}
                                  </h6>
                                  <div className="flex flex-wrap gap-1.5">
                                    <p className="first:pl-0 first:border-l-0 pl-1.5 border-l border-utilityGray600 text-utilityGray600 my-0.5 leading-[1.5] text-[10px] font-normal">
                                      {post.rateCollected}&nbsp;Rate
                                      {post.rateCollected == 1 ? '' : 's'}
                                    </p>
                                    <p className="first:pl-0 first:border-l-0 pl-1.5 border-l border-utilityGray600 text-utilityGray600 my-0.5 leading-[1.5] text-[10px] font-normal">
                                      {post.inprogressInboundCalls}&nbsp;In
                                      Progress
                                    </p>
                                    <p className="first:pl-0 first:border-l-0 pl-1.5 border-l border-utilityGray600 text-utilityGray600 my-0.5 leading-[1.5] text-[10px] font-normal">
                                      {post.processedCalls}&nbsp;Processed
                                    </p>
                                  </div>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </>
                  }
                >
                  <div className="w-6 h-6 rounded-full bg-success200 flex-none cursor-pointer mr-1">
                    <img
                      src={inboundEmoji}
                      className={`w-full h-full p-1 object-contain`}
                    />
                  </div>
                </ActionTooltip>
              </>
            )}

            {(filter === STATUS.OPENED || filter === STATUS.ACTIVE) && (
              <div className="justify-end items-center gap-2 flex">
                <TooltipCmp message="Add Quote" type="dark">
                  <CurrencyDollar
                    className="mt-0.5 w-4 h-4 relative cursor-pointer"
                    onClick={() => {
                      setAction((old: any) => ({
                        ...old,
                        mode: 'update_quick_quote',
                        data: data,
                      }));
                    }}
                  />
                </TooltipCmp>
              </div>
            )}

            <div className="justify-end items-center gap-2 flex ">
              <TooltipCmp message="Edit" type="dark">
                {!showLoader ? (
                  <Edit01
                    className="mt-1 w-4 h-4 relative cursor-pointer"
                    onClick={() => editQuote(data)}
                  />
                ) : (
                  <span
                    className={`loading loading-spinner lg:w-4 lg:h-4 h-3 w-3 text-xs pr-2`}
                  ></span>
                )}
              </TooltipCmp>
            </div>
            {/* HELP FLOW SHARE ICON DESIGN COMMENTED BELOW */}
            <>
              <TooltipCmp message="Share Quote" type="dark">
                <Share06
                  className="w-4 h-4 cursor-pointer hidden"
                  onClick={() => setIsShowShareQuoteModal(true)}
                />
              </TooltipCmp>
            </>
          </div>
        </td>
      )}
      {isShowShareQuoteModal && (
        <ShareQuoteModal handleClose={() => setIsShowShareQuoteModal(false)} />
      )}
    </>
  );
};

export default QuotingDashboardRow;
