import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const listIntegrationType = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.TYPE, {
    params,
  });

  return response;
};

export const listIntegration = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.LIST, {
    params,
  });

  return response;
};

export const getMapService = async (params: any = {}) => {
  const response = await axiosInterceptor.get(API.INTEGRATIONS.MAP_SERVICE, {
    params,
  });

  return response;
};

export const setConfiguration = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.INTEGRATIONS.SET_CONFIGURATIONS}/${id}`,
    data
  );

  return response;
};

export const enableIntegration = async (id: number, data: any) => {
  const response = await axiosInterceptor.patch(
    `${API.INTEGRATIONS.ENABLE_INTEGRATION}/${id}`,
    data
  );

  return response;
};

export const GetOutlookConnectedAccountList = async (params: any = {}) => {
  const response = await axiosInterceptor.get(
    API.INTEGRATIONS.OUTLOOK_CONNECTED_ACCOUNTS,
    {
      params,
    }
  );

  return response;
};

export const SetConnectIntegration = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.INTEGRATIONS.CONNECT_OUTLOOK,
    data
  );

  return response;
};

export const SetDisconnectIntegration = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.INTEGRATIONS.DISCONNECT_OUTLOOK,
    data
  );

  return response;
};

export const SetMarkOutlookAsPrimary = async (data: any) => {
  const response = await axiosInterceptor.post(
    API.INTEGRATIONS.MARK_AS_PRIMARY,
    data
  );

  return response;
};
