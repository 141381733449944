import React from 'react';

const winLossRowCmpLoader = () => (
  <>
    <td className="px-5 py-4 w-[50%] min-w-[50%] max-w-[50%]">
      <span className="custom-loading">$20000</span>
    </td>
    <td className="px-5 py-4 w-[50%] min-w-[50%] max-w-[50%]">
      <span className="custom-loading">200</span>
    </td>
  </>
);

export default winLossRowCmpLoader;
