import { FilePlus03 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import Radio from 'src/components/Radio/Radio';

interface IProps {
  handleClose: any;
  selectedDisplayOptions: any;
  setSelectedDisplayOptions: any;
  selectedLanguage: any;
  handleLanguageSelect: any;
  handleGeneratePdf: any;
  isPdfGenerating: any;
  setIsQuotePdfAttached: any;
  isQuotePdfAttached: any;
}

const AttachQuotePDFModal = ({
  handleClose,
  selectedDisplayOptions,
  setSelectedDisplayOptions,
  selectedLanguage,
  handleLanguageSelect,
  handleGeneratePdf,
  isPdfGenerating,
  setIsQuotePdfAttached,
  isQuotePdfAttached,
}: IProps) => (
  <CommonModal
    title={'Attach Quote PDF'}
    titleDesc={'Preview and attach the quote PDF to proceed.'}
    handleClose={() => handleClose(false)}
    headerIcon={<FilePlus03 />}
    size={'max-w-[580px]'}
    isOverflow={true}
    modalClassName=""
    secondaryBtnText="Cancel"
    secondaryBtnOnClick={handleClose}
    primaryBtnText="Next"
    primaryBtnOnClick={() => handleGeneratePdf()}
    primaryBtnLoading={isPdfGenerating}
  >
    <div className="w-full p-5 space-y-5">
      <div className={`flex gap-x-8 pt-1`}>
        <div className="space-y-1 max-w-[250px] pr-7">
          <h6 className="text-grayLight900 text-sm font-semibold">
            Attach Quote PDF
          </h6>
          <p className="text-gray500 text-xs font-normal leading-[1.5]">
            Would you like to attach the quote PDF for quick reference?
          </p>
        </div>

        <div className="space-y-4">
          <Radio
            id="yes"
            value="yes"
            onChangeFunc={() => {
              setIsQuotePdfAttached(true);
            }}
            checked={isQuotePdfAttached}
            labelName="Yes"
            labelHtmlFor="yes"
            radioLabelClassName="normal-case"
          />
          <Radio
            id="no"
            value="no"
            onChangeFunc={() => {
              setIsQuotePdfAttached(false);
            }}
            checked={!isQuotePdfAttached}
            labelName="No"
            labelHtmlFor="no"
            radioLabelClassName="normal-case"
          />
        </div>
      </div>

      <>
        <div className={`border-t border-utilityGray200 flex gap-x-8 pt-5 `}>
          <div className="space-y-1 max-w-[250px] pr-7">
            <h6 className="text-grayLight900 text-sm font-semibold">
              Language
            </h6>
            <p className="text-gray500 text-xs font-normal leading-[1.5]">
              Choose the preferred language for your Quote PDF.
            </p>
          </div>

          <div
            className={`space-y-4 ${
              !isQuotePdfAttached ? 'opacity-50 pointer-events-none' : ''
            }`}
          >
            <Radio
              id="english"
              value="english"
              onChangeFunc={() => handleLanguageSelect('english')}
              checked={selectedLanguage === 'english'}
              labelName="English"
              labelHtmlFor="english"
              radioLabelClassName="normal-case"
            />
            <Radio
              id="french"
              value="french"
              onChangeFunc={() => handleLanguageSelect('french')}
              checked={selectedLanguage === 'french'}
              labelName="French"
              labelHtmlFor="french"
              radioLabelClassName="normal-case"
            />
          </div>
        </div>

        <div className={`border-t border-utilityGray200 flex gap-x-8 pt-5 `}>
          <div className="space-y-1 max-w-[250px] pr-7">
            <h6 className="text-grayLight900 text-sm font-semibold">
              Display Settings
            </h6>
            <p className="text-gray500 text-xs font-normal leading-[1.5]">
              Select the details you want to include in the Quote PDF.
            </p>
          </div>

          <div
            className={`space-y-4 ${
              !isQuotePdfAttached ? 'opacity-50 pointer-events-none' : ''
            }`}
          >
            <div className="flex gap-x-2.5 items-center cursor-pointer">
              <CheckBox
                checked={selectedDisplayOptions.displayServiceType}
                label="Display Service Type"
                labelClassName="!text-textSecondary"
                id="serviceType"
                labelHtmlFor="serviceType"
                classes="mt-px"
                onChangeFunc={() =>
                  setSelectedDisplayOptions((prevState: any) => ({
                    ...prevState,
                    displayServiceType: !prevState?.displayServiceType,
                  }))
                }
              ></CheckBox>
            </div>
            <div className="flex gap-x-2.5 items-center cursor-pointer">
              <CheckBox
                checked={selectedDisplayOptions.displayTransitTimes}
                label="Display Transit Times"
                labelClassName="!text-textSecondary"
                id="transitTimes"
                labelHtmlFor="transitTimes"
                classes="mt-px"
                onChangeFunc={() =>
                  setSelectedDisplayOptions((prevState: any) => ({
                    ...prevState,
                    displayTransitTimes: !prevState?.displayTransitTimes,
                  }))
                }
              ></CheckBox>
            </div>
            <div className="flex gap-x-2.5 items-center cursor-pointer">
              <CheckBox
                checked={selectedDisplayOptions.displayCarrierNames}
                label="Display Carrier Names"
                labelClassName="!text-textSecondary"
                id="carrierNames"
                labelHtmlFor="carrierNames"
                classes="mt-px"
                onChangeFunc={() =>
                  setSelectedDisplayOptions((prevState: any) => ({
                    ...prevState,
                    displayCarrierNames: !prevState?.displayCarrierNames,
                  }))
                }
              ></CheckBox>
            </div>
          </div>
        </div>
      </>
    </div>
  </CommonModal>
);

export default AttachQuotePDFModal;
