import _, { debounce } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CustomPagination from 'src/components/CustomPagination';
import DateRangePicker from 'src/components/DateRangePicker';
import NotFoundUI from 'src/components/NotFoundUI';
import PageSectionLayout from 'src/components/PageSectionLayout';
import Header from 'src/components/PageSectionLayout/Header/Header';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { TIME_FILTER, USER_ROLE } from 'src/constants/common';
import { BasicContext } from 'src/context/BasicContext';
import useCountUp from 'src/hooks/useCountUp';
import {
  getAnalytics,
  getCustomerSplit,
  getTeamPerformance,
  getTotalQuoteChartData,
} from 'src/services/QuoteService';
import { getTeamList } from 'src/services/SalesDashboard';
import {
  convertSecondsToTime,
  getDateRange,
  getFormattedNumber,
  getSelectBoxOptions,
  useRolePermission,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../assets/img/Avatar7.png';

import CustomerSplit from './CustomerSplitTable';
import CustomerSplitAsideTable from './CustomerSplitTable/CustomerSplitAsideTable';
import KpiCard from './KpiCard';
// import QuotingSplitsLoadingRow from './QuotingSplitsLoadingRow';
// import QuotingSplitsRow from './QuotingSplitsRow';
import QuotingAnalyticsChartModal from './QuotingAnalyticsChartModal';
import AnalyticsTeamPerformanceTable from './TeamPerformanceTable';
import BarChart from './TotalQuotesGraph/BarChart';

// const recordsPerPageArray = [
//   { label: '25', value: 25 },
//   { label: '50', value: 50 },
//   { label: '100', value: 100 },
// ];

interface IAnalytics {
  totalHits: number;
  totalClassificationAccuracy: number;
  totalOrders: number;
  totalQuotes: number;
  totalRequests: number;
  totalWonLoss: number;
  totalHitsGrowth: number;
  totalQuotesGrowth: number;
  totalClassificationAccuracyGrowth: number;
  totalOrdersGrowth: number;
  totalWonLossGrowth: number;
  totalRequestsGrowth: number;
  avgTimeToClassify: number;
  avgTimeToQuote: number;
  avgTimeToClassifyGrowth: number;
  avgTimeToQuoteGrowth: number;
  quotingPercentage: number;
  quotingPercentageGrowth: number;
  graph: {
    labels: string[];
    value: {
      hit: number[];
      requests: number[];
      orders: number[];
      quotes: number[];
    };
  };
  requestQuotePieChartData: {
    labels: string[];
    values: number[];
    totalValue: number;
  };
  totalQuotePieChartData: {
    labels: string[];
    values: number[];
    totalValue: number;
  };
}

const initParams = {
  search: '',
  sortType: 'desc',
  sortField: 'createdAt',
  page: 1,
  limit: 25,
  type: 'common',
  provider: '',
  member: 'all',
  team: 'all',
};

const QuotingAnalytics = () => {
  const { hasRoleV2 } = useRolePermission();
  const { appearance, allTimeStartDateQuote } = useContext(BasicContext);
  const [teamOptions, setTeamOptions] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>('all');
  const [selectedTeam, setSelectedTeam] = useState<string | null>('all');
  const [activeSplit, setActiveSplit] = useState('');
  const [search, setSearch] = useState('');
  const [quotesData, setQuotesData] = useState<any[]>([]);
  const [customerSplitData, setCustomerSplitData] = useState<any[]>([]);
  const [totals, setTotals] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isCustomerSplitLoading, setIsCustomerSplitLoading] = useState(true);
  const [isTeamPerformanceLoading, setIsTeamPerformanceLoading] =
    useState(false);
  const [analyticsData, setAnalyticsData] = useState<IAnalytics>({
    totalHits: 0,
    totalClassificationAccuracy: 0,
    totalOrders: 0,
    totalQuotes: 0,
    totalRequests: 0,
    totalWonLoss: 0,
    totalHitsGrowth: 0,
    totalQuotesGrowth: 0,
    totalClassificationAccuracyGrowth: 0,
    totalOrdersGrowth: 0,
    totalWonLossGrowth: 0,
    totalRequestsGrowth: 0,
    avgTimeToClassify: 0,
    avgTimeToQuote: 0,
    avgTimeToClassifyGrowth: 0,
    avgTimeToQuoteGrowth: 0,
    quotingPercentage: 0,
    quotingPercentageGrowth: 0,
    graph: {
      labels: [],
      value: {
        hit: [],
        requests: [],
        orders: [],
        quotes: [],
      },
    },
    requestQuotePieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
    totalQuotePieChartData: {
      labels: [],
      values: [],
      totalValue: 0,
    },
  });
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');
  const [boxLabel, setBoxLabel] = useState('Yesterday');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);
  const [activeFilter, setActiveFilter] = useState(() => 'daily');
  const [params, setParams] = useState(initParams);
  const [customerSplitParams, setCustomerSplitParams] = useState(initParams);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => 'custom'
  );
  const [startDate, setStartDate] = useState<any>(moment().toDate());

  const [endDate, setEndDate] = useState<any>(moment().toDate());
  const [userList, setUserList] = useState<any[]>([]);
  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');
  const [totalQuotesGraphData, setTotalQuotesGraphData] = useState([]);
  const [isChartDataLoading, setIsChartDataLoading] = useState<any>(true);
  const [customerSplitTotal, setCustomerSplitTotal] = useState<number>(0);
  // const [isRefresh, setIsRefresh] = useState(true);

  const analyticsApiController = useRef<AbortController | null>(null);
  const chartApiController = useRef<AbortController | null>(null);
  const teamPerformanceApiController = useRef<AbortController | null>(null);
  const customerSplitApiController = useRef<AbortController | null>(null);
  const isCustomerSplitUpdating = useRef(false);

  const cancelPreviousRequests = () => {
    if (analyticsApiController.current) {
      analyticsApiController.current.abort();
    }

    if (chartApiController.current) {
      chartApiController.current.abort();
    }

    if (teamPerformanceApiController.current) {
      teamPerformanceApiController.current.abort();
    }

    if (customerSplitApiController.current) {
      customerSplitApiController.current.abort();
    }
  };

  useEffect(
    () => () => {
      cancelPreviousRequests();
    },
    []
  );

  useEffect(() => {
    setFilterAfterPrevNext(activeFilter);

    if (activeFilter !== '') {
      const { start, end } = getDateRange(activeFilter);
      setStartDate(start);
      setEndDate(end);
      setIsPrevYearDisable(false);
    }

    if (activeFilter === 'weekly') {
      setBoxLabel('Last Week');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'monthly') {
      setBoxLabel('Last Month');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'yearly') {
      setBoxLabel('Last Year');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'daily') {
      setBoxLabel('Yesterday');
      setSelectedTimeRange('custom');
    }
  }, [activeFilter]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamOptions(teamData);
        // setSelectedTeam('all');
        // setSelectedUser('all');
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (teamOptions && teamOptions?.length > 0) {
      let uniqueArr = [];

      if (selectedTeam === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamOptions.map((tlo) => tlo.members);
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
        setSelectedUser('all');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamOptions.find((tlo) => tlo.value === selectedTeam);
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setUserList(updatedListAllMembers);
    }
  }, [selectedTeam, teamOptions]);

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateQuote);

    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateQuote);
    }
  }, [allTimeStartDateQuote]);

  const getFilterRange = (type?: string) => {
    setFilterAfterPrevNext(activeFilter);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      // setIsNextYearDisable(false);
      if (activeFilter === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (activeFilter === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      // if (moment(endDT, "DD-MM-YYYY").isSameOrAfter(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"))) {
      //     setIsNextYearDisable(true);
      // }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(activeFilter);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  // Refactor fetchAllData to accept a parameter for which data to fetch
  const fetchData = async (dataType = 'all') => {
    try {
      // Skip duplicate customer split calls
      if (dataType === 'customerSplit' && isCustomerSplitUpdating.current) {
        return;
      }

      // Set the flag if we're fetching customer split data
      if (dataType === 'all' || dataType === 'customerSplit') {
        isCustomerSplitUpdating.current = true;
      }

      // Only set loading indicators for what we're fetching
      if (dataType === 'all' || dataType === 'analytics') {
        setIsLoading(true);
      }

      if (dataType === 'all' || dataType === 'chart') {
        setIsChartDataLoading(true);
      }

      if (dataType === 'all' || dataType === 'teamPerformance') {
        setIsTeamPerformanceLoading(true);
      }

      if (dataType === 'all' || dataType === 'customerSplit') {
        setIsCustomerSplitLoading(true);
      }

      cancelPreviousRequests();

      // Only create controllers for what we're fetching
      if (dataType === 'all' || dataType === 'analytics') {
        analyticsApiController.current = new AbortController();
      }

      if (dataType === 'all' || dataType === 'chart') {
        chartApiController.current = new AbortController();
      }

      if (dataType === 'all' || dataType === 'teamPerformance') {
        teamPerformanceApiController.current = new AbortController();
      }

      if (dataType === 'all' || dataType === 'customerSplit') {
        customerSplitApiController.current = new AbortController();
      }

      const analyticsSignal = analyticsApiController.current?.signal;
      const chartSignal = chartApiController.current?.signal;
      const teamPerfSignal = teamPerformanceApiController.current?.signal;
      const customerSplitSignal = customerSplitApiController.current?.signal;

      // Set box label regardless of which data we're fetching
      if (!!startDate && !!endDate) {
        if (selectedTimeRange === 'all_time') {
          setBoxLabel('Previous period');
        } else if (selectedTimeRange === 'quarterly') {
          setBoxLabel('last quarter');
        } else if (activeFilter === '') {
          let days = moment(endDate).diff(moment(startDate), 'days');
          days =
            selectedTimeRange === 'last_30_days' ||
            selectedTimeRange === 'last_90_days' ||
            selectedTimeRange === 'last_7_days' ||
            selectedTimeRange === 'custom'
              ? days + 1
              : days;

          setBoxLabel(`Previous ${days} days`);
        }
      }

      const paramsInitial = {
        startDate: moment(startDate).format('DD/MM/YYYY'),
        endDate: moment(endDate).format('DD/MM/YYYY'),
        type: activeFilter,
        userId: selectedUser === 'all' ? null : selectedUser,
        teamId: selectedTeam === 'all' ? null : selectedTeam,
      };

      // Use different params for customer split if provided
      const baseTeamParams = {
        ...paramsInitial,
        ...(dataType === 'customerSplit' ? customerSplitParams : initParams),
        member: selectedUser ?? 'all',
        team: selectedTeam ?? 'all',
      };

      // Create an array of promises based on what we need to fetch
      const promises = [];

      if (dataType === 'all' || dataType === 'analytics') {
        promises.push(getAnalytics(paramsInitial, analyticsSignal));
      } else {
        promises.push(Promise.resolve(null));
      }

      if (dataType === 'all' || dataType === 'chart') {
        promises.push(getTotalQuoteChartData(paramsInitial, chartSignal));
      } else {
        promises.push(Promise.resolve(null));
      }

      if (dataType === 'all' || dataType === 'teamPerformance') {
        promises.push(getTeamPerformance(baseTeamParams, teamPerfSignal));
      } else {
        promises.push(Promise.resolve(null));
      }

      if (dataType === 'all' || dataType === 'customerSplit') {
        promises.push(getCustomerSplit(baseTeamParams, customerSplitSignal));
      } else {
        promises.push(Promise.resolve(null));
      }

      const [
        analyticsResponse,
        chartResponse,
        teamPerformanceResponse,
        customerSplitResponse,
      ]: any = await Promise.all(promises);

      // Clear loading states
      if (dataType === 'all' || dataType === 'analytics') {
        setIsLoading(false);
      }

      if (dataType === 'all' || dataType === 'chart') {
        setIsChartDataLoading(false);
      }

      if (dataType === 'all' || dataType === 'teamPerformance') {
        setIsTeamPerformanceLoading(false);
      }

      if (dataType === 'all' || dataType === 'customerSplit') {
        setIsCustomerSplitLoading(false);
      }

      // Update state only for the data we fetched
      if (
        analyticsResponse?.data &&
        (dataType === 'all' || dataType === 'analytics')
      ) {
        setAnalyticsData({
          totalHits: analyticsResponse?.data?.totalHits,
          totalClassificationAccuracy:
            analyticsResponse?.data?.totalClassificationAccuracy,
          totalOrders: analyticsResponse?.data?.totalOrders,
          totalQuotes: analyticsResponse?.data?.totalQuotes,
          totalRequests: analyticsResponse?.data?.totalRequests,
          totalWonLoss: +analyticsResponse?.data?.totalWonLoss,
          totalHitsGrowth: +analyticsResponse?.data?.totalHitsGrowth,
          totalQuotesGrowth: +analyticsResponse?.data?.totalQuotesGrowth,
          totalClassificationAccuracyGrowth:
            +analyticsResponse?.data?.totalClassificationAccuracyGrowth,
          totalOrdersGrowth: +analyticsResponse?.data?.totalOrdersGrowth,
          totalWonLossGrowth: +analyticsResponse?.data?.totalWonLossGrowth,
          totalRequestsGrowth: +analyticsResponse?.data?.totalRequestsGrowth,
          avgTimeToClassify: +analyticsResponse?.data?.avgTimeToClassify,
          avgTimeToQuote: +analyticsResponse?.data?.avgTimeToQuote,
          avgTimeToClassifyGrowth:
            +analyticsResponse?.data?.avgTimeToClassifyGrowth,
          avgTimeToQuoteGrowth: +analyticsResponse?.data?.avgTimeToQuoteGrowth,
          quotingPercentage: +analyticsResponse?.data?.quotingPercentage,
          quotingPercentageGrowth:
            +analyticsResponse?.data?.quotingPercentageGrowth,
          graph: {
            labels: analyticsResponse?.data?.categories,
            value: analyticsResponse?.data?.data,
          },
          totalQuotePieChartData:
            analyticsResponse?.data?.totalQuotePieChartData,
          requestQuotePieChartData:
            analyticsResponse?.data?.requestQuotePieChartData,
        });
      }

      if (chartResponse?.data && (dataType === 'all' || dataType === 'chart')) {
        setTotalQuotesGraphData(chartResponse?.data || []);
      }

      if (
        teamPerformanceResponse?.data &&
        (dataType === 'all' || dataType === 'teamPerformance')
      ) {
        setQuotesData(teamPerformanceResponse?.data || []);
        setTotals(teamPerformanceResponse?.data?.length || 0);
      }

      if (
        customerSplitResponse?.data &&
        (dataType === 'all' || dataType === 'customerSplit')
      ) {
        setCustomerSplitData(customerSplitResponse?.data || []);
        setCustomerSplitTotal(customerSplitResponse?.total || 0);
      }

      // Reset the flag after fetching customer split data
      if (dataType === 'all' || dataType === 'customerSplit') {
        isCustomerSplitUpdating.current = false;
      }
    } catch (e: any) {
      if (e.code === 'ERR_CANCELED') {
        return;
      }
      console.error('Error fetching data:', e);

      if (e.response?.data.message) {
        WalToast.error('Error fetching data');
      }

      // Clear loading states
      if (dataType === 'all' || dataType === 'analytics') {
        setIsLoading(false);
      }

      if (dataType === 'all' || dataType === 'chart') {
        setIsChartDataLoading(false);
      }

      if (dataType === 'all' || dataType === 'teamPerformance') {
        setIsTeamPerformanceLoading(false);
      }

      if (dataType === 'all' || dataType === 'customerSplit') {
        setIsCustomerSplitLoading(false);
      }

      // Make sure to reset flag even in case of error
      if (dataType === 'all' || dataType === 'customerSplit') {
        isCustomerSplitUpdating.current = false;
      }
    }
  };

  // Combine the useEffects and use the refined function
  useEffect(() => {
    if (startDate && endDate) {
      fetchData('all');
    }
  }, [startDate, endDate, selectedUser, selectedTeam]);

  useEffect(() => {
    if (customerSplitParams && !isCustomerSplitUpdating.current) {
      fetchData('customerSplit');
    }
  }, [customerSplitParams]);

  const searchDebounce = useCallback(
    debounce(
      (debSearch: string) =>
        setParams((old) => ({ ...old, ...{ search: debSearch, page: 1 } })),
      700
    ),
    []
  );

  const handleFilter = (event: any) => {
    const { name, value } = event.target;

    if (name === 'searchQuote') {
      setSearch(value);
      searchDebounce(value);
    }
  };

  useEffect(() => {}, [search, handleFilter]);

  const handlePagination = (page: number) => {
    setParams((old) => ({ ...old, page }));
  };

  return (
    <PageSectionLayout
      header={
        <Header
          title="Quoting Analytics"
          desc="Current quoting analytics and activity."
          // rightSideContent={rightSideContent}
          mainContainerClassName="!flex-row "
          leftClassName="!w-auto"
          rightClassName="xxl:!w-[30%] xlm:!w-[32%] xl:!w-[27%] sm:!w-auto !w-full"
        />
      }
    >
      <div className="h-full w-full xxl:gap-4 xl:gap-3 gap-3 flex flex-col">
        <div
          className={`w-full justify-between sm:items-center flex  xls:flex-row flex-col flex-wrap xxl:gap-4 xl:gap-3 gap-3 `}
        >
          <div
            className={`flex-1 flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full`}
          >
            <TabButton
              className="leading-4 sm:w-auto w-1/4 flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
              activeClassName="!bg-utilityGray100"
              tabArray={TIME_FILTER}
              parentClassName="w-full"
              tabParentClassName="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full"
              isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
              handleOnClick={(e: any) => {
                if (activeFilter === e.target.dataset.value) getFilterRange();
                setActiveFilter(e.target.dataset.value);
              }}
            />

            <div className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full items-center cursor-pointer justify-between flex">
              <DateRangePicker
                parentClassName="sales-datepicker"
                handleDatePickerChange={(dates: any, type?: string) =>
                  handleDatePickerChange(dates, type)
                }
                selectedTimeRange={selectedTimeRange}
                startDate={startDate}
                endDate={endDate}
                allTimeStartDate={allTimeStartDate}
                showYearDropdown={false}
                isToday={activeFilter === 'daily'}
                isShowDropDownIcon={false}
                containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
                isPrevDisabled={isPrevYearDisable}
                onPrevNextClick={(type: string) => getFilterRange(type)}
                setTodayAsDefaultOnClear={true}
              />
            </div>
          </div>

          <div className="flex sm:flex-row  flex-wrap xxl:gap-4 gap-3 sm:justify-end xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full empty:hidden min-w-[370px]">
            <>
              <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                <SelectBox
                  name="teamListGroup"
                  id="teamListGroup"
                  className="form_control"
                  size="sm"
                  placeholder="All Teams"
                  noOptionMessage="No Teams Found"
                  isClearable={true}
                  isSearchable={true}
                  options={teamOptions}
                  onChangeFunc={(event: any) => {
                    setSelectedTeam(event?.value ?? 'all');
                    setSelectedUser('all');
                  }}
                  value={teamOptions.filter(
                    (val) => val?.value == selectedTeam
                  )}
                />
              </div>
              <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                <SelectBox
                  name="userListGroup"
                  id="userListGroup"
                  size="sm"
                  placeholder="All Users"
                  noOptionMessage="No Users Found"
                  isSearchable={true}
                  className="form_control"
                  isClearable={true}
                  options={userList}
                  onChangeFunc={(event: any) => {
                    setSelectedUser(event?.value ?? 'all');
                  }}
                  value={userList.filter((val) => val?.value == selectedUser)}
                />
              </div>
            </>
          </div>
        </div>

        <div className="flex flex-wrap xxl:gap-4 gap-3">
          <div className="xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] w-full flex flex-col xxl:gap-3 gap-3 flex-1">
            <div className="w-full flex flex-wrap xxl:gap-3 gap-3 sm:flex-row flex-col">
              {/* <KpiCard
                headerTitle="Total Hits"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalHits.toFixed(2), isLoading)
                )}
                growth={analyticsData.totalHitsGrowth}
                fromDate={boxLabel}
                tooltipText="Total Hits"
                isLoading={isLoading}
              />
              <KpiCard
                headerTitle="Classification Accuracy"
                contentLable={`${analyticsData.totalClassificationAccuracy}`}
                growth={analyticsData.totalClassificationAccuracyGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
              /> */}
              <KpiCard
                headerTitle="Total Quote Requests"
                contentLable={getFormattedNumber(
                  useCountUp(
                    +analyticsData.totalRequests.toFixed(2),
                    isLoading
                  ),
                  true,
                  false,
                  false,
                  false
                )}
                growth={analyticsData.totalRequestsGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
                handleChart={() => setActiveSplit('totalQuoteRequests')}
              />
              <KpiCard
                headerTitle="Total Quotes"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalQuotes.toFixed(2), isLoading),
                  true,
                  false,
                  false,
                  false
                )}
                growth={analyticsData.totalQuotesGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
                handleChart={() => setActiveSplit('totalQuotes')}
              />
              <KpiCard
                headerTitle="Total Orders"
                contentLable={getFormattedNumber(
                  useCountUp(+analyticsData.totalOrders.toFixed(2), isLoading),
                  true,
                  false,
                  false,
                  false
                )}
                growth={analyticsData.totalOrdersGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
                handleChart={() => setActiveSplit('totalOrders')}
              />
              <KpiCard
                headerTitle="Avg. Time to Classify"
                contentLable={convertSecondsToTime(
                  analyticsData.avgTimeToClassify
                )}
                growth={analyticsData.avgTimeToClassifyGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
                handleChart={() => setActiveSplit('avgTimeToClassify')}
              />
              {/* <KpiCard
                headerTitle="Quoting Percentage"
                tooltipText="Quoting Percentage"
                contentLable={`${getFormattedNumber(
                  useCountUp(
                    +analyticsData.quotingPercentage.toFixed(2),
                    isLoading
                  )
                )}`}
                growth={analyticsData?.quotingPercentageGrowth}
                signIcon={'%'}
                fromDate={boxLabel}
                isLoading={isLoading}
              /> */}
              <KpiCard
                headerTitle="Avg. Time to Quote"
                contentLable={convertSecondsToTime(
                  analyticsData.avgTimeToQuote
                )}
                growth={analyticsData.avgTimeToQuoteGrowth}
                fromDate={boxLabel}
                isLoading={isLoading}
                handleChart={() => setActiveSplit('avgTimeToQuote')}
              />
              <KpiCard
                headerTitle="Win/Loss"
                contentLable={getFormattedNumber(
                  useCountUp(
                    +(analyticsData.totalWonLoss > 0 &&
                    analyticsData.totalQuotes > 0
                      ? (
                          (analyticsData.totalWonLoss * 100) /
                          analyticsData.totalQuotes
                        ).toFixed(2)
                      : 0),
                    isLoading
                  )
                )}
                growth={analyticsData.totalWonLossGrowth ?? 0}
                fromDate={boxLabel}
                signIcon={'%'}
                isLoading={isLoading}
                handleChart={() => setActiveSplit('winLoss')}
              />
            </div>
            <BarChart
              totalQuotesGraphData={totalQuotesGraphData}
              isChartDataLoading={isChartDataLoading}
            />
          </div>
          {(hasRoleV2('admin') || hasRoleV2('manager')) && (
            <div className="w-full h-auto flex flex-col justify-between xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xxl:gap-3 gap-3">
              <div className="bg-white rounded-xl border border-utilityGray200 shadow flex-1 flex flex-col xl:h-[750px] xl:min-h-[750px] xl:max-h-[750px]">
                <AnalyticsTeamPerformanceTable
                  activeFilter={activeFilter}
                  teamPerformanceData={quotesData}
                  isDataLoading={isTeamPerformanceLoading}
                  userList={userList}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                  dataObj={{
                    startDate: startDate,
                    endDate: endDate,
                    user: selectedUser,
                    team: selectedTeam,
                  }}
                />
              </div>
            </div>
          )}
          {hasRoleV2(USER_ROLE.USER) && (
            <div className="w-full h-auto flex flex-col justify-between xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xxl:gap-3 gap-3">
              <div className="bg-white rounded-xl border border-utilityGray200 shadow flex-1 flex flex-col xl:h-[727px] xl:max-h-[727px] xl:min-h-[727px] overflow-hidden">
                <CustomerSplitAsideTable
                  customerList={customerSplitData}
                  isDataLoading={isCustomerSplitLoading}
                />
              </div>
            </div>
          )}
        </div>

        <div className=" w-full bg-white rounded-xl shadow border border-utilityGray200 flex justify-between flex-col ">
          {/* <div className="table-bottom-header">
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === params.limit
                )}
              />
            </div>
          </div> */}
          {(hasRoleV2('admin') || hasRoleV2('manager')) && (
            <div className="w-full ">
              <div className="overflow-x-auto custom-scrollbar scrollbar-hide">
                <CustomerSplit
                  customerList={customerSplitData}
                  params={customerSplitParams}
                  setParams={setCustomerSplitParams}
                  isDataLoading={isCustomerSplitLoading}
                  totalRecords={customerSplitTotal}
                />
              </div>
              {!quotesData.length && !isTeamPerformanceLoading && (
                <NotFoundUI
                  title="No Quoting Splits found"
                  desc="There are no data for Quoting Splits."
                  containerClassName="!h-auto"
                />
              )}
            </div>
          )}

          <CustomPagination
            recordsPerPage={params.limit}
            totalRecords={totals}
            currentPage={params.page}
            handlePagination={handlePagination}
          />
        </div>

        {activeSplit !== '' && (
          <QuotingAnalyticsChartModal
            handleClose={() => setActiveSplit('')}
            analyticsData={analyticsData}
            activeSplit={activeSplit}
            startDate={startDate}
            endDate={endDate}
            selectedUser={selectedUser}
            selectedTeam={selectedTeam}
          />
        )}
      </div>
    </PageSectionLayout>
  );
};
export default QuotingAnalytics;
