import { QUOTE_CLASSIFICATION } from 'src/constants/common';

export interface IMapAddress {
  fullAddress: string;
  address1: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  latitude: string;
  longitude: string;
  stateCode: string;
  countryCode: string;
  companyName?: string;
}

export const initSelectQuoteFormData = {
  classification: 'ltl',
  urgency: 'live',
};

export const initShipperAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
  companyName: '',
};

export const initConsigneeAddress: IMapAddress = {
  fullAddress: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal: '',
  latitude: '',
  longitude: '',
  stateCode: '',
  countryCode: '',
  companyName: '',
};

export interface IFormData {
  [key: string]: any;
  customerId: any;
  shipperId: number | null;
  shipperCompanyName: string;
  shipperAddress: IMapAddress;
  pickupDate: any;
  consigneeId: number | null;
  consigneeCompanyName: string;
  consigneeAddress: IMapAddress;
}

export const initFormData: IFormData = {
  customerId: '',
  shipperId: null,
  shipperCompanyName: '',
  shipperAddress: initShipperAddress,
  pickupDate: new Date().toISOString(),
  consigneeId: null,
  consigneeCompanyName: '',
  consigneeAddress: initConsigneeAddress,
  classification: 'ltl',
};

export const limitedAccessList = [
  { name: 'Construction Site', value: 'construction-site' },
  { name: 'Individual (Mini) Storage Unit', value: 'mini-storage-unit' },
  { name: 'Fair/Amusement Park', value: 'fair' },
  { name: 'Place of Worship', value: 'place-of-worship' },
  { name: 'Farm/Country Club/Estate', value: 'farm' },
  {
    name: 'Secured Locations Delivery - prisons, military bases, airport',
    value: 'secured-location',
  },
  { name: 'School/University', value: 'school' },
  { name: 'Plaza/Mall with only parking lot/Street access', value: 'mall' },
  { name: 'Airport', value: 'airport' },
  { name: 'Cemetery', value: 'cemetery' },
  { name: 'Church', value: 'church' },
  { name: 'Convention Center', value: 'convention-center' },
  { name: 'Funeral Home', value: 'funeral-home' },
  { name: 'Golf Course', value: 'golf-course' },
  { name: 'Grocery Warehouse', value: 'grocery-warehouse' },
  { name: 'Hospital/Medical Facility', value: 'hospital-medical-facility' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Inspection Site', value: 'inspection-site' },
  { name: 'Marina', value: 'marina' },
  { name: 'Military Base', value: 'military-base' },
  { name: 'Mine Site', value: 'mine-site' },
  { name: 'No Dock/Dock obstruction', value: 'no-dock-dock-obstruction' },
  { name: 'Nursing Home', value: 'nursing-home' },
  { name: 'Park Forest/Preserve', value: 'park-forest-preserve' },
  { name: 'Piers and Wharves', value: 'piers-and-wharves' },
  { name: 'Prison', value: 'prison' },
  { name: 'Ranch', value: 'ranch' },
  {
    name: 'Recreational/athletic Facility',
    value: 'recreational-athletic-facility',
  },
  { name: 'Remote Government', value: 'remote-government' },
  { name: 'Reservation', value: 'reservation' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Service Station', value: 'service-station' },
  { name: 'Social Club', value: 'social-club' },
  { name: 'Steel Mill', value: 'steel-mill' },
  { name: 'Unspecified', value: 'unspecified' },
  { name: 'Utility Site', value: 'utility-site' },
  { name: 'Winery/Brewery/Distillery', value: 'winery-brewery-distillery' },
  { name: 'Other', value: 'other' },
];

export const STEPS = [
  {
    name: 'Add Shipper & Consignee',
    step: 1,
  },
  {
    name: 'Add Quote Details',
    step: 2,
  },
];

export const initValidForm = {
  customerId: true,
  shipperId: true,
  shipperCompanyName: true,
  shipperAddress: true,
  pickupDate: {
    valid: true,
    nextDay: true,
  },
  consigneeId: true,
  consigneeCompanyName: true,
  consigneeAddress: true,
  classification: true,
  urgency: true,
  primaryEquipmentType: true,
  linearFootage: true,
  totalWeight: true,
  totalWeightMax: true,
  totalWeightMin: true,

  linearFootageMax: true,
  linearFootageMin: true,
  insuranceCommercialValue: true,
};

export const initAction = {
  mode: null,
  shipper: false,
  consignee: false,
};

export const classOptions = [
  {
    label: 'LTL',
    value: QUOTE_CLASSIFICATION.LTL,
  },
  {
    label: 'FTL',
    value: QUOTE_CLASSIFICATION.FTL,
  },
  // {
  //   label: 'Volume LTL',
  //   value: QUOTE_CLASSIFICATION.VOLUME_LTL,
  // },
];

export const urgencyOptions = [
  {
    label: 'Live',
    value: 'live',
  },
  {
    label: 'RFQ',
    value: 'rfq',
  },
];

export const scriptId = 'google-maps-api';
