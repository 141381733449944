import { yupResolver } from '@hookform/resolvers/yup';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { addInFollowUpAiAgent } from 'src/services/RadarService';
import * as yup from 'yup';

const followUpArr = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
];

const methodArr = [{ label: 'Email', value: 'email' }];
const intervalArr = [
  { label: '10 minutes', value: '10' },
  { label: '20 minutes', value: '20' },
  { label: '30 minutes', value: '30' },
];

const schema = yup.object().shape({
  followUpCount: yup.number().required(''),
  followUpsValidation: yup.array().of(
    yup.object().shape({
      followupId: yup.number().nullable(),
      outreachMethod: yup.string().required(),
      outreachInterval: yup.string().required(),
    })
  ),
});

const FollowUpModal = forwardRef(
  ({
    activeLegData,
    reviewConfigData,
    followUpModalRef,
    loading,
    setLoading,
    getOrderAIAgentDetails,
    setStep,
    aiAgentDetails,
  }: any) => {
    const initialFollowUpCount =
      reviewConfigData?.isFollowUp?.length > 0
        ? reviewConfigData?.isFollowUp?.[0]?.noOfFollowUp
        : 1;

    const initialFollowUpsValidation =
      reviewConfigData?.isFollowUp?.length > 0
        ? reviewConfigData?.isFollowUp?.map((followup: any) => ({
            followupId: followup?.id || null,
            outreachMethod: followup?.followUpMethod || 'email',
            outreachInterval: followup?.followUpInterval || '',
          }))
        : [
            {
              followupId: null,
              outreachMethod: 'email',
              outreachInterval: '',
            },
          ];
    const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { errors },
      clearErrors,
    } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        followUpCount: initialFollowUpCount,
        followUpsValidation: initialFollowUpsValidation,
      },
    });

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'followUpsValidation',
    });

    const followUpCount = watch('followUpCount');

    useEffect(() => {
      const currentLength = fields.length;

      if (followUpCount > currentLength) {
        for (let i = currentLength; i < followUpCount; i++) {
          append({ outreachMethod: 'email', outreachInterval: '' });
        }
      } else if (followUpCount < currentLength) {
        for (let i = currentLength - 1; i >= followUpCount; i--) {
          remove(i);
        }
      }
    }, [followUpCount]);

    const onSubmit = async (data: any) => {
      if (loading) return;

      if (
        Number(data?.followUpCount) ===
          Number(reviewConfigData?.isFollowUp?.[0]?.noOfFollowUp) &&
        data?.followUpsValidation?.every(
          (followup: any, index: number) =>
            followup?.outreachMethod ===
              reviewConfigData?.isFollowUp?.[index]?.followUpMethod &&
            followup?.outreachInterval ===
              reviewConfigData?.isFollowUp?.[index]?.followUpInterval
        )
      ) {
        setStep(3);

        return;
      }

      setLoading(true);

      const payload = {
        masterOrderId: activeLegData?.masterOrderId,
        legId: activeLegData?.id,
        agentId: aiAgentDetails?.id,
        followupDetails: data?.followUpsValidation?.map((followup: any) => ({
          id: followup?.followupId ?? null,
          noOfFollowUp: data?.followUpCount,
          followUpMethod: followup?.outreachMethod,
          followUpInterval: followup?.outreachInterval,
        })),
      };

      addInFollowUpAiAgent(payload)
        .then((response: any) => {
          if (response?.message === 'Success') {
            getOrderAIAgentDetails();
            setStep(3);
          }
        })
        .catch((error) => {
          console.log('error :>> ', error);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useImperativeHandle(followUpModalRef, () => ({
      followUpSubmit: () => {
        handleSubmit(onSubmit)();
      },
    }));

    return (
      <>
        <div className="space-y-1">
          <h2 className="text-grayLight900 text-lg font-semibold">
            Follow Ups
          </h2>
          <p className="text-grayLight600 text-sm font-normal">
            Let me know how many times I should follow up before escalating to
            you.
          </p>
        </div>

        <div className="flex-1 overflow-auto custom-scrollbar-v2">
          <Controller
            name="followUpCount"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectBox
                isClearable={false}
                name="followUpCount"
                required
                placeholder="Select Follow Up Count"
                label="Set Follow up Count"
                className={`form_control`}
                labelClassName="form_label mb-1.5 block"
                isSearchable={true}
                options={followUpArr}
                value={followUpArr.find((val) => val?.value === value) || null}
                onChangeFunc={(e: any) => {
                  onChange(e?.value);
                }}
                errorText={errors.followUpCount?.message ? true : false}
              />
            )}
          />

          <div className="space-y-4 mt-4">
            {fields.map((item, index) => (
              <div
                key={item.id}
                className="flex flex-wrap [&>div]:px-1.5 -mx-1.5"
              >
                <Controller
                  name={`followUpsValidation.${index}.outreachMethod`}
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectBox
                      name={`followUpsValidation.${index}.outreachMethod`}
                      parentClassName="w-1/2"
                      isClearable
                      placeholder="Outreach Method"
                      label={`Outreach Method ${index + 1}`}
                      className={`form_control`}
                      labelClassName="form_label mb-1.5 block"
                      isSearchable={true}
                      options={methodArr}
                      value={
                        methodArr.find((val) => val?.value === value) || null
                      }
                      onChangeFunc={(e: any) => {
                        setValue(
                          `followUpsValidation.${index}.outreachMethod`,
                          e?.value || null
                        );

                        if (e?.value) {
                          clearErrors(
                            `followUpsValidation.${index}.outreachMethod`
                          );
                        }
                      }}
                      errorText={
                        errors.followUpsValidation?.[index]?.outreachMethod
                          ?.message
                          ? true
                          : false
                      }
                    />
                  )}
                />

                <Controller
                  name={`followUpsValidation.${index}.outreachInterval`}
                  control={control}
                  render={({ field: { value } }) => (
                    <SelectBox
                      name={`followUpsValidation.${index}.outreachInterval`}
                      parentClassName="w-1/2"
                      isClearable
                      placeholder="Set Interval"
                      label={`Set Interval ${index + 1}`}
                      className={`form_control`}
                      labelClassName="form_label mb-1.5 block"
                      isSearchable={true}
                      options={intervalArr}
                      value={
                        intervalArr.find((val) => val?.value === value) || null
                      }
                      onChangeFunc={(e: any) => {
                        setValue(
                          `followUpsValidation.${index}.outreachInterval`,
                          e?.value || null
                        );

                        if (e?.value) {
                          clearErrors(
                            `followUpsValidation.${index}.outreachInterval`
                          );
                        }
                      }}
                      errorText={
                        errors.followUpsValidation?.[index]?.outreachInterval
                          ?.message
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
);

export default FollowUpModal;
