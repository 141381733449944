import {
  ArrowRight,
  Container,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
// import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ActionTooltip from 'src/components/ActionTooltip';
import CommonModal from 'src/components/CommonModal';
import NotFoundUI from 'src/components/NotFoundUI';
import TooltipCmp from 'src/components/TooltipCmp';
import { CURRENCY } from 'src/constants/common';
import { PATH } from 'src/constants/path';
import {
  getFormattedNumber,
  getShortName,
  onError,
  usePhone,
} from 'src/utils/CommonFunctions';

// import defaultimage from '../../../../../assets//img/default-image.jpg';

interface IProps {
  handleClose: any;
  quotedList: any;
  currency: any;
  customerId: any;
  handleActionType: any;
}

const ConvertOrderModal = ({
  handleClose,
  quotedList = [],
  currency,
  customerId,
  handleActionType,
}: IProps) => {
  const navigate = useNavigate();
  const { getFormatPhoneWithPlus } = usePhone();

  return (
    <CommonModal
      title={'Convert to an Order'}
      titleDesc={'Select the active quote you would like to move forward with'}
      handleClose={() => handleClose(false)}
      size={'max-w-[750px] overflow-unset'}
      isOverflow={true}
      modalClassName=""
      headerIcon={<PackagePlus />}
    >
      <ul className="w-full p-5 flex flex-col gap-y-5">
        {quotedList?.length > 0 ? (
          quotedList.map((data: any, index: any) => {
            let phoneNumbers = [data.contactPhone];

            if (typeof data.contactPhone === 'string') {
              if (data.contactPhone.startsWith('{')) {
                try {
                  const parsed = JSON.parse(data.contactPhone);
                  phoneNumbers = Array.isArray(parsed?.phoneNumbers)
                    ? parsed.phoneNumbers
                    : [data.contactPhone];
                } catch (error) {
                  phoneNumbers = [data.contactPhone];
                }
              } else {
                phoneNumbers = [data.contactPhone];
              }
            }

            const phoneNumbersText = phoneNumbers.join(', ');

            return (
              <li
                key={index}
                className={`p-4 rounded-lg border border-utilityGray200 bg-transparent hover:bg-white hover:shadow hover:border-borderSecondary cursor-pointer flex items-center`}
                onClick={() => {
                  handleClose(false);

                  if (data?.carrierId) {
                    handleActionType('carrierOrderModel', '', data)();
                  } else {
                    navigate(`${PATH.ORDER_CREATE}`, {
                      state: {
                        customerId: customerId,
                        quoteId: data?.quoteId,
                      },
                    });
                  }
                }}
              >
                <div className="flex flex-wrap items-center gap-2 w-full">
                  <div className="w-[35%]">
                    <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                      Carrier
                    </h6>
                    <div className="flex gap-x-1">
                      <div className="flex-none">
                        {data.image ? (
                          <img
                            src={`${data.imageUrl}${data.image}`}
                            className="w-5 h-5 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none "
                            alt={data.name}
                            title={data.name}
                            onError={onError}
                          />
                        ) : data?.name ? (
                          <div className="w-5 h-5 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                            {getShortName(data.name)}
                          </div>
                        ) : data?.carrierName ? (
                          <div className="w-5 h-5 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                            {getShortName(data.carrierName)}
                          </div>
                        ) : (
                          <div className="w-5 h-5 rounded-md border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-sm">
                            <Container className="w-5 h-5 text-gray500" />
                          </div>
                        )}
                      </div>
                      <div className="w-[calc(100%_-_30px)]">
                        {data?.carrierId ? (
                          (data.name + (data.banyanPostFix || '')).length >
                          30 ? (
                            <TooltipCmp
                              message={`${data.name} ${
                                data.banyanPostFix || ''
                              }`}
                            >
                              <h6 className="text-grayLight900 text-sm font-medium truncate">
                                {`${data.name} ${data.banyanPostFix || ''}`}
                              </h6>
                            </TooltipCmp>
                          ) : (
                            <h6 className="text-grayLight900 text-sm font-medium truncate">
                              {`${data.name} ${data.banyanPostFix || ''}`}
                            </h6>
                          )
                        ) : data?.carrierName ? (
                          (data.carrierName + (data.banyanPostFix || ''))
                            .length > 15 ? (
                            <TooltipCmp
                              message={`${data.carrierName} ${
                                data.banyanPostFix || ''
                              }`}
                            >
                              <h6 className="text-grayLight900 text-sm font-medium truncate">
                                {`${data.carrierName} ${
                                  data.banyanPostFix || ''
                                }`}
                              </h6>
                            </TooltipCmp>
                          ) : (
                            <h6 className="text-grayLight900 text-sm font-medium truncate">
                              {`${data.carrierName} ${
                                data.banyanPostFix || ''
                              }`}
                            </h6>
                          )
                        ) : (
                          <h6 className="text-grayLight900 text-sm font-medium truncate">
                            {`Unassigned Carrier`}
                          </h6>
                        )}
                        {phoneNumbersText && (
                          <ActionTooltip
                            openOnHover
                            tooltipPosition="center"
                            isOpenChildTopPosOnTop
                            message={
                              phoneNumbersText
                                ? getFormatPhoneWithPlus(phoneNumbersText)
                                : ''
                            }
                            parentClassName="min-w-fit max-w-max w-52"
                          >
                            <p className="text-grayLight600 text-xs leading-[1.5] font-medium truncate">
                              {phoneNumbersText
                                ? getFormatPhoneWithPlus(phoneNumbersText)
                                : ''}
                            </p>
                          </ActionTooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="min-w-[20%]">
                    <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                      Total Cost
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {data.totalCostCAD
                        ? currency === CURRENCY.CAD
                          ? `$${getFormattedNumber(data.totalCostCAD)}`
                          : `$${getFormattedNumber(data.totalCostUSD)}`
                        : '-'}
                    </p>
                  </div>
                  <div className="min-w-[20%]">
                    <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                      Total Margin
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {data.margin === 0
                        ? '$0.00'
                        : currency === CURRENCY.CAD
                        ? `$${getFormattedNumber(data.margin)}`
                        : `$${getFormattedNumber(data.marginUSD)}`}
                    </p>
                  </div>
                  <div className="min-w-[20%]">
                    <h6 className="text-grayLight600 text-xs leading-[1.5] font-medium">
                      Total Price
                    </h6>
                    <p className="text-grayLight900 text-sm font-medium">
                      {data.finalCharge
                        ? currency === CURRENCY.CAD
                          ? `$${getFormattedNumber(data.finalCharge)} CAD`
                          : `$${getFormattedNumber(data.finalChargeUSD)} USD`
                        : '-'}
                    </p>
                  </div>
                </div>
                <ArrowRight className="w-4 h-4 flex-none text-primary" />
              </li>
            );
          })
        ) : (
          <li>
            <NotFoundUI
              title="No Active Quotes"
              desc="To proceed with the order, move your quotes to active quotes.we can only convert Active Quotes that are not expired"
              descClass="!max-w-[300px]"
            />
          </li>
        )}
      </ul>
    </CommonModal>
  );
};
export default ConvertOrderModal;
