import React from 'react';

const CustomerListTableRowLoader = () => (
  <>
    <td className="px-5 py-4 w-[19%] min-w-[19%] max-w-px">
      <div className="flex items-center gap-3 overflow-hidden">
        <div className="w-8 h-8 rounded-md bg-utilityGray100 text-primary flex items-center justify-center uppercase border-gray-200 border-[1px] flex-none custom-loading">
          ts{' '}
        </div>
        <span className="custom-loading">Test User</span>
      </div>
    </td>
    <td className="px-5 py-4 w-[16%] min-w-[16%] max-w-px">
      <div className="flex items-center gap-2 overflow-hidden">
        <div className="w-5 h-5 bg-primary rounded-full font-medium text-white flex items-center justify-center uppercase text-[8px] p-1 flex-none custom-loading"></div>
        <span className="custom-loading">Test User</span>
      </div>
    </td>
    <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4 w-[13%] min-w-[13%] max-w-px">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100.00%</span>
    </td>
  </>
);

export default CustomerListTableRowLoader;
