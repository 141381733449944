import React, { createContext, useState } from 'react';

export const DirectCarrierRatesContext = createContext<any>(null);

export const DirectCarrierRatesProvider = (props: any) => {
  const { children } = props;
  const [isCarrierRateFetched, setIsCarrierRateFetched] = useState<any>({});

  return (
    <DirectCarrierRatesContext.Provider
      value={{ isCarrierRateFetched, setIsCarrierRateFetched }}
    >
      {children}
    </DirectCarrierRatesContext.Provider>
  );
};
