import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';
import { BasicContext } from 'src/context/BasicContext';
import 'react-tippy/dist/tippy.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  message?: any;
  children: any;
  type?: 'light' | 'dark';
  parentClassName?: string;
  isOpenChildTopPosOnTop?: boolean;
  openOnHover?: boolean;
  childrenClassName?: string;
  tooltipPosition?: 'right' | 'center' | 'left';
  actionTooltipCustomClass?: string;
  hoverDelay?: number;
}

const ActionTooltip = ({
  message,
  children,
  type = 'dark',
  parentClassName = '',
  childrenClassName = '',
  isOpenChildTopPosOnTop,
  tooltipPosition = 'right',
  openOnHover = false,
  actionTooltipCustomClass = '',
  hoverDelay = 100,
}: IProps) => {
  const uniqueId = uuidv4();
  const tooltipUniqueId = `tooltipChild-${uniqueId}`;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const {
    actionTooltipGlobalOpen,
    setActionTooltipGlobalOpen,
    actionDelayTimeout,
    setActionDelayTimeout,
    setTooltipGlobalOpen,
  } = useContext(BasicContext);

  const clearExistingTimeouts = () => {
    if (actionDelayTimeout) {
      clearTimeout(actionDelayTimeout);
      setActionDelayTimeout(null);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (tooltipOpen) {
        setTooltipOpen(false);
        setActionTooltipGlobalOpen(false);
        clearExistingTimeouts();
      }
    };

    const scrollableContainer = document.querySelector('.main__layout_section');
    scrollableContainer?.addEventListener('scroll', handleScroll);

    const allScrollableContainers =
      document.querySelectorAll('.overflow-x-auto');

    allScrollableContainers.forEach((element) => {
      element.addEventListener('scroll', handleScroll);
    });

    return () => {
      scrollableContainer?.removeEventListener('scroll', handleScroll);
      allScrollableContainers.forEach((element) => {
        element?.removeEventListener('scroll', handleScroll);
      });
    };
  }, [tooltipOpen]);

  useEffect(() => {
    let handleOutsideClick: any;
    let handleEscapePress: any;
    const tooltipChild = document.querySelector(`.${tooltipUniqueId}`);

    if (tooltipOpen) {
      handleOutsideClick = (event: any) => {
        if (tooltipChild && !tooltipChild?.contains(event.target)) {
          setTooltipOpen(false);
          setActionTooltipGlobalOpen(false);
        }
      };

      handleEscapePress = (event: any) => {
        if (tooltipOpen && event.key === 'Escape') {
          setTooltipOpen(false);
          setActionTooltipGlobalOpen(false);
        }
      };

      document.addEventListener('mousedown', handleOutsideClick);
      document.addEventListener('keydown', handleEscapePress);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('keydown', handleEscapePress);
    };
  }, [tooltipOpen]);

  const handleToggleTooltip = (newValue: boolean) => {
    clearExistingTimeouts();

    if (newValue) {
      setActionDelayTimeout(
        setTimeout(() => {
          setTooltipOpen(true);
          setActionTooltipGlobalOpen(true);
          setTooltipGlobalOpen(false);
        }, hoverDelay)
      );
    } else {
      setTooltipOpen(false);
      setActionTooltipGlobalOpen(false);
    }
  };

  useEffect(() => {
    if (!actionTooltipGlobalOpen) {
      setTooltipOpen(false);
    }
  }, [actionTooltipGlobalOpen]);

  return (
    <>
      {/* @ts-ignore */}
      <Tooltip
        html={
          <div
            style={{
              ...(isOpenChildTopPosOnTop ? { bottom: '0px' } : { top: `0px` }),
              ...(tooltipPosition === 'right'
                ? { transform: 'translateX(-83%)' }
                : {}),
              ...(tooltipPosition === 'center'
                ? { transform: 'translateX(-50%)' }
                : {}),
              ...(tooltipPosition === 'left'
                ? { transform: 'translateX(-20%)' }
                : {}),
            }}
            className={`absolute left-1/2 transform shadow text-xs rounded-lg break-words px-[6px] py-[6px] cursor-pointer empty:hidden ${parentClassName} ${
              type === 'light'
                ? 'bg-white border border-borderPrimary'
                : 'bg-[#0C111B] border border-[#0C111B] text-grayLight '
            }`}
          >
            {message}
          </div>
        }
        popperOptions={{
          modifiers: {
            addCustomClass: {
              enabled: true,
              fn: (data: any) => {
                data.instance.popper.classList.add('action-tooltip-custom');

                if (actionTooltipCustomClass) {
                  data.instance.popper.classList.add(
                    ...actionTooltipCustomClass.split(' ')
                  );
                }

                return data;
              },
            },
          },
        }}
        theme={type}
        duration={0}
        open={tooltipOpen}
        arrow={true}
        hideOnClick={false}
        trigger="manual"
        position={isOpenChildTopPosOnTop ? 'top' : 'bottom'}
      >
        <div
          className={`${childrenClassName} cursor-pointer ${tooltipUniqueId} inline`}
          onClick={(e) => {
            e.stopPropagation();

            if (!openOnHover) {
              handleToggleTooltip(!tooltipOpen);
            }
          }}
          onMouseEnter={() => {
            if (openOnHover) handleToggleTooltip(true);
          }}
          onMouseLeave={() => {
            if (openOnHover) handleToggleTooltip(false);
          }}
        >
          {children}
        </div>
      </Tooltip>
    </>
  );
};

export default ActionTooltip;
