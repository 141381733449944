import { InfoCircle, XClose } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

interface IProps {
  style?: 'info' | 'error';
  label?: string;
  labelInfo?: any;
  CloseAlert?: boolean;
  className?: string;
}

const AlertMessage = ({
  style = 'info',
  labelInfo,
  label,
  CloseAlert,
  className,
}: IProps) => (
  <div
    className={`px-3 py-2.5 rounded-md border  flex gap-x-2 ${
      style === 'error'
        ? 'border-utilityError300 bg-utilityError50'
        : style === 'info'
        ? 'border-borderSecondary bg-utilityindigo25'
        : ''
    } ${className}`}
  >
    <InfoCircle
      className={`mt-px w-3.5 h-3.5 ${
        style === 'error'
          ? 'text-utilityError400'
          : style === 'info'
          ? 'text-primary'
          : 'text-textSecondary'
      }`}
    />
    <p className={`text-textSecondary text-xs font-medium flex-1`}>
      {label && (
        <span className="text-grayLight900 font-semibold">{label}&nbsp;</span>
      )}
      {labelInfo}
    </p>
    {CloseAlert && (
      <>
        <div
          className={`-m-[3px] cursor-pointer [&>svg]:w-3 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-[18px] w-[18px] flex justify-center items-center rounded-full flex-none `}
        >
          <XClose className="text-gray500 hover:text-textSecondary" />
        </div>
      </>
    )}
  </div>
);

export default AlertMessage;
