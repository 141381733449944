// export const STEPS = [
//   {
//     name: 'Address Details',
//     step: 1,
//   },
//   {
//     name: 'Dimensions',
//     step: 2,
//   },
//   {
//     name: 'Finish',
//     step: 3,
//   },
// ];

export const STEPS = [
  {
    name: 'Add Shipper & Consignee',
    step: 1,
  },
  {
    name: 'Add Quote Details',
    step: 2,
  },
];
