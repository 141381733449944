import {
  CreditCard01,
  CurrencyDollar,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { updateOnboarding } from 'src/services/CustomerOnBoardingService';
import WalToast from 'src/utils/WalToast';

const CreditLimitModal = ({
  customerId,
  handleClose,
  onApprovalSuccess,
  getDetail,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [initialCreditLimit, setInitialCreditLimit] = useState<any>();

  const handleSubmit = () => {
    if (!initialCreditLimit) {
      setIsError(true);

      return;
    } else setIsError(true);
    setIsLoading(true);
    updateOnboarding({
      id: customerId,
      status: 'Approved',
      initialCreditLimit: initialCreditLimit,
    })
      .then((response: any) => {
        if (response?.isCustomerExist) {
          WalToast.error('Customer already exist');
        } else {
          getDetail();
          onApprovalSuccess();
        }
        setIsLoading(false);
        handleClose();
      })
      .catch((e: any) => console.error(e));

    console.log('handleSubmit');
  };

  return (
    <CommonModal
      title={' Initial Credit Limit'}
      titleDesc={'View and manage your Initial Credit limit.'}
      handleClose={() => handleClose(false)}
      headerIcon={<CreditCard01 />}
      size={'max-w-[688px]'}
      modalClassName=""
      primaryBtnOnClick={() => handleSubmit()}
      primaryBtnDisabled={isLoading}
      primaryBtnLoading={isLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      primaryBtnText="Save"
      isOverflow={false}
    >
      <div className="p-5">
        <div className="flex gap-x-2 rounded-md border border-utilityGray200 p-3">
          <InputText
            label="Set Initial Credit Limit"
            labelClassName="block mb-1.5"
            placeholder="Set Initial Credit Limit"
            parentClassName="w-full"
            value={initialCreditLimit}
            onFocus={() =>
              initialCreditLimit === 0 && setInitialCreditLimit('')
            }
            onBlur={() =>
              initialCreditLimit === '' && setInitialCreditLimit('')
            }
            inputType="number"
            iconLeft={
              <CurrencyDollar className="h-4 w-4 cursor-pointer absolute left-2 mr-2 text-black/90" />
            }
            shouldFormatNumber={true}
            className="placeholder:text-secondaryTxtColor pl-7"
            onChangeFunc={(e: any) => {
              setInitialCreditLimit(e?.target?.value);
            }}
            errorText={
              isError && !initialCreditLimit
                ? 'Initial Credit Limit is required'
                : ''
            }
            isClearable={false}
          />
        </div>
      </div>
    </CommonModal>
  );
};
export default CreditLimitModal;
