import React, { KeyboardEventHandler } from 'react';
import CreatableSelect from 'react-select/creatable';

import ErrorMsg from '../errorMsg';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

// const createOption = (label: string) => ({
//   label,
//   value: label,
// });

interface Props {
  placeholder?: string;
  inputName?: string;
  isClearable?: boolean;
  isMulti?: boolean;
  menuIsOpen?: boolean;
  onChange?: (newValue: readonly Option[]) => void;
  onInputChange?: (newValue: string) => void;
  onKeyDown?: KeyboardEventHandler;
  onBlur?: (event: any) => void;
  value: readonly Option[];
  inputValue: string;
  errorText?: any;
  CreatableSelectWrapClass?: string;
}

const DynamicCreatableSelect: React.FC<Props> = ({
  placeholder = 'Type something and press enter...',
  isClearable = true,
  inputName,
  isMulti = true,
  menuIsOpen = false,
  onChange,
  onInputChange,
  onKeyDown,
  onBlur,
  value,
  inputValue,
  CreatableSelectWrapClass,
  errorText = '',
}) => (
  <>
    <CreatableSelect
      components={components}
      name={inputName}
      inputValue={inputValue}
      isClearable={isClearable}
      isMulti={isMulti}
      menuIsOpen={menuIsOpen}
      onChange={onChange as any}
      onInputChange={onInputChange}
      onKeyDown={onKeyDown}
      onBlur={(event) => {
        if (inputValue && onBlur) {
          onBlur(event);
        }
      }}
      placeholder={placeholder}
      value={value}
      className={` ${CreatableSelectWrapClass} custom-reply-select`}
      classNamePrefix={'custom-reply-select'}
    />
    {errorText && <ErrorMsg errorText={errorText} />}
  </>
);

export default DynamicCreatableSelect;
