import { ChevronUp, ChevronDown } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';

const TotalMarginRow = ({ data, subDataVisibilityForTotalMargin }: any) => {
  const filteredData = data?.key?.find(
    (item: any) => item.name === 'Total Margin'
  );

  return (
    <>
      <td className="px-4 py-3 w-[45%] min-w-[45%] max-w-[1px]">
        <div className="text-grayLight900 font-medium">Total Margin</div>
      </td>
      <td className="px-4 py-3 w-[50.66%] min-w-[50.66%] max-w-[1px]">
        <span
          className={`truncate text-grayLight900 font-semibold`}
        >{`$${filteredData?.cad} `}</span>
        <span
          className={`truncate text-gray500 font-medium`}
        >{`($${filteredData?.usd})`}</span>
      </td>
      <td className="px-4 py-3 w-[50px] max-w-[50px] min-w-[50px]">
        {subDataVisibilityForTotalMargin[1] ? (
          <ChevronUp className="w-4 h-4 flex-none" />
        ) : (
          <ChevronDown className="w-4 h-4 flex-none" />
        )}
      </td>
    </>
  );
};

export default TotalMarginRow;
