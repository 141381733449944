import React, { useMemo } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import TableCmp from 'src/components/TableCmp';

import ProgressOverviewLoadingRow from './ProgressOverviewLoadingRow';
import ProgressOverviewRow from './ProgressOverviewRow';

const Overview = () => {
  const headCells = useMemo(
    () => [
      {
        id: 'time',
        name: '',
      },
      {
        id: 'id',
        name: '',
      },
      {
        id: 'type',
        name: '',
      },

      {
        id: 'address',
        name: '',
      },
      {
        id: 'length',
        name: '',
      },
      {
        id: 'rate',
        name: '',
      },
      {
        id: 'action',
        name: '',
      },
    ],
    []
  );

  return (
    <>
      <div className="space-y-1.5">
        <h6 className="text-sm font-semibold text-grayLight900">
          Todays Overview
        </h6>
        <div className="flex [&>div:not(:last-child)]:pr-5 [&>div:not(:last-child)]:mr-5 [&>div:not(:last-child)]:border-r [&>div:not(:last-child)]:border-utilityGray200">
          <div className="py-1">
            <div className="text-gray500 text-xs font-normal">
              Completed Tasks
            </div>
            <div className="text-grayLight900 font-semibold text-sm">500</div>
          </div>
          <div className="py-1">
            <div className="text-gray500 text-xs font-normal">Time On Call</div>
            <div className="text-grayLight900 font-semibold text-sm">
              4hr 21min
            </div>
          </div>
          <div className="py-1">
            <div className="text-gray500 text-xs font-normal">Emails Sent</div>
            <div className="text-grayLight900 font-semibold text-sm">1600</div>
          </div>
          <div className="py-1">
            <div className="text-gray500 text-xs font-normal">
              Emails Processed
            </div>
            <div className="text-grayLight900 font-semibold text-sm">2500</div>
          </div>
          <div className="py-1">
            <div className="text-gray500 text-xs font-normal">Time Savings</div>
            <div className="text-grayLight900 font-semibold text-sm">
              6hr 43min
            </div>
          </div>
        </div>
      </div>
      <div className="bg-utilityGray200 h-px"></div>
      {/* In Progress Section */}
      <div className="flex gap-x-1.5">
        <h3 className="text-grayLight900 text-sm font-semibold">In Progress</h3>
        <BadgeCmp style="modern" type="primary" isHidePillDot>
          10
        </BadgeCmp>
      </div>

      <div className=" w-full bg-white ">
        <TableCmp
          headCells={headCells}
          tableDataArr={[{}, {}]}
          TableLoaderRowCmp={ProgressOverviewLoadingRow}
          TableRowCmp={ProgressOverviewRow}
          isTableRowClickable={true}
          tableWrapClass=""
          tableHeaderClass="hidden"
        />
      </div>
      <div className="rounded-xl border border-utilityGray200 p-3 min-h-[158px] space-y-1 flex flex-col items-center justify-center">
        <p className="text-grayLight900 text-sm font-semibold">
          No tasks currently in progress
        </p>
        <p className="text-grayLight600 text-xs font-normal">
          Richard is not currently working on any tasks.
        </p>
      </div>

      {/* Completed Tasks Section */}
      <h3 className="text-grayLight900 text-sm font-semibold">Completed</h3>
      <div className="rounded-xl border border-utilityGray200 p-3 min-h-[158px] space-y-1 flex flex-col items-center justify-center">
        <p className="text-grayLight900 text-sm font-semibold">
          No tasks have been completed yet today.
        </p>
        <p className="text-grayLight600 text-xs font-normal">
          Richard is not currently working on any tasks.
        </p>
      </div>
    </>
  );
};

export default Overview;
