import {
  Download01,
  // File05,
  Upload01,
} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import UploadOrderPodModal from 'src/app/Orders/Common/UploadOrderPodModal';
import { downloadPdf } from 'src/utils/CommonFunctions';

import { OrderContext } from '../..';
import pdf from '../../../../../assets/img/pdf-v2.svg';

export const initAction = {
  mode: null,
  type: null,
};

interface IProps {
  iconClass?: string;
  title: string;
  isPrimary: boolean;
  onClick: () => void;
  isLoading: boolean;
}

const DocumentItem = ({
  // iconClass,
  title,
  isPrimary = false,
  onClick,
  isLoading,
}: IProps) => (
  <li className="xxl:p-1.5 p-1 xlm:w-1/4 w-1/3">
    <div
      className={`rounded-lg border border-utilityGray200 bg-white p-3 flex items-center cursor-pointer ${
        isLoading ? 'custom-loading' : ''
      }`}
      onClick={() => {
        onClick();
      }}
    >
      <div
        className={`h-[34px] flex-none ${isPrimary ? 'w-[34px] mr-1.5 ' : ''}`}
      >
        {isPrimary && (
          <img src={pdf} className="w-full h-full object-contain" />
        )}
      </div>

      <div className="flex-1 pr-1.5">
        <p
          className={`text-xs font-semibold ${
            isPrimary ? 'text-primary' : 'text-gray500'
          }`}
        >
          {title}
        </p>
        {/* {isPrimary && (
          <p className="text-grayLight600 text-[10px] leading-[1.5] font-normal">
            200 KB
          </p>
        )} */}
      </div>
      {isPrimary ? (
        <Download01 className="w-4 h-4 text-primary cursor-pointer" />
      ) : (
        <Upload01 className="w-4 h-4 text-textSecondary cursor-pointer" />
      )}
    </div>
  </li>
);

const DocumentDetailsTab = () => {
  const { currentLegData, isMasterOrder, isLoading } =
    useContext<any>(OrderContext);

  const [action, setAction] = useState(initAction);
  const [documents, setDocuments] = useState<any>([]);
  const [modalTitle, setModalTitle] = useState<string>('Upload Document');
  const [documentType, setDocumentType] = useState<string | null>(null);

  useEffect(() => {
    if (currentLegData?.documents && currentLegData?.documents?.length > 0) {
      setDocuments(currentLegData?.documents);
    }
  }, [currentLegData]);

  const handleDocumentClick = (type: string) => {
    if (type === 'POD_document') {
      setModalTitle('Upload POD');
      setDocumentType('POD_document');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'POD',
      }));
    } else if (type === 'BOL') {
      setModalTitle('Upload Bill of Lading');
      setDocumentType('BOL');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Bill of Lading',
      }));
    } else if (type === 'rate_confirmation') {
      setModalTitle('Upload Rate Confirmation');
      setDocumentType('rate_confirmation');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Rate Confirmation',
      }));
    } else if (type === 'customer_quote') {
      setModalTitle('Upload Customer Quote');
      setDocumentType('customer_quote');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Customer Quote',
      }));
    } else if (type === 'commercial_invoice') {
      setModalTitle('Upload Commercial Invoice');
      setDocumentType('commercial_invoice');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Commercial Invoice',
      }));
    } else if (type === 'new_documents') {
      setModalTitle('Upload New Documents');
      setDocumentType('new_documents');
      setAction((old: any) => ({
        ...old,
        mode: 'upload-document',
        type: 'Upload New Documents',
      }));
    }
  };

  const handleDocumentDownload = (docType: string) => {
    const docs =
      documents && documents?.length > 0
        ? documents?.filter((doc: any) => doc?.documentType === docType)
        : [];

    if (docs && docs.length > 0) {
      if (docs[0].file) {
        downloadPdf(`${docs[0].fileUrl}${docs[0].file}`, docs[0].file);
      } else {
        downloadPdf(`${docs[0].fileUrl}`, `${docs[0].documentType}.pdf`);
      }
    } else {
      handleDocumentClick(docType);
    }
  };

  const getDocumentItemProps = (docType: string) => {
    const hasDocument = documents?.some(
      (doc: any) => doc?.documentType === docType
    );

    const onClickHandler = () => {
      if (hasDocument) {
        handleDocumentDownload(docType);
      } else {
        handleDocumentClick(docType);
      }
    };

    return {
      iconClass: hasDocument ? 'text-primary' : 'text-textSecondary',
      isPrimary: hasDocument,
      onClick: onClickHandler,
    };
  };

  const documentItems = [
    {
      title: 'Bill of Lading',
      ...getDocumentItemProps('BOL'),
    },
    {
      title: 'Rate Confirmation',
      ...getDocumentItemProps('rate_confirmation'),
    },
    {
      title: 'Customer Quote',
      ...getDocumentItemProps('customer_quote'),
    },
    {
      title: 'Commercial Invoice',
      ...getDocumentItemProps('commercial_invoice'),
    },
    {
      title: 'POD',
      ...getDocumentItemProps('POD'),
    },
    {
      title: 'Upload New Documents',
      ...getDocumentItemProps('new_documents'),
    },
  ];

  const legDocumentItems = [
    {
      title: 'Leg BOL',
      ...getDocumentItemProps('BOL'),
    },
    {
      title: 'Leg Rate Confirmation',
      ...getDocumentItemProps('rate_confirmation'),
    },
    {
      title: 'Leg POD',
      ...getDocumentItemProps('POD'),
    },
    {
      title: 'Commercial Invoice',
      ...getDocumentItemProps('commercial_invoice'),
    },
    {
      title: 'Upload New Documents',
      ...getDocumentItemProps('new_documents'),
    },
  ];

  const documentList = isMasterOrder ? documentItems : legDocumentItems;

  return (
    <>
      <div className="p-4 bg-gray25">
        <div className="p-3 rounded-lg border border-utilityGray200 bg-white">
          <ul className=" flex flex-wrap xxl:-m-1.5 -m-1 ">
            {documentList.map((item, index) => (
              <DocumentItem
                key={index}
                iconClass={item.iconClass}
                title={item.title}
                isPrimary={item.isPrimary}
                onClick={() => {
                  item.onClick();
                }}
                isLoading={isLoading}
              />
            ))}
          </ul>
        </div>
      </div>

      {action.mode === 'upload-document' && documentType && (
        <UploadOrderPodModal
          handleClose={() => {
            setAction(initAction);
            setDocumentType(null);
          }}
          orderLegId={currentLegData?.id}
          title={modalTitle}
          fileStorePath={null}
          documentType={documentType}
          isShowTitle={false}
          handleOnSubmit={(documentData: any) => {
            if (documentData) {
              setDocuments((prevDocs: any[]) => [
                ...prevDocs,
                { ...documentData },
              ]);
            }
            setAction(initAction);
          }}
        />
      )}
    </>
  );
};

export default DocumentDetailsTab;
