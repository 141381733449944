import { yupResolver } from '@hookform/resolvers/yup';
import { XClose } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import InputText from 'src/components/InputText/InputText';
import { setNumberWithCommas } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import inboundEmoji from '../../../../../assets/img/inbound-emoji.webp';

const truckFindingAiSchema = yup.object().shape({
  enableTruckFinding: yup
    .boolean()
    .required('Please select truck finding ai for this load'),
  enableInboundTruckFinding: yup.boolean(),
  acceptInboundCallDuration: yup
    .string()
    .nullable()
    .when('enableInboundTruckFinding', (enableInboundTruckFinding, schema) =>
      enableInboundTruckFinding[0]
        ? schema
            .required('Accept inbound calls is required.')
            .test(
              'is-greater-than-zero',
              'Accept inbound calls must be greater than 0.',
              (value) => {
                if (!value) return false;
                const parsedValue = parseInt(
                  value.replace(/minutes?/, '').trim(),
                  10
                );

                return !isNaN(parsedValue) && parsedValue > 0;
              }
            )
        : schema.nullable()
    ),
  totalRates: yup
    .string()
    .nullable()
    .when('enableTruckFinding', (enableTruckFinding, schema) =>
      enableTruckFinding[0]
        ? schema
            .required('Total rates is required.')
            .test(
              'is-greater-than-zero',
              'Total rates must be more than 0.',
              (value) => {
                if (!value) return false;
                const parsedValue = parseInt(
                  value.replace(/rates?/, '').trim(),
                  10
                );

                return !isNaN(parsedValue) && parsedValue > 0;
              }
            )
        : schema.nullable()
    ),
  budgetCost: yup
    .string()
    .nullable()
    .test(
      'budget-less-than-highestRate',
      'Budget cost must be less than highest rate',
      function (value) {
        const highestRate = this.parent?.highestRate?.replace(/[$,\s]/g, '');
        value = value?.replace(/[$,\s]/g, '');

        return (
          !value || !highestRate || parseFloat(value) <= parseFloat(highestRate)
        );
      }
    ),
  highestRate: yup
    .string()
    .nullable()
    .test(
      'highestRate-greater-than-budget',
      'Highest rate must be greater than budget cost',
      function (value) {
        const budgetCost = this.parent?.budgetCost?.replace(/[$,\s]/g, '');
        value = value?.replace(/[$,\s]/g, '');

        return (
          !value || !budgetCost || parseFloat(value) >= parseFloat(budgetCost)
        );
      }
    ),
});

interface IProps {
  title?: any;
  truckFindingAiDetail?: any;
  postCreatePayload: any;
  rateGatheringWithAi: any;
  handleClose: () => void;
  handleModalSubmit?: (data: any) => void;
}

const setInboundCallDuration = (truckFindingAi: any) => {
  const updatedAt = moment.utc(truckFindingAi.updatedAt, 'YYYY-MM-DD HH:mm:ss');
  const currentGMT = moment().tz('GMT');
  const diffMinutes = currentGMT.diff(updatedAt, 'minutes');
  const remainingTime = truckFindingAi.acceptInboundCallDuration - diffMinutes;

  return `${
    remainingTime >= 0
      ? `${setNumberWithCommas(remainingTime)} minute${
          truckFindingAi.acceptInboundCallDuration == 1 ? '' : 's'
        }`
      : '0 minutes'
  }`;
};

const TruckFindingAiModals = ({
  title,
  truckFindingAiDetail,
  postCreatePayload,
  rateGatheringWithAi,
  handleClose,
  handleModalSubmit,
}: IProps) => {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(truckFindingAiSchema),
    mode: 'onChange',
    defaultValues: {
      enableTruckFinding: true,
      enableInboundTruckFinding: true,
      acceptInboundCallDuration:
        truckFindingAiDetail &&
        'acceptInboundCallDuration' in truckFindingAiDetail
          ? setInboundCallDuration(truckFindingAiDetail)
          : rateGatheringWithAi &&
            rateGatheringWithAi.acceptInboundCallDuration != null &&
            rateGatheringWithAi.acceptInboundCallDuration >= 0
          ? `${setNumberWithCommas(
              rateGatheringWithAi.acceptInboundCallDuration
            )} minute${
              rateGatheringWithAi.acceptInboundCallDuration == 1 ? '' : 's'
            }`
          : '',
      totalRates:
        truckFindingAiDetail && 'totalRates' in truckFindingAiDetail
          ? truckFindingAiDetail.totalRates
            ? `${setNumberWithCommas(truckFindingAiDetail.totalRates)} rate${
                truckFindingAiDetail.totalRates == 1 ? '' : 's'
              }`
            : '0 rates'
          : rateGatheringWithAi && rateGatheringWithAi.totalRates
          ? `${setNumberWithCommas(rateGatheringWithAi.totalRates)} rate${
              rateGatheringWithAi.totalRates == 1 ? '' : 's'
            }`
          : '',
      budgetCost:
        truckFindingAiDetail && 'budgetCost' in truckFindingAiDetail
          ? `${
              truckFindingAiDetail.budgetCost
                ? `$ ${setNumberWithCommas(truckFindingAiDetail?.budgetCost)}`
                : ''
            }`
          : '',
      highestRate:
        truckFindingAiDetail && 'highestRate' in truckFindingAiDetail
          ? `${
              truckFindingAiDetail.highestRate
                ? `$ ${setNumberWithCommas(truckFindingAiDetail?.highestRate)}`
                : ''
            }`
          : '',
    },
  });

  const [step, setStep] = useState(1);
  const [primaryButtonText, setPrimaryButtonText] = useState('Yes');
  const enableTruckFinding = watch('enableTruckFinding');
  const enableInboundTruckFinding = watch('enableInboundTruckFinding');
  const budgetCost = watch('budgetCost');
  const highestRate = watch('highestRate');

  const setPrimaryButtonTextOnStep2 = () => {
    if (budgetCost || highestRate) {
      setPrimaryButtonText('Next');
    } else {
      setPrimaryButtonText('Skip');
    }
  };

  useEffect(() => {
    if (step == 2) setPrimaryButtonTextOnStep2();
  }, [step, budgetCost, highestRate]);

  const onSubmit = (data: any) => {
    const loadPostingData = {
      ...postCreatePayload,
      truckFindingAiData: {
        enableTruckFinding: data.enableTruckFinding,
        enableInboundTruckFinding: data.enableInboundTruckFinding,
        acceptInboundCallDuration: data.acceptInboundCallDuration
          ? data.acceptInboundCallDuration.replace(/,|minutes?/g, '').trim()
          : null,
        enableOutboundTruckFinding: false,
        outgoingCallStartAfter: null,
        totalRates: data.totalRates
          ? data.totalRates.replace(/,|rates?/g, '').trim()
          : null,
        budgetCost: data.budgetCost
          ? data.budgetCost.replace(/[$,\s]/g, '')
          : null,
        highestRate: data.highestRate
          ? data.highestRate.replace(/[$,\s]/g, '')
          : null,
      },
    };

    if (handleModalSubmit) {
      handleModalSubmit(loadPostingData);
    }
  };

  const handleNext = () => {
    if (step === 1) {
      setPrimaryButtonTextOnStep2();
      setStep(2);
    } else if (step === 2) {
      if (!errors.budgetCost && !errors.highestRate) {
        setPrimaryButtonText('Launch');
        setStep(3);
      }
    } else if (step === 3) {
      handleSubmit(onSubmit)();
    }
  };

  const setAcceptInboundCallDuration = (e: any) => {
    setValue(
      'acceptInboundCallDuration',
      e.target.value
        ? `${setNumberWithCommas(parseInt(e.target.value))} minute${
            e.target.value == 1 ? '' : 's'
          }`
        : ''
    );
  };

  const setTotalRates = (e: any) => {
    setValue(
      'totalRates',
      e.target.value
        ? `${setNumberWithCommas(parseInt(e.target.value))} rate${
            e.target.value == 1 ? '' : 's'
          }`
        : ''
    );
  };

  const [isTruckFindingAiUpdateLoading] = useState(false);

  return (
    <CommonModal
      title={title}
      handleClose={handleClose}
      size={'max-w-[480px] min-h-[408px]'}
      primaryBtnText={primaryButtonText}
      primaryBtnOnClick={() => handleNext()}
      primaryBtnLoading={isTruckFindingAiUpdateLoading}
      primaryBtnDisabled={isTruckFindingAiUpdateLoading}
      secondaryBtnText={step == 1 ? 'No' : 'Back'}
      secondaryBtnOnClick={() => {
        if (step == 1) {
          setValue('enableTruckFinding', false);
          setValue('enableInboundTruckFinding', false);
          setValue('acceptInboundCallDuration', '');
          setValue('totalRates', '');
          setValue('budgetCost', '');
          setValue('highestRate', '');
          handleSubmit(onSubmit)();
        } else {
          setPrimaryButtonText(step == 2 ? 'Yes' : 'Launch');
          setStep(step - 1);
        }
      }}
      secondaryBtnDisabled={isTruckFindingAiUpdateLoading}
      modalFooterClass={`!border-0 !p-6`}
      modalBtnTypeClass="btn-outline-primary"
    >
      <div className="px-6 pt-6 pb-2 space-y-5">
        {step === 1 && (
          <>
            <div className="rounded-lg bg-utilitySuccess100 p-4">
              <figure className="w-[140px] h-[140px] mx-auto">
                <img
                  src={inboundEmoji}
                  className="w-full h-full object-contain"
                />
              </figure>
            </div>
            <div className="text-center">
              <h6 className="text-grayLight900 text-lg font-semibold">
                Would like to Collect Inbound Rates with Richard?
              </h6>
              <p className="text-grayLight600 text-sm font-normal">
                Get Richard to answer and classify all inbound rates for this
                load posting
              </p>
            </div>
            <ul className="flex items-center justify-center gap-x-4">
              <li
                className={`w-2.5 h-2.5 rounded-full ${
                  step === 1 ? 'bg-primary' : 'bg-utilityGray200'
                }`}
              ></li>
              <li
                className={`w-2.5 h-2.5 rounded-full bg-utilityGray200 `}
              ></li>
              <li className={`w-2.5 h-2.5 rounded-full bg-utilityGray200`}></li>
            </ul>
          </>
        )}

        {(step === 2 || step === 3) && (
          <div className="flex justify-between">
            <div className="w-14 h-14 p-2 rounded-full bg-utilitySuccess100 relative">
              <img
                src={inboundEmoji}
                className="w-full h-full object-contain"
              />
              <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] p-[47px]"></div>
              <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-80 p-[71px]"></div>
              <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-60 p-[95px]"></div>
              <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-40 p-[119px]"></div>
              <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-20 p-[143px]"></div>
            </div>
            <div
              className={`cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full flex-none`}
            >
              <XClose
                className="text-gray400 hover:text-textSecondary"
                onClick={handleClose}
              />
            </div>
          </div>
        )}

        {step === 2 && (
          <>
            <div className="space-y-1">
              <h2 className="text-grayLight900 text-lg font-semibold">
                Add a Budget Cost
              </h2>
              <p className="text-grayLight600 text-sm font-normal">
                Let me know what range of prices I should try to look for.
              </p>
            </div>

            <Controller
              name="budgetCost"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="budgetCost"
                  inputType="text"
                  label="What's your budget cost?"
                  labelClassName="block mb-1.5"
                  placeholder="Enter your budget amount"
                  className="form_control"
                  parentClassName="rounded-lg bg-white"
                  value={value ?? ''}
                  onChangeFunc={(e) => {
                    const val = e.target.value;
                    const newValue = val.replace(/[$,\s]/g, '');

                    if (/^\d*$/.test(newValue)) {
                      onChange(
                        newValue
                          ? `$ ${setNumberWithCommas(parseInt(newValue))}`
                          : ''
                      );
                    }
                  }}
                  errorText={errors.budgetCost ? true : false}
                />
              )}
            />

            <Controller
              name="highestRate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="highestRate"
                  inputType="text"
                  label="What's the highest rate you would accept?"
                  labelClassName="block mb-1.5"
                  placeholder="Enter your highest rate amount"
                  className="form_control min-w-[120px]"
                  parentClassName="rounded-lg bg-white"
                  value={value ?? ''}
                  onChangeFunc={(e) => {
                    const val = e.target.value;
                    const newValue = val.replace(/[$,\s]/g, '');

                    if (/^\d*$/.test(newValue)) {
                      onChange(
                        newValue
                          ? `$ ${setNumberWithCommas(parseInt(newValue))}`
                          : ''
                      );
                    }
                  }}
                  errorText={errors.highestRate ? true : false}
                />
              )}
            />
          </>
        )}
        {step === 3 && (
          <>
            <div className="space-y-1">
              <h2 className="text-grayLight900 text-lg font-semibold">
                Add a Rate Count
              </h2>
              <p className="text-grayLight600 text-sm font-normal">
                Let me know how many rates I should aim to collect.
              </p>
            </div>
            <Controller
              name="totalRates"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="totalRates"
                  inputType="text"
                  label="How many total rates would you like to collect?"
                  labelClassName="block mb-1.5"
                  placeholder="Enter total number of rate for collect"
                  className="form_control"
                  parentClassName="rounded-lg bg-white"
                  value={value ?? ''}
                  required={enableTruckFinding}
                  onFocus={() => {
                    setValue(
                      'totalRates',
                      value && value.trim() !== '0 rates'
                        ? value.replace(/,|rates?/g, '').trim()
                        : ''
                    );
                  }}
                  onChangeFunc={(e) => {
                    const val = e.target.value;
                    if (/^\d*$/.test(val)) onChange(val);
                  }}
                  onBlur={setTotalRates}
                  errorText={errors.totalRates ? true : false}
                />
              )}
            />

            <Controller
              name="acceptInboundCallDuration"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  inputName="acceptInboundCallDuration"
                  inputType="text"
                  label="For how many minutes should we accept inbound calls?"
                  labelClassName="block mb-1.5"
                  placeholder="Enter time of collect rates"
                  className="form_control min-w-[120px]"
                  parentClassName="rounded-lg bg-white"
                  value={value ?? ''}
                  required={enableTruckFinding && enableInboundTruckFinding}
                  onFocus={() => {
                    setValue(
                      'acceptInboundCallDuration',
                      value && value.trim() !== '0 minutes'
                        ? value.replace(/,|minutes?/g, '').trim()
                        : ''
                    );
                  }}
                  onChangeFunc={(e) => {
                    const val = e.target.value;
                    if (/^\d*$/.test(val)) onChange(val);
                  }}
                  onBlur={setAcceptInboundCallDuration}
                  errorText={errors.acceptInboundCallDuration ? true : false}
                />
              )}
            />
          </>
        )}
      </div>
    </CommonModal>
  );
};

export default TruckFindingAiModals;
