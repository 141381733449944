import { ArrowUpRight } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useMemo, useState } from 'react';
import TableCmp from 'src/components/TableCmp';

import CustomPagination from '../../../../components/CustomPagination';
import NotFoundUI from '../../../../components/NotFoundUI';

import TeamPerformanceDetailTable from './TeamPerformanceDetailTable';
import TeamPerformanceLoadingRow from './TeamPerformanceLoadingRow';
import TeamPerformanceRow from './TeamPerformanceRow';

interface IProps {
  teamPerformanceData: any;
  isDataLoading: boolean;
  activeFilter: string;
  userList: any;
  dataObj: any;
  setSelectedUser: any;
  selectedTeam?: any;
  selectedUser: string | null;
}

const recordsPerPage = 9;

const AnalyticsTeamPerformanceTable = ({
  selectedUser,
  setSelectedUser,
  userList,
  teamPerformanceData,
  isDataLoading,
}: IProps) => {
  const [filteredTeamPerformanceList, setFilteredTeamPerformanceList] =
    useState<any[]>([...teamPerformanceData]);
  const [sortData, setSortData] = useState({
    sortField: 'name',
    sortType: 'asc',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [isShowGraphPopup, setIsShowGraphPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageItem, setPageItem] = useState<any[]>([]);
  const headCells = useMemo(
    () => [
      {
        id: 'firstName',
        name: 'User',
        rowClassName: '',
        sortable: true,
      },
      {
        id: 'totalRequest',
        name: 'Total QR',
        rowClassName: 'w-32 ',
        sortable: true,
      },
      {
        id: 'wonLoss',
        name: `Win/Loss`,
        rowClassName: 'w-28 text-center text-grayLight600 ',
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    setFilteredTeamPerformanceList([...teamPerformanceData]);
  }, [teamPerformanceData]);

  const handleSorting = () => {
    const { sortField, sortType } = sortData;

    const sortedCustomerList = filteredTeamPerformanceList.sort(
      (a: any, b: any) => {
        if (sortField === 'name') {
          const nameA = a.firstName.toUpperCase();
          const nameB = b.firstName.toUpperCase();

          if (sortType === 'asc') {
            return nameA.localeCompare(nameB);
          }

          return nameB.localeCompare(nameA);
        } else {
          if (a[sortField] < b[sortField]) {
            return sortType === 'asc' ? -1 : 1;
          }

          if (a[sortField] > b[sortField]) {
            return sortType === 'asc' ? 1 : -1;
          }
        }

        return 0;
      }
    );

    setFilteredTeamPerformanceList([...sortedCustomerList]);
  };

  useEffect(() => {
    setPageItem([]);

    if (filteredTeamPerformanceList.length > recordsPerPage) {
      const pageItems = filteredTeamPerformanceList?.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
      );
      setPageItem([...pageItems]);
    } else {
      setPageItem([...filteredTeamPerformanceList]);
    }
  }, [filteredTeamPerformanceList, currentPage, recordsPerPage]);

  useEffect(() => {
    handleSorting();
  }, [sortData]);

  return (
    <>
      <div className="flex-1 flex flex-col">
        <div className="w-full p-4 justify-between items-center flex flex-wrap gap-2">
          <div className="gap-1 flex flex-col">
            <div className="text-grayLight900 text-base font-semibold leading-7">
              Team Performance
            </div>
          </div>

          {selectedUser === 'all' && !isLoading && (
            <div className="gap-4 flex flex-col">
              <div
                className="flex text-xs items-center text-primary font-semibold cursor-pointer"
                onClick={() => setIsShowGraphPopup(true)}
              >
                View All <ArrowUpRight className="h-4 w-4" />
              </div>
            </div>
          )}
        </div>
        <div className="w-full bg-white border-t border-gray100 overflow-x-auto custom-scrollbar scrollbar-hide">
          <TableCmp
            params={sortData}
            setParams={setSortData}
            headCells={headCells}
            tableDataArr={isLoading ? [] : pageItem}
            TableRowCmp={TeamPerformanceRow}
            TableLoaderRowCmp={TeamPerformanceLoadingRow}
            tableHeaderClass=""
            isTableDataLoading={isLoading}
            numberOfSkeletonRows={recordsPerPage + 1}
            tableRowCmpProps={{
              // currentFilterType: currentFilterType,
              userList: userList,
              setSelectedUser: setSelectedUser,
            }}
          />
        </div>

        {!isLoading && !filteredTeamPerformanceList.length && (
          <NotFoundUI
            title="No Team Performance Found"
            desc="There are no data for team member performance related data."
            containerClassName="my-0 flex-1"
          />
        )}
      </div>

      <div className="w-full">
        {!isLoading && (
          <CustomPagination
            recordsPerPage={recordsPerPage}
            totalRecords={filteredTeamPerformanceList.length}
            currentPage={currentPage}
            handlePagination={setCurrentPage}
            nextLabelText=""
            prevLabelText=""
            isShowArrowAlways={true}
          />
        )}
      </div>

      {isShowGraphPopup && (
        <TeamPerformanceDetailTable
          isLoading={isLoading}
          filteredTeamPerformanceList={teamPerformanceData}
          handleClose={() => setIsShowGraphPopup(false)}
          // currentFilterType={currentFilterType}
        />
      )}
    </>
  );
};

export default AnalyticsTeamPerformanceTable;
