import axios from 'axios';
import { createRoute, getRoute } from 'src/services/RouteService';

/**
 * Fetches route information or calculates it if not found, then saves it
 * @param locationData Origin and destination coordinates
 * @param addressData Full address information for both locations
 * @param setRouteDistance State setter function for route distance
 * @param setIsRouteDistanceLoading State setter function for loading state
 * @returns Promise resolving to route distance information
 */

export const calculateRouteDistance = async (
  startPoint: { lat: number; lng: number },
  endPoint: { lat: number; lng: number }
): Promise<{
  distanceMiles: number;
  distanceFormatted: string;
  durationSeconds: number;
  durationFormatted: string;
  success: boolean;
  error?: string;
}> => {
  try {
    const mapboxAccessToken = window.MAPBOX_ACCESSTOKEN;

    if (!mapboxAccessToken) {
      return {
        distanceMiles: 0,
        distanceFormatted: '0.00 mi',
        durationSeconds: 0,
        durationFormatted: '0 min',
        success: false,
        error: 'Mapbox access token not configured',
      };
    }

    const response = await axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${startPoint.lng},${startPoint.lat};${endPoint.lng},${endPoint.lat}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxAccessToken}`
    );

    if (
      !response.data ||
      !response.data.routes ||
      response.data.routes.length === 0
    ) {
      return {
        distanceMiles: 0,
        distanceFormatted: '0.00 mi',
        durationSeconds: 0,
        durationFormatted: '0 min',
        success: false,
        error: 'No routes found',
      };
    }

    const route = response.data.routes[0];

    const distanceMiles = parseFloat((route.distance / 1609.344).toFixed(2));
    const durationSeconds = Math.round(route.duration);

    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const durationFormatted =
      hours > 0 ? `${hours} hr ${minutes} min` : `${minutes} min`;

    return {
      distanceMiles,
      distanceFormatted: `${distanceMiles.toFixed(2)} mi`,
      durationSeconds,
      durationFormatted,
      success: true,
    };
  } catch (error) {
    console.error('Error calculating route distance:', error);

    return {
      distanceMiles: 0,
      distanceFormatted: '0.00 mi',
      durationSeconds: 0,
      durationFormatted: '0 min',
      success: false,
      error: 'Failed to calculate route distance',
    };
  }
};

export const fetchOrCalculateRoute = async (
  locationData: {
    originLatitude: number;
    originLongitude: number;
    destinationLatitude: number;
    destinationLongitude: number;
  },
  addressData: {
    fromFullAddress: {
      fullAddress?: string;
      postal?: string;
      city?: string;
      country?: string;
      latitude: number;
      longitude: number;
    };
    toFullAddress: {
      fullAddress?: string;
      postal?: string;
      city?: string;
      country?: string;
      latitude: number;
      longitude: number;
    };
  },
  setRouteDistance: (distance: number) => void,
  setIsRouteDistanceLoading: (loading: boolean) => void
): Promise<{ distanceMiles: number; durationSeconds?: number }> => {
  setIsRouteDistanceLoading(true);

  try {
    const response = await getRoute(locationData);

    if (response && response.data) {
      const { distance } = response.data;
      const { miles } = distance;
      setRouteDistance(miles);
      setIsRouteDistanceLoading(false);

      return { distanceMiles: miles };
    }

    throw new Error('No data returned from getRoute');
  } catch (error: any) {
    if (error.response?.status === 404) {
      setRouteDistance(0);

      try {
        const calculatedRoute = await calculateRouteDistance(
          {
            lat: locationData.originLatitude,
            lng: locationData.originLongitude,
          },
          {
            lat: locationData.destinationLatitude,
            lng: locationData.destinationLongitude,
          }
        );

        setRouteDistance(calculatedRoute.distanceMiles);

        if (calculatedRoute.distanceMiles > 0) {
          await createRoute({
            originAddress: addressData.fromFullAddress?.fullAddress,
            originPostalCode: addressData.fromFullAddress?.postal,
            originCity: addressData.fromFullAddress?.city,
            originCountry: addressData.fromFullAddress?.country,
            originLat: addressData.fromFullAddress?.latitude,
            originLng: addressData.fromFullAddress?.longitude,
            destinationAddress: addressData.toFullAddress?.fullAddress,
            destinationPostalCode: addressData.toFullAddress?.postal,
            destinationCity: addressData.toFullAddress?.city,
            destinationCountry: addressData.toFullAddress?.country,
            destinationLat: addressData.toFullAddress?.latitude,
            destinationLng: addressData.toFullAddress?.longitude,
            distanceMiles: calculatedRoute.distanceMiles,
            durationSeconds: calculatedRoute.durationSeconds,
          });
        }

        return calculatedRoute;
      } catch (calcError) {
        console.error('Error calculating route distance', calcError);
        setRouteDistance(0);

        return { distanceMiles: 0 };
      }
    } else {
      console.error('Error fetching route', error);
      setRouteDistance(0);

      return { distanceMiles: 0 };
    }
  } finally {
    setIsRouteDistanceLoading(false);
  }
};
