import React from 'react';

const CustomerSplitTableRowLoader = () => (
  <>
    <td className="px-5 py-4 first:w-80 first:min-w-80 first:max-w-80 text-pretty">
      <div className="flex items-center gap-2 ">
        <div className="table-profile-customer-circle-xl rounded-md custom-loading">
          ts{' '}
        </div>
        <span className="truncate block max-w-48  text-sm whitespace-nowrap font-semibold custom-loading">
          Sun-Rype Products a division of A. Lassonde USA
        </span>
      </div>
    </td>
    <td className="px-5 py-4 w-64 min-w-64 max-w-64">
      <div className="flex items-center min-w-92 ">
        <div className="table-profile-user-circle-sm custom-loading">WA</div>
        <div className="pl-2 ">
          <h6 className="text-grayLight900 font-medium leading-tight max-w-52 truncate custom-loading">
            Usman Malik
          </h6>
        </div>
      </div>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">200</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">$220.22</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">33.16%</span>
    </td>
    <td className="px-5 py-4">
      <span className="custom-loading">100.00%</span>
    </td>
  </>
);

export default CustomerSplitTableRowLoader;
