import { Star06, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import ActionTooltip from '../ActionTooltip';
import ErrorMsg from '../errorMsg';

import { IInputTextProps } from './InputText.interface';

const InputText = ({
  inputName = '',
  placeholder = '',
  errorText = '',
  asTextarea = '',
  className = '',
  inputWrapClass,
  regularExp = '',
  label = '',
  tabIndex,
  maxLength,
  icon = '',
  iconLeft,
  labelIconRight,
  min,
  max,
  disabled,
  inputType = 'text',
  minLength,
  onChangeFunc = () => void 0,
  onBlur = () => void 0,
  onFocus,
  required,
  requiredAfter,
  readOnly = false,
  rows,
  value,
  labelClassName = '',
  parentClassName,
  handleKeyDown,
  isClearable,
  isError,
  isLoading,
  autoFocus,
  searchIconClass,
  isShowAiStar,
  inputMainClassName,
  requiredClassName,
  shouldFormatNumber = false,
  inputValueUnitType = '',
  labelWrapClass,
  mask,
  isShowDisableClass = true,
}: IInputTextProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const regExpArray: any = {
    // number: /^\d+(\.\d+)?$|^\.\d+$/,
    number: /^\d+(\.\d+)?$/,
    letters: /^[A-za-z]+$/,
    alphanumeric: /^[ A-Za-z0-9_@.]*$/,
    regularExp: regularExp,
  };

  const removeLeadingZeros = (input: any) => {
    if (typeof input === 'string') {
      return input.replace(/^0+(?=\d)/, '').trim() || '';
    }

    return input;
  };

  const typeArr = ['text', 'email', 'password', 'file', 'textarea', 'number'];

  const HandleChange = (e: any) => {
    const testValueType: any = regularExp === '' ? inputType : 'regularExp';

    if (inputType === 'number') {
      const isValidNumber = /^-?\d*\.?\d*$/.test(e.target.value);

      if (!isValidNumber && e.target.value !== '') {
        return;
      }
    }

    const valueTypeMatch =
      testValueType !== '' && !typeArr.includes(testValueType)
        ? regExpArray[testValueType].test(e.target.value)
        : true;

    if (e.target.value === '' || valueTypeMatch) {
      onChangeFunc(e);
    }
  };

  const handleInputKeyDown = (e: any) => {
    if (inputType === 'number' && ['e', 'E', '+', '-'].includes(e.key)) {
      e.preventDefault();
    }

    if (handleKeyDown) {
      handleKeyDown(e);
    }
  };

  const clearInput = () => {
    onChangeFunc({ target: { name: inputName, value: '' } });
  };

  const handleFocus = (e: any) => {
    setIsFocused(true);
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: any) => {
    setIsFocused(false);
    const formattedNumber = removeLeadingZeros(value) || '';

    if (shouldFormatNumber) {
      onChangeFunc({
        target: { value: formattedNumber, name: e?.target?.name },
      });
    }
    if (onBlur) onBlur(e);
  };

  const formatNumber = (num: string | number | null | undefined) => {
    if (num === null || num === undefined || num === '') return '';
    const numericValue = Number(num);
    if (isNaN(numericValue)) return '';

    return new Intl.NumberFormat('en-US').format(numericValue);
  };

  return (
    <>
      <div
        className={`${
          errorText && typeof errorText !== 'boolean' ? ' mb-2' : ''
        } ${parentClassName}`}
      >
        <div className={`${inputMainClassName}`}>
          <div
            className={`${
              labelIconRight ? 'flex justify-between' : ''
            } ${labelWrapClass}`}
          >
            {label && (
              <label className={`form_label ${labelClassName}`}>
                {label}
                {required && (
                  <span
                    className={`text-red-600 ms-[2px] leading-4 text-xs ${requiredClassName}`}
                  >
                    *
                  </span>
                )}
              </label>
            )}
            {labelIconRight && labelIconRight}
          </div>
          {requiredAfter && (
            <span className="text-red-600 ms-[2px] leading-4 text-xs">*</span>
          )}
          {asTextarea === 'textarea' ? (
            <textarea
              name={inputName}
              className={`form_control block resize-none custom-scrollbar-v2 ${className} ${
                errorText && 'is-invalid'
              } ${isError ? 'text-utilityRed500 border-red-600' : ''}`}
              placeholder={placeholder}
              onChange={HandleChange}
              tabIndex={tabIndex}
              rows={rows || 5}
              maxLength={maxLength}
              minLength={minLength}
              disabled={disabled}
              value={value}
              autoComplete="off"
              required={required || requiredAfter}
              onKeyDown={handleInputKeyDown}
            />
          ) : (
            <div
              className={`relative flex justify-center items-center ${inputWrapClass} ${
                isLoading ? 'custom-loading' : ''
              } ${!isError && isShowAiStar ? 'ai-gradient-border-wrap' : ''}`}
            >
              {iconLeft && iconLeft}
              {isShowAiStar && (
                <div className="absolute top-1/2 -translate-y-1/2 left-2 w-4 h-4 mt-px">
                  <ActionTooltip
                    message={'Detected with AI'}
                    isOpenChildTopPosOnTop={true}
                    tooltipPosition="center"
                    parentClassName="min-w-[120px]"
                    openOnHover={true}
                  >
                    <Star06 className="w-4 h-4 text-primary " />
                  </ActionTooltip>
                </div>
              )}

              {mask ? (
                <InputMask
                  mask={mask}
                  maskPlaceholder={null}
                  name={inputName}
                  className={`form_control ${className} ${
                    errorText && 'is-invalid'
                  } ${
                    isError
                      ? 'text-utilityRed500 border-red-600 hover:border-red-600'
                      : ''
                  } ${isShowAiStar ? 'pl-7' : ''} ${
                    isShowDisableClass
                      ? 'disabled:border-borderPrimary disabled:bg-gray50 disabled:text-gray500 [&:disabled>svg]:hidden disabled-input disabled:opacity-100'
                      : ''
                  }`}
                  placeholder={placeholder}
                  onChange={HandleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  tabIndex={tabIndex}
                  min={min}
                  maxLength={maxLength}
                  minLength={minLength}
                  max={max}
                  autoFocus={autoFocus}
                  value={
                    shouldFormatNumber && !isFocused
                      ? formatNumber(value as string)
                        ? `${formatNumber(
                            value as string
                          )} ${inputValueUnitType}`
                        : ''
                      : value
                  }
                  disabled={disabled}
                  autoComplete="off"
                  required={required || requiredAfter}
                  readOnly={readOnly}
                  onKeyDown={handleInputKeyDown}
                />
              ) : (
                <input
                  type={shouldFormatNumber && !isFocused ? 'text' : inputType}
                  name={inputName}
                  className={`form_control !outline-0 outline-none ${className} ${
                    errorText && 'is-invalid'
                  } ${
                    isError
                      ? 'text-utilityRed500 border-red-600 hover:border-red-600'
                      : ''
                  } ${isShowAiStar ? 'pl-7' : ''} ${
                    isShowDisableClass
                      ? 'disabled:border-borderPrimary disabled:bg-gray50 disabled:text-gray500 [&:disabled>svg]:hidden disabled-input disabled:opacity-100'
                      : ''
                  } ${readOnly ? 'pointer-events-none' : ''}`}
                  placeholder={placeholder}
                  onChange={HandleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  tabIndex={tabIndex}
                  min={min}
                  maxLength={maxLength}
                  minLength={minLength}
                  max={max}
                  autoFocus={autoFocus}
                  value={
                    shouldFormatNumber && !isFocused
                      ? formatNumber(value as string)
                        ? `${formatNumber(
                            value as string
                          )} ${inputValueUnitType}`
                        : ''
                      : value
                  }
                  disabled={disabled}
                  autoComplete="off"
                  required={required || requiredAfter}
                  readOnly={readOnly}
                  onKeyDown={handleInputKeyDown}
                />
              )}

              {icon && icon}
              {isClearable && value !== '' && (
                <XClose
                  id="clear"
                  className={`h-4 w-4 cursor-pointer absolute right-2 clear ${searchIconClass}`}
                  onClick={clearInput}
                />
              )}
            </div>
          )}
        </div>
        {errorText && typeof errorText !== 'boolean' && (
          <ErrorMsg errorText={errorText} />
        )}
      </div>
    </>
  );
};

export default InputText;
