import { API } from '../constants/api';

import { axiosInterceptor } from './interceptors';

export const getPopulationData = async (data: any) => {
  const response: any = await axiosInterceptor.post(API.GEOGRAPHY.GET, data);

  return response;
};

export const setPricingDetail = async (data: any) => {
  const response: any = await axiosInterceptor.post(
    API.GEOGRAPHY.SET_PRICTIVE_PRICING,
    data
  );

  return response;
};

export const getTopCarriers = async (params: any = {}, signal?: any) => {
  const response: any = await axiosInterceptor.get(API.GEOGRAPHY.TOP_CARRIERS, {
    params,
    signal,
  });

  return response;
};

export const getLaneHistory = async (data: any) => {
  const response: any = await axiosInterceptor.post(
    API.GEOGRAPHY.GET_LANE_HISTORY,
    data
  );

  return response;
};
