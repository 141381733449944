import { CheckCircle } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
// import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';

const LeftSidebarLoading = () => (
  <>
    {[...Array(5)].map((_, index) => (
      <div
        key={index}
        className="rounded-lg border border-utilityGray200 bg-white cursor-pointer shadow-none hover:shadow-lgc overflow-hidden "
      >
        <div className="px-3 py-2 space-y-1.5 border-b border-dashed border-utilityGray200">
          <div className="flex items-center justify-between flex-wrap gap-x-2.5">
            <p className="text-primary text-sm font-medium truncate underline cursor-pointer custom-loading">
              #WAL-TC3B-465
            </p>
            <div className="flex items-center gap-x-3 flex-none custom-loading">
              <ButtonCmp
                className="btn-link-custom"
                icon={<CheckCircle className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>

              <ButtonCmp
                className="btn-link-custom"
                icon={<CheckCircle className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>

              <ButtonCmp
                className="btn-link-custom"
                icon={<CheckCircle className="w-4 h-4" />}
              >
                <></>
              </ButtonCmp>
            </div>
          </div>
          <StatusDotProfileCmp
            parentClassName="custom-loading"
            dotColor="green"
            label={'Picking up on'}
          />
        </div>
        <div className="flex items-center px-3 py-2 gap-x-2.5">
          <div className="flex-1 flex gap-x-2.5 truncate custom-loading">
            1050 Rue De la Gauchetière O, Montréal, QC H3B 4C9, Canada
          </div>
        </div>
        <div className="border-t border-utilityGray200 bg-gray50 p-3 flex items-center flex-wrap gap-1.5">
          <div className="flex gap-x-2.5 truncate w-fit custom-loading">
            1050 Rue De la
          </div>
        </div>
      </div>
    ))}
  </>
);

export default LeftSidebarLoading;
