import React from 'react';

interface FreightStatisticsDetail {
  from: string;
  to: string;
  no_of_loads: number;
  order_weight: number;
  no_of_customers: number;
  total_gross_profit: number;
  gp_weight: number;
}

interface IProps {
  data: FreightStatisticsDetail;
}

const FreightStatisticsDetailModalRow: React.FC<IProps> = ({ data }) => (
  <>
    <td className="px-4 py-3">{data.from}</td>
    <td className="px-4 py-3">{data.to}</td>
    <td className="px-4 py-3">{data.no_of_loads}</td>
    <td className="px-4 py-3">
      <p
        className={`min-h-8 flex items-center font-medium ${
          data.order_weight < 0
            ? 'text-fgErrorPrimary'
            : 'text-fgSuccessPrimary'
        }`}
      >
        {data.order_weight < 0 ? '' : '+'}
        {data.order_weight.toLocaleString()}%
      </p>
    </td>
    <td className="px-4 py-3">{data.no_of_customers}</td>
    <td className="px-4 py-3">${data.total_gross_profit.toLocaleString()}</td>
    <td className="px-4 py-3">
      <span
        className={`font-medium ${
          data.gp_weight < 0 ? 'text-fgErrorPrimary' : 'text-fgSuccessPrimary'
        }`}
      >
        {data.gp_weight < 0 ? '' : '+'}
        {data.gp_weight}%
      </span>
    </td>
  </>
);

export default FreightStatisticsDetailModalRow;
