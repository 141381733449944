import { Call, Device } from '@twilio/voice-sdk';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from 'src/context/AuthContext';
import {
  setAdminJoiningACall,
  setBargeWhisperCall,
  setBargeWhisperNumber,
  setDevice,
  setFromNumberContact,
  setIncomingCall,
  setToken,
} from 'src/redux/CallCenter.slice';
import { RootState } from 'src/redux/store';

import ActiveCallModal from './ActiveCallModal';
import BargeWhisperModal from './BargeWhisperModal';
import IncomingCallModal from './IncomingCallModal';
import InProgressCall from './InProgressCall';
import OutboundModal from './OutboundModal';
// import OutboundModal from './OutboundModal';
// import OutboundModal from './OutboundModal';

interface TokenResponse {
  token: string;
}

const CallCenterModals = () => {
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);

  const {
    // device,
    incomingCall,
    isCallInProgress,
    outgoingCall,
    isBargeWhisper,
    adminJoiningACall,
    bargeWhisperNumber,
  } = useSelector((state: RootState) => state.CallCenter);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const initializeDevice = (token: string) => {
    // if (device) {
    //   // Unregister if the device is already registered
    //   if (device._state === 'registered') {
    //     device.unregister();
    //   }
    // }

    const newDevice = new Device(token, {
      disableAudioContextSounds: false,
    });

    newDevice.register();

    dispatch(setDevice(newDevice));

    newDevice.on('ready', () => {
      console.log('Device ready for incoming calls');
    });

    newDevice.on('error', (error: any) => {
      console.error('Twilio Device Error:', error);
      // setErrorMessage(error.message);
    });

    newDevice.on('incoming', (call: Call) => {
      console.log('1. Incoming Call:', call);
      console.log('2. call?.parameters', call?.parameters);
      console.log('3. incomingCall?.parameters.To', call?.parameters?.To);

      if (
        call?.parameters.To === `client:${currentUser?.phone}` &&
        bargeWhisperNumber === currentUser.phone
      ) {
        console.log('should not go hear');

        dispatch(setBargeWhisperNumber(null));

        dispatch(setBargeWhisperCall(call));
        dispatch(setAdminJoiningACall(!adminJoiningACall));

        return call.accept();
      }
      console.log('4. Accept call');

      dispatch(setIncomingCall(call));

      // if (call != null) {
      //   setAnotherIncomingCall(call);
      // } else {
      //   setIncomingCall(call);
      // }

      // console.log('5. conference details get successfully');
    });

    // device.register();
  };

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await axios.post<TokenResponse>(
          `${window.API_URL}/twilio/get-token`,
          { identity: currentUser?.phone || 'device' }
        );
        const token = response.data.token;
        dispatch(setToken(token));

        // Initialize Twilio Device
        initializeDevice(response.data.token);
      } catch (err) {
        // setErrorMessage('Error fetching token. Please try again.');
        console.error('Error fetching token:', err);
      }
    };

    fetchToken();

    // return () => {
    //   if (device) {
    //     device.destroy();
    //   }
    // };
  }, []);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.on('classifyCaller', (contact: any) => {
        console.log('hear 🚀 ~ contact:', contact);
        dispatch(setFromNumberContact(contact));
      });
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('classifyCaller');
      }
    };
  }, [isSocketConnected]);

  return (
    <>
      {incomingCall && !isCallInProgress && <IncomingCallModal />}
      {isCallInProgress && <InProgressCall />}
      {outgoingCall && !isCallInProgress && <OutboundModal />}
      {isBargeWhisper && !isCallInProgress && <BargeWhisperModal />}
      {false && isBargeWhisper && !isCallInProgress && <ActiveCallModal />}
    </>
  );
};

export default CallCenterModals;
