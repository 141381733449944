import { CheckSquareBroken, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useState } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import ButtonCmp from 'src/components/ButtonCmp';
import TooltipCmp from 'src/components/TooltipCmp';
import {
  SetDisconnectIntegration,
  SetMarkOutlookAsPrimary,
} from 'src/services/IntegrationService';
import { getShortName } from 'src/utils/CommonFunctions';

interface IProps {
  outlookAccountData: any;
  setIsRefresh: any;
  totalLinkedAccounts: number;
}

const OutlookAccountCard = ({
  outlookAccountData,
  setIsRefresh,
  totalLinkedAccounts,
}: IProps) => {
  const [isDisconnectLoading, setIsDisconnectLoading] = useState(false);
  const [isSetPrimaryLoading, setIsSetPrimaryLoading] = useState(false);

  const handleDisconnect = async (connectedId: string) => {
    setIsDisconnectLoading(true);

    await SetDisconnectIntegration({
      tokenId: connectedId,
    }).finally(() => {
      setIsDisconnectLoading(false);
      setIsRefresh(true);
    });
  };

  const handleSetAsPrimary = async (outlookAccountUserId: any) => {
    setIsSetPrimaryLoading(true);

    await SetMarkOutlookAsPrimary({
      tokenUserId: outlookAccountUserId,
    }).finally(() => {
      setIsSetPrimaryLoading(false);
      setIsRefresh(true);
    });
  };

  return (
    <div className="w-1/2">
      <div className="rounded-lg border border-utilityGray200 px-3 py-5 flex items-center gap-x-3">
        <div className="flex-none">
          {/* <img
            src={defaultImage}
            className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none object-contain"
          /> */}
          <div className="w-8 h-8 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-sm">
            {getShortName(outlookAccountData.name)}
          </div>
        </div>
        <div className="flex-1 overflow-hidden space-y-1">
          <div className="leading-[1]">
            <h6 className="text-grayLight900 text-sm font-medium inline align-middle mr-1">
              {outlookAccountData.name}
            </h6>
            {!!outlookAccountData.isPrimary && (
              <BadgeCmp style="modern" type="success">
                primary
              </BadgeCmp>
            )}
          </div>
          <p className="text-grayLight600 text-xs font-normal truncate">
            {outlookAccountData.userName}
          </p>
        </div>
        {!outlookAccountData.isPrimary && (
          <TooltipCmp message={'Set to Primary'}>
            <ButtonCmp
              onClick={() => handleSetAsPrimary(outlookAccountData.userId)}
              loading={isSetPrimaryLoading}
              className="btn-link-secondary"
            >
              {!isSetPrimaryLoading && (
                <CheckSquareBroken className="w-4 h-4 text-grayLight900" />
              )}
            </ButtonCmp>
          </TooltipCmp>
        )}
        <TooltipCmp
          message={
            outlookAccountData.isPrimary && totalLinkedAccounts > 1
              ? 'You can not disable primary outlook account'
              : 'Disconnect'
          }
        >
          <ButtonCmp
            onClick={() => handleDisconnect(outlookAccountData.id)}
            loading={isDisconnectLoading}
            className="btn-link-secondary"
            disabled={!!outlookAccountData.isPrimary && totalLinkedAccounts > 1}
          >
            {!isDisconnectLoading && (
              <Trash01 className="w-4 h-4 text-fgErrorPrimary" />
            )}
          </ButtonCmp>
        </TooltipCmp>
      </div>
    </div>
  );
};

export default OutlookAccountCard;
