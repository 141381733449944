import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { TABLE_IDS, USER_ROLE } from 'src/constants/common';
import { AuthContext } from 'src/context/AuthContext';
import { BasicContext } from 'src/context/BasicContext';
import WalToast from 'src/utils/WalToast';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import DateRangePicker from '../../../components/DateRangePicker';
import PageSectionLayout from '../../../components/PageSectionLayout';
import Header from '../../../components/PageSectionLayout/Header/Header';
import SelectBox from '../../../components/SelectBox/SelectBox';
import TabButton from '../../../components/TabButton';
import {
  getTeamPerformanceList,
  getSalesAnalyticsData,
  getSalesData,
  getTeamList,
  getTotalGrossProfitChartData,
} from '../../../services/SalesDashboard';
import {
  getDateRange,
  getSelectBoxOptions,
  isValidJSON,
  useRolePermission,
} from '../../../utils/CommonFunctions';

import CustomerSplit from './CustomerSplit';
import CustomerSplitAsideTable from './CustomerSplitTable';
import FreightStatisticsTable from './FreightStatisticsTable';
import { FilterArray, bookMarginArray } from './OverView.interface';
import SalesCardBox from './SalesCardBox';
import TeamPerformanceTable from './TeamPerformanceTable';
import TotalGrossProfitChart from './TotalGrossProfitChart';

/* eslint-disable max-lines-per-function */
const OverView = () => {
  const storedParamsString = localStorage.getItem(TABLE_IDS.SALES_DASHBOARD);
  const storedParams = isValidJSON(storedParamsString)
    ? JSON.parse(storedParamsString!)
    : undefined;
  const { hasRoleV2 } = useRolePermission();

  // CONTEXTS & REDUX
  const { currentUser } = useContext(AuthContext);
  const { appearance } = useContext(BasicContext);

  const {
    allTimeStartDateActual,
    ordersUpdateSocketTriggeredCount,
    setOrdersUpdateSocketTriggeredCount,
  } = useContext(BasicContext);

  // FILTERS
  const [selectedTeam, setSelectedTeam] = useState(
    () => storedParams?.team ?? 'all'
  );
  const [selectedUser, setSelectedUser] = useState(
    () => storedParams?.member ?? 'all'
  );
  const [activeFilter, setActiveFilter] = useState(
    () => storedParams?.activeFilter ?? 'daily'
  );
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>(
    () => storedParams?.activeFilter ?? 'custom'
  );
  const [startDate, setStartDate] = useState<any>(
    () => storedParams?.startDate ?? ''
  );
  const [endDate, setEndDate] = useState<any>(
    () => storedParams?.endDate ?? ''
  );
  const [currentFilterType, setCurrentFilterType] = useState(
    () => storedParams?.order ?? 'projected'
  );

  const [allTimeStartDate, setAllTimeStartDate] = useState<any>('');
  const [filterAfterPrevNext, setFilterAfterPrevNext] = useState('daily');
  const [isPrevYearDisable, setIsPrevYearDisable] = useState<any>(false);

  // FILTERS API Data & OPTIONS
  const [userList, setUserList] = useState<any[]>([]);
  const [teamListOptions, setTeamListOptions] = useState<any[]>([]);

  // CHART OPTIONS & Others state
  const [boxLabel, setBoxLabel] = useState('Yesterday');

  // API Data
  const [salesData, setSalesData] = useState<any>({});
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [teamPerformanceData, setTeamPerformanceData] = useState<any>([]);
  const [totalGrossProfitChartData, setTotalGrossProfitChartData] = useState(
    []
  );

  // API Loaders
  const [isSalesLoading, setIsSalesLoading] = useState<boolean>(true);
  const [isMarginGraphLoading, setIsMarginGraphLoading] = useState(true);
  const [isSalesAnalyticsLoading, setIsSalesAnalyticsLoading] = useState(false);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);
  const [isSalesGraphLoading, setIsSalesGraphLoading] = useState<boolean>(true);

  const [isRefresh, setIsRefresh] = useState(false);

  useEffect(() => {
    const startOfLastYear = moment().subtract(1, 'years').startOf('year');
    const providedDate = moment(allTimeStartDateActual);

    // for user if start of allTimeStartDateActual is less than last year's first date then not letting user go further than last year's first date
    if (hasRoleV2('user') && providedDate.isBefore(startOfLastYear)) {
      setAllTimeStartDate(startOfLastYear.format('YYYY-MM-DD'));
    } else {
      setAllTimeStartDate(allTimeStartDateActual);
    }
  }, [allTimeStartDateActual]);

  useEffect(() => {
    setFilterAfterPrevNext(activeFilter);

    if (activeFilter !== '') {
      const { start, end } = getDateRange(activeFilter);
      setStartDate(start);
      setEndDate(end);
      setIsPrevYearDisable(false);
    }

    if (activeFilter === 'weekly') {
      setBoxLabel('Last Week');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'monthly') {
      setBoxLabel('Last Month');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'yearly') {
      setBoxLabel('Last Year');
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'daily') {
      setBoxLabel('Yesterday');

      setSelectedTimeRange('custom');
    }
  }, [activeFilter]);

  useEffect(() => {
    if (ordersUpdateSocketTriggeredCount) {
      setIsRefresh(false);
      setTimeout(() => {
        setIsRefresh(true);
      }, 0);
    }
  }, [ordersUpdateSocketTriggeredCount]);

  useEffect(() => {
    setIsRefresh(false);
    setTimeout(() => {
      setOrdersUpdateSocketTriggeredCount(false);
      setIsRefresh(true);
    }, 0);
  }, [startDate, endDate, currentFilterType, selectedUser, selectedTeam]);

  useEffect(() => {
    if (!isSalesLoading && !isSalesAnalyticsLoading && !isMarginGraphLoading) {
      setIsRefresh(false);
      setIsAllDataLoading(false);
      setTimeout(() => {
        setOrdersUpdateSocketTriggeredCount(false);
      }, 1000);
    } else {
      setIsAllDataLoading(true);
    }
  }, [isSalesLoading, isSalesAnalyticsLoading, isMarginGraphLoading]);

  useEffect(() => {
    if (!isRefresh) {
      return;
    }

    const data = {
      order: currentFilterType,
      team: selectedTeam,
      member: selectedUser,
      startDate:
        selectedTimeRange === 'custom'
          ? moment(startDate).local().format('YYYY-MM-DD')
          : moment(startDate).format('YYYY-MM-DD'),
      endDate:
        selectedTimeRange === 'custom'
          ? moment(endDate).local().format('YYYY-MM-DD')
          : moment(endDate).format('YYYY-MM-DD'),
    };

    const localArr = {
      ...data,
      ...{
        selectedTimeRange: selectedTimeRange,
        activeFilter: filterAfterPrevNext !== 'custom' ? activeFilter : '',
      },
    };

    localStorage.setItem(TABLE_IDS.SALES_DASHBOARD, JSON.stringify(localArr));
    const salesDataController = new AbortController();
    const salesDataSignal = salesDataController.signal;

    if (!!startDate && !!endDate && !!selectedUser) {
      // Label updates based on time range
      if (selectedTimeRange === 'all_time') {
        setBoxLabel('Previous period');
      } else if (selectedTimeRange === 'quarterly') {
        setBoxLabel('last quarter');
      } else if (activeFilter === '') {
        let days = moment(endDate).diff(moment(startDate), 'days');
        days =
          selectedTimeRange === 'last_30_days' ||
          selectedTimeRange === 'last_90_days' ||
          selectedTimeRange === 'last_7_days' ||
          selectedTimeRange === 'custom'
            ? days + 1
            : days;

        setBoxLabel(`Previous ${days} days`);
      }

      // Set all loading states to true initially
      setIsSalesLoading(true);
      setIsSalesAnalyticsLoading(true);
      setIsSalesGraphLoading(true);

      // API call for Customer split
      getSalesData(
        { ...data, type: activeFilter },
        salesDataSignal,
        !ordersUpdateSocketTriggeredCount
      )
        .then((response: any) => {
          setCustomerList(response.customer);
          setIsSalesLoading(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsSalesLoading(false);

          if (e.response?.data.message) {
            WalToast.error(e.response.data.message, '');
          }
        });

      // API call for Customer split
      getTotalGrossProfitChartData(
        { ...data, type: activeFilter },
        salesDataSignal,
        !ordersUpdateSocketTriggeredCount
      )
        .then((response: any) => {
          setTotalGrossProfitChartData(response.data);
          setIsSalesGraphLoading(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsSalesGraphLoading(false);

          if (e.response?.data.message) {
            WalToast.error(e.response.data.message, '');
          }
        });

      // API call for Sales Analytics Data
      getSalesAnalyticsData(
        {
          ...data,
          type:
            moment(startDate).isSame(moment(endDate), 'day') && !activeFilter
              ? 'daily'
              : activeFilter,
        },
        salesDataSignal,
        !ordersUpdateSocketTriggeredCount
      )
        .then((response: any) => {
          setSalesData(response);

          if (activeFilter === 'daily') {
            const yesterday = moment().subtract(1, 'days').startOf('day');
            if (
              moment(
                response?.averageDailyMargin?.previousDataStartDate
              ).isSame(yesterday, 'day') &&
              moment(response?.averageDailyMargin?.previousDataEndDate).isSame(
                yesterday,
                'day'
              ) &&
              moment(startDate).isSame(moment(endDate), 'day') &&
              moment(startDate).isSame(moment(), 'day') &&
              moment(endDate).isSame(moment(), 'day')
            )
              setBoxLabel(`Yesterday`);
            else
              setBoxLabel(
                moment(
                  response?.averageDailyMargin?.previousDataStartDate
                ).format('MMM Do, YYYY')
              );
          }

          setIsSalesAnalyticsLoading(false);
        })
        .catch((e) => {
          if (e.code === 'ERR_CANCELED') {
            return;
          }
          setIsSalesAnalyticsLoading(false);

          if (e.response?.data.message) {
            WalToast.error(e.response.data.message, '');
          }
        });

      // API call for Team Performance List
      if (
        currentUser?.role?.name === USER_ROLE.ADMIN ||
        currentUser?.role?.name === USER_ROLE.MANAGER
      ) {
        setIsMarginGraphLoading(true);
        getTeamPerformanceList(
          data,
          salesDataSignal,
          !ordersUpdateSocketTriggeredCount
        )
          .then((response) => {
            setTeamPerformanceData(response.data);
            setIsMarginGraphLoading(false);
          })
          .catch((e) => {
            if (e.code === 'ERR_CANCELED') {
              return;
            }
            setIsMarginGraphLoading(false);

            if (e.response?.data.message) {
              WalToast.error(e.response.data.message, '');
            }
          });
      } else {
        setIsMarginGraphLoading(false);
      }
    }

    return () => {
      salesDataController.abort();
    };
  }, [isRefresh]);

  useEffect(() => {
    if (teamListOptions && teamListOptions?.length > 0) {
      let uniqueArr = [];

      if (selectedTeam === 'all') {
        let allTeamMembers: any = [];
        const allTeamMembersMultiple = teamListOptions.map(
          (tlo) => tlo.members
        );
        allTeamMembers = Object.values(allTeamMembersMultiple).flat();
        uniqueArr = _.uniqBy(allTeamMembers, 'id');
        setSelectedUser('all');
      } else {
        let allTeamMembers: any = [];
        allTeamMembers = teamListOptions.find(
          (tlo) => tlo.value === selectedTeam
        );
        uniqueArr = _.uniqBy(allTeamMembers?.members, 'id');
      }

      const updatedListAllMembers = getSelectBoxOptions(
        uniqueArr,
        'id',
        'firstName',
        true,
        'imageUrl',
        'image',
        undefined,
        'lastName',
        true
      );
      setUserList(updatedListAllMembers);
    }
  }, [selectedTeam, teamListOptions]);

  useEffect(() => {
    getTeamList({ onlySales: true })
      .then((response: any) => {
        const teamData = getSelectBoxOptions(
          response.data,
          'id',
          'name',
          true,
          'iconUrl',
          'icon',
          'members',
          null,
          true,
          TeamIcon1
        );
        const allTeamMembersObj: any = [];
        response.data.forEach((teamRec: any) => {
          allTeamMembersObj.push(...teamRec.members);
        });

        setTeamListOptions(teamData);
        // setSelectedTeam('all');
        // setSelectedUser('all');
      })
      .catch(console.error);
  }, []);

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      const twoYearsAgo = moment().subtract(1, 'years').startOf('year');
      const daysDiff = moment(end).diff(moment(start), 'days') + 1;
      const prevMonth = moment(start).subtract(daysDiff, 'day');

      if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'days')) {
        setIsPrevYearDisable(true);
      } else {
        setIsPrevYearDisable(false);
      }

      // setIsNextYearDisable(false);
      // if (moment(end).format("DD-MM-YYYY") >= moment().format("DD-MM-YYYY")) {
      //     setIsNextYearDisable(true);
      // }

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  const rightSideContent = (
    <div className="flex flex-row xxl:gap-4 gap-3 justify-between w-full sm:items-center ">
      <TabButton
        className="flex-1 lg:max-w-[none] sm:max-w-28 w-full flex justify-center items-center"
        tabArray={bookMarginArray}
        isActive={currentFilterType}
        activeClassName="bg-primary text-white"
        parentClassName="w-full "
        tabParentClassName="w-full justify-end"
        handleOnClick={(e: any) => {
          setCurrentFilterType(e?.target?.dataset?.value);
        }}
      />
    </div>
  );

  const getFilterRange = (type?: string) => {
    setFilterAfterPrevNext(activeFilter);
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    moment.tz.setDefault(appearance?.timezone ?? browserTimezone);

    var startDT = new Date();
    var endDT = new Date();
    const currentYear = new Date().getFullYear();
    const twoYearsAgo = moment().subtract(1, 'years').startOf('year');

    if (type === 'prev' && !isPrevYearDisable) {
      // setIsNextYearDisable(false);
      if (activeFilter === 'yearly') {
        startDT = moment(startDate).subtract(1, 'year').toDate();
        endDT = moment(endDate).subtract(1, 'year').toDate();
        const prevMonth = moment(moment(startDate, 'YYYY'))
          .subtract(1, 'year')
          .format('YYYY');

        if (hasRoleV2('user') && currentYear - parseInt(prevMonth) >= 1) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        startDT = moment(endDate).subtract(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).subtract(1, 'week').isoWeekday(6).toDate();
        const prevMonth = moment(startDate).subtract(1, 'week');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'week')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate)
          .subtract(1, 'months')
          .startOf('month')
          .toDate();
        endDT = moment(startDate).subtract(1, 'months').endOf('month').toDate();
        const prevMonth = moment(startDate).subtract(1, 'months');

        if (
          hasRoleV2('user') &&
          prevMonth.isSameOrBefore(twoYearsAgo, 'month')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).subtract(1, 'days').toDate();
        endDT = moment(endDate).subtract(1, 'days').toDate();
        const prevMonth = moment(startDate).subtract(1, 'day');

        if (hasRoleV2('user') && prevMonth.isSameOrBefore(twoYearsAgo, 'day')) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).subtract(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).subtract(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          const prevMonth = moment(startDate).subtract(1, 'quarters');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'quarters')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).subtract(1, 'year').toDate();
          endDT = moment(startDate).subtract(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate)
            .clone()
            .subtract(daysDiff, 'days')
            .toDate();
          endDT = moment(endDate).clone().subtract(daysDiff, 'days').toDate();

          const daysDiffN = moment(endDT).diff(moment(startDT), 'days') + 1;
          const prevMonth = moment(startDT).subtract(daysDiffN, 'day');

          if (
            hasRoleV2('user') &&
            prevMonth.isSameOrBefore(twoYearsAgo, 'days')
          ) {
            setIsPrevYearDisable(true);
          } else {
            setIsPrevYearDisable(false);
          }
        }
      }
      setStartDate(startDT);
      setEndDate(endDT);
    } else if (type === 'next') {
      setIsPrevYearDisable(false);

      if (activeFilter === 'yearly') {
        startDT = moment(startDate).add(1, 'year').toDate();
        endDT = moment(endDate).add(1, 'year').toDate();

        if (currentYear !== parseInt(moment(startDT).format('YYYY'))) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'weekly') {
        startDT = moment(endDate).add(1, 'week').isoWeekday(0).toDate();
        endDT = moment(endDate).add(1, 'week').isoWeekday(6).toDate();
        const startOfCurrentWeek = moment()
          .startOf('isoWeek')
          .isoWeekday(0)
          .toDate();
        const endOfCurrentWeek = moment()
          .endOf('isoWeek')
          .isoWeekday(6)
          .toDate();

        if (
          !(startDT >= startOfCurrentWeek && startDT <= endOfCurrentWeek) &&
          !(endDT >= startOfCurrentWeek && endDT <= endOfCurrentWeek)
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'monthly') {
        startDT = moment(startDate).add(1, 'months').startOf('month').toDate();
        endDT = moment(endDate).add(1, 'months').endOf('month').toDate();

        if (moment().format('MM-YYYY') !== moment(startDT).format('MM-YYYY')) {
          setFilterAfterPrevNext('custom');
        }
      } else if (activeFilter === 'daily') {
        startDT = moment(startDate).add(1, 'days').toDate();
        endDT = moment(endDate).add(1, 'days').toDate();

        if (
          moment().format('DD-MM-YYYY') !== moment(startDT).format('DD-MM-YYYY')
        ) {
          setFilterAfterPrevNext('custom');
        }
      } else {
        if (selectedTimeRange === 'quarterly') {
          startDT = moment(
            moment(startDate).add(1, 'quarters').startOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
          endDT = moment(
            moment(startDate).add(1, 'quarters').endOf('quarter'),
            'YYYY-MM-DD'
          ).toDate();
        } else if (selectedTimeRange === 'last_year') {
          startDT = moment(startDate).add(1, 'year').toDate();
          endDT = moment(startDate).add(1, 'year').toDate();
        } else {
          const daysDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
          startDT = moment(startDate).clone().add(daysDiff, 'days').toDate();
          endDT = moment(endDate).clone().add(daysDiff, 'days').toDate();
        }
      }
      // if (moment(endDT, "DD-MM-YYYY").isSameOrAfter(moment(moment().format("DD-MM-YYYY"), "DD-MM-YYYY"))) {
      //     setIsNextYearDisable(true);
      // }
      setStartDate(startDT);
      setEndDate(endDT);
    } else {
      if (!isPrevYearDisable) {
        const { start, end } = getDateRange(activeFilter);
        const daysDiff = moment(end).diff(moment(start), 'days') + 1;
        const prevMonth = moment(start).subtract(daysDiff, 'day');

        if (
          hasRoleV2('user') &&
          prevMonth?.isSameOrBefore(twoYearsAgo, 'day')
        ) {
          setIsPrevYearDisable(true);
        } else {
          setIsPrevYearDisable(false);
        }
        startDT = start ?? new Date();
        endDT = end ?? new Date();
        setStartDate(startDT);
        setEndDate(endDT);
      }
    }
  };

  return (
    <>
      <PageSectionLayout
        header={
          <Header
            topContent={<></>}
            title="Sales Overview"
            desc="Your current sales performance and activity."
            rightSideContent={rightSideContent}
            mainContainerClassName="!flex-row "
            leftClassName="!w-auto"
            rightClassName="xxl:!w-[30%] xlm:!w-[32%] xl:!w-[27%] sm:!w-auto !w-full"
          />
        }
      >
        <div className="h-full w-full xxl:gap-4 xl:gap-3 gap-3 flex flex-col">
          <div
            className={`w-full justify-between sm:items-center flex  xls:flex-row flex-col flex-wrap xxl:gap-4 xl:gap-3 gap-3 `}
          >
            <div
              className={`flex-1 flex sm:flex-row flex-col flex-wrap xxl:gap-4 gap-3 xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] xls:w-[calc(50%_-_6px)] w-full`}
            >
              <TabButton
                className="leading-4 sm:w-auto w-1/4 flex-grow !px-1 xxl:!text-xs xl:!text-[11px] xls:!text-[10px] !text-[11px]"
                activeClassName="!bg-utilityGray100"
                tabArray={FilterArray}
                parentClassName="w-full"
                tabParentClassName="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full"
                isActive={filterAfterPrevNext !== 'custom' ? activeFilter : ''}
                handleOnClick={(e: any) => {
                  if (activeFilter === e.target.dataset.value) getFilterRange();
                  setActiveFilter(e.target.dataset.value);
                }}
              />
              <div
                className="xxl:min-w-[calc(33.33%_-_11px)] xl:min-w-[calc(33.33%_-_8px)] sm:min-w-[calc(50%_-_6px)] min-w-full items-center cursor-pointer justify-between flex"
                key={`datepicker_${startDate}_${endDate}`}
              >
                {hasRoleV2('user') ? (
                  <DateRangePicker
                    handleDatePickerChange={(dates: any, type?: string) =>
                      handleDatePickerChange(dates, type)
                    }
                    selectedTimeRange={selectedTimeRange}
                    startDate={startDate}
                    endDate={endDate}
                    allTimeStartDate={allTimeStartDate}
                    showYearDropdown={false}
                    isToday={activeFilter === 'daily'}
                    minDate={
                      hasRoleV2('user')
                        ? moment().subtract(1, 'years').startOf('year').toDate()
                        : ''
                    }
                    isShowDropDownIcon={false}
                    containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
                    parentClassName="sales-datepicker"
                    isPrevDisabled={isPrevYearDisable}
                    onPrevNextClick={(type: string) => getFilterRange(type)}
                    setTodayAsDefaultOnClear={true}
                  />
                ) : (
                  <DateRangePicker
                    handleDatePickerChange={(dates: any, type?: string) =>
                      handleDatePickerChange(dates, type)
                    }
                    selectedTimeRange={selectedTimeRange}
                    startDate={startDate}
                    endDate={endDate}
                    allTimeStartDate={allTimeStartDate}
                    showYearDropdown={false}
                    isToday={activeFilter === 'daily'}
                    isShowDropDownIcon={false}
                    containerClassName="xls:left-0 xls:right-[unset] lg:left-[unset] lg:right-0 left-0"
                    parentClassName="sales-datepicker"
                    isPrevDisabled={isPrevYearDisable}
                    onPrevNextClick={(type: string) => getFilterRange(type)}
                    setTodayAsDefaultOnClear={true}
                  />
                )}
              </div>
            </div>

            <div className="flex sm:flex-row  flex-wrap xxl:gap-4 gap-3 sm:justify-end xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xls:w-[calc(50%_-_6px)] w-full empty:hidden ">
              <>
                <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                  <SelectBox
                    name="teamListGroup"
                    id="teamListGroup"
                    className="form_control"
                    size="sm"
                    placeholder="All Teams"
                    noOptionMessage="No Teams Found"
                    isClearable={true}
                    isSearchable={true}
                    options={teamListOptions}
                    onChangeFunc={(event: any) => {
                      setSelectedTeam(event?.value ?? 'all');
                      setSelectedUser('all');
                    }}
                    value={teamListOptions.filter(
                      (val: any) => selectedTeam === val.value
                    )}
                  />
                </div>
                <div className="xxl:w-[calc(50%_-_8px)] xl:w-[calc(50%_-_6px)] sms:w-[calc(50%_-_6px)] w-full">
                  <SelectBox
                    name="userListGroup"
                    id="userListGroup"
                    size="sm"
                    placeholder="All Users"
                    noOptionMessage="No Users Found"
                    isSearchable={true}
                    className="form_control"
                    isClearable={true}
                    options={userList}
                    onChangeFunc={(event: any) => {
                      setSelectedUser(event?.value ?? 'all');
                    }}
                    value={userList.filter(
                      (user: any) => selectedUser === user.value
                    )}
                  />
                </div>
              </>
            </div>
          </div>
          <div className="flex flex-wrap xxl:gap-4 gap-3">
            <div className="xxl:w-[calc(70%_-_16px)] xlm:w-[calc(68%_-_12px)] xl:w-[calc(73%_-_12px)] w-full flex flex-col xxl:gap-3 gap-3">
              <div className="w-full flex flex-wrap xxl:gap-3 gap-3 sm:flex-row flex-col">
                <SalesCardBox
                  salesData={salesData}
                  currentFilterType={currentFilterType}
                  boxLabel={boxLabel}
                  startDate={startDate}
                  endDate={endDate}
                  teamId={selectedTeam}
                  userId={selectedUser}
                  isDataLoading={
                    ordersUpdateSocketTriggeredCount ? false : isAllDataLoading
                  }
                />
              </div>

              <TotalGrossProfitChart
                totalGrossProfitChartData={totalGrossProfitChartData}
                isAllDataLoading={isAllDataLoading}
                isSalesGraphLoading={isSalesGraphLoading}
                ordersUpdateSocketTriggeredCount={
                  ordersUpdateSocketTriggeredCount
                }
              />
            </div>

            {(hasRoleV2('admin') || hasRoleV2('manager')) && (
              <div className="w-full h-auto flex flex-col justify-between xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xxl:gap-3 gap-3">
                <div className="bg-white rounded-xl border border-utilityGray200 shadow flex-1 flex flex-col xl:h-[750px] xl:min-h-[750px] xl:max-h-[750px]">
                  <TeamPerformanceTable
                    activeFilter={activeFilter}
                    currentFilterType={currentFilterType}
                    teamPerformanceData={teamPerformanceData}
                    isDataLoading={
                      ordersUpdateSocketTriggeredCount
                        ? false
                        : isAllDataLoading
                    }
                    userList={userList}
                    setSelectedUser={setSelectedUser}
                    selectedUser={selectedUser}
                    selectedTeam={selectedTeam}
                    dataObj={{
                      startDate: startDate,
                      endDate: endDate,
                      user: selectedUser,
                      team: selectedTeam,
                    }}
                  />
                </div>
              </div>
            )}

            {hasRoleV2(USER_ROLE.USER) && (
              <div className="w-full h-auto flex flex-col justify-between xxl:w-[30%] xlm:w-[32%] xl:w-[27%] xxl:gap-3 gap-3">
                <div className="bg-white rounded-xl border border-utilityGray200 shadow flex-1 flex flex-col xl:h-[727px] xl:max-h-[727px] xl:min-h-[727px] overflow-hidden">
                  <CustomerSplitAsideTable
                    customerList={customerList}
                    isDataLoading={
                      ordersUpdateSocketTriggeredCount
                        ? false
                        : isAllDataLoading
                    }
                  />
                </div>
              </div>
            )}
          </div>

          <FreightStatisticsTable
            regionalAnalyticsData={salesData?.regionalAnalyticsData ?? []}
            isDataLoading={
              ordersUpdateSocketTriggeredCount ? false : isAllDataLoading
            }
          />

          {(hasRoleV2('admin') || hasRoleV2('manager')) && (
            <div className="w-full bg-white rounded-xl border border-utilityGray200 shadow flex">
              <CustomerSplit
                customerList={customerList}
                isDataLoading={
                  ordersUpdateSocketTriggeredCount ? false : isAllDataLoading
                }
              />
            </div>
          )}
        </div>
      </PageSectionLayout>
    </>
  );
};

export default OverView;
