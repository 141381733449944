import { File02 } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import { iconMap } from 'src/constants/common';

interface IProps {
  handleClose: any;
  services: Array<{
    name: string;
    description: string;
  }>;
  title?: string;
  titleDesc?: string;
}

const AdditionalServiceModal = ({
  handleClose,
  services,
  title,
  titleDesc,
}: IProps) => (
  <CommonModal
    title={title || 'Additional Services '}
    titleDesc={
      titleDesc ||
      'Explore key details of additional pickup and delivery services'
    }
    handleClose={() => handleClose(false)}
    headerIcon={<File02 />}
    size={'max-w-[810px]'}
    isOverflow={true}
    modalClassName=""
  >
    <div className="p-5 ">
      <ul className="[&>*]:px-1.5 [&>*]:py-2 -mx-1.5 -my-2 flex flex-wrap">
        {services.map((service, index) => (
          <li key={`service_${index}`} className="w-1/2">
            <div className="rounded-lg border border-utilityGray200 bg-white shadow-xs px-3 py-4 space-y-2 h-full">
              <div className="flex items-center gap-1.5">
                {iconMap[service.name]?.icon && (
                  <>
                    {React.createElement(
                      iconMap[service.name].icon,
                      iconMap[service.name]?.className
                        ? {
                            className: iconMap[service.name].className,
                          }
                        : {}
                    )}
                  </>
                )}
                <h6 className="text-grayLight900 text-sm font-semibold">
                  {service.name}
                </h6>
              </div>
              <p className="text-grayLight600 text-xs font-normal">
                {service.description}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  </CommonModal>
);

export default AdditionalServiceModal;
