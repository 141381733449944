import { yupResolver } from '@hookform/resolvers/yup';
import { Calendar } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { QuoteType } from 'src/app/QuotingHub/QuotingDashboard/Quote/PostToLoadBoard/LoadBoard.interface';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import DateTimePicker from 'src/components/DateTimePicker';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { DATE_FORMAT } from 'src/constants/common';
import { QUOTE_CLASSIFICATION } from 'src/constants/common';
import {
  getLoadLinkVehicleAttribute,
  getLoadLinkVehicleSize,
  getLoadLinkVehicleType,
} from 'src/services/LoadLinkService';
import { getQuote } from 'src/services/QuoteService';
import {
  getDateWithSuffixFormat,
  setNumberWithCommas,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

enum AddressDetails {
  ORIGIN = 'Origin',
  DESTINATION = 'destination',
}

enum EquipmentDetails {
  EQUIPMENTTYPES = 'equipmentTypes',
  ADDITIONAL = 'additional',
}

const addressDetailArr = [
  {
    value: AddressDetails.ORIGIN,
    name: 'Origin',
  },
  {
    value: AddressDetails.DESTINATION,
    name: 'Destination',
  },
];

const equipmentDetailArr = [
  {
    value: EquipmentDetails.EQUIPMENTTYPES,
    name: 'Equipment Types',
  },
  {
    value: EquipmentDetails.ADDITIONAL,
    name: 'Additional Services',
  },
];

const radiusOptions = [
  {
    label: '50 Miles',
    value: 50,
  },
  {
    label: '100 Miles',
    value: 100,
  },
  {
    label: '150 Miles',
    value: 150,
  },
  {
    label: '200 Miles',
    value: 200,
  },
];

function containsWholeMatchForEquipmentType(str: string) {
  const strParts = str
    ? str.split(',').map((item) => item.trim().toLowerCase())
    : [];

  return ['van', 'dry van', 'reefer', 'straight truck'].some((item) =>
    strParts.includes(item.toLowerCase())
  );
}

const TruckFind = ({ id }: any) => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const [addressDetail, setAddressDetail] = useState('Origin');
  const [equipmentDetail, setEquipmentDetail] = useState<any>(
    EquipmentDetails.EQUIPMENTTYPES
  );
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('Primary');
  const [isRefresh, setIsRefresh] = useState(true);
  const [addressDetails, setAddressDetails] = useState<any>({});
  const [loadLinkPostingDetail, setLoadLinkPostingDetail] = useState<
    any | undefined
  >(undefined);
  const [loadLinkPosts, setLoadLinkPosts] = useState<any[]>([]);
  const [activePostIndex, setActivePostIndex] = useState<number | any>(null);
  const [isUpdateLoadPost, setIsUpdateLoadPost] = useState<boolean>(false);
  const [quoteType, setQuoteType] = useState<QuoteType | boolean>(false);
  const [validatePostToLoadBoardForm, setValidatePostToLoadBoardForm] =
    useState(false);
  const [vehicleSizeOptions, setVehicleSizeOptions] = useState<any[]>([]);

  const [isVehicleTypeLoading, setIsVehicleTypeLoading] = useState(true);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<any[]>([]);

  const [isVehicleAttrLoading, setIsVehicleAttrLoading] = useState(true);
  const [vehicleAttrOptions, setVehicleAttrOptions] = useState<any[]>([]);
  const [vehicleAttributeFullNames, setVehicleAttributeFullNames] = useState<
    any[]
  >([]);

  const [window, setWindow] = useState(0);
  const [pickupTime, setPickupTime] = useState('');

  const [primaryEquipmentType, setPrimaryEquipmentType] = useState('');
  const [primaryEquipmentTypeFullName, setPrimaryEquipmentTypeFullName] =
    useState('');

  const [secondaryEquipmentType, setSecondaryEquipmentType] = useState<any[]>(
    []
  );
  const [secondaryEquipmentTypeFullName, setSecondaryEquipmentTypeFullName] =
    useState<any[]>([]);
  const specialInstructionMaxLength = 17;

  const loadPostingSchema = yup.object().shape({
    totalWeight: yup.string().required('Total weight is required.'),
    commodity: yup.string(),
    linearFootage: yup
      .string()
      .when([], (field, schema) =>
        addressDetails?.classification === QUOTE_CLASSIFICATION.LTL
          ? schema.required('Linear footage is required')
          : schema
      ),
    sourceState: yup.string().required('Origin state is required'),
    sourceCity: yup.string().required('Origin city is required'),
    sourceRadius: yup.number().required('Source radius is required.'),
    destinationState: yup.string().required('Destination state is required'),
    destinationCity: yup.string().required('Destination city is required'),
    destinationRadius: yup.number().required('Destination radius is required.'),
    vehicleAttribute: yup.array(),
    specialInstructions: yup.string(),
  });

  const cancelApiCall = () => {
    abortControllerRef.current?.abort();
  };

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loadPostingSchema),
    defaultValues: {
      totalWeight:
        (loadLinkPostingDetail && loadLinkPostingDetail.totalWeight
          ? `${setNumberWithCommas(loadLinkPostingDetail.totalWeight)} lbs`
          : '') ||
        (addressDetails && addressDetails.totalWeight
          ? `${setNumberWithCommas(addressDetails.totalWeight)} lbs`
          : '') ||
        '',
      commodity: loadLinkPostingDetail?.commodity || '',
      linearFootage:
        (addressDetails?.classification === QUOTE_CLASSIFICATION.LTL &&
          (loadLinkPostingDetail && loadLinkPostingDetail.linearFootage
            ? `${setNumberWithCommas(loadLinkPostingDetail.linearFootage)} feet`
            : '')) ||
        (addressDetails && addressDetails.linearFootage
          ? `${setNumberWithCommas(addressDetails.linearFootage)} feet`
          : '') ||
        '',
      sourceState:
        loadLinkPostingDetail?.sourceState ||
        addressDetails?.shipperStateCode ||
        '',
      sourceCity:
        loadLinkPostingDetail?.sourceCity || addressDetails?.shipperCity || '',
      sourceRadius: loadLinkPostingDetail?.sourceRadius || 200,
      destinationState:
        loadLinkPostingDetail?.destinationState ||
        addressDetails?.consigneeStateCode ||
        '',
      destinationCity:
        loadLinkPostingDetail?.destinationCity ||
        addressDetails?.consigneeCity ||
        '',
      destinationRadius: loadLinkPostingDetail?.destinationRadius || 200,
      vehicleAttribute: loadLinkPostingDetail?.vehicleAttribute
        ? loadLinkPostingDetail?.vehicleAttribute?.split(',') || []
        : [],
      specialInstructions: loadLinkPostingDetail?.specialInstructions || '',
    },
  });

  useEffect(() => {
    if (addressDetails?.totalWeight) {
      setValue(
        'totalWeight',
        (loadLinkPostingDetail && loadLinkPostingDetail.totalWeight
          ? `${setNumberWithCommas(loadLinkPostingDetail.totalWeight)} lbs`
          : '') ||
          (addressDetails && addressDetails.totalWeight
            ? `${setNumberWithCommas(addressDetails.totalWeight)} lbs`
            : '') ||
          ''
      );
      setValue('commodity', loadLinkPostingDetail?.commodity || '');
      setValue(
        'linearFootage',
        (addressDetails?.classification === QUOTE_CLASSIFICATION.LTL &&
          (loadLinkPostingDetail && loadLinkPostingDetail.linearFootage
            ? `${setNumberWithCommas(loadLinkPostingDetail.linearFootage)} feet`
            : '')) ||
          (addressDetails && addressDetails.linearFootage
            ? `${setNumberWithCommas(addressDetails.linearFootage)} feet`
            : '') ||
          ''
      );

      setValue(
        'sourceState',
        loadLinkPostingDetail?.sourceState ||
          addressDetails?.shipperStateCode ||
          ''
      );
      setValue(
        'sourceCity',
        loadLinkPostingDetail?.sourceCity || addressDetails?.shipperCity || ''
      );
      setValue('sourceRadius', loadLinkPostingDetail?.sourceRadius || 200);

      setValue(
        'destinationState',
        loadLinkPostingDetail?.destinationState ||
          addressDetails?.consigneeStateCode ||
          ''
      );
      setValue(
        'destinationCity',
        loadLinkPostingDetail?.destinationCity ||
          addressDetails?.consigneeCity ||
          ''
      );
      setValue(
        'destinationRadius',
        loadLinkPostingDetail?.destinationRadius || 200
      );

      setValue(
        'vehicleAttribute',
        loadLinkPostingDetail?.vehicleAttribute
          ? loadLinkPostingDetail?.vehicleAttribute?.split(',') || []
          : []
      );

      setValue(
        'specialInstructions',
        loadLinkPostingDetail?.specialInstructions || ''
      );
    }
  }, [addressDetails, setValue]);

  const setTotalWeight = (e: any) => {
    setValue(
      'totalWeight',
      e.target.value ? `${setNumberWithCommas(e.target.value)} lbs` : ''
    );
  };

  const setLinearFootage = (e: any) => {
    setValue(
      'linearFootage',
      e.target.value ? `${setNumberWithCommas(e.target.value)} feet` : ''
    );
  };

  const handleSelectAddressDetails = (name: any) => (event: any) => {
    if (name === AddressDetails.ORIGIN) {
      setValue('sourceRadius', event.value);
    } else {
      setValue('destinationRadius', event.value);
    }
  };

  const getVehicleTypeOptions = () => {
    setIsVehicleTypeLoading(true);
    cancelApiCall();

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    getLoadLinkVehicleType(signal)
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleTypeOptions(updatedListVehicleTypes);
        const pEquipTypeFNames: any = updatedListVehicleTypes.find(
          (type: any) => primaryEquipmentType == type.value
        );
        if (pEquipTypeFNames)
          setPrimaryEquipmentTypeFullName(pEquipTypeFNames.label);

        const secEquipTypeFNames: any = updatedListVehicleTypes
          .filter((type: any) => secondaryEquipmentType.includes(type.value))
          .map((type: any) => type.label);
        setSecondaryEquipmentTypeFullName(secEquipTypeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Type Error ', e);
      })
      .finally(() => {
        setIsVehicleTypeLoading(false);
      });
  };

  const getVehicleAttributeOptions = () => {
    setIsVehicleAttrLoading(true);

    getLoadLinkVehicleAttribute()
      .then((response: any) => {
        const updatedListVehicleAttribute = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleAttrOptions(updatedListVehicleAttribute);
        const vehicleAttr: any = '';
        const vehicleAttributeFNames = updatedListVehicleAttribute
          .filter((type: any) => vehicleAttr.includes(type.value))
          .map((type: any) => type.label);
        setVehicleAttributeFullNames(vehicleAttributeFNames);
      })
      .catch((e) => {
        console.log('Vehicle Attr Error', e);
      })
      .finally(() => {
        setIsVehicleAttrLoading(false);
      });
  };

  const changeEquipmentType = (vehicleType: any) => (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      if (!primaryEquipmentType) {
        setPrimaryEquipmentType(vehicleType.value);
        setPrimaryEquipmentTypeFullName(vehicleType.label);
      } else {
        const secEquipmentTypes =
          secondaryEquipmentType.length > 0 ? [...secondaryEquipmentType] : [];
        let secEquipmentTypeFullName: any =
          secondaryEquipmentTypeFullName.length > 0
            ? [...secondaryEquipmentTypeFullName]
            : [];
        secEquipmentTypes.push(vehicleType.value);
        secEquipmentTypeFullName.push(vehicleType.label);
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    } else {
      if (primaryEquipmentType == vehicleType.value) {
        if (secondaryEquipmentType.length > 0) {
          setPrimaryEquipmentType(secondaryEquipmentType[0]);
          setPrimaryEquipmentTypeFullName(secondaryEquipmentTypeFullName[0]);
          setSecondaryEquipmentType(secondaryEquipmentType.slice(1));
          setSecondaryEquipmentTypeFullName(
            secondaryEquipmentTypeFullName.slice(1)
          );
        } else {
          setPrimaryEquipmentType('');
          setPrimaryEquipmentTypeFullName('');
        }
      } else {
        const secEquipmentTypes = secondaryEquipmentType.filter(
          (item) => item !== vehicleType.value
        );
        const secEquipmentTypeFullName = secondaryEquipmentTypeFullName.filter(
          (item) => item !== vehicleType.value
        );
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    }
  };

  const getVehicleSizeOptions = () => {
    getLoadLinkVehicleSize()
      .then((response: any) => {
        const updatedListVehicleTypes = response?.data.map((vt: any) => ({
          label: vt.detail,
          value: vt.code,
        }));
        setVehicleSizeOptions(updatedListVehicleTypes);
      })
      .catch((e: any) => {
        console.log('Vehicle Size Error', e);
      });
  };

  useEffect(() => {
    getVehicleTypeOptions();
    getVehicleAttributeOptions();
  }, []);

  useEffect(() => {
    getVehicleSizeOptions();
  }, []);

  useEffect(() => {
    if (!loadLinkPostingDetail) {
      setLoadLinkPostingDetail(loadLinkPosts[activePostIndex]);
    }
  }, [isUpdateLoadPost]);

  useEffect(() => {
    if (id) {
      if (isRefresh) {
        getQuote(id)
          .then((response: any) => {
            const responseData = response.data;

            if (responseData?.addressDetails) {
              setAddressDetails(responseData?.addressDetails);
            }

            setLoadLinkPosts(responseData.loadLinkPosts);

            if (responseData.loadLinkPosts.length > 0) {
              setLoadLinkPostingDetail(responseData.loadLinkPosts[0]);
              setActivePostIndex(0);
            }

            if (
              responseData?.addressDetails?.classification ===
                QUOTE_CLASSIFICATION.LTL &&
              responseData?.dimensions?.length > 0 &&
              (containsWholeMatchForEquipmentType(
                responseData?.addressDetails?.primaryEquipmentTypeFullName
              ) ||
                containsWholeMatchForEquipmentType(
                  responseData?.addressDetails?.secondaryEquipmentTypeFullNames
                ))
            ) {
              setQuoteType(QuoteType.WITH_RATE);
            } else {
              if (
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.FTL ||
                responseData?.addressDetails?.classification ===
                  QUOTE_CLASSIFICATION.VOLUME_LTL
              ) {
                setQuoteType(QuoteType.PREDICTIVE_PRICING);
              } else {
                setQuoteType(QuoteType.LOAD_BOARD);
              }
            }
          })
          .finally(() => setIsRefresh(false))
          .catch(console.error);
      }
    }
  }, [isRefresh]);

  console.log('setSelectedEquipmentType', setSelectedEquipmentType);
  console.log('primaryEquipmentTypeFullName', primaryEquipmentTypeFullName);
  console.log('vehicleSizeOptions', vehicleSizeOptions);
  console.log('setIsUpdateLoadPost', setIsUpdateLoadPost);
  console.log('validatePostToLoadBoardForm', validatePostToLoadBoardForm);
  console.log('quoteType', quoteType);

  return (
    <>
      <div className="p-5 bg-white flex flex-wrap gap-y-4 rounded-lg border border-utilityGray200 shadow-sm">
        {/* first row */}
        <div className="w-full">
          <div className="bg-gray25 p-4 xl:pb-3 border border-utilityGray200 shadow-xs rounded-xl h-full">
            <h5 className="text-grayLight900 mb-4 text-sm font-medium w-full min-h-[30px] flex items-center">
              Full Truckload Details
            </h5>
            <div className="-mx-1 flex flex-wrap gap-y-3">
              <div className="xl:w-[37%] px-1 relative xls:w-1/2 w-full">
                <label className="form_label block mb-1.5">
                  Availability Date
                  <span className="text-red-600 ms-[2px] leading-4 text-xs">
                    *
                  </span>
                </label>

                <DateTimePicker
                  inputName="availabilityDate"
                  className={`form_control`}
                  placeholder={DATE_FORMAT}
                  dateFormat="dd/MM/yyyy"
                  selected={moment().toDate()}
                  value={getDateWithSuffixFormat(moment().toNow())}
                  errorText={''}
                  onChangeFunc={() => {}}
                  icon={<Calendar className="h-5 w-5" />}
                  parentClassName="w-full"
                  pickupTime={pickupTime}
                  setPickupTime={setPickupTime}
                  window={window}
                  setWindow={setWindow}
                  availabilityDateSubmit={() => {}}
                  resetAvailabilityDate={() => {}}
                />

                {/* <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                {errors.availabilityDate && (
                  <ErrorMsg errorText={errors.availabilityDate.message} />
                )}
              </div> */}
              </div>
              <div className="xl:flex-1 xls:w-1/2 w-full px-1">
                <label className="form_label mb-1.5 block">
                  Total Weight (lbs)
                  <span className="text-red-600 leading-4">*</span>
                </label>
                <div className="form_control p-0 relative !h-auto">
                  <Controller
                    name="totalWeight"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="totalWeight"
                        inputType="text"
                        placeholder="Enter Total Weight"
                        labelClassName="mb-1.5 block"
                        className="!border-0 !h-auto"
                        value={value}
                        required={true}
                        onFocus={() => {
                          setValue(
                            'totalWeight',
                            value.replace(/,|lbs/g, '').trim()
                          );
                        }}
                        onChangeFunc={(e) => {
                          const val = e.target.value;

                          if (/^\d*\.?\d*$/.test(val)) {
                            onChange(val);
                          }
                        }}
                        onBlur={setTotalWeight}
                      />
                    )}
                  />
                </div>
                <div className="[&:empty]:hidden [&:empty]:mb-0 mb-5 ">
                  {errors.totalWeight && (
                    <ErrorMsg errorText={errors.totalWeight.message} />
                  )}
                </div>
              </div>

              <Controller
                name="commodity"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="commodity"
                    placeholder="Enter Commodity"
                    label="Commodity"
                    labelClassName="form_label block mb-1.5"
                    parentClassName="xl:flex-1 w-1/2 px-1"
                    id="commodity"
                    className="form_control"
                    value={value}
                    onChangeFunc={(e) => onChange(e.target.value)}
                    onBlur={(e) => onChange(e.target.value)}
                    required={false}
                  />
                )}
              />

              <Controller
                name="linearFootage"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputText
                    inputName="linearFootage"
                    placeholder="Enter Linear Footage"
                    label="Linear Footage"
                    labelClassName="form_label block mb-1.5"
                    parentClassName="xl:flex-1 w-1/2 px-1"
                    id="linearFootage"
                    className="form_control"
                    value={value}
                    onFocus={() => {
                      setValue(
                        'linearFootage',
                        value ? value.replace(/,|feet/g, '').trim() : ''
                      );
                    }}
                    onChangeFunc={(e) => {
                      const val = e.target.value;

                      if (/^\d*\.?\d*$/.test(val)) {
                        onChange(val);
                      }
                    }}
                    onBlur={setLinearFootage}
                    required={true}
                    errorText={
                      errors.linearFootage ? errors.linearFootage.message : null
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>

        {/* second row */}
        <div className="w-full">
          <div className="bg-gray25 p-4 border border-utilityGray200 shadow-xs rounded-xl">
            <div className="flex justify-between items-center mb-4 gap-2 w-full">
              <h5 className="text-grayLight900 text-sm font-medium">
                Address Details
              </h5>
              <TabButton
                className="!min-h-[30px]"
                activeClassName="!bg-utilityGray100"
                tabArray={addressDetailArr}
                isActive={addressDetail}
                handleOnClick={(e: any) => {
                  setAddressDetail(e?.target?.dataset?.value);
                }}
              />
            </div>

            <div className="flex flex-wrap -mx-1 xls:gap-y-8 gap-y-5">
              {addressDetail === AddressDetails.ORIGIN && (
                <>
                  <Controller
                    name="sourceState"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="sourceState"
                        label="State"
                        labelClassName="mb-1.5 block"
                        parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                        className="form_control"
                        value={value}
                        required={true}
                        onChangeFunc={(e) => onChange(e.target.value)}
                        onBlur={(e) => onChange(e.target.value)}
                        errorText={
                          errors.sourceState ? errors.sourceState.message : null
                        }
                      />
                    )}
                  />

                  <Controller
                    name="sourceCity"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="sourceCity"
                        label="City"
                        labelClassName="mb-1.5 block"
                        parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                        className="form_control"
                        value={value}
                        required={true}
                        onChangeFunc={(e) => onChange(e.target.value)}
                        onBlur={(e) => onChange(e.target.value)}
                        errorText={
                          errors.sourceCity ? errors.sourceCity.message : null
                        }
                      />
                    )}
                  />

                  <Controller
                    name="sourceRadius"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        required
                        label="Radius"
                        className={`form_control`}
                        labelClassName="form_label mb-1.5 block"
                        options={radiusOptions}
                        value={radiusOptions.filter(
                          (val: any) => getValues('sourceRadius') === val.value
                        )}
                        onChangeFunc={handleSelectAddressDetails(
                          AddressDetails.ORIGIN
                        )}
                        parentClassName="sm:flex-1 single-select-x-scroll px-1 "
                      />
                    )}
                  />
                </>
              )}

              {addressDetail === AddressDetails.DESTINATION && (
                <>
                  <Controller
                    name="destinationState"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="destinationState"
                        label="State"
                        labelClassName="mb-1.5 block"
                        parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                        className="form_control"
                        value={value}
                        required={true}
                        onChangeFunc={(e) => onChange(e.target.value)}
                        onBlur={(e) => onChange(e.target.value)}
                        errorText={
                          errors.destinationState
                            ? errors.destinationState.message
                            : null
                        }
                      />
                    )}
                  />

                  <Controller
                    name="destinationCity"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="destinationCity"
                        label="City"
                        labelClassName="mb-1.5 block"
                        parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                        className="form_control"
                        value={value}
                        required={true}
                        onChangeFunc={(e) => onChange(e.target.value)}
                        onBlur={(e) => onChange(e.target.value)}
                        errorText={
                          errors.destinationCity
                            ? errors.destinationCity.message
                            : null
                        }
                      />
                    )}
                  />

                  <Controller
                    name="destinationRadius"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        {...field}
                        required
                        label="Radius"
                        className={`form_control`}
                        labelClassName="form_label mb-1.5 block"
                        options={radiusOptions}
                        value={radiusOptions.filter(
                          (val: any) =>
                            getValues('destinationRadius') == val.value
                        )}
                        onChangeFunc={handleSelectAddressDetails(
                          AddressDetails.DESTINATION
                        )}
                        parentClassName="flex-1 single-select-x-scroll px-1"
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {/* third row */}
        <div
          className={`rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full`}
        >
          <div className="flex sm:flex-nowrap flex-wrap justify-between items-center gap-2 w-full pb-4 border-b border-utilityGray200">
            <h5 className="text-grayLight900 text-sm font-medium">
              {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES
                ? `Select ${selectedEquipmentType} Equipment Type`
                : `Additional Services`}
            </h5>
            <TabButton
              className="!min-h-[30px]"
              activeClassName="!bg-utilityGray100"
              tabArray={equipmentDetailArr}
              isActive={equipmentDetail}
              handleOnClick={(e: any) => {
                setEquipmentDetail(e?.target?.dataset?.value);
              }}
            />
          </div>
          {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES && (
            <>
              <div
                className={`flex flex-wrap pt-4 gap-y-3 ${
                  isVehicleTypeLoading ? 'custom-loading' : ''
                }`}
              >
                {vehicleTypeOptions.map((vehicleType: any, index: number) => (
                  <div
                    className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                    key={`equipment_type_${index}`}
                  >
                    <CheckBox
                      id={`vehicle_type_${vehicleType.value}`}
                      name="vehicleType"
                      checked={
                        primaryEquipmentType == vehicleType.value ||
                        secondaryEquipmentType.includes(vehicleType.value)
                      }
                      parentClassName={`!w-3.5 !h-3.5`}
                      value={vehicleType.value}
                      onChangeFunc={changeEquipmentType(vehicleType)}
                    />
                    <label
                      htmlFor={`vehicle_type_${vehicleType.value}`}
                      className={`text-textSecondary text-xs font-medium cursor-pointer`}
                    >
                      {vehicleType.label}&nbsp;
                      <span className="text-gray500 text-xs font-normal">
                        {primaryEquipmentType == vehicleType.value
                          ? '(Primary)'
                          : secondaryEquipmentType.includes(vehicleType.value)
                          ? '(Secondary)'
                          : ''}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </>
          )}
          {equipmentDetail === EquipmentDetails.ADDITIONAL && (
            <div
              className={`flex flex-wrap pt-4 gap-y-3 ${
                isVehicleAttrLoading ? 'custom-loading' : ''
              }`}
            >
              {vehicleAttrOptions.map(
                (vehicleAttribute: any, index: number) => (
                  <Controller
                    key={`vehicle_attribute_${index}`}
                    name="vehicleAttribute"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2 ">
                        <CheckBox
                          id={vehicleAttribute.value}
                          name="vehicleAttribute"
                          value={vehicleAttribute.value}
                          checked={value?.includes(vehicleAttribute.value)}
                          onChangeFunc={(event: any) => {
                            const checked = event.target.checked;
                            let vehicleAttributes = value ? [...value] : [];
                            let vehicleAttrFullNames = vehicleAttributeFullNames
                              ? [...vehicleAttributeFullNames]
                              : [];

                            if (checked) {
                              vehicleAttributes.push(vehicleAttribute.value);
                              vehicleAttrFullNames.push(vehicleAttribute.label);
                            } else {
                              vehicleAttributes = vehicleAttributes.filter(
                                (item) => item !== vehicleAttribute.value
                              );
                              vehicleAttrFullNames =
                                vehicleAttributeFullNames.filter(
                                  (item) => item !== vehicleAttribute.value
                                );
                            }
                            onChange(vehicleAttributes);
                            setVehicleAttributeFullNames(vehicleAttrFullNames);
                          }}
                        />
                        <label
                          htmlFor={vehicleAttribute.value}
                          className="text-textSecondary text-xs font-medium  cursor-pointer "
                        >
                          {vehicleAttribute.label}
                        </label>
                      </div>
                    )}
                  />
                )
              )}
            </div>
          )}
        </div>

        {/* fourth row */}
        <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full flex flex-col gap-3">
          <h5 className="text-grayLight900 text-sm font-medium w-full">
            Special Instructions
          </h5>

          <Controller
            name="specialInstructions"
            control={control}
            render={({ field: { onChange, value } }) => (
              <InputText
                inputName="specialInstructions"
                placeholder={'Add your special instructions'}
                asTextarea="textarea"
                parentClassName=""
                maxLength={specialInstructionMaxLength}
                rows={1}
                className="h-[38px]"
                value={value}
                onChangeFunc={(e) => onChange(e?.target?.value)}
              />
            )}
          />
        </div>

        <div className="text-end w-full">
          <ButtonCmp
            className="btn-outline-primary"
            onClick={() => setValidatePostToLoadBoardForm(true)}
          >
            Post to Load Board
          </ButtonCmp>
        </div>
      </div>
    </>
  );
};

export default TruckFind;
