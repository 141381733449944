import { User01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { setCurrentSalesRepHistory } from 'src/services/CustomerService';
import { listUser } from 'src/services/TeamMemberService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';

interface IProps {
  customerId: string;
  handleClose: any;
  setIsRefresh: any;
}

const AddAllocationHistoryModal = ({
  customerId,
  handleClose,
  setIsRefresh,
}: IProps) => {
  const [isUpdateSalesRepHistoryLoading, setIsUpdateSalesRepHistoryLoading] =
    useState(false);
  const [isSalesRepLoading, setIsSalesRepLoading] = useState(true);
  const [salesRepList, setSalesRepList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    setIsSalesRepLoading(true);

    listUser({ type: 'sales' })
      .then((response: any) => {
        const updatedListAllMembers = getSelectBoxOptions(
          response.data,
          'id',
          'firstName',
          true,
          'imageUrl',
          'image',
          undefined,
          'lastName',
          true
        );

        setSalesRepList([...updatedListAllMembers]);
        setIsSalesRepLoading(false);
      })
      .catch(console.log);
  }, []);

  const handleUpdateSalesHistory = () => {
    setIsUpdateSalesRepHistoryLoading(true);
    setCurrentSalesRepHistory(customerId, { salesRepId: selectedUser })
      .then(() => {
        // WalToast.success(response?.message);
        setIsRefresh(true);
        handleClose();
      })
      .finally(() => {
        setIsUpdateSalesRepHistoryLoading(false);
      })
      .catch(console.log);
  };

  return (
    <CommonModal
      title={'Update Sales Representative'}
      titleDesc={'Update and mange your sales representative'}
      handleClose={() => handleClose(false)}
      headerIcon={<User01 />}
      size={'xl:max-w-[647px] max-w-[551px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      primaryBtnText="Save Changes"
      primaryBtnOnClick={handleUpdateSalesHistory}
      primaryBtnLoading={isUpdateSalesRepHistoryLoading}
      primaryBtnDisabled={isUpdateSalesRepHistoryLoading || !selectedUser}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={isUpdateSalesRepHistoryLoading}
    >
      <div className="w-full p-5">
        <SelectBox
          name="userListGroup"
          label="Update Sales Representative"
          labelClassName="block mb-1.5 form_label"
          id="userListGroup"
          size="sm"
          placeholder="All Users"
          noOptionMessage="No Users Found"
          isSearchable={true}
          className="form_control"
          isClearable={true}
          options={salesRepList}
          isLoading={isSalesRepLoading}
          onChangeFunc={(event: any) => {
            setSelectedUser(event?.value ?? null);
          }}
          value={salesRepList.filter(
            (user: any) => selectedUser === user.value
          )}
        />
      </div>
    </CommonModal>
  );
};

export default AddAllocationHistoryModal;
