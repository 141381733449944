import {} from '@untitled-ui/icons-react/build/cjs';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthContext } from 'src/context/AuthContext';

import InternalChat from './InternalChat';
import InternalNotes from './InternalNotes';

interface IProps {
  claim?: any;
  dispute?: any;
  isClaim: any;
  notesCount: any;
  setNotesCount: any;
  isShowChatAndNotesSideBar: any;
  setIsShowChatAndNotesSideBar: any;
  chatWrapClassName?: string;
}

const InternalChatAndNotes = ({
  claim,
  dispute,
  isClaim,
  notesCount,
  setNotesCount,
  isShowChatAndNotesSideBar,
  setIsShowChatAndNotesSideBar,
  chatWrapClassName,
}: IProps) => {
  const { currentUser } = useContext(AuthContext);

  const [currentTab, setCurrentTab] = useState('messages');
  const [data, setData] = useState<any>([]);

  //For Internal Note
  const [internalNotes, setInternalNotes] = useState<any[]>([]);

  //For Chat
  const [typingUsers, setTypingUsers] = useState<string[]>([]);
  // when finalize chat and note flow, refactor this state and move up to chat component including socket listen event also
  const [messages, setMessages] = useState<any>([]);
  const [members, setMembers] = useState([]);
  const [lastMessageId, setLastMessageId] = useState(null);
  const [lastNoteId, setLastNoteId] = useState(null);

  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const typingTimeouts = new Map();

  useEffect(() => {
    if (claim) {
      setData(claim);
    }

    if (dispute) {
      setData(dispute);
    }
  }, [claim, dispute]);

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on && data.id && currentUser?.id) {
      socketIO.get(`/subscribe/${currentUser?.id}`, function (jwr: any) {
        if (jwr.error) {
          console.error('Subscription error:', jwr.error);

          return;
        }
      });

      let noteChannelName = isClaim
        ? `/subscribe/claim_notes_${data.id}`
        : `/subscribe/dispute_notes_${data.id}`;

      socketIO.get(noteChannelName, (details: any) => {
        console.log(`Joined room: ${JSON.stringify(details)}`);
      });

      const messageChannelName = isClaim
        ? `/subscribe/claim_${data.id}`
        : `/subscribe/dispute_${data.id}`;

      socketIO.get(messageChannelName, (details: any) => {
        console.log(`Joined room: ${JSON.stringify(details)}`);
      });

      socketIO.on('internalNotes', (notes: any) => {
        setInternalNotes((prevNotes: any) => [...prevNotes, notes]);
        setNotesCount((prevCount: number) => prevCount + 1);
        setLastNoteId(notes.id);
      });

      socketIO.on('userStatusUpdate', (userStatus: any) => {
        const userStatusElements = document.querySelectorAll(
          `.user-status-${userStatus.userId}`
        );

        if (userStatusElements.length > 0) {
          userStatusElements.forEach((element) => {
            element.classList.remove('bg-successSecondary', 'bg-danger500');

            if (userStatus.onlineStatus === 'online') {
              element.classList.add('bg-successSecondary');
            } else {
              element.classList.add('bg-danger500');
            }
          });
        }
      });

      socketIO.on('message', (data2: any) => {
        setTypingUsers((prevTypingUsers) => {
          const newTypingUsers = new Map(
            prevTypingUsers.map((user: any) => [user.id, user])
          );

          if (newTypingUsers.has(data2.sender.id)) {
            newTypingUsers.delete(data2.sender.id);

            if (typingTimeouts.has(data2.sender.id)) {
              clearTimeout(typingTimeouts.get(data2.sender.id));
              typingTimeouts.delete(data2.sender.id);
            }
          }

          return Array.from(newTypingUsers.values());
        });

        setMessages((prevMessages: any) => [...prevMessages, data2]);
        setLastMessageId(data2.id);
      });

      socketIO.on('userStatusUpdate', (userStatus: any) => {
        const userStatusElements = document.querySelectorAll(
          `.user-status-${userStatus.userId}`
        );

        if (userStatusElements.length > 0) {
          userStatusElements.forEach((element) => {
            element.classList.remove('bg-successSecondary', 'bg-danger500');

            if (userStatus.onlineStatus === 'online') {
              element.classList.add('bg-successSecondary');
            } else {
              element.classList.add('bg-danger500');
            }
          });
        }

        setMembers((prevMembers: any) =>
          prevMembers?.map((member: any) =>
            member.id === userStatus.userId
              ? { ...member, user_status: userStatus.onlineStatus }
              : member
          )
        );
      });

      socketIO.on('typing', (typingStatus: any) => {
        setTypingUsers((prevTypingUsers) => {
          const newTypingUsers = new Map(
            prevTypingUsers.map((user: any) => [user.id, user])
          );

          if (typingStatus.typing && typingStatus.user.id !== currentUser.id) {
            newTypingUsers.set(typingStatus.user.id, {
              ...typingStatus.user,
              lastTyped: Date.now(),
            });

            if (typingTimeouts.has(typingStatus.user.id)) {
              clearTimeout(typingTimeouts.get(typingStatus.user.id));
            }

            const timeoutId = setTimeout(() => {
              setTypingUsers((prevTypingUsers2) =>
                prevTypingUsers2.filter(
                  (user: any) => user.id !== typingStatus.user.id
                )
              );
              typingTimeouts.delete(typingStatus.user.id);
            }, 10000);

            typingTimeouts.set(typingStatus.user.id, timeoutId);
          }

          return Array.from(newTypingUsers.values());
        });
      });

      // Send typing event to server
      const handleTyping = (isTypingValue: any) => {
        const typingChannelName = isClaim
          ? `/typing/claim_${data.id}`
          : `/typing/dispute_${data.id}`;

        socketIO.get(typingChannelName, {
          user: currentUser,
          typing: isTypingValue,
        });
      };

      const inputElement: any = document.getElementById('chatInput');
      inputElement?.addEventListener('keypress', () => handleTyping(true));
      inputElement?.addEventListener('blur', () => handleTyping(false));
    }

    return () => {
      if (socketIO?.on) {
        socketIO?.off('internalNotes');
        socketIO?.off('message');
        socketIO?.off('typing');
        socketIO?.off('userStatusUpdate');
      }
    };
  }, [isSocketConnected, data.id, currentUser?.id]);

  return (
    <div
      className={`xlm:w-[380px] xl:w-[360px] w-[320px]  bg-gray25 border-l border-utilityGray200 h-full flex flex-col transition-all duration-[0.5s] max-xl:absolute ${chatWrapClassName}  ${
        isShowChatAndNotesSideBar
          ? 'xl:-mr-0 right-0'
          : 'xxl:-mr-[407px] xl:-mr-[412px] -right-[383px] '
      } `}
    >
      {currentTab === 'messages' && (
        <InternalChat
          data={data}
          isClaim={isClaim}
          typingUsers={typingUsers}
          messages={messages}
          members={members}
          setMessages={setMessages}
          setMembers={setMembers}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
          lastMessageId={lastMessageId}
          setLastMessageId={setLastMessageId}
        />
      )}

      {currentTab === 'internal_notes' && (
        <InternalNotes
          data={data}
          isClaim={isClaim}
          notesCount={notesCount}
          internalNotes={internalNotes}
          setInternalNotes={setInternalNotes}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          setIsShowChatAndNotesSideBar={setIsShowChatAndNotesSideBar}
          lastNoteId={lastNoteId}
          setLastNoteId={setLastNoteId}
        />
      )}
    </div>
  );
};

export default InternalChatAndNotes;
