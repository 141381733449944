/* eslint-disable max-lines-per-function */
import {
  ArrowUpRight,
  Calendar,
  InfoCircle,
  SearchLg,
} from '@untitled-ui/icons-react/build/cjs';
import { PackagePlus } from 'lucide-react';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import AdditionalServiceModal from 'src/app/QuotingHub/Common/AdditionalServiceModal';
import AutoCompleteGoogle from 'src/components/AutoCompleteGoogle/AutoCompleteGoogle';
import AutoCompleteTrimble from 'src/components/AutoCompleteTrimble/AutoCompleteTrimble';
import ButtonCmp from 'src/components/ButtonCmp';
import CheckBox from 'src/components/CheckBox';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import Radio from 'src/components/Radio/Radio';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { BasicContext } from 'src/context/BasicContext';
import {
  formatDateValue,
  GetAppearance,
  getDateWithSuffixFormat,
} from 'src/utils/CommonFunctions';

import { classOptions } from '../../AddressDetails/Address.interface';
import {
  IMapAddress,
  initConsigneeAddress,
  initShipperAddress,
  initValidForm,
} from '../../AddressDetails/Address.interface';
import { DATE_FORMAT } from '../AddQuoteDetail';

import Address from './Address';
import AddressLoading from './AddressLoading';
import { isValidAddress } from './ValidationFunction';

interface IFormData {
  [key: string]: any;
  customerId: any;
  shipperId: number | null;
  shipperCompanyName: string;
  shipperAddress: IMapAddress;
  pickupDate: any;
  consigneeId: number | null;
  consigneeCompanyName: string;
  consigneeAddress: IMapAddress;
}

const initFormData: IFormData = {
  customerId: '',
  shipperId: null,
  shipperCompanyName: '',
  shipperAddress: initShipperAddress,
  pickupDate: new Date().toISOString(),
  consigneeId: null,
  consigneeCompanyName: '',
  consigneeAddress: initConsigneeAddress,
  classification: 'ltl',
};

const initAction = {
  mode: '',
  shipper: false,
  consignee: false,
  tailgate: false,
  unusual: false,
};

const limitedAccessList = [
  { name: 'Construction Site', value: 'construction-site' },
  { name: 'Individual (Mini) Storage Unit', value: 'mini-storage-unit' },
  { name: 'Fair/Amusement Park', value: 'fair' },
  { name: 'Place of Worship', value: 'place-of-worship' },
  { name: 'Farm/Country Club/Estate', value: 'farm' },
  {
    name: 'Secured Locations Delivery - prisons, military bases, airport',
    value: 'secured-location',
  },
  { name: 'School/University', value: 'school' },
  { name: 'Plaza/Mall with only parking lot/Street access', value: 'mall' },
  { name: 'Airport', value: 'airport' },
  { name: 'Cemetery', value: 'cemetery' },
  { name: 'Church', value: 'church' },
  { name: 'Convention Center', value: 'convention-center' },
  { name: 'Funeral Home', value: 'funeral-home' },
  { name: 'Golf Course', value: 'golf-course' },
  { name: 'Grocery Warehouse', value: 'grocery-warehouse' },
  { name: 'Hospital/Medical Facility', value: 'hospital-medical-facility' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Inspection Site', value: 'inspection-site' },
  { name: 'Marina', value: 'marina' },
  { name: 'Military Base', value: 'military-base' },
  { name: 'Mine Site', value: 'mine-site' },
  { name: 'No Dock/Dock obstruction', value: 'no-dock-dock-obstruction' },
  { name: 'Nursing Home', value: 'nursing-home' },
  { name: 'Park Forest/Preserve', value: 'park-forest-preserve' },
  { name: 'Piers and Wharves', value: 'piers-and-wharves' },
  { name: 'Prison', value: 'prison' },
  { name: 'Ranch', value: 'ranch' },
  {
    name: 'Recreational/athletic Facility',
    value: 'recreational-athletic-facility',
  },
  { name: 'Remote Government', value: 'remote-government' },
  { name: 'Reservation', value: 'reservation' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Service Station', value: 'service-station' },
  { name: 'Social Club', value: 'social-club' },
  { name: 'Steel Mill', value: 'steel-mill' },
  { name: 'Unspecified', value: 'unspecified' },
  { name: 'Utility Site', value: 'utility-site' },
  { name: 'Winery/Brewery/Distillery', value: 'winery-brewery-distillery' },
  { name: 'Other', value: 'other' },
];

const initServiceValidForm = {
  limitedAccessOther: true,
};

const AddressTab = ({
  quoteFormData,
  setSubmitAction,
  setQuoteFormData,
  isUpdateLoading,
  setIsUpdateLoading,
  updateQuoteDetail,
  isQuoteLoading,
  tabArray,
  activeTab,
  setActiveTab,
  quoteData,
  quoteId,
  serviceTypes,
  handleClose,
  status,
  validFormDetail,
  isViewQuote = false,
  setIsDisplayDimensionsDetail = () => {},
  isServiceLoading,
}: any) => {
  const [isLoading, setIsLoading] = useState(isQuoteLoading);

  const [formData, setFormData] = useState<IFormData>(
    quoteFormData?.addressDetail ?? initFormData
  );
  let appearance = GetAppearance();
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [validForm, setValidForm] = useState(initValidForm);
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [shipperAddress, setShipperAddress] = useState('');
  const [consigneeAddress, setConsigneeAddress] = useState('');
  const { mapService } = useContext(BasicContext);

  const [selectedServices, setSelectedServices] = useState<number[]>([]);

  const [pickup1pmError, setPickup1pmError] = useState(false);
  const [tomorrowDayName, setTomorrowDayName] = useState('');
  const [isTailgate, setIsTailgate] = useState<any>(
    quoteFormData?.services?.isTailgate ?? []
  );
  const [action, setAction] = useState(initAction);
  const [isLimitedMore, setIsLimitedMore] = useState<boolean>(
    quoteFormData?.services?.limitedAccess ?? false
  );
  const [limitedAccessTypeList, setLimitedAccessTypeList] =
    useState<any>(limitedAccessList);

  const [limitedAccessSearch, setLimitedAccessSearch] = useState<string>('');
  const [limitedAccessType, setLimitedAccessType] = useState<string>(
    quoteFormData?.services?.limitedAccessType ??
      limitedAccessTypeList?.[0].value
  );
  const [limitedAccessOther, setLimitedAccessOther] = useState<string>(
    quoteFormData?.services?.limitedAccessOther ?? ''
  );
  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(false);
  const [addressData, setAddressData] = useState<IMapAddress>();
  const [isAccessTypeModalOpen, setIsAccessTypeModalOpen] =
    useState<boolean>(false);
  const [isValid] = useState(true);

  useEffect(() => {
    setSubmitAction((old: any) => ({
      ...old,
      isUpdateAddress: true,
    }));

    if (validFormDetail) {
      setValidForm({ ...validForm, ...validFormDetail });
    }
  }, []);

  useEffect(() => {
    if (!allowHandleChecked && !isLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isLoading]);

  useEffect(() => {
    if (isLimitedMore && allowHandleChecked) {
      setIsAccessTypeModalOpen(true);
    }
  }, [isLimitedMore]);

  const handleQuoteFormData = () => {
    const formDataCurrent: any = {
      services: selectedServices,
      serviceId: selectedServices.join(','),
      limitedAccess: isLimitedMore,
    };
    formDataCurrent.isLimitedMore = isLimitedMore;

    if (isLimitedMore) {
      formDataCurrent.limitedAccessType = limitedAccessType;
      formDataCurrent.limitedAccessOther = limitedAccessOther;
    }

    if (isTailgate) {
      formDataCurrent.isTailgate = isTailgate;
    }

    setQuoteFormData((old: any) => ({
      ...old,
      addressDetail: {
        ...old.addressData,
        ...formData,
      },
      services: {
        ...old.services,
        ...formDataCurrent,
      },
    }));
  };

  useEffect(() => {
    handleQuoteFormData();
  }, [activeTab]);

  const isFormValid = () => {
    let addressValidObj = isValidAddress(quoteFormData?.addressDetail);

    setValidForm({ ...validForm, ...addressValidObj?.validFormData });

    return addressValidObj?.valid;
  };

  useEffect(() => {
    setQuoteFormData((old: any) => ({
      ...old,
      addressDetail: {
        ...old.addressDetail,
        ...formData,
      },
    }));
  }, [formData]);

  useEffect(() => {
    let filterTypes = limitedAccessList.filter((type: any) => {
      if (type.value === limitedAccessType) {
        return true;
      }

      return type.name
        .toLowerCase()
        .includes(limitedAccessSearch.toLowerCase());
    });
    setLimitedAccessTypeList(filterTypes);
  }, [limitedAccessSearch]);

  useEffect(() => {
    if (quoteId && !isQuoteLoading && !isLoading) {
      setAllowHandleChecked(false);
      const { addressDetails, additionalService } = quoteData;

      setShipperAddress(
        quoteFormData.addressDetail.shipperAddress?.fullAddress
      );
      setConsigneeAddress(
        quoteFormData.addressDetail.consigneeAddress?.fullAddress
      );

      const newData: any = {};

      if (addressDetails?.primaryEquipmentType) {
        newData.primaryEquipmentType =
          addressDetails?.primaryEquipmentType?.split(',');
      }

      if (addressDetails?.secondaryEquipmentTypes) {
        newData.secondaryEquipmentTypes =
          addressDetails?.secondaryEquipmentTypes?.split(',');
      }

      let savedServices: any = [];

      if (additionalService) {
        savedServices = additionalService.map((val: any) => {
          if (
            val.slug === 'tailgate_pickup' ||
            val.slug === 'tailgate_delivery'
          ) {
            setIsTailgate((old: any) => [...old, val.serviceId]);
          }

          if (val.slug === 'limited_access_delivery') {
            setIsLimitedMore(true);
            setLimitedAccessType(val.limitedAccessType);
            setLimitedAccessOther(val.limitedAccessOther);
          }

          return val.serviceId;
        });
      }
      setSelectedServices(savedServices);
      setIsLoading(false);
    }
  }, [quoteData, isLoading, isQuoteLoading]);

  useEffect(() => {
    setQuoteFormData((old: any) => ({
      ...old,
      services: {
        ...old.services,
        services: selectedServices,
      },
    }));
  }, [selectedServices]);

  const validation = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (isLimitedMore && limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm(validFormData);

    return valid;
  };

  const handleSubmit = () => {
    if (isViewQuote) {
      setIsDisplayDimensionsDetail(false);

      return;
    }

    if (!isFormValid() || !validation()) {
      return true;
    }

    setIsUpdateLoading(true);
    handleQuoteFormData();

    if (activeTab === 'address') {
      setTimeout(() => {
        updateQuoteDetail();
      }, 100);
    }
  };

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (
        e.target.name === 'tailgate_pickup' ||
        e.target.name === 'tailgate_delivery'
      ) {
        setIsTailgate((old: any) => [...old, value]);
      }

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);
      setIsTailgate((current: any) =>
        current.filter((item: any) => item !== value)
      );

      if (e.target.name === 'limited_access_delivery') {
        setIsLimitedMore(false);
        setLimitedAccessType(limitedAccessTypeList[0].value);
      }
    }
  };

  const capitalizeFirstLetter = (str: string): string => {
    if (str.length === 0) return str; // Check if the string is empty

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const getList = (data: any) => (
    <div className="flex flex-wrap -m-1">
      {data.map((val: any) => (
        <div
          className="flex cursor-pointer space-x-1.5 p-1 w-1/3"
          key={`service_${val.id}`}
        >
          <CheckBox
            id={`service_${val.id}`}
            onChangeFunc={handleChange}
            name={val.slug}
            value={val.id}
            checked={selectedServices.includes(val.id)}
            parentClassName="mt-px"
            disabled={val.isOnlyForFTL || isViewQuote}
          />
          <div className="leading-[0.9] space-x-1.5 flex items-center truncate  ">
            {val.slug === 'pickup_appointment_required' ||
            val.slug === 'delivery_appointment_required' ||
            val.slug === 'after_hours_pickup' ? (
              <TooltipCmp message={val.name}>
                <label
                  htmlFor={`service_${val.id}`}
                  className={`leading-4 text-textSecondary text-xs font-medium truncate ${
                    val.isOnlyForFTL ? 'line-through' : 'cursor-pointer'
                  }`}
                >
                  {val.slug === 'pickup_appointment_required'
                    ? 'Pickup Appointment'
                    : val.slug === 'delivery_appointment_required'
                    ? 'Delivery Appointment'
                    : 'After Hours Pickup'}
                </label>
              </TooltipCmp>
            ) : (
              <label
                htmlFor={`service_${val.id}`}
                className={`leading-4 text-textSecondary text-xs font-medium truncate ${
                  val.isOnlyForFTL ? 'line-through' : 'cursor-pointer'
                }`}
              >
                {val.slug === 'after_hours_pickup'
                  ? 'After Hours Pickup'
                  : val.name}
              </label>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const handleAutocompleteChange = (name: string) => (value: any) => {
    if (value.type === 'blur') {
      return;
    }

    if (name === 'shipperAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setShipperAddress(value?.fullAddress);

        setFormData((old: any) => ({ ...old, [name]: value }));

        return;
      }

      setValidForm({ ...validForm, shipperAddress: true });
    } else if (name === 'consigneeAddress') {
      if (
        value?.city === '' ||
        value?.postal === '' ||
        value.postal?.length < 4
      ) {
        setConsigneeAddress(value?.fullAddress);

        setFormData((old: any) => ({ ...old, [name]: value }));

        return;
      }

      setValidForm({ ...validForm, consigneeAddress: true });
    }

    setFormData((old: any) => {
      const newObj = { ...old, [name]: value };

      if (name === 'shipperAddress') {
        if (!old?.shipperId) {
          newObj.shipperCompanyName =
            value.companyName ?? old.shipperCompanyName;
        }
      } else {
        if (!old?.consigneeId) {
          newObj.consigneeCompanyName =
            value.companyName ?? old.consigneeCompanyName;
        }
      }

      return newObj;
    });
  };

  const checkPickupDate = (value: any) => {
    const pickupDate = new Date(value);
    const currentDateTime = new Date();
    pickupDate.setHours(
      currentDateTime.getHours(),
      currentDateTime.getMinutes(),
      currentDateTime.getSeconds(),
      currentDateTime.getMilliseconds()
    );

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const onePM = new Date();
    onePM.setHours(13, 0, 0, 0);

    const isSameDay = pickupDate.toDateString() === today.toDateString();
    const isAfterOnePM = pickupDate.getTime() > onePM.getTime();

    if (isSameDay && isAfterOnePM) {
      setPickup1pmError(true);
      const tomorrow = moment().add(1, 'days');
      const dayName = tomorrow.format('dddd');
      setTomorrowDayName(dayName);
    } else {
      setPickup1pmError(false);
    }
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;

    if (name === 'limitedAccessType') {
      setLimitedAccessType(value);
    }
  };

  const isAccessTypeFormValid = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
    };

    if (limitedAccessType === 'other') {
      if (limitedAccessOther === '' || limitedAccessOther === null) {
        validFormData.limitedAccessOther = false;
        valid = false;
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleActionType =
    (actionKey: any, mode: any = null) =>
    () => {
      if (mode === 'addressUpdate' && actionKey === 'shipper') {
        setAddressData(formData.shipperAddress);
      }

      if (mode === 'addressUpdate' && actionKey === 'consignee') {
        setAddressData(formData.consigneeAddress);
      }
      setAction((old) => ({ ...old, [actionKey]: true, mode }));
    };

  const handleDateChange = (event: any) => {
    const { name, value } = event;

    const formattedDate = moment(value)
      .tz(appearance.timezone ?? browserTimezone)
      .format('YYYY-MM-DD HH:mm:ss');

    setFormData((old: any) => ({ ...old, [name]: formattedDate }));
    localStorage.removeItem('isPickupDateChanged');
    checkPickupDate(value);
  };

  const selectNextDay = (val: boolean) => {
    if (val) {
      if (tomorrowDayName === 'Saturday') {
        const tomorrow = moment().add(3, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else if (tomorrowDayName === 'Sunday') {
        const tomorrow = moment().add(2, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      } else {
        const tomorrow = moment().add(1, 'days');
        setFormData((old: any) => ({ ...old, pickupDate: tomorrow }));
      }

      localStorage.removeItem('isPickupDateChanged');
    }
    setPickup1pmError(false);
  };

  const handleDrawerClose = useCallback(() => {
    setAction(initAction);
  }, []);

  const handleAddressSelection = (value: any) => {
    if (action.shipper) {
      setShipperAddress(value.fullAddress);
      setFormData((old) => ({ ...old, shipperAddress: value }));
    } else if (action.consignee) {
      setConsigneeAddress(value.fullAddress);
      setFormData((old) => ({ ...old, consigneeAddress: value }));
    }
  };

  const [
    isShowAdditionalServiceInfoModal,
    setIsShowAdditionalServiceInfoModal,
  ] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState<
    'PICKUP' | 'DELIVERY'
  >('PICKUP');

  return (
    <>
      <CommonModal
        title={isViewQuote ? 'View Quote Details' : 'Edit Quote Details'}
        titleDesc={
          isViewQuote
            ? 'Get a breakdown of your quote and shipment details.'
            : 'Enter total weight for accurate pricing.'
        }
        handleClose={() => handleClose()}
        headerIcon={<PackagePlus className="w-5 h-5" />}
        size={' max-w-[745px] h-[85vh] '}
        primaryBtnText={isViewQuote ? 'Edit' : 'Save Changes'}
        secondaryBtnText="Cancel"
        primaryBtnDisabled={isLoading || isUpdateLoading}
        primaryBtnLoading={isUpdateLoading}
        primaryBtnOnClick={handleSubmit}
        secondaryBtnOnClick={() => handleClose(false)}
      >
        {isLoading ? (
          <div className="px-5 pb-5 space-y-4">
            <TabButton
              parentClassName={`w-full `}
              tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px"
              className={`flex-1 ${!isValid ? '!cursor-not-allowed' : ''}`}
              tabArray={tabArray.filter((e: any) => e.isAllow)}
              isActive={activeTab}
              isTab={true}
              handleOnClick={(e: any) => {
                if (isValid) setActiveTab(e.target.dataset.value);
              }}
            />
            <AddressLoading />
          </div>
        ) : (
          <div className="px-5 pb-5 space-y-4">
            <TabButton
              parentClassName={`w-full ${isLoading ? 'custom-loading' : ''}`}
              tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px"
              className={`flex-1 ${!isValid ? '!cursor-not-allowed' : ''}`}
              tabArray={tabArray.filter((e: any) => e.isAllow)}
              isActive={activeTab}
              isTab={true}
              handleOnClick={(e: any) => {
                if (isValid) setActiveTab(e.target.dataset.value);
              }}
            />
            <div className="space-y-1.5 !mt-0">
              <h6 className="text-grayLight900 text-xs font-medium">Quote</h6>
              <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2">
                <SelectBox
                  parentClassName="single-select-x-scroll flex-1"
                  name="classification"
                  required
                  label="Service Type"
                  className={`form_control `}
                  classComp={`!bg-white`}
                  labelClassName="form_label mb-1.5 block"
                  isDisabled={isViewQuote}
                  options={classOptions}
                  isLoading={isLoading}
                  value={
                    formData?.classification
                      ? classOptions.filter(
                          (val: any) => formData?.classification === val.value
                        )
                      : 'ltl'
                  }
                  onChangeFunc={(e: any) => {
                    setFormData((old: any) => ({
                      ...old,
                      classification: e?.value,
                    }));
                  }}
                />
                <div className="flex-1 min-w-[264px]">
                  <div className="flex flex-wrap items-center gap-1 mb-1.5 relative">
                    <div className="flex">
                      <label className="form_label mb-0">Pickup Date</label>
                      <span className="text-red-600 ms-[2px] leading-4 text-xs">
                        *
                      </span>
                    </div>
                    <p
                      className={`form_label mb-0 w-max mdm:absolute left-[82px] flex items-center`}
                    >
                      {pickup1pmError && (
                        <>
                          <span className="text-xs text-primary font-normal flex items-center">
                            {tomorrowDayName === 'Saturday'
                              ? `Set to Monday`
                              : 'Set to Tomorrow'}
                          </span>
                          <a
                            onClick={() => selectNextDay(true)}
                            className="text-xs cursor-pointer underline decoration-1 ml-1 mr-1 text-primary700 font-semibold"
                          >
                            Yes
                          </a>
                          &nbsp;
                          {'/'}
                          <a
                            onClick={() => selectNextDay(false)}
                            className="text-xs cursor-pointer underline decoration-1 ml-1 text-primary700 font-semibold"
                          >
                            No
                          </a>
                          <TooltipCmp message={'Pickup is unlikely after 1pm.'}>
                            <InfoCircle className="w-3.5 h-3.5 text-textSecondary ml-1 cursor-pointer" />
                          </TooltipCmp>
                        </>
                      )}
                    </p>
                  </div>
                  <DateSelect
                    inputName="pickupDate"
                    className={`form_control w-full ${
                      !validForm.pickupDate.valid
                        ? 'border border-red-500 mb-1 border-solid'
                        : ''
                    }`}
                    placeholder={DATE_FORMAT}
                    dateFormat="dd/MM/yyyy"
                    minDate={moment().toDate()}
                    selected={
                      formData.pickupDate
                        ? moment
                            .utc(formData.pickupDate)
                            .tz(appearance.timezone ?? browserTimezone)
                            .toDate()
                        : null
                    }
                    value={getDateWithSuffixFormat(
                      formatDateValue(
                        moment
                          .utc(formData.pickupDate)
                          .tz(appearance.timezone ?? browserTimezone)
                          .format('YYYY-MM-DD HH:mm:ss')
                      )
                    )}
                    errorText={''}
                    onChangeFunc={handleDateChange}
                    icon={<Calendar className="h-5 w-5" />}
                    parentClassName={`w-full one-month-datepicker ${
                      isLoading ? 'custom-loading' : ''
                    }`}
                    disabled={isViewQuote}
                  />
                </div>
              </div>
            </div>
            <div className="space-y-1.5">
              <h6 className="text-grayLight900 text-xs font-medium">
                Shipper Details
              </h6>
              <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2 space-y-3">
                {status &&
                  (mapService === 'trimble_map' ? (
                    <AutoCompleteTrimble
                      mapParentClass="w-full"
                      inputName="shipperAddress"
                      required
                      placeholder="Enter address"
                      className={`form_control truncate ${
                        isLoading ? 'custom-loading' : ''
                      } ${
                        !validForm.shipperAddress
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      isLoading={isLoading}
                      label="Address"
                      labelClassName="mb-1.5 block"
                      isClearable
                      onChangeFunc={handleAutocompleteChange('shipperAddress')}
                      onBlur={handleAutocompleteChange('shipperAddress')}
                      onAddressInputChange={setShipperAddress}
                      value={shipperAddress}
                      labelRight={
                        <>
                          <ButtonCmp
                            className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 ${
                              isLoading ? 'custom-loading' : ''
                            }`}
                            type="button"
                            onClick={handleActionType(
                              'shipper',
                              formData.shipperAddress.fullAddress
                                ? 'addressUpdate'
                                : 'addressCreate'
                            )}
                            disabled={false}
                            icon={
                              <ArrowUpRight className="w-4 h-4 text-primary700" />
                            }
                            iconSide="right"
                          >
                            View Address
                          </ButtonCmp>
                        </>
                      }
                      disabled={isViewQuote}
                    />
                  ) : (
                    <AutoCompleteGoogle
                      mapParentClass="w-full"
                      inputName="shipperAddress"
                      required
                      placeholder="Enter address"
                      className={`form_control truncate ${
                        isLoading ? 'custom-loading' : ''
                      } ${
                        !validForm.shipperAddress
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      label="Address"
                      isLoading={isLoading}
                      labelClassName="mb-1.5 block"
                      isClearable
                      onChangeFunc={handleAutocompleteChange('shipperAddress')}
                      onBlur={handleAutocompleteChange('shipperAddress')}
                      onAddressInputChange={setShipperAddress}
                      value={shipperAddress}
                      labelRight={
                        <>
                          <ButtonCmp
                            className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5 ${
                              isLoading ? 'custom-loading' : ''
                            }`}
                            type="button"
                            onClick={handleActionType(
                              'shipper',
                              formData.shipperAddress.fullAddress
                                ? 'addressUpdate'
                                : 'addressCreate'
                            )}
                            disabled={false}
                            icon={
                              <ArrowUpRight className="w-4 h-4 text-primary700" />
                            }
                            iconSide="right"
                          >
                            View Address
                          </ButtonCmp>
                        </>
                      }
                      disabled={isViewQuote}
                    />
                  ))}
                <div className="space-y-1.5 w-full">
                  <div className="flex items-center gap-x-1.5 mb-1.5">
                    <h6 className="form_label block mb-0">
                      Pickup Additional Services
                    </h6>
                    <div
                      className={`cursor-pointer ${
                        isServiceLoading ? 'custom-loading' : ''
                      }`}
                      onClick={() => {
                        setSelectedServiceType('PICKUP');
                        setIsShowAdditionalServiceInfoModal(true);
                      }}
                    >
                      <InfoCircle className="h-3.5 w-3.5 cursor-pointer text-primary" />
                    </div>
                  </div>

                  <div className="rounded-lg border border-utilityGray200 bg-white shadow-sm p-3">
                    {!isServiceLoading && serviceTypes.PICKUP?.length > 0 ? (
                      getList(serviceTypes.PICKUP)
                    ) : isServiceLoading ? (
                      <div className="flex flex-wrap -m-1">
                        {Array.from({ length: 12 }).map((_, index) => (
                          <div
                            className="flex p-1 w-1/3"
                            key={`service_${index}`}
                          >
                            <CheckBox
                              id={`service_abc`}
                              onChangeFunc={handleChange}
                              name="abc"
                              value="xyz"
                              checked={false}
                              parentClassName="mt-0.5 custom-loading"
                            />
                            <div className="flex leading-4">
                              <label
                                htmlFor={`service_abc`}
                                className={`md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary font-medium xxl:me-2.5 me-1.5 custom-loading`}
                              >
                                fdgjdflkjgkldjflgdl
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <NotFoundUI
                        iconClassName="!hidden"
                        title="No services found"
                        desc={`No Pickup Services found. Please try again.`}
                        containerClassName="!h-auto !my-5"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-1.5">
              <h6 className="text-grayLight900 text-xs font-medium">
                Consignee Details
              </h6>
              <div className="p-3 rounded-lg border border-utilityGray200 bg-gray25 flex flex-wrap gap-x-2 space-y-3">
                {status &&
                  (mapService === 'trimble_map' ? (
                    <AutoCompleteTrimble
                      mapParentClass="w-full"
                      inputName="consigneeAddress"
                      required
                      placeholder="Enter address"
                      dropdownWrapClass="!top-[unset] bottom-[65%]"
                      className={`form_control truncate ${
                        isLoading ? 'custom-loading' : ''
                      } ${
                        !validForm.consigneeAddress
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      isLoading={isLoading}
                      label="Address"
                      labelClassName="mb-1.5 block"
                      onChangeFunc={handleAutocompleteChange(
                        'consigneeAddress'
                      )}
                      onBlur={handleAutocompleteChange('consigneeAddress')}
                      isClearable
                      onAddressInputChange={setConsigneeAddress}
                      value={consigneeAddress}
                      labelRight={
                        <>
                          <ButtonCmp
                            className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5  ${
                              isLoading ? 'custom-loading' : ''
                            }`}
                            type="button"
                            onClick={handleActionType(
                              'consignee',
                              formData.consigneeAddress.fullAddress
                                ? 'addressUpdate'
                                : 'addressCreate'
                            )}
                            disabled={false}
                            icon={
                              <ArrowUpRight className="w-4 h-4 text-primary700" />
                            }
                            iconSide="right"
                          >
                            View Address
                          </ButtonCmp>
                        </>
                      }
                      disabled={isViewQuote}
                    />
                  ) : (
                    <AutoCompleteGoogle
                      mapParentClass="w-full"
                      inputName="consigneeAddress"
                      required
                      isLoading={isLoading}
                      placeholder="Enter address"
                      className={`form_control truncate ${
                        !validForm.consigneeAddress
                          ? 'border border-red-500 border-solid'
                          : ''
                      }`}
                      label="Address"
                      listClassName="showAddressOnTop"
                      labelClassName="mb-1.5 block"
                      onChangeFunc={handleAutocompleteChange(
                        'consigneeAddress'
                      )}
                      onBlur={handleAutocompleteChange('consigneeAddress')}
                      isClearable
                      onAddressInputChange={setConsigneeAddress}
                      value={consigneeAddress}
                      labelRight={
                        <>
                          <ButtonCmp
                            className={`btn-outline-primary !p-0 !border-0 !shadow-none !bg-transparent !gap-0.5  ${
                              isLoading ? 'custom-loading' : ''
                            }`}
                            type="button"
                            onClick={handleActionType(
                              'consignee',
                              formData.consigneeAddress.fullAddress
                                ? 'addressUpdate'
                                : 'addressCreate'
                            )}
                            disabled={false}
                            icon={
                              <ArrowUpRight className="w-4 h-4 text-primary700" />
                            }
                            iconSide="right"
                          >
                            View Address
                          </ButtonCmp>
                        </>
                      }
                      disabled={isViewQuote}
                    />
                  ))}

                <div className="space-y-1.5 w-full">
                  <div className="flex items-center gap-x-1.5 mb-1.5">
                    <h6 className="text-textSecondary text-xs font-medium">
                      Delivery Additional Services
                    </h6>
                    <div
                      className={`cursor-pointer ${
                        isServiceLoading ? 'custom-loading' : ''
                      }`}
                      onClick={() => {
                        setSelectedServiceType('DELIVERY');
                        setIsShowAdditionalServiceInfoModal(true);
                      }}
                    >
                      <InfoCircle className="h-3.5 w-3.5 cursor-pointer text-primary" />
                    </div>
                  </div>
                  <div className="rounded-lg border border-utilityGray200 bg-white shadow-sm p-3">
                    {!isServiceLoading && serviceTypes.DELIVERY?.length ? (
                      getList(serviceTypes.DELIVERY)
                    ) : isServiceLoading ? (
                      <div className="flex flex-wrap -m-1">
                        {Array.from({ length: 12 }).map((_, index) => (
                          <div
                            className="flex p-1 w-1/3"
                            key={`service_${index}`}
                          >
                            <CheckBox
                              id={`service_abc`}
                              onChangeFunc={handleChange}
                              name="abc"
                              value="xyz"
                              checked={false}
                              parentClassName="mt-0.5 custom-loading"
                            />
                            <div className="flex leading-4">
                              <label
                                htmlFor={`service_abc`}
                                className={`md:ml-2 ml-1.5 text-sm leading-4 text-textSecondary font-medium xxl:me-2.5 me-1.5 custom-loading`}
                              >
                                fdgjdflkjgkldjflgdl
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <NotFoundUI
                        iconClassName="!hidden"
                        title="No services found"
                        desc={`No Delivery Services found. Please try again.`}
                        containerClassName="!h-auto !my-5"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </CommonModal>

      {isAccessTypeModalOpen && (
        <CommonModal
          title={'Limited access delivery'}
          titleDesc={'Please select limited access delivery type'}
          modalClassName="flex flex-col"
          handleClose={() => {
            setLimitedAccessSearch('');
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="limited_access_delivery"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsAccessTypeModalOpen(false);
          }}
          headerIcon={<PackagePlus />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (isAccessTypeFormValid()) {
              setIsAccessTypeModalOpen(false);
            }
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="limited_access_delivery"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsAccessTypeModalOpen(false);
          }}
        >
          <div className="p-5 flex-1 flex flex-col overflow-y-auto">
            <InputText
              inputName="searchCarrier"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={limitedAccessSearch}
              isClearable
              onChangeFunc={(e) => setLimitedAccessSearch(e.target.value)}
              inputType="text"
              parentClassName="w-full mb-4"
            />

            <div className="rounded-lg border border-borderPrimary px-3 py-1 flex-1 overflow-y-auto custom-scrollbar-v2">
              {limitedAccessTypeList.map((val: any, i: any) => (
                <div
                  className="flex sms:p-4 px-2 py-3 border-b border-utilityGray200 last:border-b-0"
                  key={`limited_${i}`}
                >
                  <div className="flex items-center">
                    <div
                      className="flex items-start gap-2"
                      key={`service_${val.value}`}
                    >
                      <Radio
                        id={`service_${val.value}`}
                        onChangeFunc={handleChangeRadio}
                        inputName={'limitedAccessType'}
                        value={val.value}
                        checked={limitedAccessType === val.value}
                      />
                      <label
                        htmlFor={`service_${val.value}`}
                        className="text-xs text-textSecondary uppercase cursor-pointer font-medium"
                      >
                        {val.name}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              {limitedAccessType === 'other' && (
                <div className="mt-5 w-full">
                  <InputText
                    inputName="limitedAccessOther"
                    className={`form_control ${
                      !validServicesForm.limitedAccessOther
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    label=""
                    value={limitedAccessOther}
                    errorText={
                      !validServicesForm.limitedAccessOther
                        ? 'This field is required'
                        : null
                    }
                    onChangeFunc={(e) => {
                      setLimitedAccessOther(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </CommonModal>
      )}

      <Address
        isOpen={
          action.mode === 'addressCreate' || action.mode === 'addressUpdate'
        }
        mode={action.mode}
        handleClose={handleDrawerClose}
        setAddressData={handleAddressSelection}
        addressType={action}
        addressData={addressData}
        status={status}
        isViewQuote={isViewQuote}
      />
      {isShowAdditionalServiceInfoModal && (
        <AdditionalServiceModal
          handleClose={() => setIsShowAdditionalServiceInfoModal(false)}
          services={serviceTypes?.[selectedServiceType] || []}
          title={`${capitalizeFirstLetter(
            selectedServiceType
          )} Additional Services `}
          titleDesc={`Explore key details of additional ${selectedServiceType.toLocaleLowerCase()} services`}
        />
      )}
    </>
  );
};
export default AddressTab;
