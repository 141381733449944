import {
  Calendar,
  CurrencyDollar,
  PackageCheck,
  Percent02,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { memo, useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { fixedDecimal } from 'src/utils/CommonFunctions';

import InputText from '../../../../components/InputText/InputText';
import { MARGIN_TYPE } from '../../../../constants/common';
import { updateRate } from '../../../../services/QuoteService';

export const DATE_FORMAT: string = 'MMM DD, YYYY';

const initFormData = {
  transitTime: 0,
  carrierQuoteId: '',
  projectedDeliveryDate: moment(),
  margin: '',
  marginUnit: '',
  additionalCharges: 0,
  finalCharge: '',
  totalCharge: '',
  marginType: MARGIN_TYPE.VALUE,
  services: [],
  currency: '',
};

const initValidForm = {
  carrierQuoteId: false,
  finalCharge: false,
};

const initRequiredFields = ['carrierQuoteId', 'finalCharge'];
const tabArray: any = [
  {
    value: '$',
    name: '$',
  },
  {
    value: '%',
    name: '%',
  },
];

const QuoteDetail = ({
  handleClose,
  currency,
  carrierRate,
  setIsRefresh,
  servicesList,
  pickupDate,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<any>({
    ...initFormData,
    projectedDeliveryDate:
      pickupDate && moment(pickupDate).isAfter(moment())
        ? moment(pickupDate).toDate()
        : moment().toDate(),
    transitTime:
      pickupDate && moment(pickupDate).isAfter(moment())
        ? moment(moment(pickupDate)).diff(moment(), 'days') + 1
        : 0,
  });
  const [validForm, setValidForm] = useState<any>(initValidForm);
  const [isValid, setIsValid] = useState<any>({});
  const [activeMarginType, setActiveMarginType] = useState<any>('$');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [lastUpdatedField, setLastUpdatedField] = useState<string | null>(
    'totalCharge'
  );

  const handleTabChange = () => {
    setActiveMarginType((prevType: any) => {
      const newType = prevType === '$' ? '%' : '$';
      const updatedFormData = { ...formData };

      const total =
        parseFloat(updatedFormData?.totalCharge || 0) +
        parseFloat(updatedFormData?.additionalCharges);

      if (newType === '%') {
        const margin = parseFloat(updatedFormData?.margin || 0);
        updatedFormData.marginUnit =
          total > 0 && margin > 0
            ? ((margin / total) * 100).toFixed(2)
            : formData?.marginUnit ?? '';
        let finalChargeAmt = total + margin;
        updatedFormData.finalCharge =
          finalChargeAmt > 0
            ? finalChargeAmt.toFixed(2)
            : formData?.finalCharge ?? '';
      } else {
        const marginUnit = parseFloat(updatedFormData?.marginUnit || 0);

        updatedFormData.margin =
          total > 0 && marginUnit > 0
            ? ((total * marginUnit) / 100).toFixed(2)
            : formData?.margin ?? '';
        let finalChargeAmt = total + parseFloat(updatedFormData?.margin);
        updatedFormData.finalCharge =
          finalChargeAmt > 0
            ? finalChargeAmt.toFixed(2)
            : formData?.finalCharge ?? '';
      }

      setFormData(updatedFormData);

      return newType;
    });
  };
  useEffect(() => {
    console.log('validForm', validForm);
    setIsValid(Object.values(validForm).every((value) => value));
  }, [validForm]);

  useEffect(() => {
    Object.entries(formData).forEach(([key, value]: any) => {
      if (initRequiredFields.includes(key)) {
        setValidForm({ ...validForm, [key]: value ? true : false });
      }
    });
    const initialSelected = formData?.services?.flatMap((service: any) =>
      Object.keys(service)
    );

    setSelectedOptions(initialSelected);
  }, [formData]);

  useEffect(() => {
    let services: any = [];
    const isServiceValid: any = [];

    if (carrierRate?.services && carrierRate?.services?.length) {
      carrierRate.services.forEach((service: any) => {
        services.push({
          [service?.serviceId ? service?.serviceId?.slug : 'other']: {
            id: service?.id,
            name: service.serviceId?.name,
            value: service.value ? fixedDecimal(service.value, 2) : 0,
            serviceId: service.serviceId?.id,
            isOther: service.serviceId?.id ? false : true,
          },
        });
        isServiceValid.push({
          label: true,
          price: true,
        });
      });
    }
    setValidForm((old: any) => ({
      ...old,
      services: isServiceValid,
    }));
    setFormData({ ...formData, services: services, currency });
  }, [carrierRate]);

  const getBusinessDays = (startDate: Date, endDate: Date) => {
    let count = 0;
    const oneDay = 24 * 60 * 60 * 1000;

    for (
      let date = moment(startDate).toDate();
      date <= endDate;
      date.setTime(date.getTime() + oneDay)
    ) {
      const dayOfWeek = date.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
    }

    return count;
  };

  const calculateMarginAndTotal = (event: any, isService = false) => {
    const { target } = event;
    const { name } = target;

    const existingFormData: any = { ...formData };

    if (
      ['totalCharge', 'marginUnit', 'margin', 'finalCharge'].includes(name) ||
      isService
    ) {
      let additionalCharges = existingFormData.additionalCharges || 0;

      if (isService) {
        let updatedAdditionCharge = 0;
        existingFormData?.services?.forEach((quoteService: any) => {
          Object.entries(quoteService).forEach(([, service]: any) => {
            service.value = service.value || 0;
            updatedAdditionCharge += parseFloat(service.value);
          });
        });
        existingFormData.additionalCharges = fixedDecimal(
          updatedAdditionCharge
        );
      }

      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);

      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let finalCharge =
        existingFormData.finalCharge !== undefined
          ? parseFloat(existingFormData.finalCharge)
          : 0;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;

      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      let nonEmptyFields = [total, finalCharge, margin].filter(
        (val) => val !== undefined && val !== 0 && !isNaN(val)
      ).length;

      if (nonEmptyFields < 2) {
        setFormData(existingFormData);

        return;
      }

      if (name === 'totalCharge' || name === 'finalCharge') {
        existingFormData.margin = undefined;
        existingFormData.marginUnit = undefined;
      }

      if (name === 'totalCharge' || isService) {
        if (
          !existingFormData.finalCharge ||
          (!existingFormData.additionalCharges &&
            !existingFormData.totalCharge &&
            !existingFormData.margin)
        ) {
          existingFormData.finalCharge =
            total + margin ? fixedDecimal(total + margin, 2) : '';
          existingFormData.marginUnit =
            total > 0 ? fixedDecimal((100 * margin) / total, 2) : '';
        } else {
          let marginVal = existingFormData.finalCharge - total;
          existingFormData.margin = marginVal !== 0 ? marginVal : '';
          existingFormData.marginUnit =
            total > 0
              ? fixedDecimal((100 * existingFormData.margin) / total, 2)
              : '';
        }
      } else if (name === 'finalCharge') {
        if (total > 0) {
          existingFormData.margin =
            existingFormData.finalCharge - total || undefined;
          existingFormData.marginUnit =
            total > 0
              ? fixedDecimal((100 * existingFormData.margin) / total, 2)
              : '';
        }
      } else if (name === 'marginUnit') {
        if (!existingFormData.finalCharge) {
          existingFormData.finalCharge =
            total > 0 ? fixedDecimal((total / 100) * marginUnit, 2) : '';
          existingFormData.margin =
            total > 0 ? fixedDecimal((total / 100) * marginUnit, 2) : '';
        } else {
          if (lastUpdatedField === 'finalCharge') {
            let totalCharge = fixedDecimal(
              existingFormData.finalCharge / (1 + marginUnit / 100) -
                additionalCharges
            );
            let marginVal = fixedDecimal(
              existingFormData.finalCharge -
                (existingFormData.totalCharge + additionalCharges)
            );

            existingFormData.totalCharge = totalCharge > 0 ? totalCharge : '';
            existingFormData.margin = marginVal > 0 ? marginVal : '';
          } else {
            let finalChargeVal = fixedDecimal(
              total + total * (marginUnit / 100)
            );

            let marginVal = fixedDecimal(
              finalChargeVal -
                (existingFormData.totalCharge + additionalCharges)
            );

            existingFormData.finalCharge =
              finalChargeVal > 0 ? finalChargeVal : '';

            existingFormData.margin = marginVal > 0 ? marginVal : '';
          }
        }
      } else if (name === 'margin') {
        if (!existingFormData.finalCharge) {
          let finalChargeVal = fixedDecimal(total + (margin || 0), 2);
          existingFormData.finalCharge =
            finalChargeVal > 0 ? finalChargeVal : '';
        } else {
          if (lastUpdatedField === 'finalCharge') {
            let totalChargeVal =
              existingFormData.finalCharge - (margin || 0) - additionalCharges;

            existingFormData.totalCharge =
              totalChargeVal !== 0 ? totalChargeVal : '';
          } else {
            let finalChargeVal = total + margin;

            existingFormData.finalCharge =
              finalChargeVal !== 0 ? finalChargeVal : '';
          }
        }

        existingFormData.marginUnit =
          existingFormData.totalCharge > 0
            ? fixedDecimal(
                (100 * margin) /
                  ((+existingFormData.totalCharge || 0) + +additionalCharges),
                2
              )
            : '';
      }
    }

    setFormData(existingFormData);
  };

  const handleInputChangeNew = (event: any, isService = false, index = 0) => {
    const { target, type: eventType } = event;
    let { name, value, type } = target;

    value =
      type === 'number'
        ? value
          ? parseFloat(value)
          : eventType === 'blur'
          ? 0
          : value
        : value;

    const regex = /^[0-9.]*$/;

    if (
      (['totalCharge', 'marginUnit', 'transitTime', 'margin'].includes(name) ||
        isService) &&
      !regex.test(value)
    ) {
      return;
    }

    if (initRequiredFields.includes(name)) {
      setValidForm((old: any) => ({
        ...old,
        [name]: value ? true : false,
      }));
    }
    setFormData((prevFormData: any) => {
      if (isService) {
        prevFormData?.services?.forEach((quoteService: any, qIndex: number) => {
          Object.entries(quoteService).forEach(([slug, service]: any) => {
            service.value = service.value || '';
            prevFormData.services[qIndex][slug] = {
              ...service,
              value:
                index === qIndex && name === slug
                  ? value ?? 0
                  : parseFloat(service.value),
            };
          });
        });
      }

      return {
        ...prevFormData,
        [name]: value,
      };
    });

    if (name === 'totalCharge' || name === 'finalCharge' || isService) {
      if (isService) {
        setLastUpdatedField('totalCharge');
      } else {
        setLastUpdatedField(name);
      }
    }
  };

  const handleInputChange = (event: any, isService = false) => {
    const { target, type } = event;
    const { name, value } = target;

    const regex = /^[0-9.]*$/;

    if (
      ['totalCharge', 'transitTime', 'margin'].includes(name) &&
      !regex.test(value)
    ) {
      return;
    }

    if (initRequiredFields.includes(name)) {
      setValidForm({ ...validForm, [name]: value ? true : false });
    }

    const existingFormData = {
      ...{
        margin: 0,
        marginUnit: 0,
        totalCharge: 0,
        services: formData.services || [],
      },
      ...formData,
    };

    if (!isService) {
      existingFormData[name] = value;
    }

    if (name === 'transitTime') {
      existingFormData.projectedDeliveryDate = moment()
        .clone()
        .add(value, 'days')
        .toDate();
    }

    if (['totalCharge', 'marginUnit', 'margin'].includes(name) || isService) {
      const parseValue =
        type === 'blur' && !value ? 0 : value ? parseFloat(value) : 0;

      let additionalCharges = 0;

      existingFormData.services.forEach((quoteService: any, index: number) => {
        Object.entries(quoteService).forEach(([slug, service]: any) => {
          service.value = service.value || 0;
          additionalCharges +=
            name === slug ? parseValue : parseFloat(service.value);
          existingFormData.services[index][slug] = {
            ...service,
            value:
              index === index && name === slug
                ? value ?? 0
                : parseFloat(service.value),
          };
        });
      });
      existingFormData.additionalCharges = additionalCharges.toFixed(2);
      const total: number =
        parseFloat(existingFormData.totalCharge || 0) +
        parseFloat(existingFormData.additionalCharges || 0);
      existingFormData.marginType = MARGIN_TYPE.PERCENTAGE;

      let margin: number = existingFormData.margin
        ? parseFloat(existingFormData.margin)
        : 0;
      let marginUnit: number = existingFormData.marginUnit
        ? parseFloat(existingFormData.marginUnit)
        : 0;

      if (name === 'marginUnit') {
        margin =
          total > 0 ? parseFloat(((total / 100) * marginUnit).toFixed(2)) : 0;
      } else {
        marginUnit =
          total > 0 ? parseFloat(((100 * margin) / total).toFixed(2)) : 0;
      }
      existingFormData.margin = margin;
      existingFormData.marginUnit = marginUnit;

      if (name === 'finalCharge') {
        if (type === 'blur') {
          existingFormData[name] =
            parseValue < total
              ? fixedDecimal(total, 2)
              : fixedDecimal(parseValue, 2);
        }
        let newMargin = parseValue - total;

        if (existingFormData.marginType === MARGIN_TYPE.PERCENTAGE) {
          newMargin = ((parseValue - total) / total) * 100;
        }
        existingFormData.margin =
          parseValue < total
            ? 0
            : newMargin
            ? fixedDecimal(newMargin, 2)
            : newMargin;
      } else {
        if (parseFloat(existingFormData.margin) >= 0) {
          existingFormData.finalCharge = fixedDecimal(
            total + parseFloat(existingFormData.margin),
            2
          );
        }
      }

      if (name === 'marginUnit') {
        existingFormData.marginUnit = value;
      } else if (name === 'margin') {
        existingFormData.margin = value;
      }
    }

    setFormData(existingFormData);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    try {
      formData.margin = formData.margin > 0 ? formData.margin : 0;
      formData.marginUnit = formData.marginUnit > 0 ? formData.marginUnit : 0;
      formData.totalCharge =
        formData.totalCharge > 0 ? formData.totalCharge : 0;
      updateRate(carrierRate.id, formData)
        .then(() => {
          setFormData(initFormData);
          handleClose();
          setIsRefresh(true);
        })
        .finally(() => setIsLoading(false))
        .catch(console.error);
    } catch (error) {
      handleClose(false);
      setIsRefresh(true);
    }
  };

  return (
    <>
      <CommonModal
        title="Add a Quick Quote"
        titleDesc={'Review rate and margin details to create an active quote.'}
        handleClose={() => handleClose(false)}
        headerIcon={<PackageCheck />}
        size={`xl:max-w-[606px] md:max-w-[536px] max-w-[496px]`}
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        primaryBtnLoading={isLoading}
        primaryBtnDisabled={isLoading || !isValid}
        primaryBtnOnClick={handleSubmit}
        secondaryBtnOnClick={() => handleClose(false)}
      >
        <>
          <div className="flex-1">
            <div className="w-full p-5 flex flex-col gap-y-4">
              <InputText
                label="Carrier quote ID"
                placeholder="Enter carrier quote ID"
                labelClassName="mb-1.5 block text-textSecondary"
                inputType="text"
                inputName="carrierQuoteId"
                className="gap-x-1.5"
                value={formData.carrierQuoteId}
                onChangeFunc={handleInputChange}
                onBlur={handleInputChange}
                required
                parentClassName=""
              />
              <DateSelect
                inputName="projectedDeliveryDate"
                className={`form_control `}
                label="Projected delivery"
                labelClassName="block"
                placeholder={DATE_FORMAT}
                dateFormat="dd/MM/yyyy"
                minDate={
                  pickupDate && moment(pickupDate).isAfter(moment())
                    ? moment(pickupDate).toDate()
                    : moment().toDate()
                }
                value={
                  formData.projectedDeliveryDate
                    ? (() => {
                        const deliveryDate = moment(
                          formData.projectedDeliveryDate
                        );
                        const today =
                          pickupDate && moment(pickupDate).isAfter(moment())
                            ? moment(pickupDate)
                            : moment();

                        const businessDays = getBusinessDays(
                          today.toDate(),
                          deliveryDate.toDate()
                        );

                        const date = deliveryDate.toDate();
                        const day = date.getDate();
                        const month = date.toLocaleString('default', {
                          month: 'long',
                        });
                        const year = date.getFullYear();

                        return `${day} ${month}, ${year} (${businessDays} Business ${
                          businessDays === 1 ? 'Day' : 'Days'
                        })`;
                      })()
                    : 'N/A'
                }
                selected={
                  formData && formData.projectedDeliveryDate
                    ? moment(formData.projectedDeliveryDate).toDate()
                    : null
                }
                errorText={''}
                onChangeFunc={(date: any) => {
                  const today =
                    pickupDate && moment(pickupDate).isAfter(moment())
                      ? moment(pickupDate)
                      : moment();

                  const businessDays = getBusinessDays(
                    today.toDate(),
                    moment(date?.value).toDate()
                  );

                  setFormData({
                    ...formData,
                    projectedDeliveryDate: moment(date?.value),
                    transitTime: businessDays,
                  });
                }}
                icon={<Calendar className="h-4 w-4 " />}
                calendarIconClass="!text-primary"
                parentClassName="fixed-width-datepicker right-side-datepicker one-month-datepicker border-b border-utilityGray200 pb-4"
              />

              <InputText
                label="Cost"
                placeholder="Enter cost"
                labelWrapClass="mb-1.5"
                labelClassName="mb-0 block text-textSecondary"
                inputType="number"
                inputName="totalCharge"
                className="pl-8"
                parentClassName="w-full"
                value={formData.totalCharge || undefined}
                onChangeFunc={handleInputChangeNew}
                onBlur={calculateMarginAndTotal}
                shouldFormatNumber={true}
                onFocus={() => {
                  if (Number(formData?.totalCharge) === 0) {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      totalCharge: '',
                    }));
                    setIsValid(false);
                  }
                }}
                icon={<CurrencyDollar className="input-currency-sign" />}
              />

              {formData?.services?.length > 0 && (
                <div>
                  <div className="flex flex-col gap-3">
                    {formData?.services?.map(
                      (nonQuoteService: any, index: any) =>
                        Object.entries(nonQuoteService).map(
                          ([slug, service]: any) => (
                            <div className="flex w-full gap-x-2" key={slug}>
                              <>
                                {!service.isOther ? (
                                  <SelectBox
                                    label={`${
                                      index === 0 ? 'Additional Charges' : ''
                                    }`}
                                    name={slug}
                                    parentClassName="flex-1"
                                    labelClassName="form_label block mb-1.5"
                                    id={`additional_charge-${index}-charge`}
                                    size="sm"
                                    placeholder="Select Additional Charge"
                                    noOptionMessage="No Additional Charge Found"
                                    isSearchable={true}
                                    className={`form_control`}
                                    isDisabled
                                    isError={
                                      !validForm.services?.[index]?.label
                                    }
                                    isClearable={true}
                                    options={servicesList.filter(
                                      (item: any) =>
                                        item?.value === slug ||
                                        item?.value === 'other' || // Keep the currently selected option
                                        !selectedOptions.includes(item?.value) // Remove options already selected elsewhere
                                    )}
                                    onChangeFunc={(e: any) => {
                                      const selectedValue = e?.value;
                                      setSelectedOptions([
                                        ...selectedOptions,
                                        selectedValue,
                                      ]);
                                      setFormData((old: any) => {
                                        // Get the service to update

                                        const updatedService =
                                          old.services[index];
                                        // Create a new object with the updated slug and the same values

                                        const updatedServiceObj = {
                                          [e?.value]: {
                                            ...updatedService[slug],
                                            serviceId: e?.id,
                                            isOther:
                                              e?.value === 'other'
                                                ? true
                                                : false,
                                          },
                                        };

                                        // Return the updated form data
                                        const updatedServices = [
                                          ...old.services.slice(0, index), // Services before the updated one
                                          updatedServiceObj, // New updated service
                                          ...old.services.slice(index + 1), // Services after the updated one
                                        ];

                                        return {
                                          ...old,
                                          services: updatedServices,
                                        };
                                      });
                                    }}
                                    value={servicesList?.find(
                                      (e: any) => e?.value === slug
                                    )}
                                  />
                                ) : (
                                  <InputText
                                    label={`${
                                      index === 0 ? 'Additional Charges' : ''
                                    }`}
                                    labelClassName="form_label block mb-1.5"
                                    inputName={`additional_charge.${index}.otherCharge`}
                                    placeholder="Enter charge name"
                                    required
                                    className={`w-full ${
                                      !validForm.services?.[index]?.label
                                        ? 'border border-red-500 border-solid'
                                        : ''
                                    }`}
                                    value={service?.name || ''} // Ensure value is tied to the state
                                    onChangeFunc={(e) => {
                                      setFormData((old: any) => ({
                                        ...old,
                                        services: old.services.map(
                                          (serviceItem: any, idx: any) =>
                                            idx === index
                                              ? {
                                                  ...serviceItem,
                                                  [slug]: {
                                                    ...serviceItem[slug],
                                                    name: e?.target?.value, // Update the value correctly
                                                  },
                                                }
                                              : serviceItem
                                        ),
                                      }));
                                    }}
                                    parentClassName="flex-1"
                                    isClearable
                                  />
                                )}

                                <InputText
                                  label={`${index === 0 ? 'Price' : ''}`}
                                  placeholder="Enter price"
                                  inputType="number"
                                  className={`pl-8 w-full ${
                                    !validForm.services?.[index]?.price
                                      ? 'border border-red-500 border-solid'
                                      : ''
                                  }`}
                                  inputName={slug}
                                  value={service?.value || ''}
                                  onChangeFunc={(e) =>
                                    handleInputChange(e, true)
                                  }
                                  labelClassName="block mb-1.5"
                                  parentClassName="flex-1"
                                  icon={
                                    <CurrencyDollar className="input-currency-sign" />
                                  }
                                  shouldFormatNumber={true}
                                />

                                <div
                                  className={`lg:h-9 h-[34px] flex items-center ${
                                    index === 0 ? 'mt-[21px]' : ''
                                  }`}
                                ></div>
                              </>
                            </div>
                          )
                        )
                    )}
                  </div>
                </div>
              )}

              <div className="w-ful">
                <label className="form_label block mb-1.5">Margin</label>
                <div className="form_control p-0 relative !h-auto">
                  {activeMarginType === '$' ? (
                    <InputText
                      inputName="margin"
                      inputType="number"
                      className="!border-0 !h-auto !pl-8 !pr-20"
                      labelClassName="block mb-1.5"
                      value={formData?.margin || undefined}
                      placeholder="Enter margin"
                      onChangeFunc={handleInputChangeNew}
                      onBlur={calculateMarginAndTotal}
                      shouldFormatNumber={true}
                      onFocus={() => {
                        if (Number(formData?.margin) === 0) {
                          setFormData((prevFormData: any) => ({
                            ...prevFormData,
                            margin: '',
                          }));
                          setIsValid(false);
                        }
                      }}
                      icon={<CurrencyDollar className="input-currency-sign" />}
                    />
                  ) : (
                    <InputText
                      inputName="marginUnit"
                      inputType="number"
                      placeholder="Enter margin"
                      className="!border-0 !h-auto !pl-8 !pr-20"
                      labelClassName="block mb-1.5"
                      value={formData?.marginUnit}
                      onChangeFunc={handleInputChangeNew}
                      onBlur={calculateMarginAndTotal}
                      shouldFormatNumber={true}
                      onFocus={() => {}}
                      icon={<Percent02 className="input-currency-sign" />}
                    />
                  )}

                  <TabButton
                    tabParentClassName="absolute top-1/2 -translate-y-1/2 right-1"
                    parentClassName="!bg-gray50 !p-[3px]"
                    className={`!rounded-md text-gray500 !border-0 !px-[8px] !py-0.5 !text-[11px] cursor-pointer first:font-normal [&>span]:text-gray500 !min-h-[auto]`}
                    activeClassName=" !bg-white [&>span]:!text-grayLight900 !shadow-md "
                    tabArray={tabArray}
                    isActive={activeMarginType}
                    isTab={true}
                    handleOnClick={handleTabChange}
                  />
                </div>
              </div>
              <InputText
                label="Sale Price"
                inputName="finalCharge"
                inputType="number"
                placeholder="Enter sale price"
                className="pl-8"
                labelClassName="block mb-1.5"
                parentClassName="w-full"
                value={formData?.finalCharge || undefined}
                onChangeFunc={handleInputChangeNew}
                onBlur={calculateMarginAndTotal}
                shouldFormatNumber={true}
                onFocus={() => {
                  if (formData?.finalCharge === 0) {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      finalCharge: '',
                    }));
                    setIsValid(false);
                  }
                }}
                required
                icon={<CurrencyDollar className="input-currency-sign" />}
              />
            </div>
          </div>
        </>
      </CommonModal>
    </>
  );
};

export default memo(QuoteDetail);
