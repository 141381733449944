import {
  HANDLING_UNIT_NUMBER,
  QUOTE_CLASSIFICATION,
} from 'src/constants/common';

export const initValidForm = {
  dimensions: [
    {
      handlingUnit: {
        required: true,
        valid: true,
      },
      handlingUnitNo: {
        required: true,
        valid: true,
      },
      weight: {
        required: true,
        valid: true,
      },
      itemLength: {
        required: true,
        valid: true,
      },
      freightClass: {
        required: true,
        valid: true,
      },
      width: {
        required: true,
        valid: true,
      },
      height: {
        required: true,
        valid: true,
      },
      totalWeight: {
        required: true,
        valid: true,
      },
      isCommodity: {
        required: true,
        valid: true,
      },
      isStack: {
        required: true,
        valid: true,
      },
      commodityId: {
        required: true,
        valid: true,
      },
      commodityName: {
        required: true,
        valid: true,
      },
      sub: {
        required: true,
        valid: true,
      },
      // description: {
      //   required: true,
      //   valid: true,
      // },
    },
  ],
};

export const dimensionRequiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'freightClass',
  'commodityId',
  'sub',
  'totalWeight',
];

export function isValidAddress(addressFormData: any) {
  let valid = true;

  const validFormData = {
    shipperAddress: true,
    pickupDate: {
      valid: true,
      nextDay: true,
    },
    consigneeAddress: true,
    primaryEquipmentType: true,
  };

  // Validate Shipper Address
  if (!addressFormData?.shipperAddress?.fullAddress) {
    validFormData.shipperAddress = false;
    valid = false;
  }

  // Validate Pickup Date
  if (!addressFormData?.pickupDate) {
    validFormData.pickupDate.valid = false;
    valid = false;
  }

  // Validate Consignee Address
  if (!addressFormData?.consigneeAddress?.fullAddress) {
    validFormData.consigneeAddress = false;
    valid = false;
  }

  return { validFormData, valid };
}

export function isValidDimension(
  dimensionFormData: any,
  validateMeasurement: any
) {
  const validFormData: any = [];
  dimensionFormData?.forEach((form: any) => {
    // totalWeight += form.totalWeight;
    const validation = {
      ...initValidForm?.dimensions?.[0],
      handlingUnit: {
        required: Boolean(form.handlingUnit),
        valid: Boolean(form.handlingUnit),
      },
      weight: {
        required: Boolean(form.weight),
        valid: Boolean(form.weight),
      },
      totalWeight: {
        required: Boolean(form.totalWeight),
        valid: form.totalWeight <= validateMeasurement.maxTotalWeight,
      },
      handlingUnitNo: {
        required: Boolean(form.handlingUnitNo),
        valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
      },
      itemLength: {
        required: Boolean(form.itemLength),
        valid: form.itemLength <= validateMeasurement.length,
      },
      width: {
        required: Boolean(form.width),
        valid: form.width <= validateMeasurement.width,
      },
      height: {
        required: Boolean(form.height),
        valid: form.height <= validateMeasurement.height,
      },
      freightClass: {
        required: Boolean(form.freightClass),
        valid: form.freightClass > 0,
      },
      commodityId: {
        required: form.isCommodity ? Boolean(form.commodityId) : true,
        valid: form.isCommodity ? Boolean(form.commodityId) : true,
      },
      sub: {
        required: form.isCommodity ? Boolean(form.sub) : true,
        valid: form.isCommodity ? Boolean(form.sub) : true,
      },
      // description: {
      //   required: Boolean(form.description),
      //   valid: Boolean(form.description),
      // },
    };
    validFormData.push(validation);
  });

  let valid = true;
  const invalidIndexes: number[] = [];

  validFormData.forEach((form: any, index: number) => {
    const isFormValid = dimensionRequiredFields.every(
      (requiredField) =>
        form[requiredField].required && form[requiredField].valid
    );

    if (!isFormValid) {
      valid = false;
      invalidIndexes.push(index);
    }
  });

  return {
    validFormData,
    valid,
    invalidIndexes,
  };
}

export function isValidQuoteDetail(quoteFormData: any) {
  let valid = true;
  const validFormData = {
    primaryEquipmentType: true,
    totalWeight: true,
    totalWeightMax: true,
    totalWeightMin: true,
    linearFootage: true,
    linearFootageMax: true,
    linearFootageMin: true,
  };

  if (quoteFormData.totalWeight || quoteFormData.totalWeight === 0) {
    if (Number(quoteFormData.totalWeight) > 100000) {
      validFormData.totalWeightMax = false;
      valid = false;
    }

    if (Number(quoteFormData.totalWeight) <= 0) {
      validFormData.totalWeightMin = false;
      valid = false;
    }
  }

  if (quoteFormData?.classification !== QUOTE_CLASSIFICATION.FTL) {
    if (quoteFormData.linearFootage || quoteFormData.linearFootage === 0) {
      if (Number(quoteFormData.linearFootage) > 53) {
        validFormData.linearFootageMax = false;
        valid = false;
      }

      if (Number(quoteFormData.linearFootage) <= 0) {
        validFormData.linearFootageMin = false;
        valid = false;
      }
    }

    if (!quoteFormData.linearFootage || quoteFormData.linearFootage === '') {
      validFormData.linearFootage = false;
      valid = false;
    }
  }

  if (!quoteFormData.primaryEquipmentType) {
    validFormData.primaryEquipmentType = false;
    valid = false;
  }

  if (!quoteFormData.totalWeight) {
    validFormData.totalWeight = false;
    valid = false;
  }

  return { validFormData, valid };
}
