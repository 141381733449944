import React from 'react';
import { getShortName, onError } from 'src/utils/CommonFunctions';

interface IProps {
  data: any;
}

export const getStartingChars = (str: string): string => {
  const trimmedStr = str.trim();

  const string = trimmedStr.slice(0, 15);

  if (trimmedStr.length > 15) {
    return `${string}...`;
  }

  return string;
};

const CustomerSplitAsideTableRow = ({ data }: IProps) => (
  <>
    <td className={`px-5 py-4  min-w-96 w-96 max-w-96`}>
      <div className="flex items-center truncate">
        <div className="table-profile-wrap notification-mark-wrap">
          {data?.image ? (
            <img
              src={`${data?.imageUrl}${data?.image}`}
              className="table-profile-icn-circle-xl rounded-md"
              alt={data.name}
              title={data.name}
              onError={onError}
            />
          ) : (
            <div className="table-profile-customer-circle-xl rounded-md">
              {getShortName(data.name)}
            </div>
          )}
          {data.hasUnreadNotification ? (
            <span className={`notification-dot`}></span>
          ) : (
            ''
          )}
        </div>

        <div className="pl-3 w-[calc(100%_-_58px)]">
          <h6 className="text-grayLight900 font-medium leading-tight truncate">
            {data.name}
          </h6>
        </div>
      </div>
    </td>
    <td className={`px-5 py-4`}>
      <span className="">{data.totalRequest}</span>
    </td>
    <td className={`px-5 py-4`}>
      <span className="">{data.wonLoss}</span>
    </td>
  </>
);
export default CustomerSplitAsideTableRow;
