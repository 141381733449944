import { yupResolver } from '@hookform/resolvers/yup';
import { Calendar, Clock } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';
import { BasicContext } from 'src/context/BasicContext';
import { createOrUpdateAppointment } from 'src/services/OrderService';
import {
  getDateWithSuffixFormat,
  formatDateValue,
} from 'src/utils/CommonFunctions';
import * as yup from 'yup';

interface IPops {
  modalTitle: string;
  modalDesc: string;
  primaryBtnText: string;
  type: string;
  orderLegId: number;
  editData: any;
  handleClose: any;
  handleOnSubmit: any;
  isShowTimePicker?: any;
  isSubmitAppointment?: boolean;
}

const schema = (isShowTimePicker: boolean, appearance: any) =>
  yup.object().shape({
    appointmentDate: yup
      .date()
      .required('Appointment Date is required')
      .nullable(),
    ...(isShowTimePicker && {
      appointmentTime: yup
        .string()
        .required('Appointment Time is required')
        .matches(/^\d{2}:\d{2}$/, 'Appointment Time is required')
        .test('valid-time', 'Invalid time', (value) => {
          if (!value) return false;

          const [hours, minutes] = value.split(':').map(Number);

          return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
        })
        .test(
          'not-past-time',
          'Appointment time cannot be in the past',
          function (value) {
            const { appointmentDate } = this.parent;

            if (!appointmentDate || !value) return true;

            const selectedDateTime = moment
              .tz(
                `${moment(appointmentDate).format('YYYY-MM-DD')} ${value}`,
                'YYYY-MM-DD HH:mm',
                appearance?.timezone
              )
              .utc();

            return selectedDateTime.isSameOrAfter(moment.utc());
          }
        ),
    }),
  });

const AppointmentModal = ({
  modalTitle,
  modalDesc,
  primaryBtnText,
  type,
  orderLegId,
  editData,
  handleClose,
  handleOnSubmit,
  isShowTimePicker = true,
  isSubmitAppointment = true,
}: IPops) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  let { appearance } = useContext(BasicContext);

  const headerIcon = <Calendar className="w-7 h-7" />;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema(isShowTimePicker, appearance)),
    defaultValues: {
      appointmentDate: editData?.date
        ? moment.utc(editData.date).tz(appearance?.timezone).toDate()
        : moment.utc().tz(appearance?.timezone).toDate(),
      appointmentTime: isShowTimePicker
        ? editData?.time
          ? moment
              .utc(editData.time, 'YYYY-MM-DD HH:mm:ss')
              .tz(appearance?.timezone)
              .format('HH:mm')
          : undefined
        : undefined,
    },
  });

  const onSubmit = (formData: any) => {
    setIsLoading(true);

    let formattedData = {
      ...formData,
      appointmentDate: moment(formData.appointmentDate)
        .utc()
        .format('YYYY-MM-DD'),
      appointmentTime: moment
        .tz(
          `${moment(formData.appointmentDate).utc().format('YYYY-MM-DD')} ${
            formData.appointmentTime
          }`,
          'YYYY-MM-DD HH:mm',
          appearance?.timezone
        )
        .utc()
        .format('YYYY-MM-DD HH:mm'),
      type,
      orderLegId,
    };

    formattedData = {
      ...formattedData,
      combinedDatetime: `${moment(formattedData.appointmentDate).format(
        'YYYY-MM-DD'
      )} ${moment(formattedData.appointmentTime).format('HH:mm:ss')}`,
    };

    if (isSubmitAppointment) {
      createOrUpdateAppointment(formattedData)
        .then((response) => {
          if (response.data) {
            const combinedDatetime = `${moment(
              formattedData.appointmentDate
            ).format('YYYY-MM-DD')} ${moment(
              formattedData.appointmentTime
            ).format('HH:mm:ss')}`;

            handleOnSubmit({ ...formattedData, combinedDatetime });
          }
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      handleOnSubmit(formattedData);
      setIsLoading(false);
    }
  };

  return (
    <CommonModal
      title={modalTitle}
      titleDesc={modalDesc}
      handleClose={handleClose}
      headerIcon={headerIcon}
      size={'xl:max-w-[420px] overflow-unset'}
      primaryBtnText={primaryBtnText}
      secondaryBtnText="Cancel"
      primaryBtnOnClick={handleSubmit(onSubmit)}
      primaryBtnLoading={isLoading}
      isOverflow={false}
      secondaryBtnOnClick={handleClose}
    >
      <div className="w-full p-5 flex flex-col gap-y-4 ">
        <Controller
          name="appointmentDate"
          control={control}
          render={({ field: { onChange, value } }) => (
            <DateSelect
              inputName="appointmentDate"
              className="form_control"
              parentClassName="datepicker-w-auto one-month-datepicker w-full max-sm:mt-4"
              label="Select Date"
              selected={value}
              onChangeFunc={(e: any) => onChange(e?.value)}
              minDate={moment().toDate()}
              labelClassName="block"
              placeholder="Select Date"
              dateFormat="dd/MM/yyyy"
              errorText={errors.appointmentDate?.message}
              icon={<Calendar className="h-5 w-5" />}
              value={getDateWithSuffixFormat(formatDateValue(value))}
            />
          )}
        />

        {isShowTimePicker && (
          <Controller
            name="appointmentTime"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputText
                inputName="appointmentTime"
                label="Appointment Time"
                placeholder="HH:mm"
                labelClassName="block mb-1.5"
                parentClassName=""
                value={value as string}
                onChangeFunc={(e) => {
                  let timeValue = e.target.value;

                  const [firstDigit, secondDigit] = timeValue
                    .split('')
                    .map(Number);

                  if (firstDigit > 2) {
                    return false;
                  }

                  if (firstDigit === 2 && secondDigit > 3) {
                    return false;
                  }

                  const minuteFirstDigit = parseInt(timeValue[3]);

                  if (minuteFirstDigit > 5) {
                    return false;
                  }

                  if (timeValue.includes(':')) {
                    const [hours] = timeValue.split(':');

                    if (!hours || hours.length !== 2) {
                      return false;
                    }
                  }

                  onChange(timeValue);
                }}
                mask="99:99"
                errorText={error?.message}
                icon={
                  <div className="absolute top-1/2 -translate-y-1/2 right-4">
                    <Clock className="h-4 w-4" />
                  </div>
                }
              />
            )}
          />
        )}
      </div>
    </CommonModal>
  );
};

export default AppointmentModal;
