import React from 'react';
import CheckBox from 'src/components/CheckBox';

import FreightcomIcon from '../../../../assets/img/frieghtcom.png';

const ActiveCarrierRowLoading = () => (
  <>
    <td className="custom-loading px-5 py-5 w-[3%] min-w-[3%] max-w-[3%]">
      <span onClick={(e) => e.stopPropagation()}>
        <CheckBox className="w-4 h-4 " onChangeFunc={() => {}} />
      </span>
    </td>
    <td className="custom-loading px-5 py-5 w-[27%] min-w-[27%] max-w-px">
      <div className=" flex items-center ">
        <div className="table-profile-customer-circle-xl rounded-md  ">
          <img
            className="w-full h-full"
            src={FreightcomIcon}
            alt="IMAGE"
            title="IMAGE"
          />
        </div>
        <div className="pl-3 max-w-48">
          <h6 className="text-grayLight900 font-medium leading-tight truncate max-w-36 flex-none ">
            *****************
          </h6>
          <p className="max-w-28 truncate inline-block align-middle ">#11111</p>
        </div>
      </div>
    </td>
    <td className="custom-loading px-5 py-4 min-w-[160px] w-[160px] max-w-[160px]">
      <div className="flex items-center ">
        <div className="table-profile-icn-circle-sm ">
          <img
            className="table-profile-icn-circle-sm  "
            src={FreightcomIcon}
            alt="FC"
          />
        </div>

        <div className="pl-3 font-medium ">Freightcom</div>
      </div>
    </td>

    <td className="custom-loading px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]">
      <span className=""> {`Est. 5 days`} </span>
    </td>

    <td className="custom-loading px-5 py-4 w-[11.8%] min-w-[11.8%] max-w-[11.8%]">
      <span className="">100000</span>
    </td>
    <td className="custom-loading px-5 py-4 w-[172px] min-w-[172px] max-w-[172px]">
      <span className="">100000</span>
    </td>
    <td className="custom-loading px-5 py-4 w-[160px] min-w-[160px] max-w-[160px]">
      {' '}
      <span className="text-textSecondary font-bold "> {'2000000000'}</span>
    </td>
  </>
);

export default ActiveCarrierRowLoading;
