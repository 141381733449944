import { SearchLg } from '@untitled-ui/icons-react/build/cjs';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import BadgeCmp from 'src/components/BadgeCmp';
import CustomPagination from 'src/components/CustomPagination';
import InputText from 'src/components/InputText/InputText';
import NotFoundUI from 'src/components/NotFoundUI';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TableCmp from 'src/components/TableCmp';

import CustomerSplitTableRow from './CustomerSplitTableRow';
import CustomerSplitTableRowLoader from './CustomerSplitTableRowLoader';
// import CustomerSplitPieChartModal from './CustomerSplitPieChartModal';
// import CustomerOrdersDetail from './CustomerSplitTable/CutomerOrderDetail';
// import CustomerOrdersTable from './CustomerSplitTable/CustomerOrdersTable';

const recordsPerPageArray = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
];

interface IProps {
  customerList: any;
  isDataLoading: boolean;
  params: any;
  setParams: any;
  totalRecords?: number;
}

const CustomerSplit = ({
  customerList,
  isDataLoading,
  params,
  setParams,
  totalRecords,
}: IProps) => {
  const [sortData, setSortData] = useState({
    sortField: params.sortField || 'createdAt',
    sortType: params.sortType || 'desc',
  });
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [recordsPerPage, setRecordsPerPage] = useState(params.limit || 25);
  const [searchInput, setSearchInput] = useState(params.search || '');
  const [isLoading, setIsLoading] = useState(true);
  const [isShowOrdersModal, setIsShowOrdersModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

  const headCells = useMemo(
    () => [
      {
        id: 'name',
        name: 'Customer',
        sortable: true,
      },
      {
        id: 'totalRequest',
        name: 'Total Quote Requests',
        sortable: true,
      },
      {
        id: 'avgTimeToClassify',
        name: 'Avg. Time to Classify',
        sortable: true,
      },
      {
        id: 'totalQuote',
        name: 'Total Quotes',
        sortable: true,
      },
      {
        id: 'avgTimeToQuote',
        name: 'Avg. Time to Quote',
        sortable: true,
      },

      {
        id: 'totalOrders',
        name: 'Total Orders',
        sortable: true,
      },
      {
        id: 'wonLoss',
        name: 'Win/Loss',
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    setIsLoading(isDataLoading);
  }, [isDataLoading]);

  const skipUpdateForEmptyData = () => {
    if (customerList.length === 0 && (!totalRecords || totalRecords === 0)) {
      // Skipping ${actionType} update when no records found
      return true;
    }

    return false;
  };

  const handleSorting = () => {
    const { sortField, sortType } = sortData;
    setParams((old: any) => ({
      ...old,
      sortField,
      sortType,
    }));
  };

  useEffect(() => {
    handleSorting();
    setCurrentPage(1);
  }, [sortData]);

  const handleOnRowClick = (currentCustomer: any) => {
    if (currentCustomer?.id !== selectedCustomer?.id)
      setIsShowOrdersModal(true);
    else setIsShowOrdersModal(!isShowOrdersModal);
    setSelectedCustomer(currentCustomer);
  };

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      setParams((old: any) => ({
        ...old,
        search: searchValue,
        page: 1,
      }));
    }, 500), // 500ms debounce
    []
  );

  return (
    <>
      <div className="w-full flex-col flex">
        <div className="table-top-header flex-wrap ">
          <div className="table-left-wrap flex-none max-mdm:flex-[0_0_100%]">
            <div className="table-title-wrap flex-wrap">
              <h5 className="table-title">Customer Split</h5>
              <div className="flex gap-2.5 flex-wrap">
                <BadgeCmp
                  style="modern"
                  type="success"
                  mainClassName={isLoading ? 'custom-loading' : ''}
                >
                  {customerList.length}{' '}
                  {customerList.length > 1 ? 'Customers' : 'Customer'}
                </BadgeCmp>

                {/* {customerList.length > 0 && (
                  <div
                    className="flex justify-between items-center cursor-pointer flex-none"
                    onClick={() => setIsShowPieChartModal(true)}
                  >
                    <div className="flex text-[11px] items-center text-primary font-semibold">
                      View Graph <ArrowUpRight className="h-4 w-4" />
                    </div>
                  </div>
                )} */}
              </div>
            </div>
            <p className="table-subtitle">
              View customer specific sales analytics.
            </p>
          </div>
          <div className="table-right-wrap max-mdm:w-full">
            <InputText
              inputName="searchCarrier"
              placeholder="Search"
              className="bg-white focus:bg-white pl-8 pr-7 placeholder:text-gray500 shadow-sm font-normal search-input"
              icon={
                <SearchLg className="absolute top-1/2 -translate-y-1/2 left-2 text-grayText h-4 w-4" />
              }
              value={searchInput}
              isClearable={true}
              parentClassName="table-searchInput max-mdm:flex-1"
              inputType="text"
              onChangeFunc={(e: any) => {
                const searchValue = e.target.value;
                setSearchInput(searchValue);
                debouncedSearch(searchValue);
              }}
              autoFocus={false}
            />
            <div className="table-recordsPerPage">
              <SelectBox
                name="recordsPerPageGroup"
                id="recordsPerPageGroup"
                className="form_control shadow"
                size="sm"
                options={recordsPerPageArray}
                onChangeFunc={(event: any) => {
                  setRecordsPerPage(event.value);
                  setCurrentPage(1);
                  setParams((old: any) => ({
                    ...old,
                    limit: event.value,
                    page: 1,
                  }));
                }}
                isSearchable={false}
                value={recordsPerPageArray.find(
                  (val: any) => val.value === recordsPerPage
                )}
                isDisabled={skipUpdateForEmptyData()}
              />
            </div>
          </div>
        </div>

        <div className="w-full border-t border-gray100 flex flex-col flex-1">
          <div className="overflow-x-auto custom-scrollbar scrollbar-hide flex-1">
            <TableCmp
              params={sortData}
              setParams={(updaterFn: any) => {
                if (skipUpdateForEmptyData()) {
                  return;
                }

                if (typeof updaterFn === 'function') {
                  const newSortData = updaterFn(sortData);
                  setSortData(newSortData);

                  setParams((old: any) => ({
                    ...old,
                    sortField: newSortData.sortField,
                    sortType: newSortData.sortType,
                    page: 1,
                  }));
                }
              }}
              headCells={headCells}
              tableDataArr={isLoading ? [] : customerList}
              TableRowCmp={CustomerSplitTableRow}
              TableLoaderRowCmp={CustomerSplitTableRowLoader}
              isTableDataLoading={isLoading}
              numberOfSkeletonRows={recordsPerPage}
              onRowClick={(currentCustomer: any) => {
                handleOnRowClick(currentCustomer);
              }}
              isTableRowClickable={false}
              subDataRowCmpProps={{ selectedCustomer: selectedCustomer }}
              isDisplaySubData={isShowOrdersModal}
              selectedData={selectedCustomer}
              // SubDataCmp={CustomerOrdersDetail}
              tableRowCmpProps={{
                customerList: customerList,
                selectedCustomer: selectedCustomer,
                isShowOrdersModal: isShowOrdersModal,
              }}
              // TableFooterRowCmp={CustomerListTableFooterRow}
              // footerDataArr={[totalObj]}
            />
          </div>
          {customerList.length <= 0 && !isDataLoading && (
            <NotFoundUI
              title="No Customer found"
              desc="There is no data available for customer split analytics."
              containerClassName="min-h-[unset]"
            />
          )}
          <div className="w-full">
            {!isLoading && (
              <CustomPagination
                recordsPerPage={recordsPerPage}
                totalRecords={totalRecords || customerList.length}
                currentPage={currentPage}
                handlePagination={(page) => {
                  if (skipUpdateForEmptyData()) {
                    return;
                  }

                  setCurrentPage(page);
                  setParams((old: any) => ({
                    ...old,
                    page: page,
                  }));
                }}
              />
            )}
          </div>
        </div>
      </div>

      {/* {isShowPieChartModal && (
        <CustomerSplitPieChartModal
          handleClose={() => setIsShowPieChartModal(false)}
          customerList={customerList}
        />
      )} */}
    </>
  );
};

export default CustomerSplit;
