import { CalendarCheck01, User01 } from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { updateSalesRepHistory } from 'src/services/CustomerService';
import { listUser } from 'src/services/TeamMemberService';
import {
  getDateWithSuffixFormat,
  formatDateValue,
  getSelectBoxOptions,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';

interface IProps {
  editAllocationHistoryModal: any;
  handleClose: any;
}

const EditAllocationHistoryModal = ({
  editAllocationHistoryModal,
  handleClose,
}: IProps) => {
  const [isUpdateSalesRepHistoryLoading, setIsUpdateSalesRepHistoryLoading] =
    useState(false);
  const [isSalesRepLoading, setIsSalesRepLoading] = useState(true);
  const [salesRepList, setSalesRepList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState(
    editAllocationHistoryModal?.userId
  );
  const [startDateValue, setStartDateValue] = useState(
    editAllocationHistoryModal.startDate
  );
  const [endDateValue, setEndDateValue] = useState(
    editAllocationHistoryModal.endDate
  );

  useEffect(() => {
    setIsSalesRepLoading(true);

    listUser({ type: 'sales' })
      .then((response: any) => {
        const updatedListAllMembers = getSelectBoxOptions(
          response.data,
          'id',
          'firstName',
          true,
          'imageUrl',
          'image',
          undefined,
          'lastName',
          true
        );

        setSalesRepList([...updatedListAllMembers]);
        setIsSalesRepLoading(false);
      })
      .catch(console.log);
  }, []);

  const handleUpdateSalesHistory = () => {
    setIsUpdateSalesRepHistoryLoading(true);
    const apiPayload: any = {
      startDate: startDateValue
        ? moment(startDateValue).format('YYYY-MM-DD')
        : null,
      endDate: endDateValue ? moment(endDateValue).format('YYYY-MM-DD') : null,
    };

    if (selectedUser != editAllocationHistoryModal?.userId) {
      apiPayload.selectedSalesRepId = selectedUser;
    }

    updateSalesRepHistory(editAllocationHistoryModal?.id, apiPayload)
      .then(() => {
        // WalToast.success(response?.message);
        handleClose(true);
      })
      .catch((e: any) => {
        console.log('Error ', e?.response?.data?.message);
        WalToast.error(
          e?.response?.data?.message ??
            'Something went wrong updating sales rep history.'
        );
      })
      .finally(() => {
        setIsUpdateSalesRepHistoryLoading(false);
      });
  };

  const handleStartDateChange = (value: any) => {
    setStartDateValue(value);
  };

  const handleEndDateChange = (value: any) => {
    setEndDateValue(value);
  };

  return (
    <CommonModal
      title={'Edit Sales Representative'}
      titleDesc={'Update and mange your sales representative'}
      handleClose={() => handleClose(false)}
      headerIcon={<User01 />}
      size={'xl:max-w-[647px] max-w-[551px] overflow-unset'}
      isOverflow={false}
      modalClassName=""
      primaryBtnText="Save Changes"
      primaryBtnOnClick={handleUpdateSalesHistory}
      primaryBtnLoading={isUpdateSalesRepHistoryLoading}
      primaryBtnDisabled={isUpdateSalesRepHistoryLoading}
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
      secondaryBtnDisabled={isUpdateSalesRepHistoryLoading}
    >
      <div className="w-full p-5">
        <form className="flex flex-col gap-4">
          <SelectBox
            name="userListGroup"
            label="Update Sales Representative"
            labelClassName="block mb-1.5 form_label"
            id="userListGroup"
            size="sm"
            placeholder="Select first name"
            noOptionMessage="No Users Found"
            isSearchable={true}
            className="form_control"
            isClearable={true}
            options={salesRepList}
            isLoading={isSalesRepLoading}
            onChangeFunc={(event: any) => {
              setSelectedUser(event?.value ?? '');
            }}
            value={salesRepList.filter(
              (user: any) => selectedUser === user.value
            )}
          />

          <div className="flex max-sm:flex-col sm:-mx-1.5">
            <DateSelect
              inputName="startDate"
              className={`form_control`}
              parentClassName="sm:px-1.5 sm:w-1/2 one-month-datepicker"
              label={
                <div className="flex justify-between">
                  <span>Start Date</span>
                  {editAllocationHistoryModal?.minAllowedStartDate ? (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        handleStartDateChange(
                          moment(
                            editAllocationHistoryModal?.minAllowedStartDate
                          ).toDate()
                        );
                      }}
                    >
                      Set to Earliest
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              }
              labelClassName="block w-full"
              placeholder="Start Date"
              dateFormat="dd/MM/yyyy"
              selected={
                startDateValue ? moment(startDateValue).toDate() : undefined
              }
              onChangeFunc={(e: any) => handleStartDateChange(e?.value)}
              icon={<CalendarCheck01 className="h-5 w-5" />}
              value={getDateWithSuffixFormat(formatDateValue(startDateValue))}
              isShowCustomMonthYearHeader
            />

            <DateSelect
              inputName="endDate"
              className={`form_control`}
              parentClassName="sm:px-1.5 sm:w-1/2 one-month-datepicker max-sm:mt-4"
              label={
                <div className="flex justify-between">
                  <span>End Date</span>
                  {editAllocationHistoryModal?.maxAllowedEndDate ? (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => {
                        handleEndDateChange(
                          moment(
                            editAllocationHistoryModal?.maxAllowedEndDate
                          ).toDate()
                        );
                      }}
                    >
                      Set to Latest
                    </span>
                  ) : (
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => handleEndDateChange(null)}
                    >
                      Set as Present
                    </span>
                  )}
                </div>
              }
              labelClassName="block w-full"
              placeholder="End Date"
              dateFormat="dd/MM/yyyy"
              selected={
                endDateValue ? moment(endDateValue).toDate() : undefined
              }
              onChangeFunc={(e: any) => handleEndDateChange(e?.value)}
              icon={<CalendarCheck01 className="h-5 w-5" />}
              isClearable={!editAllocationHistoryModal.endDate}
              isShowCustomMonthYearHeader
              value={
                endDateValue
                  ? getDateWithSuffixFormat(formatDateValue(endDateValue))
                  : 'Present'
              }
            />
          </div>
        </form>
      </div>
    </CommonModal>
  );
};

export default EditAllocationHistoryModal;
