import { yupResolver } from '@hookform/resolvers/yup';
import { Plus, XClose } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ButtonCmp from 'src/components/ButtonCmp';
import CommonModal from 'src/components/CommonModal';
import ConfirmationModalCmp from 'src/components/ConfirmModal/ConfirmationModalCmp';
import InputText from 'src/components/InputText/InputText';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import SelectBox from 'src/components/SelectBox/SelectBox';
import { usePhone } from 'src/hooks/usePhone';
import { addNewContact, ContactList } from 'src/services/CallCenterService';
import {
  addOrderAgentContactDetails,
  getAiAgentDetails,
  getRadarAiAgentDetails,
} from 'src/services/RadarService';
import { getSelectBoxOptions } from 'src/utils/CommonFunctions';
import * as yup from 'yup';

import inboundEmoji from '../../assets/img/ai-emoji2.webp';

import DeliveryModal from './DeliveryModal';
import FollowUpModal from './FollowUpModal';
import InTransitModal from './InTransitModal';
import PickUpModal from './PickUpModal';
import ReviewConfig from './ReviewConfig';

const trackAndTraceAISchema = yup.object().shape({
  carrierId: yup.string().required(),
  contactId: yup.string().nullable(),
  countryRegionCode: yup.string().nullable(),
  email: yup.string().email().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup
    .string()
    .test('isValidPhone', function (value) {
      const { countryRegionCode: phoneCountryCode } = this.parent;
      const { isPhoneValid, isGetCountryCallingCode } = usePhone();

      if (value?.length === isGetCountryCallingCode(phoneCountryCode)?.length) {
        return this.createError({
          message: 'Contact number is required.',
        });
      }

      const isValid = isPhoneValid(value, phoneCountryCode);

      if (!isValid) {
        return this.createError({
          message: 'Invalid phone number.',
        });
      }

      return true;
    })
    .required('Phone number is required'),
  extension: yup.string().nullable(),
});

interface IProps {
  handleClose: () => void;
  activeLegData: any;
  setActiveLegData: any;
}

const TrackAndTraceAIModal = ({
  handleClose,
  activeLegData,
  setActiveLegData,
}: IProps) => {
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
    setError,
    trigger,
  } = useForm({
    resolver: yupResolver(trackAndTraceAISchema),
    mode: 'onChange',
    defaultValues: {
      carrierId: '',
      contactId: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      extension: '',
      countryRegionCode: '',
    },
  });
  const contactId = watch('contactId');
  const [step, setStep] = useState(1);
  const [contacts, setContacts] = useState<any[]>([]);
  const [contactsOptions, setContactsOptions] = useState<any[]>([]);
  const [isContactLoading, setIsContactLoading] = useState<boolean>(true);
  const [isPhoneNumberValue, setIsPhoneNumberValue] = useState<boolean>(true);
  const [saveContactloading, setSaveContactloading] = useState(false);
  const [reviewConfigData, setReviewConfigData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [launchAgentLoading, setLaunchAgentLoading] = useState(false);
  const [aiAgentDetails, setAiAgentDetails] = useState<any>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<string>('');

  const pickupModalRef = useRef<any>(null);
  const deliveryModalRef = useRef<any>(null);
  const inTransitModalRef = useRef<any>(null);
  const followUpModalRef = useRef<any>(null);
  const reviewConfigModalRef = useRef<any>(null);

  const makeToFillDispatchDetail = (isContactId: any) => {
    const contactInfo = contacts.find((item) => item.id === isContactId);
    setIsPhoneNumberValue(true);
    const firstName = contactInfo?.name
      ? contactInfo?.name?.split(' ', 2)?.[0]
      : '';
    const lastName = contactInfo?.name
      ? contactInfo?.name?.split(' ', 2)?.[1]
      : '';
    setValue('email', contactInfo?.email ? contactInfo?.email : '');
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('phoneNumber', contactInfo?.number ? contactInfo?.number : '');
    setValue('extension', contactInfo?.extension ? contactInfo?.extension : '');
  };

  const getContactData = (signal?: any) => {
    setContacts([]);
    setIsContactLoading(true);
    setValue(
      'carrierId',
      activeLegData?.carrierPrice?.carrierId
        ? activeLegData?.carrierPrice?.carrierId
        : ''
    );
    const queryParams = {
      isCarrierId: activeLegData?.carrierPrice?.carrierId,
      type: 'dispatcher',
    };

    ContactList(queryParams, signal)
      .then((response: any) => {
        if (response.data) {
          setContacts(response.data);
          const contactsSelectBoxOptions: any = getSelectBoxOptions(
            response.data,
            'id',
            'name',
            true,
            'imageUrl',
            'image'
          );

          setContactsOptions(contactsSelectBoxOptions);
        }

        setIsContactLoading(false);
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        }

        setContacts([]);
        setIsContactLoading(false);
      });
  };

  const getOrderAIAgentDetails = async () => {
    const queryParams = `?masterOrderId=${activeLegData?.masterOrderId}&legId=${activeLegData?.id}`;
    await getRadarAiAgentDetails(queryParams)
      .then((response) => {
        if (response?.data) {
          setReviewConfigData(response?.data);
          setValue(
            'contactId',
            response?.data?.isDispatch?.contactId
              ? response?.data?.isDispatch?.contactId
              : null
          );
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  };

  const getAiAgent = async () => {
    const queryParams = {
      email: 'travis@westernalliancelogistics.com',
      associatedWith: 'RADAR',
    };
    await getAiAgentDetails(queryParams)
      .then((response) => {
        setAiAgentDetails(response?.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getContactData();
    getOrderAIAgentDetails();
    getAiAgent();
  }, []);

  useEffect(() => {
    if (contactId) {
      makeToFillDispatchDetail(contactId);
    }
  }, [contacts, contactId]);

  const onSubmit = async (data: any) => {
    if (!contactId || loading) {
      return;
    }

    if (reviewConfigData?.isDispatch?.contactId === contactId) {
      setStep(3);

      return;
    }
    const payload = {
      id: reviewConfigData?.isDispatch?.id,
      masterOrderId: activeLegData?.masterOrderId,
      legId: activeLegData?.id,
      agentId: aiAgentDetails?.id,
      contactId: data?.contactId,
    };

    setLoading(true);
    await addOrderAgentContactDetails(payload)
      .then((response) => {
        setStep(3);
        setValue(
          'contactId',
          response?.data?.contactId ? response?.data?.contactId : null
        );
        getOrderAIAgentDetails();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveContact = async () => {
    const isValid = await trigger([
      'email',
      'firstName',
      'lastName',
      'phoneNumber',
    ]);

    if (!isValid || saveContactloading) {
      return;
    }
    const values = getValues([
      'carrierId',
      'email',
      'firstName',
      'lastName',
      'phoneNumber',
      'extension',
    ]);
    const contactData = {
      carrierId: activeLegData?.carrierPrice?.carrierId,
      email: values[1],
      name: `${values[2]} ${values[3]}`,
      number: values[4],
      extension: values[5],
      contactType: 'dispatcher',
    };

    setSaveContactloading(true);
    await addNewContact(contactData)
      .then((response) => {
        if (response?.data?.id) {
          setValue('contactId', response?.data?.id ? response?.data?.id : null);
          getContactData();
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setSaveContactloading(false);
      });
  };

  const handleNext = () => {
    if (step == 1) {
      setStep(2);
    } else if (step == 2) {
      handleSubmit(onSubmit)();
    } else if (step == 3) {
      reviewConfigModalRef.current?.reviewConfigValidate();
      // setIsConfirmationModalOpen(activeLegData?.agentId ? 'abort' : 'launch');
    } else if (step == 4) {
      pickupModalRef.current?.pickupSubmit();
    } else if (step == 6) {
      deliveryModalRef.current?.deliverySubmit();
    } else if (step == 5) {
      inTransitModalRef.current?.inTransitSubmit();
    } else if (step == 7) {
      followUpModalRef.current?.followUpSubmit();
    }
  };

  return (
    <>
      <CommonModal
        title={''}
        handleClose={handleClose}
        size={`${
          step == 3
            ? 'max-w-[550px]'
            : step == 1
            ? 'max-w-[480px]'
            : 'max-w-[550px]'
        }`}
        primaryBtnText={
          step == 3
            ? activeLegData?.agentId
              ? 'Abort'
              : 'Launch'
            : step < 3
            ? 'Next'
            : 'Save'
        }
        primaryBtnOnClick={() => handleNext()}
        primaryBtnLoading={loading}
        primaryBtnDisabled={
          loading || saveContactloading || (!contactId && step === 2)
        }
        secondaryBtnText={step == 1 ? 'Close' : 'Back'}
        secondaryBtnOnClick={() => {
          if (step === 1) {
            handleClose();
          } else {
            setStep(step - ({ 5: 2, 6: 3, 7: 4 }[step] || 1));
          }
        }}
        secondaryBtnDisabled={loading}
        modalFooterClass="!border-0 !p-6"
        modalBtnTypeClass="btn-outline-primary"
        modalClassName="flex flex-col"
      >
        <div className="[&>*]:px-6 pt-6 pb-2 space-y-5 flex flex-col overflow-y-auto custom-scrollbar-v2">
          {step === 1 && (
            <>
              <div>
                <div className="rounded-lg bg-utilityPurple300 p-1">
                  <figure className="w-[200px] h-[200px] mx-auto">
                    <img
                      src={inboundEmoji}
                      className="w-full h-full object-contain"
                    />
                  </figure>
                </div>
              </div>
              <div className="text-center space-y-1">
                <h6 className="text-grayLight900 text-lg font-semibold">
                  Track and Trace With Travis
                </h6>
                <p className="text-grayLight600 text-sm font-normal max-w-[352px] mx-auto">
                  AI Track & Trace Supervisor monitoring shipments from pickup
                  to delivery.
                </p>
              </div>
              <ul className="flex items-center justify-center gap-x-4">
                <li
                  className={`w-2.5 h-2.5 rounded-full ${
                    step === 1 ? 'bg-primary' : 'bg-utilityGray200'
                  }`}
                ></li>
                <li
                  className={`w-2.5 h-2.5 rounded-full bg-utilityGray200 `}
                ></li>
                <li
                  className={`w-2.5 h-2.5 rounded-full bg-utilityGray200`}
                ></li>
                <li
                  className={`w-2.5 h-2.5 rounded-full bg-utilityGray200`}
                ></li>
              </ul>
            </>
          )}

          {step >= 2 && (
            <div className="flex justify-between">
              <div className="w-12 h-12 rounded-full bg-utilityPurple300 relative">
                <img
                  src={inboundEmoji}
                  className="w-full h-full object-contain"
                />
                <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] p-[47px]"></div>
                <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-80 p-[71px]"></div>
                <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-60 p-[95px]"></div>
                <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-40 p-[119px]"></div>
                <div className="border border-utilityGray200 w-full h-full rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-[1] opacity-20 p-[143px]"></div>
              </div>
              <div
                className={`cursor-pointer [&>svg]:w-4 [&>svg]:text-grayLight600 bg-transparent hover:bg-[#e5e5e5] transition-all duration-300 h-8 w-8 flex justify-center items-center rounded-full flex-none`}
              >
                <XClose
                  className="text-gray400 hover:text-textSecondary"
                  onClick={handleClose}
                />
              </div>
            </div>
          )}

          {step === 2 && (
            <>
              <div className="space-y-1">
                <h2 className="text-grayLight900 text-lg font-semibold">
                  Add Dispatch Contact Details
                </h2>
                <p className="text-grayLight600 text-sm font-normal">
                  Let me Know main point of my contact from tracking this load.
                </p>
              </div>
              <div className="flex-1 overflow-auto custom-scrollbar-v2">
                <div className="flex flex-wrap space-y-5 [&>div]:px-1.5 -mx-1.5">
                  <Controller
                    name="contactId"
                    control={control}
                    render={({ field: { value } }) => (
                      <SelectBox
                        parentClassName="w-full"
                        labelWrapClassName="mb-1.5"
                        labelRight={
                          <ButtonCmp
                            className="btn-link-custom"
                            icon={<Plus className="w-4 h-4" />}
                            onClick={handleSaveContact}
                            disabled={!!contactId || saveContactloading}
                          >
                            Save Contact
                          </ButtonCmp>
                        }
                        isClearable
                        name="contactId"
                        required
                        placeholder="Select Contact"
                        label="Select Contact"
                        className={`form_control`}
                        labelClassName="form_label mb-0 block"
                        isSearchable={true}
                        isLoading={isContactLoading}
                        options={contactsOptions}
                        value={contactsOptions.filter(
                          (val) => val?.value == value
                        )}
                        onChangeFunc={(e: any) => {
                          setValue('contactId', e?.value ? e?.value : null);
                          makeToFillDispatchDetail(e?.value);
                          clearErrors();
                        }}
                        errorText={errors.contactId ? true : false}
                      />
                    )}
                  />
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        parentClassName="w-full"
                        inputName="email"
                        inputType="text"
                        label={
                          <>
                            Email Address
                            <span className="text-red-600 ms-[2px] leading-4 text-xs undefined">
                              *
                            </span>{' '}
                            <span className="text-gray500 text-[11px]">
                              (from Rate Confirmation)
                            </span>
                          </>
                        }
                        labelClassName="block mb-1.5"
                        placeholder="Enter your email address"
                        className="form_control"
                        value={value ?? ''}
                        onFocus={() => {
                          setValue(
                            'email',
                            value ? value.replace(/[$,\s]/g, '') : ''
                          );
                        }}
                        onChangeFunc={(e) => {
                          const val = e.target.value;
                          onChange(val);
                        }}
                        errorText={errors.email ? true : false}
                        disabled={!!contactId}
                        required
                        requiredClassName="hidden"
                      />
                    )}
                  />

                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        parentClassName="w-1/2"
                        inputName="firstName"
                        inputType="text"
                        label="First Name"
                        labelClassName="block mb-1.5"
                        placeholder="First Name"
                        className="form_control"
                        value={value ?? ''}
                        onChangeFunc={(e) => {
                          const val = e.target.value;
                          onChange(val);
                        }}
                        errorText={errors.firstName ? true : false}
                        disabled={!!contactId}
                        required
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        parentClassName="w-1/2"
                        inputName="lastName"
                        inputType="text"
                        label="Last Name"
                        labelClassName="block mb-1.5"
                        placeholder="Last Name"
                        className="form_control"
                        value={value ?? ''}
                        onChangeFunc={(e) => {
                          const val = e.target.value;
                          onChange(val);
                        }}
                        errorText={errors.lastName ? true : false}
                        disabled={!!contactId}
                        required
                      />
                    )}
                  />

                  <div className="flex-1">
                    <label className="form_label block mb-1.5">
                      Phone Number
                      <span className="text-red-600 ms-[2px] leading-4 text-xs">
                        *
                      </span>
                    </label>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      render={() => (
                        <PhoneInput
                          name="phoneNumber"
                          value={`${watch('phoneNumber')}`}
                          onNumberChange={(
                            country: any,
                            phone: any,
                            code: any,
                            isValid: boolean
                          ) => {
                            const newValue = `+${code}${phone}`;
                            setValue('phoneNumber', newValue);
                            setValue('countryRegionCode', country);

                            if (isValid && phone !== '') {
                              clearErrors('phoneNumber');
                            } else if (!isValid && phone !== '') {
                              setError('phoneNumber', {
                                type: 'manual',
                                message: 'Contact number is invalid.',
                              });
                            }
                          }}
                          isPhoneNumberValue={isPhoneNumberValue}
                          setIsPhoneNumberValue={setIsPhoneNumberValue}
                          errors={errors.phoneNumber ? true : false}
                          disabled={!!contactId}
                          dropdownDelayClassname="bottom-full"
                        />
                      )}
                    />
                  </div>

                  <Controller
                    name="extension"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        parentClassName="w-[128px]"
                        inputName="extension"
                        inputType="number"
                        label="Extension"
                        labelClassName="block mb-1.5"
                        placeholder="Extension"
                        className="form_control"
                        value={value ?? ''}
                        onFocus={() => {
                          setValue(
                            'extension',
                            value ? value.replace(/[$,\s]/g, '') : ''
                          );
                        }}
                        onChangeFunc={(e) => {
                          const val = e.target.value;
                          onChange(val);
                        }}
                        errorText={errors.extension ? true : false}
                        disabled={!!contactId}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          )}

          {step === 3 && (
            <ReviewConfig
              setStep={setStep}
              reviewConfigData={reviewConfigData}
              activeLegData={activeLegData}
              reviewConfigModalRef={reviewConfigModalRef}
              launchAgentLoading={launchAgentLoading}
              setLaunchAgentLoading={setLaunchAgentLoading}
              aiAgentDetails={aiAgentDetails}
              setActiveLegData={setActiveLegData}
              handleSuccess={() => {
                setIsConfirmationModalOpen('');
              }}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
          )}

          {step === 4 && (
            <PickUpModal
              activeLegData={activeLegData}
              setStep={setStep}
              reviewConfigData={reviewConfigData}
              pickupModalRef={pickupModalRef}
              loading={loading}
              setLoading={setLoading}
              getOrderAIAgentDetails={getOrderAIAgentDetails}
              aiAgentDetails={aiAgentDetails}
            />
          )}
          {step === 5 && (
            <InTransitModal
              activeLegData={activeLegData}
              setStep={setStep}
              reviewConfigData={reviewConfigData}
              inTransitModalRef={inTransitModalRef}
              loading={loading}
              setLoading={setLoading}
              getOrderAIAgentDetails={getOrderAIAgentDetails}
              aiAgentDetails={aiAgentDetails}
            />
          )}
          {step === 6 && (
            <DeliveryModal
              activeLegData={activeLegData}
              setStep={setStep}
              reviewConfigData={reviewConfigData}
              deliveryModalRef={deliveryModalRef}
              loading={loading}
              setLoading={setLoading}
              getOrderAIAgentDetails={getOrderAIAgentDetails}
              aiAgentDetails={aiAgentDetails}
            />
          )}
          {step === 7 && (
            <FollowUpModal
              activeLegData={activeLegData}
              setStep={setStep}
              reviewConfigData={reviewConfigData}
              followUpModalRef={followUpModalRef}
              loading={loading}
              setLoading={setLoading}
              getOrderAIAgentDetails={getOrderAIAgentDetails}
              aiAgentDetails={aiAgentDetails}
            />
          )}
        </div>
      </CommonModal>
      {isConfirmationModalOpen && (
        <ConfirmationModalCmp
          title={
            isConfirmationModalOpen === 'launch'
              ? 'Launch AI Agent'
              : 'Abort AI Agent'
          }
          description={
            isConfirmationModalOpen === 'launch'
              ? 'Are you sure you want to launch the AI agent?'
              : 'Are you sure you want to abort the AI agent?'
          }
          Icon={''}
          handleClose={() => setIsConfirmationModalOpen('')}
          isSubmitting={launchAgentLoading}
          handleSubmit={() => {
            reviewConfigModalRef.current?.reviewConfigSubmit();
          }}
          submitBtnText={'Confirm'}
        />
      )}
    </>
  );
};

export default TrackAndTraceAIModal;
