import {
  CalendarCheck01,
  RefreshCw05,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import {
  getDateWithSuffixFormat,
  formatDateValue,
} from 'src/utils/CommonFunctions';

interface IProps {
  handleClose: any;
  handleSubmit: any;
  isExpiredRefresh: boolean;
}

const RefreshRatesModal = ({
  handleClose,
  handleSubmit,
  isExpiredRefresh,
}: IProps) => {
  const [date, setDate] = React.useState(moment().toDate());

  return (
    <CommonModal
      title={`Refresh ${isExpiredRefresh ? 'Expired' : 'All'} Rates`}
      titleDesc={'Set a new pickup date for the shipment.'}
      handleClose={() => handleClose(false)}
      headerIcon={<RefreshCw05 />}
      size={'max-w-[400px]'}
      modalClassName=""
      primaryBtnOnClick={() => handleSubmit(date)}
      primaryBtnText="Confirm"
      secondaryBtnText="Cancel"
      secondaryBtnOnClick={() => handleClose(false)}
    >
      <div className="w-full p-5 gap-4">
        <DateSelect
          inputName="pickup_date"
          className="form_control"
          parentClassName="datepicker-w-auto one-month-datepicker"
          label="Pickup Date"
          selected={date}
          labelClassName="block"
          placeholder="Pickup Date"
          required={true}
          dateFormat="dd/MM/yyyy"
          errorText={() => {}}
          minDate={moment().toDate()}
          onChangeFunc={(e: any) => {
            console.log(e.value);
            setDate(moment(e.value, 'dd/MM/yyyy').toDate());
          }}
          value={getDateWithSuffixFormat(formatDateValue(date))}
          icon={<CalendarCheck01 className="h-5 w-5" />}
        />
      </div>
    </CommonModal>
  );
};

export default RefreshRatesModal;
