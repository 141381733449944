/* eslint-disable max-lines-per-function */
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckBox from 'src/components/CheckBox';
import ErrorMsg from 'src/components/errorMsg';
import InputText from 'src/components/InputText/InputText';
import SelectBox from 'src/components/SelectBox/SelectBox';
import TabButton from 'src/components/TabButton';
import { PATH } from 'src/constants/path';
import { BasicContext } from 'src/context/BasicContext';
import { createdLoadLinkPosting } from 'src/services/LoadLinkService';
import {
  getRoundUpLinearFootage,
  setNumberWithCommas,
} from 'src/utils/CommonFunctions';
import WalToast from 'src/utils/WalToast';
import * as yup from 'yup';

import datIcon from '../../../../../assets/img/dat-icon.svg';
import loadLinkIcon from '../../../../../assets/img/load-link-icon.svg';
import truckStopIcon from '../../../../../assets/img/truck-stop-icon.svg';
import { QUOTE_CLASSIFICATION } from '../../../../../constants/common';

import { QuoteType } from './LoadBoard.interface';
// import TruckFindingAi from './TruckFindingAi';
import TruckFindingAiModals from './TruckFindingAiModals';

const today = new Date();
today.setHours(0, 0, 0, 0);
const radiusOptions = [
  {
    label: '50 Miles',
    value: 50,
  },
  {
    label: '100 Miles',
    value: 100,
  },
  {
    label: '150 Miles',
    value: 150,
  },
  {
    label: '200 Miles',
    value: 200,
  },
];

interface IProps {
  addressDetails: any;
  validatePostToLoadBoardForm: any;
  setValidatePostToLoadBoardForm: any;
  setIsPostCreateLoading: any;
  setIsRefresh: any;
  loadLinkPostingDetail?: any;
  setLoadLinkPostingDetail: any;
  isUpdateLoadPost: any;
  setIsUpdateLoadPost: any;
  setIsCreateLoadPost: any;
  rateGatheringWithAi: any;
  setShowTimerLoader: any;
  setCurrentTab: any;
}

enum AddressDetails {
  ORIGIN = 'Origin',
  DESTINATION = 'destination',
}

enum EquipmentDetails {
  EQUIPMENTTYPES = 'equipmentTypes',
  ADDITIONAL = 'additional',
}

const CreateLoadPostingFrom = ({
  addressDetails,
  validatePostToLoadBoardForm,
  setValidatePostToLoadBoardForm,
  setIsPostCreateLoading,
  setIsRefresh,
  loadLinkPostingDetail,
  setLoadLinkPostingDetail,
  isUpdateLoadPost,
  setIsUpdateLoadPost,
  setIsCreateLoadPost,
  rateGatheringWithAi,
  setShowTimerLoader,
  setCurrentTab,
}: IProps) => {
  const {
    isVehicleTypeLoading,
    vehicleTypeOptions,
    isVehicleAttrLoading,
    vehicleAttrOptions,
    vehicleSizeOptions,
  } = useContext(BasicContext);

  const navigate = useNavigate();
  const location = useLocation();

  const loadPostingSchema = yup.object().shape({
    availabilityDate: yup
      .date()
      .required('Pickup date is required')
      .min(today, 'Pickup date cannot be in the past'),
    serviceType: yup.string().required('Service type is required'),
    vehicleAttribute: yup.array(),
    sourceState: yup.string().required('Origin state is required'),
    sourceCity: yup.string().required('Origin city is required'),
    sourceRadius: yup.number().required('Source radius is required.'),
    destinationState: yup.string().required('Destination state is required'),
    destinationCity: yup.string().required('Destination city is required'),
    destinationRadius: yup.number().required('Destination radius is required.'),
    totalWeight: yup.string().required('Total weight is required.'),
    specialInstructionsLoadBoard: yup.string(),
    specialInstructionsAiAgent: yup.string(),
    commodity: yup.string(),
    linearFootage: yup.string().when([], (field, schema) =>
      addressDetails?.classification === QUOTE_CLASSIFICATION.LTL
        ? schema
            .required('Linear footage is required')
            .test(
              'max',
              'Linear footage should be less than 53 Ft.',
              (value) => {
                if (!value) return true;

                return parseFloat(value.replace(/[^0-9.]/g, '')) <= 53;
              }
            )
        : schema
    ),
    loadPostingCompany: yup
      .array()
      .min(1, 'Please select at least one load posting company'),
  });

  const localData = localStorage.getItem('load_post_form_data');
  let localFormData: any = {};

  if (localData) {
    const localLoadPostFormObject = JSON.parse(localData);

    if (Object.hasOwn(localLoadPostFormObject, addressDetails?.id)) {
      localFormData = localLoadPostFormObject[addressDetails?.id];
    }
  }

  const [postCreatePayload, setPostCreatePayload] = useState({});
  const [selectedEquipmentType, setSelectedEquipmentType] = useState('Primary');
  const [primaryEquipmentType, setPrimaryEquipmentType] = useState(
    localFormData?.primaryEquipmentType
      ? localFormData?.primaryEquipmentType
      : loadLinkPostingDetail?.primaryEquipmentType
      ? loadLinkPostingDetail?.primaryEquipmentType
      : addressDetails?.primaryEquipmentType
      ? addressDetails.primaryEquipmentType
      : ''
  );
  const [primaryEquipmentTypeFullName, setPrimaryEquipmentTypeFullName] =
    useState('');
  const [primaryEquipmentTypeError, setPrimaryEquipmentTypeError] =
    useState(false);
  const [secondaryEquipmentType, setSecondaryEquipmentType] = useState<any[]>(
    localFormData?.secondaryEquipmentType
      ? localFormData?.secondaryEquipmentType
      : loadLinkPostingDetail?.secondaryEquipmentType
      ? loadLinkPostingDetail?.secondaryEquipmentType.split(',')
      : addressDetails?.secondaryEquipmentTypes
      ? addressDetails.secondaryEquipmentTypes.split(',')
      : []
  );
  const [secondaryEquipmentTypeFullName, setSecondaryEquipmentTypeFullName] =
    useState<any[]>([]);
  const [vehicleAttributeFullNames, setVehicleAttributeFullNames] = useState<
    any[]
  >([]);
  const [isShowTruckFindingAiModal, setIsShowTruckFindingAiModal] =
    useState(false);
  const [addressDetail, setAddressDetail] = useState<any>(
    AddressDetails.ORIGIN
  );
  const [equipmentDetail, setEquipmentDetail] = useState<any>(
    EquipmentDetails.EQUIPMENTTYPES
  );
  const specialInstructionMaxLength = 13;

  const addressDetailArr = [
    {
      value: AddressDetails.ORIGIN,
      name: 'Origin',
    },
    {
      value: AddressDetails.DESTINATION,
      name: 'Destination',
    },
  ];

  const equipmentDetailArr = [
    {
      value: EquipmentDetails.EQUIPMENTTYPES,
      name: 'Equipment Types',
    },
    {
      value: EquipmentDetails.ADDITIONAL,
      name: 'Additional Services',
    },
  ];

  const classOptions = [
    {
      label: 'LTL',
      value: QUOTE_CLASSIFICATION.LTL,
    },
    {
      label: 'FTL',
      value: QUOTE_CLASSIFICATION.FTL,
    },
  ];

  const loadPostingCompanies = [
    {
      name: 'Loadlink Technologies',
      alias: 'loadlink',
      enable: true,
      icon: loadLinkIcon,
    },
    {
      name: 'Truckstop',
      alias: 'truckstop',
      enable: false,
      icon: truckStopIcon,
    },
    {
      name: 'DAT Freight & Analytics',
      alias: 'dat',
      enable: false,
      icon: datIcon,
    },
  ];

  const {
    handleSubmit,
    getValues,
    control,
    trigger,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(loadPostingSchema),
    defaultValues: {
      availabilityDate: new Date(),
      serviceType:
        localFormData?.serviceType ||
        loadLinkPostingDetail?.serviceType ||
        addressDetails?.classification ||
        '',
      vehicleAttribute: localFormData?.vehicleAttribute
        ? localFormData?.vehicleAttribute
        : loadLinkPostingDetail?.vehicleAttribute
        ? loadLinkPostingDetail?.vehicleAttribute?.split(',') || []
        : [],
      sourceState:
        localFormData?.sourceState ||
        loadLinkPostingDetail?.sourceState ||
        addressDetails?.shipperStateCode ||
        '',
      sourceCity:
        localFormData?.sourceCity ||
        loadLinkPostingDetail?.sourceCity ||
        addressDetails?.shipperCity ||
        '',
      sourceRadius:
        localFormData?.sourceRadius ||
        loadLinkPostingDetail?.sourceRadius ||
        200,
      destinationState:
        localFormData?.destinationState ||
        loadLinkPostingDetail?.destinationState ||
        addressDetails?.consigneeStateCode ||
        '',
      destinationCity:
        localFormData?.destinationCity ||
        loadLinkPostingDetail?.destinationCity ||
        addressDetails?.consigneeCity ||
        '',
      destinationRadius:
        localFormData?.destinationRadius ||
        loadLinkPostingDetail?.destinationRadius ||
        200,
      specialInstructionsLoadBoard:
        localFormData?.specialInstructionsLoadBoard ||
        loadLinkPostingDetail?.specialInstructionsLoadBoard ||
        '',
      specialInstructionsAiAgent:
        localFormData?.specialInstructionsAiAgent ||
        loadLinkPostingDetail?.specialInstructionsAiAgent ||
        '',
      commodity:
        localFormData?.commodity || loadLinkPostingDetail?.commodity || '',
      totalWeight: localFormData?.totalWeight
        ? localFormData.totalWeight
        : (loadLinkPostingDetail && loadLinkPostingDetail.totalWeight
            ? `${setNumberWithCommas(loadLinkPostingDetail.totalWeight)} lbs`
            : '') ||
          (addressDetails && addressDetails.totalWeight
            ? `${setNumberWithCommas(addressDetails.totalWeight)} lbs`
            : '') ||
          '',
      linearFootage:
        addressDetails?.classification === QUOTE_CLASSIFICATION.LTL
          ? (localFormData && localFormData.linearFootage
              ? getRoundUpLinearFootage(localFormData.linearFootage)
              : '') ||
            (loadLinkPostingDetail && loadLinkPostingDetail.linearFootage
              ? `${setNumberWithCommas(
                  getRoundUpLinearFootage(loadLinkPostingDetail.linearFootage)
                )} feet`
              : '') ||
            (addressDetails && addressDetails.linearFootage
              ? `${setNumberWithCommas(
                  getRoundUpLinearFootage(addressDetails.linearFootage)
                )} feet`
              : '')
          : '',
      loadPostingCompany:
        localFormData?.loadPostingCompany ||
        loadLinkPostingDetail?.loadPostingCompany.split(',') ||
        [],
    },
  });

  const serviceType = watch('serviceType') || '';
  const specialInstructionsLoadBoard = watch('specialInstructionsLoadBoard');

  const onSubmit = (data: any) => {
    const vehicleSize = vehicleSizeOptions.find((size: any) =>
      size.label.toLowerCase() ==
      [QUOTE_CLASSIFICATION.LTL, QUOTE_CLASSIFICATION.VOLUME_LTL].includes(
        serviceType
      )
        ? 'l'
        : 'tl'
    );

    const formDataPayload = {
      quoteId: addressDetails?.id,
      availabilityDate: moment.utc(data?.availabilityDate),
      pickupTime: '',
      window: 0,
      vehicleSize: vehicleSize?.value,
      serviceType: serviceType,
      primaryEquipmentType,
      primaryEquipmentTypeFullName: primaryEquipmentTypeFullName.toString(),
      secondaryEquipmentType: secondaryEquipmentType.toString(),
      secondaryEquipmentTypeFullName: secondaryEquipmentTypeFullName.toString(),
      vehicleAttribute: data?.vehicleAttribute?.toString(),
      vehicleAttributeFullNames: vehicleAttributeFullNames.toString(),
      specialInstructionsLoadBoard: data?.specialInstructionsLoadBoard,
      specialInstructionsAiAgent: data?.specialInstructionsAiAgent,
      sourceState: data?.sourceState,
      sourceCity: data?.sourceCity,
      sourceRadius: data?.sourceRadius,
      destinationState: data?.destinationState,
      destinationCity: data?.destinationCity,
      destinationRadius: data?.destinationRadius,
      totalWeight: data.totalWeight
        ? data.totalWeight.replace(/,|lbs/g, '').trim()
        : null,
      linearFootage: data.linearFootage
        ? data.linearFootage.replace(/,|feet/g, '').trim()
        : null,
      commodity: data?.commodity,
      loadPostingCompany: data?.loadPostingCompany.join(','),
      postId: null,
    };

    if (
      isUpdateLoadPost &&
      loadLinkPostingDetail &&
      loadLinkPostingDetail?.id
    ) {
      formDataPayload.postId = loadLinkPostingDetail?.id;
    }
    setPostCreatePayload(formDataPayload);
    setIsShowTruckFindingAiModal(true);
  };

  const storeFormDataLocal = (fieldName: any) => {
    if (loadLinkPostingDetail?.id) return;

    const storedData = localStorage.getItem('load_post_form_data');
    const loadPostFormData = storedData ? JSON.parse(storedData) : {};

    if (fieldName == 'equipmentType') {
      loadPostFormData[addressDetails.id] = {
        ...loadPostFormData[addressDetails.id],
        primaryEquipmentType: primaryEquipmentType,
        secondaryEquipmentType: secondaryEquipmentType,
      };
    } else {
      loadPostFormData[addressDetails.id] = {
        ...loadPostFormData[addressDetails.id],
        [fieldName]: getValues(fieldName),
      };
    }
    localStorage.setItem(
      'load_post_form_data',
      JSON.stringify(loadPostFormData)
    );
  };

  useEffect(() => {
    storeFormDataLocal('equipmentType');
  }, [primaryEquipmentType, secondaryEquipmentType]);

  const changeEquipmentType = (vehicleType: any) => (event: any) => {
    const checked = event.target.checked;

    if (checked) {
      if (!primaryEquipmentType) {
        setPrimaryEquipmentType(vehicleType.value);
        setPrimaryEquipmentTypeFullName(vehicleType.label);
      } else {
        const secEquipmentTypes =
          secondaryEquipmentType.length > 0 ? [...secondaryEquipmentType] : [];
        let secEquipmentTypeFullName: any =
          secondaryEquipmentTypeFullName.length > 0
            ? [...secondaryEquipmentTypeFullName]
            : [];
        secEquipmentTypes.push(vehicleType.value);
        secEquipmentTypeFullName.push(vehicleType.label);
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    } else {
      if (primaryEquipmentType == vehicleType.value) {
        if (secondaryEquipmentType.length > 0) {
          setPrimaryEquipmentType(secondaryEquipmentType[0]);
          setPrimaryEquipmentTypeFullName(secondaryEquipmentTypeFullName[0]);
          setSecondaryEquipmentType(secondaryEquipmentType.slice(1));
          setSecondaryEquipmentTypeFullName(
            secondaryEquipmentTypeFullName.slice(1)
          );
        } else {
          setPrimaryEquipmentType('');
          setPrimaryEquipmentTypeFullName('');
        }
      } else {
        const secEquipmentTypes = secondaryEquipmentType.filter(
          (item) => item !== vehicleType.value
        );
        const secEquipmentTypeFullName = secondaryEquipmentTypeFullName.filter(
          (item) => item !== vehicleType.value
        );
        setSecondaryEquipmentType(secEquipmentTypes);
        setSecondaryEquipmentTypeFullName(secEquipmentTypeFullName);
      }
    }
  };

  const handleSelectAddressDetails = (name: any, value: number) => {
    if (name === AddressDetails.ORIGIN) {
      setValue('sourceRadius', value);
    } else {
      setValue('destinationRadius', value);
    }
  };

  const onTruckFindingAiModalSubmit = (formDataPayload: any) => {
    setIsShowTruckFindingAiModal(false);
    setIsPostCreateLoading(true);
    createdLoadLinkPosting(formDataPayload)
      .then(() => {
        if (isUpdateLoadPost) {
          setIsUpdateLoadPost(false);
          setLoadLinkPostingDetail(undefined);
        } else {
          setIsCreateLoadPost(false);
        }

        const storedData = localStorage.getItem('load_post_form_data');
        const loadPostFormData = storedData ? JSON.parse(storedData) : {};
        if (loadPostFormData[addressDetails?.id])
          delete loadPostFormData[addressDetails?.id];
        localStorage.setItem(
          'load_post_form_data',
          JSON.stringify(loadPostFormData)
        );

        navigate(`${PATH.MY_QUOTE}/${addressDetails?.id}`, {
          state: {
            ...location.state,
            totalLoadPost: Math.max(
              (location.state?.totalLoadPost || 0) + 1,
              0
            ),
          },
        });

        setShowTimerLoader(true);
        setCurrentTab(QuoteType.LOAD_BOARD);
        setIsRefresh(true);
      })
      .finally(() => {
        setIsPostCreateLoading(false);
        setValidatePostToLoadBoardForm(false);
      })
      .catch((e: any) => {
        WalToast.error('Something went wrong while creating load link post.');
        console.log(e);
      });
  };

  useEffect(() => {
    const pEquipTypeFNames: any = vehicleTypeOptions.find(
      (type: any) => primaryEquipmentType == type.value
    );
    if (pEquipTypeFNames)
      setPrimaryEquipmentTypeFullName(pEquipTypeFNames.label);

    const secEquipTypeFNames: any = vehicleTypeOptions
      .filter((type: any) => secondaryEquipmentType.includes(type.value))
      .map((type: any) => type.label);
    setSecondaryEquipmentTypeFullName(secEquipTypeFNames);
  }, [vehicleTypeOptions]);

  useEffect(() => {
    const vehicleAttr: any = getValues('vehicleAttribute');
    const vehicleAttributeFNames = vehicleAttrOptions
      .filter((type: any) => vehicleAttr.includes(type.value))
      .map((type: any) => type.label);
    setVehicleAttributeFullNames(vehicleAttributeFNames);
  }, [vehicleAttrOptions]);

  const handleCommodityChange = (pEquipmentType: string) => {
    const commodity = getValues('commodity');
    if (commodity) return;

    switch (pEquipmentType) {
      case 'V':
      case 'K':
        setValue('commodity', 'Dry Freight');
        break;
      case 'F':
      case 'S':
      case 'I':
      case 'N':
        setValue('commodity', 'Construction Equipment (Tarp)');
        break;
      case 'R':
        setValue('commodity', 'Produce (Fresh)');
        break;
      case 'L':
      case 'D':
        setValue('commodity', 'Machinery');
        break;
      case 'default':
        break;
    }
  };

  const setDefaultCommodity = () => {
    if (primaryEquipmentType) {
      handleCommodityChange(primaryEquipmentType);
    }
  };

  const validateForm = async () => trigger();

  const handleManualSubmit = async () => {
    const isValid = await validateForm();

    if (!isValid) {
      setValidatePostToLoadBoardForm(false);
    }
  };

  const setTotalWeight = (e: any) => {
    setValue(
      'totalWeight',
      e.target.value ? `${setNumberWithCommas(e.target.value)} lbs` : ''
    );
    storeFormDataLocal('totalWeight');
  };

  const setLinearFootage = (e: any) => {
    const roundedValue = e.target.value
      ? getRoundUpLinearFootage(e.target.value)
      : '';

    setValue(
      'linearFootage',
      roundedValue ? `${setNumberWithCommas(roundedValue)} feet` : ''
    );
    storeFormDataLocal('linearFootage');
  };

  const checkPickupDate = () => {
    const currentDay = moment().format('dddd');

    if (currentDay === 'Saturday') {
      const tomorrow: any = moment().add(2, 'days');
      setValue('availabilityDate', tomorrow);
    } else if (currentDay === 'Sunday') {
      const tomorrow: any = moment().add(1, 'days');
      setValue('availabilityDate', tomorrow);
    }
  };

  useEffect(() => {
    if (primaryEquipmentType) {
      setSelectedEquipmentType('Secondary');
      setPrimaryEquipmentTypeError(false);
      setDefaultCommodity();
    } else {
      setSelectedEquipmentType('Primary');
    }
  }, [primaryEquipmentType]);

  useEffect(() => {
    setDefaultCommodity();
    checkPickupDate();
  }, []);

  useEffect(() => {
    if (validatePostToLoadBoardForm) {
      if (!primaryEquipmentType) {
        validateForm();
        setPrimaryEquipmentTypeError(true);
        setValidatePostToLoadBoardForm(false);
      } else {
        handleManualSubmit();
        handleSubmit(onSubmit)();
      }
    }
  }, [validatePostToLoadBoardForm]);

  const checkLoadPostingCompany = (company: any, onChange: any) => {
    if (!company.enable) return;
    const currentValues = getValues('loadPostingCompany') || [];
    const isSelected = currentValues.includes(company.alias);

    if (isSelected) {
      onChange(currentValues.filter((val: string) => val !== company.alias));
    } else {
      onChange([...currentValues, company.alias]);
    }
    storeFormDataLocal('loadPostingCompany');
  };

  return (
    <>
      <form className="smd:px-5 px-3 py-3">
        <div className="sm:-mx-1.5 flex flex-wrap gap-y-4">
          {/* first row */}
          <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full mx-1.5">
            <h5 className="text-grayLight900 mb-4 text-sm font-medium w-full">
              Select Load Boards
            </h5>
            <div className="flex flex-wrap gap-x-4">
              {loadPostingCompanies.map((company: any, index: number) => (
                <Controller
                  key={`load_posting_company_${index}`}
                  name="loadPostingCompany"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div
                      className={`flex-1 rounded-lg border p-3 flex gap-x-3 items-center shadow-none hover:shadow-md  ${
                        errors.loadPostingCompany && company.enable
                          ? 'border-red-500'
                          : 'border-utilityGray200'
                      } ${
                        company.enable
                          ? 'cursor-pointer bg-white '
                          : 'cursor-not-allowed bg-utilityGray100'
                      }`}
                      onClick={() => checkLoadPostingCompany(company, onChange)}
                    >
                      <CheckBox
                        onChangeFunc={() => {}}
                        disabled={!company.enable}
                        checked={value?.includes(company.alias)}
                      />
                      <img
                        src={company.icon}
                        alt="LoadLink"
                        className={`w-5 h-5 rounded-full object-contain`}
                      />
                      <p className={`text-grayLight900 text-sm font-medium`}>
                        {company.name}
                      </p>
                    </div>
                  )}
                />
              ))}
            </div>
          </div>
          <div className="sm:px-1.5 mdm:w-1/2 w-full">
            <div className="bg-gray25 p-4 xl:pb-3 border border-utilityGray200 shadow-xs rounded-xl h-full">
              <h5 className="text-grayLight900 mb-4 text-sm font-medium w-full min-h-[30px] flex items-center">
                Load Details
              </h5>
              <div className="-mx-1 flex flex-wrap gap-y-3">
                <Controller
                  name="serviceType"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <SelectBox
                      parentClassName="single-select-x-scroll w-[132px] min-w-fit px-1.5"
                      name="serviceType"
                      id="serviceType"
                      className="form_control shadow"
                      label="Service Type"
                      labelClassName="form_label mb-1.5 block"
                      size="sm"
                      isSearchable={false}
                      options={classOptions}
                      value={classOptions.filter(
                        (val: any) => getValues('serviceType') === val.value
                      )}
                      onChangeFunc={(e: any) => {
                        onChange(e.value);
                        storeFormDataLocal('serviceType');
                      }}
                    />
                  )}
                />

                <div className="xl:flex-1 xls:w-1/2 w-full px-1">
                  <Controller
                    name="totalWeight"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        label="Total Weight (lbs)"
                        inputName="totalWeight"
                        inputType="text"
                        placeholder="Enter Total Weight"
                        labelClassName="mb-1.5 block"
                        value={value}
                        required={true}
                        onFocus={() => {
                          setValue(
                            'totalWeight',
                            value.replace(/,|lbs/g, '').trim()
                          );
                        }}
                        onChangeFunc={(e) => {
                          const val = e.target.value;

                          if (/^\d*\.?\d*$/.test(val)) {
                            onChange(val);
                            storeFormDataLocal('totalWeight');
                          }
                        }}
                        onBlur={setTotalWeight}
                        errorText={errors.totalWeight ? true : false}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="commodity"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <InputText
                      inputName="commodity"
                      placeholder="Enter Commodity"
                      label="Commodity"
                      labelClassName="form_label block mb-1.5"
                      parentClassName="xl:flex-1 w-1/2 px-1"
                      id="commodity"
                      className="form_control"
                      value={value}
                      onChangeFunc={(e) => {
                        onChange(e.target.value);
                        storeFormDataLocal('commodity');
                      }}
                      onBlur={(e) => {
                        onChange(e.target.value);
                        storeFormDataLocal('commodity');
                      }}
                      required={false}
                    />
                  )}
                />
                {[
                  QUOTE_CLASSIFICATION.LTL,
                  QUOTE_CLASSIFICATION.VOLUME_LTL,
                ].includes(serviceType) && (
                  <Controller
                    name="linearFootage"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <InputText
                        inputName="linearFootage"
                        placeholder="Enter Linear Footage"
                        label="Linear Footage"
                        labelClassName="form_label block mb-1.5"
                        parentClassName="xl:flex-1 w-1/2 px-1"
                        id="linearFootage"
                        className="form_control"
                        value={value}
                        onFocus={() => {
                          setValue(
                            'linearFootage',
                            value ? value.replace(/,|feet/g, '').trim() : ''
                          );
                        }}
                        onChangeFunc={(e) => {
                          const val = e.target.value;

                          if (/^\d*\.?\d*$/.test(val)) {
                            onChange(val);
                            storeFormDataLocal('linearFootage');
                          }
                        }}
                        onBlur={setLinearFootage}
                        required={true}
                        errorText={
                          errors.linearFootage
                            ? errors.linearFootage.type == 'max'
                              ? errors.linearFootage.message
                              : true
                            : false
                        }
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="sm:px-1.5 mdm:w-1/2 w-full">
            <div className="bg-gray25 p-4 border border-utilityGray200 shadow-xs rounded-xl">
              <div className="flex justify-between items-center mb-4 gap-2 w-full">
                <h5 className="text-grayLight900 text-sm font-medium">
                  Address Details
                </h5>
                <TabButton
                  className="!min-h-[30px]"
                  activeClassName="!bg-utilityGray100"
                  tabArray={addressDetailArr}
                  isActive={addressDetail}
                  handleOnClick={(e: any) => {
                    setAddressDetail(e?.target?.dataset?.value);
                  }}
                />
              </div>

              <div className="flex flex-wrap -mx-1 xls:gap-y-8 gap-y-5">
                {addressDetail === AddressDetails.ORIGIN && (
                  <>
                    <Controller
                      name="sourceState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="sourceState"
                          label="State"
                          labelClassName="mb-1.5 block"
                          placeholder="Enter Origin State"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('sourceState');
                          }}
                          onBlur={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('sourceState');
                          }}
                          errorText={errors.sourceState ? true : false}
                        />
                      )}
                    />

                    <Controller
                      name="sourceCity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="sourceCity"
                          label="City"
                          placeholder="Enter Origin City"
                          labelClassName="mb-1.5 block"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('sourceCity');
                          }}
                          onBlur={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('sourceCity');
                          }}
                          errorText={errors.sourceCity ? true : false}
                        />
                      )}
                    />

                    <Controller
                      name="sourceRadius"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          required
                          label="Radius"
                          className={`form_control`}
                          labelClassName="form_label mb-1.5 block"
                          options={radiusOptions}
                          value={radiusOptions.filter(
                            (val: any) =>
                              getValues('sourceRadius') === val.value
                          )}
                          onChangeFunc={(e: any) => {
                            handleSelectAddressDetails(
                              AddressDetails.ORIGIN,
                              e?.value
                            );
                            storeFormDataLocal('sourceRadius');
                          }}
                          parentClassName="sm:flex-1 single-select-x-scroll px-1 "
                        />
                      )}
                    />
                  </>
                )}

                {addressDetail === AddressDetails.DESTINATION && (
                  <>
                    <Controller
                      name="destinationState"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="destinationState"
                          label="State"
                          labelClassName="mb-1.5 block"
                          placeholder="Enter Destination State"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('destinationState');
                          }}
                          onBlur={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('destinationState');
                          }}
                          errorText={errors.destinationState ? true : false}
                        />
                      )}
                    />
                    <Controller
                      name="destinationCity"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <InputText
                          inputName="destinationCity"
                          label="City"
                          labelClassName="mb-1.5 block"
                          placeholder="Enter Destination City"
                          parentClassName="xl:flex-1 xls:w-1/2 w-full px-1"
                          className="form_control"
                          value={value}
                          required={true}
                          onChangeFunc={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('destinationCity');
                          }}
                          onBlur={(e) => {
                            onChange(e.target.value);
                            storeFormDataLocal('destinationCity');
                          }}
                          errorText={errors.destinationCity ? true : false}
                        />
                      )}
                    />
                    <Controller
                      name="destinationRadius"
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          {...field}
                          required
                          label="Radius"
                          className={`form_control`}
                          labelClassName="form_label mb-1.5 block"
                          options={radiusOptions}
                          value={radiusOptions.filter(
                            (val: any) =>
                              getValues('destinationRadius') == val.value
                          )}
                          onChangeFunc={(e: any) => {
                            handleSelectAddressDetails(
                              AddressDetails.DESTINATION,
                              e?.value
                            );
                            storeFormDataLocal('destinationRadius');
                          }}
                          parentClassName="flex-1 single-select-x-scroll px-1"
                        />
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          {/* second row */}
          <div
            className={`rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full mx-1.5`}
          >
            <div className="flex sm:flex-nowrap flex-wrap justify-between items-center gap-2 w-full pb-4 border-b border-utilityGray200">
              <h5 className="text-grayLight900 text-sm font-medium">
                {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES
                  ? `Select ${selectedEquipmentType} Equipment Type`
                  : `Select Additional Services`}
              </h5>
              <TabButton
                className="!min-h-[30px]"
                activeClassName="!bg-utilityGray100"
                tabArray={equipmentDetailArr}
                isActive={equipmentDetail}
                handleOnClick={(e: any) => {
                  setEquipmentDetail(e?.target?.dataset?.value);
                }}
              />
            </div>
            {equipmentDetail === EquipmentDetails.EQUIPMENTTYPES && (
              <>
                <div className="flex flex-wrap pt-4 gap-y-3">
                  {isVehicleTypeLoading ? (
                    <>
                      {Array.from({ length: 15 }).map((_, index) => (
                        <div
                          key={index}
                          className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                        >
                          <label className="text-textSecondary text-xs font-medium cursor-pointer custom-loading">
                            Double Drop
                          </label>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {vehicleTypeOptions.map(
                        (vehicleType: any, index: number) => (
                          <div
                            className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                            key={`equipment_type_${index}`}
                          >
                            <CheckBox
                              id={`vehicle_type_${vehicleType.value}`}
                              name="vehicleType"
                              checked={
                                primaryEquipmentType == vehicleType.value ||
                                secondaryEquipmentType.includes(
                                  vehicleType.value
                                )
                              }
                              parentClassName={`!w-3.5 !h-3.5`}
                              value={vehicleType.value}
                              onChangeFunc={changeEquipmentType(vehicleType)}
                            />
                            <label
                              htmlFor={`vehicle_type_${vehicleType.value}`}
                              className={`text-textSecondary text-xs font-medium cursor-pointer`}
                            >
                              {vehicleType.label}&nbsp;
                              <span className="text-gray500 text-xs font-normal">
                                {primaryEquipmentType == vehicleType.value
                                  ? '(Primary)'
                                  : secondaryEquipmentType.includes(
                                      vehicleType.value
                                    )
                                  ? '(Secondary)'
                                  : ''}
                              </span>
                            </label>
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
                <div className="[&:empty]:hidden [&:empty]:mb-0 mt-5">
                  {primaryEquipmentTypeError && (
                    <ErrorMsg errorText="Primary equipment type is required." />
                  )}
                </div>
              </>
            )}
            {equipmentDetail === EquipmentDetails.ADDITIONAL && (
              <div className="flex flex-wrap pt-4 gap-y-3">
                {isVehicleAttrLoading ? (
                  <>
                    {Array.from({ length: 8 }).map((_, index) => (
                      <div
                        key={index}
                        className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2"
                      >
                        <label className="text-textSecondary text-xs font-medium cursor-pointer custom-loading">
                          Double Drop
                        </label>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {vehicleAttrOptions.map(
                      (vehicleAttribute: any, index: number) => (
                        <Controller
                          key={`vehicle_attribute_${index}`}
                          name="vehicleAttribute"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <div className="xls:w-1/5 mdm:w-1/4 sm:w-1/3 sms:w-1/2 w-full flex items-center gap-x-2 ">
                              <CheckBox
                                id={vehicleAttribute.value}
                                name="vehicleAttribute"
                                value={vehicleAttribute.value}
                                checked={value?.includes(
                                  vehicleAttribute.value
                                )}
                                onChangeFunc={(event: any) => {
                                  const checked = event.target.checked;
                                  let vehicleAttributes = value
                                    ? [...value]
                                    : [];
                                  let vehicleAttrFullNames =
                                    vehicleAttributeFullNames
                                      ? [...vehicleAttributeFullNames]
                                      : [];

                                  if (checked) {
                                    vehicleAttributes.push(
                                      vehicleAttribute.value
                                    );
                                    vehicleAttrFullNames.push(
                                      vehicleAttribute.label
                                    );
                                  } else {
                                    vehicleAttributes =
                                      vehicleAttributes.filter(
                                        (item) =>
                                          item !== vehicleAttribute.value
                                      );
                                    vehicleAttrFullNames =
                                      vehicleAttributeFullNames.filter(
                                        (item) =>
                                          item !== vehicleAttribute.value
                                      );
                                  }
                                  onChange(vehicleAttributes);
                                  setVehicleAttributeFullNames(
                                    vehicleAttrFullNames
                                  );
                                  storeFormDataLocal('vehicleAttribute');
                                }}
                              />
                              <label
                                htmlFor={vehicleAttribute.value}
                                className="text-textSecondary text-xs font-medium  cursor-pointer "
                              >
                                {vehicleAttribute.label}
                              </label>
                            </div>
                          )}
                        />
                      )
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          {/* third row */}
          <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-xs p-4 w-full mx-1.5 space-y-4">
            <Controller
              name="specialInstructionsLoadBoard"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Loadboard Instructions"
                  labelClassName="block mb-1.5"
                  labelIconRight={
                    <>
                      <p className="text-grayLight900 text-xs flex justify-end mt-1">
                        {specialInstructionsLoadBoard?.length}&nbsp;/&nbsp;
                        {specialInstructionMaxLength}
                      </p>
                    </>
                  }
                  inputName="specialInstructionsLoadBoard"
                  placeholder={'Add comments to the load posting'}
                  asTextarea="textarea"
                  parentClassName=""
                  maxLength={specialInstructionMaxLength}
                  rows={1}
                  className="h-[38px]"
                  value={value}
                  onChangeFunc={(e) => {
                    onChange(e?.target?.value);
                    storeFormDataLocal('specialInstructionsLoadBoard');
                  }}
                />
              )}
            />

            <Controller
              name="specialInstructionsAiAgent"
              control={control}
              render={({ field: { onChange, value } }) => (
                <InputText
                  label="Special Instructions"
                  labelClassName="block mb-1.5"
                  inputName="specialInstructionsAiAgent"
                  placeholder={'Add your special instructions'}
                  asTextarea="textarea"
                  parentClassName=""
                  rows={2}
                  className="h-[76px]"
                  value={value}
                  onChangeFunc={(e) => {
                    onChange(e?.target?.value);
                    storeFormDataLocal('specialInstructionsAiAgent');
                  }}
                />
              )}
            />
          </div>
        </div>
      </form>

      {isShowTruckFindingAiModal && (
        // <TruckFindingAi
        //   title={`AI Rate Gathering Settings`}
        //   postCreatePayload={postCreatePayload}
        //   truckFindingAiDetail={loadLinkPostingDetail?.truckFindingAi}
        //   rateGatheringWithAi={rateGatheringWithAi}
        //   handleClose={() => {
        //     setIsShowTruckFindingAiModal(false);
        //     setValidatePostToLoadBoardForm(false);
        //   }}
        //   handleModalSubmit={onTruckFindingAiModalSubmit}
        // />
        <TruckFindingAiModals
          postCreatePayload={postCreatePayload}
          truckFindingAiDetail={
            loadLinkPostingDetail?.loadLinkTruckFindingAis.length
              ? loadLinkPostingDetail?.loadLinkTruckFindingAis[0]
              : undefined
          }
          rateGatheringWithAi={rateGatheringWithAi}
          handleClose={() => {
            setIsShowTruckFindingAiModal(false);
            setValidatePostToLoadBoardForm(false);
          }}
          handleModalSubmit={onTruckFindingAiModalSubmit}
        />
      )}
    </>
  );
};

export default CreateLoadPostingFrom;
