import { PackagePlus } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import CommonModal from 'src/components/CommonModal';
import Radio from 'src/components/Radio/Radio';
import TabButton from 'src/components/TabButton';
import { KGS, LBS, MEASUREMENT } from 'src/constants/common';

import VisualizeUnit from '../../VisualizeUnit';

import DimensionLoading from './DimensionLoading';
import Items from './Items';
import { initValidForm, isValidDimension } from './ValidationFunction';

interface IFormData {
  dimensions: [
    {
      id: number | null;
      handlingUnit: string | null;
      handlingUnitNo: number;
      weight: number;
      itemLength: number;
      width: number;
      height: number;
      totalWeight: number;
      freightClass: number;
      isCommodity: boolean;
      isStack: boolean;
      weightMeasurement: string;
      commodityId: string | null;
      commodityName: string | null;
      sub: string | null;
      description: string | null;
    }
  ];
}

const initFormData: IFormData = {
  dimensions: [
    {
      id: null,
      handlingUnit: '1',
      // handlingUnitNo: 1,
      handlingUnitNo: 0,
      weight: 0,
      itemLength: 0,
      width: 0,
      height: 0,
      totalWeight: 0,
      freightClass: 0,
      isCommodity: false,
      isStack: true,
      weightMeasurement: MEASUREMENT.WEIGHT1.value,
      commodityId: null,
      commodityName: null,
      sub: null,
      description: null,
    },
  ],
};

const DimensionTab = ({
  quoteFormData,
  setSubmitAction,
  isQuoteLoading,
  setQuoteFormData,
  updateQuoteDetail,
  isUpdateLoading,
  setIsUpdateLoading,
  quoteId,
  tabArray,
  activeTab,
  setActiveTab,
  handlingUnitOptions,
  quoteData,
  handleClose,
  lengthMeasurement,
  setLengthMeasurement,
  setValidateMeasurement,
  validateMeasurement,
  validFormDetail,
  isViewQuote = false,
  setIsDisplayDimensionsDetail = () => {},
  isTotalWeightManuallyChanged,
  setTotalDimensionsWeight,
  isHandlingUnitLoading,
  isLinearFootageManuallyChanged,
}: any) => {
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [formData, setFormData] = useState<IFormData>(
    quoteFormData ?? initFormData
  );
  const [invalidIndexes, setInvalidIndexes] = useState<number[]>([]);
  const [validForm, setValidForm] = useState(initValidForm);

  const [isDimensionLoading, setIsDimensionLoading] = useState(
    isQuoteLoading ?? false
  );
  const [isLoading] = useState(isQuoteLoading ?? false);
  const [isPrimaryBtnDisabled, setIsPrimaryBtnDisabled] = useState(false);

  const [currentItem, setCurrentItem] = useState<any>({
    index: null,
    data: null,
  });
  const [isShowVisualUnit, setIsShowVisualUnit] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const isFormValid = () => {
    let dimensionValidObj: any = isValidDimension(
      quoteFormData?.dimensions,
      validateMeasurement
    );

    setValidForm((old: any) => ({
      ...old,
      dimensions: dimensionValidObj?.validFormData,
    }));
    setInvalidIndexes(dimensionValidObj?.invalidIndexes);

    return dimensionValidObj?.valid;
  };

  useEffect(() => {
    const form = formData?.dimensions.map((formDataField: any) => {
      formDataField.quoteId = quoteId;

      return formDataField;
    });
    setQuoteFormData((old: any) => ({
      ...old,
      dimensions: form,
    }));
    // isFormValid();
  }, [formData]);

  useEffect(() => {
    setSubmitAction((old: any) => ({
      ...old,
      isUpdateDimension: true,
    }));

    if (validFormDetail) {
      setValidForm((old: any) => ({
        ...old,
        dimensions: validFormDetail,
      }));
    }
  }, []);

  const handleMeasurement =
    (lengthMeasurementValue: string) => (event: any) => {
      const { value } = event.target;
      setWeightMeasurement(value);
      setLengthMeasurement(lengthMeasurementValue);
    };

  useEffect(() => {
    const fetchDimensionsData = async () => {
      if (!isQuoteLoading) {
        if (quoteId) {
          const { dimensions } = quoteData;

          if (dimensions?.length > 0) {
            setWeightMeasurement(dimensions?.[0].weightMeasurement);
          }

          if (quoteFormData?.dimensions?.length) {
            for (let i = 0; i < quoteFormData?.dimensions?.length; i++) {
              setValidForm((old: any) => ({
                ...old,
                dimensions: [...old.dimensions, ...initValidForm.dimensions],
              }));
            }
          } else {
            if (dimensions) {
              let weightMeasurementValue = MEASUREMENT.WEIGHT1.value;

              for (const dimension of dimensions) {
                setValidForm((old: any) => ({
                  ...old,
                  dimensions: [...old.dimensions, ...initValidForm.dimensions],
                }));

                weightMeasurementValue = dimension.weightMeasurement;
              }

              setWeightMeasurement(weightMeasurementValue);
            }
          }
          setIsDimensionLoading(false);
        } else {
          setIsDimensionLoading(false);
        }
      } else {
        setIsDimensionLoading(false);
      }
    };
    fetchDimensionsData();
  }, [isQuoteLoading]);

  useEffect(() => {
    const form = formData?.dimensions.map((formDataField: any) => {
      formDataField.quoteId = quoteId;
      formDataField.weightMeasurement = weightMeasurement;

      return formDataField;
    });

    setFormData((old: any) => ({
      ...old,
      dimensions: form,
    }));

    const validateMeasurementValue = {
      weight:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.WEIGHT
          : LBS.WEIGHT,
      length:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.LENGTH
          : LBS.LENGTH,
      width:
        weightMeasurement === MEASUREMENT.WEIGHT2.value ? KGS.WIDTH : LBS.WIDTH,
      height:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.HEIGHT
          : LBS.HEIGHT,
    };
    setValidateMeasurement((old: any) => ({
      ...old,
      ...validateMeasurementValue,
    }));
  }, [weightMeasurement]);

  useEffect(() => {
    const form = formData?.dimensions.map((formDataField: any) => {
      formDataField.quoteId = quoteId;

      return formDataField;
    });
    setQuoteFormData((old: any) => ({
      ...old,
      dimensions: form,
    }));
  }, [activeTab]);

  const handleSubmit = async () => {
    if (isViewQuote) {
      setIsDisplayDimensionsDetail(false);

      return;
    }

    if (!isFormValid()) {
      return true;
    }

    const form = formData?.dimensions.map((formDataField: any) => {
      formDataField.quoteId = quoteId;

      return formDataField;
    });
    setIsUpdateLoading(true);

    setQuoteFormData((old: any) => ({
      ...old,
      dimensions: form,
    }));

    if (activeTab === 'dimensions') {
      setTimeout(() => {
        updateQuoteDetail();
      }, 100);
    }
  };

  return (
    <>
      <CommonModal
        title={isViewQuote ? 'View Quote Details' : 'Edit Quote Details'}
        titleDesc={
          isViewQuote
            ? 'Get a breakdown of your quote and shipment details.'
            : 'Enter total weight for accurate pricing.'
        }
        handleClose={() => handleClose()}
        headerIcon={<PackagePlus className="w-5 h-5" />}
        size={' max-w-[745px] h-[85vh] '}
        primaryBtnText={isViewQuote ? 'Edit' : 'Save Changes'}
        secondaryBtnText="Cancel"
        primaryBtnDisabled={
          isLoading ||
          isUpdateLoading ||
          !isValid ||
          isDimensionLoading ||
          isPrimaryBtnDisabled
        }
        primaryBtnLoading={isUpdateLoading}
        primaryBtnOnClick={handleSubmit}
        secondaryBtnOnClick={() => handleClose(false)}
      >
        {isQuoteLoading || isLoading || isDimensionLoading ? (
          <div className="px-5 pb-5 space-y-4">
            <TabButton
              tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px "
              parentClassName={`w-full`}
              className={`flex-1`}
              tabArray={tabArray.filter((e: any) => e.isAllow)}
              isActive={activeTab}
              isTab={true}
              handleOnClick={(e: any) => {
                setActiveTab(e.target.dataset.value);
              }}
            />
            <DimensionLoading />
          </div>
        ) : (
          <div className="px-5 pb-5 space-y-4">
            <TabButton
              tabParentClassName="sticky pt-5 pb-4 top-0 bg-white z-[2] -mx-px "
              parentClassName={`w-full  ${
                isQuoteLoading || isLoading || isDimensionLoading
                  ? 'custom-loading'
                  : ''
              }`}
              className={`flex-1`}
              tabArray={tabArray.filter((e: any) => e.isAllow)}
              isActive={activeTab}
              isTab={true}
              handleOnClick={(e: any) => {
                setActiveTab(e.target.dataset.value);
              }}
            />

            <div>
              <div className="flex justify-between items-center mb-3">
                <h6 className="text-grayLight900 text-xs font-medium">
                  Dimensions
                </h6>
                <div className="flex items-center justify-center gap-1.5">
                  <Radio
                    onChangeFunc={handleMeasurement(MEASUREMENT.LENGTH1.value)}
                    // name="weightMeasurement"
                    id="lbs"
                    value={MEASUREMENT.WEIGHT1.value}
                    checked={weightMeasurement === MEASUREMENT.WEIGHT1.value}
                    labelName="Imperial (lbs)"
                    labelHtmlFor="lbs"
                    radioLabelClassName="normal-case"
                    disabled={isViewQuote}
                  />

                  <Radio
                    onChangeFunc={handleMeasurement(MEASUREMENT.LENGTH2.value)}
                    checked={weightMeasurement === MEASUREMENT.WEIGHT2.value}
                    value={MEASUREMENT.WEIGHT2.value}
                    // name="weightMeasurement"
                    id="kgs"
                    labelName="Metric (kgs)"
                    labelHtmlFor="kgs"
                    radioLabelClassName="normal-case"
                    disabled={isViewQuote}
                  />
                </div>
              </div>

              {isDimensionLoading ? (
                <>
                  <div className="custom-loading bg-gray50 w-full h-[42px] rounded-[10px]"></div>
                  <div className="custom-loading bg-gray50 w-full h-[42px] rounded-lg mt-4"></div>
                </>
              ) : (
                !isDimensionLoading && (
                  <Items
                    formData={formData}
                    validForm={validForm}
                    initFormData={initFormData}
                    initValidForm={initValidForm}
                    handlingUnitOptions={handlingUnitOptions}
                    validateMeasurement={validateMeasurement}
                    lengthMeasurement={lengthMeasurement}
                    weightMeasurement={weightMeasurement}
                    setFormData={setFormData}
                    setValidForm={setValidForm}
                    currentItem={currentItem}
                    setCurrentItem={setCurrentItem}
                    isShowVisualUnit={isShowVisualUnit}
                    setIsShowVisualUnit={setIsShowVisualUnit}
                    setIsValid={setIsValid}
                    isViewQuote={isViewQuote}
                    invalidIndexes={invalidIndexes}
                    isTotalWeightManuallyChanged={isTotalWeightManuallyChanged}
                    setTotalDimensionsWeight={setTotalDimensionsWeight}
                    setIsPrimaryBtnDisabled={setIsPrimaryBtnDisabled}
                    isHandlingUnitLoading={isHandlingUnitLoading}
                    isLinearFootageManuallyChanged={
                      isLinearFootageManuallyChanged
                    }
                    setQuoteFormData={setQuoteFormData}
                  />
                )
              )}
            </div>
          </div>
        )}
      </CommonModal>
      {isShowVisualUnit && currentItem && (
        <VisualizeUnit
          setDimensionsFormData={setFormData}
          currentItem={currentItem}
          unitArr={handlingUnitOptions}
          handleClose={() => {
            setIsShowVisualUnit(false);
            setCurrentItem({ index: null, data: null });
          }}
          weightMeasurement={weightMeasurement}
          lengthMeasurement={lengthMeasurement}
          isFormDataHaveKey={true}
          isViewQuote={isViewQuote}
        />
      )}
    </>
  );
};

export default DimensionTab;
