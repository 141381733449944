import { Plus, Trash01 } from '@untitled-ui/icons-react/build/cjs';
import React, { useEffect, useState } from 'react';
import ButtonCmp from 'src/components/ButtonCmp';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';

const AddQuickQuotePhoneNumber = ({
  formData,
  setFormData,
  validForm,
  setValidForm,
  carrierId,
  setHasEmptyPhones,
}: {
  formData: any;
  setFormData: any;
  validForm: any;
  setValidForm: any;
  carrierId: any;
  setHasEmptyPhones: (value: boolean) => void;
}) => {
  const [isPhoneNumberValueUpdated, setIsPhoneNumberValueUpdated] =
    useState<boolean>(false);

  useEffect(() => {
    const phoneNumbers = formData[carrierId]?.contactPhone?.phoneNumbers || [];
    const hasEmpty = phoneNumbers.some(
      (phone: string) => !phone || phone.trim() === ''
    );
    setHasEmptyPhones(hasEmpty);
  }, [formData]);

  useEffect(() => {
    if (!formData[carrierId]?.contactPhone?.phoneNumbers) {
      setFormData((prev: any) => ({
        ...prev,
        [carrierId]: {
          ...prev[carrierId],
          contactPhone: {
            phoneNumbers: [''],
          },
        },
      }));
    }
  }, []);

  const handleNumberChange = (
    country: string,
    phoneNumber: string,
    code: string,
    isPhoneValid: boolean,
    index: number
  ) => {
    setFormData((prev: any) => {
      const updatedPhones = [
        ...(prev[carrierId]?.contactPhone?.phoneNumbers || []),
      ];
      updatedPhones[index] = `+${code}${phoneNumber}`;

      return {
        ...prev,
        [carrierId]: {
          ...prev[carrierId],
          contactPhone: {
            phoneNumbers: updatedPhones,
          },
        },
      };
    });

    setValidForm((prevValidForm: any) => {
      const updatedValidation = [...(prevValidForm.phoneNumbers || [])];
      updatedValidation[index] = phoneNumber && code && isPhoneValid;

      return { ...prevValidForm, phoneNumbers: updatedValidation };
    });
  };

  const handleNumberOnBlurChange = (
    country: string,
    phoneNumber: string,
    code: string,
    isPhoneValid: boolean,
    index: number
  ) => {
    setValidForm((prevValidForm: any) => {
      const updatedValidation = [...(prevValidForm.phoneNumbers || [])];
      updatedValidation[index] = phoneNumber && code && isPhoneValid;

      return { ...prevValidForm, phoneNumbers: updatedValidation };
    });
  };

  const addPhoneNumber = () => {
    setFormData((prev: any) => ({
      ...prev,
      [carrierId]: {
        ...prev[carrierId],
        contactPhone: {
          phoneNumbers: [
            ...(prev[carrierId]?.contactPhone?.phoneNumbers || []),
            '',
          ],
        },
      },
    }));
    setValidForm((prevValidForm: any) => ({
      ...prevValidForm,
      phoneNumbers: [...(prevValidForm.phoneNumbers || []), false],
    }));
  };

  const removePhoneNumber = (indexToRemove: number) => {
    setFormData((prev: any) => {
      const updatedData = {
        ...prev,
        [carrierId]: {
          ...prev[carrierId],
          contactPhone: {
            phoneNumbers: [
              ...(prev[carrierId]?.contactPhone?.phoneNumbers || []),
            ].filter((_, index) => index !== indexToRemove),
          },
        },
      };

      if (updatedData[carrierId].contactPhone.phoneNumbers.length === 0) {
        updatedData[carrierId].contactPhone.phoneNumbers = [''];
      }

      return updatedData;
    });

    setValidForm((prevValidForm: any) => {
      const validations = [...(prevValidForm.phoneNumbers || [])].filter(
        (_, index) => index !== indexToRemove
      );

      return {
        ...prevValidForm,
        phoneNumbers: validations.length > 0 ? validations : [false],
      };
    });

    setIsPhoneNumberValueUpdated(true);
  };

  return (
    <>
      <label className="form_label block mb-1.5">
        Contact Number(s)
        <span className="text-red-600 ms-[2px] leading-4 text-xs">*</span>
      </label>
      <div className="space-y-3 empty:hidden">
        {formData[carrierId]?.contactPhone?.phoneNumbers?.map(
          (phone: string, index: number) => (
            <div key={index} className="flex gap-2 items-center">
              <PhoneInput
                phoneInputParentClassName="flex-1"
                phoneInputWrapClassName={`${
                  !validForm?.phoneNumbers?.[index] ? 'border-danger600' : ''
                }`}
                onNumberChange={(
                  country: string,
                  phoneNumber: string,
                  code: string,
                  isPhoneValid: boolean
                ) =>
                  handleNumberChange(
                    country,
                    phoneNumber,
                    code,
                    isPhoneValid,
                    index
                  )
                }
                onBlur={(
                  country: string,
                  phoneNumber: string,
                  code: string,
                  isPhoneValid: boolean
                ) =>
                  handleNumberOnBlurChange(
                    country,
                    phoneNumber,
                    code,
                    isPhoneValid,
                    index
                  )
                }
                errors={!validForm?.phoneNumbers?.[index] ? '' : ''}
                name="contactPhone"
                value={phone}
                isPhoneNumberValue={isPhoneNumberValueUpdated}
                setIsPhoneNumberValue={setIsPhoneNumberValueUpdated}
              />
              {formData[carrierId]?.contactPhone?.phoneNumbers.length > 1 && (
                <ButtonCmp
                  className="btn_secondary_black lg:!px-[9px] !px-2"
                  icon={<Trash01 className="w-4 h-4" />}
                  onClick={() => removePhoneNumber(index)}
                >
                  <></>
                </ButtonCmp>
              )}
            </div>
          )
        )}
      </div>
      <div className="btn-link-custom mt-2 w-fit" onClick={addPhoneNumber}>
        <Plus className="w-4 h-4" />
        Add Phone Number
      </div>
    </>
  );
};

export default AddQuickQuotePhoneNumber;
