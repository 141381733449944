import React from 'react';

import AccordionMailDetailView from './AccordionMailDetailView';
import MessageBodyLoading from './MessageBodyLoading';
import NoEmailUI from './NoEmailUI';

interface IProps {
  isEmailClicked: boolean;
  isLoadingMore: boolean;
  loading: boolean;
  emailData: any[];
  clearSearch: () => void;
  params: any;
  selectedEmail: any;
  selectedThreadId: number | undefined;
  setIsEmailClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedThreadId: any;
}

const MessageDetailSection = ({
  isEmailClicked,
  isLoadingMore,
  loading,
  emailData,
  clearSearch,
  params,
  selectedEmail,
  selectedThreadId,
  setIsEmailClicked,
  setSelectedThreadId,
}: IProps) => (
  <div
    className={`md:w-[50%] flex-1 w-full flex flex-col max-xlm:pl-[58px] max-sm:pl-0 h-full ${
      isEmailClicked ? 'max-sm:translate-y-0' : 'max-sm:translate-y-full'
    } max-sm:z-[2] transition-all duration-[0.5s] max-sm:bg-white`}
  >
    {loading && !isLoadingMore ? (
      <MessageBodyLoading />
    ) : emailData?.length === 0 ? (
      <NoEmailUI clearSearch={clearSearch} params={params} />
    ) : (
      <AccordionMailDetailView
        isLoadingMore={isLoadingMore}
        loading={loading}
        emailDetails={selectedEmail}
        setIsEmailClicked={setIsEmailClicked}
        selectedThreadId={selectedThreadId}
        setSelectedThreadId={setSelectedThreadId}
      />
    )}
  </div>
);
export default MessageDetailSection;
