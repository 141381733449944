import { isArray } from 'lodash';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  calculateNiceMaximumForTotalQuotes,
  getDateWithSuffixFormat,
  getFormattedNumber,
} from 'src/utils/CommonFunctions';

export const commonOptions = {
  chart: {
    animations: {
      enabled: false,
    },
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadiusApplication: 'end',
      barHeight: '80%',
      borderRadius: 5,
    },
  },
  colors: ['#3538CD', '#A4BCFD', '#6172F3'],
  annotations: {
    points: [],
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
    position: 'top',
    horizontalAlign: 'right',
  },
  states: {
    hover: {
      filter: {
        type: 'none',
      },
    },
  },
  stroke: {
    colors: ['transparent'],
    width: 5,
  },
};

export const salesOptions: any = {
  xaxis: {
    categories: [],
    labels: {
      show: false,
    },
  },
  yaxis: {
    min: 0,
    max: 1000,
    tickAmount: 5,
  },
  tooltip: {
    theme: 'dark',
    position: 'top',
    y: {
      formatter: function (val: any) {
        return val
          ? getFormattedNumber(val.toFixed(2), true, false, false, false)
          : 0;
      },
    },
    custom: function ({ series, dataPointIndex, w }: any) {
      console.log('⭐️ ~ dataPointIndex:', dataPointIndex);
      console.log('⭐️ ~ w.globals.labels:', w.globals.labels);
      let tooltipTitle = w.globals.labels[dataPointIndex];
      console.log(
        '⭐️ ~ w.globals.labels[dataPointIndex]:',
        w.globals.labels[dataPointIndex]
      );
      console.log(
        'isArray(w.globals.labels[dataPointIndex])',
        isArray(w.globals.labels[dataPointIndex])
      );

      if (isArray(w.globals.labels[dataPointIndex])) {
        let wdt1 = getDateWithSuffixFormat(w.globals.labels[dataPointIndex][0]);
        let wdt2 = getDateWithSuffixFormat(w.globals.labels[dataPointIndex][1]);
        tooltipTitle = `${wdt1} ${wdt2}`;
      } else {
        tooltipTitle = getDateWithSuffixFormat(
          w.globals.labels[dataPointIndex]
        );
      }
      let tooltipContent = `<div class="apexcharts-tooltip-title" style="font-family: Inter, Helvetica, Arial, sans-serif; font-size: 12px; font-style: normal; font-weight: 500; line-height: 18px; color:#101828">
        ${tooltipTitle}
      </div>`;
      w.globals.seriesNames.forEach((seriesName: string, index: number) => {
        tooltipContent += `
        <div class="apexcharts-tooltip-series-group" style="order: 1; display: flex;font-family: Inter, Helvetica, Arial, sans-serif; font-size: 12px; font-style: normal; line-height: 18px; color:#101828;">
          <span class="apexcharts-tooltip-marker" style="height:10px; width:10px; background-color: ${
            w.globals.colors[index]
          }">
          </span>
          <div class="apexcharts-tooltip-y-group">
            <span class="apexcharts-tooltip-text-y-label" style="font-weight: 400;">${seriesName}: </span>
            <span class="apexcharts-tooltip-text-y-value" style="font-weight: 700;"> ${
              series[index]?.[dataPointIndex] > 0
                ? `${getFormattedNumber(
                    series[index][dataPointIndex],
                    true,
                    false,
                    false,
                    false
                  )}`
                : 0
            }</span>
          </div>
        </div>`;
      });

      return tooltipContent;
    },
  },
  ...commonOptions,
};

const initSeries = [
  {
    name: 'Requests',
    data: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  },
  {
    name: 'Quotes',
    data: [150, 260, 350, 450, 550, 650, 750, 850, 950],
  },
];

const initSalesOptions = {
  options: salesOptions,
  series: initSeries,
};

const BarChart = ({ totalQuotesGraphData, isChartDataLoading }: any) => {
  const [totalSalesOptions, setTotalSalesOptions] = useState(initSalesOptions);

  useEffect(() => {
    if (!totalQuotesGraphData?.length) {
      const noDataOption = {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: -50,
        style: { color: undefined, fontSize: '16px', fontFamily: undefined },
      };

      setTotalSalesOptions({
        ...totalSalesOptions,
        series: [],
        options: {
          ...salesOptions,
          noData: noDataOption,
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      });

      return;
    }

    const namesArray = totalQuotesGraphData.map((item: any) => item.xValue);

    const filteredData = totalQuotesGraphData.filter(
      (item: any) =>
        (item.totalRequests !== 0 && item.totalRequests !== false) ||
        (item.totalQuotes !== 0 && item.totalQuotes !== false)
    );

    if (filteredData?.length > 0) {
      const valueArrays = totalQuotesGraphData.reduce(
        (acc: any, item: any) => {
          acc[0].push(
            item.totalRequests > 0 ? item.totalRequests.toFixed(2) : 0
          );
          acc[1].push(item.totalQuotes > 0 ? item.totalQuotes.toFixed(2) : 0);

          return acc;
        },
        [[], []]
      );
      console.log('⭐️ ~ useEffect ~ valueArrays:', valueArrays);

      const max = Math.max(
        ...filteredData.map((item: any) =>
          Math.max(item.totalRequests, item.totalQuotes)
        )
      );
      console.log('⭐️ ~ useEffect ~ max:', max);
      const tickAmount = 5;

      const series = [
        { name: 'Requests', data: valueArrays[0] },
        { name: 'Quotes', data: valueArrays[1] },
      ];
      console.log('⭐️ ~ useEffect ~ series:', series);

      const noDataConfig =
        filteredData?.length > 0
          ? {}
          : {
              text: '',
              align: 'center',
              verticalAlign: 'middle',
              offsetX: 0,
              offsetY: -50,
              style: {
                color: undefined,
                fontSize: '16px',
                fontFamily: undefined,
              },
            };

      setTotalSalesOptions({
        options: {
          ...salesOptions,
          xaxis: {
            ...salesOptions.xaxis,
            categories: namesArray,
            labels: {
              show: true,
              formatter: function (value: any) {
                if (typeof value !== 'string') return value;

                value = value?.split('-')[0];

                if (value?.split && value?.split(',')?.length > 1) {
                  // formatting x axis label for daily filter
                  return value?.split(',')?.[0];
                }

                return value;
              },
            },
          },
          yaxis: {
            min: 0,
            max: calculateNiceMaximumForTotalQuotes(max, tickAmount),
            forceNiceScale: true,
            labels: {
              formatter: function (value: any) {
                if (value >= 1000) {
                  return `${value.toFixed(0)}`;
                }

                return value;
              },
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: false,
            },
          },
          noData: noDataConfig,
        },
        series: series,
      });
    } else {
      const series: any[] = [];
      const noDataConfig = {
        text: '',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: -50,
        style: {
          color: undefined,
          fontSize: '16px',
          fontFamily: undefined,
        },
      };

      setTotalSalesOptions({
        ...totalSalesOptions,
        series: series,
        options: {
          ...salesOptions,
          xaxis: {
            ...salesOptions.xaxis,
            categories: namesArray,
            labels: {
              show: true,
              formatter: function (value: any) {
                if (typeof value !== 'string') return value;

                value = value?.split('-')[0];

                if (value?.split && value?.split(',')?.length > 1) {
                  // formatting x axis label for daily filter
                  return value?.split(',')?.[0];
                }

                return value;
              },
            },
          },
          noData: noDataConfig,
        },
      });
    }
  }, [totalQuotesGraphData]);

  return (
    <div className="w-full h-full bg-white p-5 rounded-xl border border-utilityGray200 shadow flex flex-col">
      <div className="w-full justify-between xls:items-center items-start flex sm:flex-row flex-col mb-2.5 xxl:gap-4 gap-2 relative xs:mt-0 mt-5">
        <div className=" text-grayLight900 sm:text-lg text-base font-semibold gap-2 xs:justify-between xs:items-center xl:pr-4 mdm:flex-none flex-1">
          <p className="flex xs:flex-row flex-col xs:items-center justify-between">
            Total Quotes
          </p>
          {/* <p className="text-grayLight600 text-sm font-normal">
            Organizational total gross profit over time
          </p> */}
        </div>
        <div className="mdm:justify-center justify-end items-center mdm:gap-5 gap-2 sms:order-none -order-1 flex mr-1 sms:w-auto w-full sm:relative absolute sm:top-0 sm:right-[unset] xs:top-1 right-0 -top-5">
          <div className="rounded-[10px] justify-end items-center gap-2 flex">
            <div className="w-2 h-2 rounded-[10px] bg-indigo-600" />
            <div className="text-textSecondary text-xs font-normal leading-none">
              Requests
            </div>
          </div>
          <div className="rounded-[10px] justify-end items-center gap-[9px] flex ">
            <div className="w-2 h-2 rounded-[10px] bg-indigo-100" />
            <div className="text-textSecondary text-xs font-normal leading-none">
              Quotes
            </div>
          </div>
        </div>
      </div>
      <div
        className="sales-chart-container w-full h-full min-h-[310px]"
        id="sales-chart-container"
        key={`${totalSalesOptions?.series?.[0]?.name}_${
          isChartDataLoading ? 'custom-loading' : 'loaded'
        }`}
      >
        <Chart
          options={
            isChartDataLoading
              ? initSalesOptions.options
              : totalSalesOptions.options
          }
          series={
            isChartDataLoading
              ? initSalesOptions.series
              : totalSalesOptions.series
          }
          className={`${
            isChartDataLoading ? 'custom-loading graph-loading' : ''
          }`}
          type="bar"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default BarChart;
