/* eslint-disable max-lines-per-function */
import {
  AlertCircle,
  AlertHexagon,
  CurrencyDollar,
  InfoCircle,
  InfoHexagon,
  PackagePlus,
} from '@untitled-ui/icons-react/build/cjs';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import _, { isEqual } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from 'src/components/ConfirmModal';
import NotFoundUI from 'src/components/NotFoundUI';
import PhoneInput from 'src/components/PhoneInput/PhoneInput';
import TabButton from 'src/components/TabButton';
import { getRoundUpLinearFootage } from 'src/utils/CommonFunctions';

import ButtonCmp from '../../../../components/ButtonCmp';
import CheckBox from '../../../../components/CheckBox';
import CommonModal from '../../../../components/CommonModal';
import InputText from '../../../../components/InputText/InputText';
import Radio from '../../../../components/Radio/Radio';
import SelectBox from '../../../../components/SelectBox/SelectBox';
import StepBar from '../../../../components/StepBar/StepBar';
import {
  LBS,
  KGS,
  HANDLING_UNIT_NUMBER,
  MEASUREMENT,
  STATUS,
  CURRENCY,
  QUOTE_CLASSIFICATION,
  TIME_FORMAT,
  FOOT,
} from '../../../../constants/common';
import { PATH } from '../../../../constants/path';
import { ROUTES } from '../../../../constants/routes';
import { BasicContext } from '../../../../context/BasicContext';
import { getPricingRate } from '../../../../services/CommonService';
import {
  getQuote,
  getFreightClass,
  createQuote,
  updateQuote,
  createUpdateService,
  createUpdateDimension,
} from '../../../../services/QuoteService';
import WalToast from '../../../../utils/WalToast';
import AdditionalServiceModal from '../../Common/AdditionalServiceModal';
import { DATE_FORMAT } from '../Quote/AddQuoteDetail';
import { isValidDimension } from '../Quote/EditQuoteDetail/ValidationFunction';
import { STEPS } from '../SmartQuote.interface';

import Items from './Items';

const currencyTypeOptions = [
  {
    label: 'USD',
    value: CURRENCY.USD,
  },
  {
    label: 'CAD',
    value: CURRENCY.CAD,
  },
];

interface IFormData {
  totalWeight: string | number | undefined;
  linearFootage: string | number | undefined;
  classification: string | null;
  insuranceCommercialValue: string | number | undefined;
  primaryEquipmentType: any;
  insuranceCurrencyType: string;
  primaryEquipmentTypeFullName: any[];
  secondaryEquipmentTypeFullNames: any[];
  secondaryEquipmentTypes: any;
  dimensions: [
    {
      id: number | null;
      handlingUnit: string | null;
      handlingUnitNo: number;
      weight: number;
      itemLength: number;
      width: number;
      height: number;
      totalWeight: number;
      freightClass: number;
      isCommodity: boolean;
      isStack: boolean;
      weightMeasurement: string;
      commodityId: string | null;
      commodityName: string | null;
      sub: string | null;
      description: string | null;
    }
  ];
}

const initFormData: IFormData = {
  linearFootage: '',
  totalWeight: '',
  classification: '',
  primaryEquipmentType: '',
  insuranceCurrencyType: CURRENCY.CAD,
  insuranceCommercialValue: '',
  primaryEquipmentTypeFullName: [],
  secondaryEquipmentTypeFullNames: [],
  secondaryEquipmentTypes: '',
  dimensions: [
    {
      id: null,
      handlingUnit: '1',
      handlingUnitNo: 0,
      weight: 0,
      itemLength: 0,
      width: 0,
      height: 0,
      totalWeight: 0,
      freightClass: 0,
      isCommodity: false,
      isStack: true,
      weightMeasurement: MEASUREMENT.WEIGHT1.value,
      commodityId: null,
      commodityName: null,
      sub: null,
      description: null,
    },
  ],
};

const initValidForm = {
  totalWeight: true,
  linearFootage: true,
  totalWeightMax: true,
  totalWeightMin: true,
  insuranceCommercialValue: true,
  linearFootageMax: true,
  linearFootageMin: true,
  primaryEquipmentType: true,
  dimensions: [
    {
      handlingUnit: {
        required: true,
        valid: true,
      },
      handlingUnitNo: {
        required: true,
        valid: true,
      },
      weight: {
        required: true,
        valid: true,
      },
      itemLength: {
        required: true,
        valid: true,
      },
      freightClass: {
        required: true,
        valid: true,
      },
      width: {
        required: true,
        valid: true,
      },
      height: {
        required: true,
        valid: true,
      },
      totalWeight: {
        required: true,
        valid: true,
      },
      isCommodity: {
        required: true,
        valid: true,
      },
      isStack: {
        required: true,
        valid: true,
      },
      commodityId: {
        required: true,
        valid: true,
      },
      commodityName: {
        required: true,
        valid: true,
      },
      sub: {
        required: true,
        valid: true,
      },
    },
  ],
};

const initServiceValidForm = {
  limitedAccessOther: true,
  inbondName: true,
  inbondAddress: true,
  inbondContactValue: true,
  unitedNationsNumber: true,
  isEmailValid: true,
  isPhoneValid: true,
  goodsClass: true,
  description: true,
  emergencyContactName: true,
  emergencyContactNumber: true,
  isEmergencyPhoneValid: true,
  declaredValue: true,
  isLoadInsuranceValid: true,
};

const initAction = {
  tailgate: false,
  unusual: false,
  totalWeightMismatch: false,
};

const initValidateMeasurement = {
  weight: LBS.WEIGHT,
  length: LBS.LENGTH,
  width: LBS.WIDTH,
  height: LBS.HEIGHT,
  maxTotalWeight: 100000,
};

const inbondTypeList = [
  {
    name: '(IT) Immediate Transportation Bond',
    value: 'immediate-exportation',
  },
  {
    name: '(T&E) Transportation & Export Bond',
    value: 'transportation-and-exportation',
  },
];

const inbondContactMethodList = [
  { name: 'Email', value: 'email-address' },
  { name: 'Phone', value: 'phone-number' },
  { name: 'Fax Number', value: 'fax-number' },
];

const dangerTypeList = [
  { name: 'Limited Quantity', value: 'limited-quantity' },
  { name: '500 kg Exemption', value: 'exemption-500-kg' },
  { name: 'Fully Regulated', value: 'fully-regulated' },
];

const packagingGroupOptions = [
  { label: 'PG I', value: 'PGI' },
  { label: 'PG II', value: 'PGII' },
  { label: 'PG III', value: 'PGIII' },
];

const requiredFields = [
  'handlingUnit',
  'handlingUnitNo',
  'weight',
  'itemLength',
  'width',
  'height',
  'freightClass',
  'commodityId',
  'sub',
];

const Dimensions = ({
  setQuoteFormData,
  quoteFormData,
  setCurrentStep,
  currentStep,
  serviceList,
  serviceTypes,
}: any) => {
  const { id } = useParams<{ id: any }>();
  const navigate = useNavigate();
  const {
    currency,
    isVehicleTypeLoading,
    vehicleTypeOptions,
    handlingUnitOptions,
    isHandlingUnitLoading,
  } = useContext(BasicContext);
  const [formData, setFormData] = useState<IFormData>(
    quoteFormData ?? initFormData
  );
  const [validForm, setValidForm] = useState(initValidForm);
  const [validServicesForm, setServicesValidForm] =
    useState(initServiceValidForm);
  const [isLoading, setIsLoading] = useState(false);
  const [isDimensionLoading, setIsDimensionLoading] = useState(true);
  const [selectedServices, setSelectedServices] = useState<number[]>(
    quoteFormData?.services?.services ?? []
  );
  const [isInbondMoreModalOpen, setIsInbondMoreModalOpen] =
    useState<boolean>(false);
  const [isInbondMore, setIsInbondMore] = useState<boolean>(false);
  const [inbondType, setInbondType] = useState<string>(inbondTypeList[0].value);
  const [inbondName, setInbondName] = useState<string>('');
  const [inbondAddress, setInbondAddress] = useState<string>('');
  const [inbondContactMethod, setInbondContactMethod] = useState<string>(
    inbondContactMethodList[0].value
  );
  const [inbondContactValue, setInbondContactValue] = useState<string>('');
  const [quoteId, setQuoteId] = useState(id);
  const [isDeclaredValueMore, setIsDeclaredValueMore] =
    useState<boolean>(false);
  const [declaredValue, setDeclaredValue] = useState<any>(0);
  const [rates, setRates] = useState<any>({});
  const [isDangerousMoreModalOpen, setIsDangerousMoreModalOpen] =
    useState<boolean>(false);
  const [isExcessValuationModalOpen, setIsExcessValuationModalOpen] =
    useState<boolean>(false);
  const [isLoadInsuranceModalOpen, setIsLoadInsuranceModalOpen] =
    useState<boolean>(false);
  const [isDangerousMore, setIsDangerousMore] = useState<boolean>(false);
  const [isLoadInsurance, setIsLoadInsurance] = useState<boolean>(false);
  const [loadInsuranceValue, setLoadInsuranceValue] = useState<any>(0);
  const [loadInsuranceValueUnit, setLoadInsuranceValueUnit] =
    useState(currency);
  const [loadInsuranceValueWithUnit, setLoadInsuranceValueWithUnit] = useState({
    loadInsuranceValueCAD: 0,
    loadInsuranceValueUSD: 0,
  });
  const [dangerType, setDangerType] = useState<string>(dangerTypeList[0].value);
  const [packagingGroup, setPackagingGroup] = useState<string>(
    packagingGroupOptions[0].value
  );
  const [unitedNationsNumber, setUnitedNationsNumber] = useState<string>('');
  const [goodsClass, setGoodsClass] = useState<string>('');
  const [emergencyContactName, setEmergencyContactName] = useState<string>('');
  const [emergencyContactNumber, setEmergencyContactNumber] =
    useState<string>('');
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState<string>('');
  const [isDangerousFormSubmitted, setIsDangerousFormSubmitted] =
    useState<boolean>(false);
  const [emergencyContactExt, setEmergencyContactExt] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [weightMeasurement, setWeightMeasurement] = useState(
    MEASUREMENT.WEIGHT1.value
  );
  const [lengthMeasurement, setLengthMeasurement] = useState(
    MEASUREMENT.LENGTH1.value
  );
  const [validateMeasurement, setValidateMeasurement] = useState(
    initValidateMeasurement
  );

  const [isQuoteDetailChange, setIsQuoteDetailChange] =
    useState<boolean>(false);
  const [originalQuoteDetail, setOriginalQuoteDetail] = useState<any>({});
  const [isModifyingQuote, setIsModifyingQuote] = useState<boolean>(false);
  const [quoteDetails, setQuoteDetails] = useState<any>({});
  const [allowHandleChecked, setAllowHandleChecked] = useState<any>(true);
  const [declaredValueUnit, setDeclaredValueUnit] = useState(currency);
  const [declaredValueWithUnit, setDeclaredValueWithUnit] = useState({
    declaredValueCAD: 0,
    declaredValueUSD: 0,
  });
  const [isValidData, setIsValidData] = useState(false);
  const [isTotalWeightOver, setIsTotalWeightOver] = useState<boolean>(false);
  const [isItemLengthOver, setIsItemLengthOver] = useState<boolean>(false);
  const [action, setAction] = useState(initAction);
  const [isTotalWeightManuallyChanged, setIsTotalWeightManuallyChanged] =
    useState(false);
  const [isLinearFootageManuallyChanged, setIsLinearFootageManuallyChanged] =
    useState(false);
  const [totalDimensionsWeight, setTotalDimensionsWeight] = useState(null);
  const [totalWeightValue, setTotalWeightValue] = useState(null);
  const [isChangeTotalWeight, setIsChangeTotalWeight] =
    useState<boolean>(false);
  const [isSelectBoxOpen, setIsSelectBoxOpen] = useState(false);

  const filterArr: any = [
    {
      value: CURRENCY.CAD,
      name: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      name: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  const filterSelectArr: any = [
    {
      value: CURRENCY.CAD,
      label: 'CAD',
      tooltipValue: `Live Rate : ${rates.cadRate ? rates.cadRate : 0}`,
    },
    {
      value: CURRENCY.USD,
      label: 'USD',
      tooltipValue: `Live Rate : ${rates.usdRate ? rates.usdRate : 0}`,
    },
  ];

  useEffect(() => {
    if (!allowHandleChecked && !isLoading) {
      setTimeout(() => {
        setAllowHandleChecked(true);
      }, 1500);
    }
  }, [allowHandleChecked, isLoading]);

  const getRate = () => {
    getPricingRate()
      .then((response: any) => {
        setRates(response?.data);
      })
      .catch((err: any) => {
        console.error('Pricing rates error', err);
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  useEffect(() => {
    if (handlingUnitOptions && handlingUnitOptions.length) {
      console.log('formData listunit', formData);

      if (formData?.dimensions && formData.dimensions.length > 0) {
        const newDataArray = [...formData.dimensions];
        newDataArray[0].handlingUnit =
          handlingUnitOptions.length > 0 && newDataArray[0].handlingUnit === ''
            ? handlingUnitOptions[0].value
            : newDataArray[0].handlingUnit ?? '';

        setFormData((old: any) => ({
          ...old,
          dimensions: newDataArray,
        }));
      }
    }
  }, [handlingUnitOptions]);

  useEffect(() => {
    setIsModifyingQuote(true);

    const modifyQuoteId = parseInt(
      localStorage.getItem('isModifyQuoteFromQuote') || ''
    );

    if (modifyQuoteId || quoteId) {
      getQuote(modifyQuoteId)
        .then((response: any) => {
          if (response && response.data) {
            setOriginalQuoteDetail(response.data);
          } else {
            navigate(ROUTES.QUOTING_DASHBOARD);
          }
        })
        .catch(console.error);
    }
  }, []);

  const compareArrays = (
    originalArray: any,
    modifiedArray: any,
    compareKey: any[] = []
  ) => {
    if (originalArray.length !== modifiedArray.length) {
      return false;
    }

    for (let i = 0; i < originalArray.length; i++) {
      const originalObj = originalArray[i];
      const modifiedObj = modifiedArray[i];

      for (let key in originalObj) {
        if (compareKey.includes(key) && originalObj[key] !== modifiedObj[key]) {
          return false;
        }
      }
    }

    return true;
  };

  useEffect(() => {
    if (isModifyingQuote) {
      if (quoteDetails && originalQuoteDetail?.addressDetails) {
        if (
          quoteDetails.pickupDate &&
          originalQuoteDetail?.addressDetails?.pickupDate &&
          moment(quoteDetails.pickupDate, 'YYYY-MM-DD HH:mm:ss').format(
            'YYYY-MM-DD'
          ) !==
            moment(
              originalQuoteDetail.addressDetails.pickupDate,
              'YYYY-MM-DD HH:mm:ss'
            ).format('YYYY-MM-DD')
        ) {
          setIsQuoteDetailChange(true);

          return;
        }

        if (
          quoteDetails.consigneeFullAddress !==
            originalQuoteDetail.addressDetails.consigneeFullAddress ||
          quoteDetails.shipperFullAddress !==
            originalQuoteDetail.addressDetails.shipperFullAddress ||
          quoteDetails.customerId !== originalQuoteDetail.customerId
        ) {
          setIsQuoteDetailChange(true);

          return;
        }
      }

      if (selectedServices?.length) {
        if (originalQuoteDetail.additionalService?.length) {
          if (
            selectedServices.length !==
            originalQuoteDetail.additionalService.length
          ) {
            setIsQuoteDetailChange(true);

            return;
          }

          originalQuoteDetail.additionalService.forEach((element: any) => {
            if (!selectedServices.includes(element.serviceId)) {
              setIsQuoteDetailChange(true);

              return;
            }
          });
        } else {
          setIsQuoteDetailChange(true);

          return;
        }
      } else if (originalQuoteDetail.additionalService?.length) {
        setIsQuoteDetailChange(true);

        return;
      }

      if (
        originalQuoteDetail.dimensions?.length !== formData?.dimensions?.length
      ) {
        setIsQuoteDetailChange(true);

        return;
      }

      if (
        originalQuoteDetail.dimensions?.length &&
        formData?.dimensions?.length
      ) {
        const isSameArray = compareArrays(
          originalQuoteDetail.dimensions,
          formData,
          [
            'handlingUnitNo',
            'height',
            'width',
            'itemLength',
            'weight',
            'freightClass',
          ]
        );
        setIsQuoteDetailChange(!isSameArray);

        return;
      }

      setIsQuoteDetailChange(false);
    }
  }, [originalQuoteDetail, quoteDetails, selectedServices, formData]);

  useEffect(() => {
    console.log('selectedServices', selectedServices);
    setQuoteFormData((old: any) => ({
      ...old,

      services: {
        ...old.services,
        services: selectedServices,
      },
    }));
  }, [selectedServices]);

  useEffect(() => {
    if (isInbondMore && allowHandleChecked) {
      setIsInbondMoreModalOpen(true);
    }
  }, [isInbondMore]);

  useEffect(() => {
    if (isDangerousMore && allowHandleChecked) {
      setIsDangerousMoreModalOpen(true);
    }
  }, [isDangerousMore]);

  useEffect(() => {
    if (isLoadInsurance && allowHandleChecked) {
      setIsLoadInsuranceModalOpen(true);
    }
  }, [isLoadInsurance]);

  useEffect(() => {
    if (isDeclaredValueMore && allowHandleChecked) {
      setIsExcessValuationModalOpen(true);
    }
  }, [isDeclaredValueMore]);

  useEffect(() => {
    if (declaredValueUnit === CURRENCY.CAD) {
      if (declaredValueWithUnit.declaredValueCAD)
        setDeclaredValue(declaredValueWithUnit.declaredValueCAD);
    } else if (declaredValueUnit === CURRENCY.USD) {
      if (declaredValueWithUnit.declaredValueUSD)
        setDeclaredValue(declaredValueWithUnit.declaredValueUSD);
    }
  }, [declaredValueUnit]);

  useEffect(() => {
    if (loadInsuranceValueUnit === CURRENCY.CAD) {
      if (loadInsuranceValueWithUnit.loadInsuranceValueCAD)
        setLoadInsuranceValue(loadInsuranceValueWithUnit.loadInsuranceValueCAD);
    } else if (loadInsuranceValueUnit === CURRENCY.USD) {
      if (loadInsuranceValueWithUnit.loadInsuranceValueUSD)
        setLoadInsuranceValue(loadInsuranceValueWithUnit.loadInsuranceValueUSD);
    }
  }, [loadInsuranceValueUnit]);

  const getQuoteInfo = (qId: any) => {
    setAllowHandleChecked(false);
    setIsDimensionLoading(true);

    if (qId) {
      getQuote(qId)
        .then((response: any) => {
          if (response && response.data) {
            const { dimensions, addressDetails, additionalService } =
              response.data;
            setQuoteDetails(addressDetails);

            if (addressDetails.status !== STATUS.PENDING) {
              navigate(ROUTES.QUOTING_DASHBOARD);
            }

            if (additionalService) {
              let savedServices = additionalService.map((val: any) => {
                if (val.slug === 'in-bond') {
                  setIsInbondMore(true);
                  setInbondType(val.inbondType);
                  setInbondName(val.inbondName);
                  setInbondAddress(val.inbondAddress);
                  setInbondContactMethod(val.inbondContactMethod);
                  setInbondContactValue(val.inbondContactValue);
                }

                if (val.slug === 'excess_valuation') {
                  setIsDeclaredValueMore(true);
                  setDeclaredValueWithUnit({
                    ...declaredValueWithUnit,
                    ...{
                      declaredValueCAD: val.declaredValueCAD,
                      declaredValueUSD: val.declaredValueUSD,
                    },
                  });

                  setDeclaredValueUnit(val.declaredValueUnit);

                  if (val.declaredValueUnit === CURRENCY.CAD) {
                    setDeclaredValue(val.declaredValueCAD);
                  } else if (val.declaredValueUnit === CURRENCY.USD) {
                    setDeclaredValue(val.declaredValueUSD);
                  }
                }

                if (val.slug === 'load_insurance') {
                  setIsLoadInsurance(true);
                  setLoadInsuranceValueWithUnit({
                    ...loadInsuranceValueWithUnit,
                    ...{
                      loadInsuranceValueCAD: val.loadInsuranceValueCAD,
                      loadInsuranceValueUSD: val.loadInsuranceValueUSD,
                    },
                  });

                  setLoadInsuranceValueUnit(val.loadInsuranceValueUnit);

                  if (val.loadInsuranceValueUnit === CURRENCY.CAD) {
                    setLoadInsuranceValue(val.loadInsuranceValueCAD);
                  } else if (val.loadInsuranceValueUnit === CURRENCY.USD) {
                    setLoadInsuranceValue(val.loadInsuranceValueUSD);
                  }
                }

                if (val.slug === 'dangerous_goods') {
                  setIsDangerousMore(true);
                  setDangerType(val.dangerGoodsType);
                  setPackagingGroup(val.packagingGroup);
                  setUnitedNationsNumber(val.unitedNationsNumber);
                  setGoodsClass(val.goodsClass);
                  setEmergencyContactName(val.emergencyContactName);
                  setEmergencyContactNumber(val.emergencyContactNumber);
                  setEmergencyContactExt(val.emergencyContactExt);
                  setDescription(val.description);
                }

                return val.serviceId;
              });
              setSelectedServices(savedServices);
            }

            if (dimensions) {
              let totalWeight = 0;
              setWeightMeasurement(dimensions[0].weightMeasurement);
              dimensions.forEach((dimension: any) => {
                totalWeight += dimension.totalWeight;
                const maxLengthOver =
                  dimensions[0].weightMeasurement === MEASUREMENT.WEIGHT1
                    ? LBS.ITEM_LENGTH_OVER
                    : KGS.ITEM_LENGTH_OVER;

                if (dimension.itemLength >= maxLengthOver) {
                  setIsItemLengthOver(true);
                }
              });
              const isTotalWeightOverBool =
                dimensions[0].weightMeasurement === MEASUREMENT.WEIGHT1
                  ? totalWeight >= LBS.MAX_WEIGHT
                  : totalWeight >= KGS.MAX_WEIGHT;
              setIsTotalWeightOver(isTotalWeightOverBool);
            }
          } else {
            setIsDimensionLoading(false);
            navigate(ROUTES.QUOTING_DASHBOARD);
          }
        })
        .catch((e: any) => {
          console.error(e);
          setIsDimensionLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!isHandlingUnitLoading) {
      if (id) {
        setIsDimensionLoading(true);
        getQuoteInfo(id);
        getQuote(id)
          .then(async (response: any) => {
            if (response && response.data) {
              const { dimensions } = response.data;

              if (dimensions) {
                let weightMeasurementValue = MEASUREMENT.WEIGHT1.value;
                let lengthMeasurementValue = MEASUREMENT.LENGTH1.value;

                const data: any = [];

                for (const dimension of dimensions) {
                  setValidForm((old: any) => ({
                    ...old,
                    dimensions: [
                      ...old.dimensions,
                      ...initValidForm.dimensions,
                    ],
                  }));
                  weightMeasurementValue = dimension.weightMeasurement;
                  lengthMeasurementValue = dimension.lengthMeasurement;
                  delete dimension.createdAt;
                  delete dimension.updatedAt;

                  let freightClass = dimension.freightClass;
                  let itemClass = dimension.itemClass;

                  if (!freightClass || !itemClass) {
                    const freightData = await getFreightClass({
                      itemLength: dimension.itemLength,
                      width: dimension.width,
                      height: dimension.height,
                      weight: dimension.weight,
                      lengthUnit: dimension.lengthMeasurement,
                      weightUnit: dimension.weightMeasurement,
                    })
                      .then((dataRes: any) => dataRes?.data || 0)
                      .catch(() => 0);

                    dimension.freightClass = freightData?.freightClass;
                    dimension.itemClassForDayross =
                      freightData?.itemClassForDayross;
                  }

                  data.push(dimension);
                }

                setFormData((old: any) => ({
                  ...old,
                  dimensions: data,
                }));

                setWeightMeasurement(weightMeasurementValue);
                setLengthMeasurement(lengthMeasurementValue);
              }
              setIsDimensionLoading(false);
            } else {
              setIsDimensionLoading(false);
            }
          })
          .catch((e: any) => {
            console.error(e);
            setIsDimensionLoading(false);
          });
      } else {
        setIsDimensionLoading(false);
      }
    }
  }, [isHandlingUnitLoading]);

  useEffect(() => {
    let totalWeight: number | string = 0;
    let weightMeasurementValue = '';
    formData.dimensions.forEach((form: any) => {
      totalWeight += form.totalWeight;
      weightMeasurementValue = form.weightMeasurement;
      const maxLengthOver =
        formData?.dimensions?.[0].weightMeasurement ===
        MEASUREMENT.WEIGHT1.value
          ? LBS.ITEM_LENGTH_OVER
          : KGS.ITEM_LENGTH_OVER;

      if (form.itemLength >= maxLengthOver) {
        setIsItemLengthOver(true);
      }
    });
    totalWeight = totalWeight ? Number(totalWeight).toFixed() : '0';
    const isTotalWeightOverBool =
      weightMeasurementValue === MEASUREMENT.WEIGHT1.value
        ? parseFloat(totalWeight) >= LBS.MAX_WEIGHT
        : parseFloat(totalWeight) >= KGS.MAX_WEIGHT;
    setIsTotalWeightOver(isTotalWeightOverBool);
  }, [formData]);

  useEffect(() => {
    const form = formData?.dimensions.map((formDataField: any) => {
      formDataField.weightMeasurement = weightMeasurement;

      return formDataField;
    });

    setFormData((old: any) => ({
      ...old,
      dimensions: form,
    }));

    const validateMeasurementValue = {
      weight:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.WEIGHT
          : LBS.WEIGHT,
      length:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.LENGTH
          : LBS.LENGTH,
      width:
        weightMeasurement === MEASUREMENT.WEIGHT2.value ? KGS.WIDTH : LBS.WIDTH,
      height:
        weightMeasurement === MEASUREMENT.WEIGHT2.value
          ? KGS.HEIGHT
          : LBS.HEIGHT,
    };
    setValidateMeasurement((old: any) => ({
      ...old,
      ...validateMeasurementValue,
    }));
  }, [weightMeasurement]);

  const handleChange = (e: any) => {
    let { value, checked } = e.target;
    value = parseInt(value);

    if (checked) {
      setSelectedServices((old: any) => [...old, value]);

      if (e.target.name === 'in-bond') {
        setIsInbondMore(true);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(true);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(true);
      }

      if (e.target.name === 'load_insurance') {
        setIsLoadInsurance(true);
      }
    } else {
      let arr = selectedServices.filter((item: any) => item !== value);
      setSelectedServices(arr);

      if (e.target.name === 'in-bond') {
        setIsInbondMore(false);
        setInbondType(inbondTypeList[0].value);
      }

      if (e.target.name === 'dangerous_goods') {
        setIsDangerousMore(false);
      }

      if (e.target.name === 'load_insurance') {
        setIsLoadInsurance(false);
      }

      if (e.target.name === 'excess_valuation') {
        setIsDeclaredValueMore(false);
      }
    }
  };

  const getList = (data: any) => (
    <div className="flex flex-wrap [&>div]:px-1 -mx-1 px-2.5">
      {data.map((val: any) =>
        quoteFormData?.classification == QUOTE_CLASSIFICATION.FTL ||
        val.slug === 'load_insurance' ||
        (quoteFormData?.classification !== QUOTE_CLASSIFICATION.FTL &&
          val.loadLinkTerm !== null) ? (
          <div
            className="py-4 relative w-1/6 before:content-[''] before:h-px before:w-[90vw] before:bg-utilityGray200 before:-top-px before:-left-[5vw] before:absolute"
            key={`service_${val.id}`}
          >
            <div className="flex cursor-pointer space-x-1.5">
              <CheckBox
                id={`service_${val.id}`}
                onChangeFunc={handleChange}
                name={val.slug}
                value={val.id}
                checked={selectedServices.includes(val.id)}
                parentClassName="mt-px"
              />
              <div className="leading-[0.9] space-x-1.5 flex items-center truncate">
                <>
                  <label
                    htmlFor={`service_${val.id}`}
                    className={`leading-4 text-textSecondary text-xs font-medium truncate cursor-pointer`}
                  >
                    {val.slug === 'after_hours_pickup'
                      ? 'After Hours Pickup'
                      : val.name}
                  </label>
                </>
              </div>
            </div>
          </div>
        ) : (
          ''
        )
      )}
    </div>
  );

  const getName = (contactMethod: string) => {
    if (contactMethod === 'fax-number') {
      return 'Fax';
    } else if (contactMethod === 'phone-number') {
      return 'Phone';
    } else {
      return 'Email';
    }
  };

  const parsedPhoneNumber = inbondContactValue
    ? parsePhoneNumberFromString(inbondContactValue)
    : null;
  const defaultValues = {
    phone: parsedPhoneNumber?.nationalNumber || '',
    phone_country_code: parsedPhoneNumber?.country || '',
  };

  const emergencyContactNo = emergencyContactNumber
    ? parsePhoneNumberFromString(emergencyContactNumber)
    : null;
  const defaultEmergencyPhoneValues = {
    phone: emergencyContactNo?.nationalNumber || '',
    phone_country_code: emergencyContactNo?.country || '',
  };

  const isInBondFormValid = () => {
    let valid = true;
    const validFormData = {
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      unitedNationsNumber: true,
      isEmailValid: false,
      isPhoneValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        validFormData.inbondContactValue = false;
        valid = false;
      } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
        validFormData.inbondContactValue = true;

        if (getName(inbondContactMethod) === 'Email') {
          if (emailRegex.test(inbondContactValue)) {
            validFormData.isEmailValid = true;
          } else {
            validFormData.isEmailValid = false;
            valid = false;
          }
        } else {
          validFormData.isEmailValid = true;
        }

        if (getName(inbondContactMethod) === 'Phone') {
          if (!validServicesForm.isPhoneValid) {
            validFormData.isPhoneValid = false;
            valid = false;
          }

          if (!validServicesForm.inbondContactValue) {
            validFormData.inbondContactValue = false;
            valid = false;
          }
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const handleChangeRadio = (e: any) => {
    let { value, name } = e.target;
    setInbondContactValue('');

    if (name === 'contactMethod') {
      setServicesValidForm((old) => ({
        ...old,
        inbondName: true,
        inbondAddress: true,
        inbondContactValue: true,
        unitedNationsNumber: true,
        isEmailValid: true,
        isPhoneValid: true,
      }));

      setInbondContactMethod(value);
    }

    if (name === 'inbondName') {
      setInbondType(value);
    }

    if (name === 'dangerType') {
      setDangerType(value);
    }
  };

  const handleServiceFormData = () => {
    let serviceArr = _.uniq([
      ...selectedServices,
      ...quoteFormData.services.services,
    ]);
    console.log('isInbondMore', isInbondMore);
    const formDataCurrent: any = {
      serviceId: serviceArr.join(','),
      limitedAccess: quoteFormData?.services?.isLimitedMore,
      inbond: isInbondMore,
      dangerousGoods: isDangerousMore,
      modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
    };

    if (quoteFormData.isLimitedMore) {
      formDataCurrent.limitedAccessType = quoteFormData.limitedAccessType;
      formDataCurrent.limitedAccessOther = quoteFormData.limitedAccessOther;
    }

    if (isInbondMore) {
      formDataCurrent.inbondType = inbondType;
      formDataCurrent.inbondName = inbondName;
      formDataCurrent.inbondAddress = inbondAddress;
      formDataCurrent.inbondContactMethod = inbondContactMethod;
      formDataCurrent.inbondContactValue = inbondContactValue;
    }

    if (isDangerousMore) {
      formDataCurrent.dangerGoodsType = dangerType;
      formDataCurrent.goodsClass = goodsClass;
      formDataCurrent.description = description;
      formDataCurrent.unitedNationsNumber = unitedNationsNumber;
      formDataCurrent.packagingGroup = packagingGroup;
      formDataCurrent.emergencyContactName = emergencyContactName;
      formDataCurrent.emergencyContactNumber = emergencyContactNumber;
      formDataCurrent.emergencyContactExt = emergencyContactExt;
    }

    if (isDeclaredValueMore) {
      formDataCurrent.declaredValue = declaredValue;
      formDataCurrent.isDeclaredValue = isDeclaredValueMore;
      formDataCurrent.currency = declaredValueUnit;
    }

    console.log('formDataCurrent', formDataCurrent);

    return formDataCurrent;
  };
  useEffect(() => {
    console.log('quoteId', quoteId);
  }, [quoteId]);

  const submitService = (qId?: any) => {
    console.log('quoteId service', quoteId, '  ', qId);
    let formDataCurrent = handleServiceFormData();
    let qqId = qId ?? quoteId;
    formDataCurrent.quoteId = qqId;
    console.log('formDataCurrent', formDataCurrent);
    createUpdateService(formDataCurrent)
      .then(() => {
        let redirectUrl = `${PATH.MY_QUOTE}/${qqId}`;
        navigate(redirectUrl);
      })
      .finally(() => setIsLoading(false))
      .catch(() =>
        WalToast.error('Additional services have not been updated', '')
      );
  };

  const handleServiceActionType = (actionKey: string) => {
    console.log('handleServiceActionType');
    setAction((old) => ({ ...old, [actionKey]: true }));
  };

  useEffect(() => {
    console.log('quoteFormData?.dimensions', quoteFormData?.dimensions);

    if (
      quoteFormData?.dimensions &&
      quoteFormData?.classification === QUOTE_CLASSIFICATION.LTL
    ) {
      if (quoteFormData?.dimensions?.length) {
        for (let i = 0; i < quoteFormData?.dimensions?.length; i++) {
          setValidForm((old: any) => ({
            ...old,
            dimensions: [...old.dimensions, ...initValidForm.dimensions],
          }));
        }
      }
    }

    let serviceForm = quoteFormData.services;
    setAllowHandleChecked(false);

    if (serviceForm) {
      if (serviceForm?.inbond) {
        setIsInbondMore(serviceForm?.inbond);
        setInbondType(serviceForm.inbondType ?? false);
        setInbondName(serviceForm.inbondName);
        setInbondAddress(serviceForm.inbondAddress);
        setInbondContactMethod(serviceForm.inbondContactMethod);
        setInbondContactValue(serviceForm.inbondContactValue);
      }

      if (serviceForm?.isDeclaredValue) {
        setIsDeclaredValueMore(serviceForm?.isDeclaredValueMore);
        setDeclaredValueUnit(serviceForm.declaredValueUnit);
        setDeclaredValue(serviceForm.declaredValue);
      }

      if (serviceForm?.dangerousGoods) {
        setIsDangerousMore(serviceForm?.dangerousGoods);
        setDangerType(serviceForm.dangerGoodsType);
        setPackagingGroup(serviceForm.packagingGroup);
        setUnitedNationsNumber(serviceForm.unitedNationsNumber);
        setGoodsClass(serviceForm.goodsClass);
        setEmergencyContactName(serviceForm.emergencyContactName);
        setEmergencyContactNumber(serviceForm.emergencyContactNumber);
        setEmergencyContactExt(serviceForm.emergencyContactExt);
        setDescription(serviceForm.description);
      }

      if (serviceForm?.isLoadInsurance) {
        setLoadInsuranceValueUnit(quoteFormData?.insuranceCurrencyType);
        setLoadInsuranceValue(quoteFormData?.insuranceCommercialValue);
      }
    }
  }, []);

  useEffect(() => {
    console.log('formData Dimension', formData);
  }, [formData]);

  useEffect(() => {
    console.log('validForm', validForm);
  }, [validForm]);

  const isFormValid = () => {
    console.log('validateMeasurement', validateMeasurement);
    let valid = true;
    const validFormData = {
      primaryEquipmentType: true,
      totalWeightMax: true,
      totalWeightMin: true,
      totalWeight: true,
      linearFootage: true,
      linearFootageMax: true,
      linearFootageMin: true,
      dimensions: [],
    };

    if (!formData.primaryEquipmentType) {
      validFormData.primaryEquipmentType = false;
      console.log('primaryEquipmentType');
      valid = false;
    }

    if (
      formData.totalWeight !== '' &&
      (formData.totalWeight || formData.totalWeight === 0)
    ) {
      if (Number(formData.totalWeight) > 100000) {
        validFormData.totalWeightMax = false;
        valid = false;
      }

      if (Number(formData.totalWeight) <= 0) {
        validFormData.totalWeightMin = false;
        valid = false;
      }
    }

    if (formData?.classification !== QUOTE_CLASSIFICATION.FTL) {
      if (
        formData.linearFootage !== '' &&
        (formData.linearFootage || formData.linearFootage === 0)
      ) {
        if (Number(formData.linearFootage) > 53) {
          validFormData.linearFootageMax = false;
          valid = false;
        }

        if (Number(formData.linearFootage) <= 0) {
          validFormData.linearFootageMin = false;
          valid = false;
        }
      }

      if (!formData.linearFootage || formData.linearFootage === '') {
        validFormData.linearFootage = false;
        valid = false;
      }
    }

    if (!formData.totalWeight) {
      validFormData.totalWeight = false;
      valid = false;
    }

    let isValidForm = true;
    let validData: any = [];

    if (formData?.classification === QUOTE_CLASSIFICATION.LTL) {
      formData.dimensions?.forEach((form: any) => {
        const validation = {
          ...initValidForm.dimensions?.[0],
          handlingUnit: {
            required: Boolean(form.handlingUnit),
            valid: Boolean(form.handlingUnit),
          },
          weight: {
            required: Boolean(form.weight),
            valid: Boolean(form.weight),
          },
          totalWeight: {
            required: Boolean(form.totalWeight),
            valid: form.totalWeight <= validateMeasurement.maxTotalWeight,
          },
          handlingUnitNo: {
            required: Boolean(form.handlingUnitNo),
            valid: form.handlingUnitNo <= HANDLING_UNIT_NUMBER,
          },
          itemLength: {
            required: Boolean(form.itemLength),
            valid: form.itemLength <= validateMeasurement.length,
          },
          width: {
            required: Boolean(form.width),
            valid: form.width <= validateMeasurement.width,
          },
          height: {
            required: Boolean(form.height),
            valid: form.height <= validateMeasurement.height,
          },
          freightClass: {
            required: Boolean(form.freightClass),
            valid: form.freightClass > 0,
          },
          commodityId: {
            required: form.isCommodity ? Boolean(form.commodityId) : true,
            valid: form.isCommodity ? Boolean(form.commodityId) : true,
          },
          sub: {
            required: form.isCommodity ? Boolean(form.sub) : true,
            valid: form.isCommodity ? Boolean(form.sub) : true,
          },
        };
        validData.push(validation);
      });

      isValidForm = validData.every((form: any) =>
        requiredFields.every(
          (requiredField) =>
            form[requiredField].required && form[requiredField].valid
        )
      );
    }
    console.log('validFormData', validFormData);
    setValidForm((old: any) => ({
      ...old,
      ...validFormData,
      dimensions: validData,
    }));
    console.log('isValidForm', isValidForm, '  ', valid);

    return isValidForm && valid;
  };
  useEffect(() => {
    if (
      !isEqual(validateMeasurement, initValidateMeasurement) &&
      !weightMeasurement
    ) {
      isFormValid();
    }
  }, [validateMeasurement]);

  const validation = () => {
    let valid = true;
    const validFormData = {
      limitedAccessOther: true,
      inbondName: true,
      inbondAddress: true,
      inbondContactValue: true,
      isEmailValid: true,
      isPhoneValid: true,
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
      declaredValue: true,
      isLoadInsuranceValid: true,
    };

    if (isInbondMore) {
      if (inbondName === '' || inbondName === null) {
        validFormData.inbondName = false;
        valid = false;
      }

      if (inbondAddress === '' || inbondAddress === null) {
        validFormData.inbondAddress = false;
        valid = false;
      }

      if (inbondContactValue === '' || inbondContactValue === null) {
        const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g;
        validFormData.inbondContactValue = false;

        if (
          getName(inbondContactMethod) === 'Email' &&
          !emailRegex.test(inbondContactValue)
        ) {
          validFormData.isEmailValid = false;
          valid = false;
        }
      }
    }

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyContactNumber === '' || emergencyContactNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      }
    }

    if (isDeclaredValueMore) {
      if (!declaredValue) {
        validFormData.declaredValue = false;
        valid = false;
      }
    }

    if (isLoadInsurance) {
      if (!loadInsuranceValue) {
        validFormData.isLoadInsuranceValid = false;
        valid = false;
      }
    }
    setServicesValidForm(validFormData);

    return valid;
  };

  const isDangerousGoodsFormValid = () => {
    let valid = true;
    const validFormData = {
      unitedNationsNumber: true,
      goodsClass: true,
      description: true,
      emergencyContactName: true,
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };

    if (isDangerousMore) {
      if (unitedNationsNumber === '' || unitedNationsNumber === null) {
        validFormData.unitedNationsNumber = false;
        valid = false;
      }

      if (goodsClass === '' || goodsClass === null) {
        validFormData.goodsClass = false;
        valid = false;
      }

      if (description === '' || description === null) {
        validFormData.description = false;
        valid = false;
      }

      if (emergencyContactName === '' || emergencyContactName === null) {
        validFormData.emergencyContactName = false;
        valid = false;
      }

      if (emergencyPhoneNumber === '' || emergencyPhoneNumber === null) {
        validFormData.emergencyContactNumber = false;
        valid = false;
      } else {
        if (!validServicesForm.isEmergencyPhoneValid) {
          validFormData.isEmergencyPhoneValid = false;
          valid = false;
        }
      }
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isExcessValuation = () => {
    let valid = true;
    const validFormData = {
      declaredValue: true,
    };

    if (!declaredValue) {
      validFormData.declaredValue = false;
      valid = false;
    }
    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const isLoadInsuranceFormValid = () => {
    let valid = true;
    const validFormData = {
      isLoadInsuranceValid: true,
    };

    if (!loadInsuranceValue) {
      validFormData.isLoadInsuranceValid = false;
      valid = false;
    }
    setServicesValidForm((old) => ({ ...old, ...validFormData }));

    return valid;
  };

  const submitDimension = (qId: number) => {
    const form = formData?.dimensions.map((formDataField: any) => {
      formDataField.quoteId = qId;

      return formDataField;
    });

    createUpdateDimension({
      dimensions: form,
      quoteId: qId,
      modifyQuoteId: localStorage.getItem('isModifyQuoteFromQuote'),
    })
      .then((result) => {
        if (result) {
          submitService(qId);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        WalToast.error('Dimensions have not been updated', '');
      });
  };

  const submitAddressData = () => {
    const pickupDateTime = moment(quoteFormData.pickupDate)
      .utc()
      .format(`${DATE_FORMAT} ${TIME_FORMAT}`);

    const payload: any = {
      customerId: quoteFormData.customerId,
      pickupDate: pickupDateTime,
      shipperId: quoteFormData.shipperId,
      shipperCompanyName: quoteFormData.shipperCompanyName,
      shipperFullAddress: quoteFormData.shipperAddress.fullAddress,
      shipperAddress1: quoteFormData.shipperAddress.address1,
      shipperCity: quoteFormData.shipperAddress.city,
      shipperState: quoteFormData.shipperAddress.state,
      shipperCountry: quoteFormData.shipperAddress.country,
      shipperPostal: quoteFormData.shipperAddress.postal,
      shipperLatitude: quoteFormData.shipperAddress.latitude,
      shipperLongitude: quoteFormData.shipperAddress.longitude,
      shipperStateCode: quoteFormData.shipperAddress.stateCode,
      shipperCountryCode: quoteFormData.shipperAddress.countryCode,
      consigneeId: quoteFormData.consigneeId,
      consigneeCompanyName: quoteFormData.consigneeCompanyName,
      consigneeFullAddress: quoteFormData.consigneeAddress.fullAddress,
      consigneeAddress1: quoteFormData.consigneeAddress.address1,
      consigneeCity: quoteFormData.consigneeAddress.city,
      consigneeState: quoteFormData.consigneeAddress.state,
      consigneeCountry: quoteFormData.consigneeAddress.country,
      consigneePostal: quoteFormData.consigneeAddress.postal,
      consigneeLatitude: quoteFormData.consigneeAddress.latitude,
      consigneeLongitude: quoteFormData.consigneeAddress.longitude,
      consigneeStateCode: quoteFormData.consigneeAddress.stateCode,
      consigneeCountryCode: quoteFormData.consigneeAddress.countryCode,
      primaryEquipmentType: formData.primaryEquipmentType?.toString(),
      secondaryEquipmentTypes: formData.secondaryEquipmentTypes?.toString(),
      primaryEquipmentTypeFullName:
        formData.primaryEquipmentTypeFullName?.toString(),
      secondaryEquipmentTypeFullNames:
        formData.secondaryEquipmentTypeFullNames?.toString(),
      linearFootage: formData.linearFootage ?? undefined,
      totalWeight:
        isChangeTotalWeight && totalDimensionsWeight
          ? totalDimensionsWeight
          : formData.totalWeight ?? undefined,
      classification: formData?.classification,
      insuranceCommercialValue: formData.insuranceCommercialValue ?? 0,
      insuranceCurrencyType: formData.insuranceCurrencyType ?? 0,
      isFromQuoteRequest: id ? true : false,
    };

    if (window.MODE !== 'production' && quoteFormData?.emailContentId) {
      payload.emailContentId = quoteFormData?.emailContentId;
    }

    if (window.MODE !== 'production' && quoteFormData?.urgency) {
      payload.urgency = quoteFormData?.urgency;
    }

    setIsLoading(true);

    if (quoteId) {
      updateQuote(quoteId, { ...payload })
        .then((result: any) => {
          if (result) {
            setQuoteId(result?.data?.id);

            if (formData?.classification === QUOTE_CLASSIFICATION?.LTL) {
              setTimeout(() => {
                submitDimension(result?.data?.id);
              }, 100);
            } else {
              setTimeout(() => {
                submitService(result?.data?.id);
              }, 100);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    } else {
      console.log('createQuote');
      createQuote({ ...payload })
        .then((result: any) => {
          if (result) {
            setQuoteId(result?.data?.id);

            if (formData?.classification === QUOTE_CLASSIFICATION?.LTL) {
              submitDimension(result?.data?.id);
            } else {
              submitService(result?.data?.id);
            }
          }
        })
        .catch((error: any) => {
          const errMsg =
            error?.response?.status === 409
              ? error?.response?.data?.message
              : 'Something went wrong! Please try again';
          WalToast.error(errMsg, '');
        });
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!isFormValid() || !validation()) {
      return true;
    }

    if (isModifyingQuote && !isQuoteDetailChange) {
      navigate(
        `${PATH.MY_QUOTE}/${localStorage.getItem('isModifyQuoteFromQuote')}`
      );

      return true;
    }

    setQuoteFormData((old: any) => ({
      ...old,
      ...formData,
      services: {
        ...old.services,
        serviceId: selectedServices.join(','),
        services: selectedServices,
      },
    }));

    if (
      formData?.classification === QUOTE_CLASSIFICATION?.LTL &&
      quoteFormData?.services?.isTailgate.length &&
      (isTotalWeightOver || isItemLengthOver)
    ) {
      setIsLoading(false);
      handleServiceActionType('tailgate');
    } else if (
      totalDimensionsWeight != formData.totalWeight &&
      formData?.classification === QUOTE_CLASSIFICATION?.LTL
    ) {
      handleServiceActionType('totalWeightMismatch');
    } else {
      submitAddressData();
    }
  };

  const handleMeasurement =
    (lengthMeasurementValue: string) => (event: any) => {
      const { value } = event.target;
      setWeightMeasurement(value);
      setLengthMeasurement(lengthMeasurementValue);
    };

  useEffect(() => {
    console.log('action', action);
  }, [action]);

  const handleModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        submitAddressData();
      }
    },
    [action]
  );

  const handleWeightModalClose = useCallback(
    (status: boolean) => () => {
      setAction(initAction);

      if (status) {
        const totalDimensionsWeightData = quoteFormData.dimensions?.reduce(
          (sum: number, item: any) => sum + (+item.totalWeight || 0),
          0
        );

        setTotalDimensionsWeight(totalDimensionsWeightData);
        setTotalWeightValue(totalDimensionsWeightData);
        setIsChangeTotalWeight(true);
      }
    },
    [action]
  );

  useEffect(() => {
    if (isChangeTotalWeight) {
      submitAddressData();
      setIsChangeTotalWeight(false);
    }
  }, [isChangeTotalWeight]);

  const handleNumberChange = (
    country: CountryCode,
    phone: string,
    code: string
  ) => {
    if (phone) {
      defaultValues.phone_country_code = country;
      setInbondContactValue(`+${code}${phone}`);
    }
  };

  const handleNumberOnBlur = (
    country: CountryCode,
    phone: string,
    code: string,
    isPhoneValid: boolean
  ) => {
    var validFormData = {
      phone: true,
      isPhoneValid: true,
      inbondContactValue: true,
    };

    if (phone === '') {
      validFormData.phone = false;
      validFormData.isPhoneValid = false;
      validFormData.inbondContactValue = false;
    } else {
      validFormData.isPhoneValid = isPhoneValid;
    }

    setServicesValidForm((old) => ({ ...old, ...validFormData }));
  };

  const handleDangerPhoneChange = (
    country: CountryCode,
    phone: string,
    code: string,
    isValid: boolean
  ) => {
    var validFormData = {
      emergencyContactNumber: true,
      isEmergencyPhoneValid: true,
    };
    defaultEmergencyPhoneValues.phone_country_code = country;
    setEmergencyContactNumber(`+${code}${phone}`);
    setEmergencyPhoneNumber(`${phone}`);

    if (phone === '') {
      validFormData.emergencyContactNumber = false;
      validFormData.isEmergencyPhoneValid = false;
    } else {
      validFormData.isEmergencyPhoneValid = isValid;
    }

    setServicesValidForm({ ...validServicesForm, ...validFormData });
  };

  useEffect(() => {
    console.log('quoteFormData Dimension', formData);
  }, [formData]);

  const [
    isShowAdditionalServiceInfoModal,
    setIsShowAdditionalServiceInfoModal,
  ] = useState(false);

  const handleLinearFootageBlur = (value: any) => {
    if (!isNaN(value) && value > 0) {
      const roundedValue = getRoundUpLinearFootage(value);

      setFormData((old: any) => ({
        ...old,
        linearFootage: roundedValue,
      }));

      setQuoteFormData((prev: any) => ({
        ...prev,
        addressDetail: {
          ...prev.addressDetail,
          linearFootage: roundedValue,
        },
      }));
    }
  };

  return (
    <>
      <div className="space-y-3">
        <div className="flex items-center flex-wrap gap-x-2">
          <div className="flex-1">
            <h6 className="text-grayLight900 text-lg font-semibold">
              Add Quote Details
            </h6>
            <p className="text-grayLight600 text-xs font-medium">
              Track and Review Every Detail of Your Quote
            </p>
          </div>
          <div className="flex flex-wrap gap-2">
            <ButtonCmp
              onClick={() => {
                let formDataCurrent = handleServiceFormData();
                setQuoteFormData((old: any) => ({
                  ...old,
                  ...formData,
                  services: {
                    ...old.services,
                    ...formDataCurrent,
                    serviceId: selectedServices.join(','),
                    services: selectedServices,
                  },
                }));
                setCurrentStep(currentStep - 1);
              }}
              className="btn_secondary_black min-w-[75px]"
              disabled={currentStep === 1}
            >
              Back
            </ButtonCmp>

            <ButtonCmp
              type="submit"
              className="btn-outline-primary min-w-[75px]"
              onClick={(e: any) => handleSubmit(e)}
              disabled={
                isLoading ||
                isVehicleTypeLoading ||
                isDimensionLoading ||
                isValidData
              }
              loading={isLoading}
            >
              Finish
            </ButtonCmp>
          </div>
        </div>
        <StepBar
          steps={STEPS}
          activeStep={currentStep}
          variant="progressOnly"
        ></StepBar>
      </div>

      <div className="space-y-1.5 w-full  ">
        <h6 className="form_label mb-0 block text-sm">Load Details</h6>
        <div className="rounded-xl border border-utilityGray200 bg-gray25 p-5">
          <div className="flex flex-wrap gap-y-3 -mx-1.5">
            <InputText
              parentClassName="w-[20%] px-1.5 flex-1"
              label="Total Weight"
              inputName="totalWeight"
              inputType="number"
              placeholder={'Enter total weight'}
              labelClassName="mb-1.5 block"
              value={totalWeightValue ?? formData.totalWeight}
              shouldFormatNumber={true}
              inputValueUnitType="lbs"
              required
              className={`${
                !validForm?.totalWeight
                  ? 'border border-red-500 border-solid'
                  : ''
              }`}
              onChangeFunc={(e) => {
                const newValue = e?.target?.value;

                if (Number(newValue) >= 0 || newValue === '') {
                  setFormData((old: any) => ({
                    ...old,
                    totalWeight: newValue,
                  }));

                  setTotalWeightValue(newValue);

                  if (newValue > 0) {
                    setIsTotalWeightManuallyChanged(true);
                  } else {
                    setIsTotalWeightManuallyChanged(false);
                  }
                } else {
                  setIsTotalWeightManuallyChanged(false);
                  setTotalWeightValue(newValue || 0);
                }

                setValidForm((old) => ({
                  ...old,
                  totalWeight: true,
                  totalWeightMax: true,
                  totalWeightMin: true,
                }));
              }}
              onFocus={() => {
                if (Number(formData?.totalWeight) === 0) {
                  setFormData((prevFormData: any) => ({
                    ...prevFormData,
                    totalWeight: '',
                  }));
                }
              }}
              errorText={
                !validForm.totalWeightMin
                  ? 'Total weight should not be 0'
                  : !validForm.totalWeightMax
                  ? 'Total weight should be less than 100,000 lbs'
                  : ''
              }
            />

            {formData?.classification === QUOTE_CLASSIFICATION.LTL ||
            formData?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL ? (
              <InputText
                parentClassName="w-[20%] px-1.5 flex-1"
                label="Linear Footage"
                inputName="linearFootage"
                inputType="number"
                placeholder={'Enter linear footage'}
                labelClassName="mb-1.5 block"
                value={formData.linearFootage}
                required={formData?.classification !== QUOTE_CLASSIFICATION.FTL}
                shouldFormatNumber={true}
                className={`${
                  !validForm?.linearFootage
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                onChangeFunc={(e) => {
                  const newValue = e?.target?.value;

                  if (Number(newValue) >= 0 || newValue === '') {
                    setFormData((old: any) => ({
                      ...old,
                      linearFootage: newValue,
                    }));
                  }

                  setValidForm((old) => ({
                    ...old,
                    linearFootage: true,
                    linearFootageMax: true,
                    linearFootageMin: true,
                  }));

                  if (newValue > 0) {
                    setIsLinearFootageManuallyChanged(true);
                  } else {
                    setIsLinearFootageManuallyChanged(false);
                  }
                }}
                onFocus={() => {
                  if (Number(formData?.linearFootage) === 0) {
                    setFormData((prevFormData: any) => ({
                      ...prevFormData,
                      linearFootage: '',
                    }));
                  }
                }}
                onBlur={(event) => {
                  if (event?.target?.value) {
                    const value = parseFloat(event.target.value);
                    handleLinearFootageBlur(value);
                  }
                }}
                inputValueUnitType="ft"
                errorText={
                  !validForm.linearFootageMin
                    ? 'Linear footage should not be 0'
                    : !validForm.linearFootageMax
                    ? 'Linear footage should be less than 53 Ft.'
                    : ''
                }
              />
            ) : (
              false
            )}

            {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
            formData?.classification === QUOTE_CLASSIFICATION.LTL ||
            formData?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL ? (
              <SelectBox
                parentClassName="w-[20%] px-1.5 flex-1"
                isClearable
                required
                name="primaryEquipmentType"
                placeholder="Select eqpt. type"
                label="Primary Equipment Type"
                labelClassName="form_label mb-1.5 block"
                options={vehicleTypeOptions}
                value={vehicleTypeOptions.find(
                  (item: any) => formData.primaryEquipmentType === item.value
                )}
                isLoading={isVehicleTypeLoading}
                isMultipleSelection={false}
                onChangeFunc={(event: any) => {
                  setValidForm((old) => ({
                    ...old,
                    primaryEquipmentType: true,
                  }));
                  const secondaryEquipmentTypesWithOutPrimary =
                    formData?.secondaryEquipmentTypes?.filter(
                      (set: any) => set !== event?.value
                    );
                  const secondaryEquipmentTypeFullNamesWithOutPrimary =
                    formData?.secondaryEquipmentTypeFullNames?.filter(
                      (setf: any) => setf !== event?.label
                    );
                  setFormData((old) => ({
                    ...old,
                    primaryEquipmentType: event?.value,
                    primaryEquipmentTypeFullName: event?.label,
                    secondaryEquipmentTypes:
                      secondaryEquipmentTypesWithOutPrimary,
                    secondaryEquipmentTypeFullNames:
                      secondaryEquipmentTypeFullNamesWithOutPrimary,
                  }));
                }}
                errorText={!validForm.primaryEquipmentType ? true : false}
              />
            ) : (
              false
            )}

            {formData?.classification === QUOTE_CLASSIFICATION.FTL ||
            formData?.classification === QUOTE_CLASSIFICATION.LTL ||
            formData?.classification === QUOTE_CLASSIFICATION.VOLUME_LTL ? (
              <SelectBox
                parentClassName="w-[20%] min-w-[295px] px-1.5 flex-1 multi-select-v2"
                selectValueMainClass="multi-select-v2-value"
                selectoptiontext="!mr-0"
                isClearable={false}
                name="secondaryEquipmentTypes"
                placeholder="Select eqpt. types"
                label="Secondary Equipment Types"
                labelClassName="form_label mb-1.5 block"
                options={vehicleTypeOptions.filter(
                  (eto: any) => eto.value !== formData.primaryEquipmentType
                )}
                value={vehicleTypeOptions.filter((item: any) =>
                  formData.secondaryEquipmentTypes?.includes(item.value)
                )}
                isLoading={isVehicleTypeLoading}
                isMultipleSelection={true}
                onChangeFunc={(event: any) => {
                  const newValue = event.map((e: any) => e.value);
                  const newLabel = event.map((e: any) => e.label);
                  setFormData((old) => ({
                    ...old,
                    secondaryEquipmentTypes: newValue,
                    secondaryEquipmentTypeFullNames: newLabel,
                  }));
                }}
                onMenuOpen={() => setIsSelectBoxOpen(true)}
                onMenuClose={() => setIsSelectBoxOpen(false)}
                menuIsOpen={isSelectBoxOpen}
                isMultipleEquipmentType={true}
              />
            ) : (
              false
            )}

            <div className="w-[20%] min-w-max px-1.5 flex-1">
              <label className="form_label mb-1.5 block">
                Commercial Value
              </label>
              <div className="flex gap-x-1">
                <InputText
                  parentClassName="flex-1"
                  inputName="insuranceCommercialValue"
                  inputType="number"
                  className={`pl-8 ${
                    !validForm.insuranceCommercialValue
                      ? 'border-[#f04438] '
                      : ''
                  }`}
                  placeholder={'Enter commercial value'}
                  value={formData.insuranceCommercialValue}
                  required={true}
                  icon={<CurrencyDollar className="input-currency-sign" />}
                  onChangeFunc={(e) => {
                    const newValue = e?.target?.value;

                    if (Number(newValue) >= 0 || newValue === '') {
                      setFormData((old: any) => ({
                        ...old,
                        insuranceCommercialValue: newValue,
                      }));
                    }
                  }}
                  shouldFormatNumber={true}
                />

                <SelectBox
                  name="insuranceCurrencyType"
                  labelClassName="form_label block mb-1.5"
                  parentClassName="currency-dropdown"
                  selectoptiontext="currency-dropdown-text"
                  id="insuranceCurrencyType"
                  classComp="rounded-none rounded-r-lg"
                  size="sm"
                  options={currencyTypeOptions}
                  onChangeFunc={(e: any) => {
                    setFormData((old: any) => ({
                      ...old,
                      insuranceCurrencyType: e?.value,
                    }));
                  }}
                  value={currencyTypeOptions.filter(
                    (val: any) =>
                      (formData.insuranceCurrencyType ?? CURRENCY.CAD) ===
                      val.value
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {formData?.classification === QUOTE_CLASSIFICATION.LTL ? (
        <div className=" space-y-1.5 w-full">
          <div className="flex justify-between items-center mb-1.5">
            <h6 className="form_label mb-0 block text-sm">Add Dimensions</h6>
            <div className="flex items-center justify-center gap-1.5">
              <Radio
                onChangeFunc={handleMeasurement(MEASUREMENT.LENGTH1.value)}
                id="lbs"
                value={MEASUREMENT.WEIGHT1.value}
                checked={weightMeasurement === MEASUREMENT.WEIGHT1.value}
                labelName="Imperial (lbs)"
                labelHtmlFor="lbs"
                radioLabelClassName="normal-case"
              />

              <Radio
                onChangeFunc={handleMeasurement(MEASUREMENT.LENGTH2.value)}
                checked={weightMeasurement === MEASUREMENT.WEIGHT2.value}
                value={MEASUREMENT.WEIGHT2.value}
                id="kgs"
                labelName="Metric (kgs)"
                labelHtmlFor="kgs"
                radioLabelClassName="normal-case"
              />
            </div>
          </div>
          {isDimensionLoading ? (
            <div className="flex justify-center mt-4">
              <div className="skeleton bg-gray50 rounded-none w-full h-[32px]"></div>
            </div>
          ) : (
            <Items
              formData={formData}
              validForm={validForm}
              initFormData={initFormData}
              initValidForm={initValidForm}
              handlingUnitOptions={handlingUnitOptions}
              validateMeasurement={validateMeasurement}
              lengthMeasurement={lengthMeasurement}
              weightMeasurement={weightMeasurement}
              setFormData={setFormData}
              isDimensionLoading={isDimensionLoading}
              setValidForm={setValidForm}
              setIsValid={setIsValidData}
              isValid={isValidData}
              isTotalWeightManuallyChanged={isTotalWeightManuallyChanged}
              setTotalDimensionsWeight={setTotalDimensionsWeight}
              setTotalWeightValue={setTotalWeightValue}
              isLinearFootageManuallyChanged={isLinearFootageManuallyChanged}
            />
          )}
        </div>
      ) : (
        ''
      )}
      {serviceList.length ? (
        <div className="space-y-1.5 w-full">
          <div className="flex items-center gap-x-1.5">
            <h6 className="form_label mb-0 block text-sm">
              Other Additional Services
            </h6>
            <div
              className={`cursor-pointer ${isLoading ? 'custom-loading' : ''}`}
              onClick={() => setIsShowAdditionalServiceInfoModal(true)}
            >
              <InfoCircle className="h-3.5 w-3.5 cursor-pointer text-primary" />
            </div>
          </div>
          <div className="rounded-xl border border-utilityGray200 bg-gray25 shadow-sm overflow-hidden">
            {serviceTypes?.OTHER?.length ? (
              getList(serviceTypes?.OTHER)
            ) : (
              <NotFoundUI
                iconClassName="!hidden"
                title="No services found"
                desc={`No Additional Services found. Please try again.`}
                containerClassName="!h-auto !my-5"
              />
            )}
          </div>
        </div>
      ) : (
        ''
      )}

      {isInbondMoreModalOpen && (
        <CommonModal
          title={'In-Bond Details'}
          titleDesc={'Please fill In-Bond Details'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="in-bond"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsInbondMore(false);
            setIsInbondMoreModalOpen(false);
          }}
          headerIcon={<PackagePlus />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (isInBondFormValid()) {
              setIsInbondMoreModalOpen(false);
            }
            handleServiceFormData();
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="in-bond"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsInbondMore(false);
            setIsInbondMoreModalOpen(false);
          }}
        >
          <div className="p-5 ">
            <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px] mb-5">
              {inbondTypeList.map((val: any, i) => (
                <div className="flex gap-2" key={`inbond_${i}`}>
                  <Radio
                    id={`inbond_${i}`}
                    onChangeFunc={handleChangeRadio}
                    inputName={'inbondName'}
                    value={val.value}
                    checked={inbondType === val.value}
                  />

                  <label
                    htmlFor={`inbond_${i}`}
                    className="text-textSecondary text-xs cursor-pointer font-medium"
                  >
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="w-full mb-4">
              <InputText
                inputName="inbondName"
                required={true}
                placeholder=""
                className={`form_control ${
                  !validServicesForm.inbondName
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Warehouse or Carrier who will cancel US Bond"
                labelClassName="block mb-1.5"
                value={inbondName}
                icon={
                  !validServicesForm.inbondName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                  ) : null
                }
                onChangeFunc={(e) => {
                  setInbondName(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full mb-5">
              <InputText
                inputName="inbondAddress"
                placeholder="123 Address"
                required={true}
                className={`form_control ${
                  !validServicesForm.inbondAddress
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Address"
                labelClassName="block mb-1.5"
                icon={
                  !validServicesForm.inbondName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                  ) : null
                }
                value={inbondAddress}
                onChangeFunc={(e) => {
                  setInbondAddress(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full mb-4">
              {getName(inbondContactMethod) !== 'Phone' ? (
                <InputText
                  inputName="inbondContactValue"
                  placeholder=""
                  required={true}
                  className={`form_control ${
                    !validServicesForm.inbondContactValue
                      ? 'border border-red-500 border-solid'
                      : ''
                  }`}
                  icon={
                    !validServicesForm.inbondContactValue ? (
                      <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                    ) : null
                  }
                  label={getName(inbondContactMethod)}
                  labelClassName="block mb-1.5"
                  value={inbondContactValue}
                  errorText={
                    !validServicesForm.inbondContactValue
                      ? 'This field is required'
                      : !validServicesForm.isEmailValid &&
                        getName(inbondContactMethod) === 'Email'
                      ? 'enter valid email'
                      : ''
                  }
                  onChangeFunc={(e) => {
                    setInbondContactValue(e.target.value);
                    setServicesValidForm((old) => ({
                      ...old,
                      [e.target.name]: true,
                    }));
                  }}
                />
              ) : (
                <PhoneInput
                  onNumberChange={handleNumberChange}
                  name="phone"
                  errors={
                    !validServicesForm.inbondContactValue
                      ? 'Phone number is required'
                      : !validServicesForm.isPhoneValid &&
                        getName(inbondContactMethod) === 'Phone'
                      ? 'Invalid Number'
                      : null
                  }
                  onBlur={handleNumberOnBlur}
                  value={inbondContactValue || ''}
                />
              )}
            </div>
            <div className="flex sm:flex-row flex-col flex-wrap gap-5 py-[12.5px]">
              {inbondContactMethodList.map((val: any, i) => (
                <div className="flex gap-2" key={`inbond_contact_${i}`}>
                  <Radio
                    id={`inbond_contact_${i}`}
                    onChangeFunc={handleChangeRadio}
                    inputName={'contactMethod'}
                    value={val.value}
                    checked={inbondContactMethod === val.value}
                  />
                  <label
                    htmlFor={`inbond_contact_${i}`}
                    className="text-textSecondary text-xs cursor-pointer font-medium uppercase"
                  >
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </CommonModal>
      )}
      {isDangerousMoreModalOpen && (
        <CommonModal
          title={'Dangerous Goods'}
          titleDesc={'Please provide dangerous goods details'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="dangerous_goods"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsDangerousFormSubmitted(false);
            setUnitedNationsNumber('');
            setGoodsClass('');
            setDescription('');
            setEmergencyContactName('');
            setEmergencyContactNumber('');
            setEmergencyContactExt('');
            setIsDangerousMore(false);
            setIsDangerousMoreModalOpen(false);
          }}
          headerIcon={<AlertHexagon />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            setIsDangerousFormSubmitted(true);

            if (isDangerousGoodsFormValid()) {
              setIsDangerousMoreModalOpen(false);
            }
            handleServiceFormData();
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="dangerous_goods"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsDangerousFormSubmitted(false);
            setUnitedNationsNumber('');
            setGoodsClass('');
            setDescription('');
            setEmergencyContactName('');
            setEmergencyContactNumber('');
            setEmergencyContactExt('');
            setIsDangerousMore(false);
            setIsDangerousMoreModalOpen(false);
          }}
        >
          <div className="p-5 flex flex-col md:gap-5 gap-3">
            <p className="text-grayLight600 font-normal text-xs ">
              Please provide the Dangerous Goods details, as these details will
              show up on the BOL. Failure to enter this data may result in
              delayed pickups.
            </p>
            <div className="flex sm:flex-row flex-col flex-wrap xxl:gap-5 xl:gap-4 gap-3 py-[12.5px] ">
              {dangerTypeList.map((val: any, i) => (
                <div className="flex gap-2" key={`danger_${i}`}>
                  <Radio
                    id={`danger_${i}`}
                    onChangeFunc={handleChangeRadio}
                    inputName={'dangerType'}
                    value={val.value}
                    checked={dangerType === val.value}
                  />
                  <label
                    htmlFor={`danger_${i}`}
                    className="text-textSecondary text-xs font-medium uppercase cursor-pointer"
                  >
                    {val.name}
                  </label>
                </div>
              ))}
            </div>
            <div className="grid sm:grid-cols-3 xxl:gap-5 xl:gap-4 gap-3 ">
              <InputText
                inputName="unitedNationsNumber"
                placeholder="UN0000"
                className={`form_control ${
                  !validServicesForm.unitedNationsNumber
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                required={true}
                label="UN #"
                labelClassName="block mb-1.5"
                value={unitedNationsNumber}
                icon={
                  !validServicesForm.inbondName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                onChangeFunc={(e) => {
                  setUnitedNationsNumber(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />

              <div className="content">
                <SelectBox
                  name="packagingGroup"
                  label="Packaging Group"
                  labelClassName="form_label block mb-1.5"
                  id="packagingGroup"
                  className="form_control"
                  options={packagingGroupOptions}
                  onChangeFunc={(event: any) => setPackagingGroup(event.value)}
                  value={packagingGroupOptions.find(
                    (val) => val.value === packagingGroup
                  )}
                />
              </div>

              <InputText
                inputType="number"
                inputName="goodsClass"
                placeholder=""
                className={`form_control ${
                  !validServicesForm.goodsClass
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Class"
                required={true}
                labelClassName="block mb-1.5"
                value={goodsClass}
                icon={
                  !validServicesForm.goodsClass ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                shouldFormatNumber={true}
                onChangeFunc={(e) => {
                  setGoodsClass(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full">
              <InputText
                inputName="description"
                placeholder=""
                className={`form_control ${
                  !validServicesForm.description
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="Technical Name or Description"
                required={true}
                labelClassName="block mb-1.5"
                value={description}
                icon={
                  !validServicesForm.description ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                onChangeFunc={(e) => {
                  setDescription(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="w-full ">
              <InputText
                inputName="emergencyContactName"
                placeholder=""
                className={`form_control ${
                  !validServicesForm.emergencyContactName
                    ? 'border border-red-500 border-solid'
                    : ''
                }`}
                label="24-hr Emergency Contact Name"
                required={true}
                labelClassName="block mb-1.5"
                icon={
                  !validServicesForm.emergencyContactName ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-5 w-5 text-sm font-normal text-danger500" />
                  ) : null
                }
                value={emergencyContactName}
                onChangeFunc={(e) => {
                  setEmergencyContactName(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
              />
            </div>
            <div className="grid lg:grid-cols-2 grid-col-1 xxl:gap-5 xl:gap-4 gap-3">
              <div className="md:flex flex-col">
                <PhoneInput
                  label
                  required
                  onNumberChange={handleDangerPhoneChange}
                  name="phone"
                  errors={
                    isDangerousFormSubmitted &&
                    !validServicesForm.emergencyContactNumber
                      ? 'Phone number is required'
                      : isDangerousFormSubmitted &&
                        !validServicesForm.isEmergencyPhoneValid
                      ? 'Invalid Number'
                      : null
                  }
                  value={emergencyContactNumber || ''}
                />
              </div>
              <InputText
                inputName="emergencyContactExt"
                placeholder=""
                className="form_control"
                label="Ext"
                labelClassName="block mb-1.5"
                value={emergencyContactExt}
                onChangeFunc={(e) => setEmergencyContactExt(e.target.value)}
              />
            </div>
          </div>
        </CommonModal>
      )}
      {isExcessValuationModalOpen && (
        <CommonModal
          title={'Excess Valuation'}
          titleDesc={'Please provide Declared Value'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="excess_valuation"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsDeclaredValueMore(false);
            setIsExcessValuationModalOpen(false);
          }}
          headerIcon={<AlertHexagon />}
          size={'max-w-[688px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (validServicesForm && isExcessValuation()) {
              setIsExcessValuationModalOpen(false);
            }
            handleServiceFormData();
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="excess_valuation"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsExcessValuationModalOpen(false);
          }}
        >
          <div className="flex flex-col justify-center py-3">
            <div className="xxl:w-full xl:w-2/5 w-full px-4">
              <p className="text-dark m-0 p-0 font-medium pt-2 pb-2 text-xs">
                Declared Value
              </p>
              <div className="flex items-center">
                <div className="mb-4 w-full">
                  <InputText
                    inputType="number"
                    inputName="declaredValue"
                    required={true}
                    placeholder=""
                    className={`form_control ${
                      !validServicesForm.declaredValue
                        ? 'border border-red-500 border-solid'
                        : ''
                    }`}
                    icon={
                      !validServicesForm.declaredValue ? (
                        <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                      ) : null
                    }
                    value={declaredValue}
                    shouldFormatNumber={true}
                    onChangeFunc={(e) => {
                      setDeclaredValue(e.target.value);
                      setServicesValidForm((old) => ({
                        ...old,
                        [e.target.name]: true,
                      }));
                    }}
                    onFocus={() => {
                      if (declaredValue === 0) {
                        setDeclaredValue('');
                      }
                    }}
                    onBlur={() => {
                      if (declaredValue === '') {
                        setDeclaredValue(0);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full py-2 xl:px-4 px-3">
              <p className="text-dark m-0 p-0 font-medium pb-2 text-xs">
                Currency
              </p>
              <TabButton
                tabParentClassName="sms:w-fit w-full sms:mb-0 mb-2 "
                parentClassName="w-full currency-tab quoting-currency"
                activeClassName="text-grayLight900 bg-utilityGray100"
                className=""
                isTooltip={true}
                tabArray={filterArr}
                handleOnClick={(e: any) => {
                  setDeclaredValueUnit(e.target.dataset.value);
                }}
                isActive={declaredValueUnit}
              />
            </div>
          </div>
        </CommonModal>
      )}
      {isLoadInsuranceModalOpen && (
        <CommonModal
          title={'Load Insurance'}
          titleDesc={'Please Provide Load Insurance Value'}
          handleClose={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="load_insurance"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsLoadInsurance(false);
            setIsLoadInsuranceModalOpen(false);
          }}
          headerIcon={<InfoHexagon />}
          size={'max-w-[600px]'}
          secondaryBtnText="Cancel"
          primaryBtnText="Save Changes"
          primaryBtnOnClick={() => {
            if (validServicesForm && isLoadInsuranceFormValid()) {
              setIsLoadInsuranceModalOpen(false);
            }
            setFormData((old: any) => ({
              ...old,
              insuranceCommercialValue: loadInsuranceValue,
              insuranceCurrencyType: loadInsuranceValueUnit,
            }));
          }}
          secondaryBtnOnClick={() => {
            let option = document.querySelectorAll<HTMLInputElement>(
              'input[type="checkbox"][name="load_insurance"]'
            );

            if (option?.length) {
              option[0].click();
            }
            setServicesValidForm(initServiceValidForm);
            setIsLoadInsuranceModalOpen(false);
          }}
        >
          <div className="p-5">
            <div className="rounded-xl border border-utilityGray200 bg-gray50 p-4 flex gap-2.5">
              <InputText
                parentClassName="flex-1"
                label="Commercial Value"
                labelClassName="block mb-1.5"
                inputType="number"
                inputName="loadInsuranceValue"
                required={true}
                placeholder=""
                className={`form_control ${
                  !validServicesForm.isLoadInsuranceValid
                    ? 'border border-red-500 border-solid pr-8'
                    : ''
                }`}
                icon={
                  !validServicesForm.isLoadInsuranceValid ? (
                    <AlertCircle className="absolute -translate-y-2/4 top-2/4 right-2 h-4 w-4 text-sm font-normal text-danger500" />
                  ) : null
                }
                value={loadInsuranceValue}
                shouldFormatNumber={true}
                onChangeFunc={(e) => {
                  setLoadInsuranceValue(e.target.value);
                  setServicesValidForm((old) => ({
                    ...old,
                    [e.target.name]: true,
                  }));
                }}
                onFocus={() => {
                  if (loadInsuranceValue === 0) {
                    setLoadInsuranceValue('');
                  }
                }}
                onBlur={() => {
                  if (loadInsuranceValue === '') {
                    setLoadInsuranceValue(0);
                  }
                }}
              />

              <SelectBox
                label="Currency"
                name="currency"
                placeholder="Select Currency"
                parentClassName="currency-dropdown"
                selectoptiontext="currency-dropdown-text"
                isLoading={isLoading}
                labelClassName="form_label block mb-1.5"
                value={filterSelectArr.find(
                  (item: any) => item.value === loadInsuranceValueUnit
                )}
                onChangeFunc={(e: any) => {
                  setLoadInsuranceValueUnit(e.value);
                }}
                options={filterSelectArr}
              />
            </div>
          </div>
        </CommonModal>
      )}
      {action.tailgate && (
        <ConfirmModal
          title="Tailgate Over Dimensions"
          description={`Tailgate is not offered for shipments that are over ${FOOT} feet in length and/or have a total weight ${
            weightMeasurement === MEASUREMENT.WEIGHT1.value
              ? `${LBS.MAX_WEIGHT}lbs`
              : `${KGS.MAX_WEIGHT}kgs`
          }. This may result in some carriers not quoting this load. Are you sure you want to proceed`}
          button1="Yes, I am sure"
          button2="Modify Selection"
          handleClose={handleModalClose}
        />
      )}

      {action.totalWeightMismatch &&
        isValidDimension(formData?.dimensions, validateMeasurement)?.valid && (
          <ConfirmModal
            title="Total Weight Mismatch"
            description={`The total weight you entered ${
              formData.totalWeight
                ? `(${formData.totalWeight} ${
                    weightMeasurement === MEASUREMENT.WEIGHT1.value
                      ? 'lbs'
                      : 'kgs'
                  })`
                : ''
            } does not match the sum of all dimension weights ${
              totalDimensionsWeight
                ? `(${totalDimensionsWeight} ${
                    weightMeasurement === MEASUREMENT.WEIGHT1.value
                      ? 'lbs'
                      : 'kgs'
                  })`
                : ''
            }. 

Would you like to update the total weight to match the dimension weight?`}
            button1="Yes, Update Total Weight"
            button2="Close"
            handleClose={handleWeightModalClose}
          />
        )}

      {action.unusual && (
        <ConfirmModal
          title="Unusual selection"
          description="Based on the options you selected, a lot of carriers might not give a direct quote for this load. Are you sure you want to proceed?"
          button1="Yes, I am sure"
          button2="Modify Selection"
          handleClose={handleModalClose}
        />
      )}

      {isShowAdditionalServiceInfoModal && (
        <AdditionalServiceModal
          handleClose={() => setIsShowAdditionalServiceInfoModal(false)}
          services={
            serviceTypes?.OTHER?.filter(
              (val: any) =>
                quoteFormData?.classification == QUOTE_CLASSIFICATION.FTL ||
                val.slug === 'load_insurance' ||
                (quoteFormData?.classification !== QUOTE_CLASSIFICATION.FTL &&
                  val.loadLinkTerm !== null)
            ) || []
          }
          title="Other Additional Services"
          titleDesc="Explore key details of additional pickup and delivery services"
        />
      )}
    </>
  );
};

export default Dimensions;
