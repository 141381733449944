import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DateRangePicker from 'src/components/DateRangePicker';
import { getCustomerAnalytics } from 'src/services/CustomerService';
import { getDateRange } from 'src/utils/CommonFunctions';

import AnalyticsData from './AnalyticsData';
import AnalyticsDataLoading from './AnalyticsDataLoading';
import HeatMapCmp from './HeatMapCmp';

interface IProps {
  customerId: string;
}

const CustomerDetailAnalyticsTab = ({ customerId }: IProps) => {
  const [analytics, setAnalytics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // date range picker
  const [activeFilter, setActiveFilter] = useState<any>('monthly');
  const [selectedTimeRange, setSelectedTimeRange] = useState<string>('monthly');
  const [startDate, setStartDate] = useState<any>(
    moment().local().startOf('month').endOf('day').toDate()
  );
  const [endDate, setEndDate] = useState<any>(
    moment().local().clone().endOf('month').endOf('day').toDate()
  );

  const getCustomerAnalyticsData = (param: any) => {
    setIsLoading(true);

    getCustomerAnalytics(param)
      .then((response: any) => {
        if (response.data) {
          setAnalytics(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const StartingDate = moment(startDate).local().format('YYYY-MM-DD');
    const EndingDate = moment(endDate).local().format('YYYY-MM-DD');

    getCustomerAnalyticsData({
      customerId,
      startDate: StartingDate,
      endDate: EndingDate,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (activeFilter !== '') {
      const { start, end } = getDateRange(activeFilter);
      setStartDate(start);
      setEndDate(end);
    }

    if (activeFilter === 'weekly') {
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'monthly') {
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'yearly') {
      setSelectedTimeRange(activeFilter);
    } else if (activeFilter === 'daily') {
      setSelectedTimeRange('custom');
    }
  }, [activeFilter]);

  const handleDatePickerChange = (date: any, timeRange?: string) => {
    const [start, end] = date;

    if (
      timeRange === 'weekly' ||
      timeRange === 'monthly' ||
      timeRange === 'yearly'
    ) {
      setActiveFilter(timeRange);
    } else if (
      moment(start).isSame(moment(), 'days') &&
      moment(end).isSame(moment(), 'days')
    ) {
      setActiveFilter('daily');
    } else {
      setActiveFilter('');

      setStartDate(start);
      setEndDate(end);

      if (!end) {
        setEndDate(start);
      }
    }

    if (timeRange) {
      setSelectedTimeRange(timeRange);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between flex-wrap">
        <div>
          <h6 className="text-grayLight900 text-lg font-semibold sm:mb-0 mb-1">
            Analytics
          </h6>
        </div>

        <div className="w-[250px]">
          <DateRangePicker
            handleDatePickerChange={(dates: any, type?: string) =>
              handleDatePickerChange(dates, type)
            }
            selectedTimeRange={selectedTimeRange}
            startDate={startDate}
            endDate={endDate}
            allTimeStartDate={moment()} // TODO : pass onboarding
            showYearDropdown={false}
            isToday={activeFilter === 'daily'}
            isShowDropDownIcon={false}
            containerClassName="right-0"
            parentClassName="sales-datepicker"
          />
        </div>
      </div>

      {isLoading ? (
        <AnalyticsDataLoading />
      ) : (
        <AnalyticsData analytics={analytics} activeFilter={activeFilter} />
      )}

      <HeatMapCmp customerId={customerId} />
    </>
  );
};

export default CustomerDetailAnalyticsTab;
