import React from 'react';

import AddressDetails from './AddressDetails';

interface IProps {
  action: any;
  setAction: any;
  shippers: any;
  consignees: any;
  shipperOptions: any;
  consigneeOptions: any;
  isValidAddress: any;
  setIsValidAddress: any;
  isConsigneeBtnDisable: any;
  setIsConsigneeBtnDisable: any;
  isShipperBtnDisable: any;
  setIsShipperBtnDisable: any;
  getShipper: any;
  currentLegData: any;
  setCurrentLegData: any;
  tempLegData: any;
  setTempLegData: any;
  shipperAddress: any;
  setShipperAddress: any;
  consigneeAddress: any;
  setConsigneeAddress: any;
  shipperContact: any;
  setShipperContact: any;
  consigneeContact: any;
  setConsigneeContact: any;
  shipperContactOptions: any;
  setShipperContactOptions: any;
  consigneeContactOptions: any;
  setConsigneeContactOptions: any;
  isShipperContactBtnDisable: any;
  setIsShipperContactBtnDisable: any;
  isConsigneeContactBtnDisable: any;
  setIsConsigneeContactBtnDisable: any;
  currentStep: any;
  isShipperPhoneNumberValue: any;
  setIsShipperPhoneNumberValue: any;
  isConsigneePhoneNumberValue: any;
  setIsConsigneePhoneNumberValue: any;
  shipperAdditionalServiceIds: any;
  setShipperAdditionalServiceIds: any;
  consigneeAdditionalServiceIds: any;
  setConsigneeAdditionalServiceIds: any;
}

const Step1 = ({
  action,
  setAction,
  shippers,
  consignees,
  shipperOptions,
  consigneeOptions,
  isValidAddress,
  setIsValidAddress,
  isConsigneeBtnDisable,
  setIsConsigneeBtnDisable,
  isShipperBtnDisable,
  setIsShipperBtnDisable,
  getShipper,
  currentLegData,
  setCurrentLegData,
  tempLegData,
  setTempLegData,
  shipperAddress,
  setShipperAddress,
  consigneeAddress,
  setConsigneeAddress,
  shipperContact,
  setShipperContact,
  consigneeContact,
  setConsigneeContact,
  shipperContactOptions,
  setShipperContactOptions,
  consigneeContactOptions,
  setConsigneeContactOptions,
  isShipperContactBtnDisable,
  setIsShipperContactBtnDisable,
  isConsigneeContactBtnDisable,
  setIsConsigneeContactBtnDisable,
  currentStep,
  isShipperPhoneNumberValue,
  setIsShipperPhoneNumberValue,
  isConsigneePhoneNumberValue,
  setIsConsigneePhoneNumberValue,
  shipperAdditionalServiceIds,
  setShipperAdditionalServiceIds,
  consigneeAdditionalServiceIds,
  setConsigneeAdditionalServiceIds,
}: IProps) => (
  <AddressDetails
    action={action}
    setAction={setAction}
    currentLegData={currentLegData}
    setCurrentLegData={setCurrentLegData}
    shippers={shippers}
    consignees={consignees}
    shipperOptions={shipperOptions}
    consigneeOptions={consigneeOptions}
    isValidAddress={isValidAddress}
    setIsValidAddress={setIsValidAddress}
    isConsigneeBtnDisable={isConsigneeBtnDisable}
    setIsConsigneeBtnDisable={setIsConsigneeBtnDisable}
    isShipperBtnDisable={isShipperBtnDisable}
    setIsShipperBtnDisable={setIsShipperBtnDisable}
    getShipper={getShipper}
    tempLegData={tempLegData}
    setTempLegData={setTempLegData}
    shipperAddress={shipperAddress}
    setShipperAddress={setShipperAddress}
    consigneeAddress={consigneeAddress}
    setConsigneeAddress={setConsigneeAddress}
    shipperContact={shipperContact}
    setShipperContact={setShipperContact}
    consigneeContact={consigneeContact}
    setConsigneeContact={setConsigneeContact}
    shipperContactOptions={shipperContactOptions}
    setShipperContactOptions={setShipperContactOptions}
    consigneeContactOptions={consigneeContactOptions}
    setConsigneeContactOptions={setConsigneeContactOptions}
    isShipperContactBtnDisable={isShipperContactBtnDisable}
    setIsShipperContactBtnDisable={setIsShipperContactBtnDisable}
    isConsigneeContactBtnDisable={isConsigneeContactBtnDisable}
    setIsConsigneeContactBtnDisable={setIsConsigneeContactBtnDisable}
    currentStep={currentStep}
    isShipperPhoneNumberValue={isShipperPhoneNumberValue}
    setIsShipperPhoneNumberValue={setIsShipperPhoneNumberValue}
    isConsigneePhoneNumberValue={isConsigneePhoneNumberValue}
    setIsConsigneePhoneNumberValue={setIsConsigneePhoneNumberValue}
    shipperAdditionalServiceIds={shipperAdditionalServiceIds}
    setShipperAdditionalServiceIds={setShipperAdditionalServiceIds}
    consigneeAdditionalServiceIds={consigneeAdditionalServiceIds}
    setConsigneeAdditionalServiceIds={setConsigneeAdditionalServiceIds}
  />
);

export default Step1;
