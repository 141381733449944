import { Calendar, Clock } from '@untitled-ui/icons-react/build/cjs';
import React from 'react';
import CommonModal from 'src/components/CommonModal';
import DateSelect from 'src/components/DateSelect/DateSelect';
import InputText from 'src/components/InputText/InputText';

interface IProps {
  handleClose: any;
}

const SetAppointmentModal = ({ handleClose }: IProps) => (
  <CommonModal
    title={'Set Appointment'}
    titleDesc={'Assign a carrier to complete your shipment'}
    handleClose={() => handleClose(false)}
    headerIcon={<Calendar />}
    size={'max-w-[600px]'}
    isOverflow={true}
    primaryBtnText="Set Appointment"
    secondaryBtnText="Cancel"
  >
    <div className="space-y-4 w-full p-5 ">
      <div className="flex gap-2">
        <DateSelect
          inputName="appointmentDate"
          className="form_control"
          parentClassName="datepicker-w-auto one-month-datepicker w-full flex-1"
          label="Select Date"
          // selected={value}
          onChangeFunc={() => {}}
          // minDate="test"
          labelClassName="block"
          placeholder="Select Date"
          dateFormat="dd/MM/yyyy"
          icon={<Calendar className="h-5 w-5" />}
          // value="date"
        />
        <InputText
          inputName="appointmentTime"
          label="Appointment Time"
          placeholder="HH:mm"
          labelClassName="block mb-1.5"
          parentClassName="flex-1"
          // value="11:12"
          onChangeFunc={() => {}}
          mask="99:99"
          // errorText={error?.message}
          icon={
            <div className="absolute top-1/2 -translate-y-1/2 right-2">
              <Clock className="h-4 w-4" />
            </div>
          }
        />
      </div>
      {/*BELOW CODE WILL BE VISIBLE WHEN CLICKED ON ROLLOVER DELIVERY */}
      <InputText
        labelClassName="block mb-1.5"
        inputName="appointmentNotes"
        label="Please add a detailed explanation of why need delivery rollover"
        placeholder="Enter explanation"
        asTextarea="textarea"
        className="h-32"
      />
    </div>
  </CommonModal>
);

export default SetAppointmentModal;
