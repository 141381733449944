import {
  Phone,
  Plus,
  Send01,
  SpacingHeight01,
} from '@untitled-ui/icons-react/build/cjs';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonCmp from 'src/components/ButtonCmp';
import InputText from 'src/components/InputText/InputText';
import StatusDotProfileCmp from 'src/components/StatusDotProfileCmp';
import TabButton from 'src/components/TabButton';
import TooltipCmp from 'src/components/TooltipCmp';
import { AuthContext } from 'src/context/AuthContext';
import {
  getRadarOrderChatDetail,
  sendOrderChatMessage,
} from 'src/services/RadarService';
// import AiStar from '../../../assets/img/AiStarIcon.svg';
import { getShortName } from 'src/utils/CommonFunctions';

import TeamIcon1 from '../../../assets/img/Avatar7.png';
import defaultImage from '../../../assets/img/default-image.jpg';
import aiEmoji from '../../../assets/img/inbound-emoji.webp';

// Helper function to format message text
const formatMessageText = (text: string) => {
  if (!text) return '';

  // Step 1: Replace escaped \r\n and \n with actual newlines
  let formattedText = text
    .replace(/\\r\\n/g, '\r\n') // Replace escaped \r\n with actual newline
    .replace(/\\n/g, '\n'); // Replace escaped \n with actual newline

  // Step 2: Remove leading and trailing \r\n or \n (only at the start or end)
  formattedText = formattedText.replace(/^[\r\n]+|[\r\n]+$/g, '');

  // Step 3: Replace all remaining \r\n or \n with <br /> for HTML line breaks
  formattedText = formattedText.replace(/\r\n|\n/g, '<br />');

  return formattedText;
};

const appendSampleData = {
  id: 1,
  masterOrderId: 60,
  legId: 25,
  messageId:
    'AQMkAGZiZGM1MGQ1LTM4ZmMtNDVlZi04N2M0LWUxODc4ZGY4OTFhYwBGAAADHI-Qkdj8bkes3ud2N6pkHwcAy9Y0S07VEEm71_CPFv-riQAAAgEJAAAAy9Y0S07VEEm71_CPFv-riQABurPn0wAAAA==',
  emailText: 'Test 232',
  emailType: 'SEND',
  senderType: 'SALES_REP',
  senderId: 1,
  createdAt: '2025-02-28 13:36:31',
  updatedAt: '2025-02-28 13:36:31',
  from: {
    name: 'WAL Development Team',
    address: 'dev@WESTERNALLIANCELOGISTICS.COM',
  },
  sender_details: {
    name: 'Dev User',
    image: 'a4344362-cdbb-4ffd-b882-87674778b1a5.jpeg',
    imageUrl: 'https://storage.googleapis.com/western-alliance-gcp/',
  },
};

const RadarChatBlock = ({
  order,
  setIsChatBlockOpen,
  isChatBlockOpen,
}: {
  order: any;
  setIsChatBlockOpen: any;
  isChatBlockOpen: boolean;
}) => {
  // let filterArr: any = [
  //   {
  //     value: 'internal_notes',
  //     name: 'Internal Notes',
  //     count: '10',
  //   },
  // ];
  const { currentUser } = useContext(AuthContext);
  const [isChatLoading, setIsChatLoading] = useState(true);

  console.log('isChatLoading :>> ', isChatLoading);
  const [page, setPage] = useState(1);
  const limit = 50;
  const [activeTab, setActiveTab] = useState('');
  const [activeLegData, setActiveLegData]: any = useState(null);
  const [filterArr, setFilterArr] = useState([]);
  const [messagesList, setMessagesList] = useState<any[]>([]);
  const [groupedMessages, setGroupedMessages] = useState<any[]>([]);
  const [value, setValue] = useState('');
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [isMoreData, setIsMoreData] = useState(true);
  const [isScrollToBottom, setIsScrollToBottom] = useState(true);
  const [isSendMsgLoading, setIsSendMsgLoading] = useState(false);
  const { isSocketConnected, socketIO } = useSelector(
    (state: any) => state.SocketConnection
  );

  const setDefaultMessage = (messageText = '') => {
    const updatedData = {
      ...appendSampleData,
      from: {
        name: currentUser?.name,
        address: currentUser?.email,
      },
      sender_details: {
        name: currentUser?.name,
        image: currentUser?.image,
        imageUrl: currentUser?.imageUrl,
      },
      senderId: currentUser?.id,
      createdAt: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      masterOrderId: order?.id,
      legId: order?.orderLegs?.[0]?.id,
      emailText: messageText,
    };

    return updatedData;
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const container = chatContainerRef.current;

      if (isScrollToBottom) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth',
        });
      } else {
        container.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
      }
    }
  };

  const isGetRadarOrderChatDetail = (isUrl: string, isFreshData = false) => {
    setIsChatLoading(true);
    getRadarOrderChatDetail(isUrl)
      .then(async (response: any) => {
        if (isFreshData) {
          setMessagesList(response?.data?.reverse() || []);
        } else {
          setMessagesList((prevMessageList) => [
            ...(response?.data ? response.data.reverse() : []),
            ...prevMessageList,
          ]);
          setPage((preavPage) => preavPage + 1);
        }

        if (response?.data?.length < limit) {
          setIsMoreData(false);
        } else {
          setIsMoreData(true);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.code === 'ERR_CANCELED') {
          return;
        } else {
          setMessagesList([]);
          setGroupedMessages([]);
          setIsMoreData(true);
          setPage(1);
        }
      })
      .finally(() => {
        setIsChatLoading(false);
      });
  };
  useEffect(() => {
    if (order && order?.orderLegs) {
      const filterArrLegs = order?.orderLegs?.map((item: any) => ({
        value: `${item?.id}`,
        name: `leg${item?.legPosition}`,
        count: 0,
      }));
      const isActiveLegData = order?.orderLegs?.find(
        (item: any) => item.isActive === 1
      );
      setActiveLegData(isActiveLegData ? isActiveLegData : null);
      setFilterArr(filterArrLegs);
      setActiveTab(isActiveLegData ? `${isActiveLegData?.id}` : '');

      if (isMoreData && isActiveLegData?.masterOrderId) {
        isGetRadarOrderChatDetail(
          `${isActiveLegData?.masterOrderId}?legId=${isActiveLegData?.id}&page=${page}&limit=${limit}`
        );
        setIsScrollToBottom(true);
      }
    }
  }, [order?.id]);

  const groupMessagesByDate = (messages: any) => {
    const grouped: any = [];

    messages.forEach((message: any) => {
      const date = new Date(message.createdAt).toLocaleDateString();
      const existingGroup = grouped?.find((group: any) => group.date === date);

      if (existingGroup) {
        existingGroup.messages.push(message);
      } else {
        grouped.push({ date, messages: [message] });
      }
    });

    return grouped;
  };

  useEffect(() => {
    if (messagesList?.length > 0) {
      const grouped = groupMessagesByDate(messagesList);
      setGroupedMessages(grouped);
    }
  }, [messagesList]);

  const handleScroll = () => {
    if (
      chatContainerRef.current?.scrollTop === 0 &&
      groupedMessages?.length > 0 &&
      isMoreData &&
      page > 1 &&
      activeLegData?.masterOrderId
    ) {
      isGetRadarOrderChatDetail(
        `${activeLegData?.masterOrderId}?legId=${activeLegData?.id}&page=${page}&limit=${limit}`
      );
      setIsScrollToBottom(false);
    }
  };

  useEffect(() => {
    if (groupedMessages?.length > 0) {
      scrollToBottom();
    }
    const container = chatContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [groupedMessages]);

  const onSendMessage = async (isValue: any) => {
    if (isValue && !isSendMsgLoading) {
      setIsSendMsgLoading(true);
      let payload = {
        masterOrderId:
          groupedMessages?.length > 0
            ? groupedMessages?.[0]?.messages?.[0]?.masterOrderId
            : null,
        legId:
          groupedMessages?.length > 0
            ? groupedMessages?.[0]?.messages?.[0]?.legId
            : null,
        message: `<p>${isValue}</p>`,
      };
      sendOrderChatMessage(payload)
        .then(async (response: any) => {
          if (response) {
            setMessagesList((prevMessageList) => [
              ...prevMessageList,
              setDefaultMessage(value),
            ]);
            setValue('');
          }
        })
        .catch((e) => {
          console.log(e);

          if (e.code === 'ERR_CANCELED') {
            return;
          }
        })
        .finally(() => {
          setIsSendMsgLoading(false);
        });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();

        if (value.trim()) {
          onSendMessage(value.trim());
        }
      }
    }
  };

  useEffect(() => {
    if (isSocketConnected && !!socketIO?.on) {
      socketIO.on('radar-email-chats', (newActivity: any) => {
        console.log('got newActivity through socket', newActivity);

        if (newActivity?.isNewMessageGet) {
          isGetRadarOrderChatDetail(
            `${activeLegData?.masterOrderId}?legId=${
              activeLegData?.id
            }&page=${1}&limit=${limit}`,
            true
          );
          setPage(1);
        }
      });

      return () => {
        if (socketIO?.on) {
          socketIO?.off(`radar-email-chats`);
        }
      };
    }
  }, [isSocketConnected]);

  return (
    <>
      <div className="radar-detail-box overflow-auto flex flex-col flex-1">
        <div className="flex gap-x-2 justify-between border-b border-utilityGray200 bg-gray50">
          <TabButton
            tabParentClassName="!items-stretch cursor-pointer"
            tabBadgeClassName="!bg-gray50"
            activeClassName="!bg-white"
            className="border-l-0 border-t-0 border-b-0 !border-r border-utilityGray200 !rounded-none !bg-gray50"
            parentClassName="rounded-none w-full"
            tabArray={filterArr}
            isActive={activeTab}
            handleOnClick={(e: any) => {
              if (e?.target?.dataset?.value !== activeTab) {
                setMessagesList([]);
                setGroupedMessages([]);
                setIsMoreData(true);
                setPage(1);
                setActiveTab(e?.target?.dataset?.value);

                if (e?.target?.dataset?.value) {
                  isGetRadarOrderChatDetail(
                    `${activeLegData?.masterOrderId}?legId=${
                      e?.target?.dataset?.value
                    }&page=${1}&limit=${limit}`
                  );
                }
              }
            }}
            leftSideContent={<StatusDotProfileCmp dotColor="green" />}
            leftSideContentClass="hidden"
            leftSideContentActiveClass="!flex"
          />
          <ButtonCmp
            className="btn_secondary_black lg:!px-[9px] !px-2 my-2.5 mx-4"
            icon={<SpacingHeight01 className="w-4 h-4" />}
            onClick={() => setIsChatBlockOpen(!isChatBlockOpen)}
          >
            <></>
          </ButtonCmp>
        </div>

        <>
          <div className="px-4 py-3 border-b border-utilityGray200 flex items-center gap-x-2.5">
            <div className="flex items-center gap-x-1.5 flex-1">
              {activeLegData?.carrierImageUrl ? (
                <img
                  src={
                    activeLegData?.carrierImageUrl + activeLegData?.carrierImage
                  }
                  className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none"
                />
              ) : (
                <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-utilityGray100 text-primary text-[8px]">
                  {getShortName(
                    activeLegData?.carrierName
                      ? activeLegData?.carrierName
                      : 'Unassign Carrier'
                  )}
                </div>
              )}
              <h6 className="text-grayLight900 text-sm font-medium truncate">
                {activeLegData?.carrierName
                  ? activeLegData?.carrierName
                  : 'Unassign Carrier'}
              </h6>
            </div>
            <div className="flex gap-x-2">
              <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2 text-grayLight600 text-[11px] leading-4 font-semibold">
                <TooltipCmp message={'groupname'}>
                  {/* IF IMAGE BELOW CODE */}
                  <div className="rounded-full bg-white">
                    <img
                      src={defaultImage}
                      className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full`}
                    />
                  </div>
                  {/* ELSE */}
                  {/* <div className="rounded-full bg-white">
                    <img
                      src={TeamIcon1}
                      className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                    />
                  </div> */}
                </TooltipCmp>
                <TooltipCmp message={'groupname'}>
                  {/* IF IMAGE BELOW CODE */}
                  {/* <div className="rounded-full bg-white">
                    <img
                      src={defaultImage}
                      className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full`}
                    />
                  </div> */}
                  {/* ELSE */}
                  <div className="rounded-full bg-white">
                    <img
                      src={TeamIcon1}
                      className={`w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full `}
                    />
                  </div>
                </TooltipCmp>
                <TooltipCmp message={''}>
                  <div className="rounded-full bg-white">
                    <div
                      className={`min-w-6 h-6 border-gray-200 border-[1px] flex-none rounded-full bg-utilityGray100 flex items-center justify-center p-1`}
                    >
                      +2
                    </div>
                  </div>
                </TooltipCmp>
              </div>
              <div className="w-6 h-6 border-borderPrimary border border-dashed flex-none rounded-full flex items-center justify-center cursor-pointer">
                <Plus className="w-4 h-4 text-gray400" />
              </div>
            </div>
            <ButtonCmp
              className="btn_secondary_black lg:!px-[9px] !px-2 "
              icon={<Phone className="w-4 h-4" />}
            >
              <></>
            </ButtonCmp>
          </div>

          <div
            className="px-4 py-3 flex-1 overflow-auto custom-scrollbar-v2 flex"
            ref={chatContainerRef}
          >
            <ul className="w-full mt-auto">
              {groupedMessages?.map((group, index) => (
                <React.Fragment key={index}>
                  <li className="flex items-center my-4">
                    <span className="h-[1px] bg-utilityGray200 w-full"></span>
                    <p className="text-grayLight600 text-xs text-center px-2 text-nowrap">
                      {group.date}
                    </p>
                    <span className="h-[1px] bg-utilityGray200 w-full"></span>
                  </li>
                  {group?.messages?.map((message: any) => {
                    if (message?.emailType === 'ORDER_UPDATE') {
                      return (
                        <li className="flex items-center my-4" key={message.id}>
                          <span className="h-[1px] bg-utilityGray200 w-full"></span>
                          <p className="text-grayLight600 text-xs text-center px-2 text-nowrap">
                            {message.emailText}
                          </p>
                          <span className="h-[1px] bg-utilityGray200 w-full"></span>
                        </li>
                      );
                    } else {
                      return (
                        <li
                          className={`${
                            message?.emailType === 'RECEIVE'
                              ? 'flex gap-3 max-w-[80%] mt-2'
                              : 'ml-auto flex-row-reverse flex gap-3 max-w-[80%] mt-2'
                          }`}
                          key={message.id}
                        >
                          {message?.senderType === 'AI' &&
                          message?.emailType !== 'RECEIVE' ? (
                            <div className="rounded-full bg-white">
                              <img
                                src={aiEmoji}
                                className={`w-6 h-6 rounded-full flex-none bg-utilityPurple300 p-0.5`}
                              />
                            </div>
                          ) : (
                            <div className="w-6 h-6 rounded-full border border-utilityBlack border-opacity-[0.08] flex-none flex items-center justify-center uppercase bg-primary text-white text-xs">
                              {getShortName(`${message?.from?.name}`)}
                            </div>
                          )}
                          <div className="space-y-1">
                            <div
                              className={`text-grayLight600 text-xs font-normal flex gap-2.5 flex-row-reverse ${
                                message?.emailType === 'RECEIVE'
                                  ? 'justify-end'
                                  : ''
                              }`}
                            >
                              {message?.senderType === 'AI' &&
                              message?.emailType !== 'RECEIVE'
                                ? message?.sender_details?.name
                                : currentUser?.email?.toLowerCase() ===
                                  message?.from?.address?.toLowerCase()
                                ? 'You'
                                : message?.from?.name}
                            </div>
                            <div className="rounded-tr-none bg-primary50 shadow-sm rounded-lg border border-utilityGray200 text-grayLight900 text-xs font-normal p-3">
                              {message?.emailText ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: formatMessageText(
                                      message.emailText
                                        ?.replace('"', '')
                                        .replace(/^[\r\n]+|[\r\n]+$/g, '')
                                    ),
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="flex justify-between">
                              <div className="flex items-center [&>div:not(:first-child)>div>div]:border-white [&>div:not(:first-child)>div>div]:border-[1.5px] [&>div:not(:first-child)]:-ml-2 text-grayLight600 text-[7px] leading-4 font-semibold">
                                <TooltipCmp message={'groupname'}>
                                  {message?.sender_details?.imageUrl ? (
                                    <div className="rounded-full bg-white">
                                      <img
                                        src={
                                          message?.sender_details?.imageUrl +
                                          message?.sender_details?.image
                                        }
                                        className={`w-3.5 h-3.5 border-gray-200 border-[1px] flex-none rounded-full`}
                                      />
                                    </div>
                                  ) : (
                                    <div className="rounded-full bg-white">
                                      <img
                                        src={TeamIcon1}
                                        className={`w-3.5 h-3.5 border-gray-200 border-[1px] flex-none rounded-full `}
                                      />
                                    </div>
                                  )}
                                </TooltipCmp>

                                <TooltipCmp message={''}>
                                  <div className="rounded-full bg-white">
                                    <div
                                      className={`min-w-3.5 h-3.5 border-gray-200 border-[1px] flex-none rounded-full bg-utilityGray100 flex items-center justify-center p-0.5`}
                                    >
                                      +2
                                    </div>
                                  </div>
                                </TooltipCmp>
                              </div>
                              <div className="text-grayLight600 text-[11px] leading-[1.1] font-normal">
                                {new Date(
                                  message.createdAt
                                ).toLocaleTimeString()}{' '}
                                (via Email)
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  })}
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div>
            <div className="px-4 py-3 flex gap-2 border-t border-utilityGray200">
              <InputText
                inputName="message"
                placeholder="Message"
                id="chatInputRadar"
                className=""
                value={value}
                onChangeFunc={(e) => {
                  const startTrimValue = e?.target?.value.trimStart();
                  setValue(startTrimValue);
                }}
                parentClassName="flex-1"
                handleKeyDown={handleKeyDown}
                disabled={isSendMsgLoading}
              />

              <ButtonCmp
                className="btn_primary lg:!px-[9px] !px-2"
                type="button"
                onClick={() => onSendMessage(value.trim())}
                loading={isSendMsgLoading}
                disabled={isSendMsgLoading}
              >
                {isSendMsgLoading ? '' : <Send01 className="w-4 h-4" />}
              </ButtonCmp>
            </div>
          </div>
        </>

        {/* <div className="px-4 py-3 border-b border-utilityGray200 flex items-center gap-x-2.5 flex-1">
          <div className="flex items-center gap-x-1.5 flex-1"></div>
        </div>

        <div className="px-4 py-3 border-b border-utilityGray200 flex items-center gap-x-2.5 flex-1">
          <div className="flex items-center gap-x-1.5 flex-1"></div>
        </div> */}
      </div>
    </>
  );
};
export default RadarChatBlock;
